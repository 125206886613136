import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { ISettings } from '@environment/settings';
import { VERSION } from '@environment/version';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  get domainRegexp(): string {
    return environment.domainRegexp;
  }

  get firebase(): any {
    return environment.firebase;
  }

  get isProduction() {
    return environment.firebase.projectId === 'chirpy-central' || environment.firebase.projectId === 'onlyfriends-prod';
  }

  get isLocalDevelopment() {
    return window.location.host.indexOf('localhost') === 0 || window.location.host.indexOf('127.0.0.1') === 0;
  }

  get name(): string {
    return environment.name;
  }

  get settings(): ISettings {
    return environment.settings as ISettings;
  }

  get shortName(): string {
    return environment.shortName;
  }

  get versionNumber() {
    return `v${VERSION.version} (${VERSION.hash})`;
  }

  url(country: string = 'AU'): string {
    country = country == '' ? 'AU' : country; // fallback to AU to ensure we get a URL
    return `https://${environment.settings.url[country]}`;
  }
}
