import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConstantsService } from '@shared/services/constants.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ISocial } from '@shared/models/social/social';
import { SocialService } from '@shared/services/social/social.service';

@Component({
  selector: 'chirpy-social-list',
  templateUrl: './chirpy-social-list.component.html',
  styleUrls: ['chirpy-social-list.component.scss']
})
export class ChirpySocialListComponent {
  @Input() buttonLink: string = '';
  @Input() buttonText: string = '';
  @Input() heading: string = '';
  @Output() linkClicked = new EventEmitter();
  @Input() socials$: Observable<ISocial[]>;
  @Input() noSocialsMessage: string = '';
  constructor(private socialService: SocialService) {}

  isGoing(social: ISocial): boolean {
    return this.socialService.isGoing(social);
  }

  isInterested(social: ISocial): boolean {
    return this.socialService.isInterested(social);
  }

  numberInterested(social: ISocial): string {
    return this.socialService.numberInterested(social);
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }
}
