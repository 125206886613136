import { Injectable } from '@angular/core';
import { UserObject } from '@shared/models/user-object';
import { BaseDatabase } from '@shared/services/base.database';

@Injectable({
  providedIn: 'root'
})
export class SignupGenderDatabase extends BaseDatabase {
  // TODO: Replace this with a generic UserService::updateUserProfileField(uid, data)?
  setGender(gender: string, uid: string) {
    return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS, uid, { gender: gender });
  }
}
