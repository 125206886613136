import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { ConstantsService } from '@shared/services/constants.service';
import { ResetPasswordPresenter } from './reset-password.presenter';
import { ResetPasswordService } from './reset-password.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.page.html',
  viewProviders: [ResetPasswordPresenter]
})
export class ResetPasswordPage {
  invalidEmail: boolean = false;
  resetPasswordLinkSent: boolean = false;
  showForm: boolean = true;
  type: string = 'manual';

  constructor(private analyticsService: AnalyticsService, private constantsService: ConstantsService, private resetPasswordPresenter: ResetPasswordPresenter, private resetPasswordService: ResetPasswordService, private route: ActivatedRoute) {}

  get CONSTANTS() {
    return this.constantsService.constants.RESET_PASSWORD;
  }

  get resetPasswordForm() {
    return this.resetPasswordPresenter.form;
  }

  ionViewWillEnter() {
    // ngOnInit doesn't trigger if you come back from a navigation stack, ionViewWillEnter / ionViewDidEnter will.
    this.showForm = true;
    this.resetPasswordLinkSent = false;
    this.invalidEmail = false;
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const email = params.get('email');
      if (email == null) {
        return;
      }

      this.resetPasswordPresenter.setValue(email);
      this.type = 'auto';
    });
  }

  onResetPassword() {
    const form = this.resetPasswordPresenter.resetPassword();
    if ((form.email || '').trim().length === 0) return;

    this.resetPasswordService
      .resetPassword(form)
      .then(() => {
        this.showForm = false;
        this.invalidEmail = false;
        this.resetPasswordLinkSent = true;
        this.analyticsService.eventTrack(AnalyticsCategory.AUTH, AnalyticsAction.AUTH_RESET_PASSWORD);
      })
      .catch(() => {
        this.invalidEmail = true;
        this.analyticsService.eventTrack(AnalyticsCategory.AUTH, AnalyticsAction.AUTH_RESET_PASSWORD_INVALID_EMAIL);
      });
  }
}
