import { Component, Input } from '@angular/core';

@Component({
  selector: 'email-error',
  templateUrl: './email-error.component.html'
})
export class EmailErrorComponent {
  @Input() error: any;
  @Input() state: string;
}
