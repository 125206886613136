import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MembersGuard } from '@shared/guards/members-guard';
import { CoinsPage } from './coins.page';
import { CoinsTransactionListPage } from './transaction-list/transaction-list.page';

export const routes: Routes = [
  {
    path: '',
    component: CoinsPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Coins summary'
    }
  },
  {
    path: 'transactions',
    component: CoinsTransactionListPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Coins transactions'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoinsRoutingModule {}
