import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundPage } from './not-found/not-found.page';
import { PermissionDeniedPage } from './permission-denied/permission-denied.page';

const routes: Routes = [
  {
    path: 'not-found',
    component: NotFoundPage,
    data: {
      title: 'Not found'
    }
  },
  {
    path: 'permission-denied',
    component: PermissionDeniedPage,
    data: {
      title: 'Not found'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErrorsRoutingModule {}
