import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { IColumn } from '@shared/models/column';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { GroupService } from '@shared/services/groups/group.service';
import { map, mergeMap } from 'rxjs/operators';
import { IInput } from '@shared/models/input';
import { BaseReportComponent } from '../../base-report/base-report.component';

@Component({
  selector: 'app-reports-catchups-by-date',
  templateUrl: '../../base-report/base-report.component.html'
})
export class CatchupsByDateComponent extends BaseReportComponent {
  columns = [
    { label: 'Date', field: 'date', class: 'data-table__column-responsive-bold' },
    { label: 'Name', field: 'name' },
    { label: 'Group Name', field: 'groupName' },
    { label: 'Host Name', field: 'ownerName' },
    { label: 'Host Role', field: 'ownerRole' },
    { label: 'Country', field: 'country' },
    { label: 'Going', field: 'going' },
    { label: 'Not Going', field: 'notGoing' },
    { label: 'Waiting for Host', field: 'waiting' },
    { label: 'Other', field: 'other' },
    { label: 'Shared', field: 'shared' }
  ];
  config = {
    routerLink: {
      path: '/catchups',
      id: [':uid']
    }
  };
  dateFrom: string = '';
  dateTo: string = '';
  parametersHeader = 'Select dates';
  parametersMessage = '<p>Enter start date and end date for the report.</p><p class="ion-no-margin">N.B. Catchups are automatically deleted 40 days after the date of the event.</p>';
  parametersInputs: IInput[] = [
    {
      label: 'From: ',
      name: 'dateFrom',
      placeholder: 'YYYY-MM-DD',
      type: 'date'
    },
    {
      label: 'To: ',
      name: 'dateTo',
      placeholder: 'YYYY-MM-DD',
      type: 'date'
    }
  ];
  instructions = '<p>Click on "select parameters" to choose dates for the report</p>';
  title = 'CatchUps Report by Date';

  constructor(alertController: AlertController, private catchupService: CatchupService, private groupService: GroupService) {
    super(alertController);
  }

  loadData() {
    if (!this.dateFrom || !this.dateTo) return;

    this.data$ = this.catchupService.getCatchupsByDate(this.dateFrom, this.dateTo).pipe(
      map(catchups => {
        return catchups.map(catchup => {
          const country = { country: (catchup.country || []).join(', ') };
          const attendees = Object.values(catchup.attendees || {});
          const numbers = {
            going: attendees.filter(x => CatchupRsvpStatus.GOING === x || CatchupRsvpStatus.GOING_CLAIMED === x).length,
            notGoing: attendees.filter(x => CatchupRsvpStatus.NOT_GOING === x).length,
            waiting: attendees.filter(x => CatchupRsvpStatus.WAITING_FOR_HOST === x).length
          };
          const other = { other: attendees.length - numbers.going - numbers.notGoing - numbers.waiting };
          let isShared = Object.keys(catchup.sharedGroups || {}).length > 0 ? 'Yes' : 'No';
          // Some hosts share a catchup with their own group
          if (Object.keys(catchup.sharedGroups || {}).length === 1 && catchup.sharedGroups[catchup.groupId] === catchup.groupName) isShared = 'No';
          const shared = { shared: isShared };
          const ownerName = { ownerName: catchup.ownerName.replace(/\(([^(]*)$/, '').trim() }; // remove (firstName) from ownerName string
          return Object.assign({}, catchup, numbers, other, country, shared, ownerName);
        });
      }),
      mergeMap(
        catchupData => this.groupService.getGroupsById(catchupData.map(x => x.groupId)),
        (catchupData, groupData) => {
          let groups = {};
          for (const group of groupData) {
            groups[group.uid] = group;
          }

          let data = [];
          for (const catchup of catchupData) {
            const group = groups[catchup.groupId] || null;
            let ownerRole = '';

            if (group) {
              if (Object.keys(group.cohosts).includes(catchup.ownerId)) ownerRole = 'Cohost';
              if (Object.keys(group.hosts).includes(catchup.ownerId)) ownerRole = 'Host';
              Object.assign(catchup, { ownerRole });
            }
            data.push(catchup);
          }
          return data;
        }
      )
    );
  }

  selectParametersHandler(data: any) {
    this.dateFrom = data.dateFrom;
    this.dateTo = data.dateTo;
    this.parametersInputs[0].value = this.dateFrom;
    this.parametersInputs[1].value = this.dateTo;
    this.loadData();
  }
}
