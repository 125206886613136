import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationTarget } from '@shared/constants/notification-target';
import { IAdvancedNotification } from '@shared/models/notifications/advanced-notification';
import { INotificationSettings } from '@shared/models/notifications/notification-settings';
import { IUtcOffset } from '@shared/models/utc-offset';
import { NotificationDatabase } from './notification.database';
import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import FieldValue = firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  ADVANCED_TARGETS: NotificationTarget[] = [NotificationTarget.ADVERTISER, NotificationTarget.GROUP, NotificationTarget.MESSAGE];

  constructor(private notificationDatabase: NotificationDatabase, private router: Router) {}

  createNotificationForMember(target: NotificationTarget, targetId: string, memberId: string, notificationKey: string = '', isLargeGroup: boolean = false): void {
    // Message notifications need to be handled separately.
    // directMessage/conversation settings need to be mapped to newMessage notification
    if (NotificationTarget.MESSAGE === target) {
      this.createMessageNotificationForMember(target, targetId, memberId, notificationKey);
      return;
    }

    this.getSettingsForMember(memberId)
      .pipe(first(x => !!x))
      .subscribe(settings => {
        const value = this.getDefaultsFromSettings(settings, target);

        if (this.ADVANCED_TARGETS.includes(target)) {
          for (let [key, setting] of Object.entries(settings[target])) {
            settings[target][key]['items'][targetId] = setting.default;
          }
          // Disable newChitChat notifications on groups with more than 500 members to reduce email costs
          if (isLargeGroup) {
            settings[target]['newChitChat']['items'][targetId] = false;
          }
          this.notificationDatabase.updateSettingsForMember(memberId, settings);
        }

        this.notificationDatabase.createNotificationForMember(target, targetId, memberId, value);
      });
  }

  deleteNotification(target: NotificationTarget, targetId: string): Promise<void> {
    return this.notificationDatabase.deleteNotification(target, targetId);
  }

  deleteSettingsForMember(uid: string, target: NotificationTarget): Promise<any> {
    const data = {
      [target]: FieldValue.delete()
    };
    return this.notificationDatabase.updateSettingsForMember(uid, data);
  }

  getNotificationsForMember(uid: string, target: NotificationTarget): Observable<any> {
    return this.notificationDatabase.getNotificationsForMember(uid, target);
  }

  getSettingsForMember(uid: string): Observable<INotificationSettings> {
    return this.notificationDatabase.getSettingsForMember(uid).pipe(
      map(settings => {
        // BaseDatabase returns uid as part of document, but we don't want it being used as a setting
        if (settings) delete settings.uid;
        return settings;
      })
    );
  }

  removeNotificationForMember(target: NotificationTarget, targetId: string, memberId: string) {
    if (this.ADVANCED_TARGETS.includes(target)) {
      this.getSettingsForMember(memberId)
        .pipe(first(x => !!x))
        .subscribe(settings => {
          for (let [key, setting] of Object.entries(settings[target])) {
            delete settings[target][key]['items'][targetId];
          }
          // Set merge = false to delete things from the "items" map
          this.notificationDatabase.updateSettingsForMember(memberId, settings, false);
        });
    }

    return this.notificationDatabase.removeNotificationForMember(target, targetId, memberId);
  }

  updateAdvancedSettingsForMember(uid: string, target: NotificationTarget, key: string, data: IAdvancedNotification) {
    const newData = { [key]: data };

    return this.notificationDatabase.updateIndividualSettingForMember(uid, target, newData).then(result => {
      this.updateNotificationTargetsForMember(uid, target, newData);
      this.router.navigate(['/account/notifications']);
    });
  }

  updateDigestTimeForMember(uid: string, oldOffset: IUtcOffset, newOffset: IUtcOffset): void {
    // Don't bother updating if the offsets are the same
    if (oldOffset.hours === newOffset.hours && oldOffset.minutes === newOffset.minutes) return;

    this.getSettingsForMember(uid)
      .pipe(first(x => !!x))
      .subscribe((settings: INotificationSettings) => {
        let digestTime = settings.digestTime || '18:00';
        let parts = digestTime.split(':');
        parts[0] = ((+parts[0] + oldOffset.hours - newOffset.hours + 24) % 24).toString().padStart(2, '0');
        parts[1] = ((+parts[1] + oldOffset.minutes - newOffset.minutes + 60) % 60).toString().padStart(2, '0');
        digestTime = parts.join(':'); // TODO: Need fallback if either of these is NaN
        this.notificationDatabase.updateSettingsForMember(uid, { digestTime: digestTime }, true);
      });
  }

  updateNotificationTargetsForMember(uid: string, target: NotificationTarget, settings: any) {
    switch (target) {
      case NotificationTarget.GROUP:
      case NotificationTarget.MESSAGE:
        // Convert data from newChitChat: { items: {083jPgbrAKCAZCPIRYk0: true}... to 083jPgbrAKCAZCPIRYk0: { newChitChat: true, ...}
        const groupData: Record<string, any> = {};
        for (let [notificationKey, values] of Object.entries(settings as IAdvancedNotification)) {
          for (let [groupId, value] of Object.entries(values.items)) {
            groupData[groupId] = Object.assign(groupData[groupId] || {}, { [notificationKey]: value });
          }
        }

        // Now loop over all groups and update notification with all keys
        for (let [id, groupDatum] of Object.entries(groupData)) {
          this.notificationDatabase.updateNotificationForMember(target, id, uid, groupDatum);
        }
        break;

      case NotificationTarget.CATCHUP:
      case NotificationTarget.PLACE:
      case NotificationTarget.SOCIAL:
      case NotificationTarget.ROMANCE:
      default:
        this.getNotificationsForMember(uid, target as NotificationTarget)
          .pipe(first(x => !!x))
          .subscribe((notifications: any[]) => {
            for (let notification of notifications) {
              this.notificationDatabase.updateNotificationForMember(target as NotificationTarget, notification.uid, uid, settings);
            }
          });
        break;
    }
  }

  updateNotificationsForMember(uid: string, data: Partial<Record<NotificationTarget, INotificationSettings>>) {
    for (let [target, settings] of Object.entries(data)) {
      this.updateNotificationTargetsForMember(uid, target as NotificationTarget, settings);
    }
  }

  updateSettingsForMember(uid: string, data: Partial<Record<NotificationTarget, any>>, redirect: any = ['/account']) {
    return this.notificationDatabase.updateSettingsForMember(uid, data).then(result => {
      this.updateNotificationsForMember(uid, data);
      if (redirect) this.router.navigate(redirect);
    });
  }

  private createMessageNotificationForMember(target: NotificationTarget, targetId: string, memberId: string, notificationKey: string = ''): void {
    this.getSettingsForMember(memberId)
      .pipe(first(x => !!x))
      .subscribe(settings => {
        let value = false;
        const defaultValue = settings[target][notificationKey];
        if (typeof defaultValue === 'boolean') {
          value = !!value;
        }
        if (typeof defaultValue === 'object' && defaultValue.hasOwnProperty('default')) {
          value = !!defaultValue.default;
        }

        // Only add threadId to items map for either conversation or directMessage, not both
        settings[target][notificationKey]['items'][targetId] = value;
        this.notificationDatabase.updateSettingsForMember(memberId, settings);

        this.notificationDatabase.createNotificationForMember(target, targetId, memberId, { newMessage: value });
      });
  }

  private getDefaultsFromSettings(settings: INotificationSettings, target: NotificationTarget): Record<string, boolean> {
    // TODO: Handle the case where a member's settings have not been set?

    const defaults: any = {};
    for (let [key, value] of Object.entries(settings[target])) {
      defaults[key] = value.hasOwnProperty('default') ? value.default : value;
    }
    return defaults;
  }
}
