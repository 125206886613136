import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AvatarSize } from '@shared/constants/avatar-size';
import { Country } from '@shared/constants/country';
import { IAdvertiser } from '@shared/models/advertisers/advertiser';
import { IAdvertiserOptions } from '@shared/models/advertisers/advertiser-options';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AdvertiserService } from '@shared/services/advertisers/advertiser.service';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UIService } from '@shared/services/ui.service';
import { Observable, Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'advertiser-list',
  templateUrl: './advertiser-list.page.html',
  styleUrls: ['./advertiser-list.page.scss']
})
export class AdvertiserListPage implements OnInit, OnDestroy {
  get ASSETS() {
    return this.constantsService.constants.ASSETS;
  }

  advertiserOptions: IAdvertiserOptions;
  advertisers: IAdvertiser[] = [];
  advertiserSubscription: Subscription;
  allAdvertisers: IAdvertiser[] = [];
  allowOtherCountries: boolean;
  AVATAR_SIZE: AvatarSize = AvatarSize.LARGE;
  canEditOwnAdvertisement: boolean = false;
  CONSTANTS: any;
  countries: Country[];
  currentView: string = 'list-view';
  defaultSearchModel: IAdvertiserOptions = { category: '', location: null, name: '' };
  following: IAdvertiser[] = [];
  isDesktop: boolean = false;
  isFollowingView: boolean = false;
  isListView: boolean = true;
  isSearch: boolean = false;
  isSearchView: boolean = false;
  member$: Observable<UserObject>;
  placeholders: string[] = [];
  platformSubscription: Subscription;
  @ViewChild('search', { static: false }) search: any;
  searchCriteria: Record<string, string> = {};
  searchModel: IAdvertiserOptions | null;

  constructor(private advertiserService: AdvertiserService, private analyticsService: AnalyticsService, private authService: AuthService, private constantsService: ConstantsService, private subscriptionService: SubscriptionService, private uiService: UIService) {}

  init() {
    this.member$
      .pipe(
        skipWhile(x => x == null),
        take(1)
      )
      .subscribe((profile: UserObject) => {
        this.loadAdvertisers(profile);
      });
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.advertiserSubscription);
    this.subscriptionService.clearSubscription(this.platformSubscription);
  }

  ngOnInit() {
    this.allowOtherCountries = this.constantsService.constants.APP.allowOtherCountries;
    this.CONSTANTS = this.constantsService.constants.ADVERTISERS;
    this.countries = Object.values(this.constantsService.constants.APP.countries || []);
    this.member$ = this.authService._userProfileSubject;

    this.platformSubscription = this.uiService.isLargeDisplay$.subscribe(isLargeDisplay => {
      this.isDesktop = isLargeDisplay;
      if (this.isDesktop) {
        this.isSearchView = true;
      } else {
        this.isSearchView = this.currentView === 'search-view';
      }

      // if changing from small to large and you were on the search view, change to the list view
      // two-way binding triggers onChangeView if the ion-toolbar is not hidden
      if (isLargeDisplay && this.currentView === 'search-view') {
        this.currentView = 'list-view';
        this.onChangeView({ detail: 'list-view' });
      }
    });
    this.subscriptionService.add(this.platformSubscription);
    // if default currentView changes, need to update this event too
    this.analyticsService.eventTrack(AnalyticsCategory.ADVERTISERS, AnalyticsAction.ADVERTISERS_VIEW_LISTINGS);
    this.init();
  }

  onChangeView(event: any) {
    switch (event.detail.value) {
      case 'following-view':
        this.isFollowingView = true;
        this.isListView = false;
        this.isSearchView = this.isDesktop;
        break;

      case 'search-view':
        this.isFollowingView = false;
        this.isListView = false;
        this.isSearchView = true;
        break;

      case 'list-view':
      default:
        this.isFollowingView = false;
        this.isListView = true;
        this.isSearchView = this.isDesktop;
        this.analyticsService.eventTrack(AnalyticsCategory.ADVERTISERS, AnalyticsAction.ADVERTISERS_VIEW_LISTINGS);
        break;
    }
  }

  onClickCriterion(key: string) {
    const criterion = this.searchModel[key];
    this.analyticsService.eventTrack(AnalyticsCategory.ADVERTISERS, AnalyticsAction.ADVERTISERS_CLEAR_SEARCH_CRITERION, criterion, { type: key });

    this.searchModel[key] = this.defaultSearchModel[key];
    this.search.presenter.patchValue({ [key]: this.searchModel[key] });
    if (key === 'category') this.search.clearSelectedItems();
    this.onSearch(this.searchModel);
  }

  onSearch(search: IAdvertiserOptions) {
    this.searchModel = search;
    if (search == null) return;

    this.advertisers = this.allAdvertisers
      .filter((advertiser: IAdvertiser) => {
        let showAdvertiser = true;
        showAdvertiser = showAdvertiser && (advertiser.categories.includes(search.category) || search.category === '');
        if (advertiser.searchName) {
          const normalisedAdvertiserName = advertiser.searchName.trim();
          const normalisedSearchName = search.name.trim().toLowerCase();
          showAdvertiser = showAdvertiser && normalisedAdvertiserName.includes(normalisedSearchName);
        }
        if (search.location !== null) {
          showAdvertiser = showAdvertiser && advertiser.region === search.location;
        }
        return showAdvertiser;
      })
      .sort((a, b) => a.searchName.localeCompare(b.searchName));

    this.updateSearchCriteria(search);
  }

  private loadAdvertisers(profile: UserObject) {
    if (profile == null) return;
    this.advertiserSubscription = this.advertiserService.getAllAdvertisers().subscribe(results => {
      if (results == null) return;
      this.allAdvertisers = results;
      this.advertisers = this.allowOtherCountries || this.authService.isAdmin() ? results : results.filter(x => x.country === profile.country || x.region === 'Global');
      this.advertisers.sort((a, b) => a.searchName.localeCompare(b.searchName));
      this.following = this.allAdvertisers.filter(x => profile.advertiserIds.includes(x.uid));
      this.canEditOwnAdvertisement = this.allAdvertisers.some(advertiser => advertiser.uid === profile.uid);
    });
    this.subscriptionService.add(this.advertiserSubscription);
  }

  private updateSearchCriteria(options: IAdvertiserOptions = null) {
    // Could almost do this with key: value from IAdvertiserOptions, but that doesn't allow hiding default criteria
    const criteria = {};
    if (options == null) this.searchCriteria = criteria;
    if ((options.location || '').trim().length > 0) criteria['location'] = `Location: ${options.location}`;
    if ((options.name || '').trim().length > 0) criteria['name'] = `Name: ${options.name}`;
    if (options.category !== '') criteria['category'] = `Category: ${options.category}`;
    this.searchCriteria = criteria;
    this.isSearch = Object.values(this.searchCriteria).length > 0;
  }
}
