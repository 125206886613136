import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ChirpyPopoverListComponent } from '@shared/components/chirpy-popover-list/chirpy-popover-list.component';
import { IButton } from '@shared/models/button';
import { IButtonGroup } from '@shared/models/button-group';
import { AnalyticsService, IAnalyticsSetting } from '@shared/services/analytics';
import { UIService } from '@shared/services/ui.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'chirpy-popover-buttons',
  templateUrl: './chirpy-popover-buttons.component.html'
})
export class ChirpyPopoverButtonsComponent implements OnChanges, OnInit {
  @Input() analytics: IAnalyticsSetting;
  buttonCount$: BehaviorSubject<number> = new BehaviorSubject(0);
  @Input() buttons: IButtonGroup[];
  canShowButtons$: Observable<boolean>;
  @Input() condition: boolean;
  @Input() icon: string = 'more';
  @Input() color: string = 'primary';
  @Input() responsive: boolean = false;
  @Input() slot: string = 'start';
  @Input() text: string = 'Actions';

  constructor(private analyticsService: AnalyticsService, private popoverController: PopoverController, public uiService: UIService) {}

  ngOnChanges(changes: SimpleChanges) {
    const newButtons = changes.buttons.currentValue || [];
    const count = newButtons.reduce((total, current) => {
      total += !!current.condition ? (current.buttons || []).filter(x => !!x.condition).length : 0;
      return total;
    }, 0);
    this.buttonCount$.next(count);
  }

  ngOnInit() {
    this.canShowButtons$ = combineLatest(this.uiService.isExtraLargeDisplay$, this.buttonCount$).pipe(map(([isExtraLargeDisplay, buttonCount]) => isExtraLargeDisplay || buttonCount <= 1));
  }

  onClick(button: IButton) {
    if (this.analytics) this.analyticsService.eventTrack(this.analytics.category, this.analytics.action, button.text);
    if (button.click) button.click();
  }

  async onPresentPopover(event: any) {
    const popover = await this.popoverController.create({
      component: ChirpyPopoverListComponent,
      componentProps: {
        buttons: this.buttons
      },
      event: event,
      showBackdrop: false,
      translucent: true
    });
    return await popover.present();
  }
}
