import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IAdvertiser } from '@shared/models/advertisers/advertiser';
import { IContent } from '@shared/models/content';
import { ISelectOption } from '@shared/models/select-option';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EnforceProfileService } from '@shared/services/enforce-profile.service';
import { AdvertiserService } from '@shared/services/advertisers/advertiser.service';
import { RegionService } from '@shared/services/regions/region.service';
import { ToastService } from '@shared/services/toast.service';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { AdvertiserEditPresenter } from './advertiser-edit.presenter';

@Component({
  selector: 'app-advertiser-edit',
  styleUrls: ['./advertiser-edit.page.scss'],
  templateUrl: './advertiser-edit.page.html',
  viewProviders: [AdvertiserEditPresenter]
})
export class AdvertiserEditPage implements OnInit {
  get form() {
    return this.advertiserEditPresenter.form;
  }

  ADVERTISER_CATEGORIES$: BehaviorSubject<string[]>;
  advertiser: IAdvertiser;
  CONSTANTS: any;
  content$: Observable<IContent>;
  @ViewChild('editor', { static: false }) editor: any;
  errorMessage: string;
  filePrefix: string;
  interfaceOptions: any = { cssClass: 'wide-select' };
  regions: ISelectOption[] = [];

  constructor(
    public advertiserEditPresenter: AdvertiserEditPresenter,
    private advertiserService: AdvertiserService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private enforceProfileService: EnforceProfileService,
    private regionService: RegionService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService
  ) {}

  goBack() {
    this.advertiser ? this.router.navigate([`/advertisers`, this.advertiser.uid]) : this.router.navigate([`/advertisers`]);
  }

  ionViewWillEnter() {
    this.enforceProfileService.enforce();
  }

  ngOnInit() {
    const uid = this.route.snapshot.paramMap.get('id');
    this.filePrefix = `advertiser/${uid}`;
    this.ADVERTISER_CATEGORIES$ = this.advertiserService.advertiserCategories;
    this.CONSTANTS = this.constantsService.constants.ADVERTISERS.EDIT;

    this.content$ = this.advertiserService.canEditAdvertiser(uid).pipe(
      switchMap(canEdit => {
        this.regions = this.regionService.advertiserRegions;
        if (!canEdit) {
          this.errorMessage = this.CONSTANTS.permissionErrorMessage;
          return of(null);
        } else {
          return this.advertiserService.getAdvertiser(uid).pipe(
            first(),
            map((advertiser: IAdvertiser) => {
              if (advertiser == null) {
                //shouldn't happen, listing gets created when advertiser access is granted
                this.errorMessage = this.CONSTANTS.loadingErrorMessage;
                return;
              }
              this.advertiser = advertiser;
              this.advertiserEditPresenter.setValue(advertiser);
              return advertiser.description;
            })
          );
        }
      })
    );
  }

  updateAdvertiser() {
    // merge form values with description from rich text editor
    let formValue = this.advertiserEditPresenter.advertiser();
    this.editor
      .onSave()
      .then(content => {
        Object.assign(this.advertiser, formValue, { description: content });
        this.advertiserService.updateAdvertiser(this.advertiser);
      })
      .catch(err => {
        const message = `Couldn't update ${this.CONSTANTS.branding}: ${err}`;
        this.toastService.presentToast(message);
      });
  }
}
