import { Injectable } from '@angular/core';
import { Gender } from '@shared/constants/gender';
import { ALL_AGES } from '@shared/models/age-range';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { IRomanceListing } from '../models/romance-listing';
import { IRomanceOptions } from '../models/romance-options';

@Injectable({
  providedIn: 'root'
})
export class RomanceDatabase extends BaseDatabase {
  deleteRomanceListing(uid: string) {
    return this.deleteDocument('romanceListings', uid);
  }

  getRomanceListing(uid: string) {
    return this.getDocument<IRomanceListing>('romanceListings', uid);
  }

  getRomanceListings(gender: string, isAdmin: boolean, recordsToFetch: number, romanceOptions: IRomanceOptions) {
    const queryFn = this.getRomanceListingQuery(romanceOptions, recordsToFetch, isAdmin, gender);
    return this.getDocumentsByQuery<IRomanceListing>('romanceListings', queryFn);
  }

  getRomanceOptions(uid: string) {
    return this.getDocument<IRomanceOptions>('romanceOptions', uid);
  }

  updateMemberProfile(memberId: string, data) {
    return this.updateDocument('centralMembers', memberId, data);
  }

  updatePrivateMemberData(memberId: string, data) {
    return this.updateDocument('centralMembersPrivate', memberId, data);
  }

  updateRomanceListing(romanceListing) {
    return this.updateDocument('romanceListings', romanceListing.uid, romanceListing);
  }

  updateRomanceOptions(uid: string, data: any) {
    return this.updateDocument('romanceOptions', uid, data);
  }

  private getRomanceListingQuery(romanceOptions: IRomanceOptions, recordsToFetch: number, isAdmin: boolean, gender: string) {
    const whereConditions: IWhereCondition[] = [];
    const orderConditions: IOrderCondition[] = [];

    if (!isAdmin) {
      whereConditions.push({ field: 'published', operator: '==', value: true });
      whereConditions.push({ field: 'approved', operator: '==', value: true });
      whereConditions.push({
        field: 'country',
        operator: '==',
        value: romanceOptions.country
      });

      const canFilterAgeRange = (romanceOptions.ageRange || '') !== '' && romanceOptions.ageRange !== ALL_AGES.label;
      if (canFilterAgeRange) {
        whereConditions.push({
          field: 'ageRange',
          operator: '==',
          value: romanceOptions.ageRange
        });
      }

      whereConditions.push({
        field: 'seeking',
        operator: 'in',
        value: [gender, Gender.EVERYONE]
      });

      const canShowEveryone = romanceOptions.seeking == null || romanceOptions.seeking === Gender.EVERYONE;
      if (!canShowEveryone) {
        whereConditions.push({
          field: 'gender',
          operator: '==',
          value: romanceOptions.seeking
        });
      }
    }

    let region = romanceOptions.region || '';
    const canFilterRegion = region.length > 0;
    if (canFilterRegion) {
      whereConditions.push({
        field: 'region',
        operator: '==',
        value: region
      });
    }

    if (isAdmin) {
      orderConditions.push({ field: 'approved', direction: 'asc' });
    }

    orderConditions.push({ field: 'created', direction: 'desc' });

    return this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
  }
}
