import { Component, ViewChild } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { ICoinsTransaction } from '@shared/models/coins/coins-transaction';
import { IDataTableConfig } from '@shared/models/data-table-config';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-coins-edit',
  templateUrl: './coins-edit.page.html',
  styleUrls: ['./coins-edit.page.scss']
})
export class CoinsEditPage {
  config: IDataTableConfig = {};
  configAddRedeem: IDataTableConfig = {};
  configReverse: IDataTableConfig = { onClick: { color: 'danger', icon: 'remove-circle-outline', title: 'Click to select' } };
  currentView: string = 'add-view';
  isAddView: boolean = true;
  isRedeemView: boolean = false;
  isReverseView: boolean = false;
  memberId: string = '';
  memberName: string = '';
  records: number = 100;
  transactionToReverse: ICoinsTransaction;

  constructor(protected alertController: AlertController, private route: ActivatedRoute) {}

  ngOnInit() {
    this.memberId = this.route.snapshot.paramMap.get('id');
    this.memberName = this.route.snapshot.paramMap.get('name');
  }

  onChangeView(event: any) {
    switch (event.detail.value) {
      case 'redeem-view':
        this.isAddView = false;
        this.isRedeemView = true;
        this.isReverseView = false;
        this.config = this.configAddRedeem;
        break;

      case 'reverse-view':
        this.isAddView = false;
        this.isRedeemView = false;
        this.isReverseView = true;
        this.config = this.configReverse;
        break;

      case 'add-view':
      default:
        this.isAddView = true;
        this.isRedeemView = false;
        this.isReverseView = false;
        this.config = this.configAddRedeem;
        break;
    }
  }

  onClick({ data, row, isIcon }) {
    if (isIcon) this.transactionToReverse = data as ICoinsTransaction;
  }
}
