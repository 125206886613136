import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { List } from '@shared/constants/list';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BaseRTDatabase {
  protected readonly LIST = List;

  constructor(protected db: AngularFireDatabase) {}

  // returns Promise<>
  createObject(listName: string, document: any) {
    const data: any = {};
    Object.assign(data, document);
    delete data.key;

    return this.db.list(listName).push({ ...data });
  }

  // Returns promise<>
  deleteObject(listName: string, key: string) {
    return this.db.list(listName).remove(key);
  }

  getObject<T>(listName: string, key: string): Observable<T> {
    return this.db.object<T>(`${listName}/${key}`).valueChanges();
  }

  getList<T>(listName: string, key: string = ''): Observable<T[]> {
    const ref = key !== '' ? `${listName}/${key}` : listName;
    return this.db.list<T>(ref).valueChanges();
  }

  getObjectsByQuery<T>(listName: string, queryFn: (ref) => firebase.database.Query): Observable<T[]> {
    return this.db
      .list<T>(listName, queryFn)
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.val();
            (data as any).key = a.key;

            return data;
          })
        )
      );
  }

  updateObject(listName: string, key: string, document: any, merge: boolean = true): Promise<void> {
    const data: any = {};
    Object.assign(data, document);
    delete data.key;

    if (merge) {
      return this.db.list(listName).update(key, { ...data });
    } else {
      return this.db.list(listName).set(key, { ...data });
    }
  }
}
