import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { ALL_COUNTRIES } from '@shared/constants/country';
import { IContent } from '@shared/models/content';
import { AuthService } from '@shared/services/auth.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { IPage } from '../models/page';
import { ContentPageService } from '../services/content-page.service';

@Component({
  selector: 'app-content-page-view',
  templateUrl: './content-page-view.page.html'
})
export class ContentPageViewPage {
  get canEdit() {
    return this.page != null && this.authService.isAdmin([AdminRole.SUPER, AdminRole.HOSTS, AdminRole.EDITOR]);
  }

  content$: Observable<IContent>;
  page: IPage;

  constructor(private authService: AuthService, private contentPageService: ContentPageService, private route: ActivatedRoute) {}

  ionViewWillEnter() {
    const pageId = this.route.snapshot.paramMap.get('pageId');
    this.content$ = this.contentPageService.getPage(pageId).pipe(
      first(x => !!x),
      map(page => {
        this.page = page;
        const memberCountry = this.authService.getCountry() || ALL_COUNTRIES;
        const country = page.data[memberCountry] ? memberCountry : ALL_COUNTRIES;
        return page.data[country];
      })
    );
  }

  onCreate() {
    this.contentPageService.onCreate();
  }
}
