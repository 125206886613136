import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventType } from '@infrastructure/constants/event-type';
import { ICatchup } from '@shared/models/catchups/catchup';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { AuthService } from '@shared/services/auth.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { VirtualCatchupService } from '@shared/services/virtual-catchups/virtual-catchup.service';
import { skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'app-virtual-catchup',
  templateUrl: './virtual-catchup.page.html'
})
export class VirtualCatchupPage {
  catchup: ICatchup;

  constructor(private authService: AuthService, private catchupService: CatchupService, private route: ActivatedRoute, private router: Router, private virtualCatchupService: VirtualCatchupService) {}

  ionViewWillLeave() {
    this.catchup = null;
    this.leaveVirtualCatchup();
  }

  ngOnInit() {
    const uid = this.route.snapshot.paramMap.get('id');
    this.catchupService
      .getCatchup(uid)
      .pipe(
        skipWhile(u => !u),
        take(1)
      )
      .subscribe((catchup: ICatchup) => {
        if (catchup.eventType !== EventType.Virtual) {
          console.warn('Catchup is not a virtual catchup', catchup);
          this.router.navigate(['/']);
          return;
        }

        this.authService._userProfileSubject
          .pipe(
            skipWhile(u => !u),
            take(1)
          )
          .subscribe(user => {
            if (catchup.attendees[user.uid] === CatchupRsvpStatus.GOING || catchup.attendees[user.uid] === CatchupRsvpStatus.GOING_CLAIMED) {
              this.catchup = catchup;
              this.joinVirtualCatchup();
            } else {
              console.warn(`You have not RSVP'd to this catchup`, catchup);
              this.router.navigate(['/']);
            }
          });
      });
  }

  private joinVirtualCatchup() {
    this.virtualCatchupService.setCatchup(null, this.catchup.uid, false);
  }

  private leaveVirtualCatchup() {
    this.virtualCatchupService.unsetCatchup();
  }
}
