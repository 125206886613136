import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RoomShareProfileComponent } from './components/room-share-profile/room-share-profile.component';
import { TripDetailHeaderComponent } from './components/trip-detail-header/trip-detail-header.component';
import { RoomShareEditPage } from './room-share-edit/room-share-edit.page';
import { RoomShareListPage } from './room-share-list/room-share-list.page';
import { RoomShareManagePage } from './room-share-manage/room-share-manage.page';
import { RoomShareMatchPage } from './room-share-match/room-share-match.page';
import { TripDetailPage } from './trip-detail/trip-detail.page';
import { TripEditPage } from './trip-edit/trip-edit.page';
import { TripInterestedPage } from './trip-interested/trip-interested.page';
import { TripRegisterPage } from './trip-register/trip-register.page';
import { TripsRoutingModule } from './trips-routing.module';

@NgModule({
  imports: [TripsRoutingModule, SharedModule],
  declarations: [RoomShareEditPage, RoomShareListPage, RoomShareManagePage, RoomShareMatchPage, RoomShareProfileComponent, TripDetailPage, TripDetailHeaderComponent, TripEditPage, TripInterestedPage, TripRegisterPage]
})
export class TripsModule {}
