import { Injectable, OnDestroy } from '@angular/core';
import { IActivity } from '@shared/models/activity/activity';
import { ILastRead } from '@shared/models/last-read';
import { BaseDatabase } from '@shared/services/base.database';
import { BaseRTDatabase } from '@shared/services/base.rtdatabase';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActivityRTDatabase extends BaseRTDatabase {
  createActivity(activity: IActivity) {
    return this.createObject(this.LIST.ACTIVITY, activity);
  }

  getActivityFeed(memberId: string): Observable<IActivity[]> {
    return this.getList(this.LIST.FEED, memberId);
  }

  getActivityLastRead(memberId: string): Observable<number> {
    return this.getObject<ILastRead>(this.LIST.LAST_READ, memberId).pipe(map(x => (x ? x.activity : 0)));
  }

  getActivityLastUpdated(memberId: string): Observable<number> {
    return this.getObjectsByQuery<IActivity>(`${this.LIST.FEED}/${memberId}`, ref => ref.limitToLast(1)).pipe(map(x => (x.length > 0 ? x[0].timestamp : 0)));
  }

  updateActivityLastRead(memberId: string, timestamp: number): Promise<void> {
    return this.updateObject(this.LIST.LAST_READ, memberId, { activity: timestamp });
  }
}
