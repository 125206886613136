import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UIService } from '@shared/services/ui.service';

@Component({
  selector: 'chirpy-page-header',
  templateUrl: './chirpy-page-header.component.html',
  styleUrls: ['./chirpy-page-header.component.scss']
})
export class ChirpyPageHeaderComponent {
  @Output() backButtonClick = new EventEmitter<any>();
  @Input() backButtonIcon = 'arrow-back';
  @Input() color = 'heading';
  @Input() defaultHref = '/';
  @Output() editButtonClick = new EventEmitter<any>();
  @Input() hasEditButton = false;
  @Input() shortTitle = '';
  @Input() showBackButton = true;
  @Input() title: string;

  get hasCustomBackButton() {
    return this.backButtonClick.observers.length > 0;
  }

  constructor(public uiService: UIService) {}

  onBackButton($event) {
    this.backButtonClick.emit($event);
  }

  onEditButton($event) {
    this.editButtonClick.emit($event);
  }
}
