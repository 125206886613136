import { Country } from '@shared/constants/country';
import { Gender } from '@shared/constants/gender';
import { GroupType } from '@shared/constants/group-type';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { Role } from '@infrastructure/constants/role';
import { IConstants } from '../constants';

// Constants for WeLoveIndoorPlants
export const PlantsConstants: IConstants = {
  ACCOUNT: {
    CANCEL: {
      enabled: true,
      instructions: `Select an option below. Please note, WeLoveIndoorPlants has a No Refund policy.`,
      reasons: [`The community is not active enough`, `There are no Plant Social groups in my area`, `The care guide library did not cover what I wanted to know`, `The web app is hard to use`, `I am no longer interested in indoor plants`],
      showExtraInstructions: `We're sorry to see you go. To help us continually improve WeLoveIndoorPlants, we'd appreciate knowing why you're cancelling your membership.`,
      supportRequest: `I would like WeLoveIndoorPlants Support to contact me about changing to a different subscription plan.`,
      yesNoQuestion: `Did you participate in the international chat groups?`
    }
  },
  ADVERTISERS: {
    branding: `Retailers`,
    enabled: true,
    headerText: ``,

    noAdvertisersMessage: `<p>Sorry, there are no retailers at present.</p>`,
    ANNOUNCEMENTS: {
      branding: `Promotion`,
      pageHeading: `Edit promotion`,
      titlePlaceholder: `Enter a title for your promotion`
    },
    DETAIL: {
      addAnnouncementButton: `Add promotion`,
      disclaimer: ``,
      doesNotExist: `Retailer does not exist`,
      noAnnouncements: `This retailer has not announced any promotions yet.`,
      pageHeading: `View retailer details`,
      placeholder: `Click the Update button in the header to start adding some information about your business`
    },
    EDIT: {
      contactPlaceholder: `Enter your website or email address`,
      descriptionPlaceholder: `Enter your text here`,
      displayNamePlaceholder: `Enter your business name here`,
      loadingErrorMessage: `Cannot load data. Please try again later, or contact support.`,
      pageHeading: `Update Retailer`,
      permissionErrorMessage: `Sorry, you don't have permission to edit this page`,
      regionInstructions: `Select the area you sell to, or where most of your clients/customers come from`
    },
    SEARCH: {
      heading: `Search retailers`,
      namePlaceholder: `Enter a retailer name...`
    },
    SERVICE: {
      deleteHeader: `Delete retailer`,
      deleteMessage: `This will permanently delete this retailer listing. Are you sure?`,
      deletedAnnouncementToast: `Promotion has been deleted.`,
      deletedToast: `Retailer has been deleted.`,
      updatedToast: `Retailer has been updated.`
    }
  },
  AGE_RANGES: [],
  APP: {
    allowOtherCountries: true,
    altText: `WeLoveIndoorPlants logo`,
    countries: {
      Australia: Country.AUSTRALIA,
      Canada: Country.CANADA,
      Ireland: Country.IRELAND,
      'New Zealand': Country.NEW_ZEALAND,
      'United Kingdom': Country.UNITED_KINGDOM,
      'United States': Country.UNITED_STATES
    },
    logo: `/assets/plants/header-logo.svg`
  },
  ASSETS: {
    BADGES: {
      icon: {
        founder: {
          description: `Founding member`,
          src: `/assets/plants/founder-badge.svg`,
          title: `Founding member`
        }
      },
      role: {
        [Role.ADMIN]: {
          src: ``,
          title: ``
        },
        [Role.ADVISOR]: {
          src: ``,
          title: ``
        },
        [Role.COHOST]: {
          src: `/assets/plants/cohost-icon.svg`,
          title: `WeLoveIndoorPlants co-host`
        },
        [Role.HOST]: {
          src: `/assets/plants/host-icon.svg`,
          title: `WeLoveIndoorPlants host`
        },
        [Role.MEMBER]: {
          src: ``,
          title: ``
        }
      }
    },
    defaultGroupImage: `/assets/plants/plants-icon.png`,
    defaultImage: `/assets/plants/plants-icon.png`,
    placeholderImage: `/assets/plants/plants-icon.png`
  },
  CATCHUPS: {
    branding: `catch-up`,
    CLAIM: {
      disclaimer: `By filling in this form, you are declaring that the information you have provided is true and correct. We will perform random checks to ensure coin claims are genuine. Any member found to be falsely claiming catch-up coins will forfeit their entire coins balance.`,
      excludeRoles: [],
      excludeRoleMessages: {},
      instructions: `<p>To claim coins for attending a catch-up, you must:</p>
                  <ul>
                    <li>Have RSVPed before the catch-up</li>
                    <li>Attend the catch-up</li>
                    <li>Claim your coins within two days of attending the catch-up</li>
                  </ul>
                <p>You can claim coins for a maximum of 10 catch-ups per calendar month.</p>`,
      noCatchups: `Sorry, there are no catch-ups that you can claim coins for.`,
      PERIOD: {
        first: 0,
        second: 0
      }
    },
    DETAIL: {
      disclaimer: `<p>We sometimes publish catch-up photos in our newsletters and occasional digital marketing. If you do not consent to your image being published please exclude yourself from any group photos.</p>`,
      noMessages: `No messages yet`,
      underReview: `Please check that all information entered is correct before you approve this catch-up.`
    },
    EDIT: {
      disclaimer: `WeLoveIndoorPlants reserves the right not to publish any events or listings that it feels are not suitable or appropriate for the WeLoveIndoorPlants community. All events and listings are reviewed by a member of the WeLoveIndoorPlants team before they are published.`,
      noResultsText: `Catch-ups cannot be shared with groups which already have a catch-up on the same day.`,
      populateDescription: `All WeLoveIndoorPlants members welcome!

Come along and socialise with your WeLoveIndoorPlants host and other members.

RSVP is essential
To RSVP, just click on the 'RSVP' button.

Can’t make it?
If you’ve RSVPd but need to cancel, please let us know so we can manage the table booking. You can click on the RSVP button to un-attend.

We sometimes publish member photos on our social media and in occasional digital marketing. If you do not consent to your image being published please exclude yourself from any group photos.

`,
      selectDateFirstMessage: `Please select a date first, so we can check for potential clashes.`
    },
    LIST: {
      isSearchEnabled: true,
      title: `Events`
    },
    TEMPLATE: {
      instructions: `<p>Enter as many or as few fields as you need to facilitate creating catch-ups. The only required field is the template name.</p>
      <p>The changes you make to this template will not affect existing catch-ups.</p>`
    }
  },
  COINS: {
    branding: `coins`,
    earnMoreButton: `Boost Balance`,
    earnMoreCallToAction: `Discover more ways to boost your Coins balance`,
    enabled: true,
    levels: [
      {
        icon: `/assets/plants/coins/blue-icon.svg`,
        name: `blue`,
        order: 0,
        threshold: 0
      },
      {
        icon: `/assets/plants/coins/silver-icon.svg`,
        name: `silver`,
        order: 1,
        threshold: 3000
      },
      {
        icon: `/assets/plants/coins/gold-icon.svg`,
        name: `gold`,
        order: 2,
        threshold: 6000
      },
      {
        icon: `/assets/plants/coins/platinum-icon.svg`,
        name: `platinum`,
        order: 3,
        threshold: 12000
      }
    ],
    levelSuffix: ` Level`,
    nextLevelMessage: `get to your`,
    retainLevelMessage: `retain your`,
    showLevelsFrom: -1,
    site: `WeLoveIndoorPlants`,
    title: `Coins`
  },
  CONTACT_US: {
    emailSubject: `WeLoveIndoorPlants Contact Us`,
    footerText: `We’ll get back to you shortly to help with your enquiry.`
  },
  EVENTS: {
    CLAIM: {
      disclaimer: ``,
      instructions: ``,
      noCatchups: ``
    },
    DETAIL: {
      disclaimer: `<p>We sometimes publish Event photos in our newsletters and occasional digital marketing. If you do not consent to your image being published please exclude yourself from any group photos.</p>`,
      title: `Detail title`,
      underReview: `Please check that all information entered is correct before you approve this Event.`
    },
    EDIT: {
      addressDisclaimer: ``,
      createButtonText: `Create`,
      createPageHeading: `Create an Event`,
      disclaimer: `WeLoveIndoorPlants reserves the right not to publish any events or listings that it feels are not suitable or appropriate for the WeLoveIndoorPlants community. All events and listings are reviewed by a member of the WeLoveIndoorPlants team before they are published.`,
      mapInstructions: ``,
      updateButtonText: `Update`,
      updatePageHeading: `Update Event`
    },
    INTERESTED: {
      title: `Interested title`
    },
    LIST: {
      isEventSubmissionEnabled: true,
      isSearchEnabled: true,
      title: `Events`
    },
    REGISTER: {
      instructions: ``,
      subject: ``,
      successMessage: ``
    },
    SEARCH: {
      heading: `Search Events`
    }
  },
  GENDERS: [
    {
      name: 'She/Her',
      value: Gender.SHE_HER,
      avatarUrl: `/assets/plants/gender/female-icon.svg`
    },
    {
      name: 'He/Him',
      value: Gender.HE_HIM,
      avatarUrl: `/assets/plants/gender/male-icon.svg`
    },
    {
      name: 'They/Them',
      value: Gender.THEY_THEM,
      avatarUrl: `/assets/plants/gender/no-gender-icon.svg`,
      default: 'true'
    }
  ],
  GROUPS: {
    disableMemberJoinedNotifyHost: true,
    CHIT_CHAT: {
      branding: `Chat`,
      noMessages: `No-one's said anything yet. Why not get the ball rolling? Post a message at the bottom of the screen.`
    },
    DETAIL: {
      catchupsHeading: `Catchups`,
      disclaimer: `We sometimes publish member photos on our social media channels and in occasional digital marketing. If you do not consent to your image being published please exclude yourself from any group photos.`,
      noSocialsMessage: `If you would like to organise an event in your area, click the button below, enter the details and share it with your local Plant Social groups.`,
      socialsButton: `Create an event`,
      socialsHeading: `Events`
    },
    EDIT: {
      createButtonText: `Create`,
      createPageHeading: `Create a group`,
      hasAdvisors: false,
      hasCohosts: true,
      updateButtonText: `Update`,
      updatePageHeading: `Update group`
    },
    GROUP_TYPES: [
      {
        name: 'Hidden groups',
        groupType: GroupType.HIDDEN_GROUP,
        hidden: true,
        protected: true
      },
      {
        name: 'Plant Social Groups',
        groupType: GroupType.GROUP,
        hidden: false,
        protected: false
      },
      {
        name: 'Chat Groups',
        groupType: GroupType.SPECIAL_INTEREST_GROUP,
        hidden: false,
        protected: false
      }
    ],
    LIST: {
      headerText: `<p>Welcome to WeLoveIndoorPlants Groups! Join a group to start chatting online with other members.</p>
      <p>Use the search form or browse the list to find a group that interests you. Click on a group for more details. To participate in the group chat, click the JOIN THIS GROUP button. You can join as many groups as you like.</p>`,
      mapHeaderText: null,
      noGroupsMessage: {
        [GroupType.GROUP]: `<p>Sorry, no plant social groups match your search criteria. If you'd like to start a new group, please <a target="_blank" href="/pages/create-group">fill out this form</a>.</p>`,
        [GroupType.SPECIAL_INTEREST_GROUP]: `<p>Sorry, no chat groups match your search criteria. If you'd like to suggest a new group, please <a target="_blank" href="/contact-us">contact support</a>.</p>`
      }
    },
    NOTIFICATIONS: {
      instructions: `
      <li>When something new is posted in this group, we send you a notification.</li>
      <li>You can choose whether to receive an email straight away, or have it included in the "Daily Digest" email with all other notifications from the past 24 hours.</li>
      <li>Use the toggles below to select your preferences, then click "Save Changes".</li>
      <li>You can change settings for other notifications on the <a href="/account/notifications">Manage notifications</a> page, and you can see your notifications at any time by going to your <a href="/my-greenhouse/activity">activity feed</a>.
      `
    },
    SEARCH: {
      heading: `Search Groups`,
      sigLabel: `Chat groups`
    }
  },
  LOGIN: {
    logo: `/assets/plants/we-love-indoor-plants-logo.svg`,
    heading: `Member Login`,
    text: ``,
    footerLogo: ``
  },
  MARKETPLACE: {
    branding: `Marketplace`,
    enabled: true,
    disclaimer: `<p> Marketplace is an online market exclusively available to WeLoveIndoorPlants members. You may use it to sell something in any of the listed categories, as long as it complies with the following rules.</p>
    <ul><li>You must be the legal owner of the item(s) you are selling.</li>
    <li> You may not sell items that require special sales permits or are illegal (for example, counterfeit items, smuggled plants, alcohol, drugs, tobacco, fireworks, or firearms), intimate apparel, offensive items, event tickets, or digital currency.</li>
    <li> If you run an online store through your own business website, you may not list items here that are for sale on your website. Items you are selling privately, or that are also listed on Facebook Marketplace or TradeMe, are permitted.</li>
    <li> If you are a business with a physical shop, you may not list items here that are for sale on your premises. However, we will have partnership options available for large commercial operators. Please get in touch to find out more.</li>
    <li> You may not purchase plants from an overseas seller if it breaches your country’s biosecurity laws. </li>
    <li> You can not include a private phone number or email address in your listing. To protect your privacy, all correspondence must be completed via WeLoveIndoorPlants Messages.</li></ul>
    <p> WeLoveIndoorPlants reserves the right to remove any listing that breaches these rules or any other listing that it deems unsuitable. WeLoveIndoorPlants does not take any commission on, or get involved in, any arrangements that you make between yourself and another member. If you receive a WeLoveIndoorPlants Message that is not appropriate to your listing, block and report the sender by clicking the BLOCK/REPORT button at the top of the WeLoveIndoorPlants Messages screen. Don't give out any personal information such as your home address, email address or phone number unless it is necessary to complete the transaction.</p>`,
    DETAIL: {
      codeOfConductWarning: `By contacting a member regarding their WeLoveIndoorPlants Marketplace listing you are agreeing to abide by the <a href="https://weloveindoorplants.com/code-of-conduct" target="_blank">Code of Conduct</a>`,
      doesNotExist: `Listing does not exist`,
      pageHeading: `View WeLoveIndoorPlants Marketplace Listing`,
      underReview: `This listing is being reviewed by WeLoveIndoorPlants Support, it is not available to members yet.`
    },
    EDIT: {
      codeOfConductUrl: `https://weloveindoorplants.com/code-of-conduct`,
      createButtonText: `Add Listing`,
      createPageHeading: `Add a listing`,
      descriptionPlaceholder: `Describe what you're selling`,
      locationPlaceholder: `Where you're based`,
      locationWarning: `To protect your privacy, please do not put your street address here.`,
      maxPhotos: 3,
      showGenderFilter: false,
      terms: [
        {
          id: `disclaimer`,
          required: true,
          title: `I have read the WeLoveIndoorPlants Marketplace disclaimer below`
        },
        {
          id: `codeOfConduct`,
          required: true,
          title: `I will abide by the <a href="https://weloveindoorplants.com/code-of-conduct" target="_blank" class="checkbox-label-link">Code of Conduct</a>`
        }
      ],
      termsHeading: `TERMS AND CONDITIONS`,
      titlePlaceholder: `e.g., Cuttings, plants, ceramic planters...`,
      updateButtonText: `Update Listing`,
      updatePageHeading: `Update listing`,
      updateMemberPageHeading: `Update listing for `
    },
    GENDERS: [Gender.HE_HIM, Gender.SHE_HER, Gender.THEY_THEM],
    INTRO: {
      callToAction: `What do you want to do?`,
      pageHeading: `WeLoveIndoorPlants Marketplace`,
      pageIntro: `<p> Marketplace is an online market exclusive to WeLoveIndoorPlants members where you can buy and sell planty things. To list an item, click the Sell Something button below. If you’re in a shopping mood, click the Looking For Something To Buy button to browse the listings.
      You can use Marketplace to buy and sell things like cuttings, plants, bulbs, seeds, planters, macrame hangers, plant growing accessories and more! You can also buy and sell services like plant-sitting and propagation classes. Best of all, there are no success fees!<p>`,
      pageTitle: `Welcome to WeLoveIndoorPlants Marketplace`,
      options: [
        {
          heading: `Look for something to buy`,
          routerLink: `/marketplace`,
          text: `Discover what WeLoveIndoorPlants members are selling`
        },
        {
          heading: `Sell something`,
          routerLink: `/marketplace/update/new`,
          text: `List something that you'd like to sell`
        }
      ]
    },
    LIST: {
      pageHeading: `Marketplace`,
      noRecordsFound: `No listings found matching your criteria.`
    },
    SEARCH: {
      categoryPlaceholder: `Search categories...`,
      pageHeading: `Search Listings`
    },
    SERVICE: {
      deleteHeader: `Delete listing`,
      deleteMessage: `This will permanently delete this listing. Are you sure?`,
      deletedToast: `WeLoveIndoorPlants Marketplace listing has been deleted.`,
      hiddenToast: `Your listing has been hidden.`,
      updatedProfileToast: `Listing has been `,
      publishApproved: `Your listing has been published, it is now visible to all members.`,
      publishUnapproved: `Your listing has been published. It will be displayed to other members when an admin has reviewed and approved your listing.`
    }
  },
  MEDIA: {
    branding: `Planty Pix`,
    quotas: {
      [MediaCollectionType.GROUP]: 50,
      [MediaCollectionType.MEMBER]: 10,
      [MediaCollectionType.PUBLIC]: -1
    }
  },
  MEETING_PLACE: {
    instructions: ``,
    pageHeading: `Plant Pals`,
    searchHeading: `Search Members`
  },
  MESSAGES: {
    CONTACTS: {
      LIST: {
        onboardingMessage: `You haven't added any contacts yet.\nClick the green + button below to add a WeLoveIndoorPlants member to your contacts list.`
      }
    },
    MORE: {
      ARCHIVE: {
        button: 'Archive',
        header: `Archive messages`,
        message: `Select a date to archive all threads where the last message was received before this date`,
        onboardingMessage: `You haven't archived any messages yet.`
      }
    },
    THREADS: {
      DETAIL: {
        isBlockedByMessage: `You cannot reply to this member because they have blocked you.`,
        isBlockedMessage: `You cannot reply to this member because you have blocked them.`,
        isSoleMemberMessage: `You cannot reply to this person because they are no longer a member of WeLoveIndoorPlants.`,
        moderatedMessage: `This message has been moderated by WeLoveIndoorPlants Support`
      },
      LIST: {
        onboardingMessage: `You haven't received any messages yet.\nClick the green + button below to send a message to another WeLoveIndoorPlants member.`
      }
    }
  },
  MY_CHIRPY: {
    tabs: {
      mine: {
        title: `My Greenhouse`,
        name: `home`
      },
      news: {
        title: `News`,
        name: `paper`
      },
      activity: {
        title: `Activity`,
        name: `film`
      }
    },
    iconLayout: `icon-top`,
    boxes: [
      {
        color: `primary`,
        isButtonEnabled: false,
        link: `/events`,
        placeholder: `RSVP to an event`,
        size: 12,
        title: `My Events`,
        type: `events`
      },
      {
        color: `secondary`,
        link: `/groups`,
        placeholder: `Join a group`,
        title: `My Groups`,
        type: `groups`
      },
      {
        color: `secondary`,
        link: `/marketplace/update/new`,
        placeholder: `Add a new Marketplace listing`,
        title: `Latest Marketplace listings`,
        type: `marketplace`
      },
      {
        color: `tertiary`,
        link: `/classifieds/update/new`,
        placeholder: `Add a new Classifieds listing`,
        title: `Latest Classifieds`,
        type: `share`
      }
    ]
  },
  NOTIFICATIONS: {
    headings: {
      Advertiser: 'Retailers',
      Catchup: 'Catch-ups',
      Country: 'WeLoveIndoorPlants News',
      Group: 'Groups',
      Member: 'Mentions',
      Message: 'Messages'
    },
    instructions: `
      <li>By default we send you a "Daily Digest" email once a day. This list all your notifications which have occurred for the previous 24 hours.</li>
      <li>If you do not wish to receive this Daily Digest, turn Digest to OFF with the toggle button below and click "Save Changes".</li>
      <li>However, if you wish to be notified immediately about WeLoveIndoorPlants messages and activities, use the settings on this page to turn on the notifications you are most interested in.</li>
      <li>And remember, you can check your notifications at any time by going to your <a href="/my-greenhouse/activity">activity feed</a>.
    `,
    settings: {
      Message: [
        {
          id: 'directMessage',
          title: 'Messages only to you',
          required: true,
          type: 'advanced'
        },
        {
          id: 'conversation',
          required: true,
          title: 'Messages to you and others',
          type: 'advanced'
        }
        // Too confusing to members to distinguish between MemberThreadType.Conversation and MemberThreadType.Group
      ],
      Group: [
        {
          id: 'catchupCreatedNotifyMember',
          required: true,
          title: 'New catch-up',
          type: 'advanced'
        },
        {
          id: 'newChitChat',
          required: true,
          title: 'New chat',
          type: 'advanced'
        },
        {
          id: 'newChitChatReply',
          required: true,
          title: `New reply in a conversation you're part of`,
          type: 'advanced'
        },
        {
          id: 'newNotice',
          required: true,
          title: 'New group notice',
          type: 'advanced'
        },
        {
          id: 'newMedia',
          isHidden: true,
          required: true,
          title: 'New group photo',
          type: 'advanced'
        },
        {
          id: 'newMediaComment',
          isHidden: true,
          required: true,
          title: 'New comment on group photo',
          type: 'advanced'
        },
        {
          id: 'socialCreatedNotifyGroup',
          isHidden: true,
          required: true,
          title: 'New Event',
          type: 'advanced'
        }
      ],
      Catchup: [
        {
          id: 'catchupCancelledNotifyMember',
          required: true,
          title: 'Catch-up cancelled'
        },
        {
          id: 'newCatchupNote',
          required: true,
          title: 'New note on a CatchUp'
        },
        {
          id: 'newCatchupReply',
          required: true,
          title: 'New reply to a note on CatchUp'
        }
      ],
      Country: [
        {
          id: 'newNews',
          required: true,
          title: 'WeLoveIndoorPlants News posted'
        }
      ],
      Advertiser: [
        {
          id: 'newAnnouncement',
          required: true,
          title: 'New announcement',
          type: 'advanced'
        }
      ]
    }
  },
  PROFILE: {
    DOB: {
      enabled: false,
      disclaimer: ``,
      icon: ``,
      label: ``,
      minAge: 0,
      maxAge: 110,
      placeholder: ``
    },
    genderLabel: `Pronouns`,
    INTERESTS: {
      enabled: true,
      icon: `heart`,
      label: `Interests`,
      placeholder: `Search for interests...`
    },
    onboardingMessage: `The following fields are mandatory and must be completed before you can start enjoying WeLoveIndoorPlants: <strong>Display Name, Pronouns, and Location</strong>.`,
    ORGANISATIONS: {
      enabled: false,
      icon: `contacts`,
      label: `Organisations`,
      placeholder: `Search for organisations...`
    },
    PHONE: {
      enabled: false,
      disclaimer: ``,
      icon: ``,
      label: ``,
      placeholder: ``
    },
    TITLE: {
      enabled: false,
      icon: ``,
      label: ``,
      placeholder: ``
    }
  },
  RESET_PASSWORD: {
    HEADING: {
      auto: `Set Password`,
      manual: `Reset Password`
    },
    logo: `/assets/plants/we-love-indoor-plants-logo.svg`,
    MESSAGE: {
      auto: `You need to set a password. Please check your email address, and click the button below. You will receive a link to create a password via email.`,
      manual: `Forgot your password? Please enter your email address. You will receive a link to create a new password via email.`
    }
  },
  ROMANCE: {
    enabled: false,
    DETAIL: {
      doesNotExist: ``,
      pageHeading: ``,
      underReview: ``
    },
    EDIT: {
      createButtonText: ``,
      createPageHeading: ``,
      disclaimer: ``,
      updateButtonText: ``,
      updatePageHeading: ``,
      updateMemberPageHeading: ``
    },
    GENDERS: [],
    INTRO: {
      alert: {
        button: ``,
        header: ``,
        message: ``
      },
      disclaimer: ``,
      ineligibleInstructions: ``,
      pageHeading: ``,
      pageIntro: ``,
      pageTitle: ``,
      pictureInstructions: ``,
      requireListing: false,
      submitButton: ``,
      submitInstructions: ``
    },
    LIST: {
      pageHeading: ``,
      noRecordsFound: ``
    },
    SEARCH: {
      pageHeading: ``
    },
    SERVICE: {
      branding: ``,
      deleteHeader: ``,
      deleteMessage: ``,
      deletedToast: ``,
      hiddenToast: ``,
      updatedProfileToast: ``,
      updatedMemberProfileToast: ``,
      publishApproved: ``,
      publishUnapproved: ``,
      reviewEmailSubject: ``
    }
  },
  SHARE: {
    branding: `Classified`,
    enabled: true,
    disclaimer: `WeLoveIndoorPlants Classifieds is exclusively available to WeLoveIndoorPlants members. You may use it to share something in any of the listed categories. WeLoveIndoorPlants does not take any commission on or get involved in any arrangements that you make between yourself and another member. To protect your privacy, please keep your correspondence within the WeLoveIndoorPlants platform until you are certain that the other party is trustworthy. If you receive a WeLoveIndoorPlants Message that is not appropriate to your listing, block and report the sender by clicking the BLOCK/REPORT button at the top of the WeLoveIndoorPlants Messages screen. Don't give out any personal information such as your home address, email address or phone number until it is necessary. WeLoveIndoorPlants reserves the right to limit the number of listings per member.`,
    DETAIL: {
      codeOfConductWarning: `By contacting a member regarding their Classified you are agreeing to abide by the <a href="https://weloveindoorplants.com/code-of-conduct" target="_blank">Code of Conduct</a>`,
      doesNotExist: `Classified does not exist`,
      pageHeading: `View Classified`,
      underReview: `This listing is being reviewed by WeLoveIndoorPlants Support, it is not available to members yet.`
    },
    EDIT: {
      codeOfConductUrl: `https://weloveindoorplants.com/code-of-conduct`,
      createButtonText: `Add Classified`,
      createPageHeading: `Add a classified`,
      descriptionPlaceholder: `Describe what you're offering, or what you're looking for`,
      locationPlaceholder: `Where you are, or where you're going`,
      locationWarning: `For your privacy please do not put your street address here.`,
      maxPhotos: 3,
      showGenderFilter: false,
      terms: [
        {
          id: `disclaimer`,
          required: true,
          title: `I have read the WeLoveIndoorPlants disclaimer below`
        },
        {
          id: `codeOfConduct`,
          required: true,
          title: `I will abide by the <a href="https://weloveindoorplants.com/code-of-conduct" target="_blank" class="checkbox-label-link">Code of Conduct</a>`
        }
      ],
      termsHeading: `TERMS AND CONDITIONS`,
      titlePlaceholder: `e.g. Swap a plant, share transport to an event, etc...`,
      updateButtonText: `Update Classified`,
      updatePageHeading: `Update classified`,
      updateMemberPageHeading: `Update classified for `
    },
    GENDERS: [Gender.HE_HIM, Gender.SHE_HER, Gender.THEY_THEM],
    INTRO: {
      callToAction: `What do you want to do?`,
      pageHeading: `Classifieds`,
      pageIntro: `<p> Classifieds is a sharing and swapping service exclusive to WeLoveIndoorPlants.</p>
      <p>If you have something to share or swap, click the List button below to create a listing. If you’d like to see what people have to offer, click the Search button to browse the listings.
      <p>You can use Classifieds to do things like share travel costs to a plant show, find someone to transport a plant purchase to you, teach a skill like terrarium or macrame hanger making, or arrange a plant swap with another member. Plus, it’s a fantastic way to make new planty friends!</p>`,
      pageTitle: `Welcome to Classifieds`,
      options: [
        {
          heading: `Find a Classified`,
          routerLink: `/classifieds`,
          text: `Discover what WeLoveIndoorPlants members want to share or swap with you`
        },
        {
          heading: `List a Classified`,
          routerLink: `/classifieds/update/new`,
          text: `List something that you'd like to share or swap with other WeLoveIndoorPlants members`
        }
      ]
    },
    LIST: {
      pageHeading: `Classifieds`,
      noRecordsFound: `No listings found matching your criteria.`
    },
    SEARCH: {
      pageHeading: `Search Listings`
    },
    SERVICE: {
      branding: `Classified Listing`,
      deleteHeader: `Delete listing`,
      deleteMessage: `This will permanently delete this listing. Are you sure?`,
      deletedToast: `Classified listing has been deleted.`,
      hiddenToast: `Your listing has been hidden.`,
      updatedProfileToast: `Listing has been `,
      publishApproved: `Your listing has been published, it is now visible to all members.`,
      publishUnapproved: `Your listing has been published. It will be displayed to other members when an admin has reviewed and approved your listing.`
    }
  },
  SOCIAL: {
    branding: `Event`,
    DETAIL: {
      title: `View event`,
      underReview: `This event is being reviewed by WeLoveIndoorPlants Support. It is not available for members yet.`
    },
    disclaimer: `We may publish event photos in our newsletters and occasional digital marketing. If you do not consent to your image being published please exclude yourself from any group photos.`,
    EDIT: {
      addressDisclaimer: `For the security of our members, events must happen in a public place.`,
      createButtonText: `Create`,
      createPageHeading: `Create an event`,
      mapInstructions: `Choose the nearest suburb or town to your event. We will use this to show a marker on the map.`,
      shareInstructions: `If you want to, you can advertise your event in your local Plant Social groups`,
      updateButtonText: `Update`,
      updatePageHeading: `Update event`
    },
    enabled: true,
    INTERESTED: {
      title: `Attendees list`
    },
    LIST: {
      headerText: `<p>Welcome to WeLoveIndoorPlants Events! Use the search form or browse the list or map to find an event near you</p>`
    },
    PAGE: {
      cantCreateMessage: `As a host or co-host you cannot create events. If you want to create an event for your group, please create a catch-up instead.`,
      createButtonLink: `/events/update/new`,
      createButtonText: `Create an event`,
      mineLabel: `My events`,
      shortName: `Event`,
      title: `Events`
    },
    SEARCH: {
      heading: `Search Events`
    }
  },
  TRIPS: {
    DETAIL: {
      draftNotice: ``,
      title: ``
    },
    REGISTER: {
      instructions: ``,
      subject: ``,
      successMessage: ``
    },
    ROOM_SHARE: {
      descriptionIntro: ``,
      disclaimer: ``,
      listIntro: ``,
      requirementMessage: ``
    },
    SECTIONS: []
  },
  WELCOME: {
    PASSWORD: {
      buttonColor: `primary`,
      pleaseWaitText: `<p> Welcome to WeLoveIndoorPlants! </p>
        <p> You’re just a couple of steps away from being able to participate in our group chats, plant marketplace, and more! </p>
        <p> Please hold tight while we create your account. This may take up to 30 seconds.</p>
        <p>While you wait, you can start thinking about what you’ll use as your WeLoveIndoorPlants username and password.</p>`,
      pleaseWaitImage: ``,
      spinner: ``,
      welcomeImage: ``,
      welcomeText: `<p>Hooray, your account has been created!</p>
              <p>Before you can use all of WeLoveIndoorPlants’s features, you’ll need to personalise a few things.</p>
              <p>The first step is to create a password.</p>
              <p>Once you’ve entered your password, click the Set Password button to continue.</p>`
    },
    SIGNUP: {
      buttonColor: `primary`,
      countLastStep: true,
      DISPLAY_NAME: {
        image: ``,
        instructions: `<p>Now you get to choose your display name, which will appear on your profile and next to any comments or messages you send. Most people use their first name followed by a letter, number, or location. For privacy reasons, do not use your phone number or email address.</p>
        <p>Once you’ve chosen your display name,  click the save button to continue.</p>`,
        label: `Enter a Display Name`,
        title: `Display Name`
      },
      GENDER: {
        image: ``,
        instructions: `<p>WeLoveIndoorPlants is a safe space for all our members. Being active in the community is the best way to get the most out of your membership.</p>
        <p>Let others know how you’d like them to refer to you.</p>`,
        label: `Pronouns`,
        title: `Pronouns`
      },
      GROUPS: {
        image: ``,
        INSTRUCTIONS: {
          all: `<p>Discuss all your favourite planty topics in our Chat Groups!</p>
                <p>You can join as many groups as you like, and leave or rejoin them at any time.</p>`,
          foundGroups: `<p>Here is the list of our current groups. Select the ones you’d like to join and click the Save button to proceed.</p>`,
          noGroups: `<p>Sorry, we can't automatically determine the closest groups to you. Please have a look on the Groups page after you've finished the signup wizard.</p>`
        },
        label: `Groups`,
        skip: `Continue without joining a group`,
        title: `Join a Group`
      },
      LOCATION: {
        image: ``,
        instructions: `<p>To make sure we are showing you the correct Chat groups and Marketplace categories, we need to know your general location. Don’t worry – for your security, we’ll never ask you for your full address!</p>
        <p>Select your state/region then search within the options available. Once you’ve selected your location click the Save button.</p>
        <p>Unfortunately, you won’t be able to proceed without selecting your location.</p>`,
        label: `Location`,
        title: `Location`
      },
      OPTIONAL: {
        image: ``,
        instructions: ``,
        title: ``,
        DOB: {
          disclaimer: ``,
          image: ``,
          instructions: ``,
          label: ``,
          minAge: 0,
          maxAge: 110,
          title: ``
        },
        PHONE: {
          disclaimer: ``,
          image: ``,
          instructions: ``,
          label: ``,
          title: ``
        }
      },
      progressColor: `primary`,
      showNavigation: false,
      steps: ['display-name', 'gender', 'location', 'whats-next'],
      useProgressBar: true,
      WHATS_NEXT: {
        buttonLink: '',
        buttonText: ``,
        footer: `<h3>Get Started!</h3>`,
        header: `<p>Fantastic, you're all set up and ready to go.</p>
        <p>What next?</p>
        <ul class="chirpy-spaced-list">
        <li>Head over to the <a href="/groups">Groups page</a> to explore our range of Chat groups. You can join as many groups as you like, and leave or rejoin them at any time.</li>
        <li>Explore the rest of our community – maybe sell or buy a plant in the <a href="/marketplace">Marketplace</a>!</li>
        <li>Be sure to check <a href="/my-greenhouse">My Greenhouse</a>. It's your dashboard where you can keep an eye on all your notifications and other information.</li>
        </ul>`,
        image: ``,
        options: [],
        title: 'All done!'
      }
    }
  }
};
