import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { FunRoutingModule } from './fun-routing.module';
import { WebcamsPage } from './webcams/webcams.page';

@NgModule({
  imports: [SharedModule, FunRoutingModule],
  declarations: [WebcamsPage]
})
export class FunModule {}
