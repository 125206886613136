import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Gender } from '@shared/constants/gender';
import { IListingImage } from '@shared/models/image/listing-image';
import { IShareListing } from '@shared/models/share/share-listing';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EnforceProfileService } from '@shared/services/enforce-profile.service';
import { ShareService } from '@shared/services/share/share.service';
import { UIService } from '@shared/services/ui.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ShareEditPresenter } from './share-edit.presenter';

@Component({
  selector: 'app-share-edit',
  styleUrls: ['./share-edit.component.scss'],
  templateUrl: './share-edit.component.html',
  viewProviders: [ShareEditPresenter]
})
export class ShareEditComponent implements OnInit {
  get CONSTANTS() {
    return this.constantsService.constants.SHARE.EDIT;
  }

  get DISCLAIMER() {
    return this.constantsService.constants.SHARE.disclaimer;
  }

  get form() {
    return this.shareEditPresenter.form;
  }

  get GENDERS() {
    return this.constantsService.constants.SHARE.GENDERS;
  }

  get isEdit(): boolean {
    return this.shareListing != null && this.shareListing.uid != null;
  }

  get SHARE_CATEGORIES$(): BehaviorSubject<string[]> {
    return this.shareService.shareCategories;
  }

  get SHARE_EXPIRY() {
    return this.shareService.getShareExpiry();
  }

  get terms() {
    return this.shareEditPresenter.form.controls.terms;
  }

  get title(): string {
    return this.isEdit ? this.CONSTANTS.updatePageHeading : this.CONSTANTS.createPageHeading;
  }

  get updateButtonText(): string {
    return this.isEdit ? this.CONSTANTS.updateButtonText : this.CONSTANTS.createButtonText;
  }

  canShowRenew: boolean = false;
  interfaceOptions: any = { cssClass: 'wide-select' };
  MAX_PHOTOS: number = 3;
  shareListing: IShareListing = {
    approved: false,
    categories: [],
    description: '',
    deleteDate: 0,
    expiry: 0,
    location: '',
    photos: {},
    published: false,
    startDate: 0,
    terms: {},
    title: ''
  } as IShareListing;
  showGenderFilter: boolean = false;
  user: UserObject;

  constructor(
    private authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private enforceProfileService: EnforceProfileService,
    public shareEditPresenter: ShareEditPresenter,
    private shareService: ShareService,
    private route: ActivatedRoute,
    private router: Router,
    private uiService: UIService
  ) {}

  getDateFormat(deleteDate: number) {
    return this.dateTimeService.getDateFormat(deleteDate);
  }

  getNumberOfRows() {
    // display textarea with 8 rows on tablets and bigger. this fits perfectly on iPad landscape, and within bounds of most desktop screens.
    // display textarea with 5 rows on mobile phones.
    return this.uiService.isMediumDisplay ? 8 : 5;
  }

  goBack() {
    this.router.navigate([`/share`]);
  }

  hideListing() {
    this.shareService.hideListing(this.shareListing.uid);
  }

  ionViewWillEnter() {
    this.enforceProfileService.enforce();
  }

  ionViewWillLeave() {
    // The ShareEditComponent with uid "new" is not destroyed when the form is submitted
    this.form.reset(); // this clears the form data
    this.shareListing.uid = null; // this clears the value of isEdit
  }

  ngOnInit() {
    const uid = this.route.snapshot.paramMap.get('id');
    this.showGenderFilter = this.CONSTANTS.showGenderFilter;
    this.MAX_PHOTOS = this.CONSTANTS.maxPhotos;
    this.shareEditPresenter.init(this.CONSTANTS.terms);
    this.authService._userProfileSubject.pipe(first(x => x != null)).subscribe(user => {
      this.user = user;

      // creating a new listing
      if ('new' === uid) {
        this.initialiseListing(user);
        return;
      }

      this.shareService
        .getShareListing(uid)
        .pipe(first())
        .subscribe((shareListing: IShareListing) => {
          // Shouldn't happen; if it does, create a new listing
          if (shareListing == null) {
            this.initialiseListing(user);
            return;
          }

          this.shareListing = shareListing;
          const THREE_DAYS_IN_MILLISECONDS = 3 * 24 * 60 * 60 * 1000;
          this.canShowRenew = this.shareListing.deleteDate > 0 && this.shareListing.deleteDate - this.dateTimeService.getDateTime() < THREE_DAYS_IN_MILLISECONDS;
          this.shareEditPresenter.setValue(this.shareListing);
        });
    });
  }

  publishListing() {
    this.shareService.publishListing(this.shareListing.uid, this.shareListing.approved);
  }

  updateShareListing() {
    // merge form values with shareListing
    let formValue = this.shareEditPresenter.shareListing();
    // Delete any removed images
    const oldKeys = Object.keys(this.shareListing.photos || {});
    let photosToRemove: IListingImage[] = [];
    if (oldKeys.length > 0) {
      const newKeys = Object.keys(formValue.photos || {});
      const keysToRemove = oldKeys.filter(x => !newKeys.includes(x));
      for (const key of keysToRemove) {
        photosToRemove.push(Object.assign({}, this.shareListing.photos[key]));
      }
    }

    Object.assign(this.shareListing, formValue);
    this.shareService.updateShareListing(this.user, this.shareListing, this.CONSTANTS.terms, photosToRemove);
  }

  private initialiseListing(user: UserObject) {
    this.shareListing.country = user.country;
    this.shareListing.gender = this.GENDERS;
    this.shareListing.location = user.locality;
    this.shareListing.memberId = user.uid;
    this.shareListing.memberName = user.displayName;
    this.shareListing.terms = this.CONSTANTS.terms.reduce((output, x) => {
      output[x.id] = false;
      return output;
    }, {});
    this.shareEditPresenter.setValue(this.shareListing);
  }
}
