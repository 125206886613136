import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IChirpyMemberListButton } from '@shared/components/chirpy-member-list/chirpy-member-list-button';
import { AdminRole } from '@shared/constants/admin-role';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { InterestStatus } from '@shared/constants/interest-status';
import { RoomShareMatchStatus } from '@shared/constants/room-share-match-status';
import { ITransformFunction } from '@shared/models/transform-function';
import { IRoomShareMatch } from '@shared/models/trips/room-share-match';
import { ITrip } from '@shared/models/trips/trip';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { GroupService } from '@shared/services/groups/group.service';
import { ConstantsService } from '@shared/services/constants.service';
import { RoomShareService } from '@shared/services/trips/room-share.service';
import { TripService } from '@shared/services/trips/trip.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { of, Subscription } from 'rxjs';
import { skipWhile, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-trip-interested',
  styleUrls: ['././trip-interested.page.scss'],
  templateUrl: './trip-interested.page.html'
})
export class TripInterestedPage implements OnInit, OnDestroy {
  booked: UserObject[];
  bookedButtons: IChirpyMemberListButton[];
  currentView: string = 'interested-view'; // If you change this, make sure to set isBookedView and isInterestedView correctly
  dataMap: Record<string, ITransformFunction> = {
    matches: data => data.map(x => `Room share ${x.status.toLowerCase()} with ${x.displayName}`).join(', ')
  };
  defaultHref: string = '/travel'; // This is set to /trip/:tripId below. In white-label/X-routing.module /travel is redirected to the Travel SIG or page
  interested: UserObject[];
  interestedButtons: IChirpyMemberListButton[];
  isBookedView: boolean = false;
  isInterestedView: boolean = true;
  matches: Record<string, Record<string, IRoomShareMatch[]>> = {};
  trip: ITrip;
  tripId: string;
  tripSubscription: Subscription;
  title: string;

  constructor(private authService: AuthService, private constantsService: ConstantsService, private groupService: GroupService, private roomShareService: RoomShareService, private tripService: TripService, private subscriptionService: SubscriptionService, private route: ActivatedRoute) {}

  initButtons(trip: ITrip = null, canManageGroup: boolean = false) {
    if (trip === null || !this.tripService.isClosed(trip)) {
      this.bookedButtons = [
        {
          canShow: member => this.authService.isAdmin([AdminRole.TRAVEL]) || canManageGroup,
          command: CatchupRsvpStatus.REMOVED,
          label: 'Remove'
        },
        {
          canShow: member => this.authService.isAdmin([AdminRole.TRAVEL]) || canManageGroup,
          command: InterestStatus.INTERESTED,
          label: 'Interested'
        }
      ];
      this.interestedButtons = [
        {
          canShow: member => this.authService.isAdmin([AdminRole.TRAVEL]) || canManageGroup,
          command: InterestStatus.NOT_INTERESTED,
          label: 'Remove'
        },
        {
          canShow: member => this.authService.isAdmin([AdminRole.TRAVEL]) || canManageGroup,
          command: CatchupRsvpStatus.GOING,
          label: 'Booked'
        }
      ];
    } else {
      this.bookedButtons = [];
      this.interestedButtons = [];
    }
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.tripSubscription);
  }

  ngOnInit() {
    // Set fallback title
    this.title = `Members`;

    this.initButtons();

    this.tripId = this.route.snapshot.paramMap.get('tripId');

    this.tripSubscription = this.tripService
      .getTrip(this.tripId)
      .pipe(
        switchMap(trip => {
          if (trip == null) return of({});

          this.trip = trip;
          this.defaultHref = `/trips/${trip.uid}`;
          this.title = `Members on ${trip.title}`;

          // display going/interested members
          this.tripService.getBookedMembers(this.trip.attendees).subscribe(booked => {
            if (booked == null) this.booked = [];
            else this.booked = booked;
          });
          this.tripService.getInterestedMembers(this.trip.interested).subscribe(interested => {
            if (interested == null) this.interested = [];
            else this.interested = interested;
          });

          return this.roomShareService.getRoomShareMatchesForTrip(this.trip.uid);
        }),
        switchMap(roomShareMatches => {
          if (roomShareMatches.displayNames) delete roomShareMatches.displayNames;
          if (roomShareMatches.uid) delete roomShareMatches.uid;

          const transformedMatches: Record<string, any> = {};
          for (const [uid, matches] of Object.entries(roomShareMatches)) {
            transformedMatches[uid] = { matches: Object.values(matches) } || {};
          }
          this.matches = transformedMatches;
          return this.trip && this.trip.groupId ? this.groupService.canManageGroup$(this.trip.groupId) : of(false);
        })
      )
      .subscribe(canManageGroup => {
        this.initButtons(this.trip || null, canManageGroup);
      });
    this.subscriptionService.add(this.tripSubscription);
  }

  onButtonClick({ member, command }): void {
    this.tripService.setStatus(this.trip.uid, member.uid, command, this.trip.tripGroupId);
  }

  onChangeView(event: any) {
    // Ideally call eventTrack with trip.title, but then have a race condition with loading trip data
    switch (event.detail.value) {
      case 'interested-view':
        this.isBookedView = false;
        this.isInterestedView = true;
        break;

      case 'booked-view':
      default:
        this.isBookedView = true;
        this.isInterestedView = false;
        break;
    }
  }
}
