import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ILocationInput } from './models/location-input';
@Injectable({
  providedIn: 'root'
})
export class SignupLocationPresenter {
  get region() {
    return this.form.controls['region'].value;
  }

  get suburbId() {
    return this.form.controls['suburbId'].value;
  }

  form = this.formBuilder.group({
    locality: [''],
    placeId: ['', Validators.required],
    region: ['', Validators.required],
    suburbId: [''],
    suburbName: ['']
  });

  constructor(private formBuilder: FormBuilder) {}

  patchValue(data: any) {
    this.form.patchValue(data);
  }

  setFormValue(data: ILocationInput): void {
    this.form.patchValue(data);
  }

  submit(): ILocationInput {
    return this.form.value;
  }
}
