import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AdvertiserService } from '@shared/services/advertisers/advertiser.service';
import { ConstantsService } from '@shared/services/constants.service';
import { ToastService } from '@shared/services/toast.service';
import { BaseAdminComponent } from '../base-admin/base-admin.component';

@Component({
  selector: 'app-remove-advertiser-access',
  templateUrl: '../base-admin/base-admin.component.html',
  styleUrls: ['../base-admin/base-admin.component.scss']
})
export class RemoveAdvertiserAccessComponent extends BaseAdminComponent {
  branding: string;

  constructor(private advertiserService: AdvertiserService, alertController: AlertController, private constantsService: ConstantsService, private router: Router, private toastService: ToastService) {
    super(alertController);
  }

  confirmMessage(memberId: string, memberName: string) {
    return `<p>Remove ${this.branding} access to ${memberName}?</p><p>THIS DELETES THEIR ${this.branding.toUpperCase()} LISTING IMMEDIATELY AND CANNOT BE UNDONE.</p>`;
  }

  selectMemberHandler({ memberId, memberName, data }) {
    let message = '';
    this.advertiserService
      .deleteAdvertiser(memberId)
      .then(result => {
        message = `${memberName} no longer has access to ${this.branding}.`;
      })
      .catch(err => {
        message = `Couldn't update ${this.branding} access for ${memberName}: ${err}`;
      })
      .finally(() => {
        this.toastService.presentToast(message);
        this.router.navigate(['/admin']);
      });
  }

  setup() {
    this.branding = this.constantsService.constants.ADVERTISERS.branding;

    this.confirmHeader = `Remove ${this.branding} access`;
    this.confirmButton = `Remove access`;
    this.placeholder = `Search for a member to remove ${this.branding} access from`;
    this.title = `Remove ${this.branding} access`;
  }
}
