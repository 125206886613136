import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Country } from '@shared/constants/country';
import { EventType } from '@infrastructure/constants/event-type';
import { TripSection } from '@shared/constants/trip-section';
import { ITrip } from '@shared/models/trips/trip';

@Injectable({
  providedIn: 'root'
})
export class TripEditPresenter {
  get trip() {
    let formValue = this.form.value as ITrip;
    // sharedGroups is a map of groupId, groupName pairs. We keep the actual value in sync on the component, but only show the groupNames on the form control
    // Remove it here to so that it doesn't overwrite the map when we do Object.assign
    if (formValue.sharedGroups) {
      delete formValue.sharedGroups;
    }

    return formValue;
  }

  form = this.formBuilder.group({
    address: ['', Validators.required],
    adminEmail: ['', Validators.required],
    costShare: new FormGroup({}),
    costSingle: new FormGroup({}),
    date: ['', Validators.required],
    endDate: ['', Validators.required],
    photos: [{}],
    sections: new FormGroup({}),
    sharedGroups: [{}],
    title: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}

  init(sections: TripSection[], countries: Country[]) {
    const sectionsGroup = this.form.controls.sections as FormGroup;
    // Dynamically add sections fields
    sections.forEach(section => {
      sectionsGroup.addControl(section, new FormControl(''));
    });

    const costShareGroup = this.form.controls.costShare as FormGroup;
    // Dynamically add sections fields
    countries.forEach(country => {
      costShareGroup.addControl(country, new FormControl(''));
    });

    const costSingleGroup = this.form.controls.costSingle as FormGroup;
    // Dynamically add sections fields
    countries.forEach(country => {
      costSingleGroup.addControl(country, new FormControl(''));
    });
  }

  setValue(trip: ITrip) {
    this.form.setValue({
      address: trip.address || '',
      adminEmail: trip.adminEmail || 'ALL',
      costShare: trip.costShare || {},
      costSingle: trip.costSingle || {},
      date: trip.date || '',
      endDate: trip.endDate || '',
      photos: Object.assign({}, trip.photos), // we want a copy, not a reference, so we can compare old vs new to see if images have been removed
      sections: trip.sections || {},
      sharedGroups: Object.values(trip.sharedGroups || {}).join(', '),
      title: trip.title
    });
  }
}
