import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '@shared/guards/admin-guard';
import { MembersGuard } from '@shared/guards/members-guard';
import { ConstantsService } from '@shared/services/constants.service';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthPageModule)
  },
  {
    path: 'catchups',
    loadChildren: () => import('./modules/catchups/catchups.module').then(m => m.CatchupsModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./modules/contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/errors/errors.module').then(m => m.ErrorsModule)
    // Does not need MembersGuard because these error pages don't show any content
  },
  {
    path: 'groups',
    loadChildren: () => import('./modules/groups/groups.module').then(m => m.GroupsModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'members',
    loadChildren: () => import('./modules/members/members.module').then(m => m.MembersModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'messages',
    loadChildren: () => import('./modules/messages/messages.module').then(m => m.MessagesPageModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'pages',
    loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'pix',
    loadChildren: () => import('./modules/pix/pix.module').then(m => m.PixModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'romance',
    loadChildren: () => import('./modules/romance/romance.module').then(m => m.RomanceModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'trips',
    loadChildren: () => import('./modules/trips/trips.module').then(m => m.TripsModule),
    canActivate: [MembersGuard]
  },
  {
    path: 'welcome',
    loadChildren: () => import('./modules/welcome/welcome.module').then(m => m.WelcomeModule)
    //Should not have MembersGuard because members are not logged in when they are redirected here from checkout
  }
];
/*
//Modules that are rebranded, and included in white-label routing file
Coins
Events
Dashboard,
Marketplace,
Meeting Place,
My Chirpy
Share,
Social
*/

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'ignore' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private constantsService: ConstantsService, private router: Router) {
    const disabledRoutes = [];
    if (!this.constantsService.constants.ROMANCE.enabled) disabledRoutes.push('romance');
    this.removeDisabledRoutes(disabledRoutes);
  }

  private removeDisabledRoutes(disabledRoutes) {
    const newRoutes = this.router.config.filter(c => !disabledRoutes.find(disabledRoute => disabledRoute === c.path));
    this.router.resetConfig(newRoutes);
  }
}
