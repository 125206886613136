import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MembershipStatus } from '@infrastructure/constants/membership-status';
import { AlertController } from '@ionic/angular';
import { AuthService } from '@shared/services/auth.service';
import { Observable, of } from 'rxjs';
import { map, skip, take } from 'rxjs/operators';

@Injectable()
export class MembersGuard implements CanActivate {
  constructor(private alertController: AlertController, private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  //Three scenarios:
  //1. User is already logged in, authService has already initialised, and userProfileObservable has a value
  //2. User not already logged in, logs in with valid credentials, userProfileObservable changes from null to UserObject
  //3. User not already logged in, logs in with invalid credentials or User logs out, userProfileObservable changes from null to null
  checkLogin(url: string) {
    //Scenario 1
    if (this.authService._userProfileSubject.value != null) {
      if (this.authService._userProfileSubject.value.membershipStatus !== MembershipStatus.PAUSED) {
        return of(true);
      } else if (this.authService._userProfileSubject.value.membershipStatus === MembershipStatus.PAUSED && url === '/account/settings') {
        return of(true);
      } else {
        this.alertController
          .create({
            backdropDismiss: false,
            header: 'Subscription paused',
            message: 'While your subscription is paused you can only access your account.',
            buttons: [
              {
                text: 'OK'
              }
            ]
          })
          .then(modal => {
            modal.present();
          });
        this.router.navigate(['/account']); //going to account causes infinite loop, because of memberGuard there
        return of(false);
      }
    }

    //Scenarios 2 and 3
    return this.authService.userProfileObservable.pipe(
      skip(1), //skip initial null value, because the underlying observable is a BehaviorSubject that is initialised with null
      map(user => {
        if (user != null) {
          if (this.authService._userProfileSubject.value.membershipStatus !== MembershipStatus.PAUSED) {
            return true;
          } else {
            this.router.navigate(['/account']);
            return false;
          }
        } else {
          this.authService.redirectToLogin(url);
          return false;
        }
      }),
      take(1) // stop listening to the observable
    );
  }
}
