import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { IMember } from '@shared/models/messages/member';
import { IMemberThread } from '@shared/models/messages/member-thread';
import { IMemberThreadType } from '@shared/models/messages/member-thread-type';
import { UserObject } from '@shared/models/user-object';
import { DateTimeService } from '@shared/services/date-time.service';
import { ContactService } from '@shared/services/messages/contact.service';
import { MessageHelperService } from '@shared/services/messages/message-helper.service';
import { MessageService } from '@shared/services/messages/message.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { VirtualCatchupService } from '@shared/services/virtual-catchups/virtual-catchup.service';
import { BehaviorSubject } from 'rxjs';
import { skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ThreadMembersService {
  members: IMember[] = [];

  constructor(
    private alertController: AlertController,
    private router: Router,
    private messageService: MessageService,
    private messageHelperService: MessageHelperService,
    private toastService: ToastService,
    private userService: UserService,
    private dateTimeService: DateTimeService,
    private contactService: ContactService,
    private virtualCatchupService: VirtualCatchupService
  ) {}

  get member() {
    return this.messageService.user;
  }

  addMemberToThread(memberThread: IMemberThread, memberId: string, memberName: string) {
    if (!this.messageHelperService.canAddMemberToThread(memberThread.type === IMemberThreadType.Group, this.members, memberId)) {
      return false;
    }

    if (memberThread.relatedIds.includes(memberId)) {
      return false;
    }

    const topPromises = [];
    const memberIds = memberThread.relatedIds.concat(...[memberId, this.member.uid]);

    // Add member to the Thread by adding memberId to the list of MemberThread.relatedIds
    const promises = [];
    const threadData = { memberIds };
    topPromises.push(
      this.messageService.updateThread(memberThread.threadId, threadData).then(() => {
        // Create MemberThread record for the new Member (for their own thread state)
        return this.messageService
          .addMemberToThread(memberThread.threadId, memberIds, memberId, memberThread.lastMessage, memberThread.relatedType, memberThread.name, memberThread.type)
          .then(() => {
            // Update existing MemberThreads with the new memberId (so other members can see the new member in their lists)
            this.updateMemberThreads(memberIds, memberThread, memberId, memberName, promises);
          })
          .then(() => Promise.all(promises))
          .then(() => {
            const threadType = memberThread.type === IMemberThreadType.Group ? 'group' : 'conversation';
            return this.toastService.presentToast(`${memberName} was added to the ${threadType}.`);
          });
      })
    );

    return Promise.all(topPromises);
  }

  displayRemoveMemberToast(memberThread: IMemberThread, memberName: string, isMe: boolean) {
    const threadType = memberThread.type === IMemberThreadType.Group ? 'group' : 'conversation';
    const message = isMe ? `You have left the ${threadType}.` : `${memberName} was removed from the ${threadType}.`;
    return this.toastService.presentToast(message);
  }

  getMemberThreadName(memberThread: IMemberThread, memberId: string, memberName: string, relatedIds: string[]) {
    if (memberThread.type === IMemberThreadType.Group) {
      return memberThread.name;
    }

    // Update thread name's for each user with semi colon delimited names
    const members: Record<string, string> = {};
    this.members.forEach(item => {
      members[item.memberId] = item.memberName;
    });
    members[memberId] = memberName; // this member doesn't exist on the thread yet

    return this.messageService.createThreadNameForMembers(relatedIds, members);
  }

  // Load user's displayNames from userprofile as they are not stored on the Thread or MemberThread.
  getMemberNamesInMemberThread(memberThread: IMemberThread) {
    const memberIds = [memberThread.memberId];
    if (memberThread.relatedIds != null) {
      memberIds.push(...memberThread.relatedIds);
    }

    const subject$ = new BehaviorSubject(null);
    this.userService
      .getUsers(memberIds)
      .pipe(skipWhile(u => !u))
      .subscribe((users: UserObject[]) => {
        if (users == null) {
          return;
        }

        const hasNullUser = users.some(value => value == null);
        if (hasNullUser) {
          // prevent the page from error'ing out on bad data
          if (console.error) {
            // log useful debugging information to Sentry
            const message = `/messages/threads/${memberThread.threadId} has a null user`;
            console.error(message, 'memberThreadId', memberThread.uid, 'memberIds.length', (memberIds || []).length, 'users.length', (users || []).length, 'memberIds', memberIds, 'users', users);
          }
          return;
        }

        const members: IMember[] = [];
        users.map((user: UserObject) => {
          members.push({ memberId: user.uid, memberName: user.displayName });
        });
        this.members = members;
        subject$.next(users);
      });
    return subject$;
  }

  // TODO: move the logic to filter yourself out of search results into the chirpy-search-member component.
  // don't show already added members or yourself in Search Results.
  filterMembersToAdd(searchResults: Record<string, string>) {
    const results: Record<string, string> = {};

    Object.keys(searchResults).forEach(uid => {
      const isAdded = this.members.length > 0 && this.members.some(r => r.memberId === uid);
      const isMe = uid === this.member.uid;
      if (!isAdded && !isMe) {
        results[uid] = searchResults[uid];
      }
    });

    return results;
  }

  filterMembersToRemove(searchResults: Record<string, string>) {
    if (Object.keys(searchResults).length === 0) {
      // show current members in thread when there's no search results
      return this.getThreadMembers();
    }

    const members: Record<string, string> = {};
    this.members.forEach(item => {
      const inSearchResults = searchResults[item.memberId] != null;
      const isMe = item.memberId === this.member.uid;
      if (inSearchResults && !isMe) {
        members[item.memberId] = item.memberName;
      }
    });

    return members;
  }

  getThreadMembers() {
    const members: Record<string, string> = {};
    this.members.forEach(item => {
      const isMe = item.memberId === this.member.uid;
      if (!isMe) {
        members[item.memberId] = item.memberName;
      }
    });

    return members;
  }

  async leaveConversation(memberThread: IMemberThread) {
    const threadType = memberThread.type === IMemberThreadType.Group ? 'group' : 'conversation';
    let message = `<p>Are you sure you want to leave the ${threadType}?</p><p>It will be removed from your message history and you won't be able to read it.</p>`;
    let header = `Leave ${threadType}`;
    let buttonText = 'Leave';

    if (memberThread.type === IMemberThreadType.DirectMessage) {
      message = `<p>Are you sure you want to hide your messages with ${memberThread.name}?</p><p>This will clear them from your Messages screen until ${memberThread.name} sends you a new message.</p>`;
      header = 'Hide Messages';
      buttonText = 'Hide';
    }

    const alert = await this.alertController.create({
      header,
      message,
      buttons: [
        {
          text: buttonText,
          handler: () => this.leaveConversationHandler(memberThread)
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  leaveConversationHandler(memberThread: IMemberThread) {
    return this.removeMemberFromThread(memberThread, this.messageService.user.uid, this.messageService.user.displayName);
  }

  removeMemberFromThread(memberThread: IMemberThread, memberId: string, memberName: string) {
    const isMe = memberId === this.messageService.user.uid;

    const leaveThread = () => {
      const memberIds = memberThread.relatedIds.concat(this.member.uid).filter(uid => uid !== memberId);

      // flag this Thread for deletion by a cleanup Cloud function when all Members have left.
      const canDeleteThread = memberIds.length === 0;
      const now = this.dateTimeService.getDateTime();
      const deleteDate = this.dateTimeService.addDays(now, 14);

      // Remove member from the Thread by removing memberId from the list of MemberThread.relatedIds
      const threadData = canDeleteThread ? { memberIds, deleteDate } : { memberIds };
      const promises = [];
      return this.messageService
        .updateThread(memberThread.threadId, threadData)
        .then(() => {
          return this.messageService.deleteMemberThread(memberThread.threadId, memberId);
        })
        .then(() => {
          if (canDeleteThread) {
            return this.virtualCatchupService.removeVirtualCatchupHandler(memberThread.threadId);
          }
        })
        .then(() => {
          // Remove this memberId from the existing MemberThreads
          this.updateMemberThreads(memberIds, memberThread, memberId, memberName, promises);
          return Promise.all(promises);
        })
        .then(() => {
          return this.displayRemoveMemberToast(memberThread, memberName, isMe);
        })
        .then(() => {
          if (isMe) {
            this.router.navigate(['/messages/threads']);
          }
        });
    };

    const archiveTwoPersonThread = () => {
      return (
        this.messageService
          // Archive the thread for the member that was removed
          .archiveMemberThread(memberThread.threadId, memberThread.relatedIds, memberThread.memberId)
          .then(() => this.displayRemoveMemberToast(memberThread, memberName, isMe))
          .then(() => {
            this.router.navigate(['/messages/threads']);
          })
      );
    };

    switch (memberThread.type) {
      case IMemberThreadType.Group: // Named group created by Admin/Host:
      case IMemberThreadType.Conversation: // Thread with >2 members (created by a member):
        // Members can leave, MemberThread is deleted, relatedIds updated
        return leaveThread();
      case IMemberThreadType.DirectMessage: // Thread with exactly 2 members (created by a member):
        // Either member can leave, MemberThread is archived, relatedIds are not updated, title doesn't change.
        return archiveTwoPersonThread();
    }

    return;
  }

  private updateMemberThreads(allMemberIds: string[], memberThread: IMemberThread, memberId: string, memberName: string, promises: any[]) {
    allMemberIds.forEach(uid => {
      const relatedIds = allMemberIds.filter(x => x !== uid);
      const name = this.getMemberThreadName(memberThread, memberId, memberName, relatedIds);
      const memberThreadData = memberThread.type === IMemberThreadType.Group ? { relatedIds } : { relatedIds, name };

      promises.push(this.messageService.updateMemberThread(memberThread.threadId, uid, memberThreadData));
    });
  }
}
