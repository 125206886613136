import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { AvatarSize } from '@shared/constants/avatar-size';
import { IAdvertiser } from '@shared/models/advertisers/advertiser';
import { IAnnouncement } from '@shared/models/advertisers/announcement';
import { UserObject } from '@shared/models/user-object';
import { AdvertiserService } from '@shared/services/advertisers/advertiser.service';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { MessageHelperService } from '@shared/services/messages/message-helper.service';
import { UserService } from '@shared/services/user/user.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'advertiser-detail',
  templateUrl: './advertiser-detail.page.html',
  styleUrls: ['./advertiser-detail.page.scss']
})
export class AdvertiserDetailPage implements OnInit {
  get CONSTANTS() {
    return this.constantsService.constants.ADVERTISERS.DETAIL;
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin([AdminRole.SUPER]);
  }

  get placeholderImage(): string {
    return this.constantsService.constants.ASSETS.placeholderImage;
  }

  get user(): UserObject | null {
    if (this.authService._userProfileSubject != null && this.authService._userProfileSubject.value != null) {
      return this.authService._userProfileSubject.value;
    }
    return null;
  }

  get userId(): string {
    return this.user ? this.user.uid : '';
  }

  advertiser$: Observable<IAdvertiser>;
  advertiserId: string;
  announcements$: Observable<IAnnouncement[]>;
  AVATAR_SIZE: AvatarSize = AvatarSize.LARGE;
  categoriesList: string;
  countrySuffix: string = '';
  errorMessage: string;
  isFollowing: boolean = false;
  placeholder: string;
  title: string;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private messageHelperService: MessageHelperService,
    private advertiserService: AdvertiserService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {}

  deleteAdvertiser() {
    this.advertiserService.deleteAdvertiser(this.advertiserId);
  }

  followAdvertiser() {
    this.analyticsService.eventTrack(AnalyticsCategory.ADVERTISERS, AnalyticsAction.ADVERTISERS_FOLLOW, this.title, { type: this.categoriesList });
    this.advertiserService.followAdvertiser(this.advertiserId, this.title, this.userId);
  }

  ngOnInit() {
    this.advertiserId = this.route.snapshot.paramMap.get('id');
    if (this.advertiserId == null) return;

    this.placeholder = this.CONSTANTS.placeholder;
    this.advertiser$ = this.advertiserService.getAdvertiser(this.advertiserId).pipe(
      tap(advertiser => {
        if (advertiser == null) {
          this.title = this.CONSTANTS.pageHeading;
          this.errorMessage = this.CONSTANTS.doesNotExist;
          return;
        }

        this.categoriesList = advertiser.categories.join(', ');
        this.countrySuffix = this.advertiserService.getCountrySuffix(advertiser);
        this.title = advertiser.displayName;
        this.isFollowing = this.user.advertiserIds && this.user.advertiserIds.includes(this.advertiserId);
        this.sendAnalytics(advertiser);
      })
    );

    this.announcements$ = this.advertiserService.getAnnouncements(this.advertiserId);
  }

  onSendMessage() {
    this.analyticsService.eventTrack(AnalyticsCategory.ADVERTISERS, AnalyticsAction.ADVERTISERS_SEND_MESSAGE, this.title, { type: this.categoriesList });
  }

  sendAnalytics(advertiser: IAdvertiser) {
    if (advertiser.uid === this.userId) {
      this.analyticsService.eventTrack(AnalyticsCategory.ADVERTISERS, AnalyticsAction.ADVERTISERS_VIEW_OWN_LISTING_DETAIL, this.title, { type: this.categoriesList });
    } else {
      this.analyticsService.eventTrack(AnalyticsCategory.ADVERTISERS, AnalyticsAction.ADVERTISERS_VIEW_LISTING_DETAIL, this.title, { type: this.categoriesList });
    }
  }

  unfollowAdvertiser() {
    this.analyticsService.eventTrack(AnalyticsCategory.ADVERTISERS, AnalyticsAction.ADVERTISERS_UNFOLLOW, this.title, { type: this.categoriesList });
    this.advertiserService.unfollowAdvertiser(this.advertiserId, this.title, this.userId);
  }
}
