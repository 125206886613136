import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MembersGuard } from '@shared/guards/members-guard';
import { MarketplaceDetailComponent } from './components/marketplace-detail/marketplace-detail.component';
import { MarketplaceEditComponent } from './components/marketplace-edit/marketplace-edit.component';
import { MarketplaceIntroPage } from './marketplace-intro/marketplace-intro.page';
import { MarketplacePage } from './marketplace.page';

export const routes: Routes = [
  {
    path: 'intro',
    component: MarketplaceIntroPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Marketplace intro'
    }
  },
  {
    path: '',
    component: MarketplacePage,
    data: {
      title: 'Marketplace listings'
    },
    children: [
      {
        path: 'update/:id',
        component: MarketplaceEditComponent,
        canActivate: [MembersGuard],
        data: {
          title: 'Update marketplace listing'
        }
      },
      {
        path: ':id',
        component: MarketplaceDetailComponent,
        canActivate: [MembersGuard],
        data: {
          title: 'Marketplace listing details'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MarketplaceRoutingModule {}
