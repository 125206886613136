import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { BaseDatabase } from '@shared/services/base.database';
import { CacheService } from '@shared/services/cache.service';

@Injectable({
  providedIn: 'root'
})
export class ImageDatabase extends BaseDatabase {
  constructor(afs: AngularFirestore, cache: CacheService, protected storage: AngularFireStorage) {
    super(afs, cache);
  }

  async uploadImage(file: any, filePath: string): Promise<string> {
    const task = await this.storage.upload(filePath, file);
    const fileRef = this.storage.ref(filePath);
    return fileRef.getDownloadURL().toPromise();
  }

  deleteImage(downloadURL: string) {
    return this.storage.storage.refFromURL(downloadURL).delete();
  }
}
