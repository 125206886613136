import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '@shared/services/constants.service';
import { ShareService } from '@shared/services/share/share.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { BaseApprovalComponent } from '../base-approval/base-approval.component';
import { BaseApprovalPresenter } from '../base-approval/base-approval.presenter';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-approve-share',
  styleUrls: ['../base-approval/base-approval.component.scss'],
  templateUrl: '../base-approval/base-approval.component.html'
})
export class ApproveShareComponent extends BaseApprovalComponent {
  approveButtonText = 'Approve';
  itemDetails = [
    {
      field: 'title',
      icon: '',
      type: 'heading'
    },
    {
      field: 'memberName',
      icon: 'person',
      type: 'memberWithBadges',
      uid: 'memberId'
    },
    {
      field: 'categories',
      icon: 'options',
      type: 'text'
    },
    {
      field: 'location',
      icon: 'pin',
      type: 'text'
    },
    {
      field: 'description',
      icon: '',
      type: 'long-text'
    },
    {
      field: 'photos',
      icon: '',
      type: 'photos'
    }
  ];
  items$: Observable<any[]>;
  routerPrefix: string;
  startSlot = 'avatar';
  title: string;

  constructor(baseApprovalPresenter: BaseApprovalPresenter, subscriptionService: SubscriptionService, toastService: ToastService, private shareService: ShareService) {
    super(baseApprovalPresenter, subscriptionService, toastService);
  }

  loadItems() {
    this.items$ = this.shareService.getShareListingsForApproval();
  }

  setRouterLinks() {
    this.routerPrefix = `/share`;
    this.title = `Approve Share listings`;
  }

  onApproveHandler(item: any) {
    this.shareService.approveShareListing(item.uid);
  }
}
