import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IRomanceListing } from '../../models/romance-listing';

@Injectable({
  providedIn: 'root'
})
export class RomanceEditPresenter {
  form = this.formBuilder.group({
    title: ['', Validators.required],
    seeking: ['', Validators.required],
    description: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}

  romanceListing() {
    return this.form.value as IRomanceListing;
  }

  setValue(romanceListing: IRomanceListing) {
    this.form.setValue({ title: romanceListing.title, seeking: romanceListing.seeking, description: romanceListing.description });
  }
}
