import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DefaultCatchupOptions, ICatchupOptions } from '@shared/models/catchups/catchup-options';
import { AuthService } from '@shared/services/auth.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';

@Injectable({
  providedIn: 'root'
})
export class CatchupSearchPresenter {
  defaultCatchupOptions: ICatchupOptions = DefaultCatchupOptions;
  form = this.formBuilder.group({
    country: [''],
    groupId: [''], // groupId and groupName are hidden parameters. They are set by the route of the CatchupListComponent, and cleared by clicking the criterion
    groupName: [''],
    region: [''],
    show: ['']
  });

  constructor(private formBuilder: FormBuilder, private catchupService: CatchupService, private authService: AuthService) {}

  reset() {
    this.setValue(this.defaultCatchupOptions);
  }

  search() {
    return this.form.value;
  }

  setValue(catchupOptions: ICatchupOptions) {
    this.form.setValue({
      country: catchupOptions.country || DefaultCatchupOptions.country,
      groupId: catchupOptions.groupId || DefaultCatchupOptions.groupId,
      groupName: catchupOptions.groupName || DefaultCatchupOptions.groupName,
      region: catchupOptions.region || DefaultCatchupOptions.region,
      show: catchupOptions.show || DefaultCatchupOptions.show
    });
  }
}
