import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AuthService } from '@shared/services/auth.service';
import { BaseAdminComponent } from '../base-admin/base-admin.component';

@Component({
  selector: 'app-impersonate',
  templateUrl: '../base-admin/base-admin.component.html',
  styleUrls: ['../base-admin/base-admin.component.scss']
})
export class ImpersonateComponent extends BaseAdminComponent {
  confirmHeader = 'Impersonate member';
  confirmButton = 'Impersonate';
  placeholder = 'Search for a member to impersonate';
  title = 'Impersonate member';

  constructor(alertController: AlertController, private router: Router, private authService: AuthService) {
    super(alertController);
  }

  confirmMessage(memberId: string, memberName: string) {
    return `<p>Impersonate ${memberName}?</p><p>You will browse the site as this person. Some actions will be disabled for you like sending messages.</p>`;
  }

  selectMemberHandler({ memberId, memberName, data }) {
    const ref = this.authService.impersonateMember(memberId).subscribe(() => {
      this.router.navigate(['/admin']).then(() => {
        ref.unsubscribe();
      });
    });
  }
}
