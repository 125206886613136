import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { CatchupClaimPage } from './catchup-claim/catchup-claim.page';
import { CatchupEditPage } from './catchup-edit/catchup-edit.page';
import { CatchupsRoutingModule } from './catchups-routing.module';
import { CatchupsPage } from './catchups.page';
import { CatchupTemplateEditPage } from './catchup-template-edit/catchup-template-edit.page';
import { CatchupTemplateListPage } from './catchup-template-list/catchup-template-list.page';
import { CatchupDescriptionComponent } from './components/catchup-detail/components/catchup-description/catchup-description.component';
import { CatchupAttendeesComponent } from './components/catchup-detail/components/catchup-attendees/catchup-attendees.component';
import { CatchupDetailHeaderComponent } from './components/catchup-detail/components/catchup-detail-header/catchup-detail-header.component';
import { CatchupDetailComponent } from './components/catchup-detail/catchup-detail.component';
import { CatchupGuestsComponent } from './components/catchup-detail/components/catchup-guests/catchup-guests.component';
import { CatchupMessagesComponent } from './components/catchup-detail/components/catchup-messages/catchup-messages.component';
import { CatchupPaymentsComponent } from './components/catchup-detail/components/catchup-payments/catchup-payments.component';
import { CatchupRsvpComponent } from './components/catchup-detail/components/catchup-rsvp/catchup-rsvp.component';
import { CatchupWaitlistComponent } from './components/catchup-detail/components/catchup-waitlist/catchup-waitlist.component';
import { CatchupListComponent } from './components/catchup-list/catchup-list.component';
import { CatchupSearchComponent } from './components/catchup-search/catchup-search.component';
import { VirtualCatchupPage } from './virtual-catchup/virtual-catchup.page';

@NgModule({
  imports: [CatchupsRoutingModule, SharedModule],
  declarations: [
    CatchupAttendeesComponent,
    CatchupClaimPage,
    CatchupEditPage,
    CatchupsPage,
    CatchupTemplateEditPage,
    CatchupTemplateListPage,
    CatchupDetailComponent,
    CatchupDetailHeaderComponent,
    CatchupDescriptionComponent,
    CatchupGuestsComponent,
    CatchupMessagesComponent,
    CatchupPaymentsComponent,
    CatchupRsvpComponent,
    CatchupWaitlistComponent,
    CatchupListComponent,
    CatchupSearchComponent,
    VirtualCatchupPage
  ],
  entryComponents: [CatchupSearchComponent]
})
export class CatchupsModule {}
