import { Component, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Country } from '@shared/constants/country';
import { ISocial } from '@shared/models/social/social';
import { IMapState } from '@shared/models/map-state';
import { IPlace } from '@shared/models/place';
import { IResultsModel } from '@shared/models/results-model';
import { ISearchModel } from '@shared/models/search-model';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { LocationService } from '@shared/services/location/location.service';
import { SocialService } from '@shared/services/social/social.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { UIService } from '@shared/services/ui.service';
import * as L from 'leaflet';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { SocialSearchComponent } from './components/social-search/social-search.component';

@Component({
  selector: 'app-socials',
  styleUrls: ['./social.page.scss'],
  templateUrl: './social.page.html',
  viewProviders: [SocialSearchComponent]
})
export class SocialPage implements OnInit, OnDestroy {
  allSocials: ISocial[] = [];
  analyticsCategory: AnalyticsCategory = AnalyticsCategory.SOCIAL;
  cantCreateSocials$: Observable<boolean>;
  CONSTANTS: any;
  country: Country;
  currentView: string = 'list-view';
  isListView: boolean = true;
  isMineView: boolean = false;
  mySocialsCreated$: Observable<ISocial[]>;
  mySocialsInterested$: Observable<ISocial[]>;
  noSocialsMessage: string = '';
  placeholders: string[] = [];
  places$: BehaviorSubject<IPlace[]> = new BehaviorSubject<IPlace[]>([]);
  showPlaceholders: boolean = true;
  socials$: BehaviorSubject<ISocial[]> = new BehaviorSubject<ISocial[]>([]);
  socialsSubscription: Subscription;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private locationService: LocationService,
    private socialService: SocialService,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private uiService: UIService
  ) {}

  ngOnInit() {
    this.cantCreateSocials$ = this.authService.isCohostOrHostOrAdmin$();

    // TODO: Is this pattern better than using a getter, in terms of change detection?
    this.CONSTANTS = this.constantsService.constants.SOCIAL.PAGE;

    // if default currentView changes, need to update this event too
    this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, AnalyticsAction.SOCIAL_VIEW_LIST);
    this.init();
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.socialsSubscription);
  }

  init() {
    this.authService._userProfileSubject
      .pipe(
        skipWhile(x => x == null),
        take(1)
      )
      .subscribe((profile: UserObject) => {
        this.country = this.authService.isAdmin() ? null : profile.country;
        this.loadMySocials(profile.uid);
        this.lookupSocials();
      });
  }

  loadMySocials(uid: string) {
    this.mySocialsCreated$ = this.socialService.getSocialsCreatedByMember(uid);
    this.mySocialsInterested$ = this.socialService.getSocialsOfInterestToMember(uid);
  }

  lookupSocials() {
    //Cancel subscription to observable with previous values
    this.subscriptionService.clearSubscription(this.socialsSubscription);
    this.socialsSubscription = this.socialService.getAllSocials(this.country).subscribe((results: ISocial[]) => {
      this.socials$.next(this.getUniqueSocials(results));
    });
    this.subscriptionService.add(this.socialsSubscription);
  }

  onChangeView(event: any) {
    switch (event.detail.value) {
      case 'list-view':
        this.isListView = true;
        this.isMineView = false;
        this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, AnalyticsAction.SOCIAL_VIEW_LIST);
        break;

      case 'mine-view':
      default:
        this.isListView = false;
        this.isMineView = true;
        this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, AnalyticsAction.SOCIAL_VIEW_MINE);
        break;
    }
  }

  private getUniqueSocials(socials: any[]) {
    //Put socials into key->value map. Any duplicates simply overwrite the earlier value. Convert back to an array
    const uniqueSocials = new Map(socials.map(social => [social.uid, social]));
    return Array.from(uniqueSocials.values());
  }

  private isNullOrEmpty(query: string) {
    return query == null || query.trim().length === 0;
  }
}
