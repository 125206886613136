import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '@shared/shared.module';
import { AccountRoutingModule } from './account-routing.module';
import { AccountPage } from './account.page';
import { CancelOfferPage } from './cancel-subscription/components/cancel-offer/cancel-offer.page';
import { CancelOptionPage } from './cancel-subscription/components/cancel-option/cancel-option.page';
import { CancelQuestionsPage } from './cancel-subscription/components/cancel-questions/cancel-questions.page';
import { ChangePasswordPage } from './change-password/change-password.page';
import { NotificationsPage } from './notifications/notifications.page';
import { AdvancedNotificationsPage } from './notifications/components/advanced/advanced.page';
import { SettingsPage } from './settings/settings.page';

@NgModule({
  imports: [AccountRoutingModule, CommonModule, FormsModule, IonicModule, SharedModule],
  declarations: [AccountPage, AdvancedNotificationsPage, CancelOfferPage, CancelOptionPage, CancelQuestionsPage, ChangePasswordPage, NotificationsPage, SettingsPage]
})
export class AccountModule {}
