import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { ChirpyLocatorMapComponent } from '@shared/components/chirpy-locator-map/chirpy-locator-map.component';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { MeetingPlaceMembersLocatorService } from './members-locator.service';

@Component({
  selector: 'meeting-place-members-locator',
  templateUrl: '../../../../../shared/components/chirpy-locator-map/chirpy-locator-map.component.html',
  styleUrls: ['../../../../../shared/components/chirpy-locator-map/chirpy-locator-map.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MeetingPlaceMembersLocatorComponent extends ChirpyLocatorMapComponent {
  constructor(analyticsService: AnalyticsService, authService: AuthService, ref: ChangeDetectorRef, subscriptionService: SubscriptionService, protected markerService: MeetingPlaceMembersLocatorService) {
    super(analyticsService, authService, ref, subscriptionService);
  }
}
