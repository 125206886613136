import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IGroup } from '@shared/models/groups/group';
import { INotice } from '@shared/models/groups/notice';
import { GroupService } from '@shared/services/groups/group.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'notices',
  templateUrl: './notices.component.html'
})
export class NoticesComponent {
  buttonLabel: string = 'Edit notices';
  @Input() canUpdateGroup: boolean;
  MAX_NOTICES: number = 2;
  noticeCount: string = '';
  notices$: Observable<INotice[]>;
  @Input() group: IGroup;
  @Input() isAdmin: boolean;
  @Input() isGroupMember: boolean;
  @Output() linkClicked = new EventEmitter();
  @Input() memberId: string;
  viewAllLabel: string = 'View all notices';

  constructor(private groupService: GroupService) {}

  ngOnInit() {
    this.notices$ = this.groupService.getGroupNotices(this.group.uid).pipe(
      map(notices => {
        this.noticeCount = notices.length > 0 ? `(${notices.length})` : '';
        return notices.slice(0, this.MAX_NOTICES);
      })
    );
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }
}
