import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Gender } from '@shared/constants/gender';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { RegionService } from '@shared/services/regions/region.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EnforceProfileService } from '@shared/services/enforce-profile.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UIService } from '@shared/services/ui.service';
import { UserService } from '@shared/services/user/user.service';
import { Subscription } from 'rxjs';
import { IRomanceListing } from '../../models/romance-listing';
import { RomanceService } from '../../services/romance.service';
import { RomanceEditPresenter } from './romance-edit.presenter';

@Component({
  selector: 'app-romance-edit',
  templateUrl: './romance-edit.component.html',
  viewProviders: [RomanceEditPresenter]
})
export class RomanceEditComponent implements OnInit, OnDestroy {
  get CONSTANTS() {
    return this.constantsService.constants.ROMANCE.EDIT;
  }

  get form() {
    return this.romanceEditPresenter.form;
  }

  get genders() {
    return this.constantsService.constants.ROMANCE.GENDERS;
  }

  get isEdit() {
    return this.romanceListing != null && this.romanceListing.uid != null;
  }

  get REQUIRE_LISTING() {
    return this.constantsService.constants.ROMANCE.INTRO.requireListing;
  }

  get title() {
    if (this.isEdit) {
      const isMine = this.authService._userProfileSubject.value != null && this.romanceListing.uid === this.authService._userProfileSubject.value.uid;
      return isMine ? this.CONSTANTS.updatePageHeading : `${this.CONSTANTS.updateMemberPageHeading} ${this.user.displayName}`;
    } else {
      return this.CONSTANTS.createPageHeading;
    }
  }

  get updateButtonText() {
    return this.isEdit ? this.CONSTANTS.updateButtonText : this.CONSTANTS.createButtonText;
  }

  readonly Gender = Gender;
  isAdmin: boolean = false;
  ownRomanceListingRef: Subscription;
  romanceListing: IRomanceListing = { uid: null, created: 0, published: false } as IRomanceListing;
  user: UserObject;

  constructor(
    private authService: AuthService,
    private regionService: RegionService,
    private constantsService: ConstantsService,
    private enforceProfileService: EnforceProfileService,
    private romanceEditPresenter: RomanceEditPresenter,
    private romanceService: RomanceService,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private uiService: UIService,
    private userService: UserService
  ) {}

  deleteListing() {
    this.romanceService.deleteRomanceListing(this.romanceListing);
  }

  getGenderValue(gender) {
    return this.romanceService.getGenderValue(gender);
  }

  getNumberOfRows() {
    // display textarea with 8 rows on tablets and bigger. this fits perfectly on iPad landscape, and within bounds of most desktop screens.
    // display textarea with 5 rows on mobile phones.
    return this.uiService.isMediumDisplay ? 8 : 5;
  }

  goBack() {
    this.romanceListing.uid ? this.router.navigate([`/romance`, this.romanceListing.uid]) : this.router.navigate([`/romance`]);
  }

  hideListing() {
    this.romanceService.hideListing(this.romanceListing.uid);
  }

  ionViewWillEnter() {
    this.enforceProfileService.enforce();
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.ownRomanceListingRef);
  }

  ngOnInit() {
    const uid = this.route.snapshot.paramMap.get('id');
    this.isAdmin = this.authService.isAdmin(); //TODO: what if authService hasn't loaded by this point? Though admins can delete from the romance-detail page anyway
    this.userService.getUserProfile(uid).subscribe(user => {
      if (user == null) return;
      this.user = user;

      this.romanceListing.location = user.locality;
      this.romanceListing.placeId = user.placeId;
      this.romanceListing.region = this.regionService.getRegionFromLocality(user.locality);

      this.ownRomanceListingRef = this.romanceService.getRomanceListing(uid).subscribe((romanceListing: IRomanceListing) => {
        // Doesn't exist, you're creating a new listing.
        if (romanceListing == null) return;

        this.romanceListing = romanceListing;

        // Update romance listing with the users latest location/placeId.
        this.romanceListing.location = user.locality;
        this.romanceListing.placeId = user.placeId;
        this.romanceListing.region = this.regionService.getRegionFromLocality(user.locality);

        this.romanceEditPresenter.setValue(this.romanceListing);
      });
      this.subscriptionService.add(this.ownRomanceListingRef);
    });
  }

  publishListing() {
    this.romanceService.publishListing(this.romanceListing.uid, this.romanceListing.approved);
  }

  updateRomanceListing() {
    const isGenderNull = this.user.gender == null;
    const isDateOfBirthNull = this.user.dateOfBirth == null || this.user.dateOfBirth.year == null;

    if (isGenderNull && !isDateOfBirthNull) {
      this.romanceService.presentSelectGenderPrompt(this.user.uid, this.updateRomanceListingHandler.bind(this));
      return;
    } else if (isDateOfBirthNull && !isGenderNull) {
      this.romanceService.presentEnterDateOfBirthPrompt(this.user.uid, this.updateRomanceListingHandler.bind(this));
      return;
    } else if (isGenderNull && isDateOfBirthNull) {
      this.romanceService.presentSelectGenderPrompt(this.user.uid, () => {
        this.romanceService.presentEnterDateOfBirthPrompt(this.user.uid, this.updateRomanceListingHandler.bind(this));
      });
      return;
    }

    return this.updateRomanceListingHandler();
  }

  private updateRomanceListingHandler(data: any = {}) {
    // Need to check this before saving the listing, since any changes to the listing propagate to isEdit, because of the active subscription
    const isCreating = !this.isEdit;

    // merge form values with romanceListing
    const formValue = this.romanceEditPresenter.romanceListing();
    Object.assign(this.romanceListing, formValue);
    if (Object.keys(data).length > 0) Object.assign(this.romanceListing, data);

    this.romanceService.updateRomanceListing(this.user, this.romanceListing);

    // Only request romance access here if
    // 1) Require Listing mode is active
    // 2) We are not editing an existing listing
    if (this.REQUIRE_LISTING && isCreating) {
      this.userService.requestRomanceAccess(this.user.uid);
    }
  }
}
