import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { IRelatedMembers } from '@shared/models/messages/related-members';

@Injectable({
  providedIn: 'root'
})
export class ContactDatabase {
  constructor(private afs: AngularFirestore) {}

  getContacts(uid) {
    return this.afs
      .collection<IRelatedMembers>('messagesContacts')
      .doc(uid)
      .valueChanges();
  }

  addOrUpdate(memberId: string, data: any) {
    // TODO: Handle exceptions?
    this.afs
      .collection<IRelatedMembers>('messagesContacts')
      .doc(memberId)
      .set(data, { merge: true });
  }

  updateMemberThread(memberId: string, relatedId: string, newData: any) {
    const queryFn = ref => ref.where('memberId', '==', memberId).where('relatedIds', '==', [relatedId]);
    const collection = this.afs.collection('messagesMemberThreads', queryFn);

    return collection
      .get()
      .toPromise()
      .then(response => {
        response.docs.forEach(doc => {
          return this.afs
            .collection('messagesMemberThreads')
            .doc(doc.id)
            .update({ ...newData });
        });
      });
  }
}
