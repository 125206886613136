import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CancelOptionPresenter {
  form = this.formBuilder.group({
    message: [''], // Only for support-request, and optional there
    option: ['', Validators.required],
    otherReason: [''], // Only required if the selected reason is Other
    reason: [''], // Not required for the support-request option
    yesNo: [''] // Not enabled for all sites
  });

  constructor(private formBuilder: FormBuilder) {}

  submit() {
    return this.form.value;
  }
}
