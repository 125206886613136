import { Injectable } from '@angular/core';
import { BaseDatabase } from '@shared/services/base.database';
import { Observable } from 'rxjs';
import { IPage } from '../../../pages/models/page'; // TODO move this to @shared/models?

@Injectable({
  providedIn: 'root'
})
export class CancelSubscriptionDatabase extends BaseDatabase {
  getPage(uid: string): Observable<IPage> {
    return this.getDocument<IPage>(this.COLLECTION.CONTENT_PAGES, uid);
  }

  updatePage(uid: string, data: any) {
    return this.updateDocument(this.COLLECTION.CONTENT_PAGES, uid, data);
  }
}
