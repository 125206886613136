import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { IListingImage } from '@shared/models/image/listing-image';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { ToastService } from '@shared/services/toast.service';
import { UIService } from '@shared/services/ui.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { INews } from '../../models/news';
import { NewsService } from '../../services/news.service';
import { NewsEditPresenter } from './news-edit.presenter';

@Component({
  selector: 'app-news-edit',
  templateUrl: './news-edit.component.html',
  viewProviders: [NewsEditPresenter]
})
export class NewsEditComponent implements OnInit {
  get form() {
    return this.presenter.form;
  }

  canEditNews$: Observable<boolean>;
  COUNTRIES: Record<string, Country> = {};
  errorMessage: string = 'Error';
  loadingMessage: string = 'Loading...';
  MAX_PHOTOS: number = 3;
  news: INews;
  title: string = 'Edit news item';
  updateButtonText: string = 'Update';

  constructor(private authService: AuthService, private constantsService: ConstantsService, private newsService: NewsService, private presenter: NewsEditPresenter, private route: ActivatedRoute) {}

  ionViewWillEnter() {
    const uid = this.route.snapshot.paramMap.get('id');
    this.canEditNews$ = this.authService.isAdmin$([AdminRole.EDITOR]);

    if ('new' === uid) {
      this.title = 'Create news item';
      this.updateButtonText = 'Create';
      this.news = {
        content: '',
        country: [],
        dateTimeSent: 0,
        dateTimeLastUpdated: 0,
        deleteDate: 0,
        photos: {},
        title: '',
        uid: null
      } as INews;
      this.presenter.setValue(this.news);
    } else {
      this.title = 'Update news item';
      this.updateButtonText = 'Update';
      this.newsService
        .getNewsItem(uid)
        .pipe(first())
        .subscribe(news => {
          this.news = news;
          this.presenter.setValue(this.news);
        });
    }
  }

  ionViewWillLeave() {
    // The NewsEditComponent with uid "new" is not destroyed when the form is submitted
    this.form.reset(); // this clears the form data
  }

  ngOnInit() {
    this.COUNTRIES = this.constantsService.constants.APP.countries;
  }

  onUpdateNews() {
    const formValue = this.presenter.news;
    // Delete any removed images
    const oldKeys = Object.keys(this.news.photos || {});
    let photosToRemove: IListingImage[] = [];
    if (oldKeys.length > 0) {
      const newKeys = Object.keys(formValue.photos || {});
      const keysToRemove = oldKeys.filter(x => !newKeys.includes(x));
      for (const key of keysToRemove) {
        photosToRemove.push(Object.assign({}, this.news.photos[key]));
      }
    }

    Object.assign(this.news, formValue);
    this.newsService.updateNewsItem(this.news, photosToRemove);
  }
}
