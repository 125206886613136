import { UserObject } from '@shared/models/user-object';
import { IToolbarIcon } from '../../models/toolbar-icon';
import { AbstractFooterService } from '../abstract-footer.service';

export class ChirpyFooterService extends AbstractFooterService {
  getToolbar(user: UserObject): IToolbarIcon[] {
    const coinsIcon = user.coinsLevel ? `/assets/chirpy/coins/${user.coinsLevel}-icon.svg` : `/assets/chirpy/coins/blue-icon.svg`;
    return [
      {
        iconImage: '/assets/chirpy/footer/home.svg',
        label: 'Home',
        routerLink: '/home'
      },
      {
        iconImage: '/assets/chirpy/dashboard/my-chirpy.svg',
        label: 'My Chirpy',
        routerLink: '/my-chirpy'
      },
      {
        badge: {
          counter: user.messageNotificationCount,
          showBadge: user.messageNotificationCount > 0
        },
        iconImage: '/assets/chirpy/footer/my-messages.svg',
        label: 'Messages',
        routerLink: '/messages'
      },
      {
        badge: {
          counter: user.coinsBalance,
          showBadge: user.coinsBalance > 0
        },
        iconImage: coinsIcon,
        label: 'Rewards',
        routerLink: '/rewards'
      }
    ];
  }
}
