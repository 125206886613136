import { Injectable } from '@angular/core';
import { ChirpyConstants } from '@environment/chirpy/chirpy-constants';
import { IConstants } from '@environment/constants';
import { environment } from '@environment/environment';
import { FriendsConstants } from '@environment/friends/friends-constants';
import { PlantsConstants } from '@environment/plants/plants-constants';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  // TODO: If we're not going to move these constants to the database, we could replace this service with another fileReplacement in angular.json
  get constants(): IConstants {
    // Choose strings for Chirpy or Friends or other sites in the future.
    switch (environment.settings.constants) {
      case 'friends':
        return FriendsConstants;
      case 'plants':
        return PlantsConstants;
      default:
      case 'chirpy':
        return ChirpyConstants;
    }
  }
}
