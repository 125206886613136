import { Component, OnInit } from '@angular/core';
import { CoinsTransactionType } from '@shared/constants/coins-transaction-type';
import { IColumn } from '@shared/models/column';
import { IDataTableConfig } from '@shared/models/data-table-config';
import { DefaultCoinsOptions, ICoinsOptions } from '@shared/models/coins/coins-options';
import { ICoinsTransaction } from '@shared/models/coins/coins-transaction';
import { DateTimeService } from '@shared/services/date-time.service';
import { CoinsService } from '@shared/services/coins/coins.service';
import { firestore } from 'firebase/app';
import { first } from 'rxjs/operators';
import { IInput } from '@shared/models/input';
import { BaseLogPage } from '../../base-log/base-log.page';

@Component({
  selector: 'app-logs-coins-log',
  templateUrl: '../../base-log/base-log.page.html'
})
export class CoinsLogComponent extends BaseLogPage {
  columns: IColumn[] = [
    { label: 'Date', field: 'date', class: 'data-table__column-responsive-bold' },
    { label: 'Member', field: 'memberName' },
    { label: 'Amount', field: 'amount' },
    { label: 'Description', field: 'description', class: 'data-table__column-responsive-italic' },
    { label: 'Authorised by', field: 'staffName' },
    { label: 'type', field: 'type' }
  ];
  config: IDataTableConfig = {
    routerLink: {
      path: '/admin/coins-edit',
      id: [':memberId', ':memberName']
    }
  };
  DEFAULT_OPTIONS = DefaultCoinsOptions;
  interfaceOptions: any = { cssClass: 'wide-select' };
  searchModel: ICoinsOptions = DefaultCoinsOptions;
  title = `Coins log`;
  TYPES = CoinsTransactionType;
  //fields is out of alphabetical order so we can reference other instance members
  fields: IInput[] = [
    {
      label: 'Date after: ',
      name: 'dateAfter',
      type: 'datetime-local'
    },
    {
      label: 'Date before: ',
      name: 'dateBefore',
      type: 'datetime-local'
    },
    {
      label: 'Member: ',
      name: 'member',
      placeholder: 'Enter displayName',
      type: 'chirpy-select-items',
      attributes: {
        noResultsText: '',
        singleSelect: true,
        selectType: 'item'
      }
    },
    {
      label: 'Amount: ',
      name: 'amount',
      placeholder: 'Enter amount',
      type: 'number'
    },
    {
      label: 'Type: ',
      name: 'transactionType',
      placeholder: 'Select type',
      type: 'select',
      attributes: {},
      value: this.TYPES
    }
  ];

  constructor(dateTimeService: DateTimeService, private coinsService: CoinsService) {
    super(dateTimeService);
  }

  loadData(append: boolean = false, lastTimestamp: number = null) {
    this.coinsService
      .getTransactions(this.searchModel, lastTimestamp)
      .pipe(first())
      .subscribe(coins => {
        this.isLoading = false;
        if (coins.length > 0) {
          this.canLoadMore = true;
          const lastTransaction = coins[coins.length - 1];
          if (lastTransaction.date != null) this.lastTimestamp = lastTransaction.date;
        }
        const viewCoins = [];
        coins.forEach(transaction => {
          viewCoins.push({
            amount: transaction.amount,
            date: this.dateTimeService.formatDate(transaction.date),
            description: transaction.description != null ? transaction.description : '(none)',
            memberId: transaction.memberId,
            memberName: transaction.memberName,
            type: (transaction.type || '').toUpperCase(),
            staffName: transaction.staffName
          });
        });

        if (append) {
          this.data = [...this.data, ...viewCoins];
        } else {
          this.data = viewCoins;
        }
      });
  }

  updateSearchCriteria(options: ICoinsOptions = null) {
    // TODO: Could almost do this with key: value from ICoinsOptions, but that doesn't allow hiding default criteria
    const criteria = {};

    if (options == null) this.searchCriteria = criteria;
    if (options.amount != null) criteria['amount'] = `Amount: ${options.amount}`;
    if ((options.dateAfter || '').trim().length > 0) criteria['dateAfter'] = `Date after: ${options.dateAfter}`;
    if ((options.dateBefore || '').trim().length > 0) criteria['dateBefore'] = `Date before: ${options.dateBefore}`;
    if ((options.member || '').trim().length > 0) criteria['member'] = `Member: ${options.member}`;
    if ((options.transactionType || '').trim().length > 0) criteria['transactionType'] = `Type: ${options.transactionType}`;
    this.searchCriteria = criteria;
  }
}
