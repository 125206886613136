import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { GameObject } from '../../models/game-object';
import { PlayerObject } from '../../models/player-object';
import { GameService } from '../../services/game-service';

@Component({
  selector: 'app-bingo-admin-players',
  templateUrl: 'admin-players.page.html'
})
export class AdminPlayersPage implements OnInit {
  loadedGame: Observable<GameObject>;
  loadedPlayers: Observable<PlayerObject[]>;

  constructor(private gameService: GameService, private route: ActivatedRoute, private afs: AngularFirestore, private authService: AuthService) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const param = params.get('gameId');
      if (param == null) {
        this.loadedGame = of(new GameObject());
        this.loadedPlayers = of([]);
      } else {
        this.loadedGame = this.gameService.games.pipe(
          skipWhile(gs => !gs),
          map(gs => gs.filter(g => g.uid === param)[0])
        );
        const game = this.afs.doc<GameObject>('games/bingo/games/' + param);

        this.authService.userProfileObservable.subscribe(userProfile => {
          if (userProfile != null) {
            const player = game.collection<PlayerObject>('Players');
            this.loadedPlayers = player.valueChanges();
          }
        });
      }
    });
  }
}
