import { Component, Input } from '@angular/core';
import { IGroup } from '@shared/models/groups/group';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';

@Component({
  selector: 'chirpy-group-list',
  templateUrl: './chirpy-group-list.component.html',
  styleUrls: ['./chirpy-group-list.component.scss']
})
export class ChirpyGroupListComponent {
  get ASSETS() {
    return this.constantsService.constants.ASSETS;
  }

  @Input() groups: IGroup[] = [];
  @Input() heading: string;
  @Input() noGroupsMessage: string;
  @Input() placeholders: string[] = [];
  @Input() showPlaceholders: boolean = false;

  constructor(private groupService: GroupService, private constantsService: ConstantsService) {}

  countHosts(hosts: Record<string, string>) {
    return Object.values(hosts || {}).length;
  }

  getHosts(hosts: Record<string, string>) {
    return Object.values(hosts || {}).join(', ');
  }

  getRegion(region: string) {
    if (!region) return '';

    return region.startsWith('Virtual') ? 'Virtual' : region;
  }

  isGroupMember(uid: string) {
    return this.groupService.isGroupMember(uid);
  }
}
