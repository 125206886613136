import { Component, Input } from '@angular/core';

@Component({
  selector: 'add-remove-item',
  templateUrl: './add-remove-item.component.html',
  styleUrls: ['./add-remove-item.component.scss']
})
export class ChirpyAddRemoveItemComponent {
  @Input() itemName: string;
  @Input() itemId: string;
  @Input() icon: string;
  @Input() iconColor = 'secondary';
  @Input() locationFilter: boolean = false;
  @Input() memberType = null;
}
