import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { EventType } from '@infrastructure/constants/event-type';
import { CancellationOption } from '@shared/constants/cancellation-option';
import { Country } from '@shared/constants/country';
import { ICancellation } from '@shared/models/cancellations/cancellation';
import { IChargebeeSubscription } from '@shared/models/chargebee-subscription';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { AttendanceService } from '@shared/services/attendance/attendance.service';
import { AuthService } from '@shared/services/auth.service';
import { CancellationService } from '@shared/services/cancellations/cancellation.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EmailService } from '@shared/services/email/email.service';
import { GroupService } from '@shared/services/groups/group.service';
import { ToastService } from '@shared/services/toast.service';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { CancelSubscriptionService } from '../../services/cancel-subscription.service';
import { CancelQuestionsPresenter } from './cancel-questions.presenter';

@Component({
  selector: 'app-cancel-questions',
  templateUrl: 'cancel-questions.page.html'
})
export class CancelQuestionsPage implements OnInit {
  get form() {
    return this.presenter.form;
  }

  get formValid() {
    return this.presenter.form.valid;
  }

  readonly CancellationOption = CancellationOption;
  CONSTANTS: any;
  endOfTerm: boolean = false;
  groups: Record<string, string> = {};
  hasProcessed: boolean = false;
  hasSelectedOption: boolean = false;
  isLoading: boolean = true;
  isOtherReason: boolean = false;
  isPaypalMember: boolean = false;
  isProcessing: boolean = false;
  member: UserObject;
  nextBilling: string;
  notices$: Observable<any>;
  showYesNoQuestion: boolean = false;
  subscriptions: IChargebeeSubscription[];
  submitButtonText: string = 'Cancel subscription';

  constructor(
    private analyticsService: AnalyticsService,
    private appOptionsService: AppOptionsService,
    private attendanceService: AttendanceService,
    private authService: AuthService,
    private cancellationService: CancellationService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private emailService: EmailService,
    private fns: AngularFireFunctions,
    private groupService: GroupService,
    private presenter: CancelQuestionsPresenter,
    private service: CancelSubscriptionService,
    private toastService: ToastService
  ) {}

  ionViewWillEnter() {
    // Can't set in ngOnInit, because if the page is loaded directly it remains in the stack, and thus hasSelectedOption doesn't get reset on subsequent visits
    this.hasSelectedOption = !!this.service.option;
  }

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.ACCOUNT.CANCEL;
    if (this.CONSTANTS.yesNoQuestion != '') this.showYesNoQuestion = true;

    this.authService.userProfileObservable.pipe(first(x => !!x)).subscribe(member => {
      if (member == null) return;

      this.isPaypalMember = member.isPaypalMember != null && member.isPaypalMember === true;
      this.member = member;
    });
  }

  onSubmit() {
    if (this.validateForm()) {
      this.isProcessing = true;
      this.submitButtonText = 'Processing';

      // Can't automatically cancel an old PayPal subscription (~150 left). Just show message
      if (this.isPaypalMember) {
        this.isProcessing = false;
        this.hasProcessed = true;
        return;
      }

      // Don't do anything if we don't have subscription ids, or we haven't loaded member details
      if (this.service.subscriptions.length === 0 || !this.member || !this.member.country) {
        this.isProcessing = false;
        this.submitButtonText = 'Cancel subscription';
        this.toastService.presentToast(`Sorry, we can't retrieve your subscription details, please try again later`);
      }

      const { otherReason, reason, yesNo } = this.presenter.submit();
      const option = this.service.option;
      this.endOfTerm = option === CancellationOption.CANCEL_END_OF_TERM;

      if (CancellationOption.CANCEL_END_OF_TERM === option || CancellationOption.CANCEL_NOW) {
        const data = {
          endOfTerm: this.endOfTerm,
          subscriptionIds: this.service.subscriptions.map(x => x.id),
          country: this.member.country
        };

        this.nextBilling = this.service.subscriptions.length === 0 ? '' : this.service.subscriptions.map(x => x.next_billing).join(','); // it is possible for a member to have more than one subscription, though currently this should only happen if they have a duplicate subscription

        // Cancel subscription on ChargeBee
        const callable = this.fns.httpsCallable('cancelSubscription');
        callable(data)
          .toPromise()
          .then(result => {
            this.isProcessing = false;
            this.hasProcessed = true;

            // Save member details for reports
            this.groupService
              .getAllGroupNames(true)
              .pipe(
                first(x => !!x),
                switchMap(groups => {
                  for (const group of groups) {
                    this.groups[group.uid] = group.name;
                  }
                  return this.attendanceService.getAttendanceWithType(this.member.uid);
                }),
                first(x => !!x)
              )
              .subscribe(attendance => {
                const attendanceCounts = this.attendanceService.getCounts(attendance);
                const branding: Record<EventType, string> = {
                  [EventType.CatchUp]: this.constantsService.constants.CATCHUPS.branding,
                  [EventType.Virtual]: `Virtual ${this.constantsService.constants.CATCHUPS.branding}`,
                  [EventType.Social]: this.constantsService.constants.SOCIAL.branding,
                  [EventType.Event]: EventType.Event,
                  [EventType.Trip]: EventType.Trip
                };
                const cancellation: ICancellation = {
                  attendance:
                    Object.entries(attendanceCounts).length > 0
                      ? 'Attended ' +
                        Object.entries(attendanceCounts)
                          .map(x => `${x[1]} ${branding[x[0]]}${x[1] === 1 ? '' : 's'}`)
                          .join(', ')
                      : '',
                  canAccessRomance: this.member.canAccessRomance || '',
                  cancellationType: option, // TODO: enum? or use subscription status?
                  coinsBalance: this.member.coinsBalance || 0,
                  country: this.member.country || '',
                  dateJoined: this.member.dateRegistered ? this.dateTimeService.formatDate(this.member.dateRegistered, 'D MMM YYYY') : '',
                  dateTimeCancelled: this.dateTimeService.getDateTime(),
                  displayName: this.member.displayName || '',
                  dob: Object.keys(this.member.dateOfBirth || {}).length === 3 ? `${this.member.dateOfBirth.year}-${this.member.dateOfBirth.month}-${this.member.dateOfBirth.day}` : '',
                  email: this.member.email || '',
                  fullName: this.member.fullName || '',
                  groups: (this.member.catchupGroupIds || []).map(x => (this.groups[x] ? this.groups[x] || '' : '')).join(', '),
                  locality: this.member.locality || '',
                  memberId: this.member.uid,
                  otherReason: otherReason,
                  participated: yesNo,
                  phone: this.member.phone || '',
                  reason: reason,
                  subscriptionPlan: this.service.subscriptions.map(x => `${x.currency_code} ${x.plan_unit_price} per ${x.billing_period} ${x.billing_period_unit}${x.billing_period == 1 ? '' : 's'}`).join(', ')
                };

                this.cancellationService.saveCancellation(cancellation);
              });
          })
          .catch(err => {
            this.toastService.presentToast(`Sorry, we encountered an error trying to process your request: ${JSON.stringify(err)}`);
            this.isProcessing = false;
            this.submitButtonText = 'Submit';
          });
      }
    }
  }

  onUpdateReason(event: any) {
    const reason = event.detail.value;
    if ('Other' === reason) {
      this.isOtherReason = true;
    } else {
      this.isOtherReason = false;
    }
  }

  validateForm(): boolean {
    const { message, otherReason, reason, yesNo } = this.presenter.submit();
    if (this.showYesNoQuestion && !yesNo) {
      this.toastService.presentToast(`Please answer the question "${this.CONSTANTS.yesNoQuestion}"`);
      return false;
    } else if (!reason) {
      this.toastService.presentToast(`Please select a reason.`);
      return false;
    } else if (this.isOtherReason && (!otherReason || !otherReason.trim().length)) {
      this.toastService.presentToast(`Please enter a reason.`);
      return false;
    }
    return true;
  }
}
