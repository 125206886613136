import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IWhatsNextOption } from '@shared/models/whats-next-option';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { UserDatabase } from '@shared/services/user/user.database';
import { UIService } from '@shared/services/ui.service';
import { SignupStepService } from '../services/signup-step.service';

@Component({
  selector: 'app-signup-whats-next',
  styleUrls: ['./whats-next.page.scss'],
  templateUrl: './whats-next.page.html'
})
export class SignupWhatsNextPage implements OnInit {
  branding: string;
  buttonColor: string;
  CONSTANTS: any;
  countLastStep: boolean;
  progressColor: string;
  showBackButton: boolean = false;
  useBar: boolean;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private environmentService: EnvironmentService,
    public route: ActivatedRoute,
    private signupStepService: SignupStepService,
    private uiService: UIService,
    private userDatabase: UserDatabase
  ) {}

  eventTrackButton() {
    this.analyticsService.eventTrack(AnalyticsCategory.SIGNUP, AnalyticsAction.SIGNUP_WHATS_NEXT_BUTTON, this.CONSTANTS.buttonLink, { type: this.CONSTANTS.buttonText });
  }

  eventTrackLink(event: any) {
    if (event.target.tagName === 'A') {
      this.analyticsService.eventTrack(AnalyticsCategory.SIGNUP, AnalyticsAction.SIGNUP_WHATS_NEXT_LINK, event.target.pathname, { type: event.target.text });
    }
  }

  ngOnInit() {
    this.branding = this.environmentService.settings.constants;
    this.buttonColor = this.constantsService.constants.WELCOME.SIGNUP.buttonColor;
    this.countLastStep = this.constantsService.constants.WELCOME.SIGNUP.countLastStep;
    this.CONSTANTS = this.constantsService.constants.WELCOME.SIGNUP.WHATS_NEXT;
    this.progressColor = this.constantsService.constants.WELCOME.SIGNUP.progressColor;
    this.useBar = this.constantsService.constants.WELCOME.SIGNUP.useProgressBar;

    this.authService._userProfileSubject.subscribe(user => {
      //Unset isOnboarding flag in centralMembersPrivate
      if (user != null && user.uid != null && user.isOnboarding === true) this.userDatabase.updatePrivateMemberData(user.uid, { isOnboarding: false });
    });
  }
}
