import { Component } from '@angular/core';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { Observable } from 'rxjs';
import { MineService } from './services/mine.service';

@Component({
  selector: 'app-mine-component',
  templateUrl: './mine.component.html',
  styleUrls: ['./mine.component.scss']
})
export class MineComponent {
  get CONSTANTS() {
    return this.constantsService.constants.MY_CHIRPY;
  }

  hasUnreadNotice$: Observable<boolean>;
  isVisible: Record<string, boolean> = {};
  notices$: Observable<any>;

  constructor(private analyticsService: AnalyticsService, private appOptionsService: AppOptionsService, private authService: AuthService, private constantsService: ConstantsService, private mineService: MineService) {}

  eventTrack(eventLabel: string, type: string) {
    this.analyticsService.eventTrack(AnalyticsCategory.MY_CHIRPY, AnalyticsAction.MY_CHIRPY_CLICK_LINK, eventLabel, { type: type });
  }

  ionViewWillEnter() {
    this.analyticsService.eventTrack(AnalyticsCategory.MY_CHIRPY, AnalyticsAction.MY_CHIRPY_VIEW_MINE);
  }

  ngOnInit() {
    for (let box of this.CONSTANTS.boxes) {
      this.isVisible[box.title] = box.hasOwnProperty('hideIfEmpty') ? !box.hideIfEmpty : true;
    }
    this.notices$ = this.appOptionsService.getOptionsValues('myChirpyNotice', this.authService._userProfileSubject.value.country);
    this.hasUnreadNotice$ = this.mineService.hasUnreadNotice();
  }

  onClose() {
    this.mineService.updateNoticeLastRead(this.authService._userProfileSubject.value.uid);
  }

  toggleBox(isEmpty: boolean, title: string) {
    this.isVisible[title] = !isEmpty;
  }
}
