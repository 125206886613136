import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MembersGuard } from '@shared/guards/members-guard';
import { ShareDetailComponent } from './components/share-detail/share-detail.component';
import { ShareEditComponent } from './components/share-edit/share-edit.component';
import { ShareIntroPage } from './share-intro/share-intro.page';
import { SharePage } from './share.page';

export const routes: Routes = [
  {
    path: 'intro',
    component: ShareIntroPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Share intro'
    }
  },
  {
    path: '',
    component: SharePage,
    data: {
      title: 'Share listings'
    },
    children: [
      {
        path: 'update/:id',
        component: ShareEditComponent,
        canActivate: [MembersGuard],
        data: {
          title: 'Update share listing'
        }
      },
      {
        path: ':id',
        component: ShareDetailComponent,
        canActivate: [MembersGuard],
        data: {
          title: 'Share listing details'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShareRoutingModule {}
