import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IGroupSearchModel } from '../../models/group-search-model';

@Injectable({
  providedIn: 'root'
})
export class GroupSearchPresenter {
  form = this.formBuilder.group({
    groupName: [''],
    missingLocation: [false],
    place: [''],
    placeId: [''],
    virtualGroups: [false]
  });

  get missingLocation() {
    return this.form.controls.missingLocation.value;
  }

  get virtualGroups() {
    return this.form.controls.virtualGroups.value;
  }

  constructor(private formBuilder: FormBuilder) {}

  patchValue(data: any) {
    //TODO: validate keys in data?
    this.form.patchValue(data);
  }

  reset() {
    this.form.reset();
  }

  search(): IGroupSearchModel {
    return this.form.value;
  }
}
