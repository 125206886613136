import { Injectable } from '@angular/core';
import { CountryKey } from '@shared/constants/country';
import { IAppOptions } from '@shared/models/app-options';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  organisations: Record<string, string[]>;

  constructor(private appOptionsService: AppOptionsService) {
    this.init();
  }

  init(): void {
    this.appOptionsService
      .getOptions('organisations')
      .pipe(first())
      .subscribe((organisations: IAppOptions) => {
        this.organisations = organisations ? organisations.data || {} : {};
      });
  }

  search(search: string, country: CountryKey, isAdmin: boolean): Observable<string[]> {
    let orgs: string[] = [];
    if (isAdmin) {
      orgs = Object.values(this.organisations).reduce((output, current) => output.concat(current), []); // Use Array.flat() when supported
    } else {
      orgs = this.organisations[country];
    }

    if (search == null || search.length === 0) return of(orgs);
    return of(orgs.filter(i => i.toLowerCase().indexOf(search.toLowerCase()) > -1));
  }
}
