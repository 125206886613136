import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccessStatus } from '@shared/constants/access-status';
import { BadgeType } from '@shared/constants/badge-type';
import { PhotoSize } from '@shared/constants/photo-size';
import { Role } from '@infrastructure/constants/role';
import { ICentralMemberEditable, ICentralMemberPassword, ICentralMemberPrivate } from '@shared/models/central-member';
import { UserObject } from '@shared/models/user-object';
import { IWhereCondition } from '@shared/models/where-condition';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { NotificationService, NotificationTarget } from '@shared/services/notifications';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UserDatabase } from '@shared/services/user/user.database';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { distinctUntilKeyChanged, first, map, skipWhile, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  currentYear: number;
  private userObservers: Record<string, BehaviorSubject<UserObject>> = {};
  private userObserverSubscriptions: Record<string, Subscription> = {};

  constructor(private authService: AuthService, private constantsService: ConstantsService, private dateTimeService: DateTimeService, private notificationService: NotificationService, private router: Router, private subscriptionService: SubscriptionService, private userDatabase: UserDatabase) {
    this.currentYear = new Date().getFullYear();
  }

  decrementOwnMessageNotificationCount(amount: number) {
    if (amount === 0 || amount === undefined || amount === null) return; // Don't update if valid is invalid or zero

    // Assume for the moment that we will only be decrementing our own message count
    if (!this.authService._userProfileSubject.value.uid) return;

    return this.incrementMessageNotificationCount(this.authService._userProfileSubject.value.uid, -amount);
  }

  getAge(dateOfBirth) {
    if (dateOfBirth == null || dateOfBirth.year == null) return null;

    return this.dateTimeService.getAge(dateOfBirth);
  }

  getAvatarUrl(user: UserObject, photoSize: PhotoSize = PhotoSize.THUMBNAIL) {
    const isValid = photoURL => {
      return photoURL != null && photoURL.length > 0 && !photoURL.match(/gravatar/);
    };

    switch (photoSize) {
      case PhotoSize.LARGE:
        if (isValid(user.largePhotoURL)) return user.largePhotoURL;
      // use thumbnail if there's no largePhotoURL
      case PhotoSize.THUMBNAIL:
        if (isValid(user.photoURL)) return user.photoURL;
    }

    const genders = this.constantsService.constants.GENDERS;
    const invalidGender = !genders.some(g => g.value === user.gender);
    const gender = invalidGender ? genders.find(g => g.default === 'true') : genders.find(g => g.value === user.gender);

    return gender.avatarUrl;
  }

  getAvatarUrl$(uid: string, size: PhotoSize): Observable<string> {
    const key = size === PhotoSize.LARGE ? 'largePhotoURL' : 'photoURL';
    return this.getUserProfile(uid).pipe(
      skipWhile(x => !x),
      //Don't fire observable unless the specified url field changes
      distinctUntilKeyChanged(key),
      map(profile => {
        const url = this.getAvatarUrl(profile, size);
        return url;
      })
    );
  }

  getKeys(obj) {
    if (obj == null) {
      return [];
    }

    return Object.entries(obj)
      .filter(([key, value]) => value)
      .map(([key, value]) => key)
      .join(', ');
  }

  getMembersByIds(uids: string[], isPrivate: boolean = true): Observable<any[]> {
    return this.userDatabase.getMembersByIds(uids, isPrivate);
  }

  getMembersByQuery(whereCondition: IWhereCondition, isPrivate: boolean = false) {
    return this.userDatabase.getMembersByQuery(whereCondition, isPrivate);
  }

  getPrivateMemberData(uid: string): Observable<ICentralMemberPrivate> {
    return this.userDatabase.getPrivateMemberData(uid);
  }

  getUserProfile(uid: string, showUnknown: boolean = false): BehaviorSubject<UserObject> {
    if (this.userObservers[uid] !== undefined && this.userObservers[uid] !== null) {
      return this.userObservers[uid];
    }

    const behaviorSubject$: BehaviorSubject<UserObject> = new BehaviorSubject<UserObject>(null);

    this.userObserverSubscriptions[uid] = this.authService._userProfileSubject
      .pipe(
        first(x => !!x),
        switchMap(myself => {
          if (myself.uid === uid) {
            return this.authService._userProfileSubject;
          }
          if (!this.authService.isCohostOrHostOrAdmin()) {
            return this.userDatabase.getPublicMemberData(uid).pipe(
              map(otherMember => {
                if (otherMember) delete otherMember.phone; // remove sensitive data which is only accessible by hosts or admins
                return otherMember;
              })
            );
          }
          if (!this.authService.isAdmin()) {
            return this.userDatabase.getPublicMemberData(uid);
          } else {
            return combineLatest(this.userDatabase.getPublicMemberData(uid), this.userDatabase.getPrivateMemberData(uid)).pipe(map(([publicData, privateData]) => Object.assign({}, publicData, privateData) as UserObject));
          }
        })
      )
      .subscribe(userProfile => {
        const userNotFound = Object.keys(userProfile || {}).length === 0 && uid != null;
        if (userNotFound) userProfile = showUnknown ? (Object.assign({}, { uid, displayName: '(deleted member)' }) as UserObject) : null;
        behaviorSubject$.next(userProfile);
      });

    this.subscriptionService.add(this.userObserverSubscriptions[uid]);
    this.userObservers[uid] = behaviorSubject$;
    return this.userObservers[uid];
  }

  getUsers(memberIds: string[], showUnknown: boolean = false): Observable<UserObject[]> {
    // TODO: How to handle the case where some of the getUserProfile calls fails?
    // forkJoin emits only once when inner observables complete, so don't need to unsubscribe
    return forkJoin(
      memberIds.map(id => {
        return this.getUserProfile(id, showUnknown).pipe(first(x => x != null)); // first is needed for the observables to complete
      })
    ).pipe(
      map((users: UserObject[]) => {
        return users.sort((a, b) => ((a.displayName || '').toLowerCase() > (b.displayName || '').toLowerCase() ? 1 : -1));
      })
    );
  }

  grantRomanceAccess(uid: string) {
    return this.userDatabase.updatePrivateMemberData(uid, { canAccessRomance: AccessStatus.GRANTED }).then(() => {
      const notificationSettings = {
        [NotificationTarget.ROMANCE]: {
          newRomanceListing: true // TODO: Read this from app/notifications? But DB usage is less if we only add it to members after they get romance access
        }
      };
      this.notificationService.updateSettingsForMember(uid, notificationSettings, null);
    });
  }

  incrementMessageNotificationCount(uid: string, amount: number) {
    if (amount === 0 || amount === undefined || amount === null) return; // Don't update if valid is invalid or zero

    this.userDatabase.updatePublicMemberData(uid, { messageNotificationCount: firebase.firestore.FieldValue.increment(amount) });
  }

  isAdmin(profile: UserObject) {
    return profile != null && profile.role != null && profile.role === Role.ADMIN;
  }

  isAdvisor(profile: UserObject) {
    return false;
    //return profile != null && profile.role != null && profile.role === Role.ADVISOR;
  }

  isCohost(profile: UserObject) {
    return profile != null && profile.role != null && profile.role === Role.COHOST;
  }

  isHost(profile: UserObject) {
    return profile != null && profile.role != null && profile.role === Role.HOST;
  }

  isHostOrAdmin(profile: UserObject) {
    return this.isHost(profile) || this.isAdmin(profile);
  }

  mustSetPassword(email: string) {
    this.userDatabase
      .getPasswordFlag(email)
      .pipe(first())
      .subscribe((data: ICentralMemberPassword[]) => {
        if (data.length === 1 && data[0].mustSetPassword != null && data[0].mustSetPassword === true) {
          this.router.navigate(['/auth/reset-password', email]);
        }
      });
  }

  ngOnDestroy() {
    Object.entries(this.userObserverSubscriptions).forEach(item => {
      this.subscriptionService.clearSubscription(item[1]);
    });
  }

  openMemberProfile(uid: string) {
    this.router.navigate(['/members', uid]);
  }

  removeNull(document: object) {
    Object.keys(document).forEach(key => {
      if (key !== 'gender' && key !== 'dateOfBirth') {
        if (document[key] == null) delete document[key];
      }
    });
    return document;
  }

  removeRomanceAccess(uid: string) {
    return this.userDatabase.updatePrivateMemberData(uid, { canAccessRomance: firebase.firestore.FieldValue.delete() }).then(result => this.notificationService.deleteSettingsForMember(uid, NotificationTarget.ROMANCE));
  }

  requestRomanceAccess(uid: string) {
    return this.userDatabase.updatePrivateMemberData(uid, { canAccessRomance: AccessStatus.PENDING });
  }

  setBadge(uid: string, badge: string, badgeType: BadgeType, status: boolean = true): Promise<any> {
    if ((badge || '').trim().length === 0) {
      return Promise.reject(`Please select a badge`);
    }
    const value = status === true ? true : firebase.firestore.FieldValue.delete();
    return this.userDatabase.updatePublicMemberData(uid, { badges: { [badgeType]: { [badge]: value } } });
  }

  setOwnMessageNotificationCount(amount: number) {
    if (amount === undefined || amount === null) return; // Don't update if valid is invalid
    if (!this.authService._userProfileSubject.value.uid) return;
    this.userDatabase.updatePublicMemberData(this.authService._userProfileSubject.value.uid, { messageNotificationCount: +amount });
  }

  updatePrivateUserField(uid: string, field: string, data: any) {
    return this.userDatabase.updatePrivateMemberField(uid, field, data);
  }

  updateRole(uid: string, role: Role): Promise<any> {
    return this.userDatabase.updatePublicMemberData(uid, { role: role });
  }

  updateSetPasswordFlagByEmail(email: string, status: boolean = false) {
    return this.userDatabase.updatePasswordFlagByEmail(email, status);
  }

  updateSetPasswordFlagByUid(uid: string, status: boolean = false) {
    return this.userDatabase.updatePasswordFlagByUid(uid, status);
  }

  updateUserField(uid: string, field: string, data: any) {
    return this.userDatabase.updatePublicMemberField(uid, field, data);
  }

  updateUserProfile(uid: string, member: ICentralMemberEditable) {
    return this.userDatabase.updatePublicMemberData(uid, this.removeNull(member));
  }
}
