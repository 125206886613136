import { Injectable } from '@angular/core';
import { LimitPeriod } from '@shared/constants/limit-period';
import { ILimit } from '@shared/models/limits/limit';
import { IMemberLimit } from '@shared/models/limits/member-limit';
import { AnalyticsAction } from '@shared/services/analytics/analytics-action';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { AuthService } from '@shared/services/auth.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { LimitDatabase } from '@shared/services/limits/limit.database';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { firestore } from 'firebase/app';
import { Observable, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import FieldValue = firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class LimitService {
  limits: Record<AnalyticsAction, ILimit>;
  subscription: Subscription;

  constructor(private appOptionsService: AppOptionsService, private dateTimeService: DateTimeService, private limitDatabase: LimitDatabase, private subscriptionService: SubscriptionService) {
    this.subscription = this.appOptionsService.getOptionsValues('limits', 'ALL').subscribe(values => {
      this.limits = {} as Record<AnalyticsAction, ILimit>;
      for (const limit of values) {
        this.limits[limit.key] = limit;
      }
    });
    //this.subscriptionService.add(this.subscription); // TODO: For some reason, this causes the limits not to be available on page load
  }

  hasReachedLimit(memberId: string, action: AnalyticsAction, trigger: ILimit = null, readOnly: boolean = false): Promise<boolean> {
    return this.limitDatabase
      .getMemberLimit(memberId)
      .pipe(first(x => !!x)) // TODO: This halts execution if the limits document does not exist for the member
      .toPromise()
      .then((limits: IMemberLimit) => {
        let hasReached: boolean = true;
        const now = this.dateTimeService.getDateTimeAsISOString();
        const currentDate = {
          day: now.slice(0, 10),
          hour: now.slice(0, 13),
          month: now.slice(0, 7),
          ever: 'now'
        };

        if (!trigger) trigger = this.limits[action] || { key: action, limit: 0, period: LimitPeriod.MONTH }; // define unrestricted limit in case none exists

        if (trigger.limit === 0) {
          hasReached = false;
        } else if (limits[trigger.period] && currentDate[trigger.period] === limits[trigger.period].current) {
          const currentCount = limits[trigger.period].events[action] || 0;
          if (currentCount < trigger.limit) {
            if (!readOnly) {
              const data = {
                [trigger.period]: {
                  events: {
                    [action]: FieldValue.increment(1)
                  }
                }
              };
              this.limitDatabase.updateMemberLimit(memberId, data);
            }
            hasReached = false;
          }
        } else if (limits[trigger.period] == null || limits[trigger.period].current === '' || limits[trigger.period].current == null || currentDate[trigger.period] > limits[trigger.period].current) {
          // last entry was in the previous period, therefore hasn't reached limit by definition.
          // or this is the first event
          // Reset data for the relevant period only (i.e. don't reset month if it's just a new day)
          limits[trigger.period] = {
            current: currentDate[trigger.period],
            events: {} as Record<AnalyticsAction, number>
          };
          limits[trigger.period].events[action] = readOnly ? 0 : 1;
          this.limitDatabase.updateMemberLimit(memberId, limits, false);
          hasReached = false;
        }
        return hasReached;
      });
  }

  hasReachedLimitReadOnly$(memberId: string, action: AnalyticsAction, trigger: ILimit): Observable<boolean> {
    return this.limitDatabase.getMemberLimit(memberId).pipe(
      // Don't pipe first(x =>!!x) so that we can see if the limit changes while we are checking
      map((limits: IMemberLimit) => {
        let hasReached: boolean = true;
        const now = this.dateTimeService.getDateTimeAsISOString();
        const currentDate = {
          day: now.slice(0, 10),
          hour: now.slice(0, 13),
          month: now.slice(0, 7),
          ever: 'now'
        };

        if (limits != null && limits[trigger.period] && currentDate[trigger.period] === limits[trigger.period].current) {
          const currentCount = limits[trigger.period].events[action] || 0;
          if (currentCount < trigger.limit) {
            hasReached = false;
          }
        } else if (limits == null || limits[trigger.period] == null || limits[trigger.period].current === '' || limits[trigger.period].current == null || currentDate[trigger.period] > limits[trigger.period].current) {
          hasReached = false;
        }
        return hasReached;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.subscription);
  }
}
