import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { IColumn } from '@shared/models/column';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { GroupDatabase } from '@shared/services/groups/group.database';
import { DateTimeService } from '@shared/services/date-time.service';
import { BaseReportComponent } from '../../base-report/base-report.component';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-reports-next-catchup',
  templateUrl: '../../base-report/base-report.component.html'
})
export class NextCatchupComponent extends BaseReportComponent {
  columns = [
    { label: 'Group', field: 'groupName', class: 'data-table__column-responsive-bold' },
    { label: 'Host(s)', field: 'hostNames' },
    { label: 'Country', field: 'country' },
    { label: 'CatchUps approved', field: 'catchupsApproved' },
    { label: 'CatchUps pending', field: 'catchupsPending' },
    { label: 'Next CatchUp', field: 'name' },
    { label: 'Date', field: 'date' },
    { label: 'Shared', field: 'shared' }
  ];
  config = {
    routerLink: {
      path: '/groups',
      id: [':uid']
    }
  };
  dateFrom: string = '';
  dateTo: string = '';
  hasParameters = false;
  instructions = 'Loading...';
  title = 'Next CatchUp Report';

  constructor(alertController: AlertController, private catchupService: CatchupService, private dateTimeService: DateTimeService, private groupDatabase: GroupDatabase) {
    super(alertController);
    this.dateFrom = this.dateTimeService.getStartOfTodayAsString();
    this.dateTo = '';
    this.loadData();
  }

  loadData() {
    if (!this.dateFrom) return;

    this.data$ = combineLatest(this.catchupService.getCatchupsByDate(this.dateFrom, this.dateTo), this.groupDatabase.getAllGroups()).pipe(
      map(([catchups, groups]) => {
        // Aggregate all future CatchUps by group
        let groupedCatchups = catchups.reduce((grouped, catchup) => {
          const country = { country: (catchup.country || []).join(', ') };
          const gId = catchup.groupId || '';
          if (!grouped[gId]) {
            if (gId != '') {
              grouped[gId] = Object.assign({}, catchup, country);
              grouped[gId].shared = Object.keys(catchup.sharedGroups).length > 0 ? 'Yes' : 'No';
              if (catchup.approved === true) {
                grouped[gId].catchupsApproved = 1;
                grouped[gId].catchupsPending = 0;
              } else {
                grouped[gId].catchupsApproved = 0;
                grouped[gId].catchupsPending = 1;
              }
            }
          } else {
            if (catchup.approved === true) {
              grouped[gId].catchupsApproved = grouped[gId].catchupsApproved + 1;
            } else {
              grouped[gId].catchupsPending = grouped[gId].catchupsPending + 1;
            }
          }
          return grouped;
        }, {});

        // Add in groups without a catchup, and add group uid and host names for all groups
        groupedCatchups = groups.reduce((grouped, group) => {
          if (!grouped[group.uid]) {
            const country = (group.country || []).join(', ');
            const info = {
              catchupsPending: 0,
              catchupsApproved: 0,
              country: country,
              date: '',
              groupName: group.name,
              name: '',
              uid: group.uid
            };
            grouped[group.uid] = Object.assign({}, info);
          }
          const hostNames = [...Object.values(group.hosts || {}), ...Object.values(group.cohosts || {})].join(', ');
          grouped[group.uid].uid = group.uid;
          grouped[group.uid].hostNames = hostNames;
          return grouped;
        }, groupedCatchups);

        // Sort groups alphabetically
        return Object.values(groupedCatchups).sort((a: any, b: any) => a.groupName.localeCompare(b.groupName));
      })
    );
  }
}
