import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { CoinsRoutingModule } from './coins-routing.module';
import { CoinsPage } from './coins.page';
import { CoinsTransactionListPage } from './transaction-list/transaction-list.page';

@NgModule({
  declarations: [CoinsPage, CoinsTransactionListPage],
  imports: [CoinsRoutingModule, SharedModule]
})
export class CoinsModule {}
