import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { IGroup } from '@shared/models/groups/group';
import { ITrip } from '@shared/models/trips/trip';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { TripService } from '@shared/services/trips/trip.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'trips',
  styleUrls: ['./trips.component.scss'],
  templateUrl: './trips.component.html'
})
export class TripsComponent {
  @Input() canUpdateGroup: boolean;
  countries: Country[];
  @Output() linkClicked = new EventEmitter();
  tripCount: string = '';
  trips$: Observable<ITrip[]>;
  @Input() group: IGroup;

  constructor(private authService: AuthService, private constantsService: ConstantsService, private tripService: TripService) {}

  getImage(trip: ITrip) {
    return this.tripService.getImage(trip, this.group.largePhotoURL);
  }

  getInterestedAndGoing(trip: ITrip): string {
    return this.tripService.numberInterestedAndGoing(trip);
  }

  ngOnInit() {
    this.countries = this.canUpdateGroup ? Object.values(this.constantsService.constants.APP.countries) : [this.authService.getCountry()];
    this.trips$ = this.tripService.getGroupTrips(this.canUpdateGroup, this.group.uid).pipe(
      tap(trips => {
        this.tripCount = trips.length > 0 ? `(${trips.length})` : '';
      })
    );
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }
}
