import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { Observable } from 'rxjs';
import { INews } from '../../models/news';
import { NewsService } from '../../services/news.service';

@Component({
  selector: 'app-news-detail',
  styleUrls: ['./news-detail.component.scss'],
  templateUrl: './news-detail.component.html'
})
export class NewsDetailComponent {
  canEditNews$: Observable<boolean>;
  noNewsMessage: string = `Sorry, we cannot find this news item.`;
  news$: Observable<INews>;
  newsId: string;

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private newsService: NewsService, private route: ActivatedRoute, private router: Router) {}

  ionViewWillEnter() {
    this.newsId = this.route.snapshot.paramMap.get('id');
    this.news$ = this.newsService.getNewsItem(this.newsId);
    this.canEditNews$ = this.authService.isAdmin$([AdminRole.EDITOR]);
    this.analyticsService.eventTrack(AnalyticsCategory.MY_CHIRPY, AnalyticsAction.MY_CHIRPY_VIEW_NEWS_DETAIL, this.newsId);
  }

  onDelete() {
    this.newsService.deleteNewsItem(this.newsId).then(result => {
      this.router.navigate(['/my-chirpy/news']);
    });
  }
}
