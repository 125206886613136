import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { IAdvertiserOptions } from '@shared/models/advertisers/advertiser-options';

@Injectable({
  providedIn: 'root'
})
export class AdvertiserSearchPresenter {
  defaultAdvertiserSearch: IAdvertiserOptions;
  form = this.formBuilder.group({
    category: [''],
    location: [''],
    name: ['']
  });

  constructor(private formBuilder: FormBuilder) {}

  patchValue(data: any) {
    this.form.patchValue(data);
  }

  reset() {
    this.setValue({ category: '', location: null, name: '' });
  }

  search() {
    return this.form.value;
  }

  setValue(advertiserOptions: IAdvertiserOptions) {
    this.form.setValue({
      category: advertiserOptions.category || '',
      location: advertiserOptions.location || null,
      name: advertiserOptions.name
    });
  }
}
