import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { IToken } from '@shared/models/chats/token';
import { IVirtualCatchup } from '@shared/models/chats/virtual-catchup';
import { UserObject } from '@shared/models/user-object';
import { BaseDatabase } from '@shared/services/base.database';
import { CacheService } from '@shared/services/cache.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VirtualCatchupDatabase extends BaseDatabase {
  constructor(afs: AngularFirestore, cache: CacheService, private fns: AngularFireFunctions) {
    super(afs, cache);
  }
  createVirtualCatchup(name: string, id: string) {
    // This cloud function will,
    // 1. Create a Daily.co room with the given name ()
    // 2. Add the daily.co room details to /virtualCatchups Firebase collection
    // 3. Update the virtualCatchups friendly name with the specified Virtual Catchup name.
    // 4. Update the virtualCatchups name in messagesMemberThreads/threadId
    // 5. Schedule deleting the daily.co room and virtualCatchups/threadId record in 8 days.
    const callable = this.fns.httpsCallable('createVirtualCatchup');
    return callable({ name, id });
  }

  createMeetingToken(member: UserObject) {
    // This cloud function will,
    // 1. Create a daily.co meeting token for the current member
    // 2. Save the token to /virtualCatchupsTokens Firebase collection
    const callable = this.fns.httpsCallable('createMeetingToken');
    return callable(member);
  }

  getMeetingToken(member: UserObject) {
    //TODO: Is this the best way to handle it when the current user is not yet set?
    if (member === null) return of(null);
    return this.getDocumentValueChanges<IToken>(`virtualCatchupsTokens`, member.uid);
  }

  removeVirtualCatchup(name: string, id: string) {
    // This cloud function will,
    // 1. Delete a daily.co room
    // 2. Delete the daily.co room details from /virtualCatchups Firebase collection
    const callable = this.fns.httpsCallable('deleteVirtualCatchup');
    return callable({ name, id });
  }

  updateRoomDeleteDate(uid: string) {
    const DELETE_IN_N_DAYS = 8; // 8 days, because if a catchup is held weekly then this daily.co room will be kept alive.
    const deleteDate = new Date().setDate(new Date().getDate() + DELETE_IN_N_DAYS);
    this.updateDocument(`virtualCatchups`, uid, { deleteDate });
  }
}
