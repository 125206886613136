import { Injectable } from '@angular/core';
import { IAdvancedNotification } from '@shared/models/notifications/advanced-notification';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { IContactFormSetting } from '@shared/models/contact-form-setting';

@Injectable({
  providedIn: 'root'
})
export class GroupNotificationsPresenter {
  get settings() {
    return this.form.value;
  }

  form = this.formBuilder.group({});

  constructor(private formBuilder: FormBuilder) {}

  getControlValue(id: string) {
    return this.form.get(id).value;
  }

  init(settingsData: IContactFormSetting[]) {
    // Dynamically add settings toggles
    settingsData.forEach(setting => {
      this.form.addControl(setting.id, new FormControl(false, [Validators.requiredTrue]));
    });
  }

  setValue(data: Record<string, boolean>) {
    this.form.setValue(data);
  }
}
