import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SignupStepService } from '../../services/signup-step.service';

@Component({
  selector: 'signup-progress',
  styleUrls: ['./signup-progress.component.scss'],
  templateUrl: './signup-progress.component.html'
})
export class SignupProgressComponent implements OnInit {
  @Input() color: string;
  currentStep: number;
  progress: number;
  @Input() route: ActivatedRoute;
  stepCount: number;
  steps: any[];
  @Input() useBar: boolean = true;

  constructor(private signupStepService: SignupStepService) {}

  ngOnInit() {
    this.currentStep = this.signupStepService.getCurrentStepNumber(this.route);
    this.stepCount = this.signupStepService.getTotalSteps();
    this.progress = this.currentStep / this.stepCount;
    this.steps = this.signupStepService.steps.slice(0, this.stepCount);
  }
}
