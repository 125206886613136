import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ICatchup } from '@shared/models/catchups/catchup';
import { DefaultCatchupOptions, ICatchupOptions } from '@shared/models/catchups/catchup-options';
import { CatchupShowOption } from '@shared/models/catchups/catchup-show-option';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { RegionService } from '@shared/services/regions/region.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { CatchupSearchComponent } from '../catchup-search/catchup-search.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'catchup-list',
  styleUrls: ['./catchup-list.component.scss'],
  templateUrl: './catchup-list.component.html'
})
export class CatchupListComponent implements OnInit {
  get CONSTANTS() {
    return this.constantsService.constants.CATCHUPS.LIST;
  }

  get isSearch() {
    return Object.values(this.searchCriteria).length > 0;
  }

  catchups: ICatchup[] = [];
  catchupOptions: ICatchupOptions | null = null;
  catchupSubscriptions: Record<number, Subscription> = {};
  defaultCatchupOptions: ICatchupOptions = DefaultCatchupOptions;
  groupId: string;
  groupName: string;
  initialSearch: ICatchupOptions;
  isSearchEnabled: boolean = false;
  lastDate: number;
  loading: boolean = false;
  noCatchupsMessage: string;
  pastCatchups: ICatchup[] = [];
  recordsLoaded: boolean = false;
  regionLabel: string;
  searchCriteria: Record<string, string> = {};
  showLoadMoreButton: boolean = true;
  title: string;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private catchupService: CatchupService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private regionService: RegionService,
    private modalController: ModalController,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService
  ) {}

  loadMoreCatchups(reset: boolean = false) {
    this.loading = true;
    this.showLoadMoreButton = true;
    if (reset) {
      this.recordsLoaded = false;
      this.catchups = [];
      this.pastCatchups = [];
      this.lastDate = this.dateTimeService.getStartOfToday();
      this.clearSubscriptions();
    }

    // When searching all subscriptions get cleared before a new search.
    // When loading more, searchModel stays the same, but we get a new subscription for each (date-based) query
    this.catchupSubscriptions[this.lastDate] = this.catchupService.getCatchups(this.catchupOptions, this.lastDate, !this.recordsLoaded).subscribe(results => {
      this.recordsLoaded = true;

      if (results.length > 0) {
        this.lastDate = results.slice(-1)[0].datetime;
        this.processResults(results);
      }

      if (results.length < this.catchupService.MAX_RECORDS) {
        this.showLoadMoreButton = false;
      } else {
        this.showLoadMoreButton = true;
      }
      this.loading = false;
    });
    this.subscriptionService.add(this.catchupSubscriptions[this.lastDate]);
  }

  ngOnInit() {
    this.isSearchEnabled = this.CONSTANTS.isSearchEnabled;
    this.title = this.CONSTANTS.title;
    this.groupId = this.route.snapshot.paramMap.get('groupId') || null;
    this.groupName = this.route.snapshot.paramMap.get('groupName') || null;
    if (this.groupName) this.groupName = this.groupName.replace(/(%20|\+)/g, ' ');
    // To allow admins to see all countries even when allowOtherCountries is false, we set country to null
    const country = this.authService.isAdmin() ? null : this.authService._userProfileSubject.value.country || null;
    this.initialSearch = Object.assign({}, this.defaultCatchupOptions, { country: country });
    if (this.groupId) {
      this.initialSearch.groupId = this.groupId;
      this.initialSearch.groupName = this.groupName;
    } else {
      this.initialSearch.show = CatchupShowOption.MY_GROUPS;
    }
    this.onSearch(this.initialSearch);
    // TODO: Ideally for travel groups this would say No trips found
    this.noCatchupsMessage = `No ${this.constantsService.constants.CATCHUPS.branding}s found`;
    this.sendAnalytics();
  }

  onClickCriterion(key: string) {
    const criterion = this.catchupOptions[key];
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_CLEAR_SEARCH_CRITERION, criterion, { type: key });

    this.catchupOptions[key] = this.defaultCatchupOptions[key];
    // Only groupName is shown to members, but we need to unset groupId too
    if (key === 'groupName') {
      this.catchupOptions.groupId = this.defaultCatchupOptions.groupId;
    }
    this.onSearch(this.catchupOptions);
  }

  async onOpenCatchupFilterModal() {
    await this.presentCatchupFilterModal();
  }

  onSearch(catchupSearch: any) {
    // If we just do this.catchupOptions = catchupSearch it passes object by reference
    // In particular it passes a reference to initialSearch which then gets modified
    this.catchupOptions = Object.assign({}, catchupSearch);
    this.regionLabel = this.regionService.getRegionLabelForRegion(this.catchupOptions.region || '');
    this.updateSearchCriteria(this.catchupOptions);
    this.loadMoreCatchups(true);
  }

  async presentCatchupFilterModal() {
    const eventEmitter = new EventEmitter<any>();
    eventEmitter.subscribe($event => {
      modal.dismiss();

      switch ($event.type) {
        case 'reset':
          this.resetSearch();
          return;
        case 'search':
          this.onSearch($event.data);
          return;
      }
    });

    const modal = await this.modalController.create({
      component: CatchupSearchComponent,
      cssClass: 'catchup-search__modal',
      componentProps: {
        catchupOptions: this.catchupOptions,
        search: eventEmitter
      }
    });

    return await modal.present();
  }

  processResults(results: ICatchup[]) {
    const allCatchups = this.getUniqueCatchups([...this.pastCatchups, ...this.catchups, ...results]);
    let sorted = allCatchups.sort((a, b) => (a.datetime >= b.datetime ? 1 : -1));
    // TODO: Let hosts see their CatchUps that are pending approval
    if (this.authService.isAdmin()) {
      sorted = sorted.sort((a, b) => (a.approved ? 1 : 0) - (b.approved ? 1 : 0));
    }

    const now = Date.now();
    this.pastCatchups = sorted.filter(x => x.datetime <= now);
    this.catchups = sorted.filter(x => x.datetime > now);
  }

  resetSearch() {
    this.catchupOptions = null;
    this.catchupOptions = Object.assign({}, this.initialSearch);
    this.updateSearchCriteria(this.catchupOptions);
    this.loadMoreCatchups(true);
  }

  sendAnalytics() {
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_VIEW_LISTINGS);
  }

  updateSearchCriteria(options: ICatchupOptions = null) {
    // NB: Could almost do this with key: value from ICatchupOptions, but that doesn't allow hiding default criteria
    const criteria = {};
    if (options == null) this.searchCriteria = criteria;
    if (this.constantsService.constants.APP.allowOtherCountries) {
      if ((options.country || '').trim().length > 0) criteria['country'] = `Country: ${options.country}`;
    }
    if ((options.region || '').trim().length > 0) criteria['region'] = `${this.regionLabel}: ${options.region}`;
    if (options.show !== CatchupShowOption.EVERYTHING) criteria['show'] = `Show: ${options.show}`;
    if ((options.groupName || '').trim().length > 0) criteria['groupName'] = `Group: ${options.groupName}`; //TODO parse out url-encoded characters
    this.searchCriteria = criteria;
  }

  private clearSubscriptions() {
    for (let sub of Object.values(this.catchupSubscriptions)) {
      this.subscriptionService.clearSubscription(sub);
    }
  }

  private getUniqueCatchups(catchups: ICatchup[]): ICatchup[] {
    //Put events into key->value map. Any duplicates simply overwrite the earlier value. Convert back to an array
    const uniqueCatchups = new Map(catchups.map(catchup => [catchup.uid, catchup]));
    return Array.from(uniqueCatchups.values());
  }
}
