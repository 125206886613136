import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { MarketplaceDetailComponent } from './components/marketplace-detail/marketplace-detail.component';
import { MarketplaceEditComponent } from './components/marketplace-edit/marketplace-edit.component';
import { MarketplaceListComponent } from './components/marketplace-list/marketplace-list.component';
import { MarketplaceSearchComponent } from './components/marketplace-search/marketplace-search.component';
import { MarketplaceIntroPage } from './marketplace-intro/marketplace-intro.page';
import { MarketplaceRoutingModule } from './marketplace-routing.module';
import { MarketplacePage } from './marketplace.page';

@NgModule({
  imports: [MarketplaceRoutingModule, SharedModule],
  declarations: [MarketplaceDetailComponent, MarketplaceEditComponent, MarketplaceSearchComponent, MarketplaceListComponent, MarketplaceIntroPage, MarketplacePage],
  entryComponents: [MarketplaceSearchComponent]
})
export class MarketplaceModule {}
