import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import Daily from '@daily-co/daily-js';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { VirtualCatchupService } from '@shared/services/virtual-catchups/virtual-catchup.service';
import { combineLatest, Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { ThreadDetailCatchupService } from '../../services/messages/thread-detail-catchup.service';

@Component({
  selector: 'chirpy-virtual-catchup',
  templateUrl: './chirpy-virtual-catchup.component.html',
  styleUrls: ['./chirpy-virtual-catchup.component.scss']
})
export class ChirpyVirtualCatchupComponent implements AfterViewInit, OnDestroy {
  get reduceIframeHeightForTitle() {
    return this.title != null || this.containerHasTitleBar === true;
  }

  callFrame: any;
  @Input() containerHasTitleBar: boolean;
  readonly DAILYCO_URL = 'https://chirpyplus.daily.co';
  errorMessage: string = '';
  @ViewChild('chatFrame', { static: false }) iframe: ElementRef;
  @Output() onJoinedVirtualCatchup = new EventEmitter();
  ref: Subscription;
  showLoadingSpinner = true;
  @Input() title: string;

  constructor(private virtualCatchupService: VirtualCatchupService, private threadDetailCatchupService: ThreadDetailCatchupService, private authService: AuthService, private analyticsService: AnalyticsService) {}

  getMeetingUrl(name: string, meetingToken: string): string {
    if (meetingToken !== null) {
      return `${this.DAILYCO_URL}/${name}?t=${meetingToken}`;
    } else {
      return `${this.DAILYCO_URL}/${name}`;
    }
  }

  async initIframe() {
    // Check for existing Daily.co call object, e.g. if another component with <chirpy-virtual-catchup> is still in the stack
    const existingCall = Daily.getCallInstance();
    if (existingCall) {
      await existingCall.destroy();
      this.callFrame = null;
    }
    // Turn iframe into a DailyIFrame;
    const callFrame = Daily.wrap(this.iframe.nativeElement);
    callFrame
      .on('joined-meeting', evt => {
        const videoTimeLimit = this.virtualCatchupService.videoTimeLimit;

        this.virtualCatchupService.presentTimeoutWarningModal(videoTimeLimit);

        // Display modal when the video session limit is reached.
        this.virtualCatchupService.presentTimedOutModal(videoTimeLimit);

        // Fire event to let parent component know we have connected
        this.onJoinedVirtualCatchup.emit();
      })
      .on('left-meeting', evt => {
        this.virtualCatchupService.unsetCatchup();
      })
      .on('error', evt => {
        console.log(evt);
        // TODO: log client side errors for support staff/devs to analyse production problems.
      });

    this.callFrame = callFrame;
  }

  ionViewWillLeave() {
    this.threadDetailCatchupService.unsetCatchup();
  }

  async ngAfterViewInit() {
    await this.initIframe();

    const roomName$ = this.virtualCatchupService.virtualCatchup$.asObservable();
    const meetingToken$ = this.virtualCatchupService.meetingToken$.asObservable();
    this.ref = combineLatest(roomName$, meetingToken$).subscribe(async data => {
      const roomName = data[0];
      const meetingToken = data[1] as string;

      // If we have a room and token, join the meeting
      if (roomName !== '' && meetingToken != null) {
        const state = this.callFrame.meetingState();
        if ('joined-meeting' === state) {
          // should only get into this state if a single member is connected to a meeting then clicks the browser back button
          // calling callFrame.leave() doesn't seem to do anything.
          // await this.callFrame.leave();
          this.errorMessage = 'Sorry, your Virtual Catchup has got into a funny state. Please reload this page, or wait for 30 seconds and we will reload it for you.';
          // Force a refresh
          setTimeout(() => {
            window.location.href = window.location.href;
          }, 30 * 1000);
        }
        // TODO Listen for error about room not existing?
        this.callFrame.join({
          url: this.getMeetingUrl(roomName, meetingToken),
          showLeaveButton: true,
          showFullscreenButton: true
        });

        this.sendAnalyticsEvent(roomName);

        this.showLoadingSpinner = false;
      }
      // If we have no room, leave the meeting
      if (roomName === '' && meetingToken != null && this.callFrame) await this.callFrame.leave();
    });
  }

  ngOnDestroy() {
    if (this.ref) this.ref.unsubscribe();
    if (this.callFrame) this.callFrame.destroy();
  }

  sendAnalyticsEvent(name: string) {
    this.authService._userProfileSubject.pipe(skipWhile(u => u == null)).subscribe(user => {
      this.analyticsService.eventTrack(AnalyticsCategory.VIRTUAL_CATCHUPS, AnalyticsAction.VIRTUAL_CATCHUPS_JOIN, name);
    });
  }
}
