import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { IMapState } from '@shared/models/map-state';
import { IPlace } from '@shared/models/place';
import { IPlaceResult } from '@shared/models/place-result';
import { IResultsModel } from '@shared/models/results-model';
import { ISearchModel } from '@shared/models/search-model';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { ConstantsService } from '@shared/services/constants.service';
import { EnforceProfileService } from '@shared/services/enforce-profile.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { UIService } from '@shared/services/ui.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { MeetingPlaceListUserProfilesComponent, MeetingPlaceMembersLocatorComponent } from './components';
import { MeetingPlacePresenter } from './meeting-place.presenter';
import { MeetingPlaceService } from './services/meeting-place.service';

@Component({
  selector: 'app-meeting-place',
  templateUrl: './meeting-place.page.html',
  styleUrls: ['./meeting-place.page.scss'],
  viewProviders: [MeetingPlaceMembersLocatorComponent, MeetingPlaceListUserProfilesComponent, MeetingPlacePresenter]
})
export class MeetingPlacePage implements OnDestroy {
  analyticsCategory: AnalyticsCategory = AnalyticsCategory.MEETING_PLACE;
  currentView: string = 'map-view';
  private defaultSearchModel: ISearchModel = {} as ISearchModel;
  isDesktop: boolean = false;
  isDoingSearch: boolean = false;
  isListView: boolean = false;
  isMapView: boolean = true;
  isSearchView: boolean = false;
  @ViewChild('locator', { static: false }) locator: any;
  lookupMembersThenPlacesRef: Subscription;
  lookupPlacesThenMembersRef: Subscription;
  places$: BehaviorSubject<IPlace[]> = new BehaviorSubject<IPlace[]>(null);
  platformSubscription: Subscription;
  searchModel: ISearchModel = {} as ISearchModel;
  users$: BehaviorSubject<UserObject[]> = new BehaviorSubject<UserObject[]>(null);

  constructor(
    private analyticsService: AnalyticsService,
    private enforceProfileService: EnforceProfileService,
    private meetingPlaceService: MeetingPlaceService,
    private presenter: MeetingPlacePresenter,
    private platform: Platform,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private uiService: UIService,
    private constantsService: ConstantsService
  ) {}

  get CONSTANTS() {
    return this.constantsService.constants.MEETING_PLACE;
  }

  ionViewWillEnter() {
    this.enforceProfileService.enforce();
  }

  ngOnInit() {
    this.platformSubscription = this.uiService.isLargeDisplay$.subscribe(isLargeDisplay => {
      this.isDesktop = isLargeDisplay;
      if (this.isDesktop) {
        this.isSearchView = true;
      } else {
        this.isSearchView = this.currentView === 'search-view';
      }

      // if changing from small to large and you were on the search view, change to the map view
      // two-way binding triggers onChangeView
      if (isLargeDisplay && this.currentView === 'search-view') {
        this.currentView = 'map-view';
      }
    });
    // if default currentView changes, need to update this event too
    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.MEETING_PLACE_VIEW_MAP);
  }

  ngOnDestroy() {
    this.meetingPlaceService.clearCaches();
    this.platformSubscription.unsubscribe();
    this.subscriptionService.clearSubscription(this.lookupMembersThenPlacesRef);
    this.subscriptionService.clearSubscription(this.lookupPlacesThenMembersRef);
  }

  private getUniqueMembers(members: any[]) {
    //Put members into key->value map. Any duplicates simply overwrite the earlier value. Convert back to an array
    const uniqueMembers = new Map(members.map(member => [member.uid, member]));
    return Array.from(uniqueMembers.values());
  }

  lookupMembersThenPlaces() {
    //Cancel subscription to observable with previous values
    this.subscriptionService.clearSubscription(this.lookupMembersThenPlacesRef);
    this.lookupMembersThenPlacesRef = this.meetingPlaceService.getMembersThenPlaces(this.searchModel).subscribe((results: IResultsModel) => {
      this.places$.next(results.places);
      this.users$.next(this.getUniqueMembers(results.items));
      this.locator.fitToData();
    });
    this.subscriptionService.add(this.lookupMembersThenPlacesRef);
  }

  lookupPlacesThenMembers() {
    //Cancel subscription to observable with previous values
    this.subscriptionService.clearSubscription(this.lookupPlacesThenMembersRef);
    this.lookupPlacesThenMembersRef = this.meetingPlaceService.getPlacesThenMembers(this.searchModel).subscribe((results: IResultsModel) => {
      this.places$.next(results.places);
      this.users$.next(this.getUniqueMembers(results.items));
    });
    this.subscriptionService.add(this.lookupPlacesThenMembersRef);
  }

  onChangeView(event: any) {
    switch (event.detail.value) {
      case 'list-view':
        this.isListView = true;
        this.isMapView = false;
        this.isSearchView = this.isDesktop;
        this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.MEETING_PLACE_VIEW_LIST);
        break;

      case 'search-view':
        this.isListView = false;
        this.isMapView = false;
        this.isSearchView = true;
        break;

      case 'map-view':
      default:
        this.isListView = false;
        this.isMapView = true;
        this.isSearchView = this.isDesktop;
        this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.MEETING_PLACE_VIEW_MAP);
        break;
    }
  }

  onMapBoundsChange(state: IMapState) {
    //Save initial map bounds
    if (this.isNullSearch(this.defaultSearchModel)) {
      this.defaultSearchModel.mapBounds = state.mapBounds;
      this.defaultSearchModel.mapZoom = state.mapZoom;
    }
    //Remove place from search model
    //TODO: Clear from search presenter?
    this.searchModel.place = null;
    this.searchModel.placeId = null;

    //Merge map bounds with any other search criteria
    this.searchModel.mapBounds = state.mapBounds;
    this.searchModel.mapZoom = state.mapZoom;

    // TODO is this creating multiple subscriptions?
    // Don't trigger a lookup if we've just done a search and are calling map.fitBounds
    if (!this.isDoingSearch) {
      this.lookupPlacesThenMembers();
    } else {
      this.isDoingSearch = false;
    }
  }

  onResetSearchModal() {
    const nullSearch = {} as ISearchModel;
    this.onSearch(nullSearch);
  }

  onSearch(searchModel: ISearchModel) {
    //Close search panel on mobile
    this.currentView = 'map-view';
    //clear previous search criteria
    this.searchModel = searchModel;
    if (this.isNullSearch(searchModel)) {
      //Should only get here by clicking the reset button, move map to default coordinates
      this.searchModel = {} as ISearchModel;
      this.locator.map.setView(this.defaultSearchModel.mapBounds.getCenter(), this.defaultSearchModel.mapZoom);
      //moving the map triggers this.onMapBoundsChange which calls lookupPlacesThenMembers
    } else {
      if (this.searchModel.place != null && this.searchModel.place !== '') {
        //lookup coordinates for placename/postcode
        this.meetingPlaceService
          .lookupPlace(this.searchModel)
          .pipe(first())
          .subscribe(location => {
            if (location == null || !location.coordinates) {
              this.toastService.presentToast('Sorry, that location is invalid.');
              return;
            }
            location = location as IPlaceResult;
            //Need to convert from Firestore geoPoint to Leaflet LatLng
            this.locator.map.setView([location.coordinates.latitude, location.coordinates.longitude], location.zoomTo);
            //Moving the map triggers this.onMapBoundsChange which calls lookupPlacesThenMembers
          });
      } else {
        //Set flag so that subsequent map.fitBounds doesn't trigger a lookup based on map bounds change
        this.isDoingSearch = true;
        this.lookupMembersThenPlaces();
      }
    }
  }

  private isNullSearch(searchModel: ISearchModel) {
    if (Object.keys(searchModel).length === 0) return true;
    return Object.values(searchModel).filter(x => x).length === 0;
  }
}
