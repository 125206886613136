import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { Gender } from '@shared/constants/gender';
import { IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { IMarketplaceListing } from '@shared/models/marketplace/marketplace-listing';
import { IMarketplaceOptions } from '@shared/models/marketplace/marketplace-options';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { MarketplaceService } from '@shared/services/marketplace/marketplace.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Observable, Subscription } from 'rxjs';
import { skipWhile, switchMap, take } from 'rxjs/operators';
import { MarketplaceSearchComponent } from '../marketplace-search/marketplace-search.component';

@Component({
  selector: 'marketplace-list',
  styleUrls: ['marketplace-list.component.scss'],
  templateUrl: './marketplace-list.component.html'
})
export class MarketplaceListComponent implements OnInit, OnDestroy {
  get CONSTANTS() {
    return this.constantsService.constants.MARKETPLACE.LIST;
  }

  get isAdmin() {
    return this.authService.isAdmin([AdminRole.SUPER]);
  }

  get isSearch() {
    return Object.values(this.searchCriteria).length > 0;
  }

  allMarketplaceListings: IMarketplaceListing[] = [];
  canAddNewListing$: Observable<boolean>;
  defaultSearchModel: IMarketplaceOptions = { location: '', category: '', country: null };
  hasRecords = false;
  loading = false;
  myMarketplaceListings: IMarketplaceListing[] = [];
  noListingsMessage: string;
  ownMarketplaceListingRef: Subscription;
  recordsLoaded = false;
  relatedType = IMemberThreadRelatedType.Member;
  searchCriteria: Record<string, string> = {};
  searchModel: IMarketplaceOptions | null;
  marketplaceListings: IMarketplaceListing[] = [];
  marketplaceListingsRef: Subscription;
  showMyListings: boolean = true;
  readonly THREE_DAYS_IN_MILLISECONDS = 3 * 24 * 60 * 60 * 1000;
  today: number;

  constructor(
    private analyticsService: AnalyticsService,
    public authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private modalController: ModalController,
    private marketplaceService: MarketplaceService,
    private subscriptionService: SubscriptionService
  ) {}

  getCategories(categories: string[]) {
    return this.marketplaceService.getCategoriesList(categories);
  }

  getDateFormat(marketplaceListing: IMarketplaceListing): string {
    return this.dateTimeService.getDateFormat(marketplaceListing.created);
  }

  isAlmostExpired(marketplaceListing: IMarketplaceListing): boolean {
    return marketplaceListing.deleteDate - this.today < this.THREE_DAYS_IN_MILLISECONDS ? true : false;
  }

  loadMarketplaceListings() {
    if (this.loading) return;
    this.loading = true;

    this.subscriptionService.clearSubscription(this.marketplaceListingsRef);
    this.marketplaceListingsRef = this.marketplaceService.getMoreMarketplaceListings().subscribe(marketplaceListings => {
      if (marketplaceListings == null) return;

      // Get own listings before filtering by gender/approved
      // TODO: Use a separate query to get these?
      this.myMarketplaceListings = marketplaceListings.filter(x => x.memberId === this.authService._userProfileSubject.value.uid);

      // Filter out listings pending approval for non-admins
      if (!this.authService.isAdmin([AdminRole.SUPER])) {
        marketplaceListings = marketplaceListings.filter(x => x.approved);
      }

      // TODO: Do we need to explicitly subscribe to authService._userProfileSubject to prevent race condition?
      // Do not filter by "show gender" if an admin, or if member's gender is not specified
      if (!this.authService.isAdmin([AdminRole.SUPER]) && null != this.authService._userProfileSubject.value.gender && Gender.NOT_SPECIFIED !== this.authService._userProfileSubject.value.gender) {
        marketplaceListings = marketplaceListings.filter(x => x.gender.includes(this.authService._userProfileSubject.value.gender));
      }

      this.marketplaceListings = marketplaceListings.filter(x => x.memberId !== this.authService._userProfileSubject.value.uid);
      this.allMarketplaceListings = this.marketplaceListings;

      // If we allow filter by country, then show default country as search parameter
      if (this.constantsService.constants.APP.allowOtherCountries) {
        let initialSearch = Object.assign({}, this.defaultSearchModel, { country: this.authService._userProfileSubject.value.country || '' }); // TODO what if userProfile is not loaded yet?
        this.onSearch(initialSearch);
      }

      this.hasRecords = !!this.marketplaceListings.length;
      this.recordsLoaded = true;
      this.loading = false;
    });
    this.subscriptionService.add(this.marketplaceListingsRef);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.marketplaceListingsRef);
  }

  ngOnInit() {
    this.today = this.dateTimeService.getDay();
    this.noListingsMessage = `${this.CONSTANTS.noRecordsFound}`;
    this.loadMarketplaceListings();
    this.sendAnalytics();
    this.canAddNewListing$ = this.authService._userProfileSubject.pipe(switchMap(member => this.marketplaceService.canAddNewListing(member.uid)));
  }

  onClickCriterion(key: string) {
    const criterion = this.searchModel[key];
    this.analyticsService.eventTrack(AnalyticsCategory.MARKETPLACE, AnalyticsAction.MARKETPLACE_CLEAR_SEARCH_CRITERION, criterion, { type: key });

    this.searchModel[key] = this.defaultSearchModel[key];
    this.onSearch(this.searchModel);
  }

  async onOpenMarketplaceFilterModal() {
    await this.presentMarketplaceFilterModal();
  }

  onSearch(search: IMarketplaceOptions) {
    this.searchModel = search;
    if (search == null) return;

    this.marketplaceListings = this.allMarketplaceListings.filter((listing: IMarketplaceListing) => {
      let showListing = true;
      showListing = showListing && (listing.categories.includes(search.category) || search.category === '');
      if (listing.location) {
        const normalisedListingLocation = listing.location.trim().toLowerCase();
        const normalisedSearchLocation = search.location.trim().toLowerCase();
        showListing = showListing && normalisedListingLocation.includes(normalisedSearchLocation);
      }
      if (this.constantsService.constants.APP.allowOtherCountries && search.country !== null) {
        showListing = showListing && listing.country === search.country;
      }
      return showListing;
    });
    this.hasRecords = this.marketplaceListings.length > 0;

    this.updateSearchCriteria(search);
  }

  onToggleMyListings() {
    this.showMyListings = !this.showMyListings;
  }

  async presentMarketplaceFilterModal() {
    const eventEmitter = new EventEmitter<any>();
    eventEmitter.subscribe($event => {
      modal.dismiss();
      return this.onSearch($event);
    });

    const modal = await this.modalController.create({
      component: MarketplaceSearchComponent,
      cssClass: 'marketplace-search__modal',
      componentProps: {
        options: this.searchModel,
        search: eventEmitter
      }
    });

    return await modal.present();
  }

  sendAnalytics() {
    this.analyticsService.eventTrack(AnalyticsCategory.MARKETPLACE, AnalyticsAction.MARKETPLACE_VIEW_LISTINGS);
  }

  updateSearchCriteria(options: IMarketplaceOptions = null) {
    // TODO: Could almost do this with key: value from IMarketplaceOptions, but that doesn't allow hiding default criteria
    const criteria = {};
    if (options == null) this.searchCriteria = criteria;
    if ((options.country || '').trim().length > 0) criteria['country'] = `Country: ${options.country}`;
    if ((options.location || '').trim().length > 0) criteria['location'] = `Location: ${options.location}`;
    if (options.category !== '') criteria['category'] = `Category: ${options.category}`;
    this.searchCriteria = criteria;
  }
}
