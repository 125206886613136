import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { MessageHelperService } from '@shared/services/messages/message-helper.service';
import { UIService } from '@shared/services/ui.service';

@Component({
  selector: 'chirpy-send-message-button',
  templateUrl: './chirpy-send-message-button.component.html'
})
export class ChirpySendMessageButtonComponent {
  @Input() color: string = null;
  @Input() disabled: boolean = false;
  @Input() fill: string = null;
  @Input() message: string = '';
  @Input() receiver; // member UID
  @Input() responsive: boolean = true;
  @Input() text = 'Send Message';
  @Input() validateFunction = null; // custom validator if you need different validation on different pages.

  constructor(private router: Router, private authService: AuthService, private uiService: UIService) {}

  get customFill() {
    return this.fill || (this.uiService.isMediumDisplay || !this.responsive ? 'outline' : 'default');
  }

  get customColor() {
    if (this.disabled) return 'medium';
    return this.color || (this.uiService.isMediumDisplay || !this.responsive ? 'primary' : 'default');
  }

  onSendMessage() {
    if (this.disabled || (this.validateFunction && !this.validateFunction())) return;
    if (this.message) {
      this.router.navigate(['/messages/compose', this.receiver], { queryParams: { message: this.message } });
    } else {
      this.router.navigate(['/messages/compose', this.receiver]);
    }
  }
}
