export enum Country {
  // the user's country is stored against their user profile in /centralMembers as an uppercase code.
  // Don't use two letters because CA is ambiguous between California and Canada
  AUSTRALIA = 'AU',
  CANADA = 'CAN',
  GERMANY = 'DE',
  IRELAND = 'IRE',
  NEW_ZEALAND = 'NZ',
  SINGAPORE = 'SG',
  REST_OF_WORLD = 'ROW',
  UNITED_KINGDOM = 'UK',
  UNITED_STATES = 'USA'
}

export const ALL_COUNTRIES = 'ALL';

export type CountryKey = Country | typeof ALL_COUNTRIES;
