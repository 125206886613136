export interface ICancellationOptions {
  cancelledAfter: string;
  cancelledBefore: string;
  reason: string;
}

export const DefaultCancellationOptions: ICancellationOptions = {
  cancelledAfter: '',
  cancelledBefore: '',
  reason: ''
};
