import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { ChirpyTakePhotoComponent } from '@shared/components/chirpy-take-photo/chirpy-take-photo.component';
import { Gender } from '@shared/constants/gender';
import { PhotoSize } from '@shared/constants/photo-size';
import { SelectType } from '@shared/constants/select-type';
import { ICentralMemberEditable } from '@shared/models/central-member';
import { IValueWithId } from '@shared/models/value-with-id';
import { IPlace } from '@shared/models/place';
import { ISelectOption } from '@shared/models/select-option';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { RegionService } from '@shared/services/regions/region.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { InterestsService } from '@shared/services/interests.service';
import { LocationService } from '@shared/services/location/location.service';
import { NotificationService } from '@shared/services/notifications/notification.service';
import { OrganisationService } from '@shared/services/organisation.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UserService } from '@shared/services/user/user.service';
import { WebcamUtil } from 'ngx-webcam';
import { of, Subscription } from 'rxjs';
import { first, skipWhile, take } from 'rxjs/operators';
import { MemberProfileEditPresenter } from './member-profile-edit.presenter';
import { MemberProfileEditService } from './member-profile-edit.service';

@Component({
  selector: 'app-member-profile-edit',
  templateUrl: './member-profile-edit.page.html',
  styleUrls: ['./member-profile-edit.page.scss'],
  viewProviders: [MemberProfileEditPresenter]
})
export class MemberProfileEditPage implements OnInit {
  get ASSETS() {
    return this.constantsService.constants.ASSETS;
  }

  get CONSTANTS() {
    return this.constantsService.constants.PROFILE;
  }

  get form() {
    return this.presenter.form;
  }

  get GENDERS() {
    return this.constantsService.constants.GENDERS;
  }

  get hasFewPlaces() {
    return this.hasLoadedPlaces && Object.keys(this.places).length > 0 && Object.keys(this.places).length < this.MANY_PLACES && this.member.region.trim().length > 0;
  }

  get hasFewPlacesWithin() {
    return this.hasLoadedSuburbs && Object.keys(this.suburbs).length > 0 && Object.keys(this.suburbs).length < this.MANY_PLACES && this.member.region.trim().length > 0;
  }

  get hasManyPlaces() {
    return this.hasLoadedPlaces && Object.keys(this.places).length >= this.MANY_PLACES && this.member.region.trim().length > 0;
  }

  get hasManyPlacesWithin() {
    return this.hasLoadedSuburbs && Object.keys(this.suburbs).length >= this.MANY_PLACES && this.member.region.trim().length > 0;
  }

  get hasNoPlaces() {
    return this.hasLoadedPlaces && Object.keys(this.places).length === 0 && this.member.region.trim().length >= 0;
  }

  get isHost() {
    return this.userService.isHost(this.member);
  }

  get uploadPhotoButtonText() {
    return this.platform.is('ios') ? 'Change Photo' : 'Upload Photo';
  }

  errorMessage: string;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  readonly Gender = Gender;
  hasCamera: boolean;
  hasLoadedPlaces: boolean = false;
  hasLoadedSuburbs: boolean = false;
  interestsLabel: string;
  interestsPlaceholder: string;
  isDOBEnabled: boolean = false;
  isInterestsEnabled: boolean = true; // all white-label versions currently have it enabled
  isOrganisationsEnabled: boolean = false;
  isPhoneEnabled: boolean = false;
  isTitleEnabled: boolean = false;
  isRequiredField: boolean = true;
  isUploadingPhoto: boolean;
  largePhotoURL: string;
  MANY_PLACES: number = 6;
  member: UserObject;
  onboardingMessage: string;
  organisationsLabel: string;
  organisationsPlaceholder: string;
  places: Record<string, string> = {};
  regionRef: Subscription;
  regionLabel: string;
  regions: ISelectOption[] = [];
  selectedPlace: Record<string, string>;
  selectedSuburb: Record<string, string>;
  selectType: string = SelectType.PLACE;
  suburbs: Record<string, string> = {};
  titleLabel: string;
  titlePlaceholder: string;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private regionService: RegionService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private editMemberProfileService: MemberProfileEditService,
    private environmentService: EnvironmentService,
    private organisationService: OrganisationService,
    private interestsService: InterestsService,
    private locationService: LocationService,
    private notificationService: NotificationService,
    private modalController: ModalController,
    private platform: Platform,
    private presenter: MemberProfileEditPresenter,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private userService: UserService
  ) {}

  checkForCameras() {
    // dont let iPhone/iPad users take photos with the Take Photo component because iPhone/iPad
    // has native take photo functionality when you click Upload photo.
    if (this.platform.is('ios')) return;

    // Enable/Disable "Take Photo" button depending on whether camera is present.
    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
      this.hasCamera = mediaDevices && mediaDevices.length > 0;
    });
  }

  getMemberProfile(uid: string) {
    const ref = uid === 'me' ? this.authService._userProfileSubject : this.userService.getUserProfile(uid);
    ref
      .pipe(
        skipWhile(x => !x),
        take(1)
      )
      .subscribe(user => {
        if (user == null) {
          this.errorMessage = 'User does not exist';
          return;
        }

        if (user.isOnboarding === true) {
          // Edge case: If you edit profile, then complete the signup wizard, then come back to edit profile, this callback is not run again, so the onboarding message remains
          this.onboardingMessage = this.CONSTANTS.onboardingMessage;
        }

        if (user.interests == null) user.interests = {};
        if (user.organisations == null) user.organisations = {};

        this.regions = this.regionService.getRegionsForCountry(user.country);
        this.presenter.setValue(user);
        this.member = this.presenter.memberProfile();

        // copy all member values including ones we aren't editing on this page, e.g. messageNotificationCount.
        Object.assign(this.member, user);

        this.onUpdateRegion({ detail: { value: this.member.region } }, true);
      });
  }

  getPhotoURL(uid: string) {
    this.userService.getAvatarUrl$(uid, PhotoSize.LARGE).subscribe(photoURL => {
      this.largePhotoURL = photoURL;
    });
  }

  ngOnInit() {
    this.isDOBEnabled = this.CONSTANTS.DOB.enabled;

    this.isInterestsEnabled = this.CONSTANTS.INTERESTS.enabled;
    this.interestsLabel = this.CONSTANTS.INTERESTS.label;
    this.interestsPlaceholder = this.CONSTANTS.INTERESTS.placeholder;

    this.isOrganisationsEnabled = this.CONSTANTS.ORGANISATIONS.enabled;
    this.organisationsLabel = this.CONSTANTS.ORGANISATIONS.label;
    this.organisationsPlaceholder = this.CONSTANTS.ORGANISATIONS.placeholder;

    this.isTitleEnabled = this.CONSTANTS.TITLE.enabled;
    this.titleLabel = this.CONSTANTS.TITLE.label;
    this.titlePlaceholder = this.CONSTANTS.TITLE.placeholder;

    this.isPhoneEnabled = this.CONSTANTS.PHONE.enabled;

    this.regionLabel = this.regionService.regionLabel;
    this.regions = this.regionService.locationRegions;

    const userId = this.route.snapshot.paramMap.get('userId');
    this.getMemberProfile(userId);
    this.checkForCameras();
    this.getPhotoURL(userId);
  }

  onAddInterest(item: Record<string, string>) {
    this.member.interests[item.key] = true;
    this.presenter.patchValue({ interests: this.userService.getKeys(this.member.interests) });
  }

  onAddOrganisation(item: Record<string, string>) {
    this.member.organisations[item.key] = true;
    this.presenter.patchValue({ organisations: this.userService.getKeys(this.member.organisations) });
  }

  onAddPlace(item: IValueWithId) {
    this.presenter.patchValue({
      locality: item.name,
      placeId: item.uid,
      suburbName: '',
      suburbId: ''
    });
    this.selectedPlace = { [item.uid]: item.name };
    this.locationService
      .getPlace(item.uid)
      .pipe(first())
      .subscribe((place: IPlace) => {
        // If the location selected is not a lowest-level place, show another set of places to choose from
        if (place && Object.entries(place.contains || {}).length > 0) {
          this.hasLoadedSuburbs = true;
          this.suburbs = place.contains;
        }
      });
  }

  onAddSuburb(item: IValueWithId) {
    this.presenter.patchValue({
      suburbId: item.uid,
      suburbName: item.name
    });
    this.selectedSuburb = { [item.uid]: item.name };
  }

  onImageLoad() {
    this.isUploadingPhoto = false;
  }

  onRemoveInterest(item: Record<string, string>) {
    delete this.member.interests[item.key];
    this.presenter.patchValue({ interests: this.userService.getKeys(this.member.interests) });
  }

  onRemoveOrganisation(item: Record<string, string>) {
    delete this.member.organisations[item.key];
    this.presenter.patchValue({ organisations: this.userService.getKeys(this.member.organisations) });
  }

  onUpdatePlace($event) {
    const placeId = $event.detail.value;
    this.member.locality = this.places[placeId];
    //locality is not bound to a formControl in the presenter, so must be updated manually
    this.presenter.patchValue({ locality: this.member.locality });
  }

  onUpdateSuburb($event) {
    const suburbId = $event.detail.value;
    const suburbName = this.suburbs[suburbId];
    //locality is not bound to a formControl in the presenter, so must be updated manually
    this.presenter.patchValue({ suburbName: suburbName });
  }

  onUpdateRegion($event: CustomEvent | any, force: boolean = false) {
    this.hasLoadedPlaces = false;
    this.hasLoadedSuburbs = false;
    const region = $event.detail.value;
    if (region != '') {
      if (force || this.member.region !== region) {
        // set to null to re-create location chooser, user can choose new placeId based on different region.
        let data = { region: region, placeId: null, locality: null, suburbId: null, suburbName: null };
        this.selectedPlace = {};
        this.selectedSuburb = {};

        this.member.region = region;
        this.subscriptionService.clearSubscription(this.regionRef);
        this.regionRef = this.locationService.getRegion(region, this.member.country).subscribe((placeData: IPlace) => {
          if (placeData == null) {
            this.places = {};
          } else if (placeData.contains) {
            this.places = placeData.contains;
          } else {
            // If place doesn't contain any other places, use it as the sole option
            this.places = { [placeData.uid]: placeData.displayName };
          }
          this.hasLoadedPlaces = true;

          if (force && this.member) {
            const item: IValueWithId = {
              name: this.authService._userProfileSubject.value.locality,
              uid: this.authService._userProfileSubject.value.placeId
            };
            this.onAddPlace(item);
          } else {
            this.form.patchValue(data); // Edge case: If someone uses the back button to return to this page after setting location, this line unsets the placeId value on the form
          }
        });
        this.subscriptionService.add(this.regionRef);
      }
    } else {
      //this.setLocationForm.setValue({postcode: null, placeId: null, locality: null});
      this.places = {};
    }
  }

  async onUploadFile(event) {
    // Prevent loading spinner from showing when user does the following:
    // 1. "Upload File" button
    // 2. Select a file (uploads automatically)
    // 3. "Upload File" button again
    // 4. Cancel
    if (event.target.files.length === 0) return;

    const file = event.target.files[0];
    await this.savePhoto(file);

    // Remove last uploaded file from file control.
    event.target.files.length = 0;
  }

  openFileChooser() {
    this.fileInput.nativeElement.click();
  }

  async presentTakePhotoModal() {
    const eventEmitter = new EventEmitter<any>();
    eventEmitter.subscribe($event => {
      modal.dismiss();
      return this.savePhoto($event);
    });

    const modal = await this.modalController.create({
      component: ChirpyTakePhotoComponent,
      cssClass: 'chirpy-take-photo__modal',
      componentProps: {
        takePhoto: eventEmitter
      }
    });

    return await modal.present();
  }

  async savePhoto(file) {
    this.isUploadingPhoto = true;
    await this.editMemberProfileService.uploadProfilePhoto(this.member.uid, file);
  }

  searchInterests(startsWith: string) {
    return this.interestsService.search(startsWith);
  }

  searchOrganisations(startsWith: string) {
    return this.organisationService.search(startsWith, this.member.country, this.authService.isAdmin());
  }

  searchPlaces(search: string) {
    // chirpy-places-search expects to get a per-first-letter index for each new search term, and does its own filtering
    // we have only one list, place.contains, so don't need to retrieve anything on search
    return this.reformatSearchResults(this.places);
  }

  searchSuburbs(search: string) {
    return this.reformatSearchResults(this.suburbs);
  }

  sortNull() {}

  updateMemberProfile() {
    const member = {} as UserObject;
    // Copy current offset, to check for changes
    const oldOffset = Object.assign({}, this.member.utcOffset);

    // merge form values with member profile
    const formValue = this.presenter.memberProfile();

    if (this.hasLoadedSuburbs) {
      Object.assign(formValue, { locality: formValue.suburbName || '', placeId: formValue.suburbId || '' });
    }
    delete formValue.suburbId;
    delete formValue.suburbName;

    const utcOffset = this.regionService.getUtcOffset(formValue.region, this.member.country);
    Object.assign(formValue, { utcOffset: utcOffset });

    this.editMemberProfileService
      .validateForm(formValue, this.member.uid, this.member.country)
      .then((valid: boolean) => {
        if (valid) {
          // Convert dob to IDateOfBirth
          const dob = formValue.dob || '';
          const dobFields = dob.split('-'); // 1900-12-25
          const dateOfBirth = dobFields.length === 3 ? { year: dobFields[0], month: dobFields[1], day: dobFields[2] } : null;
          delete formValue.dob; // Date of birth is not part of ICentralMemberEditable

          this.editMemberProfileService.updateMemberProfile(this.member.uid, formValue);
          this.notificationService.updateDigestTimeForMember(this.member.uid, oldOffset, utcOffset);
          this.analyticsService.eventTrack(AnalyticsCategory.MEMBERS, AnalyticsAction.MEMBERS_UPDATE_PROFILE, this.member.displayName);

          this.editMemberProfileService.updateDateOfBirth(this.member.uid, dateOfBirth);

          // NB: { replaceUrl: true } is necessary to trigger ngOnDestroy, otherwise ionic caches the page
          this.router.navigate(['/members', this.member.uid], { replaceUrl: true });
        }
      })
      .catch(err => {
        // get here if validation fails; a toast will be shown
      });
  }

  private reformatSearchResults(data: Record<string, string>) {
    const result = Object.entries(data).reduce((acc, current) => {
      acc[current[1]] = current[0]; // chirpy-places-search expects name: uid, because of structure of centralPlacesIndex
      return acc;
    }, {});
    return of(result);
  }
}
