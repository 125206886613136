import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { IContact } from '@shared/models/messages/contact';
import { IMemberThread } from '@shared/models/messages/member-thread';
import { IMemberThreadType } from '@shared/models/messages/member-thread-type';
import { IMessage } from '@shared/models/messages/message';
import { IRelatedMembers } from '@shared/models/messages/related-members';
import { ShortUserObject, UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EmailService } from '@shared/services/email/email.service';
import { ContactDatabase } from '@shared/services/messages/contact.database';
import { MessageService } from '@shared/services/messages/message.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { skipWhile, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private blockedMembers$: BehaviorSubject<IContact[]> = new BehaviorSubject([]);
  blockedMembers: IContact[] = [];
  private blockedByMembers$: BehaviorSubject<IContact[]> = new BehaviorSubject([]);
  blockedByMembers: IContact[] = [];
  private contacts$: BehaviorSubject<IContact[]> = new BehaviorSubject([]);
  contacts: IContact[] = [];
  contactsSubscription: Subscription;
  user: UserObject;

  addMemberToContacts(memberId: string, memberName: string) {
    this.analyticsService.eventTrack(AnalyticsCategory.MESSAGES, AnalyticsAction.MESSAGES_ADD_CONTACT, memberName);

    const toastMessage = `${memberName} was added to your contacts.`;
    const data = { contacts: { [memberId]: true }, names: { [memberId]: memberName } };

    return this.addOrUpdate(this.user.uid, data, toastMessage);
  }

  addOrUpdate(memberId: string, data: any, toastMessage: string = null) {
    this.contactDatabase.addOrUpdate(memberId, data);

    if (toastMessage != null) {
      return this.toastService.presentToast(toastMessage);
    }
  }

  async blockMember(memberIdToBlock: string, memberName: string, success: any = null) {
    const alert = await this.alertController.create({
      header: 'Block member',
      message: `<p>Are you sure you want to block ${memberName}?</p><p>You won't be able to receive any future messages from this member.</p><p>A report will also be sent to the Support team that you have blocked this member, please enter a reason below.</p>`,
      inputs: [
        {
          name: 'reason',
          type: 'text',
          placeholder: `Reason...`
        }
      ],
      buttons: [
        {
          text: 'Block',
          handler: data => {
            if (data.reason.trim().length === 0) {
              this.toastService.presentToast('Please enter a reason.');
              return false;
            }

            // make a copy of the messages before they are archived from your list by the blocking action.
            const memberThreads = Object.assign(
              [],
              this.messageService.memberThreads.filter(t => t.relatedIds.some(x => x === memberIdToBlock) && t.type === IMemberThreadType.DirectMessage)
            );

            this.blockMemberHandler(memberIdToBlock, memberName).then(() => {
              this.reportAbuse(memberThreads, memberIdToBlock, data);

              if (success != null) {
                success();
              }
            });
          }
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  blockMemberHandler(memberIdToBlock: string, memberName: string) {
    this.analyticsService.eventTrack(AnalyticsCategory.MESSAGES, AnalyticsAction.MESSAGES_BLOCK_MEMBER, memberName);

    const promises = [];

    // Block user
    const data = { blocked: { [memberIdToBlock]: true }, names: { [memberIdToBlock]: memberName } };
    promises.push(this.addOrUpdate(this.user.uid, data));

    // Block yourself from other user
    const myData = { blockedBy: { [this.user.uid]: true }, names: { [this.user.uid]: this.user.displayName } };
    promises.push(this.addOrUpdate(memberIdToBlock, myData));

    // Archive your MessageThread so you don't see it, Blocked user can still see the conversation but they will see a message saying you Blocked them.
    promises.push(this.updateMemberThread(this.user.uid, memberIdToBlock, { isArchived: true }));

    promises.push(this.toastService.presentToast(`${memberName} was blocked.`));

    return Promise.all(promises);
  }

  constructor(
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private contactDatabase: ContactDatabase,
    private dateTimeService: DateTimeService,
    private emailService: EmailService,
    private messageService: MessageService,
    public subscriptionService: SubscriptionService,
    public toastService: ToastService,
    private userService: UserService
  ) {
    this.init();
  }

  getContacts(): Observable<IContact[]> {
    return this.contacts$.pipe(skipWhile(x => !x));
  }

  getBlockedMembers(): Observable<IContact[]> {
    return this.blockedMembers$.pipe(skipWhile(x => !x));
  }

  getEitherBlockedMembers(): Observable<IContact[]> {
    return combineLatest(this.blockedMembers$, this.blockedByMembers$).pipe(
      skipWhile(x => !x),
      map(([blocked, blockedBy]) => {
        return [...blocked, ...blockedBy];
      })
    );
  }

  init() {
    this.authService._userProfileSubject.subscribe(user => {
      if (user == null) {
        return;
      }

      this.user = user;
      // TODO: This will call initContacts every time the profile is updated
      this.initContacts();
    });
  }

  isBlocked(memberId: string) {
    return this.blockedMembers.some((value: IContact) => value.relatedId === memberId);
  }

  isBlockedBy(memberId: string) {
    return this.blockedByMembers.some((value: IContact) => value.relatedId === memberId);
  }

  isContact(memberId: string) {
    return this.contacts.some((value: IContact) => value.relatedId === memberId);
  }

  // NB This returns true for A isBlockedBy B and also A blocks B, i.e. the communication channel between A and B is blocked from either direction
  isEitherBlocked(memberId: string) {
    return this.isBlocked(memberId) || this.isBlockedBy(memberId);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.contactsSubscription);
  }

  parseContacts(relatedMembers: IRelatedMembers): IContact[] {
    // Initialise data
    const blocked = relatedMembers.blocked || {};
    const blockedBy = relatedMembers.blockedBy || {};
    const contacts = relatedMembers.contacts || {};
    const names = relatedMembers.names || {};

    // Get unique list of uids for contacts or blocked members
    const keys = Array.from(new Set([...Object.keys(blocked), ...Object.keys(blockedBy), ...Object.keys(contacts)]));

    if (keys.length === 0) {
      return [];
    }

    // Convert data into IContact form
    const parsed: IContact[] = keys.map(uid => {
      const name = names[uid] ? names[uid] : '';
      const member = { name, isBlocked: false, isBlockedBy: false, isContact: false, memberId: this.user.uid, relatedId: uid } as IContact;
      if (blocked[uid]) {
        member.isBlocked = blocked[uid];
      }
      if (blockedBy[uid]) {
        member.isBlockedBy = blockedBy[uid]; // If either party blocks the other, the thread is blocked for both
      }
      if (contacts[uid]) {
        member.isContact = contacts[uid];
      }
      return member;
    });

    return parsed;
  }

  async removeMemberFromContacts(memberId, memberName) {
    const alert = await this.alertController.create({
      header: 'Remove contact',
      message: `<p>Are you sure you want to remove ${memberName} from your contacts?</p>`,
      buttons: [
        {
          text: 'Remove',
          handler: () => this.removeMemberFromContactsHandler(memberId, memberName)
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  reportAbuse(memberThreads: IMemberThread[], memberIdToBlock: string, data: any) {
    this.userService
      .getUserProfile(memberIdToBlock)
      .pipe(
        skipWhile(x => x == null),
        take(1)
      )
      .subscribe((user: UserObject) => {
        const reportedMember = {
          uid: user.uid,
          displayName: user.displayName,
          firstName: user.firstName,
          email: user.email, // TODO: Isn't this inaccessible as it's on centralMembersPrivate for the other member?
          country: user.country
        };

        const member = {
          uid: this.authService.member.uid,
          displayName: this.authService.member.displayName,
          firstName: this.authService.member.firstName,
          email: this.authService.member.email,
          country: this.authService.member.country
        };

        if (memberThreads.length === 0) {
          return this.sendAbuseReportEmail(data, reportedMember, member, []);
        }

        const reportMessages = [];
        memberThreads.forEach((memberThread: IMemberThread) => {
          this.messageService
            .getMessages(memberThread.threadId, false, 0, 50)
            .pipe(
              skipWhile(x => x == null),
              take(1)
            )
            .subscribe((messages: IMessage[]) => {
              const heading = `<b>ThreadId:</b> ${memberThread.threadId}<br>`;
              reportMessages.push(heading);

              messages = messages.reverse();
              messages.forEach(message => {
                const date = new Date(message.dateTimeSent).toLocaleString();
                const reportMessage = `[${date}] ${message.name}: ${message.content}`;
                reportMessages.push(reportMessage);
              });

              return this.sendAbuseReportEmail(data, reportedMember, member, reportMessages);
            });
        });
      });
  }

  sendAbuseReportEmail(data: any, reportedMember: ShortUserObject, member: ShortUserObject, reportMessages: string[]) {
    const reason = data.reason;
    return this.emailService.sendAbuseReportEmail(reason, reportedMember, member, reportMessages);
  }

  unblockMember(memberIdToUnblock: string, memberName: string) {
    this.analyticsService.eventTrack(AnalyticsCategory.MESSAGES, AnalyticsAction.MESSAGES_UNBLOCK_MEMBER, memberName);

    const promises = [];

    // Unblock user
    const data = { blocked: { [memberIdToUnblock]: false } };
    promises.push(this.addOrUpdate(this.user.uid, data));

    // Unblock yourself from the other user
    const myData = { blockedBy: { [this.user.uid]: false } };
    promises.push(this.addOrUpdate(memberIdToUnblock, myData));

    // Un-archive your MessageThread to see a previously blocked user's chat.
    promises.push(this.updateMemberThread(this.user.uid, memberIdToUnblock, { isArchived: false }));

    promises.push(this.toastService.presentToast(`${memberName} was unblocked.`));

    return Promise.all(promises);
  }

  updateMemberThread(memberId: string, relatedId: string, newData: any) {
    return this.contactDatabase.updateMemberThread(memberId, relatedId, newData);
  }

  private initContacts() {
    this.subscriptionService.clearSubscription(this.contactsSubscription);
    this.contactsSubscription = this.contactDatabase.getContacts(this.user.uid).subscribe(data => {
      data = typeof data === 'undefined' ? {} : data;

      const parsed = this.parseContacts(data);
      this.blockedByMembers = parsed.filter(x => x.isBlockedBy);
      this.blockedByMembers$.next(this.blockedByMembers);

      this.blockedMembers = parsed.filter(x => x.isBlocked);
      this.blockedMembers$.next(this.blockedMembers);

      this.contacts = parsed.filter(x => x.isContact === true && x.isBlocked !== true && x.isBlockedBy !== true);
      this.contacts$.next(this.contacts);
    });
    this.subscriptionService.add(this.contactsSubscription);
  }

  private removeMemberFromContactsHandler(memberId: string, memberName: string) {
    this.analyticsService.eventTrack(AnalyticsCategory.MESSAGES, AnalyticsAction.MESSAGES_REMOVE_CONTACT, memberName);

    const toastMessage = `${memberName} was removed from your contacts.`;
    const data = { contacts: { [memberId]: false } };

    return this.addOrUpdate(this.user.uid, data, toastMessage);
  }
}
