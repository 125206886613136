import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { AuthService } from '@shared/services/auth.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class MemberProfileEditGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const uid = route.params.userId;
    return this.authService.userProfileObservable.pipe(
      map(user => {
        if (user != null) {
          if (this.authService.isAdmin([AdminRole.SUPER]) || user.uid === uid) {
            return true;
          } else {
            this.router.navigate(['/error/not-found']);
            return false;
          }
        } else {
          this.authService.redirectToLogin(state.url);
          return false;
        }
      }),
      take(1) // stop listening to the observable
    );
  }
}
