import { FirebaseObject } from '@shared/models/firebase-object';

export class PlayerObject extends FirebaseObject {
  playerName: string;
  gameId: string;
  playerId: string;

  deserialize(input: any): this {
    super.deserialize(input);
    Object.assign(this, input);
    return this;
  }
}
