import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventType } from '@infrastructure/constants/event-type';
import { AlertController } from '@ionic/angular';
import { AttendanceService } from '@shared/services/attendance/attendance.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { UserService } from '@shared/services/user/user.service';
import { map, tap } from 'rxjs/operators';
import { BaseReportComponent } from '../../base-report/base-report.component';

@Component({
  selector: 'app-reports-coins-claimed-by-member',
  templateUrl: '../../base-report/base-report.component.html'
})
export class CatchupsAttendedByMemberComponent extends BaseReportComponent {
  columns = [
    { label: 'Date', field: 'date', class: 'data-table__column-responsive-bold' },
    { label: 'CatchUp name', field: 'title' },
    { label: 'Group name', field: 'group' },
    { label: 'Type', field: 'eventType' }
  ];
  config = {
    routerLink: {
      path: '/catchups',
      id: [':uid']
    }
  };
  dateFrom: string = '';
  dateTo: string = '';
  hasParameters = false;
  header: string = '';
  instructions = '';
  title = '';

  constructor(alertController: AlertController, private attendanceService: AttendanceService, private constantsService: ConstantsService, private dateTimeService: DateTimeService, private route: ActivatedRoute) {
    super(alertController);
    this.loadData(); // TODO: run this on ionViewWillEnter?
  }

  loadData() {
    const branding = this.constantsService.constants.CATCHUPS.branding;
    const brandingTitleCase = branding.charAt(0).toUpperCase() + branding.slice(1);
    this.instructions = `No ${branding}s attended`;
    this.title = `${brandingTitleCase}s attended report by member`;

    const memberId = this.route.snapshot.paramMap.get('memberId');
    if (!memberId) return;

    this.data$ = this.attendanceService.getAttendanceWithType(memberId).pipe(
      map(events => {
        const formattedEvents: any[] = events.map((x: any) => {
          x.date = this.dateTimeService.formatDate(x.datetime, 'YYYY-MM-DD');
          x.eventType = this.applyBrandingForEventType(x.eventType);
          return x;
        });
        return formattedEvents;
      }),
      tap(events => {
        const counts = this.attendanceService.getCounts(events);
        let output = `<h2>Total</h2><ul>`;
        for (const [key, value] of Object.entries(counts)) {
          output += `<li>${key}: <strong>${value}</strong></li>`;
        }
        output += `</ul>`;
        this.header = output;
      })
    );
  }

  private applyBrandingForEventType(eventType: EventType) {
    switch (eventType) {
      case EventType.CatchUp:
        return this.constantsService.constants.CATCHUPS.branding;

      case EventType.Social:
        return this.constantsService.constants.SOCIAL.branding;

      default:
        return eventType;
    }
  }
}
