import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserObject } from '@shared/models/user-object';
import { ConstantsService } from '@shared/services/constants.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'meeting-place-members-locator-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['pin.component.scss']
})
export class MeetingPlaceMembersLocatorPinComponent {
  @Input() count: number;
  @Input() members: UserObject[];
  @Input() placeName: string;

  constructor(private constantsService: ConstantsService, private userService: UserService, private modalController: ModalController) {}

  onClose() {
    this.modalController.dismiss();
  }

  openMemberProfile(member: UserObject) {
    this.modalController.dismiss(); // close map pin modal before navigating to a different page.
    this.userService.openMemberProfile(member.uid);
  }
}
