import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageType } from '@shared/constants/message-type';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { ConstantsService } from '@shared/services/constants.service';
import { MessageService } from '@shared/services/messages/message.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-catchup-messages',
  styleUrls: ['./catchup-messages.component.scss'],
  templateUrl: './catchup-messages.component.html'
})
export class CatchupMessagesComponent implements OnInit {
  canSendMessage: boolean = true;
  catchupId: string;
  catchupName: string;
  catchupSubscription: Subscription;
  CONSTANTS: any;
  messageType: MessageType = MessageType.CATCHUP_NOTE;
  takeTwo: boolean = false;
  threadId: string;

  constructor(private catchupService: CatchupService, private constantsService: ConstantsService, private messageService: MessageService, private route: ActivatedRoute, private subscriptionService: SubscriptionService) {}

  ngOnInit() {
    this.catchupId = this.route.snapshot.parent.paramMap.get('catchupId');
    // TODO: All the components are now fetching the CatchUp. Add a catchup-detail-service to get it only once?
    this.catchupSubscription = this.catchupService.getCatchup(this.catchupId).subscribe(catchup => {
      if (catchup == null) return;
      this.catchupName = catchup.title;
    });
    this.subscriptionService.add(this.catchupSubscription);
    this.CONSTANTS = this.constantsService.constants.CATCHUPS.DETAIL;
    this.threadId = `catchup_${this.catchupId}`;
  }

  onSendMessage(messageData) {
    const threadType = null;
    const emailData = { catchupId: this.catchupId, catchupName: this.catchupName };
    this.messageService.createMessage(messageData, this.threadId, this.messageType, threadType, emailData);
  }
}
