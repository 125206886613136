import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDateOfBirth } from '@infrastructure/models/date-of-birth';
import { AvatarSize } from '@shared/constants/avatar-size';
import { RoomShareMatchStatus } from '@shared/constants/room-share-match-status';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'room-share-profile',
  templateUrl: './room-share-profile.component.html',
  styleUrls: ['./room-share-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoomShareProfileComponent implements OnInit {
  get ASSETS() {
    return this.constantsService.constants.ASSETS;
  }

  get CONSTANTS() {
    return this.constantsService.constants.PROFILE;
  }

  me: string;
  mine: boolean = false;
  @Input() member: UserObject;
  readonly RoomShareMatchStatus = RoomShareMatchStatus;
  readonly size: AvatarSize = AvatarSize.LARGE;

  constructor(private authService: AuthService, private constantsService: ConstantsService, private route: ActivatedRoute, private router: Router, private userService: UserService) {}

  getAge(dateOfBirth: IDateOfBirth) {
    return this.userService.getAge(dateOfBirth);
  }

  ngOnInit() {
    this.authService._userProfileSubject.subscribe(profile => {
      if (profile) {
        this.me = profile.uid;
        this.mine = this.me === this.member.uid;
      } else {
        this.me = '';
        this.mine = false;
      }
    });
  }

  onSendMessage() {
    // track analytics here
  }

  openMatches(isMe: boolean) {
    if (isMe) this.router.navigate(['match'], { relativeTo: this.route });
  }

  openMemberProfile(uid: string) {
    this.userService.openMemberProfile(uid);
  }
}
