import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { BadgeType } from '@shared/constants/badge-type';
import { IIcon } from '@shared/models/icon';
import { ConstantsService } from '@shared/services/constants.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { BaseAdminComponent } from '../base-admin/base-admin.component';

@Component({
  selector: 'app-add-icon-badge',
  templateUrl: '../base-admin/base-admin.component.html',
  styleUrls: ['../base-admin/base-admin.component.scss']
})
export class AddIconBadgeComponent extends BaseAdminComponent {
  get BADGES() {
    return this.constantsService.constants.ASSETS.BADGES.icon;
  }

  confirmHeader = 'Add icon badge';
  confirmButton = 'Add badge';
  confirmInputs = [];
  member: string;
  placeholder = 'Search for the member you want to give a badge to';
  title = 'Add status';

  constructor(alertController: AlertController, private constantsService: ConstantsService, private router: Router, private toastService: ToastService, private userService: UserService) {
    super(alertController);
  }

  confirmMessage(memberId: string, memberName: string) {
    // This function is called once when the dialog is initialised, so save member name
    this.member = memberName;
    return `<p>Choose a badge from the options below.</p>`;
  }

  selectMemberHandler({ memberId, memberName, data }) {
    let message = '';
    this.userService
      .setBadge(memberId, data, BadgeType.ICON, true)
      .then(result => {
        message = `${memberName} now has ${this.BADGES[data].title} badge.`;
      })
      .catch(err => {
        message = `Couldn't add ${data} (${this.BADGES[data].title}) badge to ${memberName}: ${err}`;
      })
      .finally(() => {
        this.toastService.presentToast(message);
        this.router.navigate(['/admin']);
      });
  }

  setup() {
    const badges: Record<string, IIcon> = this.BADGES;
    this.confirmInputs = [];
    for (const [key, badge] of Object.entries(badges)) {
      if (badge.title) {
        this.confirmInputs.push({
          handler: input => {
            this.updateText(input.value);
          },
          label: badge.title,
          name: 'badge',
          type: 'radio',
          value: key
        });
      }
    }
  }

  updateText(badge: string) {
    this.alert.header = `Add ${badge} badge`;
    const badgeData: IIcon = this.BADGES[badge] || null;
    let message = `<p>Add ${badgeData.title || badge} badge to ${this.member}?</p>`;
    this.alert.message = badgeData && badgeData.description ? `${message}<p>${badgeData.description}</p>` : message;
  }
}
