import { Injectable } from '@angular/core';
import { IPlaceResult } from '@shared/models/place-result';
import { IPlace } from '@shared/models/place';
import { IResultsModel } from '@shared/models/results-model';
import { ISearchModel } from '@shared/models/search-model';
import { UserObject } from '@shared/models/user-object';
import { ConstantsService } from '@shared/services/constants.service';
import { LocationService } from '@shared/services/location/location.service';
import { UserService } from '@shared/services/user/user.service';
import { LatLngBounds } from 'leaflet';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { MeetingPlaceDatabase } from './meeting-place.database';

@Injectable({
  providedIn: 'root'
})
export class MeetingPlaceService {
  readonly MAX_SEARCH_RESULTS = 30;

  constructor(private constantsService: ConstantsService, private locationService: LocationService, private meetingPlaceDatabase: MeetingPlaceDatabase) {}

  clearCaches(): void {
    this.meetingPlaceDatabase.clearCaches();
  }

  clusterMembersIntoPlaces(members: UserObject[]) {
    const placeIds: string[] = members.map(x => x.placeId);
    const uniquePlaces: string[] = Array.from(new Set(placeIds));
    const places: IPlace[] = [];

    uniquePlaces.forEach(placeId => {
      const membersInPlace = members.filter(member => member.placeId === placeId && member.coordinates != null);
      if (membersInPlace.length) {
        const place = {
          coordinates: membersInPlace[0].coordinates,
          zoomTo: 15, // Hardcode this; it's not relevant because we are going to call map.fitBounds to fit the map to the results
          displayName: membersInPlace[0].locality,
          memberCount: membersInPlace.length,
          uid: membersInPlace[0].placeId
        } as IPlace;
        places.push(place);
      }
    });
    return places;
  }

  getMembers(q: ISearchModel, places: IPlace[]): Observable<UserObject[]> {
    const allowOtherCountries = this.constantsService.constants.APP.allowOtherCountries;
    return this.meetingPlaceDatabase.getMembers(q, places, allowOtherCountries);
  }

  getMembersThenPlaces(q: ISearchModel): Observable<IResultsModel> {
    return this.getMembers(q, null).pipe(
      map(items => {
        const places = this.clusterMembersIntoPlaces(items);
        const results = { items, places } as IResultsModel;
        return results;
      })
    );
  }

  getPlaces(q: ISearchModel): Observable<IPlace[]> {
    return this.meetingPlaceDatabase.getPlaces(q).pipe(
      //filter out places within queried geohashes which are not visible on the map
      map(places => {
        const visiblePlaces = places.filter(place => this.isPlaceVisibleOnMap(place, q.mapBounds));
        return visiblePlaces;
      })
    );
  }

  getPlacesThenMembers(q: ISearchModel): Observable<IResultsModel> {
    return this.getPlaces(q).pipe(
      mergeMap(
        places => this.getMembers(q, places),
        (places, items) => {
          //If we search for name and place, make sure markers reflect only matching results
          if (!this.isNullOrEmpty(q.memberName)) places = this.clusterMembersIntoPlaces(items);
          const results = { items, places } as IResultsModel;
          return results;
        }
      )
    );
  }

  isPlaceVisibleOnMap(place: IPlace, mapBounds: LatLngBounds) {
    return mapBounds.contains([place.coordinates.latitude, place.coordinates.longitude]);
  }

  isNullOrEmpty(query: string) {
    return query == null || query.length === 0;
  }

  lookupPlace(query: ISearchModel): Observable<IPlaceResult> {
    if (query.placeId) {
      return this.locationService.getPlace(query.placeId);
    } else {
      // Shouldn't happen
    }
  }
}
