import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';
import { BeaconService } from '@shared/services/beacon.service';
import { UIService } from '@shared/services/ui.service';
import { VersionService } from '@shared/services/version/version.service';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  showFooter = true;

  constructor(private activatedRoute: ActivatedRoute, private angulartics: Angulartics2GoogleGlobalSiteTag, private beaconService: BeaconService, private platform: Platform, private router: Router, private titleService: Title, private uiService: UIService, private versionService: VersionService) {
    this.initializeApp();
    this.initializeTitles();
    angulartics.startTracking();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.uiService.showFooterSubject.subscribe(isShow => {
        this.showFooter = isShow;
      });

      this.beaconService.showBeacon();

      this.versionService.checkForNewVersion();
    });
  }

  initializeTitles() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else if (child.snapshot.params && child.snapshot.params['pageId']) {
              const path = child.snapshot.params['pageId'] || '';
              return path.charAt(0).toUpperCase() + path.slice(1).replace('-', ' ');
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.titleService.setTitle(data);
        }
      });
  }
}
