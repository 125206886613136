import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';

@Injectable()
export class LogoutResolver implements Resolve<any> {
  constructor(public afAuth: AngularFireAuth, private analyticsService: AnalyticsService, private authService: AuthService, public loadingController: LoadingController, private router: Router) {}

  async resolve(route: ActivatedRouteSnapshot) {
    this.authService.logout(); // Need to stop listening to database
    this.analyticsService.eventTrack(AnalyticsCategory.AUTH, AnalyticsAction.AUTH_LOGOUT);
    const loading = await this.loadingController.create({ message: 'Processing' });
    return loading.present().then(() => {
      this.afAuth.auth.signOut().then(
        response => {
          loading.dismiss();
          this.router.navigate(['auth/login']);
        },
        error => {
          loading.dismiss();
          this.router.navigate(['auth/login']);
          console.log(error);
        }
      );
    });
  }
}
