import { Component, Input } from '@angular/core';
import { ISocial } from '@shared/models/social/social';
import { IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { UIService } from '@shared/services/ui.service';

@Component({
  selector: 'social-detail-header',
  templateUrl: './social-detail-header.component.html',
  styleUrls: ['./social-detail-header.component.scss']
})
export class SocialDetailHeaderComponent {
  get isExtraLargeDisplay() {
    return this.uiService.isExtraLargeDisplay;
  }

  @Input() social: ISocial;
  relatedType = IMemberThreadRelatedType.Event;
  @Input() showAttendeesHyperlink = true;
  @Input() user: UserObject;

  constructor(private authService: AuthService, private constantsService: ConstantsService, private uiService: UIService) {}
}
