import { Directive, HostListener } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { EnvironmentService } from '@shared/services/environment.service';

@Directive({
  selector: '[chirpyHrefToRouterLink]'
})
export class ChirpyHrefToRouterLinkDirective {
  domainRegexp: RegExp;

  constructor(private environmentService: EnvironmentService, private router: Router) {
    // TODO: Store this on enviromentService?
    this.domainRegexp = new RegExp(this.environmentService.domainRegexp, 'igm');
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    const clickedElement = event.target;
    if (this.canUseRouterLink(clickedElement)) {
      this.cancelClick(event);
      const localHref = clickedElement.pathname;
      const queryParams = this.convertQueryStringToParams(clickedElement.search);

      if (Object.keys(queryParams).length > 0) {
        this.router.navigate([localHref], { queryParams: queryParams });
      } else {
        this.router.navigate([localHref]);
      }
    }
  }

  private cancelClick(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  private canUseRouterLink(element): boolean {
    // TODO: Add a class in the linkify pipe instead?
    return !!element.href && element.href.match(this.domainRegexp);
  }

  private convertQueryStringToParams(search: string) {
    // When node/angular supports it, use const params = Object.fromEntries(new URLSearchParams(search));
    search = search.replace('?', ''); //remove initial ?
    const params = {};
    search.replace(/([^=&]+)=([^&]*)/g, (m, key, value) => {
      params[decodeURIComponent(key)] = decodeURIComponent(value);
      return m;
    });
    return params;
  }
}
