import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ALL_COUNTRIES, CountryKey } from '@shared/constants/country';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { IRoomShare } from '@shared/models/trips/room-share';
import { BaseDatabase } from '@shared/services/base.database';
import { CacheService } from '@shared/services/cache.service';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoomShareDatabase extends BaseDatabase {
  readonly FETCH_ALL_RECORDS = 9999; // TODO: Check if this is still the limit per query in Firebase

  createRoomShareForMember(tripId: string, memberId: string, data: any) {
    // Use updateSubDocument rather than create so we can use the memberId as the uid
    return this.updateSubDocument(this.COLLECTION.TRIPS, tripId, this.COLLECTION.ROOM_SHARE, memberId, data);
  }

  deleteRoomShareForMember(tripId: string, memberId: string): Promise<void> {
    return this.deleteSubDocument(this.COLLECTION.TRIPS, tripId, this.COLLECTION.ROOM_SHARE, memberId);
  }

  getRoomShareForMember(tripId: string, memberId: string): Observable<IRoomShare> {
    return this.getSubDocument<IRoomShare>(this.COLLECTION.TRIPS, tripId, this.COLLECTION.ROOM_SHARE, memberId);
  }

  getRoomShareForTrip(tripId: string, foundMatches: boolean, country: CountryKey): Observable<IRoomShare[]> {
    const records = this.FETCH_ALL_RECORDS;
    const whereConditions: IWhereCondition[] = [
      {
        field: 'foundMatches',
        operator: '==',
        value: foundMatches
      }
    ];
    if (country !== ALL_COUNTRIES) {
      whereConditions.push({
        field: 'country',
        operator: '==',
        value: country
      });
    }
    const orderConditions: IOrderCondition[] = []; //[{ field: 'date', direction: 'asc' }];
    const queryFn = this.createQueryFunction(whereConditions, orderConditions, records);
    return this.getDocumentsBySubcollectionQuery<IRoomShare>(this.COLLECTION.TRIPS, tripId, this.COLLECTION.ROOM_SHARE, queryFn);
  }

  getRoomShareMatchesForTrip(tripId: string): Observable<Record<string, any>> {
    return this.getSubDocument<any>(this.COLLECTION.TRIPS, tripId, this.COLLECTION.MATCHES, 'list');
  }

  strictUpdateRoomShare(tripId: string, memberId: string, data: any) {
    return this.strictUpdateSubDocument(this.COLLECTION.TRIPS, tripId, this.COLLECTION.ROOM_SHARE, memberId, data);
  }

  updateRoomShare(tripId: string, memberId: string, data: any) {
    const merge = true;
    return this.updateSubDocument(this.COLLECTION.TRIPS, tripId, this.COLLECTION.ROOM_SHARE, memberId, data, merge);
  }

  updateRoomShareMatches(tripId: string, data: any) {
    const merge = true;
    return this.updateSubDocument(this.COLLECTION.TRIPS, tripId, this.COLLECTION.MATCHES, 'list', data, merge);
  }
}
