import { Component, OnInit } from '@angular/core';
import { IColumn } from '@shared/models/column';
import { IDataTableConfig } from '@shared/models/data-table-config';
import { ICancellationOptions, DefaultCancellationOptions } from '@shared/models/cancellations/cancellation-options';
import { CancellationService } from '@shared/services/cancellations/cancellation.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { first } from 'rxjs/operators';
import { IInput } from '@shared/models/input';
import { BaseLogPage } from '../../base-log/base-log.page';

@Component({
  selector: 'app-logs-chit-chat-log',
  styleUrls: ['../../base-log/base-log.page.scss'],
  templateUrl: '../../base-log/base-log.page.html'
})
export class CancellationLogComponent extends BaseLogPage {
  columns: IColumn[] = [
    { label: 'Date', field: 'date', class: 'data-table__column-responsive-bold' },
    { label: 'When', field: 'when' },
    { label: 'Reason', field: 'fullReason' },
    { label: 'Plan', field: 'subscriptionPlan' },
    { label: 'Name', field: 'fullName', class: 'data-table__column-responsive-italic' },
    { label: 'Email', field: 'email', class: 'data-table__column-responsive-italic' },
    { label: 'Phone', field: 'phone' },
    { label: 'Joined', field: 'dateJoined' },
    { label: 'Location', field: 'locality' },
    { label: 'Coins', field: 'coinsBalance' },
    { label: 'Romance access', field: 'canAccessRomance' },
    { label: 'Participated', field: 'participated' },
    { label: 'Attendance', field: 'attendance' },
    { label: 'Groups', field: 'groups' },
    { label: 'Date of birth', field: 'dob' }
  ];
  config: IDataTableConfig = {
    routerLink: null
  };
  DEFAULT_OPTIONS = DefaultCancellationOptions;
  interfaceOptions: any = { cssClass: 'wide-select' };
  searchModel: ICancellationOptions = DefaultCancellationOptions;
  title = `Cancellation log`;
  //fields is out of alphabetical order so we can reference other instance members
  fields: IInput[] = [
    {
      label: 'Cancelled after: ',
      name: 'cancelledAfter',
      type: 'datetime-local'
    },
    {
      label: 'Cancelled before: ',
      name: 'cancelledBefore',
      type: 'datetime-local'
    },
    {
      label: 'Reason: ',
      name: 'reason',
      placeholder: 'Select reason...',
      type: 'select',
      attributes: {
        interfaceOptions: this.interfaceOptions
      },
      value: null // initialise later
    }
  ];

  constructor(private cancellationService: CancellationService, private constantsService: ConstantsService, dateTimeService: DateTimeService) {
    super(dateTimeService);
    this.initPresenter();
  }

  initPresenter() {
    const reasonIndex = this.fields.findIndex(x => x.name === 'reason');
    if (reasonIndex > -1) {
      // Convert array of reasons into key-value pairs
      const reasonOptions = {};
      const reasons = this.constantsService.constants.ACCOUNT.CANCEL.reasons || [];
      for (const reason of reasons) {
        reasonOptions[reason] = reason;
      }
      reasonOptions['Other'] = 'Other';

      this.fields[reasonIndex].value = reasonOptions;
    }
  }

  loadData(append: boolean = false, lastTimestamp: number = null) {
    this.cancellationService
      .getAllCancellations(this.searchModel, lastTimestamp)
      .pipe(first(x => !!x))
      .subscribe(cancellations => {
        this.isLoading = false;
        if (cancellations.length > 0) {
          this.canLoadMore = true;
          const lastCancellation = cancellations[cancellations.length - 1];
          if (lastCancellation.dateTimeCancelled != null) this.lastTimestamp = lastCancellation.dateTimeCancelled;
        }
        const viewCancellations = [];
        cancellations.forEach(cancellation => {
          const item: any = Object.assign({}, cancellation);
          item.date = this.dateTimeService.formatDate(cancellation.dateTimeCancelled);
          (item.fullReason = item.otherReason ? `${item.reason}: ${item.otherReason}` : item.reason), (item.when = item.cancellationType === 'cancel-end-of-term' ? 'End of period' : 'Now');
          viewCancellations.push(item);
        });

        if (append) {
          this.data = [...this.data, ...viewCancellations];
        } else {
          this.data = viewCancellations;
        }
      });
  }

  updateSearchCriteria(options: ICancellationOptions = null) {
    // TODO: Could almost do this with key: value from ICoinsOptions, but that doesn't allow hiding default criteria
    const criteria = {};

    if (options == null) this.searchCriteria = criteria;
    if ((options.cancelledAfter || '').trim().length > 0) criteria['cancelledAfter'] = `Cancelled after: ${options.cancelledAfter}`;
    if ((options.cancelledBefore || '').trim().length > 0) criteria['cancelledBefore'] = `Cancelled before: ${options.cancelledBefore}`;
    if ((options.reason || '').trim().length > 0) criteria['reason'] = `Reason: ${options.reason}`;
    this.searchCriteria = criteria;
  }
}
