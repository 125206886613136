import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { IDateOfBirth } from '@infrastructure/models/date-of-birth';
import { AlertController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { Gender } from '@shared/constants/gender';
import { ALL_AGES } from '@shared/models/age-range';
import { UserObject } from '@shared/models/user-object';
import { ActivityService, ActivityType } from '@shared/services/activity';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EmailService } from '@shared/services/email/email.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { NotificationTarget, NotificationService } from '@shared/services/notifications';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, skipWhile, take } from 'rxjs/operators';
import { IRomanceListing } from '../models/romance-listing';
import { IRomanceOptions } from '../models/romance-options';
import { RomanceDatabase } from './romance.database';

@Injectable({
  providedIn: 'root'
})
export class RomanceService implements OnDestroy {
  private get CONSTANTS() {
    return this.constantsService.constants.ROMANCE.SERVICE;
  }

  get member() {
    return this.authService._userProfileSubject.value;
  }

  hasMore: boolean;
  readonly INCREMENT = 10;
  listingsSubscription: Subscription;
  optionsSubscription: Subscription;
  recordsToFetch = this.INCREMENT; // get 10 records initially. when user clicks Load more button then increase by 10. this enables us to have live updating of newly added and removed records.
  romanceListings: IRomanceListing[] = [];

  constructor(
    private activityService: ActivityService,
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private emailService: EmailService,
    private environmentService: EnvironmentService,
    private notificationService: NotificationService,
    private romanceDatabase: RomanceDatabase,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private userService: UserService
  ) {}

  approveRomanceListing(romanceListing: IRomanceListing, member: UserObject) {
    // Send notification only on first approval
    const sendNotification = !romanceListing.notificationSent;
    // Save a DB read by setting notificationSent to true on every approval
    const data = { uid: romanceListing.uid, approved: true, notificationSent: true };

    return this.romanceDatabase.updateRomanceListing(data).then(result => {
      this.userService.grantRomanceAccess(romanceListing.uid);
      this.updateNotificationForMember(romanceListing);
      if (sendNotification) this.sendListingCreatedNotification(romanceListing, member);
    });
  }

  async deleteRomanceListing(romanceListing: IRomanceListing) {
    const alert = await this.alertController.create({
      header: this.CONSTANTS.deleteHeader,
      message: this.CONSTANTS.deleteMessage,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: data => this.deleteRomanceListingHandler(romanceListing)
        }
      ]
    });

    await alert.present();
  }

  getAgeRange(member: UserObject) {
    return this.dateTimeService.getAgeRange(member);
  }

  getDefaultRomanceOptions(member: UserObject): IRomanceOptions {
    const oppositeGender = member.gender === Gender.MALE ? Gender.FEMALE : Gender.MALE;
    return { uid: null, region: '', ageRange: ALL_AGES.label, /*seeking: oppositeGender,*/ country: member.country } as any;
  }

  getGenderValue(gender: Gender) {
    // TODO: Move plural into chirpy-constants.ts GENDERS array?
    switch (gender) {
      case Gender.MALE:
        return 'Men';
      case Gender.FEMALE:
        return 'Women';
      default:
        return gender;
    }
  }

  getMoreRomanceListings() {
    const subject$ = new BehaviorSubject<IRomanceListing[]>(null);

    this.getRomanceListings().subscribe(romanceListings => {
      if (romanceListings == null) return;

      const totalNewRecords = romanceListings.length - this.romanceListings.length;
      if (totalNewRecords !== 0) this.hasMore = totalNewRecords === this.INCREMENT;
      // if (this.recordsToFetch > romanceListings.length) this.hasMore = false;

      if (this.hasMore) this.recordsToFetch += this.INCREMENT; // increase how many Romance Listings to display.

      // USE THE DATA THE DATABASE GIVES US:
      // this.romanceListings = romanceListings;
      // subject$.next(romanceListings);

      // UPDATE THE EXISTING ARRAY SO THE UI UPDATES FASTER
      const newData = [];
      // add new entries, update existing entries
      romanceListings.forEach(r => {
        const item = this.romanceListings.find(x => x.uid === r.uid);
        if (item != null) {
          // update anything in the list that has changed.
          Object.assign(item, r);
        } else {
          newData.push(r);
        }
      });

      // remove entries that no longer exist
      const currentData = [];
      this.romanceListings.forEach((r, index) => {
        const item = romanceListings.find(x => x.uid === r.uid);
        if (item != null) currentData.push(this.romanceListings[index]);
      });

      const unsorted = currentData.concat(newData);
      let sorted = unsorted.sort((a, b) => (a.created >= b.created ? 1 : -1)).sort();
      if (this.authService.isAdmin([AdminRole.SUPER, AdminRole.ROMANCE])) {
        sorted = sorted.sort((a, b) => (a.approved > b.approved ? 1 : -1));
      }

      this.romanceListings = sorted;
      subject$.next(sorted);
    });

    return subject$;
  }

  getRomanceListing(uid: string = null): Observable<IRomanceListing> {
    if (uid == null) uid = this.member.uid;
    return this.romanceDatabase.getRomanceListing(uid);
  }

  getRomanceListings() {
    const subject = new BehaviorSubject(null);

    this.authService._userProfileSubject
      .pipe(
        skipWhile(x => x == null),
        take(1)
      )
      .subscribe(member => {
        this.subscriptionService.clearSubscription(this.optionsSubscription);
        this.optionsSubscription = this.romanceDatabase.getRomanceOptions(member.uid).subscribe((romanceOptions: IRomanceOptions) => {
          if (romanceOptions == null) {
            romanceOptions = {} as IRomanceOptions;
            Object.assign(romanceOptions, this.getDefaultRomanceOptions(member));
          }

          this.subscriptionService.clearSubscription(this.listingsSubscription);
          this.listingsSubscription = this.romanceDatabase.getRomanceListings(member.gender, this.authService.isAdmin([AdminRole.SUPER, AdminRole.ROMANCE]), this.recordsToFetch, romanceOptions).subscribe((romanceListings: IRomanceListing[]) => {
            if (romanceListings != null) subject.next(romanceListings);
          });
          this.subscriptionService.add(this.listingsSubscription);
        });
        this.subscriptionService.add(this.optionsSubscription);
      });

    return subject;
  }

  getRomanceOptions() {
    return this.romanceDatabase.getRomanceOptions(this.member.uid);
  }

  hideListing(uid: string) {
    const data = { uid, published: false };
    return this.romanceDatabase.updateRomanceListing(data).then(() => {
      this.toastService.presentToast(this.CONSTANTS.hiddenToast);
    });
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.listingsSubscription);
    this.subscriptionService.clearSubscription(this.optionsSubscription);
  }

  async presentEnterDateOfBirthPrompt(memberId: string, successCallback: any) {
    const alert = await this.alertController.create({
      header: 'Enter your date of birth',
      message: `<p>We display your age on your ${this.CONSTANTS.branding.toLowerCase()} so that members can find the sort of person they are looking for more easily.</p><p>Please enter your date of birth below so we can calculate your age.</p>`,
      inputs: [
        {
          name: 'dateOfBirth',
          type: 'date',
          placeholder: `Enter a date of birth...`
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: data => this.enterDateOfBirthHandler(memberId, data.dateOfBirth, successCallback)
        }
      ]
    });

    await alert.present();
  }

  async presentSelectGenderPrompt(memberId: string, successCallback: any) {
    const alert = await this.alertController.create({
      header: 'Select your gender',
      message: `<p>We display your gender on your ${this.CONSTANTS.branding.toLowerCase()} so that members can find the sort of person they are looking for more easily.</p><p>Please select your gender below.</p>`,
      inputs: [
        {
          type: 'radio',
          label: Gender.MALE,
          value: Gender.MALE
        },
        {
          type: 'radio',
          label: Gender.FEMALE,
          value: Gender.FEMALE
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: data => this.selectGenderHandler(memberId, data, successCallback)
        }
      ]
    });

    await alert.present();
  }

  publishListing(uid: string, approved: boolean) {
    const data = { uid, published: true };
    return this.romanceDatabase.updateRomanceListing(data).then(() => {
      const message = approved ? this.CONSTANTS.publishApproved : this.CONSTANTS.publishUnapproved;
      this.toastService.presentToast(message);

      this.requestRomanceListingReview(uid, false);
    });
  }

  rejectRomanceListing(uid: string) {
    const data = { uid, approved: false };
    return this.romanceDatabase.updateRomanceListing(data);
  }

  requestRomanceListingReview(uid: string, isNew: boolean) {
    this.getRomanceListing(uid)
      .pipe(
        skipWhile(x => x == null),
        take(1)
      )
      .subscribe(romanceListing => {
        this.userService
          .getUserProfile(romanceListing.uid)
          .pipe(
            skipWhile(x => x == null),
            take(1)
          )
          .subscribe(member => {
            const newOrUpdated = isNew ? 'New' : 'Updated';
            const dateOfBirth = member.dateOfBirth == null ? `` : `${member.dateOfBirth.day}/${member.dateOfBirth.month}/${member.dateOfBirth.year}`;
            this.emailService.sendEmailToApproveRomanceListing(romanceListing, member, dateOfBirth, newOrUpdated);
          });
      });
  }

  updateRomanceListing(member: UserObject, romanceListing: IRomanceListing) {
    if (!this.validateForm(romanceListing)) return false;
    const isCreating = romanceListing.uid == null;
    if (isCreating) {
      romanceListing.uid = member.uid;
      romanceListing.approved = false;
      romanceListing.country = member.country;
      romanceListing.created = this.dateTimeService.getDateTime();
      romanceListing.deleteDate = this.dateTimeService.addDaysToCurrentDate(200);
      romanceListing.notificationSent = false;
      romanceListing.published = true;
    }

    // Gender and DOB are set via a popup on the listing if they are not present in centralMembers(Private)
    // This can lead to a race condition if we assume they are written to centralMembers(Private) before trying to access them here
    // Instead for this case we pass them through as part of the romanceListing
    if (!romanceListing.gender) romanceListing.gender = member.gender;
    if (!romanceListing.ageRange) romanceListing.ageRange = this.getAgeRange(member);

    const romanceOptions = this.getDefaultRomanceOptions(member);
    romanceOptions.seeking = romanceListing.seeking;
    this.romanceDatabase.updateRomanceOptions(member.uid, romanceOptions);

    // When a member edits their romance listing it needs approving again unless they're an Admin.
    // This also takes care of updating their notification entry
    if (!this.authService.isAdmin([AdminRole.SUPER, AdminRole.ROMANCE])) romanceListing.approved = false;

    // convert first letter to upper case
    romanceListing.title = romanceListing.title[0].toUpperCase() + romanceListing.title.slice(1);

    return this.romanceDatabase.updateRomanceListing(romanceListing).then(() => {
      const isMine = this.authService._userProfileSubject.value.uid === romanceListing.uid;
      const message = isMine ? `${this.CONSTANTS.updatedProfileToast} ${isCreating ? 'added' : 'updated'}.` : `${this.CONSTANTS.updatedMemberProfileToast} ${isCreating ? 'added' : 'updated'}.`;
      this.toastService.presentToast(message);

      this.requestRomanceListingReview(romanceListing.uid, isCreating);

      const action = isCreating ? AnalyticsAction.ROMANCE_ADD_LISTING : AnalyticsAction.ROMANCE_UPDATE_LISTING;
      this.analyticsService.eventTrack(AnalyticsCategory.ROMANCE, action, member.displayName);

      this.router.navigate(['/romance', romanceListing.uid]);
    });
  }

  updateRomanceOptions(uid: string, data: any) {
    return this.romanceDatabase.updateRomanceOptions(uid, data);
  }

  validateForm(data: IRomanceListing) {
    const missingFields = [];
    if (data.title.trim().length === 0) missingFields.push('Title');
    if (data.seeking.trim().length === 0) missingFields.push(`I'm interested in`);
    if (data.description.trim().length === 0) missingFields.push('Description');
    if (data.region.trim().length === 0) missingFields.push('Region');

    if (missingFields.length > 0) {
      const message = 'Please enter a ' + missingFields.join(', ');
      this.toastService.presentToast(message);
      return false;
    }

    return true;
  }

  private deleteRomanceListingHandler(romanceListing: IRomanceListing) {
    const uid = romanceListing.uid;
    const targetId = this.getNotificationTarget(romanceListing.country, romanceListing.gender, romanceListing.seeking);
    return this.romanceDatabase.deleteRomanceListing(uid).then(() => {
      this.userService.removeRomanceAccess(uid);
      this.notificationService.removeNotificationForMember(NotificationTarget.ROMANCE, targetId, uid);
      this.toastService.presentToast(this.CONSTANTS.deletedToast);
      this.router.navigate(['/romance']);
    });
  }

  private enterDateOfBirthHandler(memberId: string, dateString: string, successCallback: any) {
    const isEmpty = (dateString || '').length === 0;
    if (isEmpty) {
      this.toastService.presentToast(`Can't save ${this.CONSTANTS.branding.toLowerCase()}, please enter a date of birth.`);
      return;
    }
    const isInvalid = !this.dateTimeService.validateDateOfBirth(dateString);
    if (isInvalid) {
      this.toastService.presentToast(`Can't save ${this.CONSTANTS.branding.toLowerCase()}, please enter a valid date of birth.`);
      return;
    }

    const parts = dateString.split('-'); // displayed as YYYY-MM-DD - e.g. 1980-09-20
    const dateOfBirth: IDateOfBirth = {
      day: parts[2],
      month: parts[1],
      year: parts[0]
    };

    return this.romanceDatabase.updatePrivateMemberData(memberId, { dateOfBirth }).then(() => {
      // Pass age range back to romanceListing
      const member = { dateOfBirth } as UserObject;
      return successCallback({ ageRange: this.getAgeRange(member) });
    });
  }

  // TODO: Change types to Country and Gender in IRomanceListing
  private getNotificationTarget(country: string, gender: string, seeking: string): string {
    return `${country}-${gender}-seeking-${seeking}`;
  }

  private getNotificationTargets(romanceListing: IRomanceListing) {
    const genders = this.constantsService.constants.ROMANCE.GENDERS;
    let targetIds = [];
    if (romanceListing.seeking === Gender.EVERYONE) {
      const otherGenders = genders.filter(x => x !== Gender.EVERYONE);
      for (const gender of otherGenders) {
        targetIds.push(this.getNotificationTarget(romanceListing.country, gender as string, romanceListing.gender));
        targetIds.push(this.getNotificationTarget(romanceListing.country, gender as string, Gender.EVERYONE));
      }
    } else {
      // If romance Listing is Male seeking Female, targetIds should be Female seeking Male, or Female seeking everyone
      targetIds = [this.getNotificationTarget(romanceListing.country, romanceListing.seeking, romanceListing.gender), this.getNotificationTarget(romanceListing.country, romanceListing.seeking, Gender.EVERYONE)];
    }
    return targetIds;
  }

  private selectGenderHandler(memberId: string, gender: string, successCallback: any) {
    if (gender == null) {
      this.toastService.presentToast(`Can't save ${this.CONSTANTS.branding.toLowerCase()}, please select a gender.`);
      return;
    }

    return this.romanceDatabase.updateMemberProfile(memberId, { gender }).then(() => {
      return successCallback({ gender });
    });
  }

  private sendListingCreatedNotification(romanceListing: IRomanceListing, member: UserObject) {
    if (romanceListing == null) return;
    const targetIds = this.getNotificationTargets(romanceListing);
    const activity = {
      activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
      data: {
        baseUrl: this.environmentService.url(romanceListing.country),
        displayName: member.displayName,
        romanceListing: romanceListing
      },
      excludeMembers: [romanceListing.uid],
      message: `<a href="/members/${romanceListing.uid}">${member.displayName}</a> added a new <a href="/romance/${romanceListing.uid}">Romance listing</a>:<br><em>${romanceListing.title}</em>`,
      notificationType: 'newRomanceListing',
      targetIds: targetIds,
      targetType: NotificationTarget.ROMANCE,
      timestamp: Date.now()
    };

    return this.activityService.createActivity(activity);
  }

  private updateNotificationForMember(romanceListing: IRomanceListing) {
    const targetId = this.getNotificationTarget(romanceListing.country, romanceListing.gender, romanceListing.seeking);
    // If someone has updated their listing, then the targetId may have changed
    this.notificationService
      .getNotificationsForMember(romanceListing.uid, NotificationTarget.ROMANCE)
      .pipe(first())
      .subscribe(notifications => {
        if (!notifications || notifications.length === 0) {
          this.notificationService.createNotificationForMember(NotificationTarget.ROMANCE, targetId, romanceListing.uid);
        } else if (notifications.length >= 1) {
          const notificationsToDelete = notifications.map(x => x.uid).filter(x => x !== targetId);
          for (const uid of notificationsToDelete) {
            this.notificationService.removeNotificationForMember(NotificationTarget.ROMANCE, uid, romanceListing.uid);
          }
          const notificationExists = notifications.map(x => x.uid).includes(targetId);
          if (!notificationExists) {
            this.notificationService.createNotificationForMember(NotificationTarget.ROMANCE, targetId, romanceListing.uid);
          }
        }
      });
  }
}
