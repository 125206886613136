import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { BaseAdminComponent } from '../base-admin/base-admin.component';

@Component({
  selector: 'app-add-romance-access',
  templateUrl: '../base-admin/base-admin.component.html',
  styleUrls: ['../base-admin/base-admin.component.scss']
})
export class AddRomanceAccessComponent extends BaseAdminComponent {
  confirmHeader = 'Add Romance access';
  confirmButton = 'Grant access';
  placeholder = 'Search for a member to grant Romance access to';
  title = 'Add Romance access';

  constructor(alertController: AlertController, private router: Router, private toastService: ToastService, private userService: UserService) {
    super(alertController);
  }

  confirmMessage(memberId: string, memberName: string) {
    return `<p>Give ${memberName} access to Romance?</p><p>They need to have a picture of themselves.</p>`;
  }

  selectMemberHandler({ memberId, memberName, data }) {
    let message = '';
    this.userService
      .grantRomanceAccess(memberId)
      .then(result => {
        message = `${memberName} now has access to Romance.`;
      })
      .catch(err => {
        message = `Couldn't grant Romance access for ${memberName}: ${err}`;
      })
      .finally(() => {
        this.toastService.presentToast(message);
        this.router.navigate(['/admin']);
      });
  }
}
