import { Injectable } from '@angular/core';
import { IPlace } from '@shared/models/place';
import { BaseDatabase } from '@shared/services/base.database';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignupGroupsDatabase extends BaseDatabase {
  // TODO: Cache these results in a file. Would also be useful for groups page
  loadGroupLocations(): Observable<IPlace[]> {
    return this.getDocumentsByQuery<IPlace>(this.COLLECTION.CENTRAL_PLACES, ref => ref.where('hasGroup', '!=', []));
  }
}
