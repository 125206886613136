import { FirebaseObject } from '@shared/models/firebase-object';
import { GameStatus } from '../game/game-status';

export class GameObject extends FirebaseObject {
  name: string;
  status = GameStatus.WAITING; // waiting for players
  userId: string; // admin who created the game
  rounds = 6;
  roundDelay = 2;
  currentRound = 0;
  drawDelay = 3;
  autoStart = true;
  autoFill = true;
  numbers: any; // {"4":true}
  started: number;
  created: number;
  players: string[];
  bingoPlayers: string[];

  deserialize(input: any): this {
    super.deserialize(input);
    Object.assign(this, input);
    return this;
  }
}
