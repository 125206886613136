import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { AuthService } from '@shared/services/auth.service';
import { RegionService } from '@shared/services/regions/region.service';
import { ConstantsService } from '@shared/services/constants.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UserService } from '@shared/services/user/user.service';
import { Subscription } from 'rxjs';
import { ALL_AGES } from '@shared/models/age-range';
import { IRomanceListing } from '../../models/romance-listing';
import { IRomanceOptions } from '../../models/romance-options';
import { RomanceService } from '../../services/romance.service';
import { RomanceSearchPresenter } from './romance-search.presenter';

@Component({
  selector: 'app-romance-search',
  templateUrl: './romance-search.component.html',
  styleUrls: ['./romance-search.component.scss'],
  viewProviders: [RomanceSearchPresenter],
  encapsulation: ViewEncapsulation.None
})
export class RomanceSearchComponent implements OnInit, OnDestroy {
  get CONSTANTS() {
    return this.constantsService.constants.ROMANCE.SEARCH;
  }

  get genders() {
    return this.constantsService.constants.ROMANCE.GENDERS;
  }

  get isAdmin() {
    return this.authService.isAdmin([AdminRole.SUPER, AdminRole.ROMANCE]);
  }

  get regionLabel() {
    return this.regionService.regionLabel;
  }

  get regions() {
    return this.regionService.searchCatchupRegions;
  }

  get searchForm() {
    return this.presenter.form;
  }

  ageRanges: string[];
  canFilterOnSeeking: boolean;
  ref: Subscription;
  ref2: Subscription;
  @Output() search = new EventEmitter<any>();

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private regionService: RegionService,
    private constantsService: ConstantsService,
    private romanceService: RomanceService,
    private presenter: RomanceSearchPresenter,
    private modalController: ModalController,
    private subscriptionService: SubscriptionService,
    private userService: UserService
  ) {}

  dismiss() {
    this.modalController.dismiss();
  }

  getGenderValue(gender) {
    return this.romanceService.getGenderValue(gender);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.ref);
    this.subscriptionService.clearSubscription(this.ref2);
  }

  ngOnInit() {
    this.ageRanges = [ALL_AGES.label, ...this.constantsService.constants.AGE_RANGES.map(a => a.label)];

    this.ref = this.romanceService.getRomanceOptions().subscribe((romanceOptions: IRomanceOptions) => {
      if (romanceOptions == null) return;
      this.presenter.setValue(romanceOptions);
    });
    this.subscriptionService.add(this.ref);

    this.ref2 = this.romanceService.getRomanceListing().subscribe((romanceListing: IRomanceListing) => {
      // If you have a romance listing we know what gender you are looking for, so we hide the gender field on search
      this.canFilterOnSeeking = !this.isAdmin && (romanceListing == null || romanceListing.seeking == null);
    });
    this.subscriptionService.add(this.ref2);
  }

  onSearch() {
    const search = this.presenter.search();
    this.search.emit(search);
  }

  reset() {
    this.presenter.reset();
    this.onSearch();
  }
}
