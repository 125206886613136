import { Injectable } from '@angular/core';
import { AdminRole } from '@shared/constants/admin-role';
import { Role } from '@infrastructure/constants/role';
import { AuthService } from '@shared/services/auth.service';
import { UserObject } from '@shared/models/user-object';
import { GameStatus } from '../../../game/game-status';
import { GameObject } from '../../../models/game-object';

@Injectable({
  providedIn: 'root'
})
export class BingoListGamesPresenter {
  canDisplayManageGamesList(user: UserObject): boolean {
    return this.isAdmin(user);
  }

  canDisplayQuickRules(user: UserObject): boolean {
    return this.isMember(user);
  }

  canDisplayJoinGameButton(user: UserObject, game: GameObject) {
    return game.status === GameStatus.WAITING && this.isPlayerNotInGame(game, user);
  }

  canDisplayGameInProgressMessage(user: UserObject, game: GameObject) {
    return game.status !== GameStatus.WAITING && this.isPlayerNotInGame(game, user);
  }

  canDisplayContinueGameButton(user: UserObject, game: GameObject) {
    return this.isPlayerInGame(game, user);
  }

  constructor(private authService: AuthService) {}

  isAdmin(user: UserObject) {
    return this.authService.isAdmin([AdminRole.SUPER]);
  }

  isMember(user: UserObject) {
    return user.role == null || user.role === Role.MEMBER;
  }

  private isPlayerNotInGame(game: GameObject, user: UserObject) {
    return game.players == null || game.players.indexOf(user.uid) === -1;
  }

  private isPlayerInGame(game: GameObject, user: UserObject) {
    return game.players != null && game.players.indexOf(user.uid) > -1;
  }
}
