import { Injectable, Injector } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { BehaviorSubject } from 'rxjs';
import { IAppMenuItem } from '../models/app-menu-item';
import { AbstractMenuService } from './abstract-menu.service';
import { ChirpyMenuService } from './white-label/chirpy-menu-service';
import { FriendsMenuService } from './white-label/friends-menu-service';
import { PlantsMenuService } from './white-label/plants-menu-service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppMenuService {
  appMenuItems$: BehaviorSubject<IAppMenuItem[]> = new BehaviorSubject(null);
  private menuService: AbstractMenuService;

  constructor(private authService: AuthService, private environmentService: EnvironmentService, private injector: Injector) {
    this.initMenuService(); // TODO: Inject with factory
    this.getMenuItems();
  }

  private getMenuItems() {
    this.authService.userProfileObservable
      .pipe(
        first(x => !!x) // We don't need to keep listening for changes. The only things that are user-specific are host status and country
      )
      .subscribe(user => {
        let menuItems: IAppMenuItem[] = null;

        if (user != null) {
          menuItems = this.menuService.getMenuItems(user);
        }

        this.appMenuItems$.next(menuItems);
      });
  }

  private getMenuService() {
    switch (this.environmentService.settings.constants) {
      case 'chirpy':
        return this.injector.get(ChirpyMenuService);
      case 'friends':
        return this.injector.get(FriendsMenuService);
      case 'plants':
        return this.injector.get(PlantsMenuService);
      default:
        return null;
    }
  }

  private initMenuService() {
    this.menuService = this.getMenuService();
    if (this.menuService == null) {
      console.error('menuService is null, check your environment settings', this.environmentService.settings);
    }
  }
}
