import { Injectable } from '@angular/core';
import { IPlace } from '@shared/models/place';
import { AuthService } from '@shared/services/auth.service';
import { LocationDatabase } from '@shared/services/location/location.database';
import { UserDatabase } from '@shared/services/user/user.database';
import { ILocationInput } from '../models/location-input';
import { SignupLocationDatabase } from './location.database';

@Injectable({
  providedIn: 'root'
})
export class SignupLocationService {
  constructor(private authService: AuthService, private database: SignupLocationDatabase, private locationDatabase: LocationDatabase, private userDatabase: UserDatabase) {}

  getPlace(uid: string) {
    return this.database.getPlace(uid);
  }

  getRegion(region: string) {
    return this.locationDatabase.getRegion(region, this.authService._userProfileSubject.value.country);
  }

  // Use existing function in UserDatabase
  setLocation(location: ILocationInput) {
    const merge: boolean = true;
    return this.userDatabase.updatePublicMemberDataAndLocation(this.authService._userProfileSubject.value.uid, location, merge);
  }
}
