import { Component, ViewChild } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ChirpyDataTableComponent } from '@shared/components/chirpy-data-table/chirpy-data-table.component';
import { IColumn } from '@shared/models/column';
import { IDataTableConfig } from '@shared/models/data-table-config';
import { Observable } from 'rxjs';
import { IInput } from '@shared/models/input';

@Component({
  selector: 'app-base-report',
  styleUrls: ['./base-report.component.scss'],
  templateUrl: './base-report.component.html'
})
export class BaseReportComponent {
  canDownload: boolean = true;
  columns: IColumn[] = [];
  protected config: IDataTableConfig = {};
  data$: Observable<any[]>;
  @ViewChild(ChirpyDataTableComponent, { static: false }) dataTable: ChirpyDataTableComponent;
  hasParameters: boolean = true;
  header: string;
  parametersButton: string = 'Select';
  parametersClass: string = '';
  parametersHeader: string = 'Select parameters';
  parametersMessage: string = '';
  parametersInputs: IInput[];
  instructions: string = '<p>Please select parameters for the report</p>';
  title: string = 'Report';

  constructor(protected alertController: AlertController) {}

  onDownloadCsv() {
    this.dataTable.downloadCsv();
  }

  async onSelectParameters() {
    const alert = await this.alertController.create({
      cssClass: this.parametersClass,
      header: this.parametersHeader,
      message: this.parametersMessage,
      inputs: this.parametersInputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: this.parametersButton,
          handler: data => this.selectParametersHandler(data)
        }
      ]
    });

    await alert.present();
  }

  selectParametersHandler(data: any) {}
}
