import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { IMedia } from '@shared/models/media/media';
import { IMediaMetadata } from '@shared/models/media/media-metadata';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { GroupService } from '@shared/services/groups/group.service';
import { PhotoService } from '@shared/services/photos/photo.service';
import { ToastService } from '@shared/services/toast.service';
import { merge, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-group-photo-edit',
  templateUrl: './group-photo-edit.page.html'
})
export class GroupPhotoEditPage implements OnInit {
  canEditMedia$: Observable<boolean>;
  collectionId: string;
  collectionTitle: string;
  collectionType: MediaCollectionType = MediaCollectionType.GROUP;
  defaultHref: string;
  mediaId: string;
  media$: Observable<IMedia>;

  constructor(private analyticsService: AnalyticsService, private groupService: GroupService, private photoService: PhotoService, private route: ActivatedRoute, private router: Router, private toastService: ToastService) {}

  ngOnInit() {
    this.collectionId = this.route.snapshot.paramMap.get('id');
    this.collectionTitle = this.route.snapshot.paramMap.get('title');
    if (this.collectionTitle) this.collectionTitle = this.collectionTitle.replace(/(%20|\+)/g, ' ');
    this.mediaId = this.route.snapshot.paramMap.get('photoId');
    this.media$ = this.photoService.getPhoto(this.mediaId);
    this.defaultHref = `/groups/photos/${this.collectionId}/${this.collectionTitle}/detail?photo=${this.mediaId}`;

    // For the moment each group has one collection. If we allow multiple collections, needs to use different way of specifying who has admin privileges
    this.canEditMedia$ = this.groupService.canManageGroup$(this.collectionId);

    this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_VIEW_EDIT_PAGE, this.collectionId, { type: this.collectionTitle });
  }

  onSubmit(metadata: IMediaMetadata, media: IMedia) {
    // Add category for public pix
    let data = {};
    if (metadata.category.hasOwnProperty('id')) {
      Object.assign(data, { category: metadata.category.id });
    } else {
      Object.assign(data, { category: '' });
    }

    // Add caption
    if (metadata.caption.trim().length > 0) Object.assign(data, { caption: metadata.caption });

    // Determine collection IDs
    const oldCollections = [...media.collections];
    let newCollections = [this.photoService.getCollectionKey(this.collectionId, this.collectionType)];
    if (metadata.public === true && this.collectionType !== MediaCollectionType.PUBLIC) newCollections.push(MediaCollectionType.PUBLIC);
    if (metadata.relatedId !== '') newCollections.push(this.photoService.getCollectionKey(metadata.relatedId, metadata.category.type));
    Object.assign(data, { collections: newCollections });

    const removedCollections = oldCollections.filter(x => !newCollections.includes(x));
    const modifiedCollections = oldCollections.filter(x => newCollections.includes(x));
    const addedCollections = newCollections.filter(x => !oldCollections.includes(x));

    let changedCollections: Record<string, number> = {};
    for (let collection of removedCollections) {
      changedCollections[collection] = -1;
    }
    for (let collection of modifiedCollections) {
      changedCollections[collection] = 0;
    }
    for (let collection of addedCollections) {
      changedCollections[collection] = 1;
    }

    this.photoService.updatePhoto(media.uid, data).then(() => {
      Object.assign(media, data);
      this.photoService.updateCollections(media, changedCollections).then(() => {
        const message = `Changes saved`;
        this.toastService.presentToast(message);

        this.router.navigate(['../../'], { relativeTo: this.route, queryParams: { photo: this.mediaId } });

        this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_EDIT_METADATA, this.mediaId, { type: this.collectionTitle });
      });
    });
  }
}
