import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IMessage } from '@shared/models/messages/message';
import { ConstantsService } from '@shared/services/constants.service';
import { MessageDatabase } from '@shared/services/messages/message.database';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'chirpy-last-message',
  templateUrl: './chirpy-last-message.component.html'
})
export class ChirpyLastMessageComponent implements OnInit, OnDestroy {
  avatarLinksToProfile: boolean = true;
  hasMultiplePeople: boolean = true;
  isWidget: boolean = true;
  @Output() linkClicked = new EventEmitter();
  message: IMessage;
  messageSubscription: Subscription;
  noMessageText: string;
  @Input() noPadding: boolean = false;
  @Input() oneLine: boolean = false;
  @Input() showAvatar: boolean = true;
  @Input() threadId: string;

  userId: string = ''; // Don't need to show message in different format for current member

  constructor(private constantsService: ConstantsService, private messageDatabase: MessageDatabase, private subscriptionService: SubscriptionService) {}

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.messageSubscription);
  }

  ngOnInit() {
    const branding = this.constantsService.constants.GROUPS.CHIT_CHAT.branding.toLowerCase() || 'chit-chat';
    this.noMessageText = `This group does not have any ${branding} yet.`;
    this.messageSubscription = this.messageDatabase.getLastMessage(this.threadId).subscribe(messages => {
      if (messages.length > 0) {
        const message = messages[0];
        this.message = message.replyCount ? message.lastReply : message;
      } else {
        this.message = null;
      }
    });
    this.subscriptionService.add(this.messageSubscription);
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }
}
