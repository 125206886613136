import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageType } from '@shared/constants/message-type';
import { IMemberThread } from '@shared/models/messages/member-thread';
import { IMemberThreadType } from '@shared/models/messages/member-thread-type';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService, IAnalyticsSetting } from '@shared/services/analytics';
import { ConstantsService } from '@shared/services/constants.service';
import { ContactService } from '@shared/services/messages/contact.service';
import { MessageService } from '@shared/services/messages/message.service';
import { ToastService } from '@shared/services/toast.service';
import { UIService } from '@shared/services/ui.service';
import { ThreadMembersService } from '../../threads/thread-members/thread-members.service';

@Component({
  selector: 'more-detail',
  templateUrl: './more-detail.component.html',
  styleUrls: ['./more-detail.component.scss']
})
export class MoreDetailComponent implements OnInit {
  get isBlocked() {
    return this.thread != null && this.thread.relatedIds != null && this.contactService.isBlocked(this.thread.relatedIds[0]) && this.thread.type === IMemberThreadType.DirectMessage;
  }

  get isBlockedBy() {
    return this.thread != null && this.thread.relatedIds != null && this.contactService.isBlockedBy(this.thread.relatedIds[0]) && this.thread.type === IMemberThreadType.DirectMessage;
  }

  get isConversation() {
    return this.thread != null && this.thread.type === IMemberThreadType.Conversation;
  }

  get isGroup() {
    return this.thread != null && this.thread.type === IMemberThreadType.Group;
  }

  get isSoleMember() {
    return this.thread != null && this.thread.isSoleMember === true;
  }

  get isThreadClosed() {
    return this.isBlocked || this.isBlockedBy || this.isSoleMember;
  }

  get threadClosedMessage() {
    if (this.isBlocked) {
      return this.CONSTANTS.isBlockedMessage;
    } else if (this.isBlockedBy) {
      return this.CONSTANTS.isBlockedByMessage;
    } else if (this.isSoleMember) {
      return this.CONSTANTS.isSoleMemberMessage;
    } else {
      return ``;
    }
  }

  CONSTANTS: any;
  thread: IMemberThread;
  threadId: string;

  constructor(private analyticsService: AnalyticsService, private constantsService: ConstantsService, private contactService: ContactService, private messageService: MessageService, private route: ActivatedRoute, private router: Router, private toastService: ToastService) {}

  goBack() {
    this.router.navigate([`/messages/more/archive`]);
  }

  ionViewWillEnter() {
    // ngOnInit doesn't trigger if you come back from a navigation stack, ionViewWillEnter / ionViewDidEnter will.
    if (this.threadId != null) {
      this.analyticsService.eventTrack(AnalyticsCategory.MESSAGES, AnalyticsAction.MESSAGES_VIEW_ARCHIVE_DETAIL, this.threadId); // this.thread is a memberThread, use uid of the actual thread as the analytics label
    }
  }

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.MESSAGES.THREADS.DETAIL;

    this.route.paramMap.subscribe(params => {
      this.threadId = params.get('id');
      if (this.threadId == null) {
        return;
      }

      this.messageService.getArchivedMemberThread(this.threadId).subscribe(thread => {
        this.thread = thread;
      });
    });
  }

  onUnarchiveThread() {
    return this.messageService.unarchiveMemberThread(this.thread.threadId, this.thread.memberId).then(() => {
      this.toastService.presentToast(`Thread has been unarchived`);
      this.analyticsService.eventTrack(AnalyticsCategory.MESSAGES, AnalyticsAction.MESSAGES_UNARCHIVE_THREAD, this.threadId);
      this.router.navigate(['/messages/more/archive']);
    });
  }
}
