import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IPlace } from '@shared/models/place';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AbstractMarkerService } from '@shared/services/abstract-marker.service';
import { AuthService } from '@shared/services/auth.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import * as L from 'leaflet';
import 'leaflet.locatecontrol';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { IMapState } from '@shared/models/map-state';

@Component({
  selector: 'chirpy-locator-map',
  templateUrl: './chirpy-locator-map.component.html',
  styleUrls: ['./chirpy-locator-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ChirpyLocatorMapComponent implements OnInit {
  // this component uses leaflet and ngx-leaflet heavily to display maps and pins.
  // https://github.com/Asymmetrik/ngx-leaflet

  @Input() analytics: AnalyticsCategory;
  analyticsActions: Record<string, AnalyticsAction> = {};
  baseLayer: L.Layer;
  firstRender: boolean = true;
  @Input() initialZoom: number = 12; // zoomed out from suburb to try to show neighbouring points
  @Input() isSmall: boolean = false;
  items: any[] = []; //save the value of the observable for easier parsing/filtering
  @Input() items$: Observable<any[]>;
  itemsRef: Subscription;
  layers = []; // observable alters the map whenever this is changed.
  map: L.Map;
  mapBounds$ = new BehaviorSubject(null);
  @Output() mapBoundsChange: EventEmitter<any> = new EventEmitter<any>();
  markerLayer: L.FeatureGroup;
  protected markerService: AbstractMarkerService;
  @Input() maxZoom: number = 15; //Zoom level appropriate to suburb
  options: any = null; // options is only used to initialise the map, changing this value does nothing after.
  @Input() places$: Observable<IPlace[]>;
  placesRef: Subscription;
  @Input() showCount: boolean = true;
  wrapperClass: string = ''; // use to prevent styles in child classes leaking out to other instances of ChirpyLocatorMapComponent

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private ref: ChangeDetectorRef, private subscriptionService: SubscriptionService) {}

  fitToData() {
    const markerBounds = this.markerLayer.getBounds();
    //Add padding to account for marker size
    if (Object.keys(markerBounds).length > 0) this.map.fitBounds(markerBounds, { padding: [10, 10] });
  }

  getMapState(): IMapState {
    const bounds = this.map.getBounds();
    const zoom = this.map.getZoom();
    const mapState = { mapBounds: bounds, mapZoom: zoom } as IMapState;
    return mapState;
  }

  init(): void {
    this.baseLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { detectRetina: true, maxZoom: this.maxZoom, attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors' });
    this.initAnalyticsActions();
    this.initMarkerLayerGroup();
    this.initItems();
    this.updateMarkersFromPlaces();
    this.initMapOptions();
  }

  initAnalyticsActions() {
    switch (this.analytics) {
      case AnalyticsCategory.GROUPS:
        this.analyticsActions = {
          show: AnalyticsAction.GROUPS_SHOW_LOCATION,
          pan: AnalyticsAction.GROUPS_PAN_MAP,
          hide: AnalyticsAction.GROUPS_HIDE_LOCATION,
          zoom: AnalyticsAction.GROUPS_ZOOM_MAP
        };
        break;

      case AnalyticsCategory.MEETING_PLACE:
        this.analyticsActions = {
          show: AnalyticsAction.MEETING_PLACE_SHOW_LOCATION,
          pan: AnalyticsAction.MEETING_PLACE_PAN_MAP,
          hide: AnalyticsAction.MEETING_PLACE_HIDE_LOCATION,
          zoom: AnalyticsAction.MEETING_PLACE_ZOOM_MAP
        };
        break;

      case AnalyticsCategory.SOCIAL:
        this.analyticsActions = {
          show: AnalyticsAction.SOCIAL_SHOW_LOCATION,
          pan: AnalyticsAction.SOCIAL_PAN_MAP,
          hide: AnalyticsAction.SOCIAL_HIDE_LOCATION,
          zoom: AnalyticsAction.SOCIAL_ZOOM_MAP
        };
        break;

      default:
        break;
    }
  }

  initMapOptions() {
    const user = this.authService._userProfileSubject.value;
    const options = {
      zoom: this.initialZoom,
      center: L.latLng(0, 0)
    };

    if (user != null) {
      if (user.coordinates != null) {
        options.center = L.latLng(user.coordinates.latitude, user.coordinates.longitude);
      }
      if (user.zoomTo != null) {
        options.zoom = this.initialZoom ? Math.min(user.zoomTo, this.initialZoom) : user.zoomTo; // Respect initialZoom level
      }
    }

    this.options = options;
  }

  initMarkerLayerGroup() {
    this.markerLayer = L.featureGroup().on('click', this.presentMapPinsModal, this);
  }

  initItems() {
    this.itemsRef = this.items$.subscribe(items => {
      this.items = items;
    });
    this.subscriptionService.add(this.itemsRef);
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.itemsRef);
    this.subscriptionService.clearSubscription(this.placesRef);
  }

  ngOnInit(): void {
    setTimeout(() => this.init(), 0); // maps don't initialize properly without setTimeout
  }

  onResize(data: any) {
    if (!this.firstRender) return;
    if (!!data && !!data.contentRect && data.contentRect.width > 0) {
      this.firstRender = false;
      this.map.invalidateSize();
    }
  }

  onMapBoundsChange() {
    const mapState = this.getMapState();
    if (this.map && this.map.getSize().x === 0 && this.map.getSize().y === 0) {
      setTimeout(() => {
        this.map.invalidateSize();
      }, 0);
    }
    this.mapBounds$.next(mapState);
  }

  onMapReady(map: L.Map) {
    this.map = map;

    //Add geolocation control
    L.control
      .locate({
        icon: 'chirpy-locator-map__geolocator',
        iconLoading: 'chirpy-locator-map__geolocator-loading',
        locateOptions: {
          maxZoom: 15,
          enableHighAccuracy: true,
          watch: false //don't keep listening to location.
        }
      })
      .addTo(map);

    // initial search to populate map with items when the map loads.
    const mapState = this.getMapState();
    this.mapBoundsChange.emit(mapState);

    // search for items located within the visible map bounds when the map scrolls or zooms in/out
    const debounced = this.mapBounds$.pipe(debounce(() => timer(1000)));
    debounced.subscribe(() => {
      if (this.mapBounds$.value != null) {
        this.mapBoundsChange.emit(this.mapBounds$.value);
      }
    });
    map.on('zoomend', () => {
      //Add zoom level as css class to leaflet div
      const zoom = this.map.getZoom();
      const container = this.map.getContainer();
      container.className = container.className.replace(/\sz[0-9]{1,2}/g, '') + ' z' + zoom;
      if (this.analytics) {
        this.analyticsService.eventTrack(this.analytics, this.analyticsActions['zoom'], null, {}, zoom);
      }
    });
    map.on('moveend', () => {
      if (this.analytics) {
        this.analyticsService.eventTrack(this.analytics, this.analyticsActions['pan']);
      }
      this.onMapBoundsChange();
    });
    map.on('locateactivate', () => {
      if (this.analytics) {
        this.analyticsService.eventTrack(this.analytics, this.analyticsActions['show']);
      }
    });
    map.on('locatedeactivate', () => {
      if (this.analytics) {
        this.analyticsService.eventTrack(this.analytics, this.analyticsActions['hide']);
      }
    });

    // force the map to redraw
    setTimeout(() => {
      map.invalidateSize();
    }, 1000);
  }

  presentMapPinsModal(event) {
    return this.markerService.presentMapPinsModal(event, this.items);
  }

  updateMarkersFromPlaces() {
    this.placesRef = this.places$.subscribe(places => {
      this.markerService.updateMarkers(places, this.markerLayer, this.showCount);
      this.ref.detectChanges();
    });
    this.subscriptionService.add(this.placesRef);
  }
}
