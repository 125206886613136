import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AbstractMarkerService } from '@shared/services/abstract-marker.service';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { FeatureGroup } from 'leaflet';
import { map, skipWhile, take } from 'rxjs/operators';
import { IGroup } from '@shared/models/groups/group';
import { IMapPin } from '@shared/models/map-pin';
import { GroupLocatorPinComponent } from './pin/pin.component';

@Injectable({
  providedIn: 'root'
})
export class GroupLocatorService extends AbstractMarkerService {
  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private modalController: ModalController) {
    super();
  }

  async presentMapPinsModal(event, items) {
    const properties = event.layer.feature.properties;
    const groups = this.getGroupsByPlace(items, properties.placeId);
    const modal = await this.modalController.create({
      component: GroupLocatorPinComponent,
      cssClass: 'group-locator__modal', //'members-locator__modal',
      componentProps: {
        groups: groups,
        count: groups.length,
        placeName: properties.locality
      }
    });

    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_VIEW_PIN, properties.locality);
    return await modal.present();
  }

  updateMarkers(data: any[], markerGroup: FeatureGroup, showCount: boolean) {
    markerGroup.clearLayers();

    if (data == null || data.length === 0) return;

    const pins: IMapPin[] = [];

    data.forEach(place => {
      pins.push({
        count: place.memberCount,
        itemId: place.uid,
        itemName: place.displayName,
        lat: place.coordinates.latitude,
        long: place.coordinates.longitude,
        me: false // TODO change colour of marker if you belong to a group at this place? this.authService._userProfileSubject.value.catchupGroupIds.includes(group.uid)
      });
    });

    // create pin for each location
    pins.forEach(p => {
      this.addMarker(p, markerGroup, showCount);
    });
  }

  private getGroupsByPlace(groups: IGroup[], placeId: string) {
    return groups.filter(x => Object.keys(x.locations || {}).includes(placeId));
  }
}
