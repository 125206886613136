import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IDateOfBirth } from '@infrastructure/models/date-of-birth';
import { Country } from '@shared/constants/country';
import { ICentralMemberEditable } from '@shared/models/central-member';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { MemberProfileEditDatabase } from './member-profile-edit.database';

@Injectable({
  providedIn: 'root'
})
export class MemberProfileEditService {
  get CONSTANTS() {
    return this.constantsService.constants.PROFILE;
  }
  constructor(
    private authService: AuthService,
    private constantsService: ConstantsService,
    private database: MemberProfileEditDatabase,
    private dateTimeService: DateTimeService,
    private environmentService: EnvironmentService,
    private toastService: ToastService,
    private router: Router,
    private userService: UserService
  ) {}

  updateDateOfBirth(uid: string, dateOfBirth: IDateOfBirth) {
    this.userService.updatePrivateUserField(this.authService._userProfileSubject.value.uid, 'dateOfBirth', dateOfBirth);
  }

  async updateMemberProfile(uid: string, profile: ICentralMemberEditable) {
    this.userService.updateUserProfile(uid, profile).then(() => {
      const isMine = this.authService._userProfileSubject.value.uid === uid;
      const message = isMine ? `Your member profile has been  updated.` : `Member profile has been updated.`;
      this.toastService.presentToast(message);
    });
  }

  async validateForm(data: any, uid: string, country: Country) {
    const invalidCharacters = (data.displayName || '').replace(/[\u0020-\u007e\u00c0-\u00ff]+/gu, '');
    if (invalidCharacters.length > 0) {
      const message = `Please choose a Display Name that doesn't contain special characters (${invalidCharacters}),`;
      this.toastService.presentToast(message);
      return false;
    }

    const displayNameExists = await this.database.displayNameExists(uid, data.displayName, country);
    if (displayNameExists) {
      const message = 'This Display Name is already being used, please choose a different one.';
      this.toastService.presentToast(message);
      return false;
    }

    const missingFields = [];
    if (data.displayName.length === 0) missingFields.push('Display Name');
    if (data.placeId.length === 0) missingFields.push('Location');
    if (data.gender.length === 0) missingFields.push('Gender'); // TODO: Use gender label
    if (!this.isValidPhone(data.phone)) missingFields.push('valid phone number (or leave this field blank)');
    if (!this.isValidDateOfBirth(data.dob)) missingFields.push(`valid date of birth (or leave this field blank)`);

    if (missingFields.length > 0) {
      const message = 'Please enter a ' + missingFields.join(', ');
      this.toastService.presentToast(message);
      return false;
    }

    return true;
  }

  async uploadProfilePhoto(uid: string, file): Promise<any> {
    return this.database.uploadProfilePhoto(uid, file);
  }

  private isValidDateOfBirth(dob: string) {
    const MIN_AGE = this.CONSTANTS.DOB.minAge;
    const MAX_AGE = this.CONSTANTS.DOB.maxAge;
    return dob === '' || this.dateTimeService.validateDateOfBirth(dob, MIN_AGE, MAX_AGE);
  }

  private isValidPhone(phone: string): boolean {
    // NB Keep any validation here in sync with welcome/signup/phone
    const PHONE_NUMBER: RegExp = new RegExp(/^[extn\d\s\()+-]{0,25}$/i);
    if (!PHONE_NUMBER.test(phone)) return false;

    const length = phone.trim().length;
    return length === 0 || length > 6; // TODO: Should we use a library to do proper international phone number validation?
  }
}
