import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserObject } from '@shared/models/user-object';
import { IAdvancedNotification } from '@shared/models/notifications/advanced-notification';
import { AdvertiserService } from '@shared/services/advertisers/advertiser.service';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { MessageService } from '@shared/services/messages/message.service';
import { NotificationTarget, NotificationService } from '@shared/services/notifications';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { AdvancedNotificationsPresenter } from './advanced.presenter';
import { Observable, Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-notifications-advanced',
  templateUrl: 'advanced.page.html'
})
export class AdvancedNotificationsPage implements OnDestroy, OnInit {
  get form() {
    return this.presenter.form;
  }

  CONSTANTS: any;
  defaultMessage: string;
  groupIds: string[] = [];
  heading: string;
  idField: string;
  introMessage: string;
  isSaved: boolean = false;
  itemNames: Record<string, string> = {};
  member: UserObject;
  noItemsMessage: string;
  settingKey: string;
  settingTitle: string;
  settingValue: boolean;
  settings: IAdvancedNotification;
  subscription: Subscription;
  type: NotificationTarget;

  constructor(
    private advertiserService: AdvertiserService,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private groupService: GroupService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private presenter: AdvancedNotificationsPresenter,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService
  ) {}

  ionViewWillEnter() {
    this.isSaved = false;
    this.settingKey = this.route.snapshot.paramMap.get('key');
    this.type = this.route.snapshot.paramMap.get('type') as NotificationTarget;
    this.settingValue = this.route.snapshot.paramMap.get('value') === 'true';

    const config = this.CONSTANTS.settings[this.type].filter(x => x.id === this.settingKey);
    const advertiserBranding = this.constantsService.constants.ADVERTISERS.branding.toLowerCase();
    this.settingTitle = config.length > 0 ? config[0].title : '(unknown setting)';
    this.heading = config.length > 0 && config[0].placeholder ? config[0].placeholder : this.type;

    switch (this.type) {
      case NotificationTarget.ADVERTISER:
        this.defaultMessage = `${advertiserBranding} you follow`;
        this.idField = 'uid';
        this.introMessage = `${advertiserBranding} you follow`;
        this.noItemsMessage = `You are not following any ${advertiserBranding}`;
        break;

      case NotificationTarget.GROUP:
        this.defaultMessage = 'groups you join';
        this.idField = 'uid';
        this.introMessage = 'groups you belong to';
        this.noItemsMessage = 'You do not belong to any groups';
        break;

      case NotificationTarget.MESSAGE:
        this.defaultMessage = 'messages you receive';
        this.idField = 'threadId';
        this.introMessage = 'messages you receive';
        this.noItemsMessage = 'You have not received any messages';
        break;
    }

    this.subscriptionService.clearSubscription(this.subscription);
    this.subscription = this.getItemNames$().subscribe(settings => {
      this.settings = settings[this.type][this.settingKey];

      // If setting was modified on the previous screen, then update the values here
      if (this.settings.default !== this.settingValue) {
        this.settings.default = this.settingValue;
        for (let key of Object.keys(this.settings.items)) {
          this.settings.items[key] = this.settingValue;
        }
      }

      this.presenter.init(this.settings);
    });
    this.subscriptionService.add(this.subscription);
    this.analyticsService.eventTrack(AnalyticsCategory.NOTIFICATION, AnalyticsAction.NOTIFICATION_VIEW_ADVANCED_SETTINGS, this.settingKey);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.subscription);
  }

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.NOTIFICATIONS;
  }

  onSave() {
    this.isSaved = true;
    this.notificationService.updateAdvancedSettingsForMember(this.member.uid, this.type, this.settingKey, this.presenter.settings);
    this.analyticsService.eventTrack(AnalyticsCategory.NOTIFICATION, AnalyticsAction.NOTIFICATION_UPDATE_ADVANCED_SETTINGS, this.settingKey);
  }

  sortNull() {}

  private getItemNames$() {
    return this.authService._userProfileSubject.pipe(
      first(x => x != null),
      switchMap((member: UserObject) => {
        this.member = member;

        switch (this.type) {
          case NotificationTarget.ADVERTISER:
            return this.advertiserService.getAdvertisersById(member.advertiserIds);
          case NotificationTarget.GROUP:
            return this.groupService.getGroupsById(member.catchupGroupIds);
          case NotificationTarget.MESSAGE:
            return this.messageService.getMemberThreads();
          default:
            return [];
        }
      }),
      switchMap((items: any[]) => {
        this.itemNames = items.reduce((acc, item) => {
          acc[item[this.idField]] = item.name || item.displayName;
          return acc;
        }, {});
        const settings: Observable<any> = this.notificationService.getSettingsForMember(this.member.uid);
        return settings;
      })
    );
  }
}
