import { Component, Input } from '@angular/core';

@Component({
  selector: 'email-data',
  styleUrls: ['email-data.component.scss'],
  templateUrl: './email-data.component.html'
})
export class EmailDataComponent {
  @Input() data: any;
}
