import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ContentPageEditPage } from './content-page-edit/content-page-edit.page';
import { ContentPageViewPage } from './content-page-view/content-page-view.page';
import { PagesRoutingModule } from './pages-routing.module';

@NgModule({
  declarations: [ContentPageEditPage, ContentPageViewPage],
  imports: [PagesRoutingModule, SharedModule]
})
export class PagesModule {}
