import { Component, Input } from '@angular/core';
import { UIService } from '@shared/services/ui.service';

@Component({
  selector: 'chirpy-button',
  styleUrls: ['chirpy-button.component.scss'],
  templateUrl: './chirpy-button.component.html'
})
export class ChirpyButtonComponent {
  @Input() color: string = null;
  @Input() disabled: boolean = false;
  @Input() icon: string;
  @Input() fill: string = null;
  @Input() responsive: boolean = true;
  @Input() text: string; // desktop text, hidden on mobile
  @Input() title: string; // tooltip hover

  constructor(public uiService: UIService) {}

  get showDesktopView() {
    if (this.responsive === false) return true;
    return this.uiService.isMediumDisplay;
  }

  get customFill() {
    return this.fill || (this.showDesktopView ? 'outline' : 'default');
  }

  get customColor() {
    return this.color || (this.showDesktopView ? 'primary' : 'default');
  }
}
