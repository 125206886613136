import { Component, Input } from '@angular/core';
import { ILink } from '@shared/models/link';
import { AnalyticsService, IAnalyticsSetting } from '@shared/services/analytics';

@Component({
  selector: 'chirpy-link-list',
  templateUrl: './chirpy-link-list.component.html'
})
export class ChirpyLinkListComponent {
  @Input() analytics: IAnalyticsSetting = null;
  @Input() imageURL: string;
  @Input() items: ILink[];
  @Input() title: string;

  constructor(private analyticsService: AnalyticsService) {}

  eventTrack(title: string, index: number) {
    if (this.analytics.action && this.analytics.category) this.analyticsService.eventTrack(this.analytics.category, this.analytics.action, title, {}, index);
  }
}
