import { Component, Input, OnInit } from '@angular/core';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { ICatchupWithData } from '@shared/models/catchups/catchup-with-data';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { ConstantsService } from '@shared/services/constants.service';
import { ToastService } from '@shared/services/toast.service';
import { Observable } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { CatchupClaimPresenter } from './catchup-claim.presenter';

@Component({
  selector: 'app-catchup-claim-page',
  templateUrl: './catchup-claim.page.html',
  viewProviders: [CatchupClaimPresenter]
})
export class CatchupClaimPage implements OnInit {
  get CATCHUP_BRANDING() {
    return this.constantsService.constants.CATCHUPS.branding;
  }

  get COINS_BRANDING() {
    return this.constantsService.constants.COINS.branding;
  }

  get CONSTANTS() {
    return this.constantsService.constants.CATCHUPS.CLAIM;
  }

  get form() {
    return this.catchupClaimPresenter.form;
  }

  buttonText: string;
  readonly CatchupRsvpStatus = CatchupRsvpStatus;
  catchups$: Observable<ICatchupWithData[]>;
  hasCatchups: boolean = false;
  hasOtherCatchups: boolean = false;
  isProcessing: boolean = false;
  member: UserObject;
  placeholder: string;
  showSuccessMessage: boolean = false;
  successMessage: string = '';

  constructor(private authService: AuthService, private catchupService: CatchupService, private catchupClaimPresenter: CatchupClaimPresenter, private constantsService: ConstantsService, private toastService: ToastService) {}

  ngOnInit() {
    this.buttonText = `Claim ${this.COINS_BRANDING}`;
    this.placeholder = `Select a ${this.CATCHUP_BRANDING}`;
    this.catchups$ = this.authService._userProfileSubject.pipe(
      first(x => !!x),
      switchMap((member: UserObject) => {
        this.member = member;
        return this.catchupService.getRecentCatchupsForMember(member, this.CONSTANTS.excludeRoles);
      }),
      tap(catchups => {
        this.hasCatchups = catchups != null && catchups.filter(x => !x.note && x.status === CatchupRsvpStatus.GOING).length > 0;
        this.hasOtherCatchups = catchups != null && catchups.filter(x => x.note || x.status === CatchupRsvpStatus.GOING_CLAIMED).length > 0;
      })
    );
  }

  onSubmit() {
    this.isProcessing = true;
    this.buttonText = 'Processing';
    const catchupId = this.catchupClaimPresenter.catchupId;

    if (!catchupId) {
      this.toastService.presentToast(`Please select a ${this.CATCHUP_BRANDING} to claim ${this.COINS_BRANDING} for`);
      this.isProcessing = false;
      this.buttonText = `Claim ${this.COINS_BRANDING}`;
      return;
    }

    this.catchupService.claimCoins(this.member, catchupId).then(success => {
      this.isProcessing = false;
      this.buttonText = `Claim ${this.COINS_BRANDING}`;
      if (!success) {
        this.toastService.presentToast(`Sorry, we were unable to credit your ${this.COINS_BRANDING}`);
      }
    });
  }
}
