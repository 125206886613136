import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IContactFormSetting } from '@shared/models/contact-form-setting';
import { AuthService } from '@shared/services/auth.service';
import { IContactForm } from './models/contact-form';

@Injectable({
  providedIn: 'root'
})
export class ChirpyContactFormPresenter {
  autoPopulated: Record<string, boolean> = {};
  form: FormGroup = null;
  prePopulated: Record<string, boolean> = {};

  constructor(private formBuilder: FormBuilder, private authService: AuthService) {}

  contactForm(): IContactForm {
    return this.form.value as IContactForm;
  }

  disclaimer(): boolean {
    return this.form.controls.disclaimer.value;
  }

  init(fields: IContactFormSetting[], params: Record<string, string>) {
    const group = fields.reduce((groupObject: any, current: IContactFormSetting) => {
      let data = current.required ? ['', Validators.required] : [''];
      if (current.id === 'disclaimer') data = [null, Validators.requiredTrue];
      groupObject[current.id] = data;
      return groupObject;
    }, {});
    this.form = this.formBuilder.group(group);

    this.populateValuesForLoggedInMember(fields);
    this.prePopulateValues(fields, params);
  }

  populateValuesForLoggedInMember(fields: IContactFormSetting[]) {
    if (!fields.some(x => x.autoPopulate === true)) return;

    this.authService._userProfileSubject.subscribe(user => {
      if (user == null) return;
      const autoPopulateFields = fields.filter(x => x.autoPopulate === true);
      for (const field of autoPopulateFields) {
        if (user[field.id]) {
          if (field.id !== 'dateOfBirth') {
            this.form.controls[field.id].setValue(user[field.id]);
            this.autoPopulated[field.id] = true;
            if (field.prePopulate) this.prePopulated[field.id] = true;
          } else {
            const dob = user[field.id];
            if (dob != null && dob.day && dob.month && dob.year) {
              const dobString = `${dob.year}-${dob.month}-${dob.day}`;
              console.log(dob, dobString);
              this.form.controls[field.id].setValue(dobString);
              this.autoPopulated[field.id] = true;
              if (field.prePopulate) this.prePopulated[field.id] = true;
            }
          }
        }
      }
    });
  }

  prePopulateValues(fields: IContactFormSetting[], params: Record<string, string>) {
    if (!fields.some(x => x.prePopulate === true)) return;

    if (params == null) return;
    const prePopulateFields = fields.filter(x => x.prePopulate === true);
    for (const field of prePopulateFields) {
      if (params[field.id]) {
        this.form.controls[field.id].setValue(params[field.id]);
        this.prePopulated[field.id] = true;
      }
    }
  }
}
