import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { AddContactComponent } from './add-contact/add-contact.component';
import { ContactDetailComponent } from './contact-detail/contact-detail.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactsRoutingModule } from './contacts-routing.module';

@NgModule({
  imports: [SharedModule, ContactsRoutingModule],
  declarations: [AddContactComponent, ContactDetailComponent, ContactListComponent],
  exports: [AddContactComponent, ContactDetailComponent, ContactListComponent]
})
export class ContactsModule {}
