import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserObject } from '@shared/models/user-object';
import { AbstractMarkerService } from '@shared/services/abstract-marker.service';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { FeatureGroup } from 'leaflet';
import { map, skipWhile, take } from 'rxjs/operators';
import { IMapPin } from '@shared/models/map-pin';
import { MeetingPlaceMembersLocatorPinComponent } from './pin/pin.component';

@Injectable({
  providedIn: 'root'
})
export class MeetingPlaceMembersLocatorService extends AbstractMarkerService {
  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private modalController: ModalController) {
    super();
  }

  async presentMapPinsModal(event, users) {
    const properties = event.layer.feature.properties;

    const modal = await this.modalController.create({
      component: MeetingPlaceMembersLocatorPinComponent,
      cssClass: 'members-locator__modal',
      componentProps: {
        members: this.getMembersByPlace(users, properties.placeId), //TODO allow do on-demand for places
        count: properties.count,
        placeName: properties.locality
      }
    });

    this.analyticsService.eventTrack(AnalyticsCategory.MEETING_PLACE, AnalyticsAction.MEETING_PLACE_VIEW_PIN, properties.locality);
    return await modal.present();
  }

  updateMarkers(data: any[], markerGroup: FeatureGroup, showCount: boolean) {
    markerGroup.clearLayers();

    if (data == null || data.length === 0) return;

    const pins: IMapPin[] = [];

    data.forEach(place => {
      if (place.memberCount > 0) {
        pins.push({
          count: place.memberCount,
          itemId: place.uid,
          lat: place.coordinates.latitude,
          long: place.coordinates.longitude,
          me: place.uid === this.authService._userProfileSubject.value.placeId, //change colour of marker if it's your current location
          itemName: place.displayName
        });
      }
    });

    // create pin for each location
    pins.forEach(p => {
      this.addMarker(p, markerGroup, showCount);
    });
  }

  private getMembersByPlace(users: UserObject[], placeId: string) {
    return users.filter(x => x.placeId === placeId);
  }
}
