import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Role } from '@infrastructure/constants/role';
import { Country } from '@shared/constants/country';
import { ICentralMember, ICentralMemberPassword, ICentralMemberPrivate } from '@shared/models/central-member';
import { IPlace } from '@shared/models/place';
import { IOrderCondition } from '@shared/models/order-condition';
import { UserObject } from '@shared/models/user-object';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { CacheService } from '@shared/services/cache.service';
import { LocationDatabase } from '@shared/services/location/location.database';
import { NotificationService, NotificationTarget } from '@shared/services/notifications';
import firebase from 'firebase/app';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import FieldValue = firebase.firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class UserDatabase extends BaseDatabase {
  constructor(afs: AngularFirestore, cache: CacheService, private locationDatabase: LocationDatabase, private notificationService: NotificationService) {
    super(afs, cache);
  }

  getMembersByIds(uids: string[], isPrivate: boolean): Observable<any[]> {
    const collection = isPrivate ? this.COLLECTION.CENTRAL_MEMBERS_PRIVATE : this.COLLECTION.CENTRAL_MEMBERS;
    const outputs$: Array<Observable<UserObject[]>> = [];
    const queryFns = this.createChunkedQueryFunctions(uids, '__name__', 'in', [], true);
    if (queryFns.length > 0) {
      //Combine results from multiple database queries
      const newMembers = this.getDocumentsByMultipleQueries<UserObject>(collection, queryFns);
      outputs$.push(newMembers);
    }

    return combineLatest(...outputs$).pipe(
      map(arrays => Array.prototype.concat(...arrays)) //TODO use arrays.flat() when supported
    );
  }

  getMembersByQuery(whereCondition: IWhereCondition, isPrivate: boolean) {
    const collection = isPrivate ? this.COLLECTION.CENTRAL_MEMBERS_PRIVATE : this.COLLECTION.CENTRAL_MEMBERS;
    const queryFn = ref => ref.where(whereCondition.field, whereCondition.operator, whereCondition.value);
    return this.getDocumentsByQuery<UserObject>(collection, queryFn);
  }

  getPasswordFlag(email: string) {
    const queryFn = ref => ref.where('email', '==', email);
    return this.getDocumentsByQuery<ICentralMemberPassword>(this.COLLECTION.CENTRAL_MEMBERS_PASSWORD, queryFn);
  }

  getPrivateMemberData(uid: string) {
    return this.getDocument<ICentralMemberPrivate>(this.COLLECTION.CENTRAL_MEMBERS_PRIVATE, uid);
  }

  getPublicMemberData(uid: string) {
    return this.getDocument<UserObject>(this.COLLECTION.CENTRAL_MEMBERS, uid);
  }

  searchMembers(startsWith: string, searchField: string, country: string, showAllCountries: boolean, roles: Role[] = []): Observable<UserObject[]> {
    const publicSearchFields = ['firstName', 'searchName'];

    // Firestore rules prevent non-admins from accessing centralMembersPrivate of other members
    const collection = publicSearchFields.includes(searchField) ? this.COLLECTION.CENTRAL_MEMBERS : this.COLLECTION.CENTRAL_MEMBERS_PRIVATE;

    const orderConditions: IOrderCondition[] = [];
    const whereConditions: IWhereCondition[] = [];

    if (startsWith != null) {
      // Don't convert startsWith to lower case (some fields are title case). Do this in calling function if necessary.
      const end = startsWith.replace(/.$/, c => String.fromCharCode(c.charCodeAt(0) + 1));
      whereConditions.push({ field: searchField, operator: '>=', value: startsWith });
      whereConditions.push({ field: searchField, operator: '<', value: end });
    }

    if (!showAllCountries) {
      whereConditions.push({ field: 'country', operator: '==', value: country });
    }

    if (roles.length > 0) {
      whereConditions.push({ field: `role`, operator: 'in', value: roles });
    }

    const limit = roles.length > 0 && startsWith === null ? 30 : 10;
    const queryFn = this.createQueryFunction(whereConditions, orderConditions, limit);
    return this.getDocumentsByQuery<UserObject>(collection, queryFn);
  }

  updatePasswordFlagByEmail(email: string, status: boolean) {
    const queryFn = ref => ref.where('email', '==', email);
    return this.getDocumentsByQuery<ICentralMemberPassword>(this.COLLECTION.CENTRAL_MEMBERS_PASSWORD, queryFn)
      .pipe(first())
      .subscribe((docs: ICentralMemberPassword[]) => {
        // In principle there should only be one uid for each email address
        docs.forEach(doc => {
          this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS_PASSWORD, doc.uid, { mustSetPassword: status });
        });
      });
  }

  updatePasswordFlagByUid(uid: string, status: boolean) {
    return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS_PASSWORD, uid, { mustSetPassword: status });
  }

  // Logically the data in centralMembersPrivate is what members would change in their Account.
  updatePrivateMemberData(uid, data): Promise<any> {
    return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS_PRIVATE, uid, data);
  }

  updatePrivateMemberField(uid: string, field: string, data: any, merge: boolean = true): Promise<any> {
    const newData = { [field]: data };
    return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS_PRIVATE, uid, newData, merge);
  }

  // Logically the data in centralMembers is what members would change in their Member Profile.
  updatePublicMemberData(uid: string, data: any, merge: boolean = true): Promise<any> {
    //If the placeId is updated, also update coordinates and locality on member, and member counts on centralPlaces
    if (data.hasOwnProperty('placeId')) {
      return this.updatePublicMemberDataAndLocation(uid, data, merge);
    } else {
      return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS, uid, data, merge);
    }
  }

  updatePublicMemberField(uid: string, field: string, data: any, merge: boolean = true): Promise<any> {
    const newData = { [field]: data };
    return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS, uid, newData, merge);
  }
  /*
  async updatePublicMemberData2(uid: string, data: any, merge: boolean = true, oldData: any = {}): Promise<any> {
    if(oldData.hasOwnProperty('placeId')){
      data = await this.updateMemberLocation(data, oldData)
    }
    if(oldData.hasOwnProperty('displayName')){
      data = await this.updateMemberDisplayName(data, oldData)
    }
    if(oldData.hasOwnProperty('photoURL')){
      data = await this.updateMemberLocation(data, oldData)
    }
  }
*/
  async updatePublicMemberDataAndLocation(uid: string, newData: any, merge: boolean) {
    const newPlaceId = newData.placeId;
    //TODO: try and do this without three nested subscribes

    //NB Each of the following loops subscribes to an observable for a document, but updates the document inside the subscription
    //This will lead to an infinite loop if you don't destroy the subscription after reading the data once,
    //e.g. by using pipe(first())
    return this.getPublicMemberData(uid)
      .pipe(first())
      .subscribe((oldData: ICentralMember) => {
        const oldPlaceId = oldData.placeId;
        if (oldPlaceId != null && oldPlaceId !== '' && oldPlaceId !== newPlaceId) {
          this.getDocument<IPlace>(this.COLLECTION.CENTRAL_PLACES, oldPlaceId)
            .pipe(first())
            .subscribe((oldPlaceData: IPlace) => {
              this.getDocument<IPlace>(this.COLLECTION.CENTRAL_PLACES, newPlaceId)
                .pipe(first())
                .subscribe((newPlaceData: IPlace) => {
                  //Reduce memberCounts for old places
                  //If e.g. state-level placeholders have been deleted, oldPlaceData could be null
                  if (oldPlaceData) {
                    this.updatePlaceMemberCounts(oldPlaceId, oldPlaceData.containedIn, -1);
                    this.notificationService.removeNotificationForMember(NotificationTarget.PLACE, oldPlaceId, uid);
                  }

                  //Increase memberCounts for new places
                  this.updatePlaceMemberCounts(newPlaceId, newPlaceData.containedIn, 1);
                  this.notificationService.createNotificationForMember(NotificationTarget.PLACE, newPlaceId, uid);

                  //Update coordinates & zoomTo on centralMembers
                  //newData.locality is already set from member-profile-edit
                  //country can't be changed by members at present
                  newData.coordinates = newPlaceData.coordinates;
                  newData.zoomTo = newPlaceData.zoomTo;
                  return this.updateUser(uid, newData, merge);
                });
            });
        } else if (oldPlaceId == null || oldPlaceId === '') {
          this.getDocument<IPlace>(this.COLLECTION.CENTRAL_PLACES, newPlaceId)
            .pipe(first())
            .subscribe((newPlaceData: IPlace) => {
              //Increase memberCounts for new places
              this.updatePlaceMemberCounts(newPlaceId, newPlaceData.containedIn, 1);
              this.notificationService.createNotificationForMember(NotificationTarget.PLACE, newPlaceId, uid);

              //Update coordinates on centralMembers
              //newData.locality is already set from member-profile-edit
              //country can't be changed by members at present
              newData.coordinates = newPlaceData.coordinates;
              newData.zoomTo = newPlaceData.zoomTo;
              return this.updateUser(uid, newData, merge);
            });
        } else {
          //placeId is unchanged, just update the other fields
          return this.updateUser(uid, newData, merge);
        }
      });
  }

  private updatePlaceMemberCounts(placeId: string, containedIn: Record<string, string>, increment: number) {
    // Don't aggregate counts on higher level places until we've worked out how to handle them properly in meeting place
    //const places = [placeId, ...Object.keys(containedIn)];
    const places = [placeId];
    places.forEach(id => {
      this.updateDocument(this.COLLECTION.CENTRAL_PLACES, id, { memberCount: FieldValue.increment(increment) });
    });
  }

  private updateUser(uid: string, newData: any, merge: boolean) {
    // Delete organisations or interests if they exist so they can be updated.
    const data: Record<string, any> = {};
    if (newData.organisations != null) data.organisations = FieldValue.delete();
    if (newData.interests != null) data.interests = FieldValue.delete();

    if (Object.keys(data).length > 0) {
      return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS, uid, data, true).then(() => {
        return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS, uid, newData, merge);
      });
    }

    return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS, uid, newData, merge);
  }
}
