import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ToastService } from '@shared/services/toast.service';
import { BaseAdminComponent } from '../base-admin/base-admin.component';

@Component({
  selector: 'app-delete-member',
  templateUrl: '../base-admin/base-admin.component.html',
  styleUrls: ['../base-admin/base-admin.component.scss']
})
export class DeleteMemberComponent extends BaseAdminComponent {
  confirmHeader = 'Delete member';
  confirmButton = 'Delete';
  placeholder = 'Search for a member to permanently delete their account';
  title = 'Delete member';

  constructor(alertController: AlertController, private fns: AngularFireFunctions, private router: Router, private toastService: ToastService) {
    super(alertController);
  }

  confirmMessage(memberId: string, memberName: string) {
    return `<p>Delete ${memberName}'s account?</p><p>THIS CANNOT BE UNDONE AND TAKES EFFECT IMMEDIATELY!</p>`;
  }

  selectMemberHandler({ memberId, memberName, data }) {
    let message = '';

    const callable = this.fns.httpsCallable('deleteMemberNow');
    callable({ uid: memberId })
      .toPromise()
      .then(result => {
        message = `Member ${memberName} deleted`;
      })
      .catch(err => {
        message = `Couldn't delete member ${memberName}: ${err}`;
      })
      .finally(() => {
        this.toastService.presentToast(message);
        this.router.navigate(['/admin']);
      });
  }
}
