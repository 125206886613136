import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Storage } from '@ionic/storage';
import { AuthService } from '@shared/services/auth.service';
import { ThreadOpenService } from '@shared/services/messages/thread-open.service';
import { ContactDetailComponent } from '../../../modules/messages/modules/contacts/contact-detail/contact-detail.component';
import { ThreadDetailComponent } from '../../../modules/messages/modules/threads/thread-detail/thread-detail.component';

@Injectable({
  providedIn: 'root'
})
export class StateGuard implements CanActivate, CanDeactivate<ThreadDetailComponent>, CanDeactivate<ContactDetailComponent> {
  constructor(private storage: Storage, private router: Router, private authService: AuthService, private threadOpenService: ThreadOpenService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return new Promise<boolean | UrlTree>(resolve => {
      this.storage.get('messages-state').then(val => {
        let tree: UrlTree = this.router.parseUrl('/messages/threads');
        if (!this.authService.isImpersonating && null != val) {
          // Saved state, provide UrlTree to saved route
          tree = this.router.parseUrl(val);
        }
        resolve(tree);
      });
    });
  }

  canDeactivate(component: ThreadDetailComponent | ContactDetailComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Promise<boolean | UrlTree>;

  canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return new Promise<boolean | UrlTree>(resolve => {
      if ('messages' === nextState.root.firstChild.url[0].path) {
        this.storage.set('messages-state', null);
      }
      const regex = /\/messages\/threads\/(.*)/;
      const currentStateMatch = currentState.url.match(regex);
      const nextStateMatch = nextState.url.match(regex);
      const wasThreadDetail = currentStateMatch !== null && currentStateMatch.length === 2 && currentStateMatch[1].length > 0;
      const willBeThreadDetail = nextStateMatch !== null && nextStateMatch.length === 2 && nextStateMatch[1].length > 0;
      if (wasThreadDetail && !willBeThreadDetail) {
        //thread is not open, close it.
        this.threadOpenService.setThreadClosed();
      } //the other cases where one of wasThreadDetail or willBeThreadDetail is true will be handled by threadDetail.loadthread

      //Continue navigation
      resolve(true);
    });
  }
}
