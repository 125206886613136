import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IDateOfBirth } from '@infrastructure/models/date-of-birth';
import { AvatarSize } from '@shared/constants/avatar-size';
import { UserObject } from '@shared/models/user-object';
import { ConstantsService } from '@shared/services/constants.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'meeting-place-user-profile',
  templateUrl: './member-profile.component.html',
  styleUrls: ['./member-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeetingPlaceMemberProfileComponent {
  get ASSETS() {
    return this.constantsService.constants.ASSETS;
  }

  get CONSTANTS() {
    return this.constantsService.constants.PROFILE;
  }

  interestsIcon: string;
  interestsLabel: string;
  isInterestsEnabled: boolean = true; // all white-label versions currently have it enabled
  isOrganisationsEnabled: boolean = false;
  organisationsIcon: string;
  organisationsLabel: string;
  readonly size: AvatarSize = AvatarSize.MEDIUM;
  @Input() user: UserObject;

  constructor(private constantsService: ConstantsService, private environmentService: EnvironmentService, private userService: UserService) {}

  getAge(dateOfBirth: IDateOfBirth) {
    return this.userService.getAge(dateOfBirth);
  }

  getKeys(obj) {
    return this.userService.getKeys(obj);
  }

  getLocation(fullLocation: string) {
    return typeof fullLocation === 'string' ? fullLocation.replace(/ \d{4}$/, '') : ''; // Strips postcode from location string. TODO: Remove this when we remove postcodes
  }

  ngOnInit() {
    this.isInterestsEnabled = this.CONSTANTS.INTERESTS.enabled;
    this.interestsLabel = this.CONSTANTS.INTERESTS.label;
    this.interestsIcon = this.CONSTANTS.INTERESTS.icon;
    this.isOrganisationsEnabled = this.CONSTANTS.ORGANISATIONS.enabled;
    this.organisationsLabel = this.CONSTANTS.ORGANISATIONS.label;
    this.organisationsIcon = this.CONSTANTS.ORGANISATIONS.icon;
  }

  openMemberProfile(uid: string) {
    this.userService.openMemberProfile(uid);
  }
}
