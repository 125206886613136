import { Injectable } from '@angular/core';
import { IDateOfBirth } from '@infrastructure/models/date-of-birth';
import { UserObject } from '@shared/models/user-object';
import { ConstantsService } from '@shared/services/constants.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {
  get AGE_RANGES() {
    return this.constantsService.constants.AGE_RANGES;
  }

  MAX_DATE: string;
  MAX_DATE_IN_YEARS: number = 2;

  addDays(dateTime: number, days: number) {
    return moment(dateTime)
      .add(days, 'days')
      .toDate()
      .getTime();
  }

  addDaysToCurrentDate(days: number) {
    const now = this.getDateTime();
    return this.addDays(now, days);
  }

  addYears(dateTime: number, years: number) {
    return moment(dateTime)
      .add(years, 'years')
      .toDate()
      .getTime();
  }

  addYearsToCurrentDate(years: number) {
    const now = this.getDateTime();
    return this.addYears(now, years);
  }

  addYearsToCurrentDateAsString(years: number) {
    const now = this.getDateTime();
    return this.formatDate(this.addYears(now, years), 'YYYY-MM-DD');
  }

  constructor(private constantsService: ConstantsService) {
    // TODO: Will this get stale enough to matter? E.g. if member has long running browser session
    this.MAX_DATE = this.addYearsToCurrentDateAsString(this.MAX_DATE_IN_YEARS);
  }

  createDate(date: string, time: string, randomMilliseconds: boolean = false): number {
    const dateTimeString = `${date} ${time}`;
    let timestamp = moment(dateTimeString, 'YYYY-MM-DD HH:mm') // without format string, date fails to parse on iOS
      .toDate()
      .getTime();
    // When ordering queries by datetime it is sometimes useful to avoid having multiple events at exactly the same time
    // In these cases the exact number of milliseconds is not significant to people, so can be assigned a random value to disambiguate
    return randomMilliseconds ? timestamp + Math.floor(Math.random() * 1000) : timestamp;
  }

  formatDate(date: any, format: string = 'dddd, D MMM YYYY hh:mm a') {
    return moment(date).format(format);
  }

  formatDatetime(date: string, time: string, format: string = 'dddd, D MMM YYYY hh:mm a') {
    const dateTime = `${date} ${time}`;
    return moment(dateTime).format(format);
  }

  getAge(dateOfBirth: IDateOfBirth): number {
    const dateOfBirthString = `${dateOfBirth.year}-${dateOfBirth.month || 1}-${dateOfBirth.day || 1}`;
    return moment().diff(moment(dateOfBirthString, 'YYYY-MM-DD'), 'years');
  }

  getAgeRange(member: UserObject): string {
    if (member.dateOfBirth == null || member.dateOfBirth.year == null) return null;
    const age = this.getAge(member.dateOfBirth);

    let ageRange = '';
    if (age != null) {
      const ageRangeOption = this.AGE_RANGES.filter(a => a.min != null && a.max != null && age >= a.min && age <= a.max);
      if (ageRangeOption.length > 0) ageRange = ageRangeOption[0].label;
    }

    return ageRange;
  }

  // gets a format mask for the angular date pipe.
  // e.g. <time>{{ thread.dateTimeLastUpdated | date: getDateFormat(thread.dateTimeLastUpdated) }}</time>
  // TODO: Turn this into a directive so I can get the dateFormat in a the component.html without needing to inject dateTimeService into the component.ts
  getDateFormat(dateTime: number): string {
    if (dateTime > this.getDateTime()) {
      return 'd MMM y'; // future date
    } else if (dateTime >= this.getStartOfToday()) {
      return 'shortTime';
    } else if (dateTime >= this.getStartOfYear()) {
      return 'd MMM';
    } else {
      return 'd MMM y';
    }
  }

  getDateTime() {
    return Date.now();
  }

  getDateTimeAsISOString() {
    return moment().format('YYYY-MM-DDTHH:mm:ss');
  }

  getDay(dateTime: number = null) {
    if (dateTime == null) dateTime = this.getDateTime(); // use Today.

    return moment(dateTime)
      .startOf('day')
      .toDate()
      .getTime();
  }

  getStartOfToday() {
    return moment()
      .startOf('day')
      .toDate()
      .getTime();
  }

  getStartOfTodayAsString() {
    return moment()
      .startOf('day')
      .format('YYYY-MM-DD');
  }

  getStartOfYear() {
    return moment()
      .startOf('year')
      .toDate()
      .getTime();
  }

  getTimeAsString() {
    return moment().format('HH:mm');
  }

  isBefore(startDate: number, endDate: number): boolean {
    return moment(startDate).isBefore(endDate);
  }

  isPastDate(date: number): boolean {
    return !moment(date).isSameOrAfter(moment());
  }

  subtractDaysFromCurrentDateAsString(days: number) {
    return moment()
      .add(-days, 'day')
      .endOf('day')
      .format('YYYY-MM-DD');
  }

  validateDateOfBirth(dateString: string, minAge: number = 0, maxAge: number = 110) {
    const year = moment().year();
    const birthYear = moment(dateString).year();
    const age = year - birthYear;

    // validate obviously incorrectly entered birth dates
    if (birthYear >= year) return false;
    if (age < minAge) return false;
    if (age > maxAge) return false;

    return true;
  }
}
