import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IShareListing } from '@shared/models/share/share-listing';
import { DateTimeService } from '@shared/services/date-time.service';
import { ShareService } from '@shared/services/share/share.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'share-box',
  styleUrls: ['./share-box.component.scss'],
  templateUrl: './share-box.component.html'
})
export class ShareBoxComponent implements OnInit {
  @Input() link: string;
  @Output() linkClicked = new EventEmitter();
  @Input() placeholder: string;
  latestListings$: Observable<IShareListing[]>;

  constructor(private dateTimeService: DateTimeService, private shareService: ShareService) {}

  getCategories(categories: string[]) {
    return this.shareService.getCategoriesList(categories);
  }

  getDateFormat(created: number) {
    return this.dateTimeService.getDateFormat(created);
  }

  ngOnInit() {
    this.latestListings$ = this.shareService.getLatestShareListings();
  }

  onClick(title: string) {
    this.linkClicked.emit(title || 'Share listing');
  }
}
