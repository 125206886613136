import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ISearchModel } from '@shared/models/search-model';

@Injectable({
  providedIn: 'root'
})
export class MeetingPlaceSearchPresenter {
  form = this.formBuilder.group({
    place: [''],
    placeId: [''],
    memberName: [''],
    keywords: '',
    interests: ['']
  });

  constructor(private formBuilder: FormBuilder) {}

  search(): ISearchModel {
    return this.form.value;
  }

  patchValue(data: any) {
    this.form.patchValue(data);
  }

  reset() {
    this.form.reset();
  }
}
