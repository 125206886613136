import { Component, Input } from '@angular/core';

@Component({
  selector: 'chirpy-error-message',
  templateUrl: './chirpy-error-message.component.html',
  styleUrls: ['./chirpy-error-message.component.scss']
})
export class ChirpyErrorMessageComponent {
  @Input() show: boolean;
}
