import { Injectable } from '@angular/core';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { INotice } from '@shared/models/groups/notice';

@Injectable({
  providedIn: 'root'
})
export class GroupNoticesEditPresenter {
  get notices() {
    return this.form.controls.noticesArray.value;
  }

  keys: string[] = ['uid', 'title', 'content', 'photos'];
  form = this.formBuilder.group({});

  addControls(values: any[], prepend: boolean = false) {
    const array = this.form.controls['noticesArray'] as FormArray;
    values.forEach(value => {
      const group = new FormGroup({});
      for (const objectKey of this.keys) {
        // For photos we want a copy, not a reference, so we can compare old vs new to see if images have been removed
        const controlValue = objectKey === 'photos' ? Object.assign({}, value[objectKey]) : value[objectKey];
        group.addControl(objectKey, new FormControl(controlValue));
      }
      // To preserve chronological order for existing notices use FormArray.push
      // To allow new notices to be added at the top use FormArray.insert
      prepend ? array.insert(0, group) : array.push(group);
    });
  }

  addNewControl() {
    let value = { uid: 'new', title: '', content: '', photos: {} };
    this.addControls([value], true);
  }

  constructor(private formBuilder: FormBuilder) {}

  init(notices: INotice[]) {
    this.form.removeControl('noticesArray');
    this.form.addControl('noticesArray', new FormArray([]));
    this.addControls(notices);
  }

  removeControl(index: number) {
    const array = this.form.controls['noticesArray'] as FormArray;
    array.removeAt(index);
  }
}
