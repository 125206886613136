import { Component } from '@angular/core';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { ConstantsService } from '@shared/services/constants.service';

@Component({
  selector: 'app-share-intro',
  styleUrls: ['./share-intro.page.scss'],
  templateUrl: './share-intro.page.html'
})
export class ShareIntroPage {
  get CONSTANTS() {
    return this.constantsService.constants.SHARE.INTRO;
  }

  get DISCLAIMER() {
    return this.constantsService.constants.SHARE.disclaimer;
  }

  constructor(private analyticsService: AnalyticsService, private constantsService: ConstantsService) {}

  ionViewWillEnter() {
    this.analyticsService.eventTrack(AnalyticsCategory.SHARE, AnalyticsAction.SHARE_VIEW_INTRO);
  }

  onClick(option: any) {
    this.analyticsService.eventTrack(AnalyticsCategory.SHARE, AnalyticsAction.SHARE_INTRO_BUTTON, option.heading);
  }
}
