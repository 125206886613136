import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ChirpyDataTableComponent } from '@shared/components/chirpy-data-table/chirpy-data-table.component';
import { DateTimeService } from '@shared/services/date-time.service';
import { firestore } from 'firebase/app';
import { first } from 'rxjs/operators';
import { IInput } from '@shared/models/input';

@Component({
  selector: 'app-base-log',
  styleUrls: ['./base-log.page.scss'],
  templateUrl: './base-log.page.html'
})
export class BaseLogPage implements AfterViewInit, OnInit {
  canDownload: boolean = true;
  canLoadMore: boolean = false;
  data: any[] = [];
  @ViewChild(ChirpyDataTableComponent, { static: false }) dataTable: ChirpyDataTableComponent;
  DEFAULT_OPTIONS: any;
  fields: IInput[] = [];
  isLoading: boolean = true;
  lastTimestamp: number | firestore.Timestamp = null;
  @ViewChild('search', { static: false }) search: any;
  searchCriteria: Record<string, string> = {};
  searchModel: any;
  title: string = 'Log';

  constructor(protected dateTimeService: DateTimeService) {}

  loadData(append: boolean = false, lastTimestamp: number | firestore.Timestamp = null): void {}

  loadParameters(): void {}

  ngOnInit() {
    this.loadParameters();
    this.loadData();
  }

  ngAfterViewInit() {
    this.search.set(this.searchModel);
  }

  onClickCriterion(key: string) {
    if (key.startsWith('template:')) {
      const value = key.split(':').pop();
      this.searchModel['template'] = (this.searchModel['template'] || []).filter(x => x !== value);
    } else {
      this.searchModel[key] = this.DEFAULT_OPTIONS[key];
    }
    this.canLoadMore = true;
    this.search.set(this.searchModel); // keep values in search presenter in sync
    this.onSearch(this.searchModel);
  }

  onDownloadCsv() {
    this.dataTable.downloadCsv();
  }

  onLoadMore() {
    this.canLoadMore = false;
    this.isLoading = true;
    const append = true;
    this.loadData(append, this.lastTimestamp);
  }

  onSearch(search: any) {
    this.searchModel = search;
    this.loadData();
    this.updateSearchCriteria(search);
  }

  updateSearchCriteria(options: any = null) {}
}
