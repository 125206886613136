import { Injectable } from '@angular/core';
import { ILastAction } from '@shared/models/last-action/last-action';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction } from '@shared/services/analytics/analytics-action';
import { DateTimeService } from '@shared/services/date-time.service';
import { Observable } from 'rxjs';
import { LastActionRTDatabase } from './last-action.rtdatabase';

@Injectable({
  providedIn: 'root'
})
export class LastActionService {
  constructor(private dateTimeService: DateTimeService, private lastActionRTDatabase: LastActionRTDatabase) {}

  getLastActionBeforeDate(date: string): Observable<ILastAction[]> {
    return this.lastActionRTDatabase.getLastActionBeforeDate(this.dateTimeService.createDate(date, '23:59'));
  }

  updateLastAction(member: UserObject, action: AnalyticsAction): void {
    const lastAction = {
      action: action,
      dateTime: this.dateTimeService.getDateTime(),
      displayName: member.displayName || '',
      email: member.email || '',
      membershipType: member.membershipType || ''
    };
    this.lastActionRTDatabase.updateLastAction(member.uid, lastAction);
  }
}
