import { Component, OnInit } from '@angular/core';
import { ICoinsLevel } from '@shared/models/coins/coins-level';
import { ICoinsTransaction } from '@shared/models/coins/coins-transaction';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { CoinsService } from '@shared/services/coins/coins.service';
import { ConstantsService } from '@shared/services/constants.service';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-coins',
  templateUrl: './coins.page.html',
  styleUrls: ['./coins.page.scss']
})
export class CoinsPage implements OnInit {
  get CONSTANTS() {
    return this.constantsService.constants.COINS;
  }

  balance: string;
  coinsToNextLevel: number;
  level: ICoinsLevel;
  nextLevel: ICoinsLevel;
  percentage: number = 1;
  statusDate: number;
  transactions$: Observable<ICoinsTransaction[]>;
  TRANSACTIONS_TO_FETCH = 3;

  constructor(private analyticsService: AnalyticsService, public authService: AuthService, private coinsService: CoinsService, private constantsService: ConstantsService) {}

  ionViewWillEnter() {
    this.analyticsService.eventTrack(AnalyticsCategory.COINS, AnalyticsAction.COINS_VIEW_SUMMARY);
  }

  ngOnInit() {
    this.transactions$ = this.authService._userProfileSubject.pipe(
      switchMap((member: UserObject) => {
        this.statusDate = member.statusDate;
        this.level = this.coinsService.getLevelByName(member.coinsLevel);
        this.nextLevel = this.coinsService.getNextLevel(this.level);
        this.coinsToNextLevel = this.coinsService.getCoinsToNextLevel(member, this.level, this.nextLevel);
        this.percentage = this.coinsService.getPercentageToNextLevel(member, this.level, this.nextLevel);
        if (this.coinsToNextLevel < 0) {
          this.coinsToNextLevel = 0;
          this.percentage = 1;
        }
        return this.coinsService.getTransactionsForMember(member.uid, member.coinsBalance, this.TRANSACTIONS_TO_FETCH);
      })
    );
  }

  sortNull() {}
}
