import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DateTimeService } from '@shared/services/date-time.service';
import { ICatchup } from '@shared/models/catchups/catchup';
import { IEvent } from '@shared/models/events/event';
import { ISocial } from '@shared/models/social/social';
import { ITrip } from '@shared/models/trips/trip';

@Component({
  selector: 'chirpy-catchup-time',
  templateUrl: './chirpy-catchup-time.component.html',
  encapsulation: ViewEncapsulation.None
})
export class ChirpyCatchupTimeComponent {
  @Input() catchup: any; // ICatchup | ISocial | IEvent | ITrip

  constructor(private dateTimeService: DateTimeService) {}

  get time() {
    if (this.catchup == null || this.catchup.date == null || this.catchup.time == null) return null;
    return this.dateTimeService.createDate(this.catchup.date, this.catchup.time);
  }

  get endTime() {
    if (this.catchup == null || this.catchup.date == null || this.catchup.endTime == null) return null;
    return this.dateTimeService.createDate(this.catchup.date, this.catchup.endTime);
  }
}
