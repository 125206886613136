import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { IValueWithId } from '@shared/models/value-with-id';
import { first } from 'rxjs/operators';

@Component({
  selector: 'chirpy-place-search',
  templateUrl: './chirpy-place-search.component.html',
  styleUrls: ['chirpy-place-search.component.scss']
})
export class ChirpyPlaceSearchComponent implements OnInit {
  get hasIndex() {
    return this.currentLetter !== '' && this.currentIndex.length > 0;
  }

  get hasNames() {
    return Object.values(this.selectedItems).length > 0;
  }

  get names() {
    //Only show the place name if there is only one selected
    const itemValues = Object.values(this.selectedItems || {});
    return itemValues.length === 1 ? itemValues[0] : '';
  }

  @Input() addItem;
  availableItems: IValueWithId[] = [];
  @Input() canRemoveItems: boolean = true;
  currentLetter: string = '';
  currentIndex: IValueWithId[] = [];
  readonly ITEMS_TO_SHOW: number = 15;
  @Input() noResultsText: string;
  @Input() placeholder: string;
  @Input() prepopulateResults: boolean = false;
  @Input() removeItem;
  @Input() search;
  @ViewChild('searchBar', { static: false }) searchBar: IonSearchbar;
  @Input() selectedItems: Record<string, string>;
  showInstructions: boolean = true;
  showNoItemsFoundMessage: boolean = false;
  readonly VOWELS_WITH_MACRONS: string[] = ['ā', 'ē', 'ī', 'ō', 'ū', 'Ā', 'Ē', 'Ī', 'Ō', 'Ū'];
  readonly VOWELS_WITHOUT_MACRONS: string[] = ['a', 'e', 'i', 'o', 'u', 'A', 'E', 'I', 'O', 'U'];

  constructor(private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss();
  }

  filterCurrentIndex(search: string) {
    if (search.length === 0) {
      this.availableItems = this.currentIndex.slice(0, this.ITEMS_TO_SHOW);
    } else {
      let startsWith: Record<string, IValueWithId> = {};
      let includes: Record<string, IValueWithId> = {};
      for (const x of this.currentIndex) {
        const name = this.normaliseSearch(x.name);
        if (name.startsWith(search)) {
          // TODO: The following line means that if a place has two entries in the index which map to the same normalised form (i.e. without diacritics)
          // then the second entry will be displayed, and which one this is depends on the relative ordering of the uids
          // Ideally we would show the canonical form
          startsWith[x.uid] = x;
        } else if (search.split(' ').every((y, index, terms) => (index === 0 ? name.startsWith(y) : name.includes(y)))) {
          includes[x.uid] = x;
        }
      }
      this.availableItems = Object.values(Object.assign(startsWith, includes));
      if (search.length < 3) this.availableItems = this.availableItems.slice(0, this.ITEMS_TO_SHOW);
    }
    this.focusSearchBar();
    this.showNoItemsFoundMessage = this.availableItems.length === 0;
  }

  focusSearchBar(): void {
    if (this.searchBar) this.searchBar.setFocus();
  }

  ngOnInit(): void {
    // Workaround for setting focus on searchBar when there is no search term
    const delay = this.selectedItems && this.hasNames ? 0 : 1000;

    //prepopulate results
    if (this.prepopulateResults) {
      this.search('')
        .pipe(first())
        .subscribe(items => this.processSearchResults(items, ''));
    }

    setTimeout(() => {
      this.focusSearchBar();
      if (this.searchBar && this.selectedItems) this.searchBar.value = this.names;
    }, delay);
  }

  normaliseSearch(search: string) {
    // Remove excess whitespace
    search = search.trim();
    // Strip macrons from (e.g.) Maori placenames when searching
    const textArray = Array.from(search);
    const searchWithoutMacrons = textArray
      .map(char => {
        let index = this.VOWELS_WITH_MACRONS.indexOf(char);
        if (index > -1) char = this.VOWELS_WITHOUT_MACRONS[index];
        return char;
      })
      .join('');

    return searchWithoutMacrons.toLowerCase();
  }

  onAddItem(item: any) {
    this.addItem(item);
    this.dismiss();
  }

  onClearSearch() {
    this.clearSearchBar();
    this.availableItems = [];
    // In order to update the control value, we need to call either the addItem function with a null value, or remove the existing item
    if (!this.canRemoveItems) {
      this.addItem({ uid: '', name: '' });
    }
    this.showInstructions = true;
    this.showNoItemsFoundMessage = false;
  }

  onRemoveItem(item: any) {
    this.removeItem(item);
  }

  onSearch(search: string) {
    this.showNoItemsFoundMessage = false;
    const normalisedSearch = search ? this.normaliseSearch(search) : '';
    this.showInstructions = normalisedSearch.length === 0;
    const searchLetter = normalisedSearch.slice(0, 1);
    if (normalisedSearch.length < 1) {
      // TODO: Populate List with default values? hierarchy for member's location? (e.g. Ellerslie, Auckland, NZ) or recent searches?
      return;
    } else if (searchLetter !== this.currentLetter) {
      this.currentLetter = searchLetter;
      this.availableItems = [];
      //Get new index for places starting with this letter
      this.search(normalisedSearch)
        .pipe(first())
        .subscribe(items => this.processSearchResults(items, normalisedSearch));
    } else {
      //Filter the already loaded index
      this.filterCurrentIndex(normalisedSearch);
    }
  }

  // Convert object to array for ease of filtering
  processSearchResults(items: any, search: string) {
    const currentIndex: IValueWithId[] = [];
    for (const [name, uid] of Object.entries(items)) {
      currentIndex.push({ uid: uid as string, name: name as string });
    }

    this.currentIndex = currentIndex.sort((a, b) => a.name.localeCompare(b.name));
    this.filterCurrentIndex(search);
  }

  private clearSearchBar(): void {
    this.searchBar.value = '';
  }
}
