import { Injectable } from '@angular/core';
import { IEmail } from '@shared/models/email';
import { IEmailOptions } from '@shared/models/email-options';
import { IMailTemplate } from '@shared/models/mail-template';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailDatabase extends BaseDatabase {
  getEmail(uid: string): Observable<IEmail> {
    return this.getDocument<IEmail>(this.COLLECTION.MAIL, uid, true);
  }

  getEmails(search: IEmailOptions, limit: number, lastTimestamp: firestore.Timestamp): Observable<IEmail[]> {
    const queryFn = this.getEmailsQuery(search, limit, lastTimestamp);
    return this.getDocumentsByQuery<any>(this.COLLECTION.MAIL, queryFn);
  }

  getMailTemplate(mailTemplateName: string): Observable<IMailTemplate> {
    return this.getDocument<IMailTemplate>(this.COLLECTION.MAIL_TEMPLATES, mailTemplateName, true);
  }

  getMailTemplates(): Observable<IMailTemplate[]> {
    return this.getDocuments<IMailTemplate>(this.COLLECTION.MAIL_TEMPLATES, true);
  }

  getPartialTemplates(uids: string[] = []): Observable<IMailTemplate[]> {
    const queryFn = this.getPartialsQuery(uids);
    return this.getDocumentsByQuery<IMailTemplate>(this.COLLECTION.MAIL_TEMPLATES, queryFn);
  }

  sendEmail(to: string, subject: string, html: string, bcc: string) {
    return this.afs
      .collection(`mail`)
      .add({
        to,
        bcc,
        message: {
          subject,
          html
        }
      })
      .catch(err => {
        throw err;
      });
  }

  sendEmailToMember(to: any, name: string, data: any, bcc: string) {
    return this.afs
      .collection(`mail`)
      .add({
        ...to,
        bcc,
        template: {
          name,
          data
        }
      })
      .catch(err => {
        throw err;
      });
  }

  private getEmailsQuery(search: IEmailOptions, recordsToFetch: number, lastTimestamp: firestore.Timestamp) {
    const whereConditions: IWhereCondition[] = [];

    // If we are loading more entries for the same search
    if (lastTimestamp != null) {
      // If we have already loaded results for the current search, then we always want results < lastTimestamp,
      // regardless of the search, because the results are ordered by startTime desc.
      whereConditions.push({ field: 'delivery.startTime', operator: '<', value: lastTimestamp });

      // TODO: Edge case - what if lastTimestamp is exactly equal to search.sentAfter?
      // Low probability because lastTimstamp has nanosecond precision while search.sentAfter only specifies seconds
    } else {
      if (search.sentBefore !== '') {
        const sentBefore = firestore.Timestamp.fromDate(new Date(search.sentBefore));
        whereConditions.push({ field: 'delivery.startTime', operator: '<', value: sentBefore });
      }
    }

    if (search.sentAfter !== '') {
      const sentAfter = firestore.Timestamp.fromDate(new Date(search.sentAfter));
      whereConditions.push({ field: 'delivery.startTime', operator: '>=', value: sentAfter });
    }

    if (search.to !== '') {
      whereConditions.push({ field: 'delivery.info.accepted', operator: 'array-contains', value: search.to });
    }

    if (search.template.length > 0) {
      whereConditions.push({ field: 'template.name', operator: 'in', value: search.template });
    }

    if (search.status !== '') {
      // TODO: Support multiple statuses if no other conditions?
      // Can't have more than one "in" operator in a single query
      whereConditions.push({ field: 'delivery.state', operator: '==', value: search.status });
    }

    const orderConditions: IOrderCondition[] = [];
    orderConditions.push({ field: 'delivery.startTime', direction: 'desc' });

    return this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
  }

  private getPartialsQuery(uids: string[]) {
    const RECORDS_TO_FETCH = 9999;
    const whereConditions: IWhereCondition[] = [];
    if (uids) {
      //__name__ is a special keyword for document uid; an alternative is to use firebase.firestore.FieldPath.documentId()
      // TODO: in operator only supports max of 10 values, us createChunkedQueryFunctions if we get up to more than 10 partials per template
      whereConditions.push({ field: '__name__', operator: 'in', value: uids });
    } else {
      whereConditions.push({ field: 'partial', operator: '==', value: true });
    }

    const orderConditions: IOrderCondition[] = [];

    return this.createQueryFunction(whereConditions, orderConditions, RECORDS_TO_FETCH);
  }
}
