export interface IAgeRange {
  label: string;
  max: number;
  min: number;
}

export const ALL_AGES: IAgeRange = {
  label: 'All ages',
  min: 0,
  max: 120
};
