import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IColumn } from '@shared/models/column';
import { IDataTableConfig } from '@shared/models/data-table-config';
import { parseAsync } from 'json2csv';

@Component({
  selector: 'chirpy-data-table',
  styleUrls: ['./chirpy-data-table.component.scss'],
  templateUrl: './chirpy-data-table.component.html'
})
export class ChirpyDataTableComponent {
  @Input() columns: IColumn[];
  @Input() data: any[];
  @Input() config: IDataTableConfig;
  @Output() output = new EventEmitter();
  constructor() {}

  async downloadCsv(fileName: string = 'report.csv') {
    const fields: any[] = this.columns.map((column: IColumn) => {
      return { label: column.label, value: column.field };
    });

    const opts = { fields };
    const csv = await parseAsync(this.data, opts).catch(err => console.error(err));

    let anchor = document.createElement('a');
    anchor.href = 'data:attachment/csv,' + encodeURI(csv);
    anchor.target = '_self';
    anchor.rel = 'noopener';
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  getRouterLink(data: any) {
    if (!this.config.routerLink) return '';
    const ids = Array.isArray(this.config.routerLink.id) ? [...this.config.routerLink.id] : [this.config.routerLink.id];
    const params = ids.map(id => (id.startsWith(':') ? data[id.slice(1)] : id)); // TODO: rename id to params?
    return [this.config.routerLink.path, ...params];
  }

  onClick(data: any, row: number, isIcon: boolean) {
    this.output.emit({ data, row, isIcon });
  }
}
