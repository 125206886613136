import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IGroup } from '@shared/models/groups/group';
import { ConstantsService } from '@shared/services/constants.service';

@Component({
  selector: 'chit-chat',
  templateUrl: './chit-chat.component.html'
})
export class ChitChatComponent {
  get threadId() {
    return this.group != null ? `group_${this.group.uid}` : null;
  }

  viewAllLabel: string;
  CONSTANTS: any;
  @Input() group: IGroup;
  @Input() isAdmin: boolean;
  @Input() isGroupMember: boolean;
  @Output() linkClicked = new EventEmitter();
  @Input() memberId: string;

  constructor(private constantsService: ConstantsService) {}

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.GROUPS.CHIT_CHAT;
    this.viewAllLabel = `View all ${this.CONSTANTS.branding.toLowerCase()}`;
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }
}
