import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserObject } from '@shared/models/user-object';

@Component({
  selector: 'meeting-place-list-member-profiles',
  templateUrl: './list-member-profiles.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MeetingPlaceListUserProfilesComponent {
  @Input() users: UserObject[];
}
