import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { IonSearchbar } from '@ionic/angular';
import { IContact } from '@shared/models/messages/contact.ts';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { UserDatabase } from '@shared/services/user/user.database';
import { ContactService } from '@shared/services/messages/contact.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ISearchOption } from './models/search-option';

@Component({
  selector: 'chirpy-member-search',
  templateUrl: './chirpy-member-search.component.html',
  styleUrls: ['chirpy-member-search.component.scss']
})
export class ChirpyMemberSearchComponent implements OnInit, OnDestroy {
  get showAllCountries(): boolean {
    return this.constantsService.constants.APP.allowOtherCountries || this.authService.isAdmin();
  }

  @Output() addMember = new EventEmitter();
  blockedMemberIds: string[] = []; // these members have blocked you (member uid)
  @Input() chips = false; // display the list of members to remove as chips or as list items
  @Input() membersToAdd: Record<string, string> = {};
  @Input() membersToRemove: Record<string, string> = {};
  memberSubscription: Subscription;
  @Input() noSearch: boolean = false;
  @Input() placeholder: string;
  @Output() removeMember = new EventEmitter();
  @ViewChild('searchBar', { static: false }) searchBar: IonSearchbar;
  searchField: string = 'searchName';
  searchOptions: ISearchOption[] = [
    { name: 'Display name', value: 'searchName' },
    { name: 'Email', value: 'email' },
    { name: 'First name', value: 'firstName' },
    { name: 'Last name', value: 'lastName' }
  ];
  searchResults: Record<string, string> = {}; // member uid, member name
  @Input() showBlockedUsers: boolean = false;
  showNoMembersFoundMessage = false;
  @Input() showSearchOptions: boolean = false;

  constructor(private afs: AngularFirestore, private authService: AuthService, private constantsService: ConstantsService, private contactService: ContactService, private subscriptionService: SubscriptionService, private userDatabase: UserDatabase) {}

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.memberSubscription);
  }

  ngOnInit() {
    if (!this.showBlockedUsers) {
      this.getBlockedMembers();
    }
  }

  onAddMember(memberId: string, memberName: string) {
    this.addMember.emit({ memberId, memberName });
  }

  onRemoveMember(memberId: string, memberName: string) {
    this.removeMember.emit({ memberId, memberName });
  }

  clearSearch() {
    this.clearSearchBar();
    this.clearSearchResults();
  }

  private clearSearchResults() {
    this.searchResults = {};
  }

  private clearSearchBar(): void {
    this.searchBar.value = '';
  }

  focusSearchBar(): void {
    this.searchBar.setFocus();
  }

  searchForMembers(search: string, searchField: string = 'searchName') {
    this.showNoMembersFoundMessage = false;

    if (search && search.length < 2) {
      this.clearSearchResults();
      return;
    }

    const caseInsensitiveFields = ['searchName', 'email'];
    if (caseInsensitiveFields.includes(searchField)) {
      search = search.toLowerCase();
    } else {
      search = search[0].toUpperCase() + search.slice(1);
    }

    this.subscriptionService.clearSubscription(this.memberSubscription);
    this.memberSubscription = this.searchMembers(search, searchField).subscribe((members: UserObject[]) => {
      const searchResults: Record<string, string> = {};
      members.forEach((user: UserObject) => {
        const blockedByMember = this.blockedMemberIds.some(x => x === user.uid);
        if (!blockedByMember) {
          const country = this.showAllCountries && user.country ? ` (${user.country})` : '';
          searchResults[user.uid] = `${this.getDisplayField(searchField, user)}${country}`;
        }
      });
      this.searchResults = searchResults;

      this.focusSearchBar();

      this.showNoMembersFoundMessage = Object.keys(searchResults).length === 0;
    });
    this.subscriptionService.add(this.memberSubscription);
  }

  searchMembers(startsWith: string, searchField: string) {
    const myCountry: string = this.authService._userProfileSubject.value.country;
    return this.userDatabase.searchMembers(startsWith, searchField, myCountry, this.showAllCountries);
  }

  getBlockedMembers() {
    // This returns both members you have blocked, and members who have blocked you
    // In either case you shouldn't be able to contact the other member
    return this.contactService.getEitherBlockedMembers().subscribe((members: IContact[]) => {
      this.blockedMemberIds = members.map(y => y.relatedId);
    });
  }

  private getDisplayField(searchField: string, user: UserObject) {
    switch (searchField) {
      case 'email':
        return `${user.email} (${user.fullName})`;

      case 'firstName':
        return `${user.displayName} (${user.firstName})`;

      case 'lastName':
        return `${user.fullName} (${user.email})`;

      case 'searchName':
        return user.displayName;
    }
  }
}
