import { Injectable, OnDestroy } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { IToken } from '@shared/models/chats/token';
import { IVirtualCatchup } from '@shared/models/chats/virtual-catchup';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { ToastService } from '@shared/services/toast.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { VirtualCatchupDatabase } from './virtual-catchup.database';

@Injectable({
  providedIn: 'root'
})
export class VirtualCatchupService implements OnDestroy {
  get videoTimeLimit(): number {
    return this._videoTimeLimit;
  }

  meetingToken$ = new BehaviorSubject(null);
  meetingTokenSubscription: Subscription;
  roomName: string = '';
  timedOutModal: any;
  private _videoTimeLimit: number = 0; // Timeout in minutes.
  virtualCatchup$: BehaviorSubject<string> = new BehaviorSubject('');
  warningModal: any;

  constructor(
    private virtualCatchupDatabase: VirtualCatchupDatabase,
    private alertController: AlertController,
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private analyticsService: AnalyticsService,
    private environmentService: EnvironmentService
  ) {
    this.init();
  }

  createVirtualCatchupHandler(name: string, id: string) {
    this.analyticsService.eventTrack(AnalyticsCategory.VIRTUAL_CATCHUPS, AnalyticsAction.VIRTUAL_CATCHUPS_CREATE, name);
    return this.virtualCatchupDatabase.createVirtualCatchup(name, id);
  }

  init() {
    this.authService.userProfileObservable.subscribe((member: UserObject) => {
      if (member === null || member === undefined) return;

      this.subscriptionService.clearSubscription(this.meetingTokenSubscription);
      this.meetingTokenSubscription = this.virtualCatchupDatabase.getMeetingToken(member).subscribe((data: IToken) => {
        if (data !== undefined && this.meetingToken$.value !== data.token) {
          this.meetingToken$.next(data.token);
        } else {
          // This will write to the database, and hence update the getMeetingToken observable
          this.virtualCatchupDatabase.createMeetingToken(member);
        }
      });
      this.subscriptionService.add(this.meetingTokenSubscription);
    });
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.meetingTokenSubscription);
  }

  async presentTimedOutModal(videoTimeLimitInMinutes: number) {
    let delay = videoTimeLimitInMinutes * 60 * 1000;
    if (delay < 0) delay = 0; // display modal immediately if video session finishes before 2 minutes.

    this.timedOutModal = setTimeout(async () => {
      const alert = await this.alertController.create({
        header: 'Whoops...',
        message: `<p>Your video session has timed out because you have reached the ${videoTimeLimitInMinutes} minute limit.</p>`,
        buttons: ['OK']
      });

      await alert.present();

      this.unsetCatchup();
    }, delay);
  }

  // Setup a timer to warn the user their video meeting is about to end.
  presentTimeoutWarningModal(videoTimeLimitInMinutes: number) {
    const MINUTES_WARNING = 2;
    let delay = (videoTimeLimitInMinutes - MINUTES_WARNING) * 60 * 1000;
    if (delay < 0) delay = 0; // display modal immediately if video session finishes before 2 minutes.

    this.warningModal = setTimeout(async () => {
      const alert = await this.alertController.create({
        header: 'Just letting you know',
        message: `<p>Your video session will time out in ${MINUTES_WARNING} minutes.</p>`,
        buttons: ['OK']
      });
      await alert.present();
    }, delay);
  }

  async removeVirtualCatchup(virtualCatchup: IVirtualCatchup) {
    const alert = await this.alertController.create({
      header: 'Delete Virtual Catchup Group',
      message: `<p>Are you sure you want to delete ${virtualCatchup.name}?</p>`,
      buttons: [
        {
          text: 'Delete',
          handler: () => this.removeVirtualCatchupHandler(virtualCatchup.id)
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  removeVirtualCatchupHandler(id: string) {
    const virtualCatchupName = this.environmentService.name + id;
    this.analyticsService.eventTrack(AnalyticsCategory.VIRTUAL_CATCHUPS, AnalyticsAction.VIRTUAL_CATCHUPS_DELETE, virtualCatchupName);
    return this.virtualCatchupDatabase.removeVirtualCatchup(virtualCatchupName, id);
  }

  setCatchup(name: string, uid: string, isDirectMessage: boolean) {
    const virtualCatchupExists = name != null && name !== '';
    if (!virtualCatchupExists) {
      // Create Virtual Catchup on demand if it doesn't exist.
      const virtualCatchupName = this.createVirtualCatchupName(uid);
      this.createVirtualCatchupHandler(virtualCatchupName, uid).subscribe(() => {
        // Join Virtual CatchUp after creating it.
        this.setCatchup(virtualCatchupName, uid, isDirectMessage);
      });
      return;
    }

    this.clearModalTimers();
    this.setVideoTimeLimit(isDirectMessage);
    this.roomName = name;
    if (this.virtualCatchup$.value !== name) {
      this.virtualCatchupDatabase.updateRoomDeleteDate(uid);
      this.virtualCatchup$.next(name);
    }
  }

  // Daily.co has ways to eject people from the room, but we are controlling this client side so that we can easily
  // change the eject rules for existing/new virtual catchups/daily.co rooms without making upgrade scripts.
  // I'm ejecting people with a setTimeout() instead of waiting for a server side message to eject a member.
  setVideoTimeLimit(isDirectMessage: boolean): number {
    if (isDirectMessage) {
      this._videoTimeLimit = 30; // 30 minutes
    } else {
      this._videoTimeLimit = 120; // 2 hours
    }

    return this._videoTimeLimit;
  }

  unsetCatchup(): void {
    this.roomName = '';
    if (this.virtualCatchup$.value !== '') this.virtualCatchup$.next('');
    this.clearModalTimers();
  }

  private clearModalTimers() {
    if (this.warningModal) clearTimeout(this.warningModal);
    if (this.timedOutModal) clearTimeout(this.timedOutModal);
  }

  private createVirtualCatchupName(uid: string) {
    // Use shortName form of environment name; daily.co does not allow hyphens in room name and has limit of 41 characters including subdomain
    return this.environmentService.shortName + uid;
  }
}
