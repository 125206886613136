import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IAnnouncement } from '@shared/models/advertisers/announcement';
import { IContent } from '@shared/models/content';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { AdvertiserService } from '@shared/services/advertisers/advertiser.service';
import { ToastService } from '@shared/services/toast.service';
import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { AnnouncementEditPresenter } from './announcement-edit.presenter';

@Component({
  selector: 'app-announcement-edit',
  styleUrls: ['./announcement-edit.page.scss'],
  templateUrl: './announcement-edit.page.html',
  viewProviders: [AnnouncementEditPresenter]
})
export class AnnouncementEditPage implements OnInit {
  get form() {
    return this.announcementEditPresenter.form;
  }

  advertiserId: string;
  announcement: IAnnouncement;
  canEdit: boolean = false;
  CONSTANTS: any;
  content$: Observable<IContent>;
  @ViewChild('editor', { static: false }) editor: any;
  errorMessage: string;
  filePrefix: string;

  constructor(public announcementEditPresenter: AnnouncementEditPresenter, private advertiserService: AdvertiserService, private authService: AuthService, private constantsService: ConstantsService, private route: ActivatedRoute, private router: Router, private toastService: ToastService) {}

  goBack() {
    this.advertiserId ? this.router.navigate([`/advertisers`, this.advertiserId]) : this.router.navigate([`/advertisers`]);
  }

  ngOnInit() {
    this.advertiserId = this.route.snapshot.paramMap.get('advertiserId');
    let announcementId = this.route.snapshot.paramMap.get('announcementId');
    announcementId = announcementId === 'new' ? this.advertiserService.createId() : announcementId;

    this.filePrefix = `advertiser/${this.advertiserId}/announcement/${announcementId}`;
    this.CONSTANTS = this.constantsService.constants.ADVERTISERS.ANNOUNCEMENTS;

    this.content$ = this.advertiserService.canEditAdvertiser(this.advertiserId).pipe(
      switchMap(canEdit => {
        this.canEdit = canEdit;
        if (!canEdit) {
          this.errorMessage = this.CONSTANTS.permissionErrorMessage;
          return of(null);
        } else {
          return this.advertiserService.getAnnouncement(this.advertiserId, announcementId).pipe(
            first(),
            map((announcement: IAnnouncement) => {
              if (announcement == null) {
                this.announcement = {
                  content: {
                    blocks: []
                  },
                  dateTimeSent: 0,
                  dateTimeLastUpdated: 0,
                  title: '',
                  uid: announcementId
                };
              } else {
                this.announcement = announcement;
              }

              this.announcementEditPresenter.setValue(this.announcement);
              return this.announcement.content;
            })
          );
        }
      })
    );
  }

  onDeleteAnnouncement() {
    this.advertiserService.deleteAnnouncement(this.advertiserId, this.announcement.uid);
  }

  updateAnnouncement() {
    // merge form values with description from rich text editor
    let formValue = this.announcementEditPresenter.announcement();
    formValue.dateTimeLastUpdated = Date.now();
    if (this.announcement.dateTimeSent === 0) formValue.dateTimeSent = formValue.dateTimeLastUpdated;
    this.editor
      .onSave()
      .then(content => {
        Object.assign(this.announcement, formValue, { content: content });
        this.advertiserService.updateAnnouncement(this.advertiserId, this.announcement);
      })
      .catch(err => {
        const message = `Couldn't update ${this.CONSTANTS.branding}: ${err}`;
        this.toastService.presentToast(message);
      });
  }
}
