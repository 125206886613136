import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ChirpyPhotoDetailPresenter {
  form = this.formBuilder.group({
    caption: ['']
  });

  constructor(private formBuilder: FormBuilder) {}

  setFormValue(data: string) {
    this.form.patchValue({ caption: data });
  }

  submit() {
    return this.form.value;
  }
}
