import { Directive, ElementRef, EventEmitter, OnDestroy, Output, NgZone } from '@angular/core';

@Directive({
  selector: '[resize]'
})
export class ChirpyResizeObserverDirective implements OnDestroy {
  private observer: any;

  @Output() resize = new EventEmitter();

  constructor(private elementRef: ElementRef, private zone: NgZone) {
    if ('ResizeObserver' in window === true) {
      const element = this.elementRef.nativeElement;

      // Current version of Typescript gives "Cannot find name 'ResizeObserver'" error without the (window as any) workaround
      this.observer = new (window as any).ResizeObserver(entries => {
        this.zone.run(() => {
          this.resize.emit(entries[0]);
        });
      });

      this.observer.observe(this.elementRef.nativeElement);
    }
  }

  ngOnDestroy(): void {
    if (this.observer) this.observer.unobserve(this.elementRef.nativeElement);
  }
}
