import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '@shared/guards/admin-guard';
import { AdminPlayersPage } from './admin/admin-players/admin-players.page';
import { AdminPage } from './admin/admin.page';
import { GamePage } from './game/game.page';
import { StartPage } from './start/start.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
  {
    path: 'start',
    component: StartPage,
    data: {
      title: 'Bingo intro'
    }
  },
  {
    path: 'game/:gameId',
    component: GamePage,
    data: {
      title: 'Bingo game'
    }
  },
  {
    path: 'admin',
    component: AdminPage,
    canActivate: [AdminGuard],
    data: {
      title: 'Bingo settings'
    }
  },
  {
    path: 'admin/:gameId',
    component: AdminPage,
    canActivate: [AdminGuard],
    data: {
      title: 'Bingo settings'
    }
  },
  {
    path: 'admin/:gameId/players',
    component: AdminPlayersPage,
    canActivate: [AdminGuard],
    data: {
      title: 'Bingo players list'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BingoRoutingModule {}
