import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { AvatarSize } from '@shared/constants/avatar-size';
import { PhotoSize } from '@shared/constants/photo-size';
import { IMarketplaceListing } from '@shared/models/marketplace/marketplace-listing';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { MessageHelperService } from '@shared/services/messages/message-helper.service';
import { MarketplaceService } from '@shared/services/marketplace/marketplace.service';
import { UserService } from '@shared/services/user/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'marketplace-detail',
  templateUrl: './marketplace-detail.component.html',
  styleUrls: ['./marketplace-detail.component.scss']
})
export class MarketplaceDetailComponent implements OnInit, OnDestroy {
  get CONSTANTS() {
    return this.constantsService.constants.MARKETPLACE.DETAIL;
  }

  get DISCLAIMER() {
    return this.constantsService.constants.MARKETPLACE.disclaimer;
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin([AdminRole.SUPER]);
  }

  get placeholderImage(): string {
    return this.constantsService.constants.ASSETS.placeholderImage;
  }

  get user(): UserObject | null {
    if (this.authService._userProfileSubject != null && this.authService._userProfileSubject.value != null) {
      return this.authService._userProfileSubject.value;
    }
    return null;
  }

  get userId(): string {
    return this.user ? this.user.uid : '';
  }

  avatarUrl: string;
  errorMessage: string;
  marketplaceListing: IMarketplaceListing;
  marketplaceListingRef: Subscription;
  size: AvatarSize = AvatarSize.LARGE;
  title: string;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private messageHelperService: MessageHelperService,
    private marketplaceService: MarketplaceService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {}

  approveListing() {
    const uid = this.marketplaceListing.uid;
    this.marketplaceService.approveMarketplaceListing(uid);
  }

  getCategories(categories: string[]) {
    return this.marketplaceService.getCategoriesList(categories);
  }

  deleteMarketplaceListing() {
    this.marketplaceService.deleteMarketplaceListing(this.marketplaceListing);
  }

  getDateFormat(created: number) {
    return this.dateTimeService.getDateFormat(created);
  }

  getMarketplaceListing(uid: string) {
    this.marketplaceListingRef = this.marketplaceService.getMarketplaceListing(uid).subscribe(marketplaceListing => {
      if (marketplaceListing == null) {
        this.title = this.CONSTANTS.pageHeading;
        this.errorMessage = this.CONSTANTS.doesNotExist;
        return;
      }

      this.marketplaceListing = marketplaceListing;
      this.title = this.marketplaceListing.title;

      this.sendAnalytics(this.marketplaceListing);
    });
  }

  goBack() {
    this.router.navigate([`/marketplace`]);
  }

  ngOnDestroy() {
    if (this.marketplaceListingRef) this.marketplaceListingRef.unsubscribe();
  }

  ngOnInit() {
    const uid = this.route.snapshot.paramMap.get('id');
    if (uid == null) return;
    this.getMarketplaceListing(uid);
  }

  onSendMessage() {
    this.analyticsService.eventTrack(AnalyticsCategory.MARKETPLACE, AnalyticsAction.MARKETPLACE_SEND_MESSAGE, this.marketplaceListing.title, { type: this.getCategories(this.marketplaceListing.categories) });
  }

  rejectListing() {
    const uid = this.marketplaceListing.uid;
    this.marketplaceService.rejectMarketplaceListing(uid);
  }

  sendAnalytics(marketplaceListing: IMarketplaceListing) {
    if (marketplaceListing.memberId === this.userId) {
      this.analyticsService.eventTrack(AnalyticsCategory.MARKETPLACE, AnalyticsAction.MARKETPLACE_VIEW_OWN_LISTING_DETAIL, marketplaceListing.title, { type: this.getCategories(marketplaceListing.categories) });
    } else {
      this.analyticsService.eventTrack(AnalyticsCategory.MARKETPLACE, AnalyticsAction.MARKETPLACE_VIEW_LISTING_DETAIL, marketplaceListing.title, { type: this.getCategories(marketplaceListing.categories) });
    }
  }
}
