import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ICatchup } from '@shared/models/catchups/catchup';
import { IGuest } from '@shared/models/catchups/guest';
import { IPayment } from '@shared/models/catchups/payment';
import { IColumn } from '@shared/models/column';
import { IDataTableConfig } from '@shared/models/data-table-config';
import { IValueWithId } from '@shared/models/value-with-id';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UserService } from '@shared/services/user/user.service';
import { Observable, Subscription } from 'rxjs';
import { first, map, skipWhile, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-catchup-payments',
  templateUrl: './catchup-payments.component.html'
})
export class CatchupPaymentsComponent implements OnInit {
  addPaymentButton: string;
  canManageGroup: boolean; // is Admin or Group Host
  catchup: ICatchup;
  catchupSubscription: Subscription;
  columns: IColumn[] = [
    { label: 'Name', field: 'displayName', class: 'data-table__column-nowrap', placeholder: 'No-one is attending yet...' },
    { label: 'Notes', field: 'notes' }
  ];
  config: IDataTableConfig = {
    onClick: {
      color: 'danger',
      icon: 'remove-circle-outline',
      title: 'Delete'
    }
  };
  CONSTANTS: any;
  paymentMap: Record<string, IPayment> = {};
  paymentValues: IPayment[] = [];
  selectedMember: IValueWithId = { uid: '', name: '' };
  selectedGuest: IValueWithId = { uid: '', name: '' };

  constructor(
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private catchupService: CatchupService,
    private constantsService: ConstantsService,
    private groupService: GroupService,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private userService: UserService
  ) {}

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.catchupSubscription);
  }

  ngOnInit() {
    const uid = this.route.snapshot.parent.paramMap.get('catchupId');
    this.CONSTANTS = this.constantsService.constants.CATCHUPS;

    // Ideally call eventTrack on ionViewWillEnter, but then have a race condition with loading CatchUp data
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_VIEW_PAYMENTS, uid);

    this.catchupSubscription = this.catchupService
      .getCatchup(uid)
      .pipe(
        first(x => !!x),
        switchMap((catchup: ICatchup) => {
          this.catchup = catchup;
          this.addPaymentButton = this.catchup.guestCount > 0 ? `Add payment for member` : `Add payment`;
          return this.groupService.canManageGroup$(catchup.groupId);
        }),
        switchMap((canManageGroup: boolean) => {
          this.canManageGroup = canManageGroup;
          return this.catchupService.getCatchupPayments(this.catchup.uid);
        })
      )
      .subscribe((payments: Record<string, IPayment>) => {
        if (payments == null) return;
        this.paymentMap = payments;
        this.paymentValues = Object.entries(payments)
          .map(([uid, payment]) => Object.assign({}, payment, { uid }))
          .sort( (a,b) =>  b.created - a.created );
      });
    this.subscriptionService.add(this.catchupSubscription);
  }

  onAddPayment(item: any) {
    const now = Date.now();
    //this.selectedMember = { uid: item.key, name: item.value }; // chirpy-select-items needs this, but we don't use it in the component
    this.paymentValues.push({ created: now, displayName: item.value, notes: '', uid: item.key }); // update UI
    this.paymentMap[item.key] = { created: now, displayName: item.value, notes: '' }; // keep map in sync with UI for when we update

    // Save to DB, because hosts may not want to add a note
    this.catchupService.updateCatchupPayments(this.catchup.uid, this.paymentMap);
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_ADD_PAYMENT, this.catchup.uid, {}, this.paymentValues.length);
  }

  onClick({ data: payment, row: index, isIcon }) {
    if (isIcon) {
      this.onDelete(payment, index);
    } else {
      this.onUpdate(payment, index);
    }
  }

  async onDelete(payment: IPayment, index: number) {
    const alert = await this.alertController.create({
      header: `Delete payment`,
      message: `Are you sure? This cannot be undone.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: `Delete`,
          handler: data => this.deletePaymentHandler(payment, index)
        }
      ]
    });

    await alert.present();
  }

  async onUpdate(payment: IPayment, index: number) {
    const alert = await this.alertController.create({
      header: `Edit payment details`,
      inputs: [
        {
          name: 'notes',
          type: 'text',
          value: payment.notes || '',
          placeholder: `Enter notes (optional)`
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: `Save`,
          handler: data => this.updatePaymentHandler(data, payment, index)
        }
      ]
    });

    await alert.present();
  }

  searchAttendees(startsWith: string): Observable<Record<string, string>> {
    // TODO: Is there a more efficient way to do this? Though getUsers already caches member profiles
    return this.userService.getUsers(Object.keys(this.catchup.attendees)).pipe(
      map(members => {
        const attendees = startsWith ? members.filter(x => x.searchName.startsWith(startsWith.toLowerCase())) : members;
        let output = {};
        for (const attendee of attendees) {
          output[attendee.uid] = attendee.displayName;
        }
        return output;
      })
    );
  }

  searchGuests(startsWith: string) {
    return this.catchupService.getCatchupGuests(this.catchup.uid).pipe(
      map(guests => {
        const matchingGuests = startsWith ? guests.filter(x => x.name.startsWith(startsWith.toLowerCase())) : guests;
        let output = {};
        const guestCount = matchingGuests.length;
        const paymentCount = this.paymentValues.length;
        for (let i = 0; i < guestCount; i++) {
          output[`guest_${i + paymentCount}`] = matchingGuests[i].name; // guests don't have uid, use guest_X where X is greater than the existing number of payments
        }
        return output;
      })
    );
  }

  private deletePaymentHandler(payment: IPayment, index: number) {
    // Update UI immediately
    this.paymentValues.splice(index, 1);
    // Update database
    delete this.paymentMap[payment.uid];

    const merge = false; // to delete item from map
    this.catchupService.updateCatchupPayments(this.catchup.uid, this.paymentMap, merge);
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_DELETE_PAYMENT, this.catchup.uid, {}, this.paymentValues.length);
  }

  private updatePaymentHandler(data: any, oldPayment: IPayment, index: number) {
    // Check for changes
    let changed = false;
    const keys = Object.keys(data);
    for (let key of keys) {
      changed = changed || data[key].trim() !== oldPayment[key].trim();
    }

    // Don't write to database if nothing has changed
    if (!changed) {
      return;
    } else {
      const newPayment = Object.assign(oldPayment, data); // displayName, uid are not passed from the form field
      this.paymentValues[index] = newPayment;
      this.paymentMap[oldPayment.uid] = newPayment;
      delete this.paymentMap[oldPayment.uid].uid; // don't need to store uid on the value since it's used as the key

      this.catchupService.updateCatchupPayments(this.catchup.uid, this.paymentMap);
      this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_UPDATE_PAYMENT, this.catchup.uid, {}, this.paymentValues.length);
    }
  }
}
