import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ConstantsService } from '@shared/services/constants.service';
import { UserService } from '@shared/services/user/user.service';
import { first } from 'rxjs/operators';
import { IGroup } from '@shared/models/groups/group';
import { GroupService } from '@shared/services/groups/group.service';

@Component({
  selector: 'group-locator-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['pin.component.scss']
})
export class GroupLocatorPinComponent {
  get ASSETS() {
    return this.constantsService.constants.ASSETS;
  }

  @Input() count: number;
  @Input() groups: IGroup[];
  @Input() placeName: string;

  constructor(private groupService: GroupService, private constantsService: ConstantsService, private modalController: ModalController, private router: Router, private userService: UserService) {}

  countHosts(hosts: Record<string, string>) {
    return Object.values(hosts || {}).length;
  }

  getHosts(hosts: Record<string, string>) {
    return Object.values(hosts || {}).join(', ');
  }

  isGroupMember(groupId: string) {
    return this.groupService.isGroupMember(groupId);
  }

  onClose() {
    this.modalController.dismiss();
  }

  openGroupPage(group: IGroup) {
    this.modalController.dismiss(); // close map pin modal before navigating to a different page.
    this.router.navigate([`/groups/${group.uid}`]);
  }
}
