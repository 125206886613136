import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChirpyMemberSearchComponent } from '@shared/components/chirpy-member-search/chirpy-member-search.component';
import { IMemberThread, IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { IMemberThreadType } from '@shared/models/messages/member-thread-type';
import { MessageService } from '@shared/services/messages/message.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from 'rxjs';
import { ThreadMembersService } from './thread-members.service';

@Component({
  selector: 'thread-members',
  templateUrl: './thread-members.component.html',
  styleUrls: ['./thread-members.component.scss']
})
export class ThreadMembersComponent implements OnInit, OnDestroy {
  @ViewChild(ChirpyMemberSearchComponent, { static: true }) memberSearchComponent: ChirpyMemberSearchComponent;
  threadType: IMemberThreadRelatedType = IMemberThreadRelatedType.Member;
  memberThread: IMemberThread;
  sub: Subscription;

  constructor(private route: ActivatedRoute, private service: ThreadMembersService, private messageService: MessageService, private subscriptionService: SubscriptionService) {}

  get membersToAdd() {
    return this.service.filterMembersToAdd(this.memberSearchComponent.searchResults);
  }

  get membersToRemove() {
    // only group owner can remove members from group.
    if (this.memberThread != null && this.memberThread.type === IMemberThreadType.Group && this.memberThread.isOwner) {
      return this.service.filterMembersToRemove(this.memberSearchComponent.searchResults);
    }

    return {};
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const threadId = params.get('id');
      if (threadId == null) {
        return;
      }

      this.subscriptionService.clearSubscription(this.sub);
      this.sub = this.messageService.getMemberThread(threadId).subscribe(memberThread => {
        if (memberThread == null) {
          return;
        }
        this.memberThread = memberThread;
        this.service.getMemberNamesInMemberThread(memberThread);
      });
      this.subscriptionService.add(this.sub);
    });
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.sub);
  }

  ionViewWillEnter() {
    // ngOnInit doesn't trigger if you come back from a navigation stack, ionViewWillEnter / ionViewDidEnter will.
    this.memberSearchComponent.clearSearch();
    this.memberSearchComponent.focusSearchBar();
  }

  onAddMemberToThread({ memberId, memberName }) {
    this.service.addMemberToThread(this.memberThread, memberId, memberName);
  }

  onRemoveMemberFromThread({ memberId, memberName }) {
    const hasRemovedMyself = this.messageService.user.uid === memberId;
    if (hasRemovedMyself) {
      this.service.leaveConversation(this.memberThread);
    } else {
      this.service.removeMemberFromThread(this.memberThread, memberId, memberName);
    }
  }
}
