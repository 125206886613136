import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { IMedia } from '@shared/models/media/media';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { GroupService } from '@shared/services/groups/group.service';
import { PhotoService } from '@shared/services/photos/photo.service';
import { merge, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-group-photo-collection',
  templateUrl: './group-photo-collection.page.html'
})
export class GroupPhotoCollectionPage implements OnInit {
  canReviewPhotos$: Observable<boolean>;
  canUploadPhotos$: Observable<boolean>;
  canViewPhotos$: Observable<boolean>;
  collectionId: string;
  collectionTitle: string;
  collectionType: MediaCollectionType;
  routerLinkPrefix: string = '/groups/photos';

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private groupService: GroupService, private photoService: PhotoService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.collectionId = this.route.snapshot.paramMap.get('id');
    this.collectionTitle = this.route.snapshot.paramMap.get('title');
    if (this.collectionTitle) this.collectionTitle = this.collectionTitle.replace(/(%20|\+)/g, ' ');
    this.collectionType = MediaCollectionType.GROUP;

    // This is to determine whether or not to show hidden photos in the collection
    this.canReviewPhotos$ = this.authService.isAdmin$();

    // For the moment each group has one collection. If we allow multiple collections, needs to use different way of specifying who has admin privileges
    this.canUploadPhotos$ = this.groupService.canManageGroup$(this.collectionId);

    this.canViewPhotos$ = merge(this.groupService.isGroupMember$(this.collectionId), this.groupService.canManageGroup$(this.collectionId)).pipe(
      filter(permission => !!permission) // only emit if true, i.e. take OR of input observables
    );

    this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_VIEW_COLLECTION, this.collectionId, { type: this.collectionTitle });
  }
}
