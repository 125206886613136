import { Injectable } from '@angular/core';
import { Country } from '@shared/constants/country';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { Observable } from 'rxjs';
import { INews } from '../models/news';

@Injectable({
  providedIn: 'root'
})
export class NewsDatabase extends BaseDatabase {
  FETCH_ALL_RECORDS = 9999;

  createNewsItem(news: INews): Promise<any> {
    return this.createDocument(this.COLLECTION.NEWS, news);
  }

  deleteNewsItem(uid): Promise<any> {
    return this.deleteDocument(this.COLLECTION.NEWS, uid);
  }

  getNews(country: Country | null): Observable<INews[]> {
    const whereConditions: IWhereCondition[] = [];
    if (country) {
      whereConditions.push({
        field: 'country',
        operator: 'array-contains',
        value: country as string
      });
    }
    const orderConditions: IOrderCondition[] = [
      {
        field: 'dateTimeLastUpdated',
        direction: 'desc'
      }
    ];

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, this.FETCH_ALL_RECORDS);
    return this.getDocumentsByQuery<INews>(this.COLLECTION.NEWS, queryFn);
  }

  getNewsItem(uid: string): Observable<INews> {
    return this.getDocument<INews>(this.COLLECTION.NEWS, uid);
  }

  getNewsLastUpdated(): Observable<Record<string, number>> {
    return this.getDocument<Record<string, number>>(this.COLLECTION.APP, 'news');
  }

  updateNewsItem(news: INews, merge: boolean = true): Promise<void> {
    return this.updateDocument(this.COLLECTION.NEWS, news.uid, news, merge);
  }

  updateNewsLastRead(uid: string, data: any) {
    // TODO: Move this to RTDB as it is updated frequently?
    return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS_PRIVATE, uid, data);
  }

  updateNewsLastUpdated(data: any) {
    return this.updateDocument(this.COLLECTION.APP, 'news', data);
  }
}
