import { Injectable } from '@angular/core';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { IAppMenuItem } from '../../models/app-menu-item';
import { AbstractMenuService } from '../abstract-menu.service';

@Injectable({
  providedIn: 'root'
})
export class FriendsMenuService extends AbstractMenuService {
  constructor(private authService: AuthService) {
    super();
  }

  getMenuItems(user: UserObject): IAppMenuItem[] {
    return [
      {
        title: 'Admin menu',
        url: '/admin',
        canShow: this.authService.isAdmin$()
      },
      {
        title: 'Home',
        url: '/home'
      },
      {
        title: 'Central',
        url: '/central'
      },
      {
        title: 'My Messages',
        url: '/messages'
      },
      {
        title: 'Coins',
        url: '/coins'
      },
      {
        title: 'My Profile',
        url: '/members/me'
      },
      {
        title: 'CatchUps',
        url: '/catchups'
      },
      {
        title: 'Groups',
        url: '/groups'
      },
      {
        title: 'Find Friends',
        url: '/meeting-place'
      },
      {
        title: 'Travel',
        url: '/travel' // redirected in only-friends-routing.module
      },
      {
        title: 'Help',
        children: [
          {
            title: 'Help Documents',
            url: 'https://only-friends.helpscoutdocs.com/',
            external: true,
            fullExternalUrl: true
          },
          {
            title: 'Contact Us',
            url: '/contact-us'
          },
          {
            title: 'Legal Policies',
            children: [
              {
                title: 'Code of Conduct',
                url: 'https://only-friends.helpscoutdocs.com/article/471-code-of-conduct',
                external: true,
                fullExternalUrl: true
              },
              {
                title: 'T&Cs',
                url: 'https://only-friends.helpscoutdocs.com/article/472-terms-conditions',
                external: true,
                fullExternalUrl: true
              },
              {
                title: 'Membership T&Cs',
                url: 'https://only-friends.helpscoutdocs.com/article/462-membership-terms-conditions',
                external: true,
                fullExternalUrl: true
              },
              {
                title: 'Privacy',
                url: 'https://only-friends.helpscoutdocs.com/article/461-privacy-policy',
                external: true,
                fullExternalUrl: true
              },
              {
                title: 'Acceptable Use',
                url: 'https://only-friends.helpscoutdocs.com/article/460-acceptable-use-policy',
                external: true,
                fullExternalUrl: true
              }
            ]
          }
        ]
      },
      {
        title: 'Logout',
        url: '/auth/logout'
      }
    ];
  }
}
