import { Injectable } from '@angular/core';
import { BaseDatabase } from '@shared/services/base.database';
import { Observable } from 'rxjs';
import { IPage } from '../models/page';

@Injectable({
  providedIn: 'root'
})
export class ContentPageDatabase extends BaseDatabase {
  createPage(uid: string, title: string) {
    // use update rather than create because the former lets us set the uid
    return this.updateDocument(this.COLLECTION.CONTENT_PAGES, uid, { title: title, data: { blocks: [] } });
  }

  deletePage(uid: string) {
    return this.deleteDocument(this.COLLECTION.CONTENT_PAGES, uid);
  }

  getPage(uid: string): Observable<IPage> {
    return this.getDocument<IPage>(this.COLLECTION.CONTENT_PAGES, uid);
  }

  strictUpdatePage(uid: string, data: IPage) {
    return this.strictUpdateDocument(this.COLLECTION.CONTENT_PAGES, uid, data);
  }

  updatePage(uid: string, data: any) {
    return this.updateDocument(this.COLLECTION.CONTENT_PAGES, uid, data);
  }
}
