import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICatchup } from '@shared/models/catchups/catchup';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { ConstantsService } from '@shared/services/constants.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'catchup-description',
  templateUrl: './catchup-description.component.html',
  styleUrls: ['./catchup-description.component.scss']
})
export class CatchupDescriptionComponent implements OnInit {
  catchup$: Observable<ICatchup>;
  CONSTANTS: any;
  user$: Observable<UserObject>;

  constructor(private authService: AuthService, private catchupService: CatchupService, private constantsService: ConstantsService, private route: ActivatedRoute) {}

  ngOnInit() {
    const uid = this.route.snapshot.parent.paramMap.get('catchupId');
    this.catchup$ = this.catchupService.getCatchup(uid);
    this.CONSTANTS = this.constantsService.constants.CATCHUPS.DETAIL;
    this.user$ = this.authService._userProfileSubject;
  }
}
