import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { INews } from '../../models/news';

@Injectable({
  providedIn: 'root'
})
export class NewsEditPresenter {
  get news() {
    return this.form.value;
  }

  form = this.formBuilder.group({
    content: ['', Validators.required],
    country: [[], Validators.required],
    photos: [{}],
    title: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}

  setValue(news: INews) {
    this.form.setValue({
      content: news.content || '',
      country: news.country,
      photos: Object.assign({}, news.photos), // we want a copy, not a reference, so we can compare old vs new to see if images have been removed
      title: news.title || ''
    });
  }
}
