import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UIService } from '@shared/services/ui.service';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, skipWhile, tap } from 'rxjs/operators';
import { GameObject } from '../models/game-object';
import { LeaderboardObject } from '../models/leaderboard-object';
import { NumberObject } from '../models/number-object';
import { PlayerObject } from '../models/player-object';
import { RoundObject } from '../models/round-object';
import { GameService } from '../services/game-service';
import { BingoGameOverComponent } from './components';
import { GameStatus } from './game-status';

@Component({
  selector: 'app-bingo-game',
  templateUrl: 'game.page.html',
  styleUrls: ['game.page.scss']
})
export class GamePage implements OnInit, OnDestroy {
  gameSubscription: Subscription;
  isAwaitingBingo: boolean = false;
  loadedGame: Observable<GameObject>;
  loadedPlayers: Observable<PlayerObject[]>;
  private loadedPlayersSubject: BehaviorSubject<PlayerObject[]> = new BehaviorSubject([]);
  private loadedGameSubject: BehaviorSubject<GameObject> = new BehaviorSubject(null);
  loadedNumbers: Observable<NumberObject[]>;
  loadedRounds: Observable<RoundObject[]>;
  playersSubscription: Subscription;
  userObject: UserObject = null;
  readonly GameStatus = GameStatus;

  slideOpts = {
    initialSlide: 0,
    slidesPerView: 1,
    slidesPerColumn: 1,
    spaceBetween: 0,
    effect: 'slide'
  };

  constructor(
    public gameService: GameService,
    public platform: Platform,
    private route: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore,
    public authService: AuthService,
    private modalController: ModalController,
    public alertController: AlertController,
    public loadingController: LoadingController,
    private analyticsService: AnalyticsService,
    private subscriptionService: SubscriptionService,
    private uiService: UIService
  ) {}

  async bingo(game: GameObject) {
    this.isAwaitingBingo = true; // Update UI immediately

    await this.gameService.bingo(game).catch(async err => {
      this.isAwaitingBingo = false;
      if (err.code === 'out-of-range') {
        const alert = await this.alertController.create({
          header: 'Bingo Game',
          message: err.message,
          buttons: ['OK']
        });
        await alert.present();

        // Navigate to main page anyway
        this.router.navigate(['games/bingo']);
      }
    });

    this.isAwaitingBingo = false; // unset, and use value returned from gameService
  }

  canLeaveGame(game: GameObject, round: RoundObject) {
    return game.currentRound == round.index && game.status != GameStatus.FINISHED && game.status != GameStatus.ENDING;
  }

  filterPlayer = (playerObject: PlayerObject) => {
    if (this.userObject !== undefined && this.userObject !== null) {
      return playerObject.playerId !== this.userObject.uid;
    }
    return false;
  };

  getRoundColor(num: number, round: RoundObject) {
    return num < 0 ? 'light' : this.isMarked(num, round) ? 'secondary' : 'primary';
  }

  getWinner(data: any) {
    let winnerName: string = null;

    if (data.docs.length > 0) {
      const allPlayers = this.loadedPlayersSubject.value;
      const winners = data.docs.map(el => {
        const filteredPlayers = allPlayers.filter(loadedPlayer => {
          return el.data().playerId === loadedPlayer.playerId;
        });
        return filteredPlayers != null && filteredPlayers.length > 0 ? filteredPlayers[0].playerName : 'Unknown Player';
      });

      if (winners.length > 0) {
        winnerName = winners.join(',');
      }
    }

    return winnerName;
  }

  isPlayingOrWaiting(game: GameObject, round: RoundObject) {
    return game.currentRound == round.index && game.status != GameStatus.CANCELLED && game.status != GameStatus.FINISHED;
  }

  isMarked(num: number, round: RoundObject) {
    if (round.marked !== undefined && round.marked != null && round.marked.indexOf(num) >= 0) {
      return true;
    }
    return false;
  }

  isRoundDisabled(num: number, game: GameObject, round: RoundObject) {
    return num <= 0 || game.currentRound != round.index || game.status != GameStatus.PLAYING;
  }

  isWinner(data: any): boolean {
    let isWinner: boolean = false;

    if (data.docs.length > 0) {
      const allPlayers = this.loadedPlayersSubject.value;

      data.docs.map(el => {
        allPlayers.map(loadedPlayer => {
          if (el.data().playerId === this.userObject.uid) {
            isWinner = true;
          }
        });
      });
    }
    return isWinner;
  }

  async leaveGame(game: GameObject) {
    // Stop subscribers
    this.gameSubscription.unsubscribe();
    this.uiService.showLoadingOverlay();
    return this.gameService
      .leaveGame(game)
      .then(async left => {
        this.uiService.hideLoadingOverlay();
        const alert = await this.alertController.create({
          header: 'Bingo Game',
          subHeader: 'Error',
          message: 'You have decided to leave this game. Please select a new game or another option from the menu',
          buttons: ['OK']
        });
        await alert.present();
        // Send analytics event
        const duration = Math.round((Date.now() - game.started * 1000) / 1000); // TODO: use milliseconds for game times
        this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_LEFT, null, {}, duration);
        this.router.navigate(['games/bingo']);
      })
      .catch(async err => {
        this.uiService.hideLoadingOverlay();
        const alert = await this.alertController.create({
          header: 'Bingo Game',
          subHeader: 'Error',
          message: err.message,
          buttons: ['OK']
        });
        await alert.present();
        // Navigate to main page anyway
        this.router.navigate(['games/bingo']);
      });
  }

  lineTrackBy(index: number, item: any) {
    return item.uid;
  }

  mapCardLine(line) {
    const tmp: Array<[string, any]> = Object.entries(line).sort((a, b) => {
      return a[0] < b[0] ? -1 : 1;
    });
    return tmp;
  }

  async mark(num: number, round: RoundObject, game: GameObject) {
    await this.gameService.markNumber(game.uid, num, round.uid);
  }

  markNumber(num: number, round: RoundObject, game: GameObject) {
    if (num > 0) {
      if (!round.marked) round.marked = [];
      round.marked.push(num); // update status in UI immediately
      this.mark(num, round, game);
    }
  }

  ngOnInit() {
    this.loadedGame = this.loadedGameSubject.asObservable();
    this.loadedPlayers = this.loadedPlayersSubject.asObservable();

    this.route.paramMap.subscribe(params => {
      const param = params.get('gameId');
      if (param == null) {
        this.loadedGame = of(new GameObject());
      } else {
        this.loadGame(param);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.gameSubscription);
    this.subscriptionService.clearSubscription(this.playersSubscription);
  }

  numberTrackBy(index: number, item: any) {
    return item[0];
  }

  async presentGameOverModal() {
    const game = this.afs.doc<GameObject>('games/bingo/games/' + this.loadedGameSubject.value.uid);
    const leaderboard = game.collection<LeaderboardObject>('Leaderboard', ref => ref.where('isWinner', '==', true));

    return leaderboard
      .get()
      .toPromise()
      .then(async data => {
        const winnerName = this.getWinner(data);
        const isWinner = this.isWinner(data);
        return await this.presentGameOverModalInternal(this.loadedGameSubject.value, winnerName, isWinner);
      })
      .catch(async err => {
        return await this.presentGameOverModalInternal(this.loadedGameSubject.value, null);
      });
  }

  private loadGame(gameId) {
    const game = this.afs.doc<GameObject>('games/bingo/games/' + gameId);

    this.authService.userProfileObservable.subscribe(userProfile => {
      if (userProfile !== undefined && userProfile !== null) {
        this.userObject = userProfile;
        this.subscriptionService.clearSubscription(this.gameSubscription);
        this.gameSubscription = this.gameService.games
          .pipe(
            skipWhile(gs => !gs),
            map(gs => gs.filter(g => g.uid === gameId)[0])
          )
          .subscribe(async g => {
            if (g == null) return;
            if (g.players != null && g.players.indexOf(this.userObject.uid) > -1) {
              if (this.loadedGameSubject.value == null || this.loadedGameSubject.value.status !== GameStatus.FINISHED) {
                if (g.status === GameStatus.FINISHED) {
                  this.presentGameOverModal();
                }
              } else if (this.loadedGameSubject.value.status === GameStatus.FINISHED) {
                const alert = await this.alertController.create({
                  header: 'Bingo Game',
                  subHeader: this.userObject.firstName,
                  message: 'Thank you for playing',
                  buttons: ['OK']
                });

                alert.present().then(done => {
                  this.router.navigate(['games/bingo']);
                });
              }
            }
            this.loadedGameSubject.next(g);
          });
        this.subscriptionService.add(this.gameSubscription);
        const rounds = game.collection<RoundObject>('Rounds', ref => ref.where('playerId', '==', userProfile.uid));
        const numbers = game.collection<NumberObject>('Numbers', ref => ref.orderBy('timestamp', 'desc'));
        const allPlayers = game.collection<PlayerObject>('Players', ref => ref.where('gameId', '==', gameId));

        this.loadedNumbers = numbers.valueChanges();

        this.subscriptionService.clearSubscription(this.playersSubscription);
        this.playersSubscription = allPlayers
          .snapshotChanges()
          .pipe(
            map(actions =>
              actions.map(a => {
                const newRound: PlayerObject = new PlayerObject().deserialize(a.payload.doc.data());
                newRound.uid = a.payload.doc.id;
                return newRound;
              })
            )
          )
          .subscribe(async p => {
            this.loadedPlayersSubject.next(p);
          });
        this.subscriptionService.add(this.playersSubscription);

        this.loadedRounds = rounds.snapshotChanges().pipe(
          map(actions =>
            actions.map(a => {
              const newRound: RoundObject = new RoundObject().deserialize(a.payload.doc.data());
              newRound.uid = a.payload.doc.id;
              return newRound;
            })
          ),
          tap((results: RoundObject[]) => {
            return results.sort((x: RoundObject, y: RoundObject) => {
              return x.index === this.loadedGameSubject.value.currentRound ? -1 : y.index === this.loadedGameSubject.value.currentRound ? 1 : 0;
            });
          })
        );
      }
    });
  }

  private async presentGameOverModalInternal(game: GameObject, winnerName: string, isWinner: boolean = false) {
    const modal = await this.modalController.create({
      component: BingoGameOverComponent,
      cssClass: 'gameOverModal',
      componentProps: {
        winnerName,
        isWinner
      }
    });

    // Send analytics event
    const duration = Math.round((Date.now() - game.started * 1000) / 1000); // TODO: use milliseconds for game times
    this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_GAME_OVER, null, { type: isWinner }, duration);

    return await modal.present();
  }
}
