import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IAdvancedNotification } from '@shared/models/notifications/advanced-notification';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdvancedNotificationsPresenter {
  get settings() {
    return this.form.value;
  }

  form = this.formBuilder.group({});
  subscription: Subscription;

  constructor(private formBuilder: FormBuilder, private subscriptionService: SubscriptionService) {}

  init(settingsData: IAdvancedNotification) {
    // Remove existing controls
    this.form.removeControl('default');
    this.form.removeControl('items');

    // Dynamically add settings toggles
    this.form.addControl('default', new FormControl(settingsData.default, [Validators.requiredTrue]));
    this.form.addControl('items', new FormGroup({}));
    const itemsGroup = this.form.controls['items'] as FormGroup;
    for (let [id, value] of Object.entries(settingsData.items)) {
      itemsGroup.addControl(id, new FormControl(value, [Validators.requiredTrue]));
    }

    this.subscriptionService.clearSubscription(this.subscription);
    this.subscription = this.form.get('default').valueChanges.subscribe(value => {
      const items = this.form.get('items') as FormGroup;
      const newValues = Object.keys(items.controls).reduce((acc, current) => {
        acc[current] = value;
        return acc;
      }, {});
      items.setValue(newValues);
    });
    this.subscriptionService.add(this.subscription);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.subscription);
  }
}
