import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordPresenter {
  form = this.formBuilder.group({
    email: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}

  resetPassword() {
    return this.form.value;
  }

  setValue(email: string) {
    this.form.setValue({
      email: email
    });
  }
}
