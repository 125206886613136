import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData, QuerySnapshot } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MessageType } from '@shared/constants/message-type';
import { IMemberThread } from '@shared/models/messages/member-thread';
import { IMessage } from '@shared/models/messages/message';
import { IMessageOptions } from '@shared/models/messages/message-options';
import { IThread } from '@shared/models/messages/thread';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { CacheService } from '@shared/services/cache.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessageDatabase extends BaseDatabase {
  constructor(afs: AngularFirestore, cache: CacheService, private storage: AngularFireStorage) {
    super(afs, cache);
  }

  createHeldMessage(threadId: string, message: IMessage, threadName: string) {
    // Need to save threadId so we can put the message in the right collection if it is released
    // Save threadName so we can show this in the admin approve-messages component
    Object.assign(message, { threadId, threadName });
    return this.createDocument(this.COLLECTION.HELD_MESSAGES, message);
  }

  createMemberThread(memberThread: IMemberThread): Promise<any> {
    const queryFn = ref => ref.where('threadId', '==', memberThread.threadId).where('memberId', '==', memberThread.memberId);
    const collection = this.afs.collection(this.COLLECTION.MESSAGES_MEMBER_THREADS, queryFn);

    return collection
      .get()
      .toPromise()
      .then((response: QuerySnapshot<DocumentData>) => this.createOrUpdateMemberThread(response, memberThread))
      .catch(err => {
        throw err;
      });
  }

  createMessage(threadId: string, message: IMessage) {
    return this.createSubDocument(this.COLLECTION.MESSAGES_THREADS, threadId, this.COLLECTION.MESSAGES, message);
  }

  // TODO: Why is there a type error if I put this logic directly in the then callback of createMemberThread?
  createOrUpdateMemberThread(response: QuerySnapshot<DocumentData>, memberThread: IMemberThread): Promise<any> {
    if (response.size > 0) {
      return this.updateDocument(this.COLLECTION.MESSAGES_MEMBER_THREADS, response.docs[0].id, memberThread, true); // memberThread already exists
    } else {
      return this.createDocument(this.COLLECTION.MESSAGES_MEMBER_THREADS, memberThread);
    }
  }

  createThread(thread: IThread) {
    return this.createDocument(this.COLLECTION.MESSAGES_THREADS, thread);
  }

  deleteHeldMessage(uid: string) {
    return this.deleteDocument(this.COLLECTION.HELD_MESSAGES, uid);
  }

  deleteMemberThread(threadId: string, memberId: string) {
    const queryFn = ref => ref.where('threadId', '==', threadId).where('memberId', '==', memberId);
    const collection = this.afs.collection(this.COLLECTION.MESSAGES_MEMBER_THREADS, queryFn);

    return collection
      .get()
      .toPromise()
      .then(response => {
        response.docs.forEach(doc => {
          return this.deleteDocument(this.COLLECTION.MESSAGES_MEMBER_THREADS, doc.id);
        });
      });
  }

  deleteMessage(threadId: string, messageId: string) {
    return this.deleteDocument(`${this.COLLECTION.MESSAGES_THREADS}/${threadId}/${this.COLLECTION.MESSAGES}`, messageId);
  }

  getAllMessages(search: IMessageOptions, limit: number, lastTimestamp: number, messageTypes: MessageType[]): Observable<IMessage[]> {
    const queryFn = this.getAllMessagesQuery(search, limit, lastTimestamp, messageTypes);
    return this.getDocumentsByCollectionGroupQuery<IMessage>(this.COLLECTION.MESSAGES, queryFn, 'threadId');
  }

  getHeldMessagesForApproval(): Observable<IMessage[]> {
    // NB This is overriden by the date ordering on the ApproveMessagesComponent
    const queryFn = ref => ref.orderBy('dateTimeSent', 'asc');

    return this.getDocumentsByQuery<IMessage>(this.COLLECTION.HELD_MESSAGES, queryFn);
  }

  getLastMessage(threadId: string) {
    const maxRecordsToFetch = 1;
    const queryFn = ref => ref.orderBy('dateTimeLastUpdated', 'desc').limit(maxRecordsToFetch);

    return this.getDocumentsBySubcollectionQuery<IMessage>(this.COLLECTION.MESSAGES_THREADS, threadId, this.COLLECTION.MESSAGES, queryFn);
  }

  getMemberThreads() {
    return this.afs.collection(this.COLLECTION.MESSAGES_MEMBER_THREADS);
  }

  getMemberThreadById(threadId: string, memberId: string) {
    const queryFn = ref => ref.where('memberId', '==', memberId).where('threadId', '==', threadId);
    return this.getDocumentsByQuery<IMemberThread>(this.COLLECTION.MESSAGES_MEMBER_THREADS, queryFn).pipe(
      map(documents => documents[0]) // should only return one document
    );
  }

  getMemberThreadsByDate(date: number) {
    const queryFn = ref => ref.where('isOwner', '==', false).where('dateTimeLastUpdated', '>=', date);
    return this.getDocumentsByQuery<IMemberThread>(this.COLLECTION.MESSAGES_MEMBER_THREADS, queryFn);
  }

  getMemberThreadsByIds(uids: string[][]) {
    const outputs$: Array<Observable<IMemberThread[]>> = [];
    const queryFns: any[] = [];

    //Using both memberId and threadId results in fewer reads than if we used createChunkedQueryFunctions with threadId in [...] because there are at least 2 memberThreads per thread
    for (const [memberId, threadId] of uids) {
      const queryFn = ref => ref.where('memberId', '==', memberId).where('threadId', '==', threadId);
      queryFns.push(queryFn);
    }
    if (queryFns.length > 0) {
      //Combine results from multiple database queries
      const newMemberThreads = this.getDocumentsByMultipleQueries<IMemberThread>(this.COLLECTION.MESSAGES_MEMBER_THREADS, queryFns);
      outputs$.push(newMemberThreads);
    }

    return combineLatest(...outputs$).pipe(
      map(arrays => Array.prototype.concat(...arrays)) //TODO use arrays.flat() when supported
    );
  }

  getMemberThreadsForMember(memberId: string, isArchived: boolean = false) {
    const queryFn = ref =>
      ref
        .where('memberId', '==', memberId)
        .where('isArchived', '==', isArchived)
        .orderBy('dateTimeLastUpdated', 'desc');

    return this.getDocumentsByQuery<IMemberThread>(this.COLLECTION.MESSAGES_MEMBER_THREADS, queryFn);
  }

  getMemberThreadsForThread(threadId: string) {
    const queryFn = ref => ref.where('threadId', '==', threadId);

    return this.afs.collection(this.COLLECTION.MESSAGES_MEMBER_THREADS, queryFn);
  }

  getMessages(threadId: string, loadPastMessages: boolean, dateStart: number, maxRecordsToFetch: number) {
    let queryFn;
    if (loadPastMessages === true) {
      queryFn = ref =>
        ref
          .orderBy('dateTimeSent', 'desc')
          .where('dateTimeSent', '<', dateStart)
          .limit(maxRecordsToFetch);
    } else {
      queryFn = ref => ref.orderBy('dateTimeLastUpdated', 'desc').where('dateTimeLastUpdated', '>', dateStart);
    }

    return this.getDocumentsBySubcollectionQuery<IMessage>(this.COLLECTION.MESSAGES_THREADS, threadId, this.COLLECTION.MESSAGES, queryFn);
  }

  getReplies(threadId: string, messageId: string): Observable<IMessage[]> {
    // TODO: Limit number of replies returned?
    const queryFn = ref => ref.orderBy('dateTimeLastUpdated', 'asc');

    return this.getDocumentsBySubcollectionQuery<IMessage>(this.COLLECTION.MESSAGES_THREADS, `${threadId}/Messages/${messageId}`, this.COLLECTION.MESSAGES, queryFn);
  }

  getThread(threadId: string) {
    return this.getDocument<IThread>(this.COLLECTION.MESSAGES_THREADS, threadId, false);
  }

  hasHeldMessages(memberId: string): Promise<boolean> {
    const queryFn = ref => ref.where('senderId', '==', memberId);
    const collection = this.afs.collection(this.COLLECTION.HELD_MESSAGES, queryFn);

    return collection
      .get()
      .toPromise()
      .then((response: QuerySnapshot<DocumentData>) => !response.empty)
      .catch(err => {
        throw err;
      });
  }

  strictUpdateMessage(threadId: string, messageId: string, data: any) {
    return this.strictUpdateDocument(`${this.COLLECTION.MESSAGES_THREADS}/${threadId}/${this.COLLECTION.MESSAGES}`, messageId, data);
  }

  updateMemberThread(threadId: string, memberId: string, newData: any) {
    const queryFn = ref => ref.where('threadId', '==', threadId).where('memberId', '==', memberId);
    const collection = this.afs.collection(this.COLLECTION.MESSAGES_MEMBER_THREADS, queryFn);

    return collection
      .get()
      .toPromise()
      .then(response => {
        response.docs.forEach(doc => {
          return this.afs
            .collection(this.COLLECTION.MESSAGES_MEMBER_THREADS)
            .doc(doc.id)
            .update({ ...newData });
        });
      });
  }

  updateMemberThreads(threadId: string, newData: any) {
    const queryFn = ref => ref.where('threadId', '==', threadId);
    const collection = this.afs.collection(this.COLLECTION.MESSAGES_MEMBER_THREADS, queryFn);

    return collection
      .get()
      .toPromise()
      .then(response => {
        response.docs.forEach(doc => {
          return this.afs
            .collection(this.COLLECTION.MESSAGES_MEMBER_THREADS)
            .doc(doc.id)
            .update({ ...newData });
        });
      });
  }

  updateMessage(threadId: string, messageId: string, data: any, merge: boolean = true) {
    return this.updateDocument(`${this.COLLECTION.MESSAGES_THREADS}/${threadId}/${this.COLLECTION.MESSAGES}`, messageId, data, merge);
  }

  updateOwnMemberThread(memberThread: IMemberThread, newData: any) {
    return this.updateDocument(this.COLLECTION.MESSAGES_MEMBER_THREADS, memberThread.uid, newData);
  }

  updateThread(threadId: string, newData: {}) {
    return this.afs
      .collection(this.COLLECTION.MESSAGES_THREADS)
      .doc(threadId)
      .update({ ...newData });
  }

  async uploadImage(threadId: string, memberId: string, filename: string, file: any) {
    const filePath = `thread/${threadId}/${filename}`;
    const fileRef = this.storage.ref(filePath);
    const promise = new Promise<string>(resolve => {
      this.storage
        .upload(filePath, file)
        .snapshotChanges()
        .toPromise()
        .then(() => {
          fileRef
            .getDownloadURL()
            .toPromise()
            .then((url: string) => {
              resolve(url);
            });
        });
    });

    return promise;
  }

  private getAllMessagesQuery(search: IMessageOptions, recordsToFetch: number, lastTimestamp: number, messageTypes: MessageType[]) {
    const whereConditions: IWhereCondition[] = [];

    if (messageTypes.length > 0) {
      whereConditions.push({ field: 'type', operator: 'in', value: messageTypes });
    }

    // If we are loading more entries for the same search
    if (lastTimestamp != null) {
      // If we have already loaded results for the current search, then we always want results < lastTimestamp,
      // regardless of the search, because the results are ordered by startTime desc.
      whereConditions.push({ field: 'dateTimeSent', operator: '<', value: lastTimestamp });

      // TODO: Edge case - what if lastTimestamp is exactly equal to search.dateBefore?
      // Low probability because lastTimstamp has nanosecond precision while search.sentAfter only specifies seconds
    } else {
      if (search.sentBefore !== '') {
        const sentBefore = new Date(search.sentBefore).getTime();
        whereConditions.push({ field: 'dateTimeSent', operator: '<', value: sentBefore });
      }
    }

    if (search.sentAfter !== '') {
      const sentAfter = new Date(search.sentAfter).getTime();
      whereConditions.push({ field: 'dateTimeSent', operator: '>=', value: sentAfter });
    }

    if (search.sender !== '') {
      whereConditions.push({ field: 'senderId', operator: '==', value: search.sender });
    }

    const orderConditions: IOrderCondition[] = [];
    orderConditions.push({ field: 'dateTimeSent', direction: 'desc' });
    return this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
  }
}
