import { Injectable, OnDestroy } from '@angular/core';
import { ILastAction } from '@shared/models/last-action/last-action';
import { BaseRTDatabase } from '@shared/services/base.rtdatabase';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LastActionRTDatabase extends BaseRTDatabase {
  getLastActionBeforeDate(date: number): Observable<ILastAction[]> {
    return this.getObjectsByQuery<ILastAction>(`${this.LIST.LAST_ACTION}`, ref => ref.orderByChild('dateTime').endAt(date));
  }

  updateLastAction(memberId: string, lastAction: ILastAction): Promise<void> {
    return this.updateObject(this.LIST.LAST_ACTION, memberId, lastAction);
  }
}
