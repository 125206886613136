import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IChangePasswordResult } from '@shared/models/update-password-result';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { ToastService } from '@shared/services/toast.service';
import { combineLatest } from 'rxjs';
import { first } from 'rxjs/operators';
import { SignupStepService } from '../services/signup-step.service';
import { SignupDisplayNameService } from './services/display-name.service';
import { SignupDisplayNamePresenter } from './display-name.presenter';

@Component({
  selector: 'app-signup-display-name',
  templateUrl: './display-name.page.html',
  viewProviders: [SignupDisplayNamePresenter]
})
export class SignupDisplayNamePage implements OnInit {
  get formInvalid() {
    return this.presenter.form.invalid;
  }

  get formPending() {
    return this.presenter.form.pending;
  }

  get formValid() {
    return this.presenter.form.valid;
  }

  get setDisplayNameForm() {
    return this.presenter.form;
  }

  buttonColor: string;
  CONSTANTS: any;
  displayNameInvalidCharacters: string = '';
  displayNameTaken: string = '';
  hasInvalidCharacters: boolean = false;
  isInUse: boolean = false;
  isProcessing: boolean = false;
  progressColor: string;
  showBackButton: boolean = false;
  showNavigation: boolean = false;
  submitButtonText: string = 'Next';
  useBar: boolean;

  constructor(
    private authService: AuthService,
    private constantsService: ConstantsService,
    private groupService: GroupService,
    private presenter: SignupDisplayNamePresenter,
    public route: ActivatedRoute,
    private router: Router,
    private service: SignupDisplayNameService,
    private signupStepService: SignupStepService,
    private toastService: ToastService
  ) {}

  ionViewWillEnter() {
    if (this.authService._userProfileSubject.value) this.presenter.setFormValue(this.authService._userProfileSubject.value.displayName);
  }

  ngOnInit() {
    this.buttonColor = this.constantsService.constants.WELCOME.SIGNUP.buttonColor;
    this.CONSTANTS = this.constantsService.constants.WELCOME.SIGNUP.DISPLAY_NAME;
    this.progressColor = this.constantsService.constants.WELCOME.SIGNUP.progressColor;
    this.showNavigation = this.constantsService.constants.WELCOME.SIGNUP.showNavigation;
    this.useBar = this.constantsService.constants.WELCOME.SIGNUP.useProgressBar;
  }

  onSubmit() {
    if (!this.isProcessing) {
      this.toggleProcessing(); // prevent duplicate submissions

      // Blank form
      if (this.setDisplayNameForm.controls['displayName'].value.trim() === '') {
        this.isInUse = false;
        this.hasInvalidCharacters = false;
        this.toastService.presentToast(`Please enter a Display Name.`);
        this.toggleProcessing();
        return;
      }

      // Waiting for display name validation
      else if (this.formPending) {
        let sub = combineLatest(this.setDisplayNameForm.statusChanges, this.authService._userProfileSubject).subscribe(([status, profile]) => {
          // Make sure the profile is loaded before we attempt to modify it
          if (profile == null) return;

          // Display Name is in use
          if (this.formInvalid) {
            sub.unsubscribe();
            this.displayNameTaken = this.presenter.displayName.value;
            this.displayNameInvalidCharacters = this.presenter.displayName.getError('invalidCharacters');
            this.isInUse = this.presenter.displayName.hasError('displayNameInUse');
            this.hasInvalidCharacters = this.presenter.displayName.hasError('invalidCharacters');
            this.toggleProcessing();
            return;
          }
          // Display name is available
          else if (this.formValid) {
            sub.unsubscribe();
            this.processDisplayName();
          }
        });
      }

      // Form is already valid (call to this.presenter.setFormValue will trigger validation)
      else if (this.formValid) {
        this.processDisplayName();
      }
    }
  }

  private processDisplayName() {
    this.hasInvalidCharacters = false;
    this.isInUse = false;
    const { displayName } = this.presenter.submit();
    this.service.setDisplayName(displayName);
    this.toggleProcessing();
    const nextStep = this.signupStepService.getNextStep(this.route);
    if (nextStep) this.router.navigate(['/welcome/signup', nextStep]);
  }

  private toggleProcessing() {
    if (this.isProcessing) {
      this.isProcessing = false;
      this.submitButtonText = 'Next';
    } else {
      this.isProcessing = true;
      this.submitButtonText = 'Processing';
    }
  }
}
