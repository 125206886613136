import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'location' })
export class LocationPipe implements PipeTransform {
  transform(text: string): string {
    const parts = text.split(',');
    if (parts.length <= 1) return text;
    return `${parts[0]}<br><small>${parts.slice(1).join(',')}</small>`;
  }
}
