import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IRoomShare } from '@shared/models/trips/room-share';

@Injectable({
  providedIn: 'root'
})
export class RoomShareEditPresenter {
  get formValue() {
    return this.form.value;
  }

  form = this.formBuilder.group({
    description: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}

  setValue(roomShare: IRoomShare) {
    this.form.setValue({
      description: roomShare.description || ''
    });
  }
}
