import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventType } from '@infrastructure/constants/event-type';
import { Country } from '@shared/constants/country';
import { TripSection } from '@shared/constants/trip-section';
import { IListingImage } from '@shared/models/image/listing-image';
import { ITrip } from '@shared/models/trips/trip';
import { ISelectOption } from '@shared/models/select-option';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { GroupService } from '@shared/services/groups/group.service';
import { TripService } from '@shared/services/trips/trip.service';
import { ConstantsService } from '@shared/services/constants.service';
import { UIService } from '@shared/services/ui.service';
import { first, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { TripEditPresenter } from './trip-edit.presenter';

@Component({
  selector: 'app-trip-edit',
  styleUrls: ['./trip-edit.page.scss'],
  templateUrl: './trip-edit.page.html',
  viewProviders: [TripEditPresenter]
})
export class TripEditPage implements OnInit {
  get CONSTANTS() {
    return this.constantsService.constants.TRIPS;
  }

  get costShare() {
    return this.tripEditPresenter.form.controls.costShare;
  }

  get costSingle() {
    return this.tripEditPresenter.form.controls.costSingle;
  }

  get form() {
    return this.tripEditPresenter.form;
  }

  get isEdit() {
    return this.trip != null && this.trip.uid != null;
  }

  get sections() {
    return this.tripEditPresenter.form.controls.sections;
  }

  get title() {
    return this.isEdit ? `Update Trip` : `Create Trip`;
  }

  COUNTRIES: Record<string, Country> = {};
  interfaceOptions: any = { cssClass: 'wide-select' };
  isCreatingTrip: boolean;
  MAX_DATE: string;
  MAX_PHOTOS: number = 3;
  trip: ITrip;

  constructor(
    private authService: AuthService,
    private dateTimeService: DateTimeService,
    private groupService: GroupService,
    private tripEditPresenter: TripEditPresenter,
    private tripService: TripService,
    private constantsService: ConstantsService,
    private route: ActivatedRoute,
    private uiService: UIService
  ) {}

  getNumberOfRows() {
    return this.uiService.getNumberOfTextAreaRows();
  }

  ngOnInit() {
    this.COUNTRIES = this.constantsService.constants.APP.countries;
    this.MAX_DATE = this.dateTimeService.MAX_DATE;
    this.tripEditPresenter.init(this.CONSTANTS.SECTIONS, Object.values(this.COUNTRIES));

    const uid = this.route.snapshot.paramMap.get('tripId');
    if (!uid) {
      // Group ID/Name is needed primarily for notifications
      // TODO: What if group name changes? Probably need to have a cloud function to update all references to group name
      const groupId = this.route.snapshot.paramMap.get('groupId');
      let groupName = this.route.snapshot.paramMap.get('groupName');
      if (groupName) groupName = groupName.replace(/(%20|\+)/g, ' ');

      const sections: any = {};
      for (let section of this.CONSTANTS.SECTIONS) {
        sections[section] = '';
      }

      let costShare: any = {};
      let costSingle: any = {};
      for (const country of Object.values(this.COUNTRIES)) {
        costShare[country] = '';
        costSingle[country] = '';
      }

      const trip = {
        address: '',
        adminEmail: 'ALL',
        allGroupIds: [],
        attendees: {},
        eventType: EventType.Trip,
        costShare: costShare,
        costSingle: costSingle,
        created: 0,
        date: '',
        datetime: 0,
        endDate: '',
        groupId,
        groupName,
        interested: {},
        photos: {},
        published: false,
        sections: sections as Record<TripSection, string>,
        sharedGroups: {},
        title: '',
        uid: null
      } as ITrip;
      this.tripEditPresenter.setValue(trip);
      this.trip = trip;
      return;
    } else {
      this.tripService
        .getTrip(uid)
        .pipe(first(x => !!x))
        .subscribe((trip: ITrip) => {
          if (trip == null) return;
          this.trip = trip;
          this.tripEditPresenter.setValue(this.trip);
        });
    }
  }

  onAddGroup(item: Record<string, string>) {
    this.trip.sharedGroups = this.trip.sharedGroups || {};
    this.trip.sharedGroups[item.key] = item.value;

    const names = Object.values(this.trip.sharedGroups);
    this.tripEditPresenter.form.controls.sharedGroups.setValue(names);
  }

  onRemoveGroup(item: Record<string, string>) {
    delete this.trip.sharedGroups[item.key];
    const names = Object.values(this.trip.sharedGroups);
    this.tripEditPresenter.form.controls.sharedGroups.setValue(names);
  }

  searchGroups(startsWith: string = '') {
    const country = '';
    const isTravel = true;
    const excludeIds = [this.trip.groupId];
    return this.groupService.searchGroups(startsWith, country, excludeIds, isTravel);
  }

  updateTrip() {
    // merge form values with catchup
    const formValue = this.tripEditPresenter.trip;
    // Delete any removed images
    const oldKeys = Object.keys(this.trip.photos || {});
    let photosToRemove: IListingImage[] = [];
    if (oldKeys.length > 0) {
      const newKeys = Object.keys(formValue.photos || {});
      const keysToRemove = oldKeys.filter(x => !newKeys.includes(x));
      for (const key of keysToRemove) {
        photosToRemove.push(Object.assign({}, this.trip.photos[key]));
      }
    }
    Object.assign(this.trip, formValue);

    // merge groupId with any shared group Ids
    const sharedGroups = Object.keys(this.trip.sharedGroups || {});
    this.trip.allGroupIds = [this.trip.groupId, ...sharedGroups];

    this.tripService.updateTrip(this.trip, photosToRemove);
  }
}
