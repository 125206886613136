import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Gender } from '@shared/constants/gender';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { ToastService } from '@shared/services/toast.service';
import { SignupStepService } from '../services/signup-step.service';
import { SignupGenderService } from './services/gender.service';
import { SignupGenderPresenter } from './gender.presenter';

@Component({
  selector: 'app-signup-gender',
  templateUrl: './gender.page.html',
  viewProviders: [SignupGenderPresenter]
})
export class SignupGenderPage implements OnInit {
  get formValid() {
    return this.presenter.form.valid;
  }

  get setGenderForm() {
    return this.presenter.form;
  }

  buttonColor: string;
  CONSTANTS: any;
  GENDERS: any;
  isProcessing: boolean = false;
  progressColor: string;
  showBackButton: boolean = false;
  showNavigation: boolean = false;
  submitButtonText: string = 'Next';
  useBar: boolean;

  constructor(
    private authService: AuthService,
    private constantsService: ConstantsService,
    private presenter: SignupGenderPresenter,
    public route: ActivatedRoute,
    private router: Router,
    private service: SignupGenderService,
    private signupStepService: SignupStepService,
    private toastService: ToastService
  ) {}

  ionViewWillEnter() {
    if (this.authService._userProfileSubject.value) this.presenter.setFormValue(this.authService._userProfileSubject.value.gender);
  }

  ngOnInit() {
    this.buttonColor = this.constantsService.constants.WELCOME.SIGNUP.buttonColor;
    this.CONSTANTS = this.constantsService.constants.WELCOME.SIGNUP.GENDER;
    this.GENDERS = this.constantsService.constants.GENDERS;
    this.progressColor = this.constantsService.constants.WELCOME.SIGNUP.progressColor;
    this.showNavigation = this.constantsService.constants.WELCOME.SIGNUP.showNavigation;
    this.useBar = this.constantsService.constants.WELCOME.SIGNUP.useProgressBar;
  }

  onSubmit() {
    if (!this.formValid) {
      this.toastService.presentToast(`Please select a gender.`);
      return;
    } else {
      this.isProcessing = true;
      this.submitButtonText = 'Processing';
      const { gender } = this.presenter.submit();
      this.service.setGender(gender);
      const nextStep = this.signupStepService.getNextStep(this.route);
      this.isProcessing = false;
      this.submitButtonText = 'Next';
      if (nextStep) this.router.navigate(['/welcome/signup', nextStep]);
    }
  }
}
