import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMarketplaceListing } from '@shared/models/marketplace/marketplace-listing';
import { DateTimeService } from '@shared/services/date-time.service';
import { MarketplaceService } from '@shared/services/marketplace/marketplace.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'marketplace-box',
  styleUrls: ['./marketplace-box.component.scss'],
  templateUrl: './marketplace-box.component.html'
})
export class MarketplaceBoxComponent implements OnInit {
  latestListings$: Observable<IMarketplaceListing[]>;
  @Input() link: string;
  @Output() linkClicked = new EventEmitter();
  @Input() placeholder: string;

  constructor(private dateTimeService: DateTimeService, private marketplaceService: MarketplaceService) {}

  getCategories(categories: string[]) {
    return this.marketplaceService.getCategoriesList(categories);
  }

  getDateFormat(created: number) {
    return this.dateTimeService.getDateFormat(created);
  }

  ngOnInit() {
    this.latestListings$ = this.marketplaceService.getLatestMarketplaceListings();
  }

  onClick(title: string) {
    this.linkClicked.emit(title || 'Marketplace listing');
  }
}
