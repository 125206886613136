import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IAdvertiser } from '@shared/models/advertisers/advertiser';
import { IAdvertiserOptions } from '@shared/models/advertisers/advertiser-options';
import { ISelectOption } from '@shared/models/select-option';
import { AdvertiserService } from '@shared/services/advertisers/advertiser.service';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { ConstantsService } from '@shared/services/constants.service';
import { RegionService } from '@shared/services/regions/region.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdvertiserSearchPresenter } from './advertiser-search.presenter';

@Component({
  selector: 'advertiser-search',
  templateUrl: './advertiser-search.component.html',
  styleUrls: ['./advertiser-search.component.scss'],
  viewProviders: [AdvertiserSearchPresenter],
  encapsulation: ViewEncapsulation.None
})
export class AdvertiserSearchComponent implements OnInit {
  get searchForm() {
    return this.presenter.form;
  }

  categoryLabel: string;
  categoryPlaceholder: string;
  CONSTANTS: any;
  @Input() options: IAdvertiserOptions | null;
  REGIONS: ISelectOption[] = [];
  @Output() search = new EventEmitter<any>();
  selectedItems: Record<string, string> = {};

  clearSelectedItems() {
    this.selectedItems = {};
  }

  constructor(private analyticsService: AnalyticsService, private constantsService: ConstantsService, private advertiserService: AdvertiserService, private presenter: AdvertiserSearchPresenter, private modalController: ModalController, private regionService: RegionService) {}

  dismiss() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.ADVERTISERS.SEARCH;
    this.REGIONS = this.regionService.advertiserRegions;

    if (this.options != null) {
      this.presenter.setValue(this.options);
    } else {
      this.presenter.reset();
    }
  }

  onAddCategory(item: any) {
    // allow only a single category
    Object.keys(this.selectedItems).forEach(key => delete this.selectedItems[key]);
    this.selectedItems[item.key] = item.value;
    this.presenter.patchValue({ category: item.value });
  }

  onRemoveCategory(item: any) {
    delete this.selectedItems[item.key];
    this.presenter.patchValue({ category: '' });
  }

  onSearch() {
    const search = this.presenter.search();
    // Use similar order of parameters to Marketplace search
    this.analyticsService.eventTrack(AnalyticsCategory.ADVERTISERS, AnalyticsAction.ADVERTISERS_SEARCH, null, { term1: search.name, term2: search.category, term3: search.location });
    this.search.emit(search);
  }

  reset() {
    this.presenter.reset();
    const search = this.presenter.search();
    this.analyticsService.eventTrack(AnalyticsCategory.ADVERTISERS, AnalyticsAction.ADVERTISERS_CLEAR_SEARCH);
    this.search.emit(search);
  }

  searchCategories(search: string) {
    return this.advertiserService.advertiserCategories.pipe(
      map(categories => {
        return !search ? categories : categories.filter(c => c.toLowerCase().indexOf(search.toLowerCase()) > -1);
      })
    );
  }
}
