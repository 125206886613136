import { Injectable } from '@angular/core';
import { IMember } from '@shared/models/messages/member';
import { DateTimeService } from '@shared/services/date-time.service';
import { ContactService } from '@shared/services/messages/contact.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class MessageHelperService {
  constructor(private contactService: ContactService, private toastService: ToastService) {}

  canAddMemberToThread(isGroup: boolean, existingMembers: IMember[], memberId: string) {
    if (!isGroup) {
      const MAXIMUM_ALLOWED_RECIPIENTS = 6;
      if (existingMembers.length >= MAXIMUM_ALLOWED_RECIPIENTS) {
        this.toastService.presentToast(`Cannot add member, you can add up to ${MAXIMUM_ALLOWED_RECIPIENTS} members.`);
        return false;
      }
    }

    // TODO: Handle race condition between loading contacts and calling this function?
    if (this.contactService.isEitherBlocked(memberId)) {
      this.toastService.presentToast(`Cannot message this member, you have blocked or are blocked by them.`);
      return false;
    }

    return true;
  }
}
