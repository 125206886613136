import { Component, Input, OnInit } from '@angular/core';
import { ICoinsLevel } from '@shared/models/coins/coins-level';
import { UserObject } from '@shared/models/user-object';
import { CoinsService } from '@shared/services/coins/coins.service';
import { ConstantsService } from '@shared/services/constants.service';
import { UserService } from '@shared/services/user/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'chirpy-badges',
  styleUrls: ['./chirpy-badges.component.scss'],
  templateUrl: './chirpy-badges.component.html'
})
export class ChirpyBadgesComponent implements OnInit {
  CONSTANTS: Record<string, any>;
  LEVELS: Record<string, any>;
  @Input() memberId: string; // TODO: Allow passing the member object directly if the parent component already has already read it? But it's cached as a subject in UserService, so probably not much point
  member$: Observable<UserObject>;
  statusBadges: string[];

  constructor(private coinsService: CoinsService, private constantsService: ConstantsService, private userService: UserService) {}

  ngOnInit() {
    this.member$ = this.userService.getUserProfile(this.memberId);
    this.CONSTANTS = this.constantsService.constants.ASSETS.BADGES.icon;
    // In order to display status levels in increasing order, use level-X as the key
    // Using level.name means they will be sorted alphabetically
    this.LEVELS = this.constantsService.constants.COINS.levels.reduce((levels, x) => {
      levels[`level-${x.order}`] = { src: x.icon };
      return levels;
    }, {});
  }

  getStatusBadges(member: UserObject): string[] {
    if (member == null) return [];

    const levelName = member.coinsLevel || ''; //TODO: this will only work for your own status, or if you are an admin. Need to either move coinsLevel to centralMembers, or copy the icon(s) to badges.icon
    const level = this.coinsService.getLevelByName(levelName);
    if (level.order === 0) return [];

    return this.LEVELS.filter(x => x.order > 0 && x.order <= level.order).map(y => y.icon);
  }
}
