import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Country } from '@shared/constants/country';
import { IContactFormSetting } from '@shared/models/contact-form-setting';
import { SelectType } from '@shared/constants/select-type';
import { ISelectOption } from '@shared/models/select-option';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService, IAnalyticsSetting } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { GroupDatabase } from '@shared/services/groups/group.database';
import { LocationService } from '@shared/services/location/location.service';
import { RegionService } from '@shared/services/regions/region.service';
import { ChirpyContactFormPresenter } from './chirpy-contact-form.presenter';
import { ChirpyContactFormService } from './chirpy-contact-form.service';
import { IContactForm } from './models/contact-form';

@Component({
  selector: 'chirpy-contact-form',
  styleUrls: ['./chirpy-contact-form.component.scss'],
  templateUrl: './chirpy-contact-form.component.html',
  viewProviders: [ChirpyContactFormPresenter]
})
export class ChirpyContactFormComponent {
  get disclaimer() {
    return this.chirpyContactFormPresenter.disclaimer();
  }

  get contactForm() {
    return this.chirpyContactFormPresenter.form;
  }

  get hasDisclaimerValidationError() {
    return this.chirpyContactFormService.hasDisclaimerValidationError;
  }

  errorMessage: string = null;
  @Input() analytics: IAnalyticsSetting = null;
  @Input() country: Country;
  @Input() fields: IContactFormSetting[];
  @Input() footerText: string;
  interfaceOptions: any = { cssClass: 'wide-select' };
  @Output() onSubmit = new EventEmitter();
  @Input() params: Record<string, string>;
  @Input() recipient: string;
  @Input() recipientAddress: string = '';
  regionLabel: string;
  regions: ISelectOption[] = []; // TODO: Put region selector into shared component
  selectedItems: Record<string, string> = {};
  selectType: SelectType = SelectType.ITEM;
  sent: boolean = false;
  showForm: boolean = true;
  @Input() subject: string;
  @Input() submitButtonText = 'Submit';
  @Input() successMessage = 'Your message has been sent to support.';

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private chirpyContactFormPresenter: ChirpyContactFormPresenter,
    private chirpyContactFormService: ChirpyContactFormService,
    private groupDatabase: GroupDatabase,
    private locationService: LocationService,
    private regionService: RegionService,
    private route: ActivatedRoute
  ) {}

  isCheckbox(field: IContactFormSetting) {
    return field.id === 'disclaimer';
  }

  isAutoPopulated(field: IContactFormSetting) {
    if (field.autoPopulate === true) {
      return this.chirpyContactFormPresenter.autoPopulated[field.id] === true;
    } else {
      return false;
    }
  }

  isHidden(field: IContactFormSetting) {
    if (field.isHidden != null) return field.isHidden;
    return this.isAutoPopulated(field) || this.isPrePopulated(field);
  }

  isPrePopulated(field: IContactFormSetting) {
    if (field.prePopulate === true) {
      return this.chirpyContactFormPresenter.prePopulated[field.id] === true;
    } else {
      return false;
    }
  }

  isTextArea(field: IContactFormSetting) {
    return field.id === 'description';
  }

  // ionViewWillEnter only fires for page-level components
  ngOnInit() {
    this.regionLabel = this.regionService.regionLabel;
    this.regions = this.regionService.locationRegions;
    this.chirpyContactFormPresenter.init(this.fields, this.params);
  }

  replaceIdsWithTitles(contactForm: IContactForm, fields: IContactFormSetting[]) {
    const titles = Object.values(fields).reduce((output, field) => {
      const key = field.title || field.id;
      output[field.id] = key;
      return output;
    }, {});

    return Object.keys(contactForm).reduce((output, id) => {
      output[titles[id]] = contactForm[id];
      return output;
    }, {});
  }

  onAddItem(field: string, item: Record<string, string>) {
    // searchMembers returns { [memberId]: memberName}, locationService.search returns { uid: locationId, name: locationName }
    const key = item.hasOwnProperty('uid') ? item.uid : item.key;
    const value = item.hasOwnProperty('name') ? item.name : item.value;
    this.selectedItems[item.key] = item.value;
    this.chirpyContactFormPresenter.form.controls[field].setValue(value);
    const idField = `${field}Id`;
    if (this.chirpyContactFormPresenter.form.controls[idField]) {
      this.chirpyContactFormPresenter.form.controls[idField].setValue(key);
    }
  }

  searchItems(selectType: SelectType, startsWith: string = '') {
    const country = this.authService.getCountry();
    // TODO: Refactor searchMembers into userDatabase, but avoid collision with similarly-named function?
    return selectType === SelectType.ITEM ? this.groupDatabase.searchMembers(startsWith, country) : this.locationService.search(startsWith, country, false);
  }

  submit() {
    const contactForm = this.chirpyContactFormPresenter.contactForm();
    if (!this.chirpyContactFormService.validateForm(contactForm, this.fields)) return;

    this.chirpyContactFormService
      .submitContactForm(this.recipient, this.subject, this.replaceIdsWithTitles(contactForm, this.fields), this.country, this.recipientAddress)
      .then(() => {
        this.showForm = false;
        this.errorMessage = null;
        this.sent = true;

        if (this.analytics != null) {
          const label = this.analytics.label ? this.fields[this.analytics.label] || '' : '';
          this.analyticsService.eventTrack(this.analytics.category, this.analytics.action, label);
        }
      })
      .catch(() => {
        this.errorMessage = 'There was an error sending your message, please try again later.';
      });

    this.onSubmit.emit(contactForm);
  }
}
