import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { UserService } from '@shared/services/user/user.service';
import { Observable, of } from 'rxjs';
import { skipWhile, map } from 'rxjs/operators';

@Component({
  selector: 'chirpy-message-seen-by',
  templateUrl: './chirpy-message-seen-by.component.html',
  styleUrls: ['./chirpy-message-seen-by.component.scss']
})
export class ChirpyMessageSeenByComponent {
  @Input() hasMultiplePeople: boolean;
  memberNames: Observable<string>;
  @Input() seenBy: string[];
  @Input() senderId: string;
  showNames: boolean = false;

  constructor(private authService: AuthService, private userService: UserService) {}

  ngOnChanges(changes: SimpleChanges) {
    const newSeenBy = changes.seenBy.currentValue || [];
    if (newSeenBy.length === 0) {
      this.seenBy = [];
      this.memberNames = of('');
    } else {
      this.seenBy = newSeenBy || [];

      // Filter out your own uid if you are viewing, or if you sent the message
      if (this.authService._userProfileSubject.value) this.seenBy = this.seenBy.filter(x => x !== this.authService._userProfileSubject.value.uid && x !== this.senderId);
      this.memberNames = this.userService.getUsers(this.seenBy, true).pipe(
        skipWhile(u => !u),
        map(x => x.map(y => y.displayName).join(', '))
      );
    }
  }

  onToggleNames() {
    this.showNames = !this.showNames;
  }
}
