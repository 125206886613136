//This service is designed to prevent members accessing core services without setting data in their profile
//since it is posssible for them to exit without saving from the edit profile step in the onboarding flow
//E.g. without a displayName they can't be searched for in messaging,
//and any message they send to a host in catchups would come from (blank).
//Without setting their placeId (via postcode + location) they won't appear on the map in meeting place
//See CM-548

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AuthService } from '@shared/services/auth.service';
import { skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EnforceProfileService {
  modal: any;
  uid: string;

  constructor(private alertController: AlertController, private authService: AuthService, private router: Router) {
    this.init();
  }

  init() {
    this.authService.userProfileObservable.pipe(skipWhile(x => !x)).subscribe(user => {
      // skipWhile only prevents initial null values. Once there has been a non-null value, nulls will not be skipped
      if (user == null) {
        this.uid = null;
      }
      //TODO: Keep in sync with required fields in member-profile-edit.presenter.ts
      else if (this.isNullOrEmpty(user.displayName) || this.isNullOrEmpty(user.gender) || this.isNullOrEmpty(user.placeId) || this.isNullOrEmpty(user.locality)) {
        this.uid = user.uid;
      } else {
        this.uid = null;
      }
      this.enforce();
    });
  }

  enforce() {
    if (!!this.uid && !this.authService.isAdmin()) {
      this.presentModal();
    } else {
      if (this.modal) this.modal.dismiss();
    }
  }

  private isNullOrEmpty(query: string) {
    return query == null || query.length === 0;
  }

  private onClickHandler() {
    this.router.navigate([`/members/${this.uid}/edit`]);
  }

  async presentModal() {
    this.modal = await this.alertController.create({
      backdropDismiss: false,
      header: 'Sorry',
      message: 'You need to fill in the required fields in your profile before you can use this feature.',
      buttons: [
        {
          text: 'Update my profile →',
          handler: data => this.onClickHandler()
        }
      ]
    });

    return await this.modal.present();
  }
}
