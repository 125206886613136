import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { IColumn } from '@shared/models/column';
import { UserObject } from '@shared/models/user-object';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { UserService } from '@shared/services/user/user.service';
import { map, mergeMap } from 'rxjs/operators';
import { IInput } from '@shared/models/input';
import { BaseReportComponent } from '../../base-report/base-report.component';

@Component({
  selector: 'app-reports-coins-claimed-by-date',
  templateUrl: '../../base-report/base-report.component.html'
})
export class CoinsClaimedByDateComponent extends BaseReportComponent {
  columns = [
    { label: 'Date', field: 'date', class: 'data-table__column-responsive-bold' },
    { label: 'Member ID', field: 'uid' },
    { label: 'Member name', field: 'displayName' },
    { label: 'Coins balance', field: 'coinsBalance' },
    { label: 'CatchUp name', field: 'catchupName' },
    { label: 'Group name', field: 'groupName' },
    { label: 'Country', field: 'country' }
  ];
  config = {
    routerLink: {
      path: '/admin/coins-edit/',
      id: [':uid', ':displayName']
    }
  };
  dateFrom: string = '';
  dateTo: string = '';
  parametersHeader = 'Select dates';
  parametersMessage = '<p>Enter start date and end date for the report.</p><p class="ion-no-margin">N.B. Catchups are automatically deleted 40 days after the date of the event.</p>';
  parametersInputs: IInput[] = [
    {
      label: 'From: ',
      name: 'dateFrom',
      placeholder: 'YYYY-MM-DD',
      type: 'date'
    },
    {
      label: 'To: ',
      name: 'dateTo',
      placeholder: 'YYYY-MM-DD',
      type: 'date'
    }
  ];
  instructions = '<p>Click on "select parameters" to choose dates for the report</p>';
  title = 'Coins claimed report by date';

  constructor(alertController: AlertController, private catchupService: CatchupService, private userService: UserService) {
    super(alertController);
  }

  loadData() {
    if (!this.dateFrom || !this.dateTo) return;

    this.data$ = this.catchupService.getCatchupsByDate(this.dateFrom, this.dateTo).pipe(
      map(catchups => {
        return catchups.reduce((members, catchup) => {
          const country = { country: (catchup.country || []).join(', ') };
          const attendees = Object.entries(catchup.attendees || {});
          const membersClaimed = attendees.filter(x => x[1] === CatchupRsvpStatus.GOING_CLAIMED).map(x => x[0]);
          if (membersClaimed.length > 0) {
            membersClaimed.forEach(uid => {
              members[uid] = { uid: uid, catchupName: catchup.title, country: country, date: catchup.date, groupName: catchup.groupName };
            });
          }
          return members;
        }, {});
      }),
      mergeMap(
        members => this.userService.getUsers(Object.keys(members)),
        (members: Record<string, any>, profiles: UserObject[]) => {
          return profiles == null ? Object.values(members) : profiles.map(x => Object.assign(members[x.uid], x));
        }
      )
    );
  }

  selectParametersHandler(data: any) {
    this.dateFrom = data.dateFrom;
    this.dateTo = data.dateTo;
    this.parametersInputs[0].value = this.dateFrom;
    this.parametersInputs[1].value = this.dateTo;
    this.loadData();
  }
}
