import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { UserObject } from '@shared/models/user-object';
import { ToastService } from '@shared/services/toast.service';
import { UserDatabase } from '@shared/services/user/user.database';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseAdminComponent } from '../base-admin/base-admin.component';

@Component({
  selector: 'app-search-member',
  templateUrl: '../base-admin/base-admin.component.html',
  styleUrls: ['../base-admin/base-admin.component.scss']
})
export class SearchCoinsClaimedComponent extends BaseAdminComponent {
  placeholder = 'Search for a member who has claimed coins';
  showConfirm = false;
  title = 'Search coins claims';

  constructor(alertController: AlertController, private router: Router, private toastService: ToastService, private userDatabase: UserDatabase) {
    super(alertController);
  }

  selectMemberHandler({ memberId, memberName, data }) {
    this.router.navigate(['/admin/reports/coins-claimed-by-member', memberId]);
  }
}
