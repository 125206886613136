import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { IChargebeePlan } from '@shared/models/chargebee-plan';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { UIService } from '@shared/services/ui.service';
import { UrlHelperService } from '@shared/services/url-helper.service';
import { UserService } from '@shared/services/user/user.service';
import { Observable } from 'rxjs';
import { LoginPresenter } from './login.presenter';

@Component({
  selector: 'app-auth-login',
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss'],
  viewProviders: [LoginPresenter]
})
export class LoginPage {
  get CONSTANTS() {
    return this.constantsService.constants.LOGIN;
  }

  get loginForm() {
    return this.presenter.form;
  }

  get signupUrl$(): Observable<string[]> {
    return this.urlHelperService.signupUrl$;
  }

  isCheckingEmail: boolean = false;

  constructor(
    private analyticsService: AnalyticsService,
    public authService: AuthService,
    private constantsService: ConstantsService,
    private router: Router,
    public alertController: AlertController,
    private presenter: LoginPresenter,
    private urlHelperService: UrlHelperService,
    private uiService: UIService,
    private userService: UserService
  ) {}

  hideLoader() {
    this.uiService.hideLoadingOverlay();
  }

  login() {
    this.showLoader('', 'Authenticating...').then(() => {
      const loginData = this.presenter.login();

      this.authService
        .login(loginData.email, loginData.password)
        .then(() => {
          this.hideLoader();
        })
        .catch(async err => {
          this.hideLoader();
          const alert = await this.alertController.create({
            header: 'Login',
            subHeader: 'Error',
            message: err,
            buttons: ['OK']
          });

          await alert.present();
        });
    });
  }

  onEnterEmail(event: any) {
    if (!this.isCheckingEmail) {
      const email = event.target.value;
      this.userService.mustSetPassword(email);
      this.isCheckingEmail = false;
    }
  }

  showLoader(signupUrl: string, message = null) {
    if (signupUrl) this.analyticsService.eventTrack(AnalyticsCategory.AUTH, AnalyticsAction.AUTH_CLICK_SIGNUP, signupUrl);
    return this.uiService.showLoadingOverlay(message);
  }
}
