import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { UIService } from '@shared/services/ui.service';
import { GameObject } from '../models/game-object';
import { GameService } from '../services/game-service';
import { BingoHelpNotesComponent } from './components/help-notes/help-notes.component';

@Component({
  selector: 'app-bingo-start',
  templateUrl: 'start.page.html',
  styleUrls: ['start.page.scss']
})
export class StartPage {
  constructor(private gameService: GameService, public platform: Platform, public authService: AuthService, private modalController: ModalController, private uiService: UIService, private analyticsService: AnalyticsService, private alertController: AlertController, private router: Router) {}

  get userProfile() {
    return this.authService.userProfileObservable;
  }

  get listOfGames() {
    return this.gameService.games;
  }

  onJoinGame(gameObject: GameObject) {
    this.uiService.showLoadingOverlay('Please wait game is loading...');
    this.gameService
      .joinGame(gameObject)
      .then(newGame => {
        // Send analytics event
        this.analyticsService.eventTrack(AnalyticsCategory.BINGO, AnalyticsAction.BINGO_JOIN);
      })
      .catch(async err => {
        // Add toast here
        const alert = await this.alertController.create({
          header: 'Join Game',
          subHeader: 'Error',
          message: err,
          buttons: ['OK']
        });

        await alert.present();
      })
      .finally(() => {
        this.uiService.hideLoadingOverlay();
        this.router.navigate(['/games/bingo/game', gameObject.uid]);
      });
  }

  async presentHelpNotesModal() {
    const modal = await this.modalController.create({
      component: BingoHelpNotesComponent
    });
    return await modal.present();
  }
}
