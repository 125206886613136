import { Injectable } from '@angular/core';
import { VirtualCatchupService } from '@shared/services/virtual-catchups/virtual-catchup.service';

@Injectable({
  providedIn: 'root'
})
export class ThreadDetailCatchupService {
  constructor(private virtualCatchupService: VirtualCatchupService) {}

  getCatchup(): string {
    return this.virtualCatchupService.roomName;
  }

  async setCatchup(name: string, threadId: string, isDirectMessage: boolean) {
    await this.virtualCatchupService.setCatchup(name, threadId, isDirectMessage);
  }

  unsetCatchup(): void {
    this.virtualCatchupService.unsetCatchup();
  }
}
