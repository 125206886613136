import { Injectable } from '@angular/core';
import { Country } from '@shared/constants/country';
import { IPlaceIndex } from '@shared/models/meeting-place/place-index';
import { IPostcode } from '@shared/models/meeting-place/postcode';
import { IPlace } from '@shared/models/place';
import { BaseDatabase } from '@shared/services/base.database';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationDatabase extends BaseDatabase {
  getPlace(uid: string): Observable<IPlace> {
    const useCache = true;
    return this.getDocument<IPlace>(this.COLLECTION.CENTRAL_PLACES, uid, useCache);
  }

  getPlaceIndex(uid: string): Observable<IPlaceIndex> {
    const useCache = true;
    return this.getDocument<IPlaceIndex>(this.COLLECTION.CENTRAL_PLACES_INDEX, uid, useCache);
  }

  getPlaces(place: string, country: string): Observable<IPlace[]> {
    return this.getDocumentsByQuery<IPlace>(this.COLLECTION.CENTRAL_PLACES, ref => ref.where('displayName', '==', place).where('country', '==', country));
  }

  getPlacesById(uids: string[]): Observable<IPlace[]> {
    return this.getDocumentsByQuery<IPlace>(this.COLLECTION.CENTRAL_PLACES, ref => ref.where('__name__', 'in', uids));
  }

  getPlacesWithGroups(country: string): Observable<IPlace[]> {
    if (country === '') {
      return this.getDocumentsByQuery<IPlace>(this.COLLECTION.CENTRAL_PLACES, ref => ref.where('hasGroup', '!=', []));
    } else {
      return this.getDocumentsByQuery<IPlace>(this.COLLECTION.CENTRAL_PLACES, ref => ref.where('hasGroup', '!=', []).where('country', '==', country));
    }
  }

  getPostcode(uid: string): Observable<IPostcode> {
    const useCache = true;
    return this.getDocument<IPostcode>('centralPostcodes', uid, useCache);
  }

  getRegion(region: string, country: Country): Observable<IPlace> {
    const queryFn = ref =>
      ref
        .where('type', '==', 'region')
        .where('country', '==', country)
        .where('names', 'array-contains', region);
    return this.getDocumentsByQuery<IPlace>(this.COLLECTION.CENTRAL_PLACES, queryFn).pipe(
      first(x => !!x),
      map((places: IPlace[]) => {
        return places.length > 0 ? places[0] : null;
      })
    );
  }

  updatePlaceData(uid: string, data: any): void {
    this.updateDocument(this.COLLECTION.CENTRAL_PLACES, uid, data);
  }
}
