import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { IMedia } from '@shared/models/media/media';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-pix-upload',
  templateUrl: './pix-upload.page.html'
})
export class PixUploadPage implements OnInit {
  canUploadPhotos$: Observable<boolean>;
  collectionId: string;
  collectionTitle: string;
  collectionType: MediaCollectionType = MediaCollectionType.PUBLIC;

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.collectionId = this.route.snapshot.paramMap.get('id');
    this.collectionTitle = this.route.snapshot.paramMap.get('title');
    if (this.collectionTitle) this.collectionTitle = this.collectionTitle.replace(/(%20|\+)/g, ' ');

    // For the moment only hosts can upload
    this.canUploadPhotos$ = this.authService.isCohostOrHostOrAdmin$();

    this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_VIEW_UPLOAD_PAGE, this.collectionId, { type: this.collectionTitle });
  }
}
