import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from '@infrastructure/constants/role';
import { AlertController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { IIcon } from '@shared/models/icon';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { first } from 'rxjs/operators';
import { BaseAdminComponent } from '../base-admin/base-admin.component';

@Component({
  selector: 'app-change-role',
  templateUrl: '../base-admin/base-admin.component.html',
  styleUrls: ['../base-admin/base-admin.component.scss']
})
export class ChangeRoleComponent extends BaseAdminComponent {
  get ROLES() {
    return this.constantsService.constants.ASSETS.BADGES.role;
  }

  confirmHeader = 'Change role';
  confirmButton = 'Change role';
  confirmInputs = [];
  member: string;
  placeholder = 'Search for the member whose role you want to change';
  title = 'Grant role';

  constructor(alertController: AlertController, private authService: AuthService, private constantsService: ConstantsService, private router: Router, private toastService: ToastService, private userService: UserService) {
    super(alertController);
  }

  confirmMessage(memberId: string, memberName: string) {
    // This function is called once when the dialog is initialised, so save member name
    this.member = memberName;
    this.userService
      .getUserProfile(memberId)
      .pipe(first(x => !!x))
      .subscribe(profile => {
        const index = this.confirmInputs.findIndex(x => x.value === profile.role);
        this.setup(); // Clear "current" from existing entries, in case the component has been used before
        if (index > -1) this.confirmInputs[index].label += ` (current)`;
      });
    // Confirm message is updated by updateText when then input is changed
    return `<p>Choose a role from the options below. Members can only have one role.</p>`;
  }

  selectMemberHandler({ memberId, memberName, data }) {
    let message = '';
    this.userService
      .getUserProfile(memberId)
      .pipe(first(x => !!x))
      .subscribe(member => {
        if (this.userService.isAdmin(member) && !this.authService.isAdmin([AdminRole.SUPER])) {
          message = `Sorry, you don't have sufficient permission to remove the ${Role.ADMIN} role from ${memberName}`;
        }
        this.userService
          .updateRole(memberId, data)
          .then(result => {
            message = `${memberName} now has the ${data} role.`;
          })
          .catch(err => {
            message = `Couldn't update the role to ${data} for ${memberName}: ${err}`;
          })
          .finally(() => {
            this.toastService.presentToast(message);
            this.router.navigate(['/admin']);
          });
      });
  }

  setup() {
    const roles: Record<Role, IIcon> = this.ROLES;
    this.confirmInputs = [];
    for (const [key, role] of Object.entries(roles)) {
      if (role.title) {
        this.confirmInputs.push({
          handler: input => {
            this.updateText(input.value);
          },
          label: role.title,
          name: 'role',
          type: 'radio',
          value: key
        });
      }
    }
  }

  updateText(role: Role) {
    this.alert.header = `Add ${role} role`;
    const roleData: IIcon = this.ROLES[role] || null;
    let message = `<p>Grant ${role} role to ${this.member}?</p>`;
    this.alert.message = roleData && roleData.description ? `${message}<p>${roleData.description}</p>` : message;
  }
}
