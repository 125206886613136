import { Component, Input, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { EventType } from '@infrastructure/constants/event-type';
import { UserObject } from '@shared/models/user-object';
import { ICatchup } from '@shared/models/catchups/catchup';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { CatchupRsvpService } from '@shared/services/catchups/catchup-rsvp.service';
import { CatchupTypeService } from '@shared/services/catchups/catchup-types.service';
import { GroupService } from '@shared/services/groups/group.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'catchup-rsvp',
  templateUrl: './catchup-rsvp.component.html'
})
export class CatchupRsvpComponent implements OnInit {
  get attendeesCount() {
    return Object.values(this.catchup.attendees).filter(c => c === CatchupRsvpStatus.GOING.toString() || c === CatchupRsvpStatus.GOING_CLAIMED.toString()).length;
  }

  get catchupRsvpStatus() {
    return this.catchup.attendees[this.member.uid];
  }

  get hasWaitlist() {
    return this.catchup.rsvp || this.waitlistCount > 0;
  }

  get waitlistCount() {
    return Object.values(this.catchup.attendees).filter(c => c === CatchupRsvpStatus.WAITING_FOR_HOST.toString()).length;
  }

  canManageGroup: boolean = false;
  canRsvp: boolean = false;
  @Input() catchup: ICatchup;
  readonly CatchupRsvpStatus = CatchupRsvpStatus;
  isClosed: boolean = false;
  @Input() set isGroupMember(value: boolean) {
    // TODO: Make this work when member leaves a group; there seems to be a delay in updating authService._userProfileSubject
    this.initRsvp(); // update canRsvp  when a member joins/leaves a group
  }
  @Input() member: UserObject;
  @Input() showAttendeesHyperlink: boolean;
  @Input() showRegistrationsHyperlink: boolean;

  constructor(private alertController: AlertController, private groupService: GroupService, private catchupRsvpService: CatchupRsvpService, private catchupTypeService: CatchupTypeService) {}

  async cancelRsvp() {
    const alert = await this.alertController.create({
      header: 'Cancel RSVP?',
      message: 'Are you sure you want to cancel your RSVP?\n This will remove you from the list of attendees and send an email to the host.',
      buttons: [
        {
          text: 'No',
          role: 'cancel'
        },
        {
          text: 'Yes, cancel my RSVP',
          handler: data => this.cancelRsvpHandler()
        }
      ]
    });

    await alert.present();
  }

  cancelRsvpHandler() {
    this.catchupRsvpService.cancelRsvp(this.catchup, this.member);
  }

  initRsvp() {
    if ((this.catchup.eventType === EventType.CatchUp || this.catchup.eventType === EventType.Virtual) && this.groupService.isGroupMemberOfAny(this.catchup.allGroupIds)) {
      this.canRsvp = true;
    } else {
      this.canRsvp = false;
    }
  }

  ngOnInit() {
    // Prevent RSVPs if the event has started
    this.isClosed = this.catchupRsvpService.isClosed(this.catchup);

    // Determine whether event is RSVP (managed in Firebase) or Registration (email to supplier)
    this.initRsvp();

    // Admins/Hosts don't have to be group members, but let them RSVP
    if (this.catchup.groupId)
      this.groupService
        .canManageGroup$(this.catchup.groupId)
        .pipe(first())
        .subscribe(canManageGroup => {
          this.canManageGroup = canManageGroup;
          if (canManageGroup) {
            this.canRsvp = true;
          }
        });
  }

  rsvpForCatchup() {
    this.catchupRsvpService.rsvpForCatchup(this.catchup, this.member);
  }
}
