import { Component } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';

@Component({
  selector: 'chirpy-coins-balance',
  styleUrls: ['./chirpy-coins-balance.component.scss'],
  templateUrl: './chirpy-coins-balance.component.html'
})
export class ChirpyCoinsBalanceComponent {
  constructor(public authService: AuthService) {}
}
