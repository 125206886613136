import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICoinsSetting } from '@shared/models/coins/coins-setting';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { AuthService } from '@shared/services/auth.service';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'chirpy-redeem-coins-table',
  styleUrls: ['./chirpy-redeem-coins-table.component.scss'],
  templateUrl: './chirpy-redeem-coins-table.component.html'
})
export class ChirpyRedeemCoinsTableComponent {
  options$: Observable<ICoinsSetting[]>;

  constructor(private analyticsService: AnalyticsService, private appOptionsService: AppOptionsService, private authService: AuthService) {}

  ionViewWillEnter() {
    this.analyticsService.eventTrack(AnalyticsCategory.COINS, AnalyticsAction.COINS_VIEW_REWARDS);
  }

  ngOnInit() {
    this.options$ = this.authService._userProfileSubject.pipe(
      first(x => x != null),
      switchMap((member: UserObject) => {
        const values: Observable<any[]> = this.appOptionsService.getOptionsValues('coinsRedeemedFor', member.country);
        return values;
      })
    );
  }
}
