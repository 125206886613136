import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { AvatarSize } from '@shared/constants/avatar-size';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { ContactService } from '@shared/services/messages/contact.service';
import { UserService } from '@shared/services/user/user.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent implements OnInit {
  constructor(private analyticsService: AnalyticsService, private router: Router, private route: ActivatedRoute, private storage: Storage, private userService: UserService, private contactService: ContactService) {}
  size: AvatarSize = AvatarSize.LARGE;
  user: UserObject;
  errorMessage: string;

  getMemberProfile(uid: string) {
    this.userService
      .getUserProfile(uid)
      .pipe(first())
      .subscribe(user => {
        if (user == null) {
          this.errorMessage = 'Member does not exist';
          this.analyticsService.eventTrack(AnalyticsCategory.MESSAGES, AnalyticsAction.MESSAGES_VIEW_CONTACT_DETAIL, this.errorMessage);
          return;
        }
        this.user = user;
        this.analyticsService.eventTrack(AnalyticsCategory.MESSAGES, AnalyticsAction.MESSAGES_VIEW_CONTACT_DETAIL, user.displayName);
      });
  }

  getAvatarUrl(user) {
    return this.userService.getAvatarUrl(user);
  }

  ngOnInit() {
    const userId = this.route.snapshot.paramMap.get('id');
    if (userId != null) {
      this.getMemberProfile(userId);
    }
  }

  ionViewWillEnter() {
    this.storage.set('messages-state', this.router.url);
  }

  isBlocked() {
    return this.contactService.isBlocked(this.user.uid);
  }

  isContact() {
    return this.contactService.isContact(this.user.uid);
  }

  isEitherBlocked() {
    return this.contactService.isEitherBlocked(this.user.uid);
  }

  onAddContact() {
    this.contactService.addMemberToContacts(this.user.uid, this.user.displayName);
  }

  onBlockMember() {
    this.contactService.blockMember(this.user.uid, this.user.displayName);
  }

  onRemoveContact() {
    this.contactService.removeMemberFromContacts(this.user.uid, this.user.displayName);
  }

  onUnblockMember() {
    this.contactService.unblockMember(this.user.uid, this.user.displayName);
  }
}
