import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StateGuard } from '@shared/services/messages/state.guard';
import { SharedModule } from '@shared/shared.module';
import { AddContactComponent } from './add-contact/add-contact.component';
import { ContactDetailComponent } from './contact-detail/contact-detail.component';
import { ContactListComponent } from './contact-list/contact-list.component';

export const routes: Routes = [
  {
    path: 'add',
    component: AddContactComponent,
    canDeactivate: [StateGuard],
    data: {
      title: 'Add contact'
    }
  },
  {
    path: ':id',
    component: ContactDetailComponent,
    canDeactivate: [StateGuard],
    data: {
      title: 'Contact details'
    }
  },
  {
    path: '',
    component: ContactListComponent,
    canDeactivate: [StateGuard],
    data: {
      title: 'Contacts'
    }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactsRoutingModule {}
