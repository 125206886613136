import { Injectable, OnDestroy } from '@angular/core';
import { ILastRead } from '@shared/models/last-read';
import { BaseRTDatabase } from '@shared/services/base.rtdatabase';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MineRTDatabase extends BaseRTDatabase {
  getNoticeLastRead(memberId: string): Observable<number> {
    return this.getObject<ILastRead>(this.LIST.LAST_READ, memberId).pipe(map(x => (x ? x.notice : 0)));
  }

  updateNoticeLastRead(memberId: string, timestamp: number): Promise<void> {
    return this.updateObject(this.LIST.LAST_READ, memberId, { notice: timestamp });
  }
}
