import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { BaseAdminComponent } from '../base-admin/base-admin.component';

@Component({
  selector: 'app-remove-romance-access',
  templateUrl: '../base-admin/base-admin.component.html',
  styleUrls: ['../base-admin/base-admin.component.scss']
})
export class RemoveRomanceAccessComponent extends BaseAdminComponent {
  confirmHeader = 'Remove Romance access';
  confirmButton = 'Remove access';
  // TODO: restrict component to only show members with romance access?
  placeholder = 'Search for a member to remove Romance access from';
  title = 'Remove Romance access';

  constructor(alertController: AlertController, private router: Router, private toastService: ToastService, private userService: UserService) {
    super(alertController);
  }

  confirmMessage(memberId: string, memberName: string) {
    return `<p>Remove Romance access from ${memberName}?</p><p>This removes their permission to view and create Romance listings.</p>`;
  }

  selectMemberHandler({ memberId, memberName, data }) {
    let message = '';
    this.userService
      .removeRomanceAccess(memberId)
      .then(result => {
        message = `${memberName} no longer has access to Romance.`;
      })
      .catch(err => {
        message = `Couldn't update romance access for ${memberName}: ${err}`;
      })
      .finally(() => {
        this.toastService.presentToast(message);
        this.router.navigate(['/admin']);
      });
  }
}
