import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { UserService } from '@shared/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  constructor(private authService: AuthService, private userService: UserService) {}

  resetPassword(form: any) {
    this.userService.updateSetPasswordFlagByEmail(form.email);
    return this.authService.resetPassword(form.email);
  }
}
