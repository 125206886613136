import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserObject } from '@shared/models/user-object';
import { GameStatus } from '../../../game/game-status';
import { GameObject } from '../../../models/game-object';
import { BingoListGamesPresenter } from './list-games.presenter';

@Component({
  selector: 'bingo-list-games',
  styleUrls: ['list-games.component.scss'],
  templateUrl: 'list-games.component.html',
  providers: [BingoListGamesPresenter]
})
export class BingoListGamesComponent {
  @Input() user: UserObject;
  @Input() listOfGames: GameObject[];
  @Output() joinGame = new EventEmitter<GameObject>();

  readonly GameStatus = GameStatus;

  constructor(private presenter: BingoListGamesPresenter) {}

  onJoinGame(gameObject: GameObject) {
    this.joinGame.emit(gameObject);
  }

  filterGame(game: GameObject) {
    return game.status !== GameStatus.CANCELLED && game.status !== GameStatus.FINISHED;
  }

  numberOfPlayers(players: []) {
    return players != null ? players.length : 0;
  }

  maxNumberOfPlayers(maxPlayers) {
    return maxPlayers != null ? maxPlayers : '?';
  }

  canDisplayManageGamesList(user) {
    return this.presenter.canDisplayManageGamesList(user);
  }

  canDisplayManageGameButton(user) {
    return this.presenter.isAdmin(user);
  }

  canDisplayQuickRules(user) {
    return this.presenter.isMember(user);
  }

  canDisplayJoinGameButton(user, game) {
    return this.presenter.canDisplayJoinGameButton(user, game);
  }

  canDisplayGameInProgressMessage(user, game) {
    return this.presenter.canDisplayGameInProgressMessage(user, game);
  }

  canDisplayContinueGameButton(user, game) {
    return this.presenter.canDisplayContinueGameButton(user, game);
  }
}
