import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, IonInfiniteScroll, Platform } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { AuthService } from '@shared/services/auth.service';
import { UIService } from '@shared/services/ui.service';
import { ChirpyMessageListService } from './chirpy-message-list.service';

@Component({
  selector: 'chirpy-message-list',
  templateUrl: './chirpy-message-list.component.html',
  styleUrls: ['./chirpy-message-list.component.scss']
})
export class ChirpyMessageListComponent implements OnInit {
  get member() {
    return this.service.member;
  }

  get messages() {
    return this.service.messages;
  }

  get messagesToTake() {
    return this.takeTwo === true ? 2 : 1;
  }

  get noMessages() {
    return this.noMessagesText !== '' && this.service._messages.length === 0; // TODO check messages finished loading
  }

  get noMorePastMessages() {
    return this.service.noMorePastMessages;
  }

  get firstMessageDate() {
    return this.service._messages.length > 0 ? this.service._messages[0].dateTimeSent : null;
  }

  @ViewChild('content', { static: false }) content: IonContent;
  // used to check if Android keyboard has opened and message history needs to scroll to the bottom to see the last messages
  currentScrollPosition: number;
  @Output() focusInput = new EventEmitter<any>();
  @Input() hasMultiplePeople: boolean;
  @ViewChild(IonInfiniteScroll, { static: false }) infiniteScroll: IonInfiniteScroll;
  isAdmin: boolean = false;
  @Input() isThreadClosed: boolean;
  @Input() noMessagesText: string;
  @Input() takeTwo: boolean; // If there are messages for the same thread rendered on the previous page (e.g. chirpy-last-message component) this prevents stale cached results
  @Input() threadClosedMessage: string;
  threadId: string;

  constructor(private authService: AuthService, private service: ChirpyMessageListService, private route: ActivatedRoute, private uiService: UIService, private platform: Platform) {}

  hideInfiniteScrollSpinner(delay: number = 0) {
    if (delay === 0) {
      if (this.infiniteScroll) {
        this.infiniteScroll.complete();
      }
    } else {
      setTimeout(() => {
        // re-enables infinite scroll element (hides spinner) so user can resume scrolling backwards
        // through the chat history to load more past messages.
        if (this.infiniteScroll) {
          this.infiniteScroll.complete();
        }
      }, delay);
    }
  }

  ionViewWillEnter() {
    // ngOnInit doesn't trigger if you come back from a navigation stack, ionViewWillEnter / ionViewDidEnter will.
    this.focusInput.emit();
    this.scrollMessageHistoryToBottomForAndroid();
  }

  listenForNewMessages() {
    const dateStart = Date.now();
    const updateUIFunction = this.updateUI.bind(this, true);
    this.service.listenForNewMessages(updateUIFunction, this.threadId, dateStart);
  }

  loadInitialMessages() {
    const dateStart = Date.now();
    const updateUIFunction = this.updateUI.bind(this, true);
    this.service.loadInitialMessages(updateUIFunction, this.threadId, dateStart, this.messagesToTake);
  }

  loadPastMessages() {
    const updateUIFunction = this.updateUI.bind(this, false);
    this.service.loadPastMessages(updateUIFunction, this.threadId);
  }

  ngOnDestroy() {
    // Stop listening for new messages, and/or terminate any observables loading past messages
    this.service.stopReceivingMessages$.next();
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id == null) {
        return;
      }

      // TODO: Pass the threadId to this component as an observable input?
      this.threadId = this.route.snapshot.url[0].path === 'chit-chat' ? `group_${id}` : id;

      this.isAdmin = this.authService.isAdmin([AdminRole.MODERATOR, AdminRole.SUPER]);

      this.service.stopReceivingMessages$.next();
      if (this.infiniteScroll) {
        this.infiniteScroll.complete();
      }

      this.service.resetMessages();
      this.loadInitialMessages();
      this.listenForNewMessages();
    });
  }

  onDelete(messageId: string): void {
    this.service.deleteMessage(messageId);
  }

  onModerate(data: any): void {
    this.service.processMessages([data]);
  }

  // this workaround checks if we need to scroll to the bottom of the message history when we open and close the
  // on-screen keyboard because Android keyboards float on top of the thread-details component.
  scrollMessageHistoryToBottomForAndroid() {
    if (this.platform.is('android')) {
      setInterval(() => {
        if (this.currentScrollPosition === 0) {
          return;
        }

        this.content.getScrollElement().then(result => {
          if (result.clientHeight !== this.currentScrollPosition) {
            this.scrollToBottomHandler();
          }
        });
      }, 100);
    }
  }

  scrollToBottom(): void {
    const PIXELS_FROM_BOTTOM_OF_THE_SCREEN = 10; // scroll to the bottom if the user is nearly scrolled to the bottom.
    // it's annoying as a user when you think you're looking at the last message and the chat never moves, but you're
    // only a few pixels away from the bottom of the page

    this.content.getScrollElement().then(result => {
      const scrollYPosition = result.scrollHeight - result.scrollTop - result.offsetHeight;
      if (scrollYPosition < PIXELS_FROM_BOTTOM_OF_THE_SCREEN) {
        this.scrollToBottomHandler();
      }
    });
  }

  scrollToBottomHandler() {
    // setTimeout is needed to give angular a delay to update the UI with messages
    setTimeout(() => {
      this.content.scrollToBottom(0);

      // workaround: the iOS bounce effect stops you from scrolling if you scroll down before scrolling up when you first open a thread.
      // scroll up one pixel so iOS scrolls before doing the bounce effect. this prevents scrolling from breaking.
      this.content.scrollByPoint(0, -1, 0);

      this.content.getScrollElement().then(result => {
        this.currentScrollPosition = result.clientHeight;
      });
    }, 0);
  }

  updateUI(scrollToBottom: boolean) {
    this.hideInfiniteScrollSpinner();

    // loading past messages will never scroll you to the bottom of the page.
    // loading new messages will scroll to the bottom of the page if you were already at the bottom of the page.
    if (scrollToBottom) {
      this.scrollToBottom();
    }

    this.focusInput.emit();
  }
}
