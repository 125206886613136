import { Component, Input } from '@angular/core';

@Component({
  selector: 'bingo-game-over-card',
  templateUrl: './game-over-card.component.html',
  styleUrls: ['./game-over-card.component.scss']
})
export class GameOverCardComponent {
  @Input() currentRound: number;
  @Input() rounds: number;

  get isGameOver() {
    return this.currentRound === this.rounds - 1;
  }
}
