import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { AvatarSize } from '@shared/constants/avatar-size';
import { PhotoSize } from '@shared/constants/photo-size';
import { IShareListing } from '@shared/models/share/share-listing';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { MessageHelperService } from '@shared/services/messages/message-helper.service';
import { ShareService } from '@shared/services/share/share.service';
import { UserService } from '@shared/services/user/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'share-detail',
  templateUrl: './share-detail.component.html',
  styleUrls: ['./share-detail.component.scss']
})
export class ShareDetailComponent implements OnInit, OnDestroy {
  get CONSTANTS() {
    return this.constantsService.constants.SHARE.DETAIL;
  }

  get DISCLAIMER() {
    return this.constantsService.constants.SHARE.disclaimer;
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin([AdminRole.SUPER]);
  }

  get placeholderImage(): string {
    return this.constantsService.constants.ASSETS.placeholderImage;
  }

  get user(): UserObject | null {
    if (this.authService._userProfileSubject != null && this.authService._userProfileSubject.value != null) {
      return this.authService._userProfileSubject.value;
    }
    return null;
  }

  get userId(): string {
    return this.user ? this.user.uid : '';
  }

  avatarUrl: string;
  errorMessage: string;
  shareListing: IShareListing;
  shareListingRef: Subscription;
  size: AvatarSize = AvatarSize.LARGE;
  title: string;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private messageHelperService: MessageHelperService,
    private shareService: ShareService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {}

  approveListing() {
    const uid = this.shareListing.uid;
    this.shareService.approveShareListing(uid);
  }

  getCategories(categories: string[]) {
    return this.shareService.getCategoriesList(categories);
  }

  deleteShareListing() {
    this.shareService.deleteShareListing(this.shareListing);
  }

  getDateFormat(created: number) {
    return this.dateTimeService.getDateFormat(created);
  }

  getShareListing(uid: string) {
    this.shareListingRef = this.shareService.getShareListing(uid).subscribe(shareListing => {
      if (shareListing == null) {
        this.title = this.CONSTANTS.pageHeading;
        this.errorMessage = this.CONSTANTS.doesNotExist;
        return;
      }

      this.shareListing = shareListing;
      this.title = this.shareListing.title;

      this.sendAnalytics(this.shareListing);
    });
  }

  goBack() {
    this.router.navigate([`/share`]);
  }

  ngOnDestroy() {
    if (this.shareListingRef) this.shareListingRef.unsubscribe();
  }

  ngOnInit() {
    const uid = this.route.snapshot.paramMap.get('id');
    if (uid == null) return;
    this.getShareListing(uid);
  }

  onSendMessage() {
    this.analyticsService.eventTrack(AnalyticsCategory.SHARE, AnalyticsAction.SHARE_SEND_MESSAGE, this.shareListing.title, { type: this.getCategories(this.shareListing.categories) });
  }

  rejectListing() {
    const uid = this.shareListing.uid;
    this.shareService.rejectShareListing(uid);
  }

  sendAnalytics(shareListing: IShareListing) {
    if (shareListing.memberId === this.userId) {
      this.analyticsService.eventTrack(AnalyticsCategory.SHARE, AnalyticsAction.SHARE_VIEW_OWN_LISTING_DETAIL, shareListing.title, { type: this.getCategories(shareListing.categories) });
    } else {
      this.analyticsService.eventTrack(AnalyticsCategory.SHARE, AnalyticsAction.SHARE_VIEW_LISTING_DETAIL, shareListing.title, { type: this.getCategories(shareListing.categories) });
    }
  }
}
