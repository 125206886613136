import { Injectable } from '@angular/core';
import { ICancellation } from '@shared/models/cancellations/cancellation';
import { ICancellationOptions } from '@shared/models/cancellations/cancellation-options';
import { CancellationDatabase } from './cancellation.database';

@Injectable({
  providedIn: 'root'
})
export class CancellationService {
  constructor(private cancellationDatabase: CancellationDatabase) {}

  getAllCancellations(search: ICancellationOptions, lastTimestamp: number) {
    const limit = 50;
    return this.cancellationDatabase.getAllCancellations(search, limit, lastTimestamp);
  }

  saveCancellation(cancellation: ICancellation) {
    this.cancellationDatabase.saveCancellation(cancellation);
  }
}
