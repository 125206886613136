import { Component, OnInit } from '@angular/core';
import { Country } from '@shared/constants/country';
import { Role } from '@infrastructure/constants/role';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { BeaconService } from '@shared/services/beacon.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { UIService } from '@shared/services/ui.service';
import { UrlHelperService } from '@shared/services/url-helper.service';
import { UserService } from '@shared/services/user/user.service';
import { BehaviorSubject } from 'rxjs';
import { IAppMenuItem } from './models/app-menu-item';
import { AppMenuService } from './services/app-menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: 'app-menu.component.html',
  styleUrls: ['app-menu.component.scss']
})
export class AppMenuComponent implements OnInit {
  get appPages$(): BehaviorSubject<IAppMenuItem[]> {
    return this.appMenuService.appMenuItems$;
  }

  get versionNumber() {
    return this.environmentService.versionNumber;
  }

  appPageTitles: any[] = []; // entries can be string, string[], or string[][]
  readonly Country = Country;

  constructor(
    private analyticsService: AnalyticsService,
    public authService: AuthService,
    private appMenuService: AppMenuService,
    private uiService: UIService,
    private urlHelperService: UrlHelperService,
    private userService: UserService,
    private environmentService: EnvironmentService,
    private beaconService: BeaconService
  ) {}

  canShowForCountry(country: Country, user: UserObject) {
    if (country == null) {
      return true;
    }

    if (user != null && user.country != null) {
      return country === user.country;
    }

    return false;
  }

  canShowForMember(page: IAppMenuItem, member: UserObject): boolean {
    return !page.forHosts || (page.forHosts && member != null && this.userService.isHostOrAdmin(member));
  }

  eventTrack(title: string, topIndex: number, subIndex: number, subSubIndex: number) {
    const itemType = subSubIndex !== null ? 'sub-sub-item' : subIndex !== null ? 'sub-item' : 'top-level-item';

    // NB When determining index, don't exclude items that are specific to another country
    // otherwise the analytics for index X will be a mixture of different menu items
    let index = this.appPageTitles.slice(0, topIndex).flat(2).length;
    if (subIndex !== null) index += this.appPageTitles[topIndex].slice(0, subIndex).flat(1).length;
    if (subSubIndex !== null) index += this.appPageTitles[topIndex][subIndex].slice(0, subSubIndex).length;

    // Links in header are counted separately, starting from 100
    if (topIndex >= 100) index = topIndex;

    this.analyticsService.eventTrack(AnalyticsCategory.NAVIGATION, AnalyticsAction.NAVIGATION_MENU_LINK, title, { type: itemType }, index);
  }

  getAvatarUrl(user) {
    return this.userService.getAvatarUrl(user);
  }

  getWordPressUrl(user: UserObject, url: string) {
    if (url == null || !url.length) {
      return '';
    }

    if (url[0] !== '/') {
      url = `/${url}`;
    }

    const host = user.host || 'https://chirpyplus';
    let domain = user.domain;
    //Use domain from URL for admins, who could be coming from AU or NZ WordPress site
    //Also handle scenario where the WordPress domain links to a staging site, i.e.
    //user.host = https://chirpy.staging.wpengine, user.domain = .com
    if (user == null || (user.host !== null && user.role === Role.ADMIN)) {
      domain = this.urlHelperService.domain;
    }
    return `${host}${domain}${url}`;
  }

  hideBeacon() {
    // Hide Beacon when Menu is open to stop Beacon icon from overlapping the menu.
    this.beaconService.hideBeacon();
  }

  isExternalUrl(page: IAppMenuItem): boolean {
    if (page.children) return false;
    return page.url && page.external && page.fullExternalUrl ? true : false;
  }

  isFirebaseUrl(page: IAppMenuItem): boolean {
    if (page.children) return false;
    return page.url && !page.external ? true : false;
  }

  isWordPressUrl(page: IAppMenuItem): boolean {
    if (page.children) return false;
    return page.url && page.external && !page.fullExternalUrl ? true : false;
  }

  ngOnInit() {
    this.appPages$.subscribe(pages => {
      if (pages == null) return;
      this.appPageTitles = pages.map(x => this.getTitles(x)) as string[];
    });
  }

  showBeacon() {
    this.beaconService.showBeacon();
  }

  showLoadingOverlay() {
    this.uiService.showLoadingOverlay();
  }

  private getTitles(item: IAppMenuItem) {
    if (item.children) return item.children.map(x => this.getTitles(x));
    else return item.title;
  }
}
