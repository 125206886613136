import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'bingo-help-notes',
  templateUrl: 'help-notes.component.html'
})
export class BingoHelpNotesComponent {
  constructor(public modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss();
  }
}
