import { Injectable } from '@angular/core';
import { CursorType } from '@shared/constants/cursor-type';
import { Direction } from '@shared/constants/direction';
import { IAppOptions } from '@shared/models/app-options';
import { IMediaCollection } from '@shared/models/media/media-collection';
import { ICursor } from '@shared/models/cursor';
import { IOrderCondition } from '@shared/models/order-condition';
import { IMedia } from '@shared/models/media/media';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PhotoDatabase extends BaseDatabase {
  createPhoto(photo: IMedia) {
    return this.createDocument(this.COLLECTION.MEDIA, photo);
  }

  deletePhoto(uid: string) {
    return this.deleteDocument(this.COLLECTION.MEDIA, uid);
  }

  getAdjacentPhoto(photoCollection: string, timestamp: number, direction: Direction, isAdmin: boolean): Observable<IMedia[]> {
    const whereConditions: IWhereCondition[] = [{ field: 'collections', operator: 'array-contains', value: photoCollection }];
    if (!isAdmin) whereConditions.push({ field: 'isHidden', operator: '==', value: false });
    const order = direction === Direction.PREV ? 'desc' : 'asc';
    const orderConditions: IOrderCondition[] = [{ field: 'dateTimeUploaded', direction: order }];
    const cursors: ICursor[] = [{ type: CursorType.START_AFTER, value: timestamp }];

    return this.getDocumentsByQuery<IMedia>(this.COLLECTION.MEDIA, this.createQueryFunction(whereConditions, orderConditions, 1, cursors));
  }

  getCollection(uid: string): Observable<IMediaCollection> {
    return this.getDocument<IMediaCollection>(this.COLLECTION.MEDIA_COLLECTIONS, uid);
  }

  getMediaCategories() {
    return this.getDocument<IAppOptions>(this.COLLECTION.APP_OPTIONS, 'mediaCategories');
  }

  getPhoto(uid: string): Observable<IMedia> {
    return this.getDocument<IMedia>(this.COLLECTION.MEDIA, uid);
  }

  getPhotosInCollection(uid: string, isAdmin: boolean, maxPhotos: number): Observable<IMedia[]> {
    const whereConditions: IWhereCondition[] = [{ field: 'collections', operator: 'array-contains', value: uid }];
    if (!isAdmin) whereConditions.push({ field: 'isHidden', operator: '==', value: false });
    const orderConditions: IOrderCondition[] = [{ field: 'dateTimeUploaded', direction: 'desc' }];

    return this.getDocumentsByQuery<IMedia>(this.COLLECTION.MEDIA, this.createQueryFunction(whereConditions, orderConditions, maxPhotos));
  }

  updateCollection(uid: string, data: any) {
    return this.updateDocument(this.COLLECTION.MEDIA_COLLECTIONS, uid, data);
  }

  updatePhoto(uid: string, data: any) {
    return this.updateDocument(this.COLLECTION.MEDIA, uid, data);
  }
}
