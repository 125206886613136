import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IMediaOptions } from '@shared/models/media/media-options';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { ConstantsService } from '@shared/services/constants.service';
import { PhotoService } from '@shared/services/photos/photo.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PixSearchPresenter } from './pix-search.presenter';

@Component({
  selector: 'app-pix-search',
  templateUrl: './pix-search.component.html',
  styleUrls: ['./pix-search.component.scss'],
  viewProviders: [PixSearchPresenter],
  encapsulation: ViewEncapsulation.None
})
export class PixSearchComponent implements OnInit {
  get CONSTANTS() {
    return this.constantsService.constants.MEDIA;
  }

  get searchForm() {
    return this.presenter.form;
  }

  get MEDIA_CATEGORIES$(): Observable<string[]> {
    return this.photoService.mediaCategories.pipe(map(categories => categories.map(x => x.id)));
  }

  interfaceOptions: any = { cssClass: 'wide-select' };
  @Input() options: IMediaOptions | null;
  @Output() search = new EventEmitter<any>();

  constructor(private analyticsService: AnalyticsService, private constantsService: ConstantsService, private modalController: ModalController, private photoService: PhotoService, private presenter: PixSearchPresenter) {}

  dismiss() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    if (this.options != null) {
      this.presenter.setValue(this.options);
    } else {
      this.presenter.reset();
    }
  }

  onSearch() {
    const search = this.presenter.search();
    this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_SEARCH, null, { term1: search.category });
    this.search.emit(search);
  }

  reset() {
    this.presenter.reset();
    this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_CLEAR_SEARCH);
    this.onSearch();
  }
}
