import { Component, Input } from '@angular/core';

@Component({
  selector: 'add-remove-member',
  templateUrl: './add-remove-member.component.html',
  styleUrls: ['./add-remove-member.component.scss']
})
export class ChirpyAddRemoveMemberComponent {
  @Input() memberName: string;
  @Input() memberId: string;
  @Input() icon: string;
  @Input() iconColor = 'secondary';
}
