import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { ITrip } from '@shared/models/trips/trip';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import firebase from 'firebase/app';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TripDatabase extends BaseDatabase {
  readonly FETCH_ALL_RECORDS = 9999; // TODO: Check if this is still the limit per query in Firebase

  createTrip(trip) {
    return this.createDocument(this.COLLECTION.TRIPS, trip);
  }

  deleteTrip(uid: string) {
    return this.deleteDocument(this.COLLECTION.TRIPS, uid);
  }

  getTrip(uid: string): Observable<ITrip> {
    return this.getDocument<ITrip>(this.COLLECTION.TRIPS, uid);
  }

  getGroupTrips(recordsToFetch: number, groupId: string, date: string, isAdmin: boolean): Observable<ITrip[]> {
    const records = recordsToFetch || this.FETCH_ALL_RECORDS;
    const whereConditions: IWhereCondition[] = [
      {
        field: 'date',
        operator: '>=',
        value: date
      }
    ];
    if (!isAdmin) {
      whereConditions.push({
        field: 'published',
        operator: '==',
        value: true
      });
    }
    if (groupId !== '') {
      whereConditions.push({
        field: 'allGroupIds',
        operator: 'array-contains',
        value: groupId
      });
    }
    const orderConditions: IOrderCondition[] = [{ field: 'date', direction: 'asc' }];
    const queryFn = this.createQueryFunction(whereConditions, orderConditions, records);
    return this.getDocumentsByQuery<ITrip>(this.COLLECTION.TRIPS, queryFn);
  }

  getTripsBookedByMember(memberId: string): Observable<ITrip[]> {
    const whereConditions: IWhereCondition[] = [
      {
        field: `attendees.${memberId}`,
        operator: '==',
        value: CatchupRsvpStatus.GOING
      }
    ];

    const queryFn = this.createQueryFunction(whereConditions, [], this.FETCH_ALL_RECORDS);
    return this.getDocumentsByQuery<ITrip>(this.COLLECTION.TRIPS, queryFn);
  }

  updateMemberGroups(uid: string, data: any) {
    const merge: boolean = true;
    return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS, uid, data, merge);
  }

  updateTrip(uid: string, data: any, merge: boolean = true) {
    return this.updateDocument(this.COLLECTION.TRIPS, uid, data, merge);
  }
}
