import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MembersGuard } from '@shared/guards/members-guard';
import { SharedModule } from '@shared/shared.module';
import { MoreArchiveComponent } from './more-archive/more-archive.component';
import { MoreDetailComponent } from './more-detail/more-detail.component';
import { MoreListComponent } from './more-list/more-list.component';

export const routes: Routes = [
  {
    path: '',
    component: MoreListComponent,
    canActivate: [MembersGuard],
    data: {
      title: 'More messages options'
    }
  },
  {
    path: 'archive',
    component: MoreArchiveComponent,
    canActivate: [MembersGuard],
    data: {
      title: 'Message archive'
    }
  },
  {
    path: 'archive/:id',
    component: MoreDetailComponent,
    canActivate: [MembersGuard],
    data: {
      title: 'Archived message details'
    }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MoreRoutingModule {}
