import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { AvatarSize } from '@shared/constants/avatar-size';
import { IAdvertiser } from '@shared/models/advertisers/advertiser';
import { UserObject } from '@shared/models/user-object';
import { AdvertiserService } from '@shared/services/advertisers/advertiser.service';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UserService } from '@shared/services/user/user.service';
import { Observable, of, Subscription } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-member-profile-detail',
  templateUrl: './member-profile-detail.page.html',
  styleUrls: ['./member-profile-detail.page.scss']
})
export class MemberProfileDetailPage {
  advertiser$: Observable<IAdvertiser>;
  canEdit: boolean = false;
  CONSTANTS: any;
  errorMessage: string;
  interestsLabel: string;
  isCohostOrHostOrAdmin$: Observable<boolean>;
  isInterestsEnabled: boolean = true; // all white-label versions currently have it enabled
  isMe: boolean = false;
  isOrganisationsEnabled: boolean = false;
  isPhoneEnabled: boolean = false;
  isTitleEnabled: boolean = false;
  memberRef: Subscription;
  myselfRef: Subscription;
  organisationsLabel: string;
  size: AvatarSize = AvatarSize.XLARGE;
  user: UserObject;

  constructor(
    private advertiserService: AdvertiserService,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private groupService: GroupService,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private userService: UserService
  ) {}

  getKeys(user) {
    return this.userService.getKeys(user);
  }

  getMemberProfile(uid: string) {
    // In principle we could just take the first non-null value, but then the page wouldn't update if an admin edited the profile
    this.memberRef = this.userService.getUserProfile(uid).subscribe(user => {
      if (user == null) {
        this.errorMessage = `Either this member does not exist or we can't connect to the database right now.`;
        return;
      }
      this.errorMessage = ``;
      this.user = user;
      this.isMe = this.user != null && this.authService._userProfileSubject.value != null && this.user.uid === this.authService._userProfileSubject.value.uid;
      this.canEdit = (this.user != null && this.authService.isAdmin([AdminRole.SUPER])) || this.isMe;
      this.isCohostOrHostOrAdmin$ = this.isHostOfThisMember$(user);
      if (this.isMe) {
        this.analyticsService.eventTrack(AnalyticsCategory.MEMBERS, AnalyticsAction.MEMBERS_VIEW_OWN_PROFILE);
      } else {
        this.analyticsService.eventTrack(AnalyticsCategory.MEMBERS, AnalyticsAction.MEMBERS_VIEW_PROFILE, this.user.displayName);
      }
    });
    this.subscriptionService.add(this.memberRef);
  }

  getMyMemberProfile() {
    this.myselfRef = this.authService._userProfileSubject.subscribe(user => {
      this.user = user;
      this.canEdit = true;
      this.isMe = true;
      this.isCohostOrHostOrAdmin$ = this.authService.isCohostOrHostOrAdmin$();
    });
    this.subscriptionService.add(this.myselfRef);
  }

  // Can't load profile on ngOnInit because the page is cached, so (e.g.) if you update your profile you wouldn't see changes
  ionViewWillEnter() {
    this.CONSTANTS = this.constantsService.constants.PROFILE;
    this.isInterestsEnabled = this.CONSTANTS.INTERESTS.enabled;
    this.interestsLabel = this.CONSTANTS.INTERESTS.label;
    this.isOrganisationsEnabled = this.CONSTANTS.ORGANISATIONS.enabled;
    this.isPhoneEnabled = this.CONSTANTS.PHONE.enabled;
    this.organisationsLabel = this.CONSTANTS.ORGANISATIONS.label;
    this.isTitleEnabled = this.CONSTANTS.TITLE.enabled;

    const userId = this.route.snapshot.paramMap.get('userId');

    if (userId === 'me' || (this.authService._userProfileSubject.value != null && userId === this.authService._userProfileSubject.value.uid)) {
      this.getMyMemberProfile();
      this.advertiser$ = this.advertiserService.getMyAdvertiserListing();
      this.analyticsService.eventTrack(AnalyticsCategory.MEMBERS, AnalyticsAction.MEMBERS_VIEW_OWN_PROFILE);
      return;
    }

    this.getMemberProfile(userId);
    this.advertiser$ = this.advertiserService.getAdvertiser(userId);
  }

  ionViewWillLeave() {
    this.subscriptionService.clearSubscription(this.memberRef);
    this.subscriptionService.clearSubscription(this.myselfRef);
  }

  private isHostOfThisMember$(profile: UserObject): Observable<boolean> {
    // Not a host or admin, or member doesn't belong to any groups, or no feature requires this info
    if (!this.authService.isCohostOrHostOrAdmin() || (profile.catchupGroupIds || []).length === 0 || !this.isPhoneEnabled) {
      return of(false);
    }

    // Is an admin
    if (this.authService.isAdmin([AdminRole.HOSTS])) {
      return of(true);
    }

    //Check is the member viewing is the host of any of the viewed member's groups
    return this.authService._userProfileSubject.pipe(
      first(x => !!x),
      switchMap(member => {
        return this.groupService.getGroupsHostedByMember$(member.uid);
      }),
      map(groups => {
        const groupIds = groups.map(x => x.uid);
        const intersection = groupIds.filter(x => profile.catchupGroupIds.includes(x));
        return intersection.length > 0;
      })
    );
  }
}
