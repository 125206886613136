import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICoinsSetting } from '@shared/models/coins/coins-setting';
import { ICoinsTrigger } from '@shared/models/coins/coins-trigger';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'chirpy-earn-coins-table',
  styleUrls: ['./chirpy-earn-coins-table.component.scss'],
  templateUrl: './chirpy-earn-coins-table.component.html'
})
export class ChirpyEarnCoinsTableComponent {
  options$: Observable<ICoinsTrigger[]>;

  constructor(private analyticsService: AnalyticsService, private appOptionsService: AppOptionsService) {}

  ionViewWillEnter() {
    this.analyticsService.eventTrack(AnalyticsCategory.COINS, AnalyticsAction.COINS_VIEW_EARNING);
  }

  ngOnInit() {
    const manual$: Observable<ICoinsTrigger[]> = this.appOptionsService.getOptionsValues('coinsGivenFor', 'ALL').pipe(
      first(x => x != null),
      map(settings =>
        settings.map(x => {
          return { key: null, limit: 0, period: null, setting: x } as ICoinsTrigger;
        })
      )
    );

    const automatic$: Observable<ICoinsTrigger[]> = this.appOptionsService.getOptionsValues('coinsTriggers', 'ALL').pipe(first(x => x != null));

    this.options$ = combineLatest(manual$, automatic$).pipe(
      map(
        ([manual, automatic]) => [...manual, ...automatic].sort((a, b) => b.setting.value - a.setting.value).filter(x => x.setting.value != 0 && x.setting.isHidden !== 'true') // TODO: Change admin settings-edit to save "isHidden" as a boolean
      )
    );
  }
}
