import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  masterSubscription: Subscription;

  add(subscription: Subscription) {
    if (this.masterSubscription) {
      this.masterSubscription.add(subscription); //TODO: Still needed? Want to use authService.userProfileObserver as masterSubscription since it is long running
    } else {
      this.masterSubscription = subscription;
    }
  }

  clearSubscription(subscription: Subscription) {
    if (subscription) {
      this.remove(subscription);
      subscription.unsubscribe();
    }
  }

  remove(subscription: Subscription) {
    if (this.masterSubscription) this.masterSubscription.remove(subscription);
  }

  setMasterSubscription(subscription: Subscription) {
    this.unsubscribeAll();
    this.masterSubscription = subscription;
  }

  unsubscribeAll() {
    if (this.masterSubscription) this.masterSubscription.unsubscribe();
  }
}
