import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Gender } from '@shared/constants/gender';
import { IListingImage } from '@shared/models/image/listing-image';
import { IMarketplaceListing } from '@shared/models/marketplace/marketplace-listing';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EnforceProfileService } from '@shared/services/enforce-profile.service';
import { MarketplaceService } from '@shared/services/marketplace/marketplace.service';
import { ToastService } from '@shared/services/toast.service';
import { UIService } from '@shared/services/ui.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { MarketplaceEditPresenter } from './marketplace-edit.presenter';

@Component({
  selector: 'app-marketplace-edit',
  styleUrls: ['./marketplace-edit.component.scss'],
  templateUrl: './marketplace-edit.component.html',
  viewProviders: [MarketplaceEditPresenter]
})
export class MarketplaceEditComponent implements OnInit {
  get CONSTANTS() {
    return this.constantsService.constants.MARKETPLACE.EDIT;
  }

  get DISCLAIMER() {
    return this.constantsService.constants.MARKETPLACE.disclaimer;
  }

  get form() {
    return this.marketplaceEditPresenter.form;
  }

  get GENDERS() {
    return this.constantsService.constants.MARKETPLACE.GENDERS;
  }

  get isEdit(): boolean {
    return this.marketplaceListing != null && this.marketplaceListing.uid != null;
  }

  get MARKETPLACE_CATEGORIES$(): BehaviorSubject<string[]> {
    return this.marketplaceService.marketplaceCategories;
  }

  get MARKETPLACE_EXPIRY() {
    return this.marketplaceService.getMarketplaceExpiry();
  }

  get terms() {
    return this.marketplaceEditPresenter.form.controls.terms;
  }

  get title(): string {
    return this.isEdit ? this.CONSTANTS.updatePageHeading : this.CONSTANTS.createPageHeading;
  }

  get updateButtonText(): string {
    return this.isEdit ? this.CONSTANTS.updateButtonText : this.CONSTANTS.createButtonText;
  }

  canShowRenew: boolean = false;
  interfaceOptions: any = { cssClass: 'wide-select' };
  MAX_PHOTOS: number = 1;
  marketplaceListing: IMarketplaceListing = {
    approved: false,
    categories: [],
    description: '',
    deleteDate: 0,
    expiry: 0,
    location: '',
    photos: {},
    price: '',
    published: false,
    startDate: 0,
    terms: {},
    title: ''
  } as IMarketplaceListing;
  showGenderFilter: boolean = false;
  user: UserObject;

  constructor(
    private authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private enforceProfileService: EnforceProfileService,
    public marketplaceEditPresenter: MarketplaceEditPresenter,
    private marketplaceService: MarketplaceService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private uiService: UIService
  ) {}

  getDateFormat(deleteDate: number) {
    return this.dateTimeService.getDateFormat(deleteDate);
  }

  getNumberOfRows() {
    // display textarea with 8 rows on tablets and bigger. this fits perfectly on iPad landscape, and within bounds of most desktop screens.
    // display textarea with 5 rows on mobile phones.
    return this.uiService.isMediumDisplay ? 8 : 5;
  }

  goBack() {
    this.router.navigate([`/marketplace`]);
  }

  hideListing() {
    this.marketplaceService.hideListing(this.marketplaceListing.uid);
  }

  ionViewWillEnter() {
    this.enforceProfileService.enforce();
  }

  ionViewWillLeave() {
    // The MarketplaceEditComponent with uid "new" is not destroyed when the form is submitted
    this.form.reset(); // this clears the form data
    this.marketplaceListing.uid = null; // this clears the value of isEdit
  }

  ngOnInit() {
    const uid = this.route.snapshot.paramMap.get('id');
    this.showGenderFilter = this.CONSTANTS.showGenderFilter;
    this.MAX_PHOTOS = this.CONSTANTS.maxPhotos;
    this.marketplaceEditPresenter.init(this.CONSTANTS.terms);
    this.authService._userProfileSubject
      .pipe(
        first(x => x != null),
        switchMap(user => {
          this.user = user;

          // creating a new listing
          if ('new' === uid) {
            return this.marketplaceService.canAddNewListing(user.uid).pipe(
              first(x => x != null),
              tap(canAdd => {
                if (canAdd) {
                  this.initialiseListing(user);
                } else {
                  this.toastService.presentToast(`Sorry, you already have the maximum number of listings`);
                  this.router.navigate(['/marketplace']);
                }
              })
            );
          } else {
            return this.marketplaceService.getMarketplaceListing(uid).pipe(
              first(),
              tap((marketplaceListing: IMarketplaceListing) => {
                // Shouldn't happen; if it does, create a new listing
                if (marketplaceListing == null) {
                  this.initialiseListing(user);
                  return;
                }

                this.marketplaceListing = marketplaceListing;
                const THREE_DAYS_IN_MILLISECONDS = 3 * 24 * 60 * 60 * 1000;
                this.canShowRenew = this.marketplaceListing.deleteDate > 0 && this.marketplaceListing.deleteDate - this.dateTimeService.getDateTime() < THREE_DAYS_IN_MILLISECONDS;
                this.marketplaceEditPresenter.setValue(this.marketplaceListing);
              })
            );
          }
        })
      )
      .subscribe();
  }

  publishListing() {
    this.marketplaceService.publishListing(this.marketplaceListing.uid, this.marketplaceListing.approved);
  }

  updateMarketplaceListing() {
    // merge form values with marketplaceListing
    let formValue = this.marketplaceEditPresenter.marketplaceListing();
    // Delete any removed images
    const oldKeys = Object.keys(this.marketplaceListing.photos || {});
    let photosToRemove: IListingImage[] = [];
    if (oldKeys.length > 0) {
      const newKeys = Object.keys(formValue.photos || {});
      const keysToRemove = oldKeys.filter(x => !newKeys.includes(x));
      for (const key of keysToRemove) {
        photosToRemove.push(Object.assign({}, this.marketplaceListing.photos[key]));
      }
    }

    Object.assign(this.marketplaceListing, formValue);
    this.marketplaceService.updateMarketplaceListing(this.user, this.marketplaceListing, this.CONSTANTS.terms, photosToRemove);
  }

  private initialiseListing(user: UserObject) {
    this.marketplaceListing.country = user.country;
    this.marketplaceListing.gender = this.GENDERS;
    this.marketplaceListing.location = user.locality;
    this.marketplaceListing.memberId = user.uid;
    this.marketplaceListing.memberName = user.displayName;
    this.marketplaceListing.terms = this.CONSTANTS.terms.reduce((output, x) => {
      output[x.id] = false;
      return output;
    }, {});
    this.marketplaceEditPresenter.setValue(this.marketplaceListing);
  }
}
