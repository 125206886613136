import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class BaseApprovalPresenter {
  form = this.formBuilder.group({
    entries: new FormGroup({})
  });

  constructor(private formBuilder: FormBuilder) {}

  init(entriesData: any[]) {
    const entriesGroup = this.form.controls.entries as FormGroup;

    // Remove existing controls
    let controls = Object.keys(entriesGroup.controls || {});
    if (controls.length > 0) {
      for (const control of controls) {
        entriesGroup.removeControl(control);
      }
    }

    // Dynamically add entries and conditions checkboxes
    entriesData.forEach(entry => {
      entriesGroup.addControl(entry.uid, new FormControl(false));
    });
  }

  getValue() {
    return this.form.value;
  }

  setValue(data: any) {
    this.form.setValue({
      entries: data.entries
    });
  }
}
