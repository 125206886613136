import { Component, Input, OnInit } from '@angular/core';
import { ITrip } from '@shared/models/trips/trip';
import { ConstantsService } from '@shared/services/constants.service';
import { TripService } from '@shared/services/trips/trip.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'chirpy-event-cards',
  templateUrl: './chirpy-event-cards.component.html',
  styleUrls: ['./chirpy-event-cards.component.scss']
})
export class ChirpyEventCardsComponent implements OnInit {
  get ASSETS() {
    return this.constantsService.constants.ASSETS;
  }

  @Input() groupId: string = '';
  events$: Observable<ITrip[]>;
  noEventsMessage: string = 'No trips scheduled at the moment.';

  constructor(private constantsService: ConstantsService, private tripService: TripService) {}

  getImage(trip: ITrip): string {
    return this.tripService.getImage(trip, this.ASSETS.defaultImage);
  }

  getInterestedAndGoing(trip: ITrip): string {
    return this.tripService.numberInterestedAndGoing(trip);
  }

  ngOnInit() {
    const isAdmin = false;
    this.events$ = this.tripService.getGroupTrips(isAdmin, this.groupId);
  }
}
