import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { GroupService } from '@shared/services/groups/group.service';

@Component({
  selector: 'app-pix-detail',
  templateUrl: './pix-detail.page.html',
  styleUrls: ['./pix-detail.page.scss']
})
export class PixDetailPage implements OnInit {
  canManageGroup: boolean = false;
  collectionId: string;
  collectionTitle: string;
  collectionType: MediaCollectionType;
  photoId: string;

  constructor(private route: ActivatedRoute, private groupService: GroupService) {}

  ngOnInit() {
    const photoId = this.route.queryParams.subscribe(queryParams => {
      this.photoId = queryParams['photo'] || '';
    });

    // Only need to get collectionId/Title once, because a change in either qualifies as a new route, which generates a new instance of this component
    this.collectionId = this.route.snapshot.paramMap.get('id');
    this.collectionTitle = this.route.snapshot.paramMap.get('title');
    if (this.collectionTitle) this.collectionTitle = this.collectionTitle.replace(/(%20|\+)/g, ' ');
    this.collectionType = MediaCollectionType.PUBLIC;

    // For the moment each group has one collection. If we allow multiple collections, needs to use different way of specifying who has admin privileges
    this.groupService.canManageGroup$(this.collectionId).subscribe(canManageGroup => {
      this.canManageGroup = canManageGroup;
    });
  }
}
