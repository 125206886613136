import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageType } from '@shared/constants/message-type';
import { IMessage } from '@shared/models/messages/message';
import { IMessageData } from '@shared/models/messages/message-data';
import { MessageService } from '@shared/services/messages/message.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'chirpy-chit-chat-replies',
  styleUrls: ['./chirpy-chit-chat-replies.component.scss'],
  templateUrl: './chirpy-chit-chat-replies.component.html'
})
export class ChirpyChitChatRepliesComponent {
  childThreadId: string;
  @Input() groupId: string;
  @Input() groupName: string;
  @Input() isAdmin: boolean;
  @Input() level: number;
  @Input() message: IMessage;
  @Input() messageType: MessageType;
  replies$: Observable<IMessage[]>;
  showAllReplies: boolean = false;
  showReplyForm: boolean = false;
  @Input() threadId: string;
  @Input() userId: string;

  constructor(private messageService: MessageService) {}

  ngOnInit() {
    this.replies$ = this.messageService.getReplies(this.threadId, this.message.uid);
    this.childThreadId = `${this.threadId}/Messages/${this.message.uid}`;
  }

  onDelete(event: any) {
    // Reply is deleted within chirpy-chit-chat-detail component, need to update lastReply & replyCount on parent
    this.messageService
      .getLastMessage(this.childThreadId)
      .pipe(first(x => !!x))
      .subscribe((messages: IMessage[]) => {
        const decrementReplyCount = -1;
        const message: IMessage = messages.length > 0 ? messages[0] : null;
        // TODO: Using an older date won't trigger a refresh of the page, but means the message may now be out of order in the chat
        const dateTimeLastUpdated = Date.now();
        const memberIds = []; // We don't add or remove any memberIds when deleting, as (a) this seldom happens, and (b) would require reading all other replies to the message to determine if the deleted message was the only one by that author
        this.messageService.updateParentMessage(this.childThreadId, message, dateTimeLastUpdated, memberIds, decrementReplyCount);
      });
  }

  onModerate(data: any) {
    // Reply is moderated within chirpy-chit-chat-detail component, need to update lastReply field on parent
    delete data.uid;
    // NB Don't use updateParentMessage, because that updates other fields and doesn't use merge: true
    this.messageService.updateMessage(this.threadId, this.message.uid, { dateTimeLastUpdate: Date.now(), lastReply: data });
  }

  onReply() {
    this.showReplyForm = true;
  }

  onSendMessage(messageData: IMessageData) {
    const threadType = null;
    const oldIncludeMembers = this.message.memberIds ? this.message.memberIds : [this.message.senderId]; // this.message.senderId only needs to be added with the first reply.
    const includeMembers = Array.from(new Set([...oldIncludeMembers, this.userId])); // add sender of current message and remove duplicates
    const emailData = { includeMembers: includeMembers, messageAuthorId: this.message.senderId, messageAuthorName: this.message.name };
    let replyType;
    switch (this.messageType) {
      case MessageType.CATCHUP_NOTE:
        Object.assign(emailData, { catchupId: this.groupId, catchupName: this.groupName });
        replyType = MessageType.CATCHUP_REPLY;
        break;

      case MessageType.CHIT_CHAT:
        Object.assign(emailData, { groupId: this.groupId, groupName: this.groupName });
        replyType = MessageType.REPLY;
        break;

      default:
        break;
    }
    this.messageService.createMessage(messageData, this.childThreadId, replyType, threadType, emailData);
    this.showReplyForm = false;
  }

  onShowReplies() {
    this.showAllReplies = true;
  }
}
