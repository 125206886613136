import { Injectable } from '@angular/core';
import { CoinsTransactionType } from '@shared/constants/coins-transaction-type';
import { IMemberLimit } from '@shared/models/limits/member-limit';
import { ICoinsTransaction } from '@shared/models/coins/coins-transaction';
import { ICoinsTrigger } from '@shared/models/coins/coins-trigger';
import { AnalyticsAction } from '@shared/services/analytics/analytics-action';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { AuthService } from '@shared/services/auth.service';
import { CoinsDatabase } from '@shared/services/coins/coins.database';
import { CoinsService } from '@shared/services/coins/coins.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { LimitService } from '@shared/services/limits/limit.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { firestore } from 'firebase/app';
import { Observable, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import FieldValue = firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class CoinsTriggerService {
  coinsTriggers: Record<AnalyticsAction, ICoinsTrigger>;
  subscription: Subscription;

  constructor(private appOptionsService: AppOptionsService, private coinsDatabase: CoinsDatabase, private coinsService: CoinsService, private dateTimeService: DateTimeService, private limitService: LimitService, private subscriptionService: SubscriptionService, private toastService: ToastService) {
    this.subscription = this.appOptionsService.getOptionsValues('coinsTriggers', 'ALL').subscribe(values => {
      this.coinsTriggers = {} as Record<AnalyticsAction, ICoinsTrigger>;
      for (const trigger of values) {
        this.coinsTriggers[trigger.key] = trigger;
      }
    });
    //this.subscriptionService.add(this.subscription); // TODO: For some reason, this causes the coinsTriggers not to be available on page load
  }

  hasReachedLimit(memberId: string, action: AnalyticsAction, readOnly: boolean = false): Promise<boolean> {
    const trigger: ICoinsTrigger = this.coinsTriggers[action];
    return this.limitService.hasReachedLimit(memberId, action, trigger);
  }

  hasReachedLimitReadOnly$(memberId: string, action: AnalyticsAction): Observable<boolean> {
    const trigger: ICoinsTrigger = this.coinsTriggers[action];
    return this.limitService.hasReachedLimitReadOnly$(memberId, action, trigger);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.subscription);
  }

  trigger(memberId: string, memberName: string, action: AnalyticsAction, customAmount: number) {
    if (!this.coinsTriggers[action]) return;
    const trigger = this.coinsTriggers[action];

    if (trigger.limit > 0) {
      this.hasReachedLimit(memberId, action).then(hasReached => {
        if (!hasReached) {
          this.giveCoins(memberId, memberName, trigger, customAmount);
        }
      });
    } else {
      this.giveCoins(memberId, memberName, trigger, customAmount);
    }
  }

  private giveCoins(memberId: string, memberName: string, trigger: ICoinsTrigger, customAmount: number) {
    const amount = customAmount > 0 ? +customAmount : +trigger.setting.value;
    const coinsTransaction: ICoinsTransaction = {
      amount: amount,
      date: this.dateTimeService.getDateTime(),
      memberId: memberId,
      memberName: memberName || '(not set)', // sometimes members don't have a displayName set
      description: trigger.setting.label,
      staffId: '',
      staffName: 'Automatic',
      type: CoinsTransactionType.ADD
    };
    this.coinsService.addCoinsTransaction(coinsTransaction);
    const message = `You have earned ${amount} coins!`;
    this.toastService.presentToast(message);
  }
}
