import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '@shared/services/constants.service';
import { MessageService } from '@shared/services/messages/message.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { BaseApprovalComponent } from '../base-approval/base-approval.component';
import { BaseApprovalPresenter } from '../base-approval/base-approval.presenter';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-approve-messages',
  styleUrls: ['../base-approval/base-approval.component.scss'],
  templateUrl: '../base-approval/base-approval.component.html'
})
export class ApproveMessagesComponent extends BaseApprovalComponent {
  approveButtonText = 'Approve';
  itemDetails = [
    {
      field: 'threadName',
      icon: 'mail-open',
      type: 'heading'
    },
    {
      field: 'name',
      icon: 'person',
      type: 'text'
    },
    {
      field: 'dateTimeSent',
      icon: 'clock',
      type: 'date-time'
    },
    {
      field: 'content',
      icon: '',
      type: 'long-text'
    },
    {
      field: 'photos',
      icon: '',
      type: 'photos'
    }
  ];
  items$: Observable<any[]>;
  rejectButtonText = 'Delete';
  routerPrefix: string;
  startSlot = 'avatar';
  title: string;

  constructor(baseApprovalPresenter: BaseApprovalPresenter, subscriptionService: SubscriptionService, toastService: ToastService, private messageService: MessageService) {
    super(baseApprovalPresenter, subscriptionService, toastService);
  }

  loadItems() {
    this.items$ = this.messageService.getHeldMessagesForApproval().pipe(
      map(messages =>
        messages.map(x => {
          // TODO: Make the names of these fields configurable?
          x.memberId = x.senderId; // so avatar loads
          x.date = x.dateTimeSent; // for correct ordering
          return x;
        })
      )
    );
  }

  setRouterLinks() {
    this.routerField = `senderId`;
    this.routerPrefix = `/admin/member-detail`;
    this.title = `Approve Messages`;
  }

  onApproveHandler(item: any) {
    // not part of IMessage, only needed on this page
    delete item.date;
    delete item.memberId;

    this.messageService.releaseHeldMessage(item);
  }

  onRejectHandler(item: any) {
    this.messageService.deleteHeldMessage(item.uid);
  }
}
