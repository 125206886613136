import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryKey } from '@shared/constants/country';
import { DateTimeService } from '@shared/services/date-time.service';
import { combineLatest, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsEditPresenter {
  form = this.formBuilder.group({});
  hasObjectKeys: boolean;

  addControl(key: string, values: string[] | any[]) {
    const array = this.form.controls[key] as FormArray;
    if (!this.hasObjectKeys) {
      values.forEach(value => array.push(new FormControl(value)));
    } else {
      values.forEach(value => {
        const group = new FormGroup({});
        for (let objectKey in value) {
          const objectValue = value[objectKey];
          if (typeof objectValue !== 'object') {
            group.addControl(objectKey, new FormControl(objectValue));
          } else {
            const nestedGroup = new FormGroup({});
            for (let nestedKey in objectValue) {
              nestedGroup.addControl(nestedKey, new FormControl(objectValue[nestedKey]));
            }
            group.addControl(objectKey, nestedGroup);
          }
        }
        array.push(group);
      });
    }
  }

  addNewControl(key: string, dataKeys: Record<string, any>) {
    let value: string | any = '';
    if (this.hasObjectKeys) {
      value = dataKeys;
    }
    this.addControl(key, [value]);
  }

  constructor(private formBuilder: FormBuilder) {}

  getValue(key: string, index: number): string | any {
    const array = this.form.controls[key] as FormArray;
    return array.value[index];
  }

  getValues(key: string): string[] | any[] {
    const array = this.form.controls[key] as FormArray;
    return array.value;
  }

  init(options: Record<CountryKey, string[] | any[]>, dataKeys: Record<string, any>) {
    this.hasObjectKeys = Object.keys(dataKeys).length > 0;

    // Remove existing controls
    let controls = Object.keys(this.form.controls || {});
    if (controls.length > 0) {
      for (const control of controls) {
        this.form.removeControl(control);
      }
    }

    const keys = Object.keys(options || {});

    for (const key of keys) {
      if (Array.isArray(options[key])) {
        this.form.addControl(key, new FormArray([]));
        this.addControl(key, options[key]);
      }
    }
  }

  removeControl(key: string, index: number) {
    const array = this.form.controls[key] as FormArray;
    array.removeAt(index);
  }

  setValue(key: string, index: number, value: string) {
    const array = this.form.controls[key] as FormArray;
    array.at(index).setValue(value);
  }

  setValues(key: string, values: string[]) {
    const array = this.form.controls[key] as FormArray;
    array.setValue(values);
  }

  settings() {
    return this.form.value;
  }
}
