import { Component } from '@angular/core';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { ConstantsService } from '@shared/services/constants.service';

@Component({
  selector: 'app-marketplace-intro',
  styleUrls: ['./marketplace-intro.page.scss'],
  templateUrl: './marketplace-intro.page.html'
})
export class MarketplaceIntroPage {
  get CONSTANTS() {
    return this.constantsService.constants.MARKETPLACE.INTRO;
  }

  get DISCLAIMER() {
    return this.constantsService.constants.MARKETPLACE.disclaimer;
  }

  constructor(private analyticsService: AnalyticsService, private constantsService: ConstantsService) {}

  ionViewWillEnter() {
    this.analyticsService.eventTrack(AnalyticsCategory.MARKETPLACE, AnalyticsAction.MARKETPLACE_VIEW_INTRO);
  }

  onClick(option: any) {
    this.analyticsService.eventTrack(AnalyticsCategory.MARKETPLACE, AnalyticsAction.MARKETPLACE_INTRO_BUTTON, option.heading);
  }
}
