import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ChirpyRedeemCoinsFormPresenter {
  form = this.formBuilder.group({
    address: [''],
    message: [''],
    name: [''],
    option: ['', Validators.required],
    phone: ['']
  });

  constructor(private formBuilder: FormBuilder) {}

  patchValue(data: any) {
    this.form.patchValue(data);
  }
}
