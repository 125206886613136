import { Injectable } from '@angular/core';
import { ALL_COUNTRIES, CountryKey } from '@shared/constants/country';
import { IAppOptions } from '@shared/models/app-options';
import { IEdit } from '@shared/models/edit';
import { IRenameSettings } from '@shared/models/rename-settings';
import { IWhereCondition } from '@shared/models/where-condition';
import { AppOptionsDatabase } from './app-options.database';
import firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppOptionsService {
  constructor(private appOptionsDatabase: AppOptionsDatabase) {}

  getOptions(uid: string): Observable<IAppOptions> {
    return this.appOptionsDatabase.getOptions(uid);
  }

  getOptionsValues(uid: string, country: CountryKey): Observable<any[]> {
    return this.getOptions(uid).pipe(
      map((options: IAppOptions) => {
        if (options == null || options.data == null || Object.keys(options.data).length === 0) {
          return [];
        } else if (options.data[ALL_COUNTRIES]) {
          return options.data[ALL_COUNTRIES];
        } else if (options.data[country]) {
          return options.data[country];
        } else {
          return [];
        }
      })
    );
  }

  renameArrayValues(values: Record<string, IEdit[]>, renameSettings: IRenameSettings) {
    let renameQuery: IWhereCondition = Object.assign({}, renameSettings.query);
    for (const key in values) {
      for (const edit of values[key]) {
        renameQuery.value = edit.from;
        const whereConditions = [renameQuery];
        if (key !== ALL_COUNTRIES) {
          whereConditions.push({ field: 'country', operator: '==', value: key });
        }
        const fieldName = renameQuery.field as string;
        const dataFrom = { [fieldName]: firebase.firestore.FieldValue.arrayRemove(edit.from) };
        const dataTo = { [fieldName]: firebase.firestore.FieldValue.arrayUnion(edit.to) };
        this.appOptionsDatabase.renameValue(renameSettings.collection, whereConditions, dataFrom, dataTo);
      }
    }
  }

  renameMapValues(values: Record<string, IEdit[]>, renameSettings: IRenameSettings) {
    let renameQuery: IWhereCondition = Object.assign({}, renameSettings.query);
    for (const key in values) {
      for (const edit of values[key]) {
        renameQuery.field = `${renameSettings.query.field}.${edit.from}`;
        const whereConditions = [renameQuery];
        if (key !== ALL_COUNTRIES) {
          whereConditions.push({ field: 'country', operator: '==', value: key });
        }
        const fieldName = renameSettings.query.field as string;
        const data = {
          [fieldName]: {
            [edit.from]: firebase.firestore.FieldValue.delete(),
            [edit.to]: true
          }
        };
        this.appOptionsDatabase.renameValue(renameSettings.collection, whereConditions, data);
      }
    }
  }

  renameStringValues(values: Record<string, IEdit[]>, renameSettings: IRenameSettings) {
    // This handles the very specific case where the IAppOptions value is { id: XX, type: YY}, but only the id is stored in a string field on another collection
    // TODO: Also handle renaming category field in mediaCollection. Currently would have to do this with a script
    let renameQuery: IWhereCondition = Object.assign({}, renameSettings.query);
    for (const key in values) {
      for (const edit of values[key]) {
        renameQuery.value = edit.from.id;
        const whereConditions = [renameQuery];
        if (key !== ALL_COUNTRIES) {
          whereConditions.push({ field: 'country', operator: '==', value: key });
        }
        const fieldName = renameQuery.field as string;
        const data = { [fieldName]: edit.to.id };
        this.appOptionsDatabase.renameValue(renameSettings.collection, whereConditions, data);
      }
    }
  }

  renameValues(values: Record<string, IEdit[]>, renameSettings: IRenameSettings) {
    if (renameSettings.type === 'array') {
      this.renameArrayValues(values, renameSettings);
    } else if (renameSettings.type === 'map') {
      this.renameMapValues(values, renameSettings);
    } else if (renameSettings.type === 'string') {
      this.renameStringValues(values, renameSettings);
    }
  }

  updateOptions(uid: string, options: IAppOptions) {
    return this.appOptionsDatabase.updateOptions(uid, options);
  }
}
