import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { AdvertiserDetailPage } from './advertiser-detail/advertiser-detail.page';
import { AdvertiserEditPage } from './advertiser-edit/advertiser-edit.page';
import { AdvertiserListPage } from './advertiser-list/advertiser-list.page';
import { AdvertiserSearchComponent } from './components/advertiser-search/advertiser-search.component';
import { AdvertisersRoutingModule } from './advertisers-routing.module';
import { AnnouncementEditPage } from './announcement-edit/announcement-edit.page';

@NgModule({
  imports: [AdvertisersRoutingModule, SharedModule],
  declarations: [AdvertiserDetailPage, AdvertiserEditPage, AdvertiserSearchComponent, AdvertiserListPage, AnnouncementEditPage],
  entryComponents: [AdvertiserSearchComponent]
})
export class AdvertisersModule {}
