import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { IMedia } from '@shared/models/media/media';

@Injectable({
  providedIn: 'root'
})
export class ChirpyPhotoUploadPresenter {
  form = this.formBuilder.group({
    photos: [{}]
  });

  constructor(private formBuilder: FormBuilder) {}

  photo() {
    return this.form.value;
  }

  patchValue(data: any) {
    this.form.patchValue(data);
  }

  setValue(photo: IMedia) {
    // Do we need this? This is an upload component, not an edit component
    this.form.setValue({
      photos: {}
    });
  }
}
