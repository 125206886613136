import { Component, ElementRef, forwardRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController, Platform } from '@ionic/angular';
import { ChirpyTakePhotoComponent } from '@shared/components/chirpy-take-photo/chirpy-take-photo.component';
import { CustomControl } from '@shared/components/custom-control';
import { PhotoSize } from '@shared/constants/photo-size';
import { IListingImage } from '@shared/models/image/listing-image';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { EnvironmentService } from '@shared/services/environment.service';
import { ToastService } from '@shared/services/toast.service';
import { WebcamUtil } from 'ngx-webcam';
import { skipWhile, take } from 'rxjs/operators';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ChirpyAddPhotoComponent),
  multi: true
};

@Component({
  selector: 'chirpy-add-photo',
  providers: [CUSTOM_VALUE_ACCESSOR],
  templateUrl: './chirpy-add-photo.component.html',
  styleUrls: ['./chirpy-add-photo.component.scss']
})
export class ChirpyAddPhotoComponent extends CustomControl implements OnInit {
  // To override the setter for the parent class we need to implement both the setter and getter
  get controlValue() {
    return this._controlValue;
  }

  set controlValue(val) {
    this._controlValue = val;
    this.propagateChange(this._controlValue);
    this.updateFlags();
  }

  ALLOWED_TYPES: string[] = ['image/gif', 'image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'];
  canUploadPhoto: boolean = false;
  fileCount: number = 0;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  hasCamera: boolean = false;
  hasPhotos: boolean = false;
  isUploadingPhoto: boolean = false;
  @Input() isWidget: boolean = false;
  @Input() maxPhotos: number = 3;
  @Output() photoCount = new EventEmitter();

  checkForCameras() {
    // dont let iPhone/iPad users take photos with the Take Photo component because iPhone/iPad
    // has native take photo functionality when you click Upload photo.
    if (this.platform.is('ios')) return;

    // Enable/Disable "Take Photo" button depending on whether camera is present.
    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
      this.hasCamera = mediaDevices && mediaDevices.length > 0;
    });
  }

  clear() {
    this.controlValue = {};
  }

  constructor(private domSanitizer: DomSanitizer, private platform: Platform, private modalController: ModalController, private toastService: ToastService) {
    super();
  }

  ngOnInit() {
    this.clear();
    this.checkForCameras();
  }

  onImageLoad() {
    this.isUploadingPhoto = false;
  }

  onRemovePhoto(key: string) {
    delete this._controlValue[key];
    this.propagateChange(this._controlValue);
    this.updateFlags();
  }

  async onUploadFile(event) {
    // Prevent loading spinner from showing when user does the following:
    // 1. "Upload File" button
    // 2. Select a file (uploads automatically)
    // 3. "Upload File" button again
    // 4. Cancel
    if (event.target.files.length === 0) return;

    const file = event.target.files[0];
    await this.savePhoto(file);

    // Remove last uploaded file from file control.
    event.target.value = null;
  }

  openFileChooser() {
    this.fileInput.nativeElement.click();
  }

  async presentTakePhotoModal() {
    const eventEmitter = new EventEmitter<any>();
    eventEmitter.subscribe($event => {
      modal.dismiss();
      return this.savePhoto($event);
    });

    const modal = await this.modalController.create({
      component: ChirpyTakePhotoComponent,
      cssClass: 'chirpy-take-photo__modal',
      componentProps: {
        takePhoto: eventEmitter
      }
    });

    return await modal.present();
  }

  async savePhoto(file) {
    this.isUploadingPhoto = true;

    // Show error message for invalid file type, rather than spinner of doom
    // NB svg and webp are omitted from the member-facing message for simplicity
    if (!this.ALLOWED_TYPES.includes(file.type)) {
      const message = `Your image is not in an accepted file format. Please try using a .png, .jpg, or .gif file.`;
      this.toastService.presentToast(message);
      this.isUploadingPhoto = false;
      return;
    }
    const tempUrl = URL.createObjectURL(file);

    if (tempUrl.startsWith('blob:http')) {
      const imageKeys = Object.keys(this.controlValue);
      const order = imageKeys.length;
      const fileName = `image${this.fileCount + 1}`;
      this.controlValue[fileName] = {
        file: file,
        order: order,
        photoURL: this.domSanitizer.bypassSecurityTrustUrl(tempUrl) as string
      };
    } else {
      this.isUploadingPhoto = false;
    }
    this.updateFlags();
    this.writeValue(this.controlValue);
  }

  private updateFlags() {
    const imageKeys = Object.keys(this.controlValue || {});
    const imageCount = imageKeys.length;
    this.photoCount.emit(imageCount);
    this.canUploadPhoto = imageCount < this.maxPhotos;
    this.hasPhotos = imageCount > 0;
    // Use the highest filenumber seen + 1 for the next file so that names are unique
    // Include fileCount in the max() statement to persist this number even all all current images are removed
    if (imageKeys.length > 0) {
      this.fileCount = Math.max(this.fileCount, ...imageKeys.map(x => +x.replace('image', '')));
    }
  }
}
