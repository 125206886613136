import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChirpyMemberSearchComponent } from '@shared/components/chirpy-member-search/chirpy-member-search.component';
import { IContact } from '@shared/models/messages/contact';
import { AuthService } from '@shared/services/auth.service';
import { ContactService } from '@shared/services/messages/contact.service';
import { MessageService } from '@shared/services/messages/message.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { skipWhile } from 'rxjs/operators';
import { ComposeService } from './compose.service';

@Component({
  selector: 'compose',
  templateUrl: './compose.component.html',
  styleUrls: ['compose.component.scss']
})
export class ComposeComponent {
  get canSendMessage() {
    return !this.authService.isImpersonating;
  }

  get composeForm() {
    return this.service.composeForm;
  }

  get isCreatingGroup() {
    return this.service.isCreatingGroup;
  }

  get member() {
    return this.service.member;
  }

  get membersToAdd() {
    return this.service.filterSearchResults(this.memberSearchComponent.searchResults);
  }

  get membersToRemove() {
    const members: Record<string, string> = {};
    this.service.recipients.reduce((result, item) => {
      result[item.memberId] = item.memberName;
      return result;
    }, members);

    return members;
  }

  message: string = '';
  noSearch: boolean = false;
  @ViewChild(ChirpyMemberSearchComponent, { static: true }) memberSearchComponent: ChirpyMemberSearchComponent;

  constructor(private authService: AuthService, private contactService: ContactService, private messageService: MessageService, private toastService: ToastService, private userService: UserService, private route: ActivatedRoute, private router: Router, private service: ComposeService) {}

  clearRecipients() {
    this.service.recipients.length = 0;
  }

  ionViewWillEnter() {
    // ngOnInit doesn't trigger if you come back from a navigation stack, ionViewWillEnter / ionViewDidEnter will.
    this.memberSearchComponent.clearSearch();

    this.clearRecipients();

    this.route.paramMap.subscribe(params => {
      const memberId = params.get('id');
      if (memberId != null) {
        this.noSearch = true;
        this.service.addMemberToThread(memberId);
      } else {
        this.noSearch = false;
        // don't show the contacts if you've clicked a SEND MESSAGE button to open this screen with the member pre-filled.
        this.populateSearchResultsWithContacts();
      }
    });

    // TODO: Do we need to subscribe to queryParamMap
    this.message = this.route.snapshot.queryParamMap.get('message');
    this.memberSearchComponent.focusSearchBar();
  }

  onAddMemberToThread({ memberId, memberName }) {
    this.service.addMemberToThread(memberId);
  }

  onRemoveMemberFromThread({ memberId, memberName }) {
    this.service.removeMemberFromThread(memberId);
  }

  onSendMessage({ text, media }) {
    this.service
      .getThreadIdForMembers()
      .pipe(skipWhile(x => x == null))
      .subscribe(threadId => {
        const promises = [];
        promises.push(this.service.sendMessage({ text, media }, threadId));
        promises.push(this.openThread(threadId));

        return Promise.all(promises);
      });
  }

  openThread(threadId: string) {
    return this.router.navigate(['/messages/threads/', threadId]);
  }

  populateSearchResultsWithContacts() {
    this.contactService.getContacts().subscribe((contacts: IContact[]) => {
      const searchResults = {};
      contacts.forEach((contact: IContact) => {
        searchResults[contact.relatedId] = contact.name;
      });

      this.memberSearchComponent.searchResults = searchResults;
    });
  }

  validate() {
    if (!this.service.validateGroupName()) {
      return false;
    }

    if (this.service.recipients.length === 0) {
      this.toastService.presentToast(`Sorry, you need to choose someone to send the message to.`);
      return false;
    }

    return true;
  }
}
