import { Injectable } from '@angular/core';
import { IThreadOpenStatus } from '@shared/models/messages/thread-open-status';
import { BaseDatabase } from '@shared/services/base.database';

@Injectable({
  providedIn: 'root'
})
export class ThreadOpenDatabase extends BaseDatabase {
  getThreadStatusForMember(memberId: string) {
    return this.afs
      .collection('messagesMemberThreadsStatus')
      .doc(memberId)
      .get()
      .toPromise();
  }

  loadOpenThreads(uid: string) {
    return this.afs
      .collection('messagesMemberThreadsStatus')
      .doc(uid)
      .valueChanges();
  }

  updateThreadStatus(uid: string, threadStatus: Record<string, IThreadOpenStatus>) {
    return this.updateDocument('messagesMemberThreadsStatus', uid, threadStatus, true);
  }
}
