import { Directive } from '@angular/core';
import { IonTextarea } from '@ionic/angular';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[chirpyIonTextArea]'
})
export class ChirpyIonTextAreaDirective {
  constructor(private ionTextarea: IonTextarea) {
    this.performBackwardsTypingWorkaround();
  }

  // Members have reported that when typing a message that the text appears backwards
  performBackwardsTypingWorkaround() {
    // This solution was based on code from: https://github.com/ionic-team/ionic/issues/18984
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    if (isIEOrEdge) {
      this.ionTextarea.getInputElement().then((el: HTMLTextAreaElement) => {
        if (el.value != null && el.value.length > 0) return;
        el.value = 'a';
        el.setSelectionRange(el.value.length, el.value.length);
        el.value = '';
      });
    }
  }
}
