export enum AnalyticsAction {
  // displayName is default label
  // add properties to this class in alphabetical order.

  ACCOUNT_CHANGE_PASSWORD = 'change-password',
  ACCOUNT_CHANGE_PASSWORD_ERROR = 'change-password-error',
  ACCOUNT_MANAGE_SUBSCRIPTION = 'manage-subscription',

  //advertiser name
  ADVERTISERS_ADD_ANNOUNCEMENT = 'add-advertiser-announcement',
  ADVERTISERS_CLEAR_SEARCH = 'clear-advertiser-search',
  ADVERTISERS_CLEAR_SEARCH_CRITERION = 'clear-advertiser-search-criterion', // eventLabel = value, type = criterion
  ADVERTISERS_FOLLOW = 'follow-advertiser',
  ADVERTISERS_SEARCH = 'search-advertisers', // location, category, country
  ADVERTISERS_SEND_MESSAGE = 'send-advertiser-message',
  ADVERTISERS_UNFOLLOW = 'unfollow-advertiser',
  ADVERTISERS_UPDATE_ANNOUNCEMENT = 'update-advertiser-announcement',
  ADVERTISERS_UPDATE_LISTING = 'update-advertiser-listing',
  ADVERTISERS_VIEW_LISTINGS = 'view-advertiser-list',
  ADVERTISERS_VIEW_LISTING_DETAIL = 'view-advertiser-listing-detail',
  ADVERTISERS_VIEW_OWN_LISTING_DETAIL = 'view-own-advertiser-listing-detail',

  AUTH_CLICK_SIGNUP = 'click-signup-link',
  AUTH_LOGIN = 'login',
  AUTH_LOGIN_ERROR = 'login-error',
  AUTH_LOGOUT = 'logout',
  AUTH_RESET_PASSWORD = 'reset-password',
  AUTH_RESET_PASSWORD_INVALID_EMAIL = 'reset-password-invalid-email',

  //(displayName rather than game id, because all of these are once per game)
  BINGO_GAME_OVER = 'bingo-game-over',
  BINGO_JOIN = 'join-bingo-game',
  BINGO_LEFT = 'leave-bingo-game',

  //catchup id
  CATCHUPS_ADD = 'add-catchup', // type = whether rsvp required
  CATCHUPS_ADD_INFO = 'add-catchup-attendee-info', // type = attendee or waitlist
  CATCHUPS_ADD_GUEST = 'add-catchup-attendee-info', // event_value = guest count
  CATCHUPS_ADD_PAYMENT = 'add-catchup-payment', // event_value = payment count
  CATCHUPS_ADD_TEMPLATE = 'add-catchup-template', // templateId, type = groupId
  CATCHUPS_ACCEPT_RSVP = 'accept-rsvp-to-catchup',
  CATCHUPS_CANCEL_RSVP = 'cancel-rsvp-to-catchup',
  CATCHUPS_CLEAR_SEARCH = 'clear-catchups-search',
  CATCHUPS_CLEAR_SEARCH_CRITERION = 'clear-catchups-search-criterion', // eventLabel = criterion value, type = criterion type
  CATCHUPS_DECLINE_RSVP = 'decline-rsvp-to-catchup',
  CATCHUPS_DELETE = 'delete-catchup',
  CATCHUPS_DELETE_GUEST = 'delete-catchup-guest', // (click on icon) event_value = guest count
  CATCHUPS_DELETE_PAYMENT = 'delete-catchup-payment', // event_value = payment count
  CATCHUPS_DELETE_TEMPLATE = 'delete-catchup-template', // templateId, type = groupId
  CATCHUPS_ERASE_GUEST = 'erase-catchup-guest', // (delete data and update) event_value = guest count
  CATCHUPS_MOVE_TO_WAITLIST = 'move-catchup-attendee-to-waitlist',
  CATCHUPS_REMOVE_RSVP = 'remove-rsvp-to-catchup',
  CATCHUPS_REMOVE_NON_ATTENDEE = 'remove-non-attendee-from-catchup',
  CATCHUPS_RSVP = 'rsvp-to-catchup',
  CATCHUPS_SEARCH = 'search-catchups', //region, show
  CATCHUPS_SEND_NOTE = 'send-catchup-note',
  CATCHUPS_SEND_NOTE_REPLY = 'send-catchup-note-reply',
  CATCHUPS_UPDATE = 'update-catchup',
  CATCHUPS_UPDATE_GUEST = 'update-catchup-guest', // event_value = guest count
  CATCHUPS_UPDATE_PAYMENT = 'update-catchup-payment', // event_value = payment count
  CATCHUPS_UPDATE_TEMPLATE = 'update-catchup-template', //templateId, type = groupId
  CATCHUPS_VIEW_ATTENDEES = 'view-catchup-attendees',
  CATCHUPS_VIEW_GUESTS = 'view-catchup-guests',
  CATCHUPS_VIEW_LISTINGS = 'view-catchups-list',
  CATCHUPS_VIEW_DETAILS = 'view-catchup-details',
  CATCHUPS_VIEW_PAYMENTS = 'view-catchup-payments',
  CATCHUPS_VIEW_WAITLIST = 'view-catchup-waitlist',

  COINS_CLAIM_CATCHUP = 'claim-catchup-coins', // catchup id
  COINS_CLAIM_CATCHUP_ERROR = 'claim-catchup-coins-error', // catchup id
  COINS_CLAIM_FIRST_CATCHUP = 'claim-first-catchup-coins', // catchup id
  COINS_CLAIM_SECOND_CATCHUP = 'claim-second-catchup-coins', // catchup id
  REVERSE_COINS_CLAIM_CATCHUP = 'reverse-claim-catchup-coins', // catchup id
  COINS_REDEEM = 'redeem-coins', // type = reward name
  COINS_SUBMIT_REFERRAL = 'submit-referral-form',
  COINS_VIEW_EARNING = 'view-coins-earning',
  COINS_VIEW_REWARDS = 'view-coins-rewards',
  COINS_VIEW_SUMMARY = 'view-coins-summary',
  COINS_VIEW_TRANSACTIONS = 'view-coins-transactions',

  //event uid
  EVENTS_ADD_GOING = 'going-to-event',
  EVENTS_ADD_INTEREST = 'interested-in-event',
  EVENTS_ADD_EVENT = 'add-event',
  EVENTS_CLEAR_SEARCH = 'clear-event-search',
  EVENTS_CLEAR_SEARCH_CRITERION = 'clear-event-search-criterion', // eventLabel = value, type = criterion
  EVENTS_DELETE = 'delete-event',
  EVENTS_REMOVE_INTEREST = 'not-interested-in-event',
  EVENTS_SEARCH = 'search-events', //country, place
  EVENTS_SUBMIT = 'submit-event',
  EVENTS_UPDATE = 'update-event-details',
  EVENTS_VIEW_DETAILS = 'view-event-details',
  EVENTS_VIEW_GOING = 'view-going-to-event',
  EVENTS_VIEW_INTERESTED = 'view-interested-in-event',
  EVENTS_VIEW_LISTINGS = 'view-events-list',

  FORMS_CONTACT = 'submit-contact-form',
  FORMS_HOST = 'submit-host-form',
  FORMS_GROUP = 'submit-group-form',
  FORMS_UPGRADE = 'submit-upgrade-form',
  FORMS_VERIFY = 'submit-verify-form',

  FUN_CLICK_LINK = 'click-fun-link', // url or title
  FUN_CLICK_WEBCAM = 'click-webcam-link',

  //group name
  GROUPS_ADD_MEMBER = 'add-group-member',
  GROUPS_ADD_NOTICE = 'add-group-notice', //groupId
  GROUPS_CLEAR_SEARCH = 'clear-groups-search',
  GROUPS_CLICK_LINK = 'click-groups-link', // link type, group name
  GROUPS_DELETE_NOTICE = 'delete-group-notice', //groupId
  GROUPS_EDIT_NOTICES = 'edit-group-notices',
  GROUPS_HIDE_LOCATION = 'hide-groups-map-location',
  GROUPS_JOIN = 'join-group',
  GROUPS_LEAVE = 'leave-group',
  GROUPS_PAN_MAP = 'pan-groups-map',
  GROUPS_RECOUNT_MEMBERS = 'recount-group-members',
  GROUPS_REMOVE_MEMBER = 'remove-group-member',
  GROUPS_SEARCH = 'search-groups', //place, name, SIG
  GROUPS_SEND_CHIT_CHAT = 'send-chit-chat', //thread_id not group
  GROUPS_SEND_CHIT_CHAT_REPLY = 'send-chit-chat-reply', //thread_id not group
  GROUPS_SHOW_LOCATION = 'show-groups-map-location',
  GROUPS_UPDATE_GROUP = 'update-group-details',
  GROUPS_UPDATE_NOTICES = 'update-group-notices', //groupId
  GROUPS_VIEW_CHIT_CHAT = 'view-group-chit-chat',
  GROUPS_VIEW_DETAIL = 'view-group-detail',
  GROUPS_VIEW_LIST = 'view-groups-list',
  GROUPS_VIEW_MAP = 'view-groups-map',
  GROUPS_VIEW_MEMBERS = 'view-group-members',
  GROUPS_VIEW_NOTICES = 'view-group-notices',
  GROUPS_VIEW_MINE = 'view-my-groups',
  GROUPS_VIEW_PIN = 'view-groups-pin', // location
  GROUPS_ZOOM_MAP = 'zoom-groups-map', // zoom level

  // listing title + category
  MARKETPLACE_ADD_LISTING = 'add-marketplace-listing',
  MARKETPLACE_CLEAR_SEARCH = 'clear-marketplace-search',
  MARKETPLACE_CLEAR_SEARCH_CRITERION = 'clear-marketplace-search-criterion', // eventLabel = value, type = criterion
  MARKETPLACE_INTRO_BUTTON = 'click-marketplace-intro-button',
  MARKETPLACE_SEARCH = 'search-marketplace', // location, category, country
  MARKETPLACE_SEND_MESSAGE = 'send-marketplace-message',
  MARKETPLACE_UPDATE_LISTING = 'update-marketplace-listing',
  MARKETPLACE_VIEW_INTRO = 'view-marketplace-intro',
  MARKETPLACE_VIEW_LISTINGS = 'view-marketplace-listings',
  MARKETPLACE_VIEW_LISTING_DETAIL = 'view-marketplace-listing-detail',
  MARKETPLACE_VIEW_OWN_LISTING_DETAIL = 'view-own-marketplace-listing-detail',

  MEETING_PLACE_CLEAR_SEARCH = 'clear-meeting-place-search',
  MEETING_PLACE_HIDE_LOCATION = 'hide-meeting-place-map-location',
  MEETING_PLACE_PAN_MAP = 'pan-meeting-place-map',
  MEETING_PLACE_SEARCH = 'search-meeting-place', // place + member
  MEETING_PLACE_SHOW_LOCATION = 'show-meeting-place-map-location',
  MEETING_PLACE_VIEW_LIST = 'view-meeting-place-list',
  MEETING_PLACE_VIEW_MAP = 'view-meeting-place-map',
  MEETING_PLACE_VIEW_PIN = 'view-meeting-place-pin', // location
  MEETING_PLACE_ZOOM_MAP = 'zoom-meeting-place-map', // zoom level

  MEMBERS_VIEW_PROFILE = 'view-member-profile', // displayName of member
  MEMBERS_VIEW_OWN_PROFILE = 'view-own-profile',
  MEMBERS_UPDATE_PROFILE = 'update-profile',

  MESSAGES_ADD_CONTACT = 'add-contact', // contact name
  MESSAGES_BLOCK_MEMBER = 'block-member', // blockee name
  MESSAGES_CLICK_OPTIONS = 'click-message-options', // button name
  MESSAGES_REMOVE_CONTACT = 'remove-contact', // contact name
  MESSAGES_HOLD_MESSAGE = 'hold-message', //threadId
  MESSAGES_SEND_MESSAGE = 'send-message', // threadId (memberThread name is dependent on the sender), memberCount as metric?
  MESSAGES_START_THREAD = 'start-thread',
  MESSAGES_UNBLOCK_MEMBER = 'unblock-member', // blockee name
  MESSAGES_UNARCHIVE_THREAD = 'unarchive-thread', //threadId
  MESSAGES_VIEW_ARCHIVE = 'view-message-archive',
  MESSAGES_VIEW_ARCHIVE_DETAIL = 'view-message-archive-detail', // threadId
  MESSAGES_VIEW_CONTACT_LIST = 'view-contacts-list',
  MESSAGES_VIEW_CONTACT_DETAIL = 'view-contact-detail', // contact name
  MESSAGES_VIEW_LIST = 'view-messages-list',
  MESSAGES_VIEW_DETAIL = 'view-message-detail', // threadId

  MY_CHIRPY_CLICK_ACTIVITY = 'click-activity-detail', // type?
  MY_CHIRPY_CLICK_LINK = 'click-my-chirpy-link', // link type, box title
  MY_CHIRPY_VIEW_ACTIVITY = 'view-activity-feed',
  MY_CHIRPY_VIEW_MINE = 'view-my-chirpy',
  MY_CHIRPY_VIEW_NEWS_LIST = 'view-news-list',
  MY_CHIRPY_VIEW_NEWS_DETAIL = 'view-news-item', // title

  NAVIGATION_HEADER_LOGO = 'click-header-logo',
  NAVIGATION_FOOTER_ICON = 'click-footer-icon', // icon label, + slot
  NAVIGATION_DASHBOARD_ICON = 'click-dashboard-icon', // icon label, slot, type = 'large|small'
  NAVIGATION_MENU_LINK = 'click-menu-link', //link label, slot, type = top/child?

  NOTIFICATION_UPDATE_SETTINGS = 'update-notification-settings',
  NOTIFICATION_UPDATE_ADVANCED_SETTINGS = 'update-advanced-notification-settings', // settings key
  NOTIFICATION_UPDATE_GROUP_SETTINGS = 'update-group-notification-settings', // group name
  NOTIFICATION_VIEW_ADVANCED_SETTINGS = 'view-advanced-notification-settings', // settings key
  NOTIFICATION_VIEW_SETTINGS = 'view-notification-settings',
  NOTIFICATION_VIEW_GROUP_SETTINGS = 'view-group-notification-settings', // group name

  //pixId, type=collectionName
  PIX_ADD_COMMENT = 'add-pix-comment',
  PIX_CANCEL_EDIT_CAPTION = 'cancel-edit-pix-caption',
  PIX_CLEAR_SEARCH = 'clear-pix-search',
  PIX_CLEAR_SEARCH_CRITERION = 'clear-pix-search-criterion', // eventLabel = value, type = criterion
  PIX_CLICK_ARROW = 'click-pix-arrow', // next/prev
  PIX_CLICK_REPORT = 'click-pix-report',
  PIX_EDIT_CAPTION = 'edit-pix-caption',
  PIX_EDIT_METADATA = 'edit-pix-metadata',
  PIX_REPORT_MEDIA = 'report-pix-media',
  PIX_SEARCH = 'search-pix', // category
  PIX_UPLOAD_MEDIA = 'upload-media',
  PIX_VIEW_COLLECTION = 'view-pix-collection', // collection id, collection name
  PIX_VIEW_DETAIL = 'view-pix-detail',
  PIX_VIEW_EDIT_PAGE = 'view-pix-edit-page',
  PIX_VIEW_UPLOAD_PAGE = 'view-pix-upload-page',

  // listing member name
  ROMANCE_ADD_LISTING = 'add-romance-listing',
  ROMANCE_INTRO_BUTTON = 'click-romance-intro-button', //title
  ROMANCE_SEND_MESSAGE = 'send-romance-message',
  ROMANCE_UPDATE_LISTING = 'update-romance-listing',
  ROMANCE_VIEW_INTRO = 'view-romance-intro',
  ROMANCE_VIEW_LISTINGS = 'view-romance-listings',
  ROMANCE_VIEW_LISTING_DETAIL = 'view-romance-listing-detail',

  // listing title + category
  SHARE_ADD_LISTING = 'add-share-listing',
  SHARE_CLEAR_SEARCH = 'clear-share-search',
  SHARE_CLEAR_SEARCH_CRITERION = 'clear-share-search-criterion', // eventLabel = value, type = criterion
  SHARE_INTRO_BUTTON = 'click-share-intro-button',
  SHARE_SEARCH = 'search-share', // location, category, country
  SHARE_SEND_MESSAGE = 'send-share-message',
  SHARE_UPDATE_LISTING = 'update-share-listing',
  SHARE_VIEW_INTRO = 'view-share-intro',
  SHARE_VIEW_LISTINGS = 'view-share-listings',
  SHARE_VIEW_LISTING_DETAIL = 'view-share-listing-detail',
  SHARE_VIEW_OWN_LISTING_DETAIL = 'view-own-share-listing-detail',

  // url
  SIGNUP_WHATS_NEXT_BUTTON = 'click-whats-next-button',
  SIGNUP_WHATS_NEXT_LINK = 'click-whats-next-link',

  //social uid
  SOCIAL_ADD_GOING = 'going-to-social',
  SOCIAL_ADD_INTEREST = 'interested-in-social',
  SOCIAL_ADD_SOCIAL = 'add-social',
  SOCIAL_CLEAR_SEARCH = 'clear-social-search',
  SOCIAL_DELETE = 'delete-social',
  SOCIAL_HIDE_LOCATION = 'hide-social-map-location',
  SOCIAL_PAN_MAP = 'pan-social-map',
  SOCIAL_REMOVE_INTEREST = 'not-interested-in-social',
  SOCIAL_SEARCH = 'search-social', //place, name, SIG
  SOCIAL_SHOW_LOCATION = 'show-social-map-location',
  SOCIAL_UPDATE_SOCIAL = 'update-social-details',
  SOCIAL_VIEW_DETAILS = 'view-social-details',
  SOCIAL_VIEW_GOING = 'view-going-to-social',
  SOCIAL_VIEW_INTERESTED = 'view-interested-in-social',
  SOCIAL_VIEW_LIST = 'view-social-list',
  SOCIAL_VIEW_MAP = 'view-social-map',
  SOCIAL_VIEW_MINE = 'view-my-socials',
  SOCIAL_VIEW_PIN = 'view-social-pin', // location
  SOCIAL_ZOOM_MAP = 'zoom-social-map', // zoom level

  //trip title
  TRIPS_AGREE_ROOM_SHARE = 'agree-room-share-match', // type = other member displayName
  TRIPS_CANCEL_ROOM_SHARE = 'cancel-room-share-match', // type = other member displayName
  TRIPS_CONFIRM_ROOM_SHARE = 'confirm-room-share-match', // term1, term2 = members displayNames
  TRIPS_CREATE_ROOM_SHARE = 'create-room-share-listing',
  TRIPS_DELETE_ROOM_SHARE = 'delete-room-share-listing',
  TRIPS_INITIATE_ROOM_SHARE = 'initiate-room-share-match', // type = other member displayName
  TRIPS_UNCONFIRM_ROOM_SHARE = 'unconfirm-room-share-match', // term1, term2 = members displayNames
  TRIPS_UPDATE_ROOM_SHARE = 'update-room-share-listing',
  TRIPS_REGISTER = 'register-for-trip',
  TRIPS_VIEW_DETAILS = 'view-trip-details',
  TRIPS_VIEW_REGISTRATION = 'view-trip-registration',

  // catchup name
  VIRTUAL_CATCHUPS_CREATE = 'create-virtual-catchup',
  VIRTUAL_CATCHUPS_DELETE = 'delete-virtual-catchup',
  VIRTUAL_CATCHUPS_JOIN = 'join-virtual-catchup'
}
