import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import { Scope } from '@sentry/types';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { EnvironmentService } from '@shared/services/environment.service';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(private authService: AuthService, private environmentService: EnvironmentService) {
    if (!this.environmentService.settings.sentryEnabled) return;

    Sentry.init({
      dsn: this.environmentService.settings.sentryDsn,
      environment: this.environmentService.settings.sentryEnvironment,
      ignoreErrors: ['Non-Error exception captured'],
      // this function is the last chance to modify Sentry events or cancel sending them before they are logged in Sentry.
      beforeSend(event: Sentry.Event, hint?: Sentry.EventHint) {
        const error = hint.originalException;
        let errorMessage: string | undefined;
        if (typeof error === 'string') {
          errorMessage = error;
        } else if (error instanceof Error) {
          errorMessage = error.message;
        }

        // Reload the page when Production has been updated and the previous javascript chunks are requested but no longer exist.
        if (errorMessage && (errorMessage.match(/Loading chunk /i) || errorMessage.match(/Cstr is undefined/i))) {
          const member = this.authService != null && this.authService._userProfileSubject != null ? this.authService._userProfileSubject.value : null;
          const message = `Reloading page for unhandled error: ${errorMessage}`;

          Sentry.withScope(scope => {
            this.addUserDetailsToScope(scope, member);
            Sentry.captureMessage(message, Severity.Info);
          });

          // Give Sentry time to finish sending captureMessage before reloading the page.
          // Redirect to home in case the error is on this page and causes an infinite loop.
          setTimeout(() => (window.location.href = '/'), 1000);

          // Discard the error by returning NULL.
          return null;
        }

        return event;
      }
    });
  }

  // view logged errors here: https://sentry.io/organizations/chirpyplus/issues/
  handleError(error) {
    if (this.environmentService.settings.sentryEnabled) {
      const member = this.authService != null && this.authService._userProfileSubject != null ? this.authService._userProfileSubject.value : null;
      const exception = error.originalError || error.error || error;

      Sentry.withScope(scope => {
        this.addUserDetailsToScope(scope, member);
        Sentry.captureException(exception);
      });

      console.log('Logged exception', exception);
    }
  }

  addUserDetailsToScope(scope: Scope, member?: UserObject) {
    if (member != null) {
      scope.setUser({ id: member.uid, email: member.email, username: member.displayName });
      scope.setExtra('member', member);
      scope.setExtra('profile', `${this.environmentService.url(member.country)}/members/${member.uid}`);
    }

    scope.setExtra('version', this.environmentService.versionNumber);
  }
}
