import { Component } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { IDataTableConfig } from '@shared/models/data-table-config';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { ConstantsService } from '@shared/services/constants.service';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.page.html'
})
export class CoinsTransactionListPage {
  get CONSTANTS() {
    return this.constantsService.constants.COINS;
  }

  config: IDataTableConfig = {};
  memberId: string = '';
  // TODO: Monitor analytics, and see how often people view this page
  // For the moment, show all transactions as "load more" is difficult to implement with current architecture
  // In future perhaps limit to transactions within last 2 years
  records = null;

  constructor(private analyticsService: AnalyticsService, public authService: AuthService, private constantsService: ConstantsService) {}

  ionViewWillEnter() {
    this.analyticsService.eventTrack(AnalyticsCategory.COINS, AnalyticsAction.COINS_VIEW_TRANSACTIONS);
  }
}
