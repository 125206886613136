import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MembersGuard } from '@shared/guards/members-guard';
import { RomanceDetailComponent } from './components/romance-detail/romance-detail.component';
import { RomanceEditComponent } from './components/romance-edit/romance-edit.component';
import { RomanceGuard } from './services/romance.guard';
import { RomanceIntroPage } from './romance-intro/romance-intro.page';
import { RomancePage } from './romance.page';

export const routes: Routes = [
  {
    path: 'intro',
    component: RomanceIntroPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Romance intro'
    }
  },
  {
    path: 'create/:id', // This route is for members to create their listing before being granted access
    component: RomanceEditComponent,
    canActivate: [RomanceGuard],
    data: {
      title: 'Create romance listing'
    }
  },
  {
    path: '',
    component: RomancePage,
    canActivate: [RomanceGuard],
    data: {
      title: 'Romance'
    },
    children: [
      {
        path: 'update/:id',
        component: RomanceEditComponent,
        canActivate: [MembersGuard],
        data: {
          title: 'Update romance listing'
        }
      },
      {
        path: ':id',
        component: RomanceDetailComponent,
        canActivate: [MembersGuard],
        data: {
          title: 'Romance listing details'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RomanceRoutingModule {}
