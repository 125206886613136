import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '@shared/guards/admin-guard';
import { MembersGuard } from '@shared/guards/members-guard';
import { ContentPageEditPage } from './content-page-edit/content-page-edit.page';
import { ContentPageViewPage } from './content-page-view/content-page-view.page';

const routes: Routes = [
  {
    path: ':pageId/edit',
    component: ContentPageEditPage,
    canActivate: [AdminGuard],
    data: {
      title: 'Update page'
    }
  },
  {
    path: ':pageId',
    component: ContentPageViewPage,
    canActivate: [MembersGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {}
