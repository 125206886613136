import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { IChangePassword } from '@shared/models/change-password';
import { first } from 'rxjs/operators';
import { SignupDisplayNameService } from './services/display-name.service';

@Injectable({
  providedIn: 'root'
})
export class SignupDisplayNamePresenter {
  get displayName() {
    return this.form.controls['displayName'];
  }

  form = this.formBuilder.group(
    {
      displayName: ['', [Validators.required], this.validateDisplayName.bind(this)]
    },
    { updateOn: 'submit' }
  );
  validCharacters: RegExp = new RegExp(/[\u0020-\u007e\u00c0-\u00ff]+/gu);

  constructor(private formBuilder: FormBuilder, private service: SignupDisplayNameService) {}

  setFormValue(displayName: string): void {
    this.form.patchValue({ displayName });
  }

  submit(): any {
    return this.form.value;
  }

  validateDisplayName(control: AbstractControl) {
    const displayName = control.value;

    return new Promise<any>(resolve => {
      const invalidCharacters = displayName.replace(this.validCharacters, '');
      if (invalidCharacters.length > 0) {
        resolve({ invalidCharacters: invalidCharacters });
        return;
      }

      this.service
        .validateDisplayName(displayName)
        .pipe(first())
        .subscribe(
          (result: boolean) => {
            if (result === true) {
              resolve(null);
            } else {
              resolve({ displayNameInUse: displayName });
            }
          },
          err => {
            resolve({ displayNameInUse: displayName });
          }
        );
    });
  }
}
