import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AbstractMarkerService } from '@shared/services/abstract-marker.service';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { FeatureGroup } from 'leaflet';
import { map, skipWhile, take } from 'rxjs/operators';
import { ISocial } from '@shared/models/social/social';
import { IMapPin } from '@shared/models/map-pin';
import { SocialLocatorPinComponent } from './pin/pin.component';

@Injectable({
  providedIn: 'root'
})
export class SocialLocatorService extends AbstractMarkerService {
  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private modalController: ModalController) {
    super();
  }

  async presentMapPinsModal(event, items) {
    const properties = event.layer.feature.properties;
    const socials = this.getSocialsByPlace(items, properties.placeId);
    const modal = await this.modalController.create({
      component: SocialLocatorPinComponent,
      cssClass: 'social-locator__modal', //'members-locator__modal',
      componentProps: {
        socials: socials,
        count: socials.length,
        placeName: properties.locality
      }
    });

    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_VIEW_PIN, properties.locality);
    return await modal.present();
  }

  updateMarkers(data: any[], markerGroup: FeatureGroup, showCount: boolean) {
    markerGroup.clearLayers();

    if (data == null || data.length === 0) return;

    const pins: IMapPin[] = [];

    data.forEach(place => {
      const count = (place.hasSocial || []).length;
      if (count > 0) {
        pins.push({
          count: count,
          itemId: place.uid,
          itemName: place.displayName,
          lat: place.coordinates.latitude,
          long: place.coordinates.longitude,
          me: false // TODO change colour of marker if you belong to a social at this place? this.authService._userProfileSubject.value.catchupGroupIds.includes(social.uid)
        });
      }
    });

    // create pin for each location
    pins.forEach(p => {
      this.addMarker(p, markerGroup, showCount);
    });
  }

  private getSocialsByPlace(socials: ISocial[], placeId: string) {
    return socials.filter(x => x.locationId === placeId);
  }
}
