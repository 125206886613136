import { Component, OnDestroy, OnInit } from '@angular/core';
import { AvatarSize } from '@shared/constants/avatar-size';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { Observable, Subscription } from 'rxjs';
import { IItemDetail } from '../../models/item-detail';
import { BaseApprovalPresenter } from './base-approval.presenter';

@Component({
  selector: 'app-base-approval',
  styleUrls: ['./base-approval.component.scss'],
  templateUrl: './base-approval.component.html'
})
export class BaseApprovalComponent implements OnDestroy, OnInit {
  get entries() {
    return this.baseApprovalPresenter.form.controls.entries;
  }

  get form() {
    return this.baseApprovalPresenter.form;
  }

  avatarSize: AvatarSize = AvatarSize.LARGE;
  approveButtonText: string = '';
  heading: string = '';
  items: any[] = [];
  items$: Observable<any[]>;
  itemDetails: IItemDetail[] = [];
  noItemsMessage: string;
  rejectButtonText: string = '';
  routerField: string = '';
  routerPrefix: string = '';
  startSlot: string;
  subscription: Subscription;
  title: string = '';

  constructor(protected baseApprovalPresenter: BaseApprovalPresenter, protected subscriptionService: SubscriptionService, protected toastService: ToastService) {}

  loadItems() {}

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.subscription);
  }

  ngOnInit() {
    this.noItemsMessage = 'Loading...';
    this.setRouterLinks();
    this.loadItems();
    this.subscription = this.items$.subscribe(data => {
      this.noItemsMessage = 'There are no items awaiting approval.';
      this.items = data.reduce((acc, current) => {
        acc[current.uid] = current;
        return acc;
      }, {});
      this.baseApprovalPresenter.init(data);
    });
    this.subscriptionService.add(this.subscription);
  }

  onApprove() {
    const formValue = this.baseApprovalPresenter.getValue();
    if (!formValue.entries) return;

    let count = 0;
    for (let [uid, selected] of Object.entries(formValue.entries)) {
      if (selected) {
        count++;
        this.onApproveHandler(this.items[uid]);
      }
    }

    // TODO: Display success message after then callback of onApproveHandler?
    let message = `${count} items approved.`;
    if (count === 0) message = `You haven't selected any items to approve.`;
    if (count === 1) message = `One item approved.`;
    this.toastService.presentToast(message);
  }

  onApproveHandler(item: any) {}

  onReject() {
    const formValue = this.baseApprovalPresenter.getValue();
    if (!formValue.entries) return;

    let count = 0;
    for (let [uid, selected] of Object.entries(formValue.entries)) {
      if (selected) {
        count++;
        this.onRejectHandler(this.items[uid]);
      }
    }

    // TODO: This assumes verb is not irregular e.g. hold/held
    const verb = this.rejectButtonText.toLowerCase();
    const pastVerb = verb.replace(/e$/, '') + 'ed';

    // TODO: Display success message after then callback of onRejectHandler?
    let message = `${count} items ${pastVerb}.`;
    if (count === 0) message = `You haven't selected any items to ${verb}.`;
    if (count === 1) message = `One item ${pastVerb}.`;
    this.toastService.presentToast(message);
  }

  onRejectHandler(item: any) {}

  setRouterLinks() {}
}
