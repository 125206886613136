import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { ChirpySendMessageComponent } from '@shared/components/chirpy-send-message/chirpy-send-message.component';
import { MessageType } from '@shared/constants/message-type';
import { IButtonGroup } from '@shared/models/button-group';
import { IMemberThreadType } from '@shared/models/messages/member-thread-type';
import { IMessageData } from '@shared/models/messages/message-data';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService, IAnalyticsSetting } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { ContactService } from '@shared/services/messages/contact.service';
import { ThreadDetailCatchupService } from '@shared/services/messages/thread-detail-catchup.service';
import { ToastService } from '@shared/services/toast.service';
import { UIService } from '@shared/services/ui.service';
import { VirtualCatchupService } from '@shared/services/virtual-catchups/virtual-catchup.service';
import { ThreadMembersService } from '../thread-members/thread-members.service';
import { ThreadDetailService } from './thread-detail.service';

@Component({
  selector: 'thread-detail',
  templateUrl: './thread-detail.component.html',
  styleUrls: ['./thread-detail.component.scss']
})
export class ThreadDetailComponent implements OnInit {
  get canRenameThread() {
    return this.thread != null && this.thread.isOwner === true && this.thread.type !== IMemberThreadType.DirectMessage && this.authService.isHostOrAdmin();
  }

  get canSendMessage() {
    return !this.isEitherBlocked && !this.authService.isImpersonating;
  }

  get inCatchup(): boolean {
    return this.threadDetailCatchupService.getCatchup() !== '' && this.threadDetailCatchupService.getCatchup() === this.virtualCatchupName;
  }

  get isBlocked() {
    return this.thread != null && this.thread.relatedIds != null && this.contactService.isBlocked(this.thread.relatedIds[0]) && this.thread.type === IMemberThreadType.DirectMessage;
  }

  get isBlockedBy() {
    return this.thread != null && this.thread.relatedIds != null && this.contactService.isBlockedBy(this.thread.relatedIds[0]) && this.thread.type === IMemberThreadType.DirectMessage;
  }

  get isEitherBlocked() {
    return this.thread != null && this.thread.relatedIds != null && this.contactService.isEitherBlocked(this.thread.relatedIds[0]) && this.thread.type === IMemberThreadType.DirectMessage;
  }

  get isContact() {
    return this.thread != null && this.thread.relatedIds != null && this.contactService.isContact(this.thread.relatedIds[0]);
  }

  get isConversation() {
    return this.thread != null && this.thread.type === IMemberThreadType.Conversation;
  }

  get isDirectMessage() {
    return this.thread != null && this.thread.type === IMemberThreadType.DirectMessage;
  }

  get isExtraLargeDisplay() {
    return this.uiService.isExtraLargeDisplay;
  }

  get isGroup() {
    return this.thread != null && this.thread.type === IMemberThreadType.Group;
  }

  get isMediumDisplay() {
    return this.uiService.isMediumDisplay;
  }

  get isSoleMember() {
    return this.thread != null && this.thread.isSoleMember === true;
  }

  get isThreadClosed() {
    return this.isEitherBlocked || this.isSoleMember;
  }

  get thread() {
    return this.service.thread;
  }

  get threadClosedMessage() {
    if (this.isBlocked) {
      return this.CONSTANTS.isBlockedMessage;
    } else if (this.isBlockedBy) {
      return this.CONSTANTS.isBlockedByMessage;
    } else if (this.isSoleMember) {
      return this.CONSTANTS.isSoleMemberMessage;
    } else {
      return ``;
    }
  }

  get virtualCatchupName() {
    // Remove hyphen from environment name because daily.co can't have meeting rooms with hyphens and the OnlyFriends environments are named friends-dev, friends-test, or friends-prod. Chirpy environments are named dev, test, prod (no hyphen).
    return this.thread != null && this.thread.virtualCatchupName != null && this.thread.virtualCatchupName !== '' ? this.environmentService.name.replace('-', '') + this.thread.threadId : null;
  }

  buttonGroups: IButtonGroup[] = [];
  CONSTANTS: any;
  isJoiningVirtualCatchup: boolean = false;
  overrideMemberNotificationLabel = 'Notify everyone in the conversation about this message. (They will receive a notification even if they have muted notifications.)';
  popoverAnalytics: IAnalyticsSetting = {
    action: AnalyticsAction.MESSAGES_CLICK_OPTIONS,
    category: AnalyticsCategory.MESSAGES
  };
  @ViewChild(ChirpySendMessageComponent, { static: false }) sendMessageComponent: ChirpySendMessageComponent;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private contactService: ContactService,
    private environmentService: EnvironmentService,
    private service: ThreadDetailService,
    private route: ActivatedRoute,
    private router: Router,
    private storage: Storage,
    private threadDetailCatchupService: ThreadDetailCatchupService,
    private threadMembersService: ThreadMembersService,
    private toastService: ToastService,
    private uiService: UIService,
    private virtualCatchupService: VirtualCatchupService
  ) {}

  focusInput(): void {
    if (this.isMediumDisplay && this.sendMessageComponent != null) {
      // don't open keyboard on mobile until user wants to reply to a message.
      this.sendMessageComponent.focusInput();
    }
  }

  goBack() {
    this.threadDetailCatchupService.unsetCatchup();
    this.router.navigate([`/messages/threads`]);
  }

  ionViewWillEnter() {
    // ngOnInit doesn't trigger if you come back from a navigation stack, ionViewWillEnter / ionViewDidEnter will.
    this.storage.set('messages-state', this.router.url);
    this.focusInput();

    if (this.thread != null) {
      this.analyticsService.eventTrack(AnalyticsCategory.MESSAGES, AnalyticsAction.MESSAGES_VIEW_DETAIL, this.thread.threadId); // this.thread is a memberThread, use uid of the actual thread as the analytics label
    }
  }

  ionViewWillLeave() {
    //Handle leaving thread here rather than in ngOnInit, because that doesn't handle the case where you don't go to another thread detail
    //TODO This probably doesn't work if go directly to e.g. /home because the component remains in the stack
    this.service.onLeaveMemberThreadUpdateDetails();
  }

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.MESSAGES.THREADS.DETAIL;

    this.route.paramMap.subscribe(params => {
      const threadId = params.get('id');
      if (threadId == null) {
        return;
      }
      this.service.loadThread(threadId, this.initButtons.bind(this));
      this.contactService.getContacts().subscribe(contacts => {
        // Listen for changes to contact list. Also call initButtons again if contacts/blocks/blockedBy are loaded after the thread is loaded
        if (this.thread) this.initButtons();
      });

      this.virtualCatchupService.virtualCatchup$.subscribe(name => {
        // Listen to virtual catchup name, so we can update the buttons if a member leaves the VC via the daily.co 'Leave' button
        this.initButtons();
      });
    });
  }

  onAddMemberToContacts() {
    this.contactService.addMemberToContacts(this.thread.relatedIds[0], this.thread.name);
  }

  // TODO: Show confirmation
  onArchiveThread() {
    return (
      this.service
        // Archive the thread for the member that was removed
        .archiveMemberThread()
        .then(() => this.toastService.presentToast(`Thread has been archived`))
        .then(() => {
          this.router.navigate(['/messages/threads']);
        })
    );
  }

  async onBlockMember() {
    const success = () => this.router.navigate(['/messages/threads']);
    this.contactService.blockMember(this.thread.relatedIds[0], this.thread.name, success);
  }

  onJoinVirtualCatchup() {
    // Prevent double clicking Join Meeting button while Daily.co room is being created.
    if (this.isJoiningVirtualCatchup) return;
    this.isJoiningVirtualCatchup = true;
    this.threadDetailCatchupService.setCatchup(this.virtualCatchupName, this.thread.threadId, this.isDirectMessage);
    this.initButtons();
  }

  onLeaveConversation() {
    this.threadMembersService.leaveConversation(this.thread);
  }

  onLeaveVirtualCatchup() {
    this.isJoiningVirtualCatchup = false;
    this.threadDetailCatchupService.unsetCatchup();
    this.initButtons();
  }

  async onRemoveMemberFromContacts() {
    this.contactService.removeMemberFromContacts(this.thread.relatedIds[0], this.thread.name);
  }

  onRenameButtonClicked() {
    return this.service.presentRenameThreadPrompt(this.thread.type, this.thread.name, this.thread.threadId);
  }

  onSendMessage(messageData, threadId: string) {
    // handle edge case where open thread is not one of the member's threads
    // usually only affects admins
    const threadName = this.thread ? this.thread.name || '' : '';
    const threadType = this.thread ? this.thread.type || null : null;
    this.service.createMessage(messageData, threadId, MessageType.THREAD, threadType, { threadName });
  }

  openThreadSettings() {
    this.router.navigate(['/messages/threads/' + this.thread.threadId + '/settings']);
  }

  private initButtons() {
    if (!this.thread) return [];

    // TODO: Add analytics to chirpy-popover-buttons
    this.buttonGroups = [
      {
        condition: this.isDirectMessage,
        buttons: [
          {
            click: this.onAddMemberToContacts.bind(this),
            condition: !this.isEitherBlocked && !this.isContact,
            responsive: false,
            text: 'Add to contacts'
          },
          {
            click: this.onRemoveMemberFromContacts.bind(this),
            condition: this.isContact,
            responsive: false,
            text: 'Remove from contacts'
          },
          {
            condition: true,
            responsive: false,
            routerLink: ['/members', this.thread.relatedIds[0]],
            text: 'View profile'
          },
          {
            click: this.onBlockMember.bind(this),
            condition: !this.isGroup && !this.isBlocked,
            responsive: false,
            text: 'Block/report member'
          }
          // TODO: Allow unblocking from here?
        ]
      },
      {
        condition: !this.isDirectMessage,
        buttons: [
          {
            click: this.onLeaveConversation.bind(this),
            condition: true,
            text: 'Leave conversation'
          },
          {
            click: this.openThreadSettings.bind(this),
            condition: true,
            text: 'View people in thread'
          }
        ]
      },
      {
        condition: true,
        buttons: [
          {
            click: this.onArchiveThread.bind(this),
            condition: true,
            text: 'Archive thread' // TODO: Change to archive conversation/archive messages?
          },
          {
            click: this.onJoinVirtualCatchup.bind(this),
            condition: !this.isEitherBlocked && !this.inCatchup,
            text: 'Join video call'
          },
          {
            click: this.onLeaveVirtualCatchup.bind(this),
            condition: !this.isEitherBlocked && this.inCatchup,
            text: 'Leave video call'
          }
        ]
      }
    ];
  }
}
