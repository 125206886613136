import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { IMediaOptions } from '@shared/models/media/media-options';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { of, Observable } from 'rxjs';
import { PixSearchComponent } from '../pix-search/pix-search.component';

@Component({
  selector: 'app-pix-collection',
  templateUrl: './pix-collection.page.html'
})
export class PixCollectionPage implements OnInit {
  canReviewPhotos$: Observable<boolean>;
  canUploadPhotos$: Observable<boolean>;
  canViewPhotos$: Observable<boolean>;
  collectionId: string;
  collectionTitle: string;
  collectionType: MediaCollectionType;
  defaultSearchModel: IMediaOptions = { category: 'all' };
  MAX_PHOTOS: number = 50; // Limit number of photos shown per page to speed up load time
  routerLinkPrefix: string = '/pix';
  searchCriteria: Record<string, string> = {};
  searchModel: IMediaOptions | null = { category: 'all' };

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private modalController: ModalController, private route: ActivatedRoute) {}

  ngOnInit() {
    this.collectionId = this.route.snapshot.paramMap.get('id');
    this.collectionTitle = this.route.snapshot.paramMap.get('title');
    if (this.collectionTitle) this.collectionTitle = this.collectionTitle.replace(/(%20|\+)/g, ' ');
    this.collectionType = MediaCollectionType.PUBLIC;

    // This is to determine whether or not to show hidden photos in the collection
    this.canReviewPhotos$ = this.authService.isAdmin$();

    // For the moment only hosts can upload images
    this.canUploadPhotos$ = this.authService.isCohostOrHostOrAdmin$();

    // All memebers can view public collections
    this.canViewPhotos$ = of(true);

    this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_VIEW_COLLECTION, this.collectionId, { type: this.collectionTitle });
  }

  onClickCriterion(key: string) {
    const criterion = this.searchModel[key];
    this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_CLEAR_SEARCH_CRITERION, criterion, { type: key });

    this.searchModel[key] = this.defaultSearchModel[key];
    this.onSearch(this.searchModel);
  }

  async onOpenSearchModal() {
    await this.presentSearchModal();
  }

  onSearch(search: IMediaOptions) {
    this.searchModel = Object.assign({}, search);
    if (search == null) return;

    this.updateSearchCriteria(search);
  }

  async presentSearchModal() {
    const eventEmitter = new EventEmitter<any>();
    eventEmitter.subscribe($event => {
      modal.dismiss();
      return this.onSearch($event);
    });

    const modal = await this.modalController.create({
      component: PixSearchComponent,
      cssClass: 'pix-search__modal',
      componentProps: {
        options: this.searchModel,
        search: eventEmitter
      }
    });

    return await modal.present();
  }

  updateSearchCriteria(options: IMediaOptions = null) {
    const criteria = {};
    if (options == null) this.searchCriteria = criteria;
    if (options.category !== 'all') criteria['category'] = `Category: ${options.category}`;
    this.searchCriteria = criteria;
  }
}
