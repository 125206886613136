import { Injectable, OnDestroy } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AuthService } from '@shared/services/auth.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { IVersion } from '@shared/services/version/models/version';
import { VersionDatabase } from '@shared/services/version/version.database';
import { Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VersionService implements OnDestroy {
  firebaseVersion: IVersion;
  hasNewVersion: boolean; // check this in route change, reload the page when true.
  projectVersion = this.environmentService.versionNumber;
  versionSubscription: Subscription;

  constructor(private versionDatabase: VersionDatabase, private environmentService: EnvironmentService, private authService: AuthService, private dateTimeService: DateTimeService, private alertController: AlertController) {}

  checkForNewVersion() {
    if (this.environmentService.isLocalDevelopment) {
      console.warn('Skipping checkForNewVersion when developing/testing locally.');
      return;
    }

    this.versionSubscription = this.versionDatabase
      .getVersion()
      .pipe(skipWhile(x => x == null))
      .subscribe((version: IVersion) => {
        this.firebaseVersion = version;
        if (this.hasNewVersion === true) return; // don't present Modal twice.

        this.hasNewVersion = this.hasNewerVersion(version);
        if (this.hasNewVersion) {
          return this.presentRefreshAlert();
        }
      });
  }

  hasNewerVersion(version: IVersion) {
    return version.version !== this.environmentService.versionNumber;
  }

  ngOnDestroy() {
    if (this.versionSubscription) this.versionSubscription.unsubscribe(); // Doesn't require authentication to read version doc in Firestore, so don't need to handle with subscriptionService
  }

  async presentRefreshAlert() {
    const alert = await this.alertController.create({
      header: `Website updated`,
      message: `<p>Website has been updated, refresh the page now for a better experience! Otherwise the website will automatically refresh in 10 minutes.</p>`,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Refresh now',
          handler: () => this.presentRefreshAlertHandler()
        },
        {
          text: 'Later',
          role: 'cancel'
        }
      ]
    });

    await alert.present();

    window.setTimeout(() => this.presentRefreshAlertHandler(), 10 * 60 * 1000);
  }

  presentRefreshAlertHandler() {
    window.location.reload();
  }
}
