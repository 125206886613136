import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserObject } from '@shared/models/user-object';
import { UserService } from '@shared/services/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class MemberProfileEditPresenter {
  form = this.formBuilder.group({
    bitAboutMe: [''],
    displayName: ['', Validators.required],
    dob: [''],
    gender: ['', Validators.required],
    interests: [''],
    organisations: [''],
    placeId: ['', Validators.required],
    locality: [''],
    phone: [''],
    region: ['', Validators.required],
    searchName: [''],
    suburbId: [''],
    suburbName: [''],
    title: ['']
  });

  constructor(private formBuilder: FormBuilder, private userService: UserService) {}

  memberProfile() {
    const formValue = {} as any;
    Object.assign(formValue, this.form.value);

    formValue.interests = {};
    const interests = {};
    if (this.form.value.interests !== '') {
      this.form.value.interests.split(', ').forEach(key => {
        interests[key] = true;
      });
    }
    formValue.interests = interests;

    formValue.organisations = {};
    const organisations = {};
    if (this.form.value.organisations !== '') {
      this.form.value.organisations.split(', ').forEach(key => {
        organisations[key] = true;
      });
    }
    formValue.organisations = organisations;

    formValue.bitAboutMe = formValue.bitAboutMe.trim();
    formValue.displayName = this.capitalise(formValue.displayName.trim());
    formValue.locality = formValue.locality.trim();
    formValue.phone = formValue.phone.trim();
    formValue.searchName = formValue.displayName.toLowerCase();
    formValue.title = formValue.title.trim();

    return formValue;
  }

  capitalise(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  patchValue(data: any) {
    this.form.patchValue(data);
  }

  setValue(member: UserObject) {
    // This is needed to prevent an error. I don't know why the fallback within the setValue call is not sufficient
    if (!member.displayName) member.displayName = '';

    this.form.setValue({
      bitAboutMe: member.bitAboutMe || '',
      displayName: member.displayName || '',
      gender: member.gender || '',
      organisations: this.userService.getKeys(member.organisations || {}),
      interests: this.userService.getKeys(member.interests || {}),
      placeId: member.placeId || '',
      locality: member.locality || '',
      dob: member.dateOfBirth && member.dateOfBirth.year ? `${member.dateOfBirth.year}-${member.dateOfBirth.month}-${member.dateOfBirth.day}` : '',
      phone: member.phone || '',
      region: member.region || '',
      searchName: member.searchName || member.displayName.toLowerCase(),
      suburbId: '',
      suburbName: '',
      title: member.title || ''
    });
  }
}
