import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AvatarSize } from '@shared/constants/avatar-size';
import { IAdvertiser } from '@shared/models/advertisers/advertiser';
import { UserObject } from '@shared/models/user-object';
import { AdvertiserService } from '@shared/services/advertisers/advertiser.service';

@Component({
  selector: 'chirpy-advertiser',
  templateUrl: './chirpy-advertiser.component.html',
  styleUrls: ['./chirpy-advertiser.component.scss']
})
export class ChirpyAdvertiserComponent implements OnChanges {
  @Input() advertiser: IAdvertiser;
  AVATAR_SIZE: AvatarSize = AvatarSize.LARGE;
  countrySuffix: string = '';
  @Input() detail: boolean = false;
  isFollowing: boolean = false;
  @Input() lines: string = 'full';
  @Input() member: UserObject;
  @Input() showLastAnnouncement: boolean = false;

  constructor(private advertiserService: AdvertiserService) {}

  ngOnChanges(changes: SimpleChanges) {
    const newAdvertiser = changes.advertiser ? changes.advertiser.currentValue : {};
    this.countrySuffix = this.advertiserService.getCountrySuffix(newAdvertiser);

    const newMember = changes.member ? changes.member.currentValue : {};
    this.isFollowing = (newMember.advertiserIds || []).includes(newAdvertiser.uid);
  }
}
