import { CatchupShowOption } from './catchup-show-option';

export interface ICatchupOptions {
  /* tslint:disable:member-ordering */
  uid?: string;

  // search these fields using the Catchup Search UI.
  show: string;
  region: string;

  // this field is shown in the UI if constants.APP.allowOtherCountries is set to true.
  country: string;

  // this is set when you click "View all catchups" from the Group Details page.
  groupId: string;
  groupName: string;
}

export const DefaultCatchupOptions: ICatchupOptions = {
  country: null,
  groupId: null,
  groupName: null,
  region: '',
  show: CatchupShowOption.EVERYTHING
};
