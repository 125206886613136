import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Country } from '@shared/constants/country';
import { IShareListing } from '@shared/models/share/share-listing';
import { IShareOptions } from '@shared/models/share/share-options';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { ConstantsService } from '@shared/services/constants.service';
import { ShareService } from '@shared/services/share/share.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ShareSearchPresenter } from './share-search.presenter';

@Component({
  selector: 'app-share-search',
  templateUrl: './share-search.component.html',
  styleUrls: ['./share-search.component.scss'],
  viewProviders: [ShareSearchPresenter],
  encapsulation: ViewEncapsulation.None
})
export class ShareSearchComponent implements OnInit {
  get CONSTANTS() {
    return this.constantsService.constants.SHARE.SEARCH;
  }

  get searchForm() {
    return this.presenter.form;
  }

  get SHARE_CATEGORIES$(): BehaviorSubject<string[]> {
    return this.shareService.shareCategories;
  }

  COUNTRIES: Record<string, Country> = {};
  interfaceOptions: any = { cssClass: 'wide-select' };
  isCountrySearchEnabled: boolean;
  @Input() options: IShareOptions | null;
  @Output() search = new EventEmitter<any>();

  constructor(private analyticsService: AnalyticsService, private constantsService: ConstantsService, private shareService: ShareService, private presenter: ShareSearchPresenter, private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.COUNTRIES = this.constantsService.constants.APP.countries;
    this.isCountrySearchEnabled = this.constantsService.constants.APP.allowOtherCountries;

    if (this.options != null) {
      this.presenter.setValue(this.options);
    } else {
      this.presenter.reset();
    }
  }

  onSearch() {
    const search = this.presenter.search();
    // TODO: How to handle the fact country search can be turned on/off for a given environment?
    // Logical order for fields is Country, Location, Category, but if country is turned off, field1 becomes location etc
    // For the moment use term3 for country, and remember that it is not actually the third field on the form
    this.analyticsService.eventTrack(AnalyticsCategory.SHARE, AnalyticsAction.SHARE_SEARCH, null, { term1: search.location, term2: search.category, term3: search.country });
    this.search.emit(search);
  }

  reset() {
    this.presenter.reset();
    this.analyticsService.eventTrack(AnalyticsCategory.SHARE, AnalyticsAction.SHARE_CLEAR_SEARCH);
    this.onSearch();
  }
}
