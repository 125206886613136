import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { IRoomShare } from '@shared/models/trips/room-share';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EmailService } from '@shared/services/email/email.service';
import { ToastService } from '@shared/services/toast.service';
import { RoomShareService } from '@shared/services/trips/room-share.service';
import { TripService } from '@shared/services/trips/trip.service';
import { UIService } from '@shared/services/ui.service';
import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { RoomShareEditPresenter } from './room-share-edit.presenter';
import FieldValue = firestore.FieldValue;

@Component({
  selector: 'room-share-edit-page',
  templateUrl: './room-share-edit.page.html'
})
export class RoomShareEditPage {
  get form() {
    return this.roomShareEditPresenter.form;
  }

  CONSTANTS: any;
  isCreating: boolean = false;
  memberId: string;
  member$: Observable<IRoomShare>;
  roomShare: IRoomShare;
  title: string;
  title$: Observable<string>;
  tripId: string;

  constructor(
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private emailService: EmailService,
    private roomShareEditPresenter: RoomShareEditPresenter,
    private roomShareService: RoomShareService,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private tripService: TripService,
    private uiService: UIService
  ) {}

  getNumberOfRows() {
    return this.uiService.getNumberOfTextAreaRows();
  }

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.TRIPS.ROOM_SHARE;
    this.tripId = this.route.snapshot.paramMap.get('tripId');
    this.memberId = this.authService._userProfileSubject.value.uid;
    this.title$ = this.tripService.getTrip(this.tripId).pipe(
      first(x => !!x),
      map(x => {
        this.title = x.title;
        return `Room Share for ` + x.title;
      })
    );
    this.member$ = this.roomShareService.getRoomShareForMember(this.tripId, this.memberId).pipe(
      // use map instead of tap, because we need a value for this.member$
      map(roomShare => {
        if (!roomShare) {
          // TODO: If there's a problem connecting to the DB will this overwrite the existing roomShare?
          this.isCreating = true;
          roomShare = { description: '' } as IRoomShare; // Don't actually create the listing until they save
        }
        this.roomShareEditPresenter.setValue(roomShare);
        this.roomShare = roomShare; // only for use when deleting listing
        return roomShare;
      })
    );
  }

  async onDeleteRoomShare() {
    const alert = await this.alertController.create({
      header: `Delete Room Share`,
      message: `<p>Are you sure you want to delete your Room Share listing?</p> <p>Clicking 'Delete' will cancel any matches you have made and send a message to these members to notify them.</p>`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: `Delete`,
          handler: data => this.onDeleteRoomShareHandler()
        }
      ]
    });

    await alert.present();
  }

  onDeleteRoomShareHandler(): void {
    const foundMatches = false; // TODO: Make this dependent on capacity of room type
    const otherMatch = {
      [this.memberId]: FieldValue.delete()
    };
    for (const [otherMemberId, match] of Object.entries(this.roomShare.matches || {})) {
      const myMatch = {
        [otherMemberId]: FieldValue.delete()
      };
      this.roomShareService.updateRoomShareMatches(this.tripId, this.memberId, myMatch, otherMemberId, otherMatch, foundMatches); // TODO: this performs multiple writes to trips/{tripId}/Matches/list
      this.emailService.sendRoomShareCancellation(otherMemberId, this.tripId, this.title, this.memberId, this.roomShare.displayName, this.roomShare.country);
    }

    this.roomShareService.deleteRoomShareForMember(this.tripId, this.memberId).then(() => {
      this.analyticsService.eventTrack(AnalyticsCategory.TRIPS, AnalyticsAction.TRIPS_DELETE_ROOM_SHARE, this.title);
      this.router.navigate(['/trips/room-share/', this.tripId]);
    });
  }

  updateRoomShare(): void {
    const description = (this.roomShareEditPresenter.formValue.description || '').trim();
    if (description.length === 0) {
      const message = `Please enter some information about yourself`;
      this.toastService.presentToast(message);
      return;
    }

    // TODO: If there's a problem connecting to the DB will this overwrite the existing roomShare?
    const ref: Promise<any> = this.isCreating ? this.roomShareService.createRoomShareForMember(this.tripId, this.memberId, { description: description }) : this.roomShareService.updateRoomShare(this.tripId, this.memberId, { description: description });
    ref.then(() => {
      const action = this.isCreating ? AnalyticsAction.TRIPS_CREATE_ROOM_SHARE : AnalyticsAction.TRIPS_UPDATE_ROOM_SHARE;
      this.analyticsService.eventTrack(AnalyticsCategory.TRIPS, AnalyticsAction.TRIPS_INITIATE_ROOM_SHARE, this.title);
      this.router.navigate(['/trips/room-share/', this.tripId]);
    });
  }
}
