import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { AdminGuard } from '@shared/guards/admin-guard';
import { AdminPage } from './admin.page';
import { CoinsEditPage } from './coins-edit/coins-edit.page';
import { AddAdvertiserAccessComponent } from './components/add-advertiser-access/add-advertiser-access.component';
import { AddIconBadgeComponent } from './components/add-icon-badge/add-icon-badge.component';
import { AddRemoveCoinsComponent } from './components/add-remove-coins/add-remove-coins.component';
import { AddRomanceAccessComponent } from './components/add-romance-access/add-romance-access.component';
import { AddTextBadgeComponent } from './components/add-text-badge/add-text-badge.component';
import { ApproveMarketplaceComponent } from './components/approve-marketplace/approve-marketplace.component';
import { ApproveMessagesComponent } from './components/approve-messages/approve-messages.component';
import { ApproveShareComponent } from './components/approve-share/approve-share.component';
import { ApproveSocialsComponent } from './components/approve-socials/approve-socials.component';
import { ChangeRoleComponent } from './components/change-role/change-role.component';
import { DeleteMemberComponent } from './components/delete-member/delete-member.component';
import { DeleteMemberMessagesComponent } from './components/delete-member-messages/delete-member-messages.component';
import { ImpersonateComponent } from './components/impersonate/impersonate.component';
import { RemoveAdvertiserAccessComponent } from './components/remove-advertiser-access/remove-advertiser-access.component';
import { RemoveIconBadgeComponent } from './components/remove-icon-badge/remove-icon-badge.component';
import { RemoveRomanceAccessComponent } from './components/remove-romance-access/remove-romance-access.component';
import { RemoveTextBadgeComponent } from './components/remove-text-badge/remove-text-badge.component';
import { SearchAttendanceComponent } from './components/search-attendance/search-attendance.component';
import { SearchCoinsClaimedComponent } from './components/search-coins-claimed/search-coins-claimed.component';
import { SearchMemberComponent } from './components/search-member/search-member.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { CancellationLogComponent } from './components/logs/cancellation-log/cancellation-log.component';
import { ChitChatLogComponent } from './components/logs/chit-chat-log/chit-chat-log.component';
import { CoinsLogComponent } from './components/logs/coins-log/coins-log.component';
import { EmailLogComponent } from './components/logs/email-log/email-log.component';
import { MessageLogComponent } from './components/logs/message-log/message-log.component';
import { NoteLogComponent } from './components/logs/note-log/note-log.component';
import { CatchupsAttendedByMemberComponent } from './components/reports/catchups-attended-by-member/catchups-attended-by-member.component';
import { CatchupsByDateComponent } from './components/reports/catchups-by-date/catchups-by-date.component';
import { CatchupsByGroupComponent } from './components/reports/catchups-by-group/catchups-by-group.component';
import { CoinsClaimedByDateComponent } from './components/reports/coins-claimed-by-date/coins-claimed-by-date.component';
import { CoinsClaimedByMemberComponent } from './components/reports/coins-claimed-by-member/coins-claimed-by-member.component';
import { LastActivityByDateComponent } from './components/reports/last-activity-by-date/last-activity-by-date.component';
import { MemberActivityComponent } from './components/reports/member-activity/member-activity.component';
import { NextCatchupComponent } from './components/reports/next-catchup/next-catchup.component';
import { EmailDetailPage } from './email-detail/email-detail.page';
import { MemberDetailPage } from './member-detail/member-detail.page';
import { SettingsEditPage } from './settings-edit/settings-edit.page';
import { SettingsListPage } from './settings-list/settings-list.page';

// AdminGuard allows anyone with role: admin to access these routes, unless prevented by data: adminRoles
// however the list of options shown on the AdminPage is restricted by adminRole
// In practice only the coins-related pages need the extra protection of adminRoles being specified on the route
export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AdminPage,
    canActivate: [AdminGuard],
    data: {
      title: 'Admin menu'
    }
  },
  {
    path: 'add-advertiser-access',
    component: AddAdvertiserAccessComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Add advertiser access'
    }
  },
  {
    path: 'add-icon-badge',
    component: AddIconBadgeComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Add icon badge'
    }
  },
  {
    path: 'add-remove-coins',
    component: AddRemoveCoinsComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Add/redeem coins'
    }
  },
  {
    path: 'add-romance-access',
    component: AddRomanceAccessComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Add romance access'
    }
  },
  {
    path: 'add-text-badge',
    component: AddTextBadgeComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Add text badge'
    }
  },
  {
    path: 'approve-marketplace',
    component: ApproveMarketplaceComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Approve Marketplace'
    }
  },
  {
    path: 'approve-messages',
    component: ApproveMessagesComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Approve Messages'
    }
  },
  {
    path: 'approve-share',
    component: ApproveShareComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Approve Share'
    }
  },
  {
    path: 'approve-socials',
    component: ApproveSocialsComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Approve Socials'
    }
  },
  {
    path: 'change-role',
    component: ChangeRoleComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Change Role'
    }
  },
  {
    path: 'coins-edit/:id/:name',
    component: CoinsEditPage,
    canActivate: [AdminGuard],
    data: {
      adminRoles: [AdminRole.FINANCIAL, AdminRole.SUPER],
      title: 'Edit coins'
    }
  },
  {
    path: 'delete-member',
    component: DeleteMemberComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Delete member'
    }
  },
  {
    path: 'delete-member-messages',
    component: DeleteMemberMessagesComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Delete member messages'
    }
  },
  {
    path: 'impersonate',
    component: ImpersonateComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Impersonate'
    }
  },
  {
    path: 'logs/cancellation-log',
    component: CancellationLogComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Cancellation log'
    }
  },
  {
    path: 'logs/chit-chat-log',
    component: ChitChatLogComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Chit-chat log'
    }
  },
  {
    path: 'logs/coins-log',
    component: CoinsLogComponent,
    canActivate: [AdminGuard],
    data: {
      adminRoles: [AdminRole.FINANCIAL, AdminRole.SUPER],
      title: 'Coins log'
    }
  },
  {
    path: 'logs/email-log',
    component: EmailLogComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Email log'
    }
  },
  {
    path: 'logs/message-log',
    component: MessageLogComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Message log'
    }
  },
  {
    path: 'logs/note-log',
    component: NoteLogComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Note log'
    }
  },
  {
    path: 'member-detail/:id',
    component: MemberDetailPage,
    canActivate: [AdminGuard],
    data: {
      title: 'Admin member detail'
    }
  },
  {
    path: 'remove-advertiser-access',
    component: RemoveAdvertiserAccessComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Remove advertiser access'
    }
  },
  {
    path: 'remove-icon-badge',
    component: RemoveIconBadgeComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Remove icon badge'
    }
  },
  {
    path: 'remove-romance-access',
    component: RemoveRomanceAccessComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Remove romance access'
    }
  },
  {
    path: 'remove-text-badge',
    component: RemoveTextBadgeComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Remove text badge'
    }
  },
  {
    path: 'reports/catchups-attended-by-member/:memberId',
    component: CatchupsAttendedByMemberComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Report: Catchups attended by member'
    }
  },
  {
    path: 'reports/catchups-by-date',
    component: CatchupsByDateComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Report: Catchups by date'
    }
  },
  {
    path: 'reports/catchups-by-group',
    component: CatchupsByGroupComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Report: Catchups by group'
    }
  },
  {
    path: 'reports/coins-claimed-by-date',
    component: CoinsClaimedByDateComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Report: Coins claimed by date'
    }
  },
  {
    path: 'reports/coins-claimed-by-member/:memberId',
    component: CoinsClaimedByMemberComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Report: Coins claimed by member'
    }
  },
  {
    path: 'reports/last-activity-by-date',
    component: LastActivityByDateComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Report: Last Activity by date'
    }
  },
  {
    path: 'reports/member-activity',
    component: MemberActivityComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Report: Member Activity'
    }
  },
  {
    path: 'reports/next-catchup',
    component: NextCatchupComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Report: Next CatchUp'
    }
  },
  {
    path: 'search-attendance',
    component: SearchAttendanceComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Search catchups attended by member'
    }
  },
  {
    path: 'search-coins-claimed',
    component: SearchCoinsClaimedComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Search coins claimed by member'
    }
  },
  {
    path: 'search-member',
    component: SearchMemberComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Search members'
    }
  },
  {
    path: 'set-password',
    component: SetPasswordComponent,
    canActivate: [AdminGuard],
    data: {
      title: 'Set password'
    }
  },
  {
    path: 'settings',
    component: SettingsListPage,
    canActivate: [AdminGuard],
    data: {
      title: 'Settings list'
    }
  },
  {
    path: 'settings/:id',
    component: SettingsEditPage,
    canActivate: [AdminGuard],
    data: {
      title: 'Edit setting'
    }
  },
  {
    path: 'email-detail/:id',
    component: EmailDetailPage,
    canActivate: [AdminGuard],
    data: {
      title: 'Email detail'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule {}
