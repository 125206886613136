import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ToastService } from '@shared/services/toast.service';
import { UserDatabase } from '@shared/services/user/user.database';
import { BaseAdminComponent } from '../base-admin/base-admin.component';

@Component({
  selector: 'app-search-attendance',
  templateUrl: '../base-admin/base-admin.component.html',
  styleUrls: ['../base-admin/base-admin.component.scss']
})
export class SearchAttendanceComponent extends BaseAdminComponent {
  placeholder = 'Search for the member whose event attendance you want to check';
  showConfirm = false;
  title = 'Search catchups attended';

  constructor(alertController: AlertController, private router: Router, private toastService: ToastService, private userDatabase: UserDatabase) {
    super(alertController);
  }

  selectMemberHandler({ memberId, memberName, data }) {
    this.router.navigate(['/admin/reports/catchups-attended-by-member', memberId]);
  }
}
