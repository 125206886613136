import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { AvatarSize } from '@shared/constants/avatar-size';
import { ALL_COUNTRIES } from '@shared/constants/country';
import { RoomShareMatchStatus } from '@shared/constants/room-share-match-status';
import { IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { IRoomShare } from '@shared/models/trips/room-share';
import { IRoomShareMatch } from '@shared/models/trips/room-share-match';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EmailService } from '@shared/services/email/email.service';
import { ToastService } from '@shared/services/toast.service';
import { RoomShareService } from '@shared/services/trips/room-share.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { TripService } from '@shared/services/trips/trip.service';
import { firestore } from 'firebase/app';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import FieldValue = firestore.FieldValue;

@Component({
  selector: 'room-share-manage-page',
  styleUrls: ['./room-share-manage.page.scss'],
  templateUrl: './room-share-manage.page.html'
})
export class RoomShareManagePage implements OnInit {
  CONSTANTS: any;
  allMembers$: Observable<any>; //Record<string,IRoomShareMatch>>;
  displayNames: Record<string, string> = {};
  readonly RoomShareMatchStatus = RoomShareMatchStatus;
  roomShares: IRoomShare[];
  roomShareSubscription: Subscription;
  size: AvatarSize = AvatarSize.MEDIUM;
  title: string;
  title$: Observable<string>;
  tripId: string;

  constructor(
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private emailService: EmailService,
    private modalController: ModalController,
    private roomShareService: RoomShareService,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private tripService: TripService
  ) {}

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.TRIPS.ROOM_SHARE;
    this.tripId = this.route.snapshot.paramMap.get('tripId');
    this.title$ = this.tripService.getTrip(this.tripId).pipe(
      first(x => !!x),
      map(x => {
        this.title = x.title; // Save title for use in emails
        return x.title;
      })
    );

    this.allMembers$ = this.roomShareService.getRoomShareMatchesForTrip(this.tripId).pipe(
      map(records => {
        this.displayNames = records.displayNames;
        delete records.displayNames;
        delete records.uid;
        return records;
      })
    );
  }

  async onConfirmMatch(member1Id: string, member1DisplayName: string, member2Id: string, match: IRoomShareMatch) {
    const alert = await this.alertController.create({
      header: `Confirm Room Share`,
      message: `<p>Are you sure you want to confirm the room for <b>${member1DisplayName}</b> and <b>${match.displayName}</b>?</p>`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: `Confirm`,
          handler: data => this.onConfirmMatchHandler(member1Id, member1DisplayName, member2Id, match)
        }
      ]
    });

    await alert.present();
  }

  onConfirmMatchHandler(member1Id: string, member1DisplayName: string, member2Id: string, match: IRoomShareMatch) {
    const foundMatches = true; // TODO: Make this dependent on capacity of room type
    const firstMatch = {
      [member2Id]: {
        displayName: match.displayName,
        status: RoomShareMatchStatus.CONFIRMED
      }
    };
    const secondMatch = {
      [member1Id]: {
        displayName: member1DisplayName,
        status: RoomShareMatchStatus.CONFIRMED
      }
    };

    this.roomShareService.updateRoomShareMatches(this.tripId, member1Id, firstMatch, member2Id, secondMatch, foundMatches).then(() => {
      this.analyticsService.eventTrack(AnalyticsCategory.TRIPS, AnalyticsAction.TRIPS_CONFIRM_ROOM_SHARE, this.title, null, { term1: member1DisplayName, term2: match.displayName });
    });
  }

  async onUnconfirmMatch(member1Id: string, member1DisplayName: string, member2Id: string, match: IRoomShareMatch) {
    const alert = await this.alertController.create({
      header: `Confirm Room Share`,
      message: `<p>Are you sure you want to undo the room confirmation for <b>${member1DisplayName}</b> and <b>${match.displayName}</b>?</p>`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: `Undo confirm`,
          handler: data => this.onUnconfirmMatchHandler(member1Id, member1DisplayName, member2Id, match)
        }
      ]
    });

    await alert.present();
  }

  onUnconfirmMatchHandler(member1Id: string, member1DisplayName: string, member2Id: string, match: IRoomShareMatch) {
    const foundMatches = true; // TODO: Make this dependent on capacity of room type
    const firstMatch = {
      [member2Id]: {
        displayName: match.displayName,
        status: RoomShareMatchStatus.AGREED
      }
    };
    const secondMatch = {
      [member1Id]: {
        displayName: member1DisplayName,
        status: RoomShareMatchStatus.AGREED
      }
    };

    this.roomShareService.updateRoomShareMatches(this.tripId, member1Id, firstMatch, member2Id, secondMatch, foundMatches).then(() => {
      this.analyticsService.eventTrack(AnalyticsCategory.TRIPS, AnalyticsAction.TRIPS_UNCONFIRM_ROOM_SHARE, this.title, null, { term1: member1DisplayName, term2: match.displayName });
    });
  }
}
