import { Injectable } from '@angular/core';
import { CountryKey } from '@shared/constants/country';
import { IRoomShare } from '@shared/models/trips/room-share';
import { IRoomShareMatch } from '@shared/models/trips/room-share-match';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';
import { RoomShareDatabase } from './room-share.database';
import FieldValue = firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class RoomShareService {
  constructor(private authService: AuthService, private dateTimeService: DateTimeService, private roomShareDatabase: RoomShareDatabase) {}

  createRoomShareForMember(tripId: string, memberId: string, data: any = {}) {
    const profile = this.authService._userProfileSubject.value; // Should be safe from race conditions by the time the member has typed a description
    const roomShare: IRoomShare = {
      accommodationTypes: ['Shared accommodation'],
      age: this.dateTimeService.getAgeRange(profile),
      country: profile.country,
      description: data.description || '',
      displayName: profile.displayName || '',
      foundMatches: false,
      gender: profile.gender || null,
      location: profile.locality || '',
      matches: {}
    };

    return this.roomShareDatabase.createRoomShareForMember(tripId, memberId, roomShare);
  }

  deleteRoomShareForMember(tripId: string, memberId: string) {
    return this.roomShareDatabase.deleteRoomShareForMember(tripId, memberId);
  }

  getRoomShareForMember(tripId: string, memberId: string): Observable<IRoomShare> {
    return this.roomShareDatabase.getRoomShareForMember(tripId, memberId);
  }

  getRoomShareForTrip(tripId: string, foundMatches: boolean, country: CountryKey): Observable<IRoomShare[]> {
    return this.roomShareDatabase.getRoomShareForTrip(tripId, foundMatches, country);
  }

  getRoomShareMatchesForTrip(tripId: string): Observable<Record<string, any>> {
    return this.roomShareDatabase.getRoomShareMatchesForTrip(tripId);
  }

  strictUpdateRoomShare(tripId: string, memberId: string, data: any) {
    return this.roomShareDatabase.strictUpdateRoomShare(tripId, memberId, data);
  }

  updateRoomShare(tripId: string, memberId: string, data: any) {
    return this.roomShareDatabase.updateRoomShare(tripId, memberId, data);
  }

  updateRoomShareMatches(tripId: string, member1Id: string, member1Data: Record<string, IRoomShareMatch | FieldValue>, member2Id: string, member2Data: Record<string, IRoomShareMatch | FieldValue>, foundMatches: boolean = null) {
    const data1: any = foundMatches !== null ? { foundMatches, matches: member1Data } : { matches: member1Data };
    const data2: any = foundMatches !== null ? { foundMatches, matches: member2Data } : { matches: member2Data };
    return this.roomShareDatabase.updateRoomShare(tripId, member1Id, data1).then(() => {
      return this.roomShareDatabase.updateRoomShare(tripId, member2Id, data2).then(() => {
        const data: any = {
          [member1Id]: member1Data,
          [member2Id]: member2Data
        };
        const member1Info = member2Data[member1Id];
        const member2Info = member1Data[member2Id];
        if (this.isRoomShareMatch(member1Info) && this.isRoomShareMatch(member2Info)) {
          const displayNames = {
            [member1Id]: member1Info.displayName,
            [member2Id]: member2Info.displayName
          };
          data.displayNames = displayNames;
        }
        return this.roomShareDatabase.updateRoomShareMatches(tripId, data);
      });
    });
  }

  private isRoomShareMatch(toBeDetermined: IRoomShareMatch | FieldValue): toBeDetermined is IRoomShareMatch {
    if ((toBeDetermined as IRoomShareMatch).displayName) {
      return true;
    }
    return false;
  }
}
