import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { GamesPage } from './games.page';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      },
      {
        path: 'bingo',
        loadChildren: () => import('./bingo/bingo.module').then(m => m.BingoPageModule)
      },
      {
        path: 'list',
        component: GamesPage
      }
    ])
  ],
  declarations: [GamesPage]
})
export class GamesPageModule {}
