import { Injectable } from '@angular/core';
import { divIcon, FeatureGroup, marker } from 'leaflet';
import { IMapPin } from '@shared/models/map-pin';

@Injectable({
  providedIn: 'root'
})
export class AbstractMarkerService {
  constructor() {}

  addMarker(location: IMapPin, markerGroup: FeatureGroup, showCount: boolean): void {
    const myLocationClass = location.me ? ' my-location' : '';
    const locationCount = `${location.count}` !== 'undefined' ? `${location.count}` : '';
    const mapMarker = marker([location.lat, location.long], {
      icon: divIcon({
        className: `chirpy-locator-map__chirpy-marker${myLocationClass}`,
        html: showCount ? locationCount : ''
      })
    }).addTo(markerGroup);
    // Add custom data to geoJSON Marker.feature
    mapMarker.feature = {
      type: 'Feature',
      geometry: undefined,
      properties: {
        count: location.count,
        locality: location.itemName,
        placeId: location.itemId
      }
    };
  }

  async presentMapPinsModal(event: any, items: any[]) {}

  updateMarkers(data: any[], markerGroup: FeatureGroup, showCount: boolean) {}
}
