import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { AvatarSize } from '@shared/constants/avatar-size';
import { PhotoSize } from '@shared/constants/photo-size';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UserService } from '@shared/services/user/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'chirpy-avatar-list',
  templateUrl: './chirpy-avatar-list.component.html',
  styleUrls: ['./chirpy-avatar-list.component.scss']
})
export class ChirpyAvatarListComponent implements OnInit {
  get avatars() {
    if (this.oldMemberIds !== this.memberIds) {
      this.oldMemberIds = this.memberIds;
      this.clearAvatars();
      this.getImageAvatars();
    }
    return { images: this.imageAvatars, placeholders: this.placeholderAvatars };
  }

  imageAvatars: Record<string, string> = {};
  imageSize: string;
  oldMemberIds: string[];
  placeholderAvatars: any[] = [];
  @Input() memberIds: string[] = [];
  @Input() size: string = 'default';
  private subscriptions: Record<string, Subscription> = {};

  constructor(private subscriptionService: SubscriptionService, private userService: UserService) {}

  clearAvatars() {
    this.placeholderAvatars = [];
    this.imageAvatars = {}; // TODO: just filter out images not matching current memberIds?
    this.oldMemberIds = this.memberIds;
  }

  getImageAvatars(): void {
    const MAXIMUM_AVATARS_TO_DISPLAY = 4;

    this.placeholderAvatars = [];

    // TODO: Revisit implementation of this.
    // Change detection is calling this too often, and it doesn't play nicely if contained in a component with onPush change detection
    let count = 0;
    for (const id of this.memberIds) {
      count += 1;
      if (count > MAXIMUM_AVATARS_TO_DISPLAY) {
        continue;
      }

      this.subscriptions[id] = this.userService.getUserProfile(id).subscribe(profile => {
        if (profile != null) {
          this.imageAvatars[id] = this.userService.getAvatarUrl(profile, this.imageSize);
        }
      });
      this.subscriptionService.add(this.subscriptions[id]);
    }
  }

  getPlaceholderAvatar(): void {
    this.placeholderAvatars = [true];
  }

  ngOnInit() {
    this.getPlaceholderAvatar(); // TODO: Is there any point having placeholders, as they get cleared whenever getImageAvatars or avatars is called
    this.oldMemberIds = this.memberIds;
    this.imageSize = this.size === AvatarSize.LARGE || this.size === AvatarSize.XLARGE ? PhotoSize.LARGE : PhotoSize.THUMBNAIL;
    this.getImageAvatars();
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    Object.entries(this.subscriptions).forEach(item => {
      this.subscriptionService.clearSubscription(item[1]);
    });
  }
}
