import { Component, OnDestroy, OnInit } from '@angular/core';
import { IMemberThread, IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { IMemberThreadType } from '@shared/models/messages/member-thread-type';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { MessageService } from '@shared/services/messages/message.service';
import { ThreadOpenService } from '@shared/services/messages/thread-open.service';
import { UserService } from '@shared/services/user/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'thread-list',
  templateUrl: './thread-list.component.html',
  styleUrls: ['./thread-list.component.scss']
})
export class ThreadListComponent implements OnInit, OnDestroy {
  get CONSTANTS() {
    return this.constantsService.constants.MESSAGES.THREADS;
  }

  hasMessages: boolean = false;
  readonly IMemberThreadType = IMemberThreadType;
  memberThreadRef: Subscription;
  onboardingMessage: string;
  threads: IMemberThread[];
  threadsLoaded: boolean = false;

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private constantsService: ConstantsService, private dateTimeService: DateTimeService, public messageService: MessageService, private threadOpenService: ThreadOpenService, private userService: UserService) {}

  getDateFormat(thread: IMemberThread): string {
    return this.dateTimeService.getDateFormat(thread.dateTimeLastUpdated);
  }

  getDetailRoute(thread: IMemberThread): any {
    return '/messages/threads/' + thread.threadId;
  }

  getRelatedType(thread: IMemberThread) {
    switch (thread.type) {
      case IMemberThreadType.Group:
        return IMemberThreadRelatedType.Event; // show text based avatars for groups

      case IMemberThreadType.DirectMessage:
      case IMemberThreadType.Conversation:
      default:
        return IMemberThreadRelatedType.Member;
    }
  }

  ionViewWillEnter() {
    this.analyticsService.eventTrack(AnalyticsCategory.MESSAGES, AnalyticsAction.MESSAGES_VIEW_LIST);
  }

  isUnread(thread: IMemberThread): boolean {
    return this.threadOpenService.isOpenForMeHere(thread.uid) === false && (thread.dateTimeLastUpdated > thread.dateTimeLastRead || thread.messageNotificationCount > 0);
  }

  maybeClearMessageCount() {
    // If the profile or threads are not loaded we can't check if the count is synced
    if (this.authService.isImpersonating || this.authService._userProfileSubject.value == null || !this.threadsLoaded || !this.hasMessages) return;

    const unreadCountFromProfile = this.authService._userProfileSubject.value.messageNotificationCount;
    // If there are no unread messages we don't need to do anything
    if (unreadCountFromProfile === 0) return;

    // If count has somehow gone negative, reset it to zero
    if (unreadCountFromProfile < 0) {
      this.userService.setOwnMessageNotificationCount(0);
      return;
    }

    const unreadCountFromThreads = this.threads.reduce((total, thread) => {
      if (thread.messageNotificationCount) total = total + thread.messageNotificationCount;
      return total;
    }, 0);

    if (unreadCountFromThreads !== unreadCountFromProfile) {
      this.userService.setOwnMessageNotificationCount(unreadCountFromThreads);
    }
  }

  ngOnDestroy() {
    if (this.memberThreadRef) {
      this.memberThreadRef.unsubscribe();
    }
  }

  ngOnInit() {
    this.memberThreadRef = this.messageService.getMemberThreads().subscribe(threads => {
      threads = threads
        // when lastMessage is null it means the user has added a contact but not messaged this person yet. So these shouldn't show up in the threads list.
        .filter(t => t.lastMessage != null)
        .filter(t => t.isArchived !== true); // don't filter with === true because this is null by default

      this.threads = threads;

      this.threadsLoaded = true;
      this.hasMessages = !!this.threads.length;

      this.maybeClearMessageCount();
    });

    this.onboardingMessage = this.CONSTANTS.LIST.onboardingMessage;
  }
}
