import { Injectable, OnDestroy } from '@angular/core';
import { IMessage } from '@shared/models/messages/message';
import { DateTimeService } from '@shared/services/date-time.service';
import { MessageService } from '@shared/services/messages/message.service';
import { Subject } from 'rxjs';
import { skipWhile, take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChirpyMessageListService implements OnDestroy {
  get member() {
    return this.messageService.user;
  }

  _messages: IMessage[] = [];
  messages: Record<string, IMessage[]> = {};
  readonly MESSAGES_TO_FETCH = 20;
  noMorePastMessages: boolean;
  // kills the get message observable when the thread-detail tab changes from one thread to another, prevents double messages from appearing.
  stopReceivingMessages$ = new Subject();

  constructor(private dateTimeService: DateTimeService, private messageService: MessageService) {}

  deleteMessage(messageId: string) {
    const index = this._messages.findIndex(x => x.uid === messageId);
    if (index !== -1) {
      this._messages.splice(index, 1);
    }
    this.messages = this.groupMessagesByDate(this._messages);
  }

  getInitialMessages(threadId: string, dateStart: number, messagesToTake: number) {
    return this.messageService.getMessages(threadId, true, dateStart, this.MESSAGES_TO_FETCH).pipe(
      skipWhile(m => !m),
      // only accept new messages once.
      // However if the previous page has a widget showing messages these will be loaded from the cache
      // before the rest are fetched from the server, so we need to take messages twice
      take(messagesToTake),
      // let the component kill receiving messages for this threadId when the component re-initialises
      takeUntil(this.stopReceivingMessages$)
    );
  }

  getNewMessages(threadId: string, dateStart: number) {
    // NB The limit on MESSAGES_TO_FETCH is not applied by messageService when listening for new messages
    return this.messageService.getMessages(threadId, false, dateStart, this.MESSAGES_TO_FETCH).pipe(
      skipWhile(m => !m),
      take(1),
      takeUntil(this.stopReceivingMessages$)
    );
  }

  getPastMessages(threadId: string, dateStart: number) {
    return this.messageService.getMessages(threadId, true, dateStart, this.MESSAGES_TO_FETCH).pipe(
      skipWhile(m => !m),
      take(1),
      takeUntil(this.stopReceivingMessages$)
    );
  }

  groupMessagesByDate(messages: IMessage[]) {
    const groupedMessages: Record<number, IMessage[]> = {};

    messages.map(value => {
      const day = this.dateTimeService.getDay(value.dateTimeSent);
      groupedMessages[day] = groupedMessages[day] || [];
      groupedMessages[day].push(value);
    });

    return groupedMessages;
  }

  listenForNewMessages(updateUI: () => void, threadId: string, dateStart: number) {
    this.getNewMessages(threadId, dateStart).subscribe(messages => {
      if (messages.length > 0) {
        dateStart = messages[0].dateTimeLastUpdated; // update the date used by the Firestore query function to get the latest messages
      }

      this.processMessages(messages);

      updateUI();

      // This subscribe call only happens once, because of take(1) in getNewMessages
      // Call listenForNewMessages again with new dateStart to keep listening for messages
      this.listenForNewMessages(updateUI, threadId, dateStart);
    });
  }

  loadInitialMessages(updateUI: () => void, threadId: string, dateStart: number, messagesToTake: number = 1) {
    this.getInitialMessages(threadId, dateStart, messagesToTake).subscribe(messages => {
      // if the initial messages load doesn't have more than 10 messages, you've got no past messages to load.
      //
      // Technically this logic is incorrect if you've got some matching results in the cache when you make the query
      // (e.g. from a widget on the previous page).
      // However in this case messagesToTake is > 1, so the subscribe callback gets called >1 times,
      // and so hasLoadedAllMessagesWithInitialLoad will get correctly set once results come back from the server
      const hasLoadedAllMessagesWithInitialLoad = messages.length < this.MESSAGES_TO_FETCH;
      this.noMorePastMessages = hasLoadedAllMessagesWithInitialLoad;
      this.processMessages(messages);

      updateUI();
    });
  }

  loadPastMessages(updateUI: () => void, threadId: string) {
    const isLoadingInitialMessages = this._messages.length === 0;
    if (isLoadingInitialMessages) {
      // don't load past messages when we have 0 messages because,
      // 1. the thread has no messages yet (and has no past history to show)
      // 2. the messages have not finished loading (we don't want to load past messages yet until the user
      // has enough messages to scroll upwards into the boundaries of the ion-infinite-scroll component) at the top
      // of the ion-list.
      return;
    }

    const loadMessagesOlderThan = this._messages[0].dateTimeSent;
    this.getPastMessages(threadId, loadMessagesOlderThan).subscribe(messages => {
      if (messages.length === 0 || messages.length < this.MESSAGES_TO_FETCH) {
        this.noMorePastMessages = true;
      }

      this.processPastMessages(messages);

      updateUI();
    });
  }

  ngOnDestroy() {
    this.stopReceivingMessages$.next(); //In principle ngOnDestroy on the component should also call this
  }

  processMessages(messages: IMessage[]) {
    const newMessages = [];
    // add new entries, update existing entries
    messages.forEach(m => {
      const item = this._messages.find(x => x.uid === m.uid);
      if (item != null) {
        // update anything in the list that has changed.
        const hasChanges = JSON.stringify(item) !== JSON.stringify(m);
        if (hasChanges) Object.assign(item, m);
      } else {
        // add new data
        newMessages.push(m);
      }
    });

    const allMessagesUnsorted = newMessages.concat(this._messages);
    this._messages = allMessagesUnsorted.sort((a, b) => (a.dateTimeSent >= b.dateTimeSent ? 1 : -1)).sort();
    this.messages = this.groupMessagesByDate(this._messages);
  }

  processPastMessages(messages: IMessage[]) {
    this._messages = messages.reverse().concat(this._messages); // prepend past messages to the start of the thread
    this.messages = this.groupMessagesByDate(this._messages);
  }

  resetMessages() {
    this._messages = [];
    this.messages = {};
    this.noMorePastMessages = false;
  }
}
