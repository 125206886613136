import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { MessagesMasterViewComponent } from './components/master-view/master-view.component';
import { MessagesRoutingModule } from './messages-routing.module';
import { MessagesPage } from './messages.page';
import { ContactsModule } from './modules/contacts/contacts.module';
import { MoreModule } from './modules/more/more.module';
import { ThreadsModule } from './modules/threads/threads.module';
import { ThreadDetailCatchupService } from '@shared/services/messages/thread-detail-catchup.service';

@NgModule({
  imports: [MessagesRoutingModule, SharedModule, ContactsModule, MoreModule, ThreadsModule],
  declarations: [MessagesMasterViewComponent, MessagesPage],
  providers: [ThreadDetailCatchupService],
  exports: [SharedModule, ContactsModule, ThreadsModule]
})
export class MessagesPageModule {}
