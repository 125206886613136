import { Injectable } from '@angular/core';
import { Country } from '@shared/constants/country';
import { IPlace } from '@shared/models/place';
import { UserObject } from '@shared/models/user-object';
import { BaseDatabase } from '@shared/services/base.database';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ILocationInput } from '../models/location-input';

@Injectable({
  providedIn: 'root'
})
export class SignupLocationDatabase extends BaseDatabase {
  getPlace(uid: string): Observable<IPlace> {
    const useCache = true;
    return this.getDocument<IPlace>(this.COLLECTION.CENTRAL_PLACES, uid, useCache);
  }
}
