// create particle
export class Particle {
  coordinates = [];
  coordinateCount = 5;
  brightness: number;
  alpha = 1;
  speed: number;
  friction = 0.95;
  angle: number;
  gravity = 1;
  decay: number;
  hue: number;
  constructor(private ctx: CanvasRenderingContext2D, private x: number, private y: number, hue: number) {
    // track the past coordinates of each particle to create a trail effect, increase the coordinate count to create more prominent trails
    this.coordinates = [];
    this.coordinateCount = 5;
    while (this.coordinateCount--) {
      this.coordinates.push([this.x, this.y]);
    }
    // set a random angle in all possible directions, in radians
    this.angle = this.random(0, Math.PI * 2);
    this.speed = this.random(1, 10);
    // friction will slow the particle down
    this.friction = 0.95;
    // gravity will be applied and pull the particle down
    this.gravity = 1;
    // set the hue to a random number +-50 of the overall hue variable
    this.hue = this.random(hue - 50, hue + 50);
    this.brightness = this.random(50, 80);
    this.alpha = 1;
    // set how fast the particle fades out
    this.decay = this.random(0.015, 0.03);
  }

  // get a random number within a range
  random(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  draw() {
    this.ctx.beginPath();
    // move to the last tracked coordinates in the set, then draw a line to the current x and y
    this.ctx.moveTo(this.coordinates[this.coordinates.length - 1][0], this.coordinates[this.coordinates.length - 1][1]);
    this.ctx.lineTo(this.x, this.y);
    this.ctx.strokeStyle = 'hsla(' + this.hue + ', 100%, ' + this.brightness + '%, ' + this.alpha + ')';
    this.ctx.stroke();
  }

  update() {
    // remove last item in coordinates array
    this.coordinates.pop();
    // add current coordinates to the start of the array
    this.coordinates.unshift([this.x, this.y]);
    // slow down the particle
    this.speed *= this.friction;
    // apply velocity
    this.x += Math.cos(this.angle) * this.speed;
    this.y += Math.sin(this.angle) * this.speed + this.gravity;
    // fade out the particle
    this.alpha -= this.decay;

    // remove the particle once the alpha is low enough, based on the passed in index
    if (this.alpha <= this.decay) {
      return true;
    }
    return false;
  }
}

// create firework
export class Firework {
  x: number;
  y: number;
  distanceToTarget: number;
  distanceTraveled = 0;
  coordinateCount = 3;
  coordinates = [];
  angle: number;
  speed = 2;
  targetRadius = 1;
  acceleration = 1.05;
  brightness: number;

  constructor(private ctx: CanvasRenderingContext2D, private sx: number, private sy: number, private tx: number, private ty: number, private hue: number) {
    // distance from starting point to target
    this.x = sx;
    this.y = sy;

    this.distanceToTarget = this.calculateDistance(sx, sy, tx, ty);
    this.distanceTraveled = 0;
    // track the past coordinates of each firework to create a trail effect, increase the coordinate count to create more prominent trails
    this.coordinates = [];
    // populate initial coordinate collection with the current coordinates
    while (this.coordinateCount--) {
      this.coordinates.push([this.x, this.y]);
    }
    this.angle = Math.atan2(ty - sy, tx - sx);
    this.speed = 2;
    this.acceleration = 1.05;
    this.brightness = this.random(50, 70);
    // circle target indicator radius
    this.targetRadius = 1;
  }

  // calculate the distance between two points
  calculateDistance(p1x: number, p1y: number, p2x: number, p2y: number) {
    const xDistance = p1x - p2x;
    const yDistance = p1y - p2y;
    return Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));
  }

  // get a random number within a range
  random(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }

  draw() {
    this.ctx.beginPath();
    // move to the last tracked coordinate in the set, then draw a line to the current x and y
    this.ctx.moveTo(this.coordinates[this.coordinates.length - 1][0], this.coordinates[this.coordinates.length - 1][1]);
    this.ctx.lineTo(this.x, this.y);
    this.ctx.strokeStyle = 'hsl(' + this.hue + ', 100%, ' + this.brightness + '%)';
    this.ctx.stroke();

    this.ctx.beginPath();
    // draw the target for this firework with a pulsing circle
    this.ctx.arc(this.tx, this.ty, this.targetRadius, 0, Math.PI * 2);
    this.ctx.stroke();
  }

  update() {
    // remove last item in coordinates array
    this.coordinates.pop();
    // add current coordinates to the start of the array
    this.coordinates.unshift([this.x, this.y]);

    // cycle the circle target indicator radius
    if (this.targetRadius < 8) {
      this.targetRadius += 0.3;
    } else {
      this.targetRadius = 1;
    }

    // speed up the firework
    this.speed *= this.acceleration;

    // get the current velocities based on angle and speed
    const vx = Math.cos(this.angle) * this.speed;
    const vy = Math.sin(this.angle) * this.speed;
    // how far will the firework have traveled with velocities applied?
    this.distanceTraveled = this.calculateDistance(this.sx, this.sy, this.x + vx, this.y + vy);

    // if the distance traveled, including velocities, is greater than the initial distance to the target, then the target has been reached
    if (this.distanceTraveled >= this.distanceToTarget) {
      return true;
    } else {
      // target not reached, keep traveling
      this.x += vx;
      this.y += vy;
      return false;
    }
  }
}
