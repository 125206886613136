import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SignupGenderPresenter {
  form = this.formBuilder.group({
    gender: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}

  setFormValue(gender: string): void {
    this.form.patchValue({ gender });
  }

  submit(): any {
    return this.form.value;
  }
}
