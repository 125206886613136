import { Component, ViewChild } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ChirpyMemberSearchComponent } from '@shared/components/chirpy-member-search/chirpy-member-search.component';
import { UserObject } from '@shared/models/user-object';
import { Observable } from 'rxjs';
import { IInput } from '@shared/models/input';

@Component({
  selector: 'app-base-admin',
  templateUrl: './base-admin.component.html',
  styleUrls: ['./base-admin.component.scss']
})
export class BaseAdminComponent {
  alert: any;
  confirmHeader: string;
  confirmButton: string;
  confirmInputs: IInput[];
  placeholder: string;
  showConfirm: boolean = true;
  title: string;
  @ViewChild(ChirpyMemberSearchComponent, { static: true }) memberSearchComponent: ChirpyMemberSearchComponent;

  constructor(protected alertController: AlertController) {}

  get membersToAdd() {
    return this.memberSearchComponent.searchResults;
  }

  ionViewWillEnter() {
    // ngOnInit doesn't trigger if you come back from a navigation stack, ionViewWillEnter / ionViewDidEnter will.
    this.memberSearchComponent.clearSearch();
    this.memberSearchComponent.focusSearchBar();
  }

  confirmMessage(memberId: string, memberName: string) {
    return 'Are you sure you want to do this?';
  }

  ngOnInit() {
    this.setup(); // Allow child components to dynamically initialise variables
  }

  async onSelectMember({ memberId, memberName }) {
    if (this.showConfirm) {
      this.alert = await this.alertController.create({
        header: this.confirmHeader,
        message: this.confirmMessage(memberId, memberName),
        inputs: this.confirmInputs,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: this.confirmButton,
            handler: data => this.selectMemberHandler({ memberId, memberName, data })
          }
        ]
      });

      await this.alert.present();
    } else {
      const data: any = {};
      this.selectMemberHandler({ memberId, memberName, data });
    }
  }

  selectMemberHandler({ memberId, memberName, data }) {}

  setup() {}
}
