import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminRole } from '@shared/constants/admin-role';
import { MessageType } from '@shared/constants/message-type';
import { IMember } from '@shared/models/messages/member';
import { IMessageData } from '@shared/models/messages/message-data';
import { IMemberThread, IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { IMemberThreadType } from '@shared/models/messages/member-thread-type';
import { AuthService } from '@shared/services/auth.service';
import { MessageHelperService } from '@shared/services/messages/message-helper.service';
import { MessageService } from '@shared/services/messages/message.service';
import { UserService } from '@shared/services/user/user.service';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ComposeService {
  get isCreatingGroup() {
    const isAdminCreatingThreadWithAtLeastOneMember = this.recipients != null && this.authService.isAdmin([AdminRole.HOSTS, AdminRole.TRAVEL]);
    const isHostCreatingGroup = this.recipients != null && this.recipients.length > 1 && this.userService.isHost(this.member);

    return isAdminCreatingThreadWithAtLeastOneMember || isHostCreatingGroup;
  }

  get isGroup() {
    return this.isCreatingGroup && this.composeForm.value.groupName.length > 0;
  }

  get member() {
    return this.messageService.user;
  }

  composeForm: FormGroup;
  recipients: IMember[] = [];

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private messageHelperService: MessageHelperService, private messageService: MessageService, private userService: UserService) {
    this.composeForm = this.formBuilder.group({
      groupName: ['', Validators.maxLength(100)]
    });
  }

  addMemberToThread(uid: string) {
    this.userService
      .getUserProfile(uid)
      .pipe(first(x => !!x))
      .subscribe(user => {
        this.addMemberToThreadHandler(user.uid, user.displayName);
      });
  }

  addMemberToThreadHandler(memberId: string, memberName: string) {
    if (!this.messageHelperService.canAddMemberToThread(this.isCreatingGroup, this.recipients, memberId)) {
      return;
    }

    const memberExists = this.recipients.find((member: IMember) => member.memberId === memberId);
    if (!memberExists) {
      this.recipients.push({ memberId, memberName });
    }
  }

  createThread(memberThreadType: IMemberThreadType, title: string) {
    const members: Record<string, string> = {};
    this.recipients.reduce((result, item) => {
      result[item.memberId] = item.memberName;
      return result;
    }, members);

    return this.messageService.createThread(members, IMemberThreadRelatedType.Member, memberThreadType, title, null, [this.member.uid]).then(result => {
      return result;
    });
  }

  // don't show recipients or yourself in Search Results.
  filterSearchResults(searchResults: Record<string, string>) {
    const results: Record<string, string> = {};

    Object.keys(searchResults).forEach(uid => {
      const isRecipient = this.recipients.length > 0 && this.recipients.some(r => r.memberId === uid);
      const isMe = uid === this.member.uid;
      if (!isRecipient && !isMe) {
        results[uid] = searchResults[uid];
      }
    });

    return results;
  }

  getMemberThreads() {
    return this.messageService.memberThreads;
  }

  getThreadIdForMembers() {
    const subject = new BehaviorSubject(null);
    const memberIds = this.recipients.map(mt => mt.memberId);
    const threadId = this.getThreadIdForMembersHandler(this.messageService.memberThreads, memberIds, this.getThreadType());
    const threadTitle = this.isGroup ? this.composeForm.value.groupName : null;

    if (threadId == null) {
      // CREATE NEW THREAD
      const memberThreadType = this.getThreadType();
      this.createThread(memberThreadType, threadTitle).then(result => {
        subject.next(result.id);
        this.composeForm.controls.groupName.setValue('');
        // run outside angular so the compose component can read the value of the behavior subject before it's stopped.
        setTimeout(() => {
          subject.complete();
        }, 0);
      });
    } else {
      // RETURN EXISTING THREAD
      subject.next(threadId);
      this.composeForm.controls.groupName.setValue('');
      // run outside angular so the compose component can read the value of the behavior subject before it's stopped.
      setTimeout(() => {
        subject.complete();
      }, 0);
    }

    return subject;
  }

  getThreadIdForMembersHandler(memberThreads: IMemberThread[], relatedIds: string[], memberThreadType: IMemberThreadType) {
    let threadId: string = null;
    memberThreads.forEach(thread => {
      // Find an existing DirectMessage or Conversation to send messages to.
      // If the relatedIds match a Group thread, ignore it so we can create a new group thread.
      if (thread.relatedIds.sort().toString() === relatedIds.sort().toString() && thread.type !== IMemberThreadType.Group && thread.type === memberThreadType) {
        threadId = thread.threadId;
      }
    });

    return threadId;
  }

  getThreadType() {
    if (this.isGroup) {
      return IMemberThreadType.Group;
    }

    if (this.recipients.length === 1) {
      return IMemberThreadType.DirectMessage;
    }

    return IMemberThreadType.Conversation;
  }

  removeMemberFromThread(memberId: string) {
    const index = this.recipients.findIndex((member: IMember) => member.memberId === memberId);
    if (index > -1) {
      this.recipients.splice(index, 1);
    }
  }

  sendMessage(messageData, threadId: string) {
    const threadName = this.recipients[0].memberName;
    return this.messageService.createMessage(messageData, threadId, MessageType.THREAD, this.getThreadType(), { threadName });
  }

  updateMemberThreads(threadId: string, data: any) {
    return this.messageService.updateMemberThreads(threadId, data);
  }

  validateGroupName() {
    const groupName = this.composeForm.controls.groupName.value;
    return !this.isGroup || (this.isGroup && this.messageService.isGroupNameUnique(this.getMemberThreads(), groupName));
  }
}
