import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IBaseEvent } from '@shared/models/base-event';
import { ICatchup } from '@shared/models/catchups/catchup';
import { ICatchupOptions } from '@shared/models/catchups/catchup-options';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { AuthService } from '@shared/services/auth.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { CatchupTypeService } from '@shared/services/catchups/catchup-types.service';
import { RegionService } from '@shared/services/regions/region.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';

@Component({
  selector: 'chirpy-catchup-list',
  styleUrls: ['chirpy-catchup-list.component.scss'],
  templateUrl: './chirpy-catchup-list.component.html'
})
export class ChirpyCatchupListComponent {
  get canCreateCatchup() {
    return this.authService.isHostOrAdmin();
  }

  get hasRecords() {
    return this.catchups != null && !!this.catchups.length;
  }

  catchup: ICatchup;
  @Input() catchups: ICatchup[];
  @Input() catchupOptions: ICatchupOptions | null;
  @Input() groupId: string;
  @Input() groupName: string;
  @Output() linkClicked = new EventEmitter();
  @Input() loading: boolean = false;
  @Output() loadMoreCatchups = new EventEmitter();
  @Input() loadMoreLink: string; // RouterLink?
  @Input() loadMoreLinkMessage: string;
  @Input() noCatchupsMessage: string = 'No CatchUps found.';
  @Input() pastCatchups: ICatchup[] = [];
  @Input() recordsLoaded: boolean = false;
  @Output() resetSearch = new EventEmitter();
  @Input() showLoadMoreButton: boolean = false;
  @Input() showLoadMoreLink: boolean = false;
  @Input() showNumberGoing: boolean = false;
  showPastCatchups: boolean = true;
  @Input() viewAllLabel: string = 'View all catchups';
  @Input() widget: boolean = false;

  constructor(private authService: AuthService, private catchupService: CatchupService, private catchupTypeService: CatchupTypeService, private constantsService: ConstantsService, private dateTimeService: DateTimeService, private regionService: RegionService) {}

  canShowAddress(catchup: ICatchup) {
    return this.catchupTypeService.canShowAddress(catchup.eventType);
  }

  canShowRegion(catchup: ICatchup) {
    return this.catchupTypeService.canShowRegion(catchup.eventType);
  }

  getCatchupIconText(catchup: ICatchup) {
    if (catchup.region != null && catchup.region.startsWith('Virtual')) {
      const countries = this.regionService.getCountryForRegion(catchup.region);
      return countries.length === 1 ? countries[0] : countries;
    }

    // This code is specific to show NI/SI for NZ, for Australia it's not needed because the States are displayed the same in the catchups list icons.
    const region = this.regionService.catchupRegions.filter(x => x.name === catchup.region)[0];

    // Display anything to prevent page from breaking.
    if (region == null) return catchup.region.slice(0, 1) || catchup.country[0];

    return region.area;
  }

  getDateFormat(catchup: ICatchup): string {
    return this.dateTimeService.getDateFormat(catchup.datetime);
  }

  getEndTime(catchup) {
    if (catchup == null || catchup.date == null || catchup.endTime == null) return null;
    return this.dateTimeService.createDate(catchup.date, catchup.endTime);
  }

  getRouterLink(catchup: ICatchup) {
    // TODO: If the URL is rebranded use that route here, so routerLinkActive works correctly
    return this.groupId ? ['/catchups/group', this.groupId, this.groupName, catchup.uid] : ['/catchups', catchup.uid];
  }

  getTime(catchup) {
    if (catchup == null || catchup.date == null || catchup.time == null) return null;
    return this.dateTimeService.createDate(catchup.date, catchup.time);
  }

  isGoing(catchup: IBaseEvent): boolean {
    return this.catchupService.isGoing(catchup);
  }

  isShared(catchup: ICatchup): boolean {
    return this.catchupService.isShared(catchup);
  }

  numberGoing(catchup: IBaseEvent): string {
    return this.catchupService.numberGoing(catchup);
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }

  onLoadMoreCatchups($event) {
    this.loadMoreCatchups.emit($event);
  }

  onTogglePastCatchups() {
    this.showPastCatchups = !this.showPastCatchups;
  }
}
