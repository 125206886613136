import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ConstantsService } from '@shared/services/constants.service';
import { UserService } from '@shared/services/user/user.service';
import { first } from 'rxjs/operators';
import { ISocial } from '@shared/models/social/social';
import { SocialService } from '@shared/services/social/social.service';

@Component({
  selector: 'social-locator-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['pin.component.scss']
})
export class SocialLocatorPinComponent {
  get ASSETS() {
    return this.constantsService.constants.ASSETS;
  }

  @Input() count: number;
  @Input() socials: ISocial[] = [];
  @Input() placeName: string;

  constructor(private socialService: SocialService, private constantsService: ConstantsService, private modalController: ModalController, private router: Router, private userService: UserService) {}

  isInterested(social: ISocial): boolean {
    return this.socialService.isInterested(social);
  }

  numberInterested(social: ISocial): string {
    return this.socialService.numberInterested(social);
  }

  onClose() {
    this.modalController.dismiss();
  }

  openSocialPage(social: ISocial) {
    this.modalController.dismiss(); // close map pin modal before navigating to a different page.
    this.router.navigate(['/social', social.uid]);
  }
}
