import { Injectable } from '@angular/core';
import { MessageType } from '@shared/constants/message-type';
import { IMemberThreadType } from '@shared/models/messages/member-thread-type';
import { IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { AuthService } from '@shared/services/auth.service';
import { GroupService } from '@shared/services/groups/group.service';
import { MessageService } from '@shared/services/messages/message.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { SignupDisplayNameDatabase } from './display-name.database';

@Injectable({
  providedIn: 'root'
})
export class SignupDisplayNameService {
  constructor(private appOptionsService: AppOptionsService, private authService: AuthService, private database: SignupDisplayNameDatabase, private groupService: GroupService, private messageService: MessageService) {}

  takeAutomatedActions(displayName: string, uid: string) {
    this.appOptionsService
      .getOptionsValues('welcomeSettings', 'ALL')
      .pipe(first(x => !!x))
      .subscribe((settings: any[]) => {
        if (settings.length > 0 && settings[0].groupId) {
          const groupName = ''; // only used for toast message, which is not shown
          const groupMemberCount = 0; // only used to disable notifications for large groups, we want notifications set for new members
          const memberName = ''; // only used for toast message, which is not shown
          const showToast = false;
          this.groupService.addMemberToGroup(settings[0].groupId, groupName, groupMemberCount, uid, memberName, showToast);
        }

        if (settings.length > 0 && settings[0].senderId && settings[0].message) {
          this.database
            .threadExists$(uid, settings[0].senderId)
            .pipe(first(x => x != null))
            .subscribe(exists => {
              if (!exists) {
                const members = {
                  [uid]: displayName,
                  [settings[0].senderId]: settings[0].senderName
                };
                const content = settings[0].message.replace(/{{\s*displayName\s*}}/g, displayName);
                this.messageService.createThread(members, IMemberThreadRelatedType.Member, IMemberThreadType.DirectMessage).then(saved => {
                  return this.messageService.createMessage(
                    {
                      text: content,
                      media: null
                    },
                    saved.id,
                    MessageType.THREAD,
                    IMemberThreadType.DirectMessage,
                    {},
                    settings[0].senderName,
                    settings[0].senderId
                  );
                });
              }
            });
        }
      });
  }

  setDisplayName(displayName: string): void {
    const capitalisedName = displayName.charAt(0).toUpperCase() + displayName.slice(1);
    // Don't need to wait for any of these to complete
    this.database.setDisplayName(capitalisedName, this.authService._userProfileSubject.value.uid);
    this.takeAutomatedActions(capitalisedName, this.authService._userProfileSubject.value.uid);
  }

  validateDisplayName(displayName: string): Observable<boolean> {
    return this.database.getDisplayName(displayName).pipe(
      map((users: any) => {
        if (users.length === 0) return true; // displayName is available
        if (users.length > 1) return false; // more than one member has this display name already

        // One member has this displayName, check if it is the current member
        // TODO: What if userProfileSubject is not loaded yet?
        return users[0].uid === this.authService._userProfileSubject.value.uid ? true : false;
      })
    );
  }
}
