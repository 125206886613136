import { Component, OnInit } from '@angular/core';
import { EventType } from '@infrastructure/constants/event-type';
import { AlertController } from '@ionic/angular';
import { GroupType } from '@shared/constants/group-type';
import { IAttendanceData } from '@shared/models/attendance/attendance-data';
import { IColumn } from '@shared/models/column';
import { IGroup } from '@shared/models/groups/group';
import { IWhereCondition } from '@shared/models/where-condition';
import { UserObject } from '@shared/models/user-object';
import { AttendanceService } from '@shared/services/attendance/attendance.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { GroupService } from '@shared/services/groups/group.service';
import { MessageService } from '@shared/services/messages/message.service';
import { UserService } from '@shared/services/user/user.service';
import { forkJoin, of, Observable } from 'rxjs';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';
import { IInput } from '@shared/models/input';
import { BaseReportComponent } from '../../base-report/base-report.component';

@Component({
  selector: 'app-reports-last-activity-by-date',
  styleUrls: ['../../base-report/base-report.component.scss'],
  templateUrl: '../../base-report/base-report.component.html'
})
export class MemberActivityComponent extends BaseReportComponent {
  columns = [
    { label: 'Display name', field: 'displayName', class: 'data-table__column-responsive-bold' },
    { label: 'Full name', field: 'fullName' },
    { label: 'Email', field: 'email' },
    { label: 'Plan', field: 'membershipType' },
    { label: 'Status', field: 'membershipStatus' },
    { label: 'Date joined', field: 'date' },
    { label: 'Location', field: 'locality' },
    { label: 'Coins', field: 'coinsBalance' },
    { label: 'Romance access', field: 'canAccessRomance' },
    { label: 'CatchUps attended', field: 'attendanceCount' },
    { label: 'First CatchUp bonus', field: 'firstEventAttended' },
    { label: 'First CatchUp host', field: 'firstEventOrganiser' },
    { label: 'Second CatchUp bonus', field: 'secondEventAttended' },
    { label: 'Second CatchUp host', field: 'secondEventOrganiser' },
    { label: 'Groups', field: 'groups' }
  ];
  config = {
    routerLink: {
      path: '/admin/member-detail',
      id: [':uid']
    }
  };
  readonly DAY_IN_MS = 24 * 60 * 60 * 1000;
  groups: Record<string, IGroup> = {};
  option: string;
  parametersClass = 'wide-select';
  parametersHeader = 'Select report type';
  parametersMessage = `<p>Choose the type of report to see matching members</p>`;
  parametersInputs: IInput[] = [
    {
      label: 'No display name',
      name: 'option',
      type: 'radio',
      value: 'no-display-name'
    },
    {
      label: 'No location',
      name: 'option',
      type: 'radio',
      value: 'no-location'
    },
    {
      label: 'No groups',
      name: 'option',
      type: 'radio',
      value: 'no-groups'
    },
    {
      label: 'Only virtual groups',
      name: 'option',
      type: 'radio',
      value: 'virtual-groups'
    },
    {
      label: 'Members of group...',
      name: 'option',
      type: 'radio',
      value: 'group-members'
    },
    {
      label: 'New members',
      name: 'option',
      type: 'radio',
      value: 'new-members'
    },
    {
      label: 'New members without messages',
      name: 'option',
      type: 'radio',
      value: 'new-members-without-messages'
    },
    {
      label: 'Non-renewing',
      name: 'option',
      type: 'radio',
      value: 'non-renewing'
    }
  ];
  instructions = '<p>Click on "select parameters" to choose the type of report</p>';
  threadCount: Record<string, number> = {};
  title = 'Member Report';

  constructor(alertController: AlertController, private attendanceService: AttendanceService, private dateTimeService: DateTimeService, private groupService: GroupService, private messageService: MessageService, private userService: UserService) {
    super(alertController);
  }

  loadData(groupIds: string[] = []) {
    if (!this.option) return;
    const selection = this.parametersInputs.find(x => x.value === this.option);
    this.title = `${selection.label}`;
    this.instructions = `No members matching your selection`;
    let whereCondition = null;

    switch (this.option) {
      case 'no-display-name':
        whereCondition = {
          field: 'displayName',
          operator: '==',
          value: ''
        };
        this.data$ = this.getMembersByQuery(whereCondition);
        break;

      case 'no-location':
        whereCondition = {
          field: 'placeId',
          operator: '==',
          value: ''
        };
        this.data$ = this.getMembersByQuery(whereCondition);
        break;

      case 'no-groups':
        whereCondition = {
          field: 'catchupGroupIds',
          operator: '==',
          value: []
        };
        this.data$ = this.getMembersByQuery(whereCondition);
        break;

      case 'virtual-groups':
        whereCondition = {
          field: 'catchupGroupIds',
          operator: '!=',
          value: []
        };
        this.data$ = this.getMembersByQuery(whereCondition).pipe(
          map(members => {
            const virtualGroups = Object.values(this.groups)
              .filter(x => x.groupType === GroupType.SPECIAL_INTEREST_GROUP)
              .map(x => x.uid);
            return members.filter(member => {
              const memberVirtualGroups = member.catchupGroupIds.filter(x => virtualGroups.includes(x));
              return memberVirtualGroups.length === member.catchupGroupIds.length;
            });
          })
        );
        break;

      case 'group-members':
        whereCondition = {
          field: 'catchupGroupIds',
          operator: 'array-contains-any',
          value: groupIds
        };
        this.data$ = this.getMembersByQuery(whereCondition);
        break;

      case 'new-members':
        whereCondition = {
          field: 'dateRegistered',
          operator: '>=',
          value: Date.now() - 14 * this.DAY_IN_MS
        };
        this.data$ = this.getMembersByQuery(whereCondition);
        break;

      case 'new-members-without-messages':
        const date = Date.now() - 14 * this.DAY_IN_MS;
        whereCondition = {
          field: 'dateRegistered',
          operator: '>=',
          value: date
        };
        this.data$ = this.messageService.getMemberThreadsByDate(date).pipe(
          switchMap(memberThreads => {
            for (const thread of memberThreads) {
              if (!this.threadCount[thread.memberId]) this.threadCount[thread.memberId] = 0;
              this.threadCount[thread.memberId]++;
            }
            return this.getMembersByQuery(whereCondition);
          }),
          map(members => {
            // TODO: It would be slightly more efficient to do this filtering before reading member data in getMembersByQuery
            // but this way allows us to reuse the same code as for all other reports
            return members.filter(member => !this.threadCount[member.uid]);
          })
        );
        break;

      case 'non-renewing':
        whereCondition = {
          field: 'membershipStatus',
          operator: '==',
          value: 'non_renewing'
        };
        const isPrivate = true;
        this.data$ = this.getMembersByQuery(whereCondition, isPrivate);
        break;

      default:
        this.data$ = of([]);
        break;
    }

    if (this.data$) {
      this.data$ = this.data$.pipe(
        map(members => {
          return members
            .map(member => {
              const date = member.dateRegistered ? this.dateTimeService.formatDate(member.dateRegistered, 'D MMM YYYY') : '';
              const groupNames = (member.catchupGroupIds || []).map(x => (this.groups[x] ? this.groups[x].name || '' : ''));
              const selection = this.parametersInputs.find(x => x.value === this.option);
              this.title = `${selection.label} (${members.length})`;
              return Object.assign({}, member, { date, groups: groupNames });
            })
            .sort((a, b) => b.dateRegistered - a.dateRegistered);
        })
      );
    }
  }

  selectParametersHandler(data: any) {
    this.option = data;
    for (let param of this.parametersInputs) {
      param.checked = false;
    }
    const index = this.parametersInputs.findIndex(x => x.value == data);
    if (index > -1) this.parametersInputs[index].checked = true;
    if (this.option === 'group-members') {
      this.loadGroups();
    } else {
      this.loadData();
    }
  }

  private getMembersByQuery(whereCondition: IWhereCondition, isPrivate: boolean = false): Observable<any[]> {
    return this.groupService.getAllGroups(true).pipe(
      switchMap(groups => {
        for (const group of groups) {
          this.groups[group.uid] = group;
        }
        return this.userService.getMembersByQuery(whereCondition, isPrivate);
      }),
      mergeMap(
        publicData =>
          this.userService.getMembersByIds(
            publicData.map(x => x.uid),
            !isPrivate
          ),
        // NB publicData and privateData are reversed if isPrivate is true, but they still get merged
        (publicData, privateData) => {
          let data = {};
          for (const member of publicData) {
            data[member.uid] = member;
          }
          for (const member of privateData) {
            data[member.uid] = data[member.uid] ? Object.assign(data[member.uid], member) : member;
          }
          return Object.values(data);
        }
      ),
      mergeMap(
        memberData =>
          this.attendanceService.getCountsByIds(
            memberData.map(x => x.uid),
            EventType.CatchUp
          ),
        (memberData: UserObject[], attendanceData: IAttendanceData[]) => {
          let data = {};
          for (const member of memberData) {
            data[member.uid] = member;
          }
          for (const member of attendanceData) {
            data[member.uid] = data[member.uid] ? Object.assign(data[member.uid], member) : member;
          }
          return Object.values(data);
        }
      )
    );
  }

  private loadGroups() {
    this.groupService
      .getAllGroups(true)
      .pipe(first(x => !!x))
      .subscribe(async groups => {
        const groupParametersInputs = groups
          .filter(x => x.name)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(group => {
            return {
              label: group.name,
              type: 'checkbox',
              value: group.uid
            } as IInput;
          });

        const alert = await this.alertController.create({
          cssClass: 'wide-select',
          header: 'Select groups',
          message: '<p>Choose between 1 and 10 groups to see members belonging to any of those groups.</p>',
          inputs: groupParametersInputs,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel'
            },
            {
              text: `Select`,
              handler: data => this.loadData(data)
            }
          ]
        });

        await alert.present();
      });
  }
}
