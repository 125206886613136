import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from '@shared/services/groups/group.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-group-members',
  templateUrl: './group-members.page.html'
})
export class GroupMembersPage {
  canManageGroup$: Observable<boolean>;
  groupId: string;
  @ViewChild('memberList', { static: false }) memberList: any;

  constructor(private groupService: GroupService, private route: ActivatedRoute) {}

  ionViewWillEnter() {
    this.groupId = this.route.snapshot.paramMap.get('id');
    this.canManageGroup$ = this.groupService.canManageGroup$(this.groupId);
  }

  onRecountMembers() {
    this.memberList.recalculateMemberCount();
  }
}
