import { Injectable } from '@angular/core';
import { CursorType } from '@shared/constants/cursor-type';
import { Direction } from '@shared/constants/direction';
import { ICursor } from '@shared/models/cursor';
import { IOrderCondition } from '@shared/models/order-condition';
import { IMessage } from '@shared/models/messages/message';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentDatabase extends BaseDatabase {
  //TODO: Monitor number of comments. If we get any photos with too many comments implement a load more button
  COMMENTS_TO_FETCH = 9999; // Get all comments

  addComment(comment: IMessage, parentId: string) {
    return this.createSubDocument(this.COLLECTION.MEDIA, parentId, this.COLLECTION.COMMENTS, comment);
  }

  getComments(parentId: string): Observable<IMessage[]> {
    const whereConditions: IWhereCondition[] = [];
    const orderConditions: IOrderCondition[] = [{ field: 'dateTimeSent', direction: 'desc' }];

    return this.getDocumentsBySubcollectionQuery<IMessage>(this.COLLECTION.MEDIA, parentId, this.COLLECTION.COMMENTS, this.createQueryFunction(whereConditions, orderConditions, this.COMMENTS_TO_FETCH));
  }
}
