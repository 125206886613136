import { Injectable } from '@angular/core';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { UrlHelperService } from '@shared/services/url-helper.service';
import { IAppMenuItem } from '../../models/app-menu-item';
import { AbstractMenuService } from '../abstract-menu.service';

@Injectable({
  providedIn: 'root'
})
export class PlantsMenuService extends AbstractMenuService {
  constructor(private authService: AuthService, private urlHelperService: UrlHelperService) {
    super();
  }

  getMenuItems(user: UserObject): IAppMenuItem[] {
    return [
      {
        title: 'Admin menu',
        url: '/admin',
        canShow: this.authService.isAdmin$()
      },
      {
        title: 'Home',
        url: '/home'
      },
      {
        title: `My Greenhouse`,
        url: '/my-greenhouse'
      },
      {
        title: 'Groups',
        url: '/groups'
      },
      {
        title: 'Marketplace',
        url: '/marketplace/intro'
      },
      {
        title: 'Classifieds',
        url: '/classifieds/intro'
      },
      {
        title: 'Retailers',
        url: '/retailers'
      },
      {
        title: 'Events',
        url: '/events'
      },
      {
        title: 'Plant Pals',
        url: '/plant-pals'
      },
      /*
      {
        title: 'Planty Pix',
        url: '/pix/public/Planty Pix'
      },
      */
      {
        title: 'My Messages',
        url: '/messages'
      },
      {
        title: 'Upgrade',
        url: '/pages/upgrade-subscription'
      },
      {
        title: 'Contact Us',
        url: '/contact-us'
      },
      {
        title: 'Help',
        children: [
          {
            title: 'Help Documents',
            url: '/pages/help'
          },
          {
            title: 'Legal Policies',
            children: [
              {
                title: 'Code of Conduct',
                url: 'https://weloveindoorplants.com/code-of-conduct/',
                external: true,
                fullExternalUrl: true
              },
              {
                title: 'Terms and Conditions',
                url: 'https://weloveindoorplants.com/terms-and-conditions/',
                external: true,
                fullExternalUrl: true
              },
              {
                title: 'Acceptable Use Policy',
                url: 'https://weloveindoorplants.com/acceptable-use-policy/',
                external: true,
                fullExternalUrl: true
              },
              {
                title: 'Privacy Policy',
                url: 'https://weloveindoorplants.com/privacy-policy/',
                external: true,
                fullExternalUrl: true
              }
            ]
          }
        ]
      },
      {
        title: 'Logout',
        url: '/auth/logout'
      }
    ];
  }
}
