import { Injectable } from '@angular/core';
import { ImageDatabase } from '@shared/services/image/image.database';

@Injectable({
  providedIn: 'root'
})
export class ChirpyImageService {
  constructor(private database: ImageDatabase) {}

  async uploadImage(file: File, filePath: string): Promise<any> {
    return this.database.uploadImage(file, filePath);
  }
}
