import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IAdvertiser } from '@shared/models/advertisers/advertiser';

@Injectable({
  providedIn: 'root'
})
export class AdvertiserEditPresenter {
  form = this.formBuilder.group({
    categories: [[], Validators.required],
    contact: ['', Validators.required],
    description: ['', Validators.required],
    displayName: ['', Validators.required],
    region: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}

  advertiser() {
    let formValue = this.form.value;
    formValue.displayName = (formValue.displayName || '').trim();
    formValue.searchName = formValue.displayName.toLowerCase();
    return formValue;
  }

  setValue(advertiser: IAdvertiser) {
    this.form.setValue({
      categories: advertiser.categories,
      contact: advertiser.contact,
      description: advertiser.description,
      displayName: advertiser.displayName,
      region: advertiser.region
    });
  }
}
