import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ReverseTransactionPresenter {
  get formValue() {
    return this.form.value;
  }

  form = this.formBuilder.group({
    description: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}

  reset() {
    this.form.reset();
  }
}
