import { Injectable } from '@angular/core';
import { Country } from '@shared/constants/country';
import { GeohashWhitelist } from '@environment/geohash-whitelist';
import { IPostcode } from '@shared/models/meeting-place/postcode';
import { IPlaceIndex } from '@shared/models/meeting-place/place-index';
import { IPlaceResult } from '@shared/models/place-result';
import { IPlace } from '@shared/models/place';
import { LocationDatabase } from '@shared/services/location/location.database';
import firebase from 'firebase/app';
import { LatLngBounds } from 'leaflet';
import * as geohash from 'ngeohash';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  currentPosition$: BehaviorSubject<Coordinates> = new BehaviorSubject<Coordinates>(null);
  readonly MILLISECONDS_BEFORE_REFRESHING_POSITION = 1000 * 60; // refresh every 1 minute.
  readonly zoomToPrecision: Record<number, number> = { 0: 1, 1: 1, 2: 1, 3: 2, 4: 2, 5: 2, 6: 2, 7: 3, 8: 3, 9: 3, 10: 4, 11: 4, 12: 4, 13: 5, 14: 5, 15: 6, 16: 6, 17: 6 };

  constructor(private locationDatabase: LocationDatabase) {
    //TODO Move to somewhere it is explicitly triggered by a member's action?
    //Sometimes this service is called for its utility methods
    //this.getCurrentPosition();
  }

  getCurrentPosition() {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          this.currentPosition$.next(position.coords), console.log(position.coords);
        },
        error => {
          switch (error.code) {
            case 1:
              console.log('Permission Denied');
              break;
            case 2:
              this.currentPosition$.next(this.currentPosition$.value);
              console.log('Position Unavailable');
              break;
            case 3:
              this.currentPosition$.next(this.currentPosition$.value);
              console.log('Timeout');
              break;
          }
        },
        options
      );
    }

    //TODO: Use watchCurrentPosition instead of getCurrentPosition + setTimeout?
    this.refreshCurrentPosition();
  }

  getGeohash(latitude: number, longitude: number): string {
    return geohash.encode(latitude, longitude);
  }

  getMinimalGeohash(bounds: LatLngBounds, zoom: number): string[] {
    const precision = this.getPrecisionForZoom(zoom);
    const allHashes = geohash.bboxes(bounds.getSouth(), bounds.getWest(), bounds.getNorth(), bounds.getEast(), precision);
    const whitelistedHashes = this.getWhitelistedGeohashes(allHashes, precision);
    return whitelistedHashes;
  }

  getPlaceOrPostcode(query: string, country: string): Observable<IPlaceResult> {
    const postcodeRegex = /\d{4}/;
    return query.match(postcodeRegex) ? this.getPostcode(query, country) : this.getPlaces(query, country);
  }

  getPlace(uid: string): Observable<IPlace> {
    return this.locationDatabase.getPlace(uid);
  }

  getPlaces(query: string, country: string): Observable<IPlaceResult> {
    const normalisedQuery = this.normalisePlaceQuery(query);
    const places$: Observable<any[]> = this.locationDatabase.getPlaces(normalisedQuery, country);

    return places$.pipe(
      map(values => {
        const coordinates = values[0].coordinates;
        const zoomTo = Math.max(...Object.keys(values[0].zoom).map(Number));
        const value = { coordinates: coordinates, zoomTo: zoomTo } as IPlaceResult;
        return value;
      })
    );
  }

  getPlacesById(uids: string[]): Observable<IPlace[]> {
    return uids.length === 0 ? of([]) : this.locationDatabase.getPlacesById(uids);
  }

  // This is used in chirpy-locator-map component, which requires IPlace input rather than IPlaceResult
  getPlacesWithGroups(showAllCountries: boolean, country: string): Observable<IPlace[]> {
    const searchCountry = showAllCountries ? '' : country;
    return this.locationDatabase.getPlacesWithGroups(searchCountry).pipe(
      map(results => {
        return results;
      })
    );
  }

  getPostcode(postcode: string, country: string): Observable<IPlaceResult> {
    const postcodeId = `${postcode}_${country}`;
    const postcode$: Observable<IPostcode> = this.locationDatabase.getPostcode(postcodeId);

    return postcode$.pipe(
      map(data => {
        const zoomTo = 14; //postcodes don't have zoomLevel associated
        const value = { ...data, zoomTo } as IPlaceResult;
        return value;
      })
    );
  }

  getRegion(region: string, country: Country): Observable<IPlace> {
    return this.locationDatabase.getRegion(region, country);
  }

  search(search: string, country: string, showAllCountries: boolean) {
    if (search != null) {
      return this.locationDatabase.getPlaceIndex(search.toLowerCase().slice(0, 1)).pipe(
        map((places: IPlaceIndex) => {
          if (places == null) {
            return {}; // E.g. search starts with special character
          } else if (!showAllCountries) {
            return places[country];
          } else {
            const allPlaces: Record<string, string> = {};
            // places also contains a key uid; explicitly loop over country codes to avoid this
            Object.values(Country).forEach(country => {
              if (places.hasOwnProperty(country)) {
                Object.assign(allPlaces, places[country]);
              }
            });
            return allPlaces;
          }
        })
      );
    } else {
      return of({});
    }
  }

  setHasGroupFlag(uid: string, groupId: string) {
    this.locationDatabase.updatePlaceData(uid, { hasGroup: firebase.firestore.FieldValue.arrayUnion(groupId) });
  }

  setHasSocialFlag(uid: string, socialId: string) {
    this.locationDatabase.updatePlaceData(uid, { hasSocial: firebase.firestore.FieldValue.arrayUnion(socialId) });
  }

  unsetHasSocialFlag(uid: string, socialId: string) {
    this.locationDatabase.updatePlaceData(uid, { hasSocial: firebase.firestore.FieldValue.arrayRemove(socialId) });
  }

  unsetHasGroupFlag(uid: string, groupId: string) {
    this.locationDatabase.updatePlaceData(uid, { hasGroup: firebase.firestore.FieldValue.arrayRemove(groupId) });
  }

  updatePlaceData(uid: string, data: any): void {
    this.locationDatabase.updatePlaceData(uid, data);
  }

  private getPrecisionForZoom(zoom: number): number {
    return this.zoomToPrecision[zoom];
  }

  private getWhitelistedGeohashes(hashes: string[], precision: number): string[] {
    if (precision < 1 || precision > 6) return hashes;
    // precision is 1-indexed, GEOHASHES array is zero-indexed
    let whitelistedHashes = hashes.filter(hash => GeohashWhitelist.GEOHASHES[precision - 1][hash]);
    return whitelistedHashes;
  }

  private normalisePlaceQuery(query: string): string {
    //TODO Handle things like apostrophes, Mt->Mount, etc
    let normalisedQuery = query.replace(/\b[a-zA-Z]/g, match => match.toUpperCase());
    return normalisedQuery;
  }

  private refreshCurrentPosition() {
    setTimeout(() => {
      this.getCurrentPosition();
    }, this.MILLISECONDS_BEFORE_REFRESHING_POSITION);
  }
}
