export interface ICoinsOptions {
  amount: number;
  dateAfter: string;
  dateBefore: string;
  member: string;
  transactionType: string;
}

export const DefaultCoinsOptions: ICoinsOptions = {
  amount: null,
  dateAfter: '',
  dateBefore: '',
  member: '',
  transactionType: ''
};
