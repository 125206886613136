import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  cache: Map<string, Map<string, any>>;

  constructor() {
    this.cache = new Map();
  }

  clear(collection: string): void {
    if (this.cache.has(collection)) {
      this.cache.set(collection, new Map());
    }
  }

  clearAll(): void {
    this.cache.clear();
  }

  getAllEntries(collection: string): Map<string, any> {
    if (this.cache.has(collection)) {
      return this.cache.get(collection);
    } else {
      return new Map();
    }
  }

  getObservable<T>(collection: string, key: string): Observable<T> {
    const value = this.getValue(collection, key);
    return value === null ? null : of(value);
  }

  getValue(collection: string, key: string): any {
    if (this.cache.has(collection) && this.cache.get(collection).has(key)) {
      return this.cache.get(collection).get(key);
    } else {
      return null;
    }
  }

  update(collection: string, key: string, value: any): void {
    if (!this.cache.has(collection)) {
      this.cache.set(collection, new Map());
    }

    const collectionCache = this.cache.get(collection);
    collectionCache.set(key, value);
    this.cache.set(collection, collectionCache);
  }

  updateArray(collection: string, key: string, value: any): void {
    if (!this.cache.has(collection)) {
      this.cache.set(collection, new Map());
    }

    const collectionCache = this.cache.get(collection);
    if (!collectionCache.has(key)) {
      collectionCache.set(key, new Array());
    }

    const arrayCache = collectionCache.get(key);
    arrayCache.push(value);
    collectionCache.set(key, arrayCache);
    this.cache.set(collection, collectionCache);
  }
}
