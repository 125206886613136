import { Component } from '@angular/core';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { UIService } from '@shared/services/ui.service';

@Component({
  selector: 'app-header',
  styleUrls: ['./app-header.component.scss'],
  templateUrl: 'app-header.component.html'
})
export class AppHeaderComponent {
  get branding() {
    return this.environmentService.settings.constants;
  }

  get CONSTANTS() {
    return this.constantsService.constants.APP;
  }

  constructor(private analyticsService: AnalyticsService, public authService: AuthService, private constantsService: ConstantsService, private environmentService: EnvironmentService, private uiService: UIService) {}

  onClickLogo() {
    this.analyticsService.eventTrack(AnalyticsCategory.NAVIGATION, AnalyticsAction.NAVIGATION_HEADER_LOGO);
  }

  onStopImpersonation() {
    this.authService.stopImpersonating();
  }
}
