import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IValueWithId } from '@shared/models/value-with-id';
import { ConstantsService } from '@shared/services/constants.service';

@Injectable({
  providedIn: 'root'
})
export class SignupStepService {
  steps: string[]; //['display-name', 'gender', 'location', 'groups', 'whats-next'];

  constructor(private constantsService: ConstantsService) {
    this.steps = this.constantsService.constants.WELCOME.SIGNUP.steps;
  }

  getCurrentStepNumber(currentRoute: ActivatedRoute): number {
    const currentStep = currentRoute.snapshot.url[1].path;
    return this.steps.indexOf(currentStep) + 1; // index is zero-based
  }

  getNextStep(currentRoute: ActivatedRoute): string | null {
    const currentStep = currentRoute.snapshot.url[1].path;
    const currentIndex = this.steps.indexOf(currentStep); // return -1 if currentStep not found
    return currentIndex > -1 && currentIndex < this.steps.length - 1 ? this.steps[currentIndex + 1] : null;
  }

  getPreviousStep(currentRoute: ActivatedRoute): string | null {
    const currentStep = currentRoute.snapshot.url[1].path;
    const currentIndex = this.steps.indexOf(currentStep); // return -1 if currentStep not found
    return currentIndex > 1 && currentIndex < this.steps.length ? this.steps[currentIndex - 1] : null;
  }

  getStepNames(): IValueWithId[] {
    return this.steps.map(step => {
      const key = step.toUpperCase().replace('-', '_');
      return {
        uid: step,
        name: this.constantsService.constants.WELCOME.SIGNUP[key].navigationTitle || this.constantsService.constants.WELCOME.SIGNUP[key].title || step
      };
    });
  }

  getTotalSteps() {
    return this.constantsService.constants.WELCOME.SIGNUP.countLastStep ? this.steps.length : this.steps.length - 1;
  }
}
