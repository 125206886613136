import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MembersGuard } from '@shared/guards/members-guard';
import { SocialDetailPage } from './social-detail/social-detail.page';
import { SocialEditPage } from './social-edit/social-edit.page';
import { SocialInterestedPage } from './social-interested/social-interested.page';
import { SocialPage } from './social.page';

const routes: Routes = [
  {
    path: '',
    component: SocialPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Social'
    }
  },
  {
    path: ':id',
    component: SocialDetailPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Social details'
    }
  },
  {
    path: ':socialId/interested',
    component: SocialInterestedPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Interested in Social'
    }
  },
  {
    path: 'update/:id',
    component: SocialEditPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Update Social'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SocialRoutingModule {}
