import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Country } from '@shared/constants/country';
import { IAdvertiser } from '@shared/models/advertisers/advertiser';
import { IAnnouncement } from '@shared/models/advertisers/announcement';
import { IAppOptions } from '@shared/models/app-options';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { SubscriptionService } from '@shared/services/subscription.service';
import firebase from 'firebase/app';
import { Observable, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdvertiserDatabase extends BaseDatabase {
  createAdvertiser(advertiser: IAdvertiser): Promise<any> {
    return this.createDocument(this.COLLECTION.ADVERTISERS, advertiser);
  }

  createAdvertiserReference() {
    return this.createId();
  }

  deleteAdvertiser(uid: string): Promise<any> {
    return this.deleteDocument(this.COLLECTION.ADVERTISERS, uid);
  }

  deleteAnnouncement(advertiserId: string, announcementId: string): Promise<any> {
    return this.deleteSubDocument(this.COLLECTION.ADVERTISERS, advertiserId, this.COLLECTION.ANNOUNCEMENTS, announcementId);
  }

  getAdvertiser(uid: string): Observable<IAdvertiser> {
    return this.getDocument<IAdvertiser>(this.COLLECTION.ADVERTISERS, uid);
  }

  getAdvertiserCategories() {
    return this.getDocument<IAppOptions>(this.COLLECTION.APP_OPTIONS, 'advertiserCategories');
  }

  getAllAdvertisers(useCache: boolean): Observable<IAdvertiser[]> {
    return this.getDocuments<IAdvertiser>(this.COLLECTION.ADVERTISERS, useCache);
  }

  getAdvertisersById(ids: string[]): Observable<IAdvertiser[]> {
    const whereConditions: IWhereCondition[] = [];
    const RECORDS_TO_FETCH = 10;

    if (ids.length === 0) {
      const queryFn = this.createQueryFunction(whereConditions, [], RECORDS_TO_FETCH); // Set number of records to fetch to 10, because "in" queries are limited a list of 10 values, so the max # of ids this query will return is 10.
      return this.getDocumentsByQuery<IAdvertiser>(this.COLLECTION.ADVERTISERS, queryFn);
    } else {
      //__name__ is a special keyword for document uid; an alternative is to use firebase.firestore.FieldPath.documentId()
      const queryFns = this.createChunkedQueryFunctions(ids, '__name__', 'in', whereConditions, false);
      return this.getDocumentsByMultipleQueries<IAdvertiser>(this.COLLECTION.ADVERTISERS, queryFns);
    }
  }

  getAnnouncements(advertiserId: string): Observable<IAnnouncement[]> {
    return this.getDocumentsBySubcollectionQuery<IAnnouncement>(this.COLLECTION.ADVERTISERS, advertiserId, this.COLLECTION.ANNOUNCEMENTS, ref => ref.orderBy('dateTimeLastUpdated', 'desc'));
  }

  getAnnouncement(advertiserId: string, announcementId: string): Observable<IAnnouncement> {
    return this.getSubDocument<IAnnouncement>(this.COLLECTION.ADVERTISERS, advertiserId, this.COLLECTION.ANNOUNCEMENTS, announcementId);
  }

  updateAdvertiser(uid: string, data: any): Promise<void> {
    return this.updateDocument(this.COLLECTION.ADVERTISERS, uid, data);
  }

  updateAnnouncement(advertiserId: string, announcementId: string, data: any): Promise<void> {
    return this.updateSubDocument(this.COLLECTION.ADVERTISERS, advertiserId, this.COLLECTION.ANNOUNCEMENTS, announcementId, data);
  }
}
