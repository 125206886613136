import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SignupOptionalPresenter {
  form = this.formBuilder.group({
    dob: [''],
    phone: ['']
  });

  constructor(private formBuilder: FormBuilder) {}

  setFormValue(phone: string, dob: string): void {
    this.form.patchValue({ phone, dob });
  }

  submit(): any {
    return this.form.value;
  }
}
