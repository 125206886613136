import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IChangePassword } from '@shared/models/change-password';

@Injectable({
  providedIn: 'root'
})
export class ChirpyChangePasswordPresenter {
  form = this.formBuilder.group({
    currentPassword: ['', Validators.required],
    password: ['', [Validators.required, Validators.minLength(6)]]
  });

  constructor(private formBuilder: FormBuilder) {}

  setFormValue(data: IChangePassword) {
    this.form.patchValue(data);
  }

  submit(): IChangePassword {
    return this.form.value;
  }
}
