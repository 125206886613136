import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ShareDetailComponent } from './components/share-detail/share-detail.component';
import { ShareEditComponent } from './components/share-edit/share-edit.component';
import { ShareListComponent } from './components/share-list/share-list.component';
import { ShareSearchComponent } from './components/share-search/share-search.component';
import { ShareIntroPage } from './share-intro/share-intro.page';
import { ShareRoutingModule } from './share-routing.module';
import { SharePage } from './share.page';

@NgModule({
  imports: [ShareRoutingModule, SharedModule],
  declarations: [ShareDetailComponent, ShareEditComponent, ShareSearchComponent, ShareListComponent, ShareIntroPage, SharePage],
  entryComponents: [ShareSearchComponent]
})
export class ShareModule {}
