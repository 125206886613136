import { FirebaseObject } from '@shared/models/firebase-object';

export class RoundObject extends FirebaseObject {
  card: any[];
  gameId: string;
  playerId: string;
  index: number;
  marked: number[];

  deserialize(input: any): this {
    super.deserialize(input);
    Object.assign(this, input);
    return this;
  }
}
