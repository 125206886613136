import { Component } from '@angular/core';
import { IChangePasswordResult } from '@shared/models/update-password-result';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ToastService } from '@shared/services/toast.service';
import { User } from 'firebase';
import { skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'app-change-password',
  templateUrl: 'change-password.page.html'
})
export class ChangePasswordPage {
  email: string;
  hasPasswordChanged: boolean;

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private toastService: ToastService) {}

  ionViewWillEnter() {
    this.authService.userProfileObservable
      .pipe(
        skipWhile(x => !x),
        take(1)
      )
      .subscribe(member => {
        this.email = member.email;
        this.hasPasswordChanged = false;
      });
  }

  onPasswordChanged(data: IChangePasswordResult) {
    if (data.error) {
      this.analyticsService.eventTrack(AnalyticsCategory.ACCOUNT, AnalyticsAction.ACCOUNT_CHANGE_PASSWORD_ERROR);
      this.toastService.presentToast(data.message);
      return;
    }

    this.analyticsService.eventTrack(AnalyticsCategory.ACCOUNT, AnalyticsAction.ACCOUNT_CHANGE_PASSWORD);
    this.hasPasswordChanged = true;
  }
}
