import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { INotice } from '@shared/models/groups/notice';
import { IListingImage } from '@shared/models/image/listing-image';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { GroupService } from '@shared/services/groups/group.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { Observable } from 'rxjs';
import { GroupNoticesEditPresenter } from './group-notices-edit.presenter';
import equal from 'fast-deep-equal';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-group-notices-edit',
  templateUrl: './group-notices-edit.page.html'
})
export class GroupNoticesEditPage {
  get form() {
    return this.presenter.form;
  }

  get noticesArray() {
    return this.presenter.form.controls.noticesArray as FormArray;
  }

  canEditNotices$: Observable<boolean>;
  errorMessage: string = `Sorry, you don't have permission to edit this page`;
  hasItemsLoaded: boolean = false;
  MAX_PHOTOS: number = 1;
  noItemsMessage: string = '<p>Loading...</p>';
  notices: Record<string, INotice> = {};
  groupId: string;
  subscription: Subscription;

  constructor(
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private groupService: GroupService,
    private presenter: GroupNoticesEditPresenter,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService
  ) {}

  ionViewWillLeave() {
    this.subscriptionService.clearSubscription(this.subscription);
  }

  ionViewWillEnter() {
    this.noItemsMessage = '<p>Loading...</p>';
    this.groupId = this.route.snapshot.paramMap.get('id');
    this.canEditNotices$ = this.groupService.canManageGroup$(this.groupId);
    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_EDIT_NOTICES, this.groupId);

    this.subscriptionService.clearSubscription(this.subscription);
    this.subscription = this.groupService
      .getGroupNotices(this.groupId)
      .pipe(first())
      .subscribe(data => {
        this.noItemsMessage = `<p>This group has no notices.</p><p>Click the "Add notice" button above to start writing one, then click "Save changes" when you are done.</p><p>If you've just deleted all the notices, make sure to click the "Save changes" button to complete the deletion.</p>`;
        this.notices = data.reduce((acc, current) => {
          acc[current.uid] = current;
          return acc;
        }, {});
        this.presenter.init(data);
        this.hasItemsLoaded = Object.keys(this.notices).length > 0;
      });
    this.subscriptionService.add(this.subscription);
  }

  onAddNotice() {
    this.hasItemsLoaded = true;
    this.presenter.addNewControl();
    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_ADD_NOTICE, this.groupId);
  }

  async onCancel() {
    const formValues: any[] = this.presenter.notices;
    if (formValues.length === 0 && formValues.length === Object.keys(this.notices).length) this.router.navigate(['/groups/notices', this.groupId], { replaceUrl: true });

    // If the number of notices on the form differs from the number of saved notices then obviously something has changed
    let noticesChanged = formValues.length !== Object.keys(this.notices).length;

    // But if the number of notices are the same, then check the content to see if it has changed
    if (!noticesChanged) {
      for (let formValue of formValues) {
        if (this.hasNoticeChanged(formValue)) {
          noticesChanged = true;
          break;
        }
      }
    }

    if (noticesChanged) {
      const alert = await this.alertController.create({
        header: 'Cancel changes',
        message: 'Are you sure you want leave without saving your changes?',
        buttons: [
          {
            text: 'No, save changes',
            handler: () => {
              this.onUpdateNotices();
            }
          },
          {
            text: 'Yes, discard changes',
            handler: () => {
              this.router.navigate(['/groups/notices', this.groupId], { replaceUrl: true });
            }
          }
        ]
      });

      await alert.present();
    } else {
      this.router.navigate(['/groups/notices', this.groupId], { replaceUrl: true });
    }
  }

  async onDeleteNotice(index: number) {
    const alert = await this.alertController.create({
      header: 'Delete notice',
      message: 'Are you sure you want to delete this notice?',
      buttons: [
        {
          text: 'Delete',
          handler: () => {
            this.onRemoveNotice(index);
          }
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  onRemoveNotice(index: number) {
    this.presenter.removeControl(index);
    this.hasItemsLoaded = this.noticesArray.controls.length > 0;
    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_DELETE_NOTICE, this.groupId);
  }

  onUpdateNotices() {
    const formValues: any[] = this.presenter.notices;
    if (formValues.length === 0 && formValues.length === Object.keys(this.notices).length) this.router.navigate(['/groups/notices', this.groupId], { replaceUrl: true });
    if (!this.validateForm(formValues)) return; // toast shows what fields are missing

    for (let formValue of formValues) {
      if (this.hasNoticeChanged(formValue)) {
        // Delete any removed images
        let photosToRemove: IListingImage[] = [];
        if (formValue.uid !== 'new') {
          const oldNotice = this.notices[formValue.uid];
          const oldKeys = Object.keys(oldNotice.photos || {});

          if (oldKeys.length > 0) {
            const newKeys = Object.keys(formValue.photos || {});
            const keysToRemove = oldKeys.filter(x => !newKeys.includes(x));
            for (const key of keysToRemove) {
              photosToRemove.push(Object.assign({}, oldNotice.photos[key]));
            }
          }
        }

        let notice = this.updateNoticeValues(formValue);
        this.groupService.updateNotice(this.groupId, notice, photosToRemove);
        this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_UPDATE_NOTICES, this.groupId);
      }
    }

    const noticesToDelete = Object.keys(this.notices).filter(x => !formValues.some(y => y.uid === x));
    for (const noticeId of noticesToDelete) {
      this.groupService.deleteNotice(this.groupId, noticeId);
    }
    this.router.navigate(['/groups/notices', this.groupId], { replaceUrl: true });
  }

  private hasNoticeChanged(newNotice: any): boolean {
    if (newNotice.uid === 'new') return true;

    const oldNotice = this.notices[newNotice.uid];
    if (oldNotice == null) return true; // this shouldn't happen
    return oldNotice.title !== newNotice.title || oldNotice.content !== newNotice.content || !equal(oldNotice.photos, newNotice.photos);
  }

  private updateNoticeValues(newNotice: any): INotice {
    // TODO Use Object.assign? Might have difficulties with photos
    let notice: any = {
      content: newNotice.content,
      dateTimeLastUpdated: Date.now(),
      photos: newNotice.photos,
      title: newNotice.title,
      uid: newNotice.uid
    };

    if (newNotice.uid === 'new') {
      notice.memberId = this.authService._userProfileSubject.value.uid;
      notice.memberName = this.authService._userProfileSubject.value.displayName;
      notice.dateTimeSent = Date.now();
    } else {
      const oldNotice = this.notices[newNotice.uid];
      notice.dateTimeSent = oldNotice.dateTimeSent;
      notice.memberName = oldNotice.memberName;
      notice.memberId = oldNotice.memberId;
    }

    return notice;
  }

  private validateForm(data: any[]) {
    const missingFields = [];

    for (let notice of data) {
      if (notice.title.trim().length === 0) missingFields.push('a Title');
      if (notice.content.trim().length === 0) missingFields.push('a Description');
    }

    if (missingFields.length > 0) {
      const message = 'Please enter ' + missingFields.join(', ');
      this.toastService.presentToast(message);
      return false;
    }

    return true;
  }
}
