import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { IPage } from '../models/page';
import { ContentPageDatabase } from './content-page.database';

@Injectable({
  providedIn: 'root'
})
export class ContentPageService {
  constructor(private alertController: AlertController, private contentPageDatabase: ContentPageDatabase, private router: Router) {}

  getPage(pageId: string): Observable<IPage> {
    return this.contentPageDatabase.getPage(pageId);
  }

  async onCreate() {
    const alert = await this.alertController.create({
      header: `Create page`,
      subHeader: `Enter the name you want to appear in the URL, e.g. travel-2022.
      You can change the title on the page later`,
      inputs: [
        {
          name: 'name',
          type: 'text',
          placeholder: `Enter the page name...`
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: `Create`,
          handler: data => this.createPageHandler(data)
        }
      ]
    });

    await alert.present();
  }

  strictUpdatePage(pageId: string, data: IPage) {
    return this.contentPageDatabase.strictUpdatePage(pageId, data);
  }

  updatePage(pageId: string, data: any) {
    return this.contentPageDatabase.updatePage(pageId, data);
  }

  private createPageHandler(data: any) {
    const title = data.name.trim();
    const slug = data.name
      .trim()
      .toLowerCase()
      .replace(/ /g, '-');

    // TODO: Toast message?
    if (slug.length === 0) {
      return false; // return false will stop the alert from closing.
    }

    this.contentPageDatabase.createPage(slug, title).then(() => {
      this.router.navigate([`/pages/${slug}/edit`]);
    });
  }
}
