import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventType } from '@infrastructure/constants/event-type';
import { AdminRole } from '@shared/constants/admin-role';
import { ISocial } from '@shared/models/social/social';
import { IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { MessageHelperService } from '@shared/services/messages/message-helper.service';
import { SocialService } from '@shared/services/social/social.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UIService } from '@shared/services/ui.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-social-detail',
  templateUrl: './social-detail.page.html',
  styleUrls: ['./social-detail.page.scss']
})
export class SocialDetailPage implements OnInit {
  get CONSTANTS() {
    return this.constantsService.constants.SOCIAL.DETAIL;
  }

  get DISCLAIMER() {
    return this.constantsService.constants.SOCIAL.disclaimer;
  }

  get isAdmin() {
    return this.authService.isAdmin([AdminRole.HOSTS]);
  }

  branding: string = '';
  canApproveSocial: boolean = false;
  canUpdateOrDeleteSocial: boolean = false;
  social: ISocial;
  socialSubscription: Subscription;
  errorMessage: string;
  title: string;
  user: UserObject;

  constructor(private authService: AuthService, private analyticsService: AnalyticsService, private constantsService: ConstantsService, private socialService: SocialService, private subscriptionService: SubscriptionService, private route: ActivatedRoute, private router: Router) {}

  approveSocial() {
    this.socialService.approveSocial(this.social);
  }

  cancelSocial() {
    this.socialService.deleteSocial(this.social);
  }

  getSocial(uid: string) {
    this.socialSubscription = this.socialService.getSocial(uid).subscribe(social => {
      if (social == null) {
        this.errorMessage = 'Social does not exist';
        return;
      }

      if (social.interested == null) social.interested = {};
      this.social = social;
      this.title = this.social.title;

      this.sendAnalytics(this.social);

      this.canUpdateOrDeleteSocial = this.user.uid === social.memberId || this.isAdmin;
    });
    this.subscriptionService.add(this.socialSubscription);
  }

  ngOnInit() {
    this.authService._userProfileSubject.subscribe(user => {
      this.user = user;
      // set permissions to true if the member is an admin otherwise wait until we've loaded the social in getSocial to check if the member is the organiser
      this.canApproveSocial = this.isAdmin;
      this.canUpdateOrDeleteSocial = this.isAdmin;
    });

    // Set fallback title
    this.title = this.CONSTANTS.title;
    this.branding = this.constantsService.constants.SOCIAL.branding;

    const uid = this.route.snapshot.paramMap.get('id');
    if (uid == null) {
      this.social = {
        address: '',
        eventType: EventType.Social,
        coordinates: null,
        country: '',
        created: 0,
        date: '',
        datetime: 0,
        description: '',
        endTime: '',
        interested: {},
        locationId: '',
        locationName: '',
        memberId: '',
        memberName: '',
        time: '',
        title: ''
      };
      return;
    }

    this.getSocial(uid);
  }

  rejectSocial() {
    this.socialService.rejectSocial(this.social);
  }

  sendAnalytics(social: ISocial) {
    this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, AnalyticsAction.SOCIAL_VIEW_DETAILS, this.social.uid);
  }
}
