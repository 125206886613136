import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { environment } from '@environment/environment';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { AdminGuard } from '@shared/guards/admin-guard';
import { AdminOrHostGuard } from '@shared/guards/admin-or-host-guard';
import { AdminOrHostOrCohostGuard } from '@shared/guards/admin-or-host-or-cohost-guard';
import { MembersGuard } from '@shared/guards/members-guard';
import { SharedModule } from '@shared/shared.module';
import { Angulartics2Module } from 'angulartics2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppMenuComponent } from './components/app-menu/app-menu.component';
import { ChirpyModule } from './modules/white-label/chirpy/chirpy.module';
import { OnlyFriendsModule } from './modules/white-label/friends/only-friends.module';
import { PlantsModule } from './modules/white-label/plants/plants.module';
import { SentryErrorHandler } from './services/sentry-error-handler.service';
/*
// Uncomment to enable firestore debug output (very verbose)
import * as firebase from 'firebase/app';
import 'firebase/firestore';
firebase.firestore.setLogLevel('debug');
*/

@NgModule({
  declarations: [AppComponent, AppFooterComponent, AppHeaderComponent, AppMenuComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule, // imports firebase real time database
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireFunctionsModule,
    AngularFireStorageModule,
    AppRoutingModule,
    // import Angulartics2GoogleGlobalSiteTag in root ngModule
    Angulartics2Module.forRoot({
      gst: environment.gst,
      developerMode: !environment.production
    }),
    IonicStorageModule.forRoot(),
    LeafletModule.forRoot(),
    // White labelled modules
    // TODO: Use a file substitution rule in angular.json so that we only import a single WhiteLabelModule
    ChirpyModule,
    OnlyFriendsModule,
    PlantsModule,
    SharedModule
  ],
  providers: [StatusBar, SplashScreen, AngularFireAuthGuard, AdminGuard, MembersGuard, AdminOrHostGuard, AdminOrHostOrCohostGuard, { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, { provide: ErrorHandler, useClass: SentryErrorHandler }],
  bootstrap: [AppComponent, AppFooterComponent, AppHeaderComponent, AppMenuComponent]
})
export class AppModule {}
