export enum AdminRole {
  // Functions for welcoming new members
  CONCIERGE = 'concierge',
  // Permission to delete members without giving full SUPER access
  DELETE = 'delete',
  // Functions for managing News
  EDITOR = 'editor',
  // Functions for dealing with coins
  FINANCIAL = 'financial',
  // Functions for managing hosts
  HOSTS = 'hosts',
  // Functions for content moderation, verification
  MODERATOR = 'moderator',
  // Romance functions
  ROMANCE = 'romance',
  // All admin functions
  SUPER = 'super',
  // Generic support functions
  SUPPORT = 'support',
  // Functions for managing travel
  TRAVEL = 'travel'
}
