import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IGroup } from '@shared/models/groups/group';
import { ISocial } from '@shared/models/social/social';
import { GroupService } from '@shared/services/groups/group.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'socials',
  templateUrl: './socials.component.html'
})
export class SocialsComponent {
  @Input() button: string;
  @Output() linkClicked = new EventEmitter();
  MAX_SOCIALS: number = 999; // Show all socials for the moment
  socialCount: string = '';
  socials$: Observable<ISocial[]>;
  @Input() group: IGroup;
  @Input() heading: string;
  @Input() noSocialsMessage: string;

  constructor(private groupService: GroupService) {}

  ngOnInit() {
    this.socials$ = this.groupService.getGroupSocials(this.group.uid, this.MAX_SOCIALS).pipe(
      tap(socials => {
        this.socialCount = socials.length > 0 ? `(${socials.length})` : '';
      })
    );
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }
}
