import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { CancellationOption } from '@shared/constants/cancellation-option';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EmailService } from '@shared/services/email/email.service';
import { ToastService } from '@shared/services/toast.service';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { CancelSubscriptionService } from '../../services/cancel-subscription.service';
import { CancelOfferPresenter } from './cancel-offer.presenter';

@Component({
  selector: 'app-cancel-offer',
  templateUrl: 'cancel-offer.page.html'
})
export class CancelOfferPage implements OnInit {
  get form() {
    return this.presenter.form;
  }

  canEdit: boolean = false;
  hasProcessed: boolean = false;
  isProcessing: boolean = false;
  member: UserObject;
  readonly PAGE_ID = `cancel-offer`;
  page$: Observable<any>;
  submitButtonText: string = 'Submit';

  constructor(private authService: AuthService, private emailService: EmailService, private presenter: CancelOfferPresenter, private router: Router, private service: CancelSubscriptionService) {}

  ngOnInit() {
    this.page$ = this.authService._userProfileSubject.pipe(
      switchMap(profile => {
        this.canEdit = this.authService.isAdmin([AdminRole.SUPER]);
        this.member = profile;
        const country = profile.country || null;
        return country ? this.service.getPage(this.PAGE_ID, country) : of(null);
      }),
      first(x => !!x)
    );
  }

  onSubmit() {
    if (this.validateForm()) {
      this.isProcessing = true;
      this.submitButtonText = 'Processing';

      const { offer } = this.presenter.submit();

      if (!!offer) {
        this.emailService.sendSubscriptionOfferRequestToSupport(this.member, this.service.subscriptions);
        this.isProcessing = false;
        this.hasProcessed = true;
        this.submitButtonText = 'Submit';
      } else {
        this.isProcessing = false;
        this.hasProcessed = true;
        this.submitButtonText = 'Submit';
        this.router.navigate(['/account/cancel-subscription/questions']);
      }
    }
  }

  validateForm() {
    return this.presenter.form.valid;
  }
}
