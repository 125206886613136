import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IonTextarea } from '@ionic/angular';
import { AuthService } from '@shared/services/auth.service';
import { ToastService } from '@shared/services/toast.service';
import { UIService } from '@shared/services/ui.service';

@Component({
  selector: 'chirpy-send-message',
  styleUrls: ['./chirpy-send-message.component.scss'],
  templateUrl: './chirpy-send-message.component.html'
})
export class ChirpySendMessageComponent {
  @Input() buttonLabel: string = 'Send';
  @ViewChild('text', { static: false }) text: IonTextarea;
  @ViewChild('media', { static: false }) media: any;
  @Input() canUploadFile: boolean = true;
  hasMediaInput: boolean = false;
  readonly MAX_PHOTOS = 3; // TODO: Make this configurable via ConstantsService
  mediaCount: number;
  @Input() message: string = '';
  @Input() placeholder: string = 'Type a message...';
  @Output() sendMessage = new EventEmitter();
  @Input() stacked: boolean = false;
  @Input() validateFunction = null;

  constructor(private authService: AuthService, private uiService: UIService, private formBuilder: FormBuilder, private toastService: ToastService) {}

  focusInput(): void {
    setTimeout(() => {
      this.text.setFocus();
    }, 0);
  }

  getNumberOfRows() {
    // display textarea with 3 rows on tablets and bigger.
    // display textarea with 1 row on mobile phones.
    return this.uiService.isMediumDisplay ? 3 : 1;
  }

  onCountUpdated(count: number) {
    this.mediaCount = count;
  }

  onSendMessage() {
    const text = (this.text.value || '').trim();
    const media = this.media ? this.media.controlValue : null; // media input can be undefined if member hasn't clicked button to attach anything
    const messageData = { text, media };

    if (text.length === 0 && Object.keys(media || {}).length === 0) {
      this.toastService.presentToast(`Sorry, you can't send a blank message.`);
      return;
    }

    if (this.validateFunction == null || this.validateFunction()) {
      this.sendMessage.emit(messageData);
      this.clearInput();
    }
  }

  onToggleMediaInput() {
    this.hasMediaInput = !this.hasMediaInput;
  }

  private clearInput() {
    this.hasMediaInput = false;
    this.text.value = '';
    if (this.media) this.media.clear();
  }
}
