import { IMemberThreadType } from '@shared/models/messages/member-thread-type';

export enum IMemberThreadRelatedType {
  Event,
  Member
}

export interface IMemberThread {
  uid?: string;
  threadId?: string; // ThreadId
  memberId: string; // which member does this state belong to?
  name: string;
  virtualCatchupName?: string; // Friendly daily.co room name.
  relatedIds: string[];
  relatedType?: IMemberThreadRelatedType;
  dateTimeLastRead: number;
  dateTimeLastUpdated?: number;
  lastMessage?: string;
  lastMessageId?: string;
  lastMessageSeenId?: string;
  type?: IMemberThreadType;
  messageNotificationCount?: number;
  isArchived: boolean;
  isOwner?: boolean;
  isSoleMember?: boolean;
}
