import { Component, Input } from '@angular/core';

@Component({
  selector: 'chirpy-calendar-date',
  styleUrls: ['./chirpy-calendar-date.component.scss'],
  templateUrl: './chirpy-calendar-date.component.html'
})
export class ChirpyCalendarDate {
  @Input() date: string; // yyyy-mm-dd
  @Input() endDate: string = ''; // yyyy-mm-dd
  @Input() icon: boolean = false; // show green tick
  @Input() iconMaybe: boolean = false; // show yellow question mark
  @Input() isTBA: boolean = false; // don't show placeholder date if date is TBA
  layout: string;

  ngOnInit() {
    if (this.isTBA) {
      this.layout = 'isTBA';
    } else if (!this.endDate) {
      this.layout = 'oneDate';
    } else {
      const month1 = this.date.slice(5, 7);
      const month2 = this.endDate.slice(5, 7);
      if (month1 !== month2) {
        this.layout = 'twoMonths';
      } else {
        const day1 = this.date.slice(8, 10);
        const day2 = this.endDate.slice(8, 10);
        this.layout = day1 === day2 ? 'oneDate' : 'twoDates';
      }
    }
  }
}
