import { UserObject } from '@shared/models/user-object';
import { AbstractFooterService } from '../abstract-footer.service';

export class FriendsFooterService extends AbstractFooterService {
  getToolbar(user: UserObject) {
    const coinsIcon = user.coinsLevel ? `/assets/friends/footer/${user.coinsLevel.replace(' ', '-')}-icon.svg` : `/assets/friends/footer/level-1-icon.svg`;
    return [
      {
        iconImage: '/assets/friends/footer/home.svg',
        label: 'HOME',
        routerLink: '/home'
      },
      {
        iconImage: '/assets/friends/footer/find-friends.svg',
        label: 'FRIENDS',
        routerLink: '/find-friends'
      },
      {
        iconImage: '/assets/friends/footer/central.svg',
        label: 'CENTRAL',
        routerLink: '/central'
      },
      {
        badge: {
          counter: user.messageNotificationCount,
          showBadge: user.messageNotificationCount > 0
        },
        iconImage: '/assets/friends/footer/messages.svg',
        label: 'MESSAGES',
        routerLink: '/messages'
      },
      {
        badge: {
          counter: user.coinsBalance,
          showBadge: user.coinsBalance > 0
        },
        iconImage: '/assets/friends/footer/coins.svg',
        label: 'WALLET',
        routerLink: '/coins'
      }
    ];
  }
}
