import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AdminRole } from '@shared/constants/admin-role';
import { SelectType } from '@shared/constants/select-type';
import { ISearchModel } from '@shared/models/search-model';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { LocationService } from '@shared/services/location/location.service';
import { IValueWithId } from '@shared/models/value-with-id';
import { map } from 'rxjs/operators';
import { MeetingPlaceSearchPresenter } from './search.presenter';

@Component({
  selector: 'meeting-place-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  viewProviders: [MeetingPlaceSearchPresenter],
  encapsulation: ViewEncapsulation.None
})
export class MeetingPlaceSearchComponent {
  get CONSTANTS() {
    return this.constantsService.constants.MEETING_PLACE;
  }

  get searchForm() {
    return this.presenter.form;
  }

  country: string;
  selectType: string = SelectType.PLACE;
  @Output() search = new EventEmitter<ISearchModel>();
  selectedItems: Record<string, string>;

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private presenter: MeetingPlaceSearchPresenter, private constantsService: ConstantsService, private locationService: LocationService) {
    this.authService.userProfileObservable.subscribe(user => {
      if (user != null) {
        this.country = user.country;
      }
    });
  }

  isNullSearch(searchModel: ISearchModel) {
    if (Object.keys(searchModel).length === 0) return true;
    return Object.values(searchModel).filter(x => x).length === 0;
  }

  onAddPlace(item: IValueWithId) {
    this.presenter.patchValue({ place: item.name });
    this.presenter.patchValue({ placeId: item.uid });
    this.selectedItems = { [item.uid]: item.name };
  }

  onSearch() {
    const searchModel = this.presenter.search();
    // Make query term lower case to compare against lowercase searchName
    if (searchModel.memberName) searchModel.memberName = searchModel.memberName.toLowerCase();
    // Don't add a country if we are not searching for anything, otherwise we can't trigger a reset
    if (!this.isNullSearch(searchModel)) {
      searchModel.country = this.country;
      this.analyticsService.eventTrack(AnalyticsCategory.MEETING_PLACE, AnalyticsAction.MEETING_PLACE_SEARCH, null, { term1: searchModel.place, term2: searchModel.memberName });
    }
    this.search.emit(searchModel);
  }

  reset() {
    this.presenter.reset();
    this.selectedItems = null;
    this.analyticsService.eventTrack(AnalyticsCategory.MEETING_PLACE, AnalyticsAction.MEETING_PLACE_CLEAR_SEARCH);
    this.onSearch();
  }

  searchPlaces(startsWith: string) {
    const showAllCountries = this.constantsService.constants.APP.allowOtherCountries || this.authService.isAdmin();
    return this.locationService.search(startsWith, this.country, showAllCountries);
  }
}
