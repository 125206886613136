import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { PixCollectionPage } from './pix-collection/pix-collection.page';
import { PixDetailPage } from './pix-detail/pix-detail.page';
import { PixEditPage } from './pix-edit/pix-edit.page';
import { PixSearchComponent } from './pix-search/pix-search.component';
import { PixUploadPage } from './pix-upload/pix-upload.page';
import { PixRoutingModule } from './pix-routing.module';

@NgModule({
  imports: [PixRoutingModule, SharedModule],
  declarations: [PixCollectionPage, PixDetailPage, PixEditPage, PixSearchComponent, PixUploadPage],
  entryComponents: [PixSearchComponent]
})
export class PixModule {}
