import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthService } from '@shared/services/auth.service';
import { skipWhile, take } from 'rxjs/operators';
import { IShareOptions } from '@shared/models/share/share-options';
import { ShareService } from '@shared/services/share/share.service';

@Injectable({
  providedIn: 'root'
})
export class ShareSearchPresenter {
  defaultShareSearch: IShareOptions;
  form = this.formBuilder.group({
    category: [''],
    country: [''],
    location: ['']
  });

  constructor(private formBuilder: FormBuilder, private shareService: ShareService, private authService: AuthService) {}

  reset() {
    this.setValue({ category: 'all', country: null, location: '' });
  }

  search() {
    return this.form.value;
  }

  setValue(shareOptions: IShareOptions) {
    this.form.setValue({
      category: shareOptions.category || 'all',
      country: shareOptions.country || null,
      location: shareOptions.location
    });
  }
}
