import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { IMemberThread } from '@shared/models/messages/member-thread';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { MessageService } from '@shared/services/messages/message.service';
import { ToastService } from '@shared/services/toast.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'more-list',
  styleUrls: ['./more-list.component.scss'],
  templateUrl: './more-list.component.html'
})
export class MoreListComponent implements OnInit {
  CONSTANTS: any;
  items: any[] = [
    {
      title: 'View archived messages',
      url: '/messages/more/archive'
    },
    {
      title: 'Archive messages by date...',
      callback: this.onSelectDate.bind(this)
    }
  ];

  constructor(private alertController: AlertController, private authService: AuthService, private constantsService: ConstantsService, private dateTimeService: DateTimeService, private messageService: MessageService, private toastService: ToastService) {}

  doCallback(item) {
    item.callback(...item.callbackParams);
  }

  onArchiveByDate(data: any) {
    this.messageService
      .getMemberThreads()
      .pipe(
        first(x => !!x) // TODO: What if member has zero memberThreads? But then they have nothing to archive
      )
      .subscribe(async threads => {
        const dateTime = this.dateTimeService.createDate(data.date, '00:00');
        const filteredThreads = threads.filter(x => x.dateTimeLastUpdated < dateTime);
        if (filteredThreads.length === 0) {
          const message = `No threads available for archiving`;
          this.toastService.presentToast(message);
        } else {
          this.onArchiveByDateConfirmation(filteredThreads);
        }
      });
  }

  async onArchiveByDateConfirmation(threads: IMemberThread[]) {
    const alert = await this.alertController.create({
      //cssClass: this.parametersClass,
      header: 'Confirm archiving?',
      message: threads.length > 1 ? `This will archive ${threads.length} threads` : `This will archive 1 thread`,
      inputs: [
        {
          label: 'Type YES to confirm',
          name: 'confirm',
          placeholder: 'Type yes then click confirm',
          type: 'text'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Confirm',
          handler: data => (data.confirm.trim().toLowerCase() === 'yes' ? this.onArchiveByDateHandler(threads) : this.onArchiveByDateConfirmation(threads))
        }
      ]
    });

    await alert.present();
  }

  onArchiveByDateHandler(threads: IMemberThread[]) {
    const updatedData = { isArchived: true };
    for (const thread of threads) {
      //NB updateOwnMemberThread only uses the uid from the memberThread
      this.messageService.updateOwnMemberThread(thread, updatedData);
    }
    const threadCount = threads.length || 0;
    const message = threadCount === 1 ? `1 thread archived` : `${threadCount} threads archived`;
    this.toastService.presentToast(message);
  }

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.MESSAGES.MORE;
  }

  async onSelectDate() {
    const button = this.CONSTANTS.ARCHIVE.button;
    const header = this.CONSTANTS.ARCHIVE.header;
    const message = this.CONSTANTS.ARCHIVE.message;

    const alert = await this.alertController.create({
      //cssClass: this.parametersClass,
      header: header,
      message: message,
      inputs: [
        {
          label: 'Date: ',
          name: 'date',
          placeholder: 'YYYY-MM-DD',
          type: 'date'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: button,
          handler: data => this.onArchiveByDate(data)
        }
      ]
    });

    await alert.present();
  }
}
