export enum CoinsTransactionType {
  // member earns coins
  ADD = 'add',
  // member-initiated transaction adds coins to another member
  CREDIT = 'credit',
  // member-initiated transaction removes coins from a member
  DEBIT = 'debit',
  // entry to explicitly show old coins having expired
  EXPIRED = 'expired',
  // member redeems coins
  REDEEM = 'redeem',
  // this existing transaction has been reversed by an admin
  REVERSED = 'reversed',
  // admin reverses another transaction
  REVERSAL = 'reversal'
}
