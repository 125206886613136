import { Injectable } from '@angular/core';
import { EventType } from '@infrastructure/constants/event-type';
import { IBaseEvent } from '@shared/models/base-event';

@Injectable({
  providedIn: 'root'
})
export class CatchupTypeService {
  // NB This is used on CatchupEditPage and EventCardComponent.
  // TODO: Rename to EventTypeService?
  private allowedTypes: Record<string, EventType[]> = {
    address: [EventType.CatchUp, EventType.Event, EventType.Social, EventType.Trip],
    group: [EventType.CatchUp, EventType.Virtual],
    host: [EventType.CatchUp, EventType.Virtual],
    region: [EventType.CatchUp, EventType.Event],
    rsvp: [EventType.CatchUp, EventType.Event, EventType.Virtual],
    time: [EventType.CatchUp, EventType.Event, EventType.Virtual]
  };

  canShowAddress(eventType: EventType) {
    return this.canShowField('address', eventType);
  }

  canShowGroup(eventType: EventType) {
    return this.canShowField('group', eventType);
  }

  canShowHost(eventType: EventType) {
    return this.canShowField('host', eventType);
  }

  canShowRegion(eventType: EventType) {
    return this.canShowField('region', eventType);
  }

  canShowRSVP(eventType: EventType) {
    return this.canShowField('rsvp', eventType);
  }

  canShowTime(eventType: EventType) {
    return this.canShowField('time', eventType);
  }

  private canShowField(field: string, eventType: EventType) {
    return this.allowedTypes[field].includes(eventType);
  }
}
