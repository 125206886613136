import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ICoinsSetting } from '@shared/models/coins/coins-setting';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { AuthService } from '@shared/services/auth.service';
import { EmailService } from '@shared/services/email/email.service';
import { ToastService } from '@shared/services/toast.service';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { ChirpyRedeemCoinsFormPresenter } from './chirpy-redeem-coins-form.presenter';

@Component({
  selector: 'chirpy-redeem-coins-form',
  templateUrl: './chirpy-redeem-coins-form.component.html',
  viewProviders: [ChirpyRedeemCoinsFormPresenter]
})
export class ChirpyRedeemCoinsFormComponent {
  get form() {
    return this.presenter.form;
  }

  coinsBalance: number;
  interfaceOptions: any = { cssClass: 'wide-select' };
  @Input() insufficientBalanceText = `Sorry, you don't have enough coins.`;
  member: UserObject;
  options$: Observable<ICoinsSetting[]>;
  sent: boolean = false;
  showForm: boolean = true;
  @Input() submitButtonText = 'Submit';
  @Input() successMessage = 'Your message has been sent to support.';

  constructor(private analyticsService: AnalyticsService, private appOptionsService: AppOptionsService, private authService: AuthService, private emailService: EmailService, private presenter: ChirpyRedeemCoinsFormPresenter, private toastService: ToastService) {}

  ngOnInit() {
    this.options$ = this.authService._userProfileSubject.pipe(
      switchMap((member: UserObject) => {
        this.coinsBalance = member.coinsBalance;
        this.member = member;
        this.form.patchValue({ phone: member.phone || '' }); // pre-fill phone number
        const values: Observable<ICoinsSetting[]> = this.appOptionsService.getOptionsValues('coinsRedeemedFor', member.country).pipe(
          map((options: ICoinsSetting[]) => {
            const filtered = options.filter(x => +this.coinsBalance >= +x.value);

            return filtered;
          })
        );
        return values;
      })
    );
  }

  onSubmit() {
    const formValue = this.presenter.form.value;
    if (formValue.option == null || formValue.option === '') {
      this.toastService.presentToast(`Please select a reward.`);
      return;
    }

    this.showForm = false;
    this.sent = true;
    this.emailService.sendCoinsRedemptionRequest(formValue.option, this.member, formValue);
    this.analyticsService.eventTrack(AnalyticsCategory.COINS, AnalyticsAction.COINS_REDEEM, formValue.option);
  }
}
