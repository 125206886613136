import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { IGroup } from '@shared/models/groups/group';
import { IListingImage } from '@shared/models/image/listing-image';
import { IMedia } from '@shared/models/media/media';
import { IValueWithId } from '@shared/models/value-with-id';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { GroupService } from '@shared/services/groups/group.service';
import { ImageService } from '@shared/services/image/image.service';
import { PhotoService } from '@shared/services/photos/photo.service';
import { ToastService } from '@shared/services/toast.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ChirpyEditMediaMetadataPresenter } from './chirpy-edit-media-metadata.presenter';

@Component({
  selector: 'chirpy-edit-media-metadata',
  styleUrls: ['./chirpy-edit-media-metadata.component.scss'],
  templateUrl: './chirpy-edit-media-metadata.component.html',
  viewProviders: [ChirpyEditMediaMetadataPresenter]
})
export class ChirpyEditMediaMetadataComponent implements OnInit {
  get form() {
    return this.presenter.form;
  }

  @Input() buttonText: string;
  @Input() collectionId: string;
  @Input() collectionTitle: string;
  @Input() collectionType: MediaCollectionType;
  CONSTANTS: Record<string, any>;
  emptyCategory: any = { id: null, type: '' };
  groupsHosted$: Observable<IValueWithId[]>;
  hasRelatedGroup: boolean = false;
  hasRelatedMember: boolean = false;
  interfaceOptions: any = { cssClass: 'wide-select' };
  isGroup: boolean;
  isPublic: boolean;
  MEDIA_CATEGORIES$: BehaviorSubject<any[]>;
  @Output() metadataValidated = new EventEmitter();
  @Input() media: IMedia;

  compareById(a: any, b: any) {
    return a && b ? a.id === b.id : a === b;
  }

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private groupService: GroupService,
    private photoService: PhotoService,
    private presenter: ChirpyEditMediaMetadataPresenter,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.MEDIA;
    this.MEDIA_CATEGORIES$ = this.photoService.mediaCategories;

    const relatedId = this.photoService.getRelatedId(this.media.collections);
    const relatedType = this.photoService.getRelatedType(this.media.collections);
    const metadata = {
      caption: this.media.caption,
      category: this.media.category ? { id: this.media.category, type: relatedType } : this.emptyCategory, // the corresponding ion-select only looks at the ID
      public: this.collectionType === MediaCollectionType.PUBLIC || this.media.collections.some(x => x === MediaCollectionType.PUBLIC),
      relatedId: relatedId
    };

    switch (this.collectionType) {
      case MediaCollectionType.PUBLIC:
        this.isGroup = false;
        this.isPublic = true;
        this.hasRelatedGroup = this.media.category !== '' && relatedType === MediaCollectionType.GROUP;
        this.hasRelatedMember = this.media.category !== '' && relatedType === MediaCollectionType.MEMBER;
        this.groupsHosted$ = this.authService._userProfileSubject.pipe(switchMap(member => (this.authService.isAdmin([AdminRole.HOSTS]) ? this.groupService.getAllGroupNames(true) : this.groupService.getGroupsHostedByMember$(member.uid))));
        break;

      case MediaCollectionType.GROUP:
        this.isGroup = true;
        this.isPublic = metadata.public;
        break;

      case MediaCollectionType.MEMBER:
      default:
        this.isGroup = false;
        this.isPublic = false;
    }
    this.presenter.setValue(metadata);
  }

  onSelectCategory(event: any) {
    const category = event.detail.value;
    if (category.type === MediaCollectionType.PUBLIC) return;

    switch (category.type) {
      case MediaCollectionType.GROUP:
        this.hasRelatedGroup = true;
        this.hasRelatedMember = false;
        this.presenter.patchValue({ relatedId: '' });
        break;

      case MediaCollectionType.MEMBER:
        this.hasRelatedGroup = false;
        this.hasRelatedMember = true;
        this.presenter.patchValue({ relatedId: this.authService._userProfileSubject.value.uid }); //TODO What about if an admin is editing?
        break;

      default:
        this.hasRelatedGroup = false;
        this.hasRelatedMember = false;
        this.presenter.patchValue({ relatedId: '' });
        break;
    }
  }

  onSubmit() {
    if (this.validateForm()) this.metadataValidated.emit(this.presenter.media());
  }

  onTogglePublic(event: any) {
    const publicValue = !!event.detail.value;
    this.isPublic = publicValue;
    if (publicValue === false) {
      this.hasRelatedGroup = false;
      this.hasRelatedMember = false;
      this.presenter.patchValue({ category: this.emptyCategory, relatedId: '' }); // value of public has just been changed by the control
    }
  }

  private validateForm(): boolean {
    const data = this.presenter.media();

    const messages: string[] = [];

    if (data.caption.trim().length === 0) {
      messages.push(`Please enter a caption.`);
    }
    if (data.public === true) {
      if (!data.category.hasOwnProperty('id')) {
        messages.push(`Please choose a category.`);
      } else if (data.category.id == null) {
        messages.push(`Sorry, only media falling into one of the above categories can be shown in ${this.CONSTANTS.branding}.`);
      }

      if (data.category.type && data.category.type === MediaCollectionType.GROUP && data.relatedId === '' && this.collectionType === MediaCollectionType.PUBLIC) {
        messages.push(`Please select the group the ${data.category.id} relates to.`);
      }
    }

    if (messages.length > 0) {
      this.toastService.presentToast(messages.join('\n'));
      return false;
    }

    return true;
  }
}
