import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { MessageType } from '@shared/constants/message-type';
import { IMemberThread } from '@shared/models/messages/member-thread';
import { IMemberThreadType } from '@shared/models/messages/member-thread-type';
import { IMessageData } from '@shared/models/messages/message-data';
import { AuthService } from '@shared/services/auth.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { MessageService } from '@shared/services/messages/message.service';
import { ThreadOpenService } from '@shared/services/messages/thread-open.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ThreadDetailService {
  get member() {
    return this.messageService.user;
  }

  thread: IMemberThread;

  archiveMemberThread() {
    return this.messageService.archiveMemberThread(this.thread.threadId, this.thread.relatedIds, this.thread.memberId);
  }

  constructor(private dateTimeService: DateTimeService, private messageService: MessageService, private authService: AuthService, private userService: UserService, private threadOpenService: ThreadOpenService, private alertController: AlertController, private toastService: ToastService) {}

  createMessage(messageData, threadId: string, messageType: MessageType, threadType: IMemberThreadType, emailData: any) {
    return this.messageService.createMessage(messageData, threadId, messageType, threadType, emailData);
  }

  isThreadNameValid(name: string) {
    let valid = true;
    if (name.length === 0) {
      this.toastService.presentToast(`Please enter a name.`);
      valid = false;
    }

    if (name.length > 160) {
      this.toastService.presentToast(`Please enter a shorter name.`);
      valid = false;
    }

    if (!this.messageService.isGroupNameUnique(this.messageService.memberThreads, name)) {
      valid = false;
    }

    return valid;
  }

  loadThread(threadId: string, callback: Function) {
    this.messageService
      .getMemberThread(threadId)
      .pipe(first(x => !!x))
      .subscribe(thread => {
        if (thread == null) {
          return;
        }

        // Without this check, the following functions would be called every time the memberThread was updated, and lead to an infinite loop
        if (this.thread == null || threadId !== this.thread.threadId) {
          this.thread = thread;
          this.onEnterMemberThreadUpdateDetails();
          // Don't update seenBy indicator if an admin is viewing the thread
          if (!this.authService.isImpersonating) this.messageService.updateSeenBy(this.thread.memberId, this.thread.threadId, this.thread.lastMessageSeenId || null, this.thread.lastMessageId || null);
          if (callback) callback();
        }
      });
  }

  onEnterMemberThreadUpdateDetails() {
    if (!this.authService.isImpersonating) {
      this.userService.decrementOwnMessageNotificationCount(this.thread.messageNotificationCount);
      this.threadOpenService.setThreadOpen(this.thread.uid);
      this.messageService.updateOwnMemberThread(this.thread, {
        dateTimeLastRead: this.dateTimeService.getDateTime(),
        lastMessageSeenId: this.thread.lastMessageId || null, // when creating a new thread, member enters the thread before the data is set on the memberThread
        messageNotificationCount: 0
      });
    }
  }

  onLeaveMemberThreadUpdateDetails() {
    //Thread isOpen status is updated in StateGuard
    if (!this.authService.isImpersonating) {
      this.messageService.updateOwnMemberThread(this.thread, {
        dateTimeLastRead: this.dateTimeService.getDateTime()
      });
    }
  }

  async presentRenameThreadPrompt(memberThreadType: IMemberThreadType, name: string, threadId: string) {
    const threadType = memberThreadType === IMemberThreadType.Conversation ? 'Conversation' : 'Group';
    const headerText = `Rename ${threadType}`;

    // truncate long thread titles to prevent tall modals pushing the name textbox and Rename button off-screen.
    const MAX_LENGTH = 320;
    if (name.length > MAX_LENGTH) {
      name = name.slice(0, MAX_LENGTH) + '...';
    }

    const alert = await this.alertController.create({
      header: headerText,
      subHeader: `Enter a new name for ${name}`,
      inputs: [
        {
          name: 'name',
          type: 'text',
          placeholder: `Enter a new name...`
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: `Rename`,
          handler: data => this.renameThreadHandler(memberThreadType, name, threadId, data)
        }
      ]
    });

    await alert.present();
  }

  renameThreadHandler(memberThreadType, oldName, threadId, data) {
    const name = data.name.trim();

    if (!this.isThreadNameValid(name)) {
      return false; // return false will stop the alert from closing.
    }

    this.messageService.renameThread(threadId, name);
  }
}
