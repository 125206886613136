import { Component } from '@angular/core';

@Component({
  selector: 'bingo-game-waiting-card',
  templateUrl: './game-waiting-card.component.html',
  styleUrls: ['./game-waiting-card.component.scss']
})
export class GameWaitingCardComponent {
  gameWaitingMessage = 'Game will start soon!';
}
