import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LogoutResolver } from '@shared/services/logout.resolve';
import { SharedModule } from '@shared/shared.module';
import { LoginPage } from './login/login.page';
import { ResetPasswordPage } from './reset-password/reset-password.page';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginPage,
        data: {
          title: 'Login'
        }
      },
      {
        path: 'logout',
        component: LoginPage,
        resolve: { items: LogoutResolver },
        data: {
          title: 'Logout'
        }
      },
      {
        path: 'reset-password/:email',
        component: ResetPasswordPage,
        data: {
          title: 'Reset password'
        }
      },
      {
        path: 'reset-password',
        component: ResetPasswordPage,
        data: {
          title: 'Reset password'
        }
      }
    ])
  ],
  providers: [LogoutResolver],
  declarations: [LoginPage, ResetPasswordPage]
})
export class AuthPageModule {}
