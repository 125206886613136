import { BlockTool } from '@editorjs/editorjs';

export class BasePlugin implements BlockTool {
  /**
   * Our tool should be placed at the Toolbox, so describe an icon and title
   */
  static get toolbox() {
    return {
      title: 'Plugin',
      icon:
        '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
    };
  }

  api: any;
  config: any;
  data: any;
  settings: any;
  wrapper: any;

  /**
   * Tool class constructor
   * @param {ImageToolData} data — previously saved data
   * @param {object} api — Editor.js Core API {@link  https://editorjs.io/api}
   * @param {ImageToolConfig} config — custom config that we provide to our tool's user
   */
  constructor({ data, api, config }) {
    this.api = api;
    this.config = config || {};
    this.data = data || {};

    this.wrapper = undefined;
    this.settings = [
      {
        name: 'withBorder',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.8 10.592v2.043h2.35v2.138H15.8v2.232h-2.25v-2.232h-2.4v-2.138h2.4v-2.28h2.25v.237h1.15-1.15zM1.9 8.455v-3.42c0-1.154.985-2.09 2.2-2.09h4.2v2.137H4.15v3.373H1.9zm0 2.137h2.25v3.325H8.3v2.138H4.1c-1.215 0-2.2-.936-2.2-2.09v-3.373zm15.05-2.137H14.7V5.082h-4.15V2.945h4.2c1.215 0 2.2.936 2.2 2.09v3.42z"/></svg>`
      },
      {
        name: 'stretched',
        icon: `<svg width="17" height="10" viewBox="0 0 17 10" xmlns="http://www.w3.org/2000/svg"><path d="M13.568 5.925H4.056l1.703 1.703a1.125 1.125 0 0 1-1.59 1.591L.962 6.014A1.069 1.069 0 0 1 .588 4.26L4.38.469a1.069 1.069 0 0 1 1.512 1.511L4.084 3.787h9.606l-1.85-1.85a1.069 1.069 0 1 1 1.512-1.51l3.792 3.791a1.069 1.069 0 0 1-.475 1.788L13.514 9.16a1.125 1.125 0 0 1-1.59-1.591l1.644-1.644z"/></svg>`
      },
      {
        name: 'withBackground',
        icon: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.043 8.265l3.183-3.183h-2.924L4.75 10.636v2.923l4.15-4.15v2.351l-2.158 2.159H8.9v2.137H4.7c-1.215 0-2.2-.936-2.2-2.09v-8.93c0-1.154.985-2.09 2.2-2.09h10.663l.033-.033.034.034c1.178.04 2.12.96 2.12 2.089v3.23H15.3V5.359l-2.906 2.906h-2.35zM7.951 5.082H4.75v3.201l3.201-3.2zm5.099 7.078v3.04h4.15v-3.04h-4.15zm-1.1-2.137h6.35c.635 0 1.15.489 1.15 1.092v5.13c0 .603-.515 1.092-1.15 1.092h-6.35c-.635 0-1.15-.489-1.15-1.092v-5.13c0-.603.515-1.092 1.15-1.092z"/></svg>`
      }
    ];
  }

  /**
   * Return a Tool's UI
   * @return {HTMLElement}
   */
  render() {
    this.wrapper = document.createElement('ion-card');
    this.wrapper.style.zIndex = 0;

    const header = document.createElement('ion-card-header');
    const title = document.createTextNode(this.config.type);
    header.appendChild(title);
    this.wrapper.appendChild(header);

    if (this.config.fields) {
      const content = document.createElement('ion-card-content');

      this.config.fields.forEach(field => {
        const item = this.renderField(field, this.data);
        content.appendChild(item);
      });

      this.wrapper.appendChild(content);
    }

    return this.wrapper;
  }

  // Implement this function in a child class
  renderField(field: any, data: any): any {
    const ionItem = document.createElement('ion-item');
    const ionLabel = document.createElement('ion-label');
    ionLabel.setAttribute('position', 'stacked');
    ionLabel.innerHTML = field.id; // could use createTextNode
    ionItem.appendChild(ionLabel);

    const ionInput = document.createElement('ion-input');
    ionInput.setAttribute('id', field.id); // TODO: This may not be unique on the page
    ionInput.placeholder = field.placeholder || '';
    if (data[field.id]) ionInput.value = data[field.id];
    ionItem.appendChild(ionInput);

    return ionItem;
  }

  /**
   * Extract data from the UI
   * @param {HTMLElement} blockContent — element returned by render method
   * @return {SimpleImageData}
   */
  save(blockContent) {
    let data = {};
    if (this.config.fields) {
      this.config.fields.forEach(field => {
        data[field.id] = this.saveField(blockContent, field);
      });
    }
    return data;
  }

  saveField(blockContent: any, field: any) {
    // NB Use block scoping around case statements to prevent collisions of variable names
    switch (field.type) {
      case 'array': {
        const numberOfFields = field.fields.length;
        const parent = blockContent.querySelector(`#${field.id}`);
        const inputs = Array.from(parent.querySelectorAll('input'));
        const numberOfItems = inputs.length / numberOfFields;
        const values = [];
        for (let i = 0; i < numberOfItems; i++) {
          const item: any[] = inputs.slice(i * numberOfFields, (i + 1) * numberOfFields);
          let current = {};
          for (let j = 0; j < numberOfFields; j++) {
            const type = field.fields[j].id;
            current[type] = item[j].value;
          }
          if (Object.values(current).filter(x => !!x).length > 0) values.push(current); // Only push non-empty values. This removes the need for a UI to remove extra unneeded items
        }
        return values;
        break;
      }

      case 'object': {
        const numberOfFields = field.fields.length;
        const parent = blockContent.querySelector(`#${field.id}`);
        const inputs: any[] = Array.from(parent.querySelectorAll('input'));
        let values = {};
        for (let k = 0; k < numberOfFields; k++) {
          const key = field.fields[k].id;
          values[key] = inputs[k].value;
        }
        return values;
        break;
      }

      case 'string':
      default:
        const input = blockContent.querySelector(`#${field.id}`);
        return input.value;
        break;
    }
  }

  /**
   * Skip empty blocks
   * @see {@link https://editorjs.io/saved-data-validation}
   * @param {ImageToolConfig} savedData
   * @return {boolean}
   */
  validate(savedData) {
    // Purpose of validate method is not to validate individual fields, but remove entire block from saved output if empty
    return (
      Object.values(savedData || {}).filter(x => {
        if (Array.isArray(x)) return x.length > 0;
        // validation occurs after saving, empty rows in array are removed at save step
        else return !!x;
      }).length > 0
    );
  }

  /**
   * Making a Block settings: 'add border', 'add background', 'stretch to full width'
   * @see https://editorjs.io/making-a-block-settings — tutorial
   * @see https://editorjs.io/tools-api#rendersettings - API method description
   * @return {HTMLDivElement}
   */
  renderSettings() {
    const wrapper = document.createElement('div');

    this.settings.forEach(tune => {
      let button = document.createElement('div');

      button.classList.add(this.api.styles.settingsButton);
      button.classList.toggle(this.api.styles.settingsButtonActive, this.data[tune.name]);
      button.innerHTML = tune.icon;
      wrapper.appendChild(button);

      button.addEventListener('click', () => {
        this._toggleTune(tune.name);
        button.classList.toggle(this.api.styles.settingsButtonActive);
      });
    });

    return wrapper;
  }

  /**
   * @private
   * Create empty object based on field ids from config
   */
  _createDefaultValues(field) {
    let output = {};
    for (const f of field.fields) {
      output[f.id] = ''; // TODO: change default value based on type
    }
    return output;
  }

  /**
   * @private
   * Click on the Settings Button
   * @param {string} tune — tune name from this.settings
   */
  _toggleTune(tune) {
    this.data[tune] = !this.data[tune];
    this._acceptTuneView();
  }

  /**
   * Add specified class corresponds with activated tunes
   * @private
   */
  _acceptTuneView() {
    this.settings.forEach(tune => {
      this.wrapper.classList.toggle(tune.name, !!this.data[tune.name]);

      if (tune.name === 'stretched') {
        this.api.blocks.stretchBlock(this.api.blocks.getCurrentBlockIndex(), !!this.data.stretched);
      }
    });
  }
}
