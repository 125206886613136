import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { SignupStepService } from '../services/signup-step.service';
import { SignupOptionalPresenter } from './optional.presenter';

@Component({
  selector: 'app-signup-optional',
  templateUrl: './optional.page.html',
  viewProviders: [SignupOptionalPresenter]
})
export class SignupOptionalPage implements OnInit {
  get formValid() {
    return this.presenter.form.valid;
  }

  get setOptionalForm() {
    return this.presenter.form;
  }

  buttonColor: string;
  CONSTANTS: any;
  isProcessing: boolean = false;
  PHONE_NUMBER: RegExp = new RegExp(/^[extn\d\s\()+-]{0,25}$/i);
  progressColor: string;
  showBackButton: boolean = false;
  showNavigation: boolean = false;
  submitButtonText: string = 'Next';
  useBar: boolean;

  constructor(
    private authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private presenter: SignupOptionalPresenter,
    public route: ActivatedRoute,
    private router: Router,
    private signupStepService: SignupStepService,
    private toastService: ToastService,
    private userService: UserService
  ) {}

  ionViewWillEnter() {
    if (this.authService._userProfileSubject.value) {
      const dateOfBirth = this.authService._userProfileSubject.value.dateOfBirth;
      const dob = dateOfBirth ? `${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}` : '';
      this.presenter.setFormValue(this.authService._userProfileSubject.value.phone, dob);
    }
  }

  ngOnInit() {
    this.buttonColor = this.constantsService.constants.WELCOME.SIGNUP.buttonColor;
    this.CONSTANTS = this.constantsService.constants.WELCOME.SIGNUP.OPTIONAL;
    this.progressColor = this.constantsService.constants.WELCOME.SIGNUP.progressColor;
    this.showNavigation = this.constantsService.constants.WELCOME.SIGNUP.showNavigation;
    this.useBar = this.constantsService.constants.WELCOME.SIGNUP.useProgressBar;
  }

  onSubmit() {
    if (!this.validateForm()) {
      return;
    } else {
      this.isProcessing = true;
      this.submitButtonText = 'Processing';
      const { phone, dob } = this.presenter.submit();
      this.userService.updateUserField(this.authService._userProfileSubject.value.uid, 'phone', this.sanitisePhone(phone));
      const dobFields = dob.split('-'); // 1900-12-25
      const dateOfBirth = dobFields.length === 3 ? { year: dobFields[0], month: dobFields[1], day: dobFields[2] } : null;
      this.userService.updatePrivateUserField(this.authService._userProfileSubject.value.uid, 'dateOfBirth', dateOfBirth);
      const nextStep = this.signupStepService.getNextStep(this.route);
      this.isProcessing = false;
      this.submitButtonText = 'Next';
      if (nextStep) this.router.navigate(['/welcome/signup', nextStep]);
    }
  }

  validateForm() {
    let message = ``;
    let { phone, dob } = this.presenter.submit();
    phone = this.sanitisePhone(phone);
    const length = phone.length;
    if (phone && (!this.PHONE_NUMBER.test(phone) || !(length === 0 || length > 6))) message += `Please enter a valid phone number, or leave this field blank.`; // TODO: Should we use a library to do proper international phone number validation?

    const MIN_AGE = this.CONSTANTS.DOB.minAge;
    const MAX_AGE = this.CONSTANTS.DOB.maxAge;
    if (dob && !this.dateTimeService.validateDateOfBirth(dob, MIN_AGE, MAX_AGE)) message += `Please enter a valid date of birth, or leave this field blank.`;

    if (message.length > 0) {
      this.toastService.presentToast(message);
      return false;
    }

    return true;
  }

  private sanitisePhone(phone: string): string {
    return (phone || '').trim();
  }
}
