import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { BaseAdminComponent } from '../base-admin/base-admin.component';

@Component({
  selector: 'app-add-remove-coins',
  templateUrl: '../base-admin/base-admin.component.html',
  styleUrls: ['../base-admin/base-admin.component.scss']
})
export class AddRemoveCoinsComponent extends BaseAdminComponent {
  placeholder = 'Search for a member to add/remove coins';
  showConfirm = false;
  title = 'Manage coins for member';

  constructor(alertController: AlertController, private router: Router) {
    super(alertController);
  }

  selectMemberHandler({ memberId, memberName, data }) {
    this.router.navigate(['/admin/coins-edit', memberId, memberName]);
  }
}
