import { UserObject } from '@shared/models/user-object';
import { IToolbarIcon } from '../../models/toolbar-icon';
import { AbstractFooterService } from '../abstract-footer.service';

export class PlantsFooterService extends AbstractFooterService {
  getToolbar(user: UserObject): IToolbarIcon[] {
    return [
      {
        iconImage: '/assets/plants/footer/home.svg',
        label: 'Home',
        routerLink: '/home'
      },
      {
        iconImage: '/assets/plants/dashboard/my-greenhouse.svg',
        label: 'My\u00a0Greenhouse',
        routerLink: '/my-greenhouse'
      },
      {
        badge: {
          counter: user.messageNotificationCount,
          showBadge: user.messageNotificationCount > 0
        },
        iconImage: '/assets/plants/footer/messages.svg',
        label: 'Messages',
        routerLink: '/messages'
      },
      {
        iconImage: '/assets/plants/footer/activity.svg',
        label: 'Activity',
        routerLink: '/my-greenhouse/activity'
      }
    ];
  }
}
