import { Injectable } from '@angular/core';
import { EventType } from '@infrastructure/constants/event-type';
import { IAttendance } from '@shared/models/attendance/attendance';
import { IEventAttendance } from '@shared/models/attendance/event-attendance';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { firestore } from 'firebase/app';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import FieldValue = firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class AttendanceDatabase extends BaseDatabase {
  getAttendance(uid: string): Observable<IAttendance> {
    return this.getDocument<IAttendance>(this.COLLECTION.ATTENDANCE, uid);
  }

  getAttendanceByIds(uids: string[]): Observable<any[]> {
    const outputs$: Array<Observable<IAttendance[]>> = [];
    const queryFns = this.createChunkedQueryFunctions(uids, '__name__', 'in', [], true);
    if (queryFns.length > 0) {
      //Combine results from multiple database queries
      const newAttendances = this.getDocumentsByMultipleQueries<IAttendance>(this.COLLECTION.ATTENDANCE, queryFns);
      outputs$.push(newAttendances);
    }

    return combineLatest(...outputs$).pipe(
      map(arrays => Array.prototype.concat(...arrays)) //TODO use arrays.flat() when supported
    );
  }

  getAttendanceByQuery(whereCondition: IWhereCondition): Observable<IAttendance[]> {
    const queryFn = ref => ref.where(whereCondition.field, whereCondition.operator, whereCondition.value);
    return this.getDocumentsByQuery<IAttendance>(this.COLLECTION.ATTENDANCE, queryFn);
  }

  removeAttendance(memberId: string, eventType: EventType, event: IEventAttendance) {
    const data = { [eventType]: FieldValue.arrayRemove(event) };
    this.updateDocument(this.COLLECTION.ATTENDANCE, memberId, data);
  }

  updateAttendance(memberId: string, data: any) {
    this.updateDocument(this.COLLECTION.ATTENDANCE, memberId, data);
  }
}
