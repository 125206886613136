import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ICatchup } from '@shared/models/catchups/catchup';
import { IGuest } from '@shared/models/catchups/guest';
import { IColumn } from '@shared/models/column';
import { IDataTableConfig } from '@shared/models/data-table-config';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UserService } from '@shared/services/user/user.service';
import { Subscription } from 'rxjs';
import { first, skipWhile, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-catchup-guests',
  templateUrl: './catchup-guests.component.html'
})
export class CatchupGuestsComponent implements OnInit {
  canManageGroup: boolean; // is Admin or Group Host
  catchup: ICatchup;
  catchupSubscription: Subscription;
  columns: IColumn[] = [
    { label: 'Name', field: 'name', class: 'data-table__column-nowrap', placeholder: 'Click to enter guest details...' },
    { label: 'Contact details', field: 'contact' },
    { label: 'Notes', field: 'notes' }
  ];
  config: IDataTableConfig = {
    onClick: {
      color: 'danger',
      icon: 'remove-circle-outline',
      title: 'Delete'
    }
  };
  CONSTANTS: any;
  guests: IGuest[] = [];
  guestCount: number;
  user: UserObject;

  constructor(
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private catchupService: CatchupService,
    private constantsService: ConstantsService,
    private groupService: GroupService,
    private subscriptionService: SubscriptionService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {}

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.catchupSubscription);
  }

  ngOnInit() {
    const uid = this.route.snapshot.parent.paramMap.get('catchupId');
    this.CONSTANTS = this.constantsService.constants.CATCHUPS;

    // Ideally call eventTrack on ionViewWillEnter, but then have a race condition with loading CatchUp data
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_VIEW_GUESTS, uid);

    this.catchupSubscription = this.catchupService
      .getCatchup(uid)
      .pipe(
        first(x => !!x),
        switchMap((catchup: ICatchup) => {
          this.catchup = catchup;
          return this.groupService.canManageGroup$(catchup.groupId);
        }),
        switchMap((canManageGroup: boolean) => {
          this.canManageGroup = canManageGroup;
          return this.catchupService.getCatchupGuests(this.catchup.uid);
        })
      )
      .subscribe((guests: IGuest[]) => {
        if (guests == null) return;
        this.guests = guests;
        this.guestCount = guests.length;
      });
    this.subscriptionService.add(this.catchupSubscription);

    this.authService._userProfileSubject
      .pipe(
        skipWhile(u => !u),
        take(1)
      )
      .subscribe(user => (this.user = user));
  }

  onAddGuest() {
    const guest = { name: '', contact: '', notes: '' };
    this.guests.push(guest);
    this.onUpdate(guest, this.guests.length - 1);
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_ADD_GUEST, this.catchup.uid, {}, this.guests.length);
  }

  onClick({ data: guest, row: index, isIcon }) {
    if (isIcon) {
      this.onDelete(index);
    } else {
      this.onUpdate(guest, index);
    }
  }

  async onDelete(index: number) {
    const alert = await this.alertController.create({
      header: `Delete guest`,
      message: `Are you sure? This cannot be undone.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: `Delete`,
          handler: data => this.deleteGuestHandler(index)
        }
      ]
    });

    await alert.present();
  }

  async onUpdate(guest: IGuest, index: number) {
    const alert = await this.alertController.create({
      header: `Edit guest details`,
      inputs: [
        {
          name: 'name',
          type: 'text',
          value: guest.name || '',
          placeholder: `Enter guest name...`
        },
        {
          name: 'contact',
          type: 'text',
          value: guest.contact || '',
          placeholder: `Enter contact details (optional)`
        },
        {
          name: 'notes',
          type: 'text',
          value: guest.notes || '',
          placeholder: `Enter notes (optional)`
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: `Save`,
          handler: data => this.updateGuestHandler(data, guest, index)
        }
      ]
    });

    await alert.present();
  }

  private deleteGuestHandler(index: number) {
    this.guests.splice(index, 1);
    this.catchupService.updateCatchupGuests(this.catchup.uid, this.guests);
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_DELETE_GUEST, this.catchup.uid, {}, this.guests.length);
  }

  private updateGuestHandler(guest: IGuest, oldGuest: IGuest, index: number) {
    // Check for changes
    let changed = false;
    let deleted = true;
    const keys = Object.keys(guest);
    for (let key of keys) {
      changed = changed || guest[key].trim() !== oldGuest[key].trim();
      deleted = deleted && guest[key].trim() === '';
    }

    // Don't write to database if nothing has changed
    if (!deleted && !changed) return;

    if (deleted) {
      this.guests.splice(index, 1);
      this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_ERASE_GUEST, this.catchup.uid, {}, this.guests.length);
    } else if (changed) {
      this.guests[index] = guest;
      this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_UPDATE_GUEST, this.catchup.uid, {}, this.guests.length);
    }
    this.catchupService.updateCatchupGuests(this.catchup.uid, this.guests);
  }
}
