import { Component, OnInit } from '@angular/core';
import { MessageType } from '@shared/constants/message-type';
import { IColumn } from '@shared/models/column';
import { IDataTableConfig } from '@shared/models/data-table-config';
import { DefaultMessageOptions, IMessageOptions } from '@shared/models/messages/message-options';
import { IMessage } from '@shared/models/messages/message';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { GroupService } from '@shared/services/groups/group.service';
import { MessageService } from '@shared/services/messages/message.service';
import { firestore } from 'firebase/app';
import { first, switchMap, take } from 'rxjs/operators';
import { IInput } from '@shared/models/input';
import { BaseLogPage } from '../../base-log/base-log.page';

@Component({
  selector: 'app-logs-chit-chat-log',
  templateUrl: '../../base-log/base-log.page.html'
})
export class ChitChatLogComponent extends BaseLogPage {
  columns: IColumn[] = [
    { label: 'Date', field: 'date', class: 'data-table__column-responsive-bold' },
    { label: 'Sender', field: 'name' },
    { label: 'Group', field: 'threadName' },
    { label: 'Content', field: 'content', class: 'data-table__column-responsive-italic' },
    { label: 'Photos', field: 'photos' }
  ];
  config: IDataTableConfig = {
    routerLink: {
      path: '/groups/chit-chat',
      id: [':threadId', ':threadName']
    }
  };
  DEFAULT_OPTIONS = DefaultMessageOptions;
  interfaceOptions: any = { cssClass: 'wide-select' };
  searchModel: IMessageOptions = DefaultMessageOptions;
  title = `Chit-chat log`;
  //fields is out of alphabetical order so we can reference other instance members
  fields: IInput[] = [
    {
      label: 'Sent after: ',
      name: 'sentAfter',
      type: 'datetime-local'
    },
    {
      label: 'Sent before: ',
      name: 'sentBefore',
      type: 'datetime-local'
    },
    {
      label: 'Sender: ',
      name: 'sender',
      placeholder: 'Enter displayName',
      type: 'chirpy-select-items',
      attributes: {
        noResultsText: '',
        singleSelect: true,
        selectType: 'item'
      }
    }
  ];

  constructor(private constantsService: ConstantsService, dateTimeService: DateTimeService, private groupService: GroupService, private messageService: MessageService) {
    super(dateTimeService);
  }

  loadData(append: boolean = false, lastTimestamp: number = null) {
    const groupsOnly: boolean = true;
    this.messageService
      .getAllMessages(this.searchModel, lastTimestamp, [MessageType.CHIT_CHAT, MessageType.REPLY])
      .pipe(
        take(2), // necessary to avoid cached results being shown if you have visited a group chit-chat page
        switchMap(
          messages => this.groupService.getGroupsById(messages.map(x => x.threadId.replace(/^group_/, ''))),
          (messages, groups) => {
            const groupNames: Record<string, string> = {};
            for (let group of groups) {
              groupNames[group.uid] = group.name;
            }
            for (let message of messages) {
              message.threadId = message.threadId.replace(/^group_/, ''); // so we can use the groupId in routerLink
              message.threadName = groupNames[message.threadId]; //using threadName for the groupName so we don't have to add extra fields to IMessage
            }
            return messages;
          }
        )
      )
      .subscribe(messages => {
        this.isLoading = false;
        if (messages.length > 0) {
          this.canLoadMore = true;
          const lastMessage = messages[messages.length - 1];
          if (lastMessage.dateTimeSent != null) this.lastTimestamp = lastMessage.dateTimeSent;
        }
        const viewMessages = [];
        messages.forEach(message => {
          viewMessages.push({
            date: this.dateTimeService.formatDate(message.dateTimeSent),
            content: message.content != null ? message.content : '(none)',
            name: message.name,
            photos: Object.values(message.photos || {})
              .filter(x => !!x.photoURL)
              .map(x => `<img src="${x.photoURL}"/>`),
            threadId: message.threadId,
            threadName: message.threadName
          });
        });

        if (append) {
          this.data = [...this.data, ...viewMessages];
        } else {
          this.data = viewMessages;
        }
      });
  }

  loadParameters() {
    const branding = this.constantsService.constants.GROUPS.CHIT_CHAT.branding;
    this.title = `${branding} log`;
  }

  updateSearchCriteria(options: IMessageOptions = null) {
    // TODO: Could almost do this with key: value from ICoinsOptions, but that doesn't allow hiding default criteria
    const criteria = {};

    if (options == null) this.searchCriteria = criteria;
    if ((options.sentAfter || '').trim().length > 0) criteria['sentAfter'] = `Sent after: ${options.sentAfter}`;
    if ((options.sentBefore || '').trim().length > 0) criteria['sentBefore'] = `Sent before: ${options.sentBefore}`;
    if ((options.sender || '').trim().length > 0) criteria['sender'] = `Sender: ${options.sender}`;
    this.searchCriteria = criteria;
  }
}
