import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { IThreadOpenStatus } from '@shared/models/messages/thread-open-status';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from 'rxjs';
import { ThreadOpenDatabase } from './thread-open.database';

@Injectable({
  providedIn: 'root'
})
export class ThreadOpenService {
  deviceKey: string = '';
  openThreads: Record<string, IThreadOpenStatus> = {}; //deviceKey, memberThreadId
  ref: Subscription;
  threadOpenSubscription: Subscription;
  user: UserObject;
  readonly TIME_LIMIT: number = 10 * 60 * 1000; //10 minutes in milliseconds

  constructor(private authService: AuthService, private dateTimeService: DateTimeService, private platform: Platform, private subscriptionService: SubscriptionService, private threadOpenDatabase: ThreadOpenDatabase) {
    this.initPlatform();
    this.initUser();
  }

  ngOnDestroy() {
    this.ref.unsubscribe();
    this.subscriptionService.clearSubscription(this.threadOpenSubscription);
  }

  initPlatform() {
    this.platform.ready().then(() => {
      const browserName = this.getBrowserName();
      const platformString = this.platform.platforms().join('-');
      this.deviceKey = `${platformString}-${browserName}`; //desktop-chrome
    });
  }

  initUser() {
    this.ref = this.authService._userProfileSubject.subscribe(user => {
      if (user == null) {
        return;
      }
      this.user = user;
      this.loadOpenThreads();
    });
  }

  loadOpenThreads() {
    this.subscriptionService.clearSubscription(this.threadOpenSubscription);
    this.threadOpenSubscription = this.threadOpenDatabase.loadOpenThreads(this.user.uid).subscribe(data => {
      if (data != null) Object.assign(this.openThreads, data);
    });
    this.subscriptionService.add(this.threadOpenSubscription);
  }

  private isOpen(threadStatus: IThreadOpenStatus, threadId: string) {
    return threadStatus != null && threadStatus.threadId === threadId && this.dateTimeService.getDateTime() - threadStatus.timestamp <= this.TIME_LIMIT;
  }

  isOpenForMeHere(threadId: string) {
    return this.isOpen(this.openThreads[this.deviceKey], threadId);
  }

  isOpenForMeAnywhere(threadId: string) {
    return Object.values(this.openThreads).some(x => this.isOpen(x, threadId));
  }

  async isOpenForMemberAnywhere(memberId: string, threadId: string) {
    //Save a database read
    if (this.user !== null && this.user.uid === memberId) return this.isOpenForMeAnywhere(threadId);

    const status = await this.threadOpenDatabase.getThreadStatusForMember(memberId);

    return status.data() == null ? false : Object.values(status.data()).some(x => this.isOpen(x, threadId));
  }

  setThreadOpen(threadId: string) {
    this.setDeviceThreadValue(threadId);
  }

  setThreadClosed() {
    this.setDeviceThreadValue('');
  }

  setDeviceThreadValue(value: string) {
    if (!this.isOpenForMeHere(value)) {
      //Edge case: if you've opened the thread within the last TIME_LIMIT, it won't update the timestamp
      if (this.user == null) return; //TODO: Could we get a race condition where user isn't loaded before we try to set thread status?
      const threadStatus = { [this.deviceKey]: { threadId: value, timestamp: Date.now() } };
      this.threadOpenDatabase.updateThreadStatus(this.user.uid, threadStatus);
    }
  }

  getUserAgent() {
    return window.navigator.userAgent.toLowerCase();
  }

  private getBrowserName() {
    const agent = this.getUserAgent();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
}
