import { Component, OnDestroy, OnInit } from '@angular/core';
import { IMemberThread, IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { IMemberThreadType } from '@shared/models/messages/member-thread-type';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { MessageService } from '@shared/services/messages/message.service';
import { ThreadOpenService } from '@shared/services/messages/thread-open.service';
import { UserService } from '@shared/services/user/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'more-archive',
  templateUrl: './more-archive.component.html',
  styleUrls: ['./more-archive.component.scss']
})
export class MoreArchiveComponent implements OnInit, OnDestroy {
  get CONSTANTS() {
    return this.constantsService.constants.MESSAGES.MORE.ARCHIVE;
  }

  hasMessages: boolean = false;
  readonly IMemberThreadType = IMemberThreadType;
  memberThreadRef: Subscription;
  onboardingMessage: string;
  threads: IMemberThread[];
  threadsLoaded: boolean = false;

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private constantsService: ConstantsService, private dateTimeService: DateTimeService, public messageService: MessageService, private threadOpenService: ThreadOpenService, private userService: UserService) {}

  getDateFormat(thread: IMemberThread): string {
    return this.dateTimeService.getDateFormat(thread.dateTimeLastUpdated);
  }

  getDetailRoute(thread: IMemberThread): any {
    return '/messages/more/archive/' + thread.threadId;
  }

  getRelatedType(thread: IMemberThread) {
    switch (thread.type) {
      case IMemberThreadType.Group:
        return IMemberThreadRelatedType.Event; // show text based avatars for groups

      case IMemberThreadType.DirectMessage:
      case IMemberThreadType.Conversation:
      default:
        return IMemberThreadRelatedType.Member;
    }
  }

  ionViewWillEnter() {
    this.analyticsService.eventTrack(AnalyticsCategory.MESSAGES, AnalyticsAction.MESSAGES_VIEW_ARCHIVE);
  }

  ngOnDestroy() {
    if (this.memberThreadRef) {
      this.memberThreadRef.unsubscribe();
    }
  }

  ngOnInit() {
    const isArchived = true;
    this.memberThreadRef = this.messageService.getArchivedMemberThreads().subscribe(threads => {
      threads = threads
        // when lastMessage is null it means the user has added a contact but not messaged this person yet. So these shouldn't show up in the threads list.
        .filter(t => t.lastMessage != null)
        .filter(t => t.isArchived === true);

      this.threads = threads;

      this.threadsLoaded = true;
      this.hasMessages = !!this.threads.length;
    });

    this.onboardingMessage = this.CONSTANTS.onboardingMessage;
  }
}
