import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoinsTransactionType } from '@shared/constants/coins-transaction-type';
import { ICoinsTransaction } from '@shared/models/coins/coins-transaction';
import { IColumn } from '@shared/models/column';
import { IDataTableConfig } from '@shared/models/data-table-config';
import { CoinsService } from '@shared/services/coins/coins.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
@Component({
  selector: 'chirpy-coins-transactions-table',
  templateUrl: './chirpy-coins-transactions-table.component.html'
})
export class ChirpyCoinsTransactionsTableComponent {
  columns: IColumn[] = [
    { label: 'Date', field: 'dateString', class: 'data-table__column-responsive-bold' },
    { label: 'Description', field: 'description' },
    { label: 'Amount', field: 'amount' },
    { label: 'Balance', field: 'balance', class: 'data-table__column-responsive-bold data-table__column-responsive-label' }
  ];
  @Input() config: IDataTableConfig = {};
  @Input() isAdmin: boolean = false;
  @Input() memberId: string;
  @Input() records: number;
  @Output() output = new EventEmitter();
  transactions$: Observable<ICoinsTransaction[]>;

  constructor(private coinsService: CoinsService, private dateTimeService: DateTimeService) {}

  ngOnInit() {
    if (this.isAdmin) this.columns.push({ label: 'Authorised by', field: 'staffName' });

    this.transactions$ = this.coinsService.getBalanceForMember(this.memberId).pipe(
      switchMap((balance: number) => this.coinsService.getTransactionsForMember(this.memberId, balance, this.records, true)),
      map((transactions: ICoinsTransaction[]) =>
        transactions.map(x => {
          x = this.addClass(x);
          x.dateString = this.dateTimeService.formatDate(x.date, 'D MMM YYYY');
          return x;
        })
      )
    );
  }

  onClick(data: any) {
    this.output.emit(data);
  }

  private addClass(transaction: ICoinsTransaction): any {
    switch (transaction.type) {
      case CoinsTransactionType.REDEEM:
        transaction.class = 'data-table__row-primary-bold';
        break;

      case CoinsTransactionType.EXPIRED:
      case CoinsTransactionType.REVERSED:
      case CoinsTransactionType.REVERSAL:
        transaction.class = 'data-table__row-grey-italic';
        break;

      case CoinsTransactionType.ADD:
      default:
        transaction.class = '';
    }
    return transaction;
  }
}
