export interface IMessageOptions {
  sender: string;
  sentAfter: string;
  sentBefore: string;
}

export const DefaultMessageOptions: IMessageOptions = {
  sender: '',
  sentAfter: '',
  sentBefore: ''
};
