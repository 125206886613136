import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventType } from '@infrastructure/constants/event-type';
import { AlertController } from '@ionic/angular';
import { ICatchupTemplate } from '@shared/models/catchups/catchup-template';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'catchup-template-list',
  templateUrl: './catchup-template-list.page.html'
})
export class CatchupTemplateListPage implements OnInit {
  BRANDING: string;
  readonly EventType = EventType;
  groupId: string;
  groupName: string;
  templates$: Observable<ICatchupTemplate[]>;
  title: string;

  constructor(private alertController: AlertController, private authService: AuthService, private catchupService: CatchupService, private constantsService: ConstantsService, private route: ActivatedRoute) {}

  isShared(template: ICatchupTemplate) {
    return Object.keys(template.sharedGroups || {}).length > 0;
  }

  ngOnInit() {
    this.BRANDING = this.constantsService.constants.CATCHUPS.branding;
    this.groupId = this.route.snapshot.paramMap.get('groupId');
    this.groupName = this.route.snapshot.paramMap.get('groupName');
    this.templates$ = this.catchupService.getCatchupTemplates(this.groupId);
    this.title = `Manage templates for ${this.groupName}`;
  }

  async onDelete(template: ICatchupTemplate) {
    const alert = await this.alertController.create({
      header: `Delete?`,
      message: `Are you sure you want to delete this template?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: `Delete`,
          handler: data => this.onDeleteHandler(template)
        }
      ]
    });

    await alert.present();
  }

  onDeleteHandler(template: ICatchupTemplate) {
    return this.catchupService.deleteCatchupTemplate(this.groupId, template.uid);
  }
}
