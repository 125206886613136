import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { SignupGenderDatabase } from './gender.database';

@Injectable({
  providedIn: 'root'
})
export class SignupGenderService {
  constructor(private authService: AuthService, private database: SignupGenderDatabase) {}

  setGender(gender: string) {
    return this.database.setGender(gender, this.authService._userProfileSubject.value.uid);
  }
}
