import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  async presentToast(messageText: string) {
    // dismiss the previous toast before showing the next to avoid overlapping toasts.
    try {
      await this.toastController.dismiss();
    } catch {
      // catch "Value: overlay does not exist" exception when there is no toast to dismiss.
    }

    const toast = await this.toastController.create({
      message: messageText,
      color: 'toast',
      duration: 6000,
      showCloseButton: true
    });

    toast.present();
  }
}
