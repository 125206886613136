import { Country } from '@shared/constants/country';
import { ISelectOption } from '@shared/models/select-option';

// Name is shown in dropdowns,
// Value is stored in the database and shown on CatchUps/Groups,
// Area is an abbreviation shown on the icon for CatchUps
const NEWZEALAND_REGIONS: ISelectOption[] = [
  { name: `Northland`, value: `Northland`, area: 'NI', utcHours: 12, utcMinutes: 0 },
  { name: `Auckland`, value: `Auckland`, area: 'NI', utcHours: 12, utcMinutes: 0 },
  { name: `Waikato`, value: `Waikato`, area: 'NI', utcHours: 12, utcMinutes: 0 },
  { name: `Bay of Plenty`, value: `Bay of Plenty`, area: 'NI', utcHours: 12, utcMinutes: 0 },
  { name: `Gisborne`, value: 'Gisborne', area: 'NI', utcHours: 12, utcMinutes: 0 },
  { name: `Hawke's Bay`, value: `Hawke's Bay`, area: 'NI', utcHours: 12, utcMinutes: 0 },
  { name: `Taranaki`, value: `Taranaki`, area: 'NI', utcHours: 12, utcMinutes: 0 },
  { name: `Manawatu-Whanganui`, value: `Manawatu-Whanganui`, area: 'NI', utcHours: 12, utcMinutes: 0 },
  { name: `Wellington`, value: `Wellington`, area: 'NI', utcHours: 12, utcMinutes: 0 },
  { name: `Tasman`, value: `Tasman`, area: 'SI', utcHours: 12, utcMinutes: 0 },
  { name: `Nelson`, value: `Nelson`, area: 'SI', utcHours: 12, utcMinutes: 0 },
  { name: `Marlborough`, value: `Marlborough`, area: 'SI', utcHours: 12, utcMinutes: 0 },
  { name: `West Coast`, value: `West Coast`, area: 'SI', utcHours: 12, utcMinutes: 0 },
  { name: `Canterbury`, value: `Canterbury`, area: 'SI', utcHours: 12, utcMinutes: 0 },
  { name: `Otago`, value: `Otago`, area: 'SI', utcHours: 12, utcMinutes: 0 },
  { name: `Southland`, value: `Southland`, area: 'SI', utcHours: 12, utcMinutes: 0 }
];

// TODO: Do we use the full names for consistency with other countries?
const AUSTRALIAN_STATES: ISelectOption[] = [
  { name: 'ACT', value: 'ACT', area: 'ACT', utcHours: 10, utcMinutes: 0 },
  { name: 'NSW', value: 'NSW', area: 'NSW', utcHours: 10, utcMinutes: 0 },
  { name: 'NT', value: 'NT', area: 'NT', utcHours: 9, utcMinutes: 30 },
  { name: 'QLD', value: 'QLD', area: 'QLD', utcHours: 10, utcMinutes: 0 },
  { name: 'SA', value: 'SA', area: 'SA', utcHours: 9, utcMinutes: 30 },
  { name: 'TAS', value: 'TAS', area: 'TAS', utcHours: 10, utcMinutes: 0 },
  { name: 'VIC', value: 'VIC', area: 'VIC', utcHours: 10, utcMinutes: 0 },
  { name: 'WA', value: 'WA', area: 'WA', utcHours: 8, utcMinutes: 0 }
];

const CANADIAN_PROVINCES: ISelectOption[] = [
  { name: 'Alberta', value: 'Alberta', area: 'AB', utcHours: -7, utcMinutes: 0 },
  { name: 'British Columbia', value: 'British Columbia', area: 'BC', utcHours: -8, utcMinutes: 0 },
  { name: 'Manitoba', value: 'Manitoba', area: 'MB', utcHours: -6, utcMinutes: 0 },
  { name: 'New Brunswick', value: 'New Brunswick', area: 'NB', utcHours: -4, utcMinutes: 0 },
  { name: 'Newfoundland and Labrador', value: 'Newfoundland and Labrador', area: 'NL', utcHours: -3, utcMinutes: -30 },
  { name: 'Nova Scotia', value: 'Nova Scotia', area: 'NS', utcHours: -4, utcMinutes: 0 },
  { name: 'Northwest Territories', value: 'Northwest Territories', area: 'NT', utcHours: -7, utcMinutes: 0 },
  { name: 'Nunavut', value: 'Nunavut', area: 'NU', utcHours: -6, utcMinutes: 0 },
  { name: 'Ontario', value: 'Ontario', area: 'ON', utcHours: -5, utcMinutes: 0 },
  { name: 'Prince Edward Island', value: 'Prince Edward Island', area: 'PE', utcHours: -4, utcMinutes: 0 },
  { name: 'Quebec', value: 'Quebec', area: 'QC', utcHours: -5, utcMinutes: 0 },
  { name: 'Saskatchewan', value: 'Saskatchewan', area: 'SK', utcHours: -6, utcMinutes: 0 },
  { name: 'Yukon', value: 'Yukon', area: 'YT', utcHours: -7, utcMinutes: 0 }
];

const GERMAN_LAENDER: ISelectOption[] = [
  { name: 'Baden-Württemberg', value: 'Baden-Württemberg', area: 'BW', utcHours: 1, utcMinutes: 0 },
  { name: 'Bayern', value: 'Bayern', area: 'BY', utcHours: 1, utcMinutes: 0 },
  { name: 'Berlin', value: 'Berlin', area: 'BE', utcHours: 1, utcMinutes: 0 },
  { name: 'Brandenburg', value: 'Brandenburg', area: 'BB', utcHours: 1, utcMinutes: 0 },
  { name: 'Bremen', value: 'Bremen', area: 'HB', utcHours: 1, utcMinutes: 0 },
  { name: 'Hamburg', value: 'Hamburg', area: 'HH', utcHours: 1, utcMinutes: 0 },
  { name: 'Hessen', value: 'Hessen', area: 'HE', utcHours: 1, utcMinutes: 0 },
  { name: 'Mecklenburg-Vorpommern', value: 'Mecklenburg-Vorpommern', area: 'MV', utcHours: 1, utcMinutes: 0 },
  { name: 'Niedersachsen', value: 'Niedersachsen', area: 'NI', utcHours: 1, utcMinutes: 0 },
  { name: 'Nordrhein-Westfalen', value: 'Nordrhein-Westfalen', area: 'NW', utcHours: 1, utcMinutes: 0 },
  { name: 'Rheinland-Pfalz', value: 'Rheinland-Pfalz', area: 'RP', utcHours: 1, utcMinutes: 0 },
  { name: 'Saarland', value: 'Saarland', area: 'SL', utcHours: 1, utcMinutes: 0 },
  { name: 'Sachsen', value: 'Sachsen', area: 'SN', utcHours: 1, utcMinutes: 0 },
  { name: 'Sachsen-Anhalt', value: 'Sachsen-Anhalt', area: 'ST', utcHours: 1, utcMinutes: 0 },
  { name: 'Schleswig-Holstein', value: 'Schleswig-Holstein', area: 'SH', utcHours: 1, utcMinutes: 0 },
  { name: 'Thüringen', value: 'Thüringen', area: 'TH', utcHours: 1, utcMinutes: 0 }
];

const IRISH_COUNTIES: ISelectOption[] = [
  { name: 'Carlow', value: 'Carlow', area: 'CW', utcHours: 0, utcMinutes: 0 },
  { name: 'Cavan', value: 'Cavan', area: 'CN', utcHours: 0, utcMinutes: 0 },
  { name: 'Clare', value: 'Clare', area: 'CE', utcHours: 0, utcMinutes: 0 },
  { name: 'Cork', value: 'Cork', area: 'CO', utcHours: 0, utcMinutes: 0 },
  { name: 'Donegal', value: 'Donegal', area: 'DL', utcHours: 0, utcMinutes: 0 },
  { name: 'Dublin', value: 'Dublin', area: 'D', utcHours: 0, utcMinutes: 0 },
  { name: 'Galway', value: 'Galway', area: 'G', utcHours: 0, utcMinutes: 0 },
  { name: 'Kerry', value: 'Kerry', area: 'KY', utcHours: 0, utcMinutes: 0 },
  { name: 'Kildare', value: 'Kildare', area: 'KE', utcHours: 0, utcMinutes: 0 },
  { name: 'Kilkenny', value: 'Kilkenny', area: 'KK', utcHours: 0, utcMinutes: 0 },
  { name: 'Laois', value: 'Laois', area: 'LS', utcHours: 0, utcMinutes: 0 },
  { name: 'Leitrim', value: 'Leitrim', area: 'LM', utcHours: 0, utcMinutes: 0 },
  { name: 'Limerick', value: 'Limerick', area: 'LK', utcHours: 0, utcMinutes: 0 },
  { name: 'Longford', value: 'Longford', area: 'LD', utcHours: 0, utcMinutes: 0 },
  { name: 'Louth', value: 'Louth', area: 'LH', utcHours: 0, utcMinutes: 0 },
  { name: 'Mayo', value: 'Mayo', area: 'MO', utcHours: 0, utcMinutes: 0 },
  { name: 'Meath', value: 'Meath', area: 'MH', utcHours: 0, utcMinutes: 0 },
  { name: 'Monaghan', value: 'Monaghan', area: 'MN', utcHours: 0, utcMinutes: 0 },
  { name: 'Offaly', value: 'Offaly', area: 'OY', utcHours: 0, utcMinutes: 0 },
  { name: 'Roscommon', value: 'Roscommon', area: 'RN', utcHours: 0, utcMinutes: 0 },
  { name: 'Sligo', value: 'Sligo', area: 'SO', utcHours: 0, utcMinutes: 0 },
  { name: 'Tipperary', value: 'Tipperary', area: 'TA', utcHours: 0, utcMinutes: 0 },
  { name: 'Waterford', value: 'Waterford', area: 'WD', utcHours: 0, utcMinutes: 0 },
  { name: 'Westmeath', value: 'Westmeath', area: 'WH', utcHours: 0, utcMinutes: 0 },
  { name: 'Wexford', value: 'Wexford', area: 'WX', utcHours: 0, utcMinutes: 0 },
  { name: 'Wicklow', value: 'Wicklow', area: 'WW', utcHours: 0, utcMinutes: 0 }
];

const SINGAPOREAN_DISTRICTS: ISelectOption[] = [
  { name: 'Central District', value: 'Central District', area: 'C', utcHours: 8, utcMinutes: 0 },
  { name: 'North East District', value: 'North East District', area: 'NE', utcHours: 8, utcMinutes: 0 },
  { name: 'North West District', value: 'North West District', area: 'NW', utcHours: 8, utcMinutes: 0 },
  { name: 'South East District', value: 'South East District', area: 'SE', utcHours: 8, utcMinutes: 0 },
  { name: 'South West District', value: 'South West District', area: 'SW', utcHours: 8, utcMinutes: 0 }
];

const US_STATES: ISelectOption[] = [
  { name: 'Alabama', value: 'Alabama', area: 'AL', utcHours: -6, utcMinutes: 0 },
  { name: 'Alaska', value: 'Alaska', area: 'AK', utcHours: -9, utcMinutes: 0 },
  { name: 'Arizona', value: 'Arizona', area: 'AZ', utcHours: -7, utcMinutes: 0 },
  { name: 'Arkansas', value: 'Arkansas', area: 'AR', utcHours: -6, utcMinutes: 0 },
  { name: 'California', value: 'California', area: 'CA', utcHours: -8, utcMinutes: 0 },
  { name: 'Colorado', value: 'Colorado', area: 'CO', utcHours: -7, utcMinutes: 0 },
  { name: 'Connecticut', value: 'Connecticut', area: 'CT', utcHours: -5, utcMinutes: 0 },
  { name: 'Delaware', value: 'Delaware', area: 'DE', utcHours: -5, utcMinutes: 0 },
  { name: 'District of Columbia', value: 'District of Columbia', area: 'DC', utcHours: -5, utcMinutes: 0 },
  { name: 'Florida', value: 'Florida', area: 'FL', utcHours: -5, utcMinutes: 0 },
  { name: 'Georgia', value: 'Georgia', area: 'GA', utcHours: -5, utcMinutes: 0 },
  { name: 'Hawaii', value: 'Hawaii', area: 'HI', utcHours: -10, utcMinutes: 0 },
  { name: 'Idaho', value: 'Idaho', area: 'ID', utcHours: -7, utcMinutes: 0 },
  { name: 'Illinois', value: 'Illinois', area: 'IL', utcHours: -6, utcMinutes: 0 },
  { name: 'Indiana', value: 'Indiana', area: 'IN', utcHours: -6, utcMinutes: 0 },
  { name: 'Iowa', value: 'Iowa', area: 'IA', utcHours: -6, utcMinutes: 0 },
  { name: 'Kansas', value: 'Kansas', area: 'KS', utcHours: -6, utcMinutes: 0 },
  { name: 'Kentucky', value: 'Kentucky', area: 'KY', utcHours: -5, utcMinutes: 0 },
  { name: 'Louisiana', value: 'Louisiana', area: 'LA', utcHours: -6, utcMinutes: 0 },
  { name: 'Maine', value: 'Maine', area: 'ME', utcHours: -5, utcMinutes: 0 },
  { name: 'Maryland', value: 'Maryland', area: 'MD', utcHours: -5, utcMinutes: 0 },
  { name: 'Massachusetts', value: 'Massachusetts', area: 'MA', utcHours: -5, utcMinutes: 0 },
  { name: 'Michigan', value: 'Michigan', area: 'MI', utcHours: -5, utcMinutes: 0 },
  { name: 'Minnesota', value: 'Minnesota', area: 'MN', utcHours: -6, utcMinutes: 0 },
  { name: 'Mississippi', value: 'Mississippi', area: 'MS', utcHours: -6, utcMinutes: 0 },
  { name: 'Missouri', value: 'Missouri', area: 'MO', utcHours: -6, utcMinutes: 0 },
  { name: 'Montana', value: 'Montana', area: 'MT', utcHours: -7, utcMinutes: 0 },
  { name: 'Nebraska', value: 'Nebraska', area: 'NE', utcHours: -6, utcMinutes: 0 },
  { name: 'Nevada', value: 'Nevada', area: 'NV', utcHours: -8, utcMinutes: 0 },
  { name: 'New Hampshire', value: 'New Hampshire', area: 'NH', utcHours: -5, utcMinutes: 0 },
  { name: 'New Jersey', value: 'New Jersey', area: 'NJ', utcHours: -5, utcMinutes: 0 },
  { name: 'New Mexico', value: 'New Mexico', area: 'NM', utcHours: -7, utcMinutes: 0 },
  { name: 'New York', value: 'New York', area: 'NY', utcHours: -5, utcMinutes: 0 },
  { name: 'North Carolina', value: 'North Carolina', area: 'NC', utcHours: -5, utcMinutes: 0 },
  { name: 'North Dakota', value: 'North Dakota', area: 'ND', utcHours: -6, utcMinutes: 0 },
  { name: 'Ohio', value: 'Ohio', area: 'OH', utcHours: -5, utcMinutes: 0 },
  { name: 'Oklahoma', value: 'Oklahoma', area: 'OK', utcHours: -6, utcMinutes: 0 },
  { name: 'Oregon', value: 'Oregon', area: 'OR', utcHours: -8, utcMinutes: 0 },
  { name: 'Puerto Rico', value: 'Puerto Rico', area: 'PR', utcHours: -4, utcMinutes: 0 },
  { name: 'Pennsylvania', value: 'Pennsylvania', area: 'PA', utcHours: -5, utcMinutes: 0 },
  { name: 'Rhode Island', value: 'Rhode Island', area: 'RI', utcHours: -5, utcMinutes: 0 },
  { name: 'South Carolina', value: 'South Carolina', area: 'SC', utcHours: -5, utcMinutes: 0 },
  { name: 'South Dakota', value: 'South Dakota', area: 'SD', utcHours: -6, utcMinutes: 0 },
  { name: 'Tennessee', value: 'Tennessee', area: 'TN', utcHours: -6, utcMinutes: 0 },
  { name: 'Texas', value: 'Texas', area: 'TX', utcHours: -6, utcMinutes: 0 },
  { name: 'Utah', value: 'Utah', area: 'UT', utcHours: -7, utcMinutes: 0 },
  { name: 'Vermont', value: 'Vermont', area: 'VT', utcHours: -5, utcMinutes: 0 },
  { name: 'Virginia', value: 'Virginia', area: 'VA', utcHours: -5, utcMinutes: 0 },
  { name: 'Washington', value: 'Washington', area: 'WA', utcHours: -8, utcMinutes: 0 },
  { name: 'West Virginia', value: 'West Virginia', area: 'WV', utcHours: -5, utcMinutes: 0 },
  { name: 'Wisconsin', value: 'Wisconsin', area: 'WI', utcHours: -6, utcMinutes: 0 },
  { name: 'Wyoming', value: 'Wyoming', area: 'WY', utcHours: -7, utcMinutes: 0 }
];

// TODO: Should we use 48 counties (ENG) 32 Council areas (SCO) 22 principal areas (CYM) and 11 districts (NI)?
const UK_REGIONS: ISelectOption[] = [
  { name: 'North East', value: 'North East', area: 'NE', utcHours: 0, utcMinutes: 0 },
  { name: 'North West', value: 'North West', area: 'NW', utcHours: 0, utcMinutes: 0 },
  { name: 'Yorkshire and the Humber', value: 'Yorkshire and the Humber', area: 'YH', utcHours: 0, utcMinutes: 0 },
  { name: 'East Midlands', value: 'East Midlands', area: 'EM', utcHours: 0, utcMinutes: 0 },
  { name: 'West Midlands', value: 'West Midlands', area: 'WM', utcHours: 0, utcMinutes: 0 },
  { name: 'East of England', value: 'East of England', area: 'EE', utcHours: 0, utcMinutes: 0 },
  { name: 'London', value: 'London', area: 'LON', utcHours: 0, utcMinutes: 0 },
  { name: 'South East', value: 'South East', area: 'SE', utcHours: 0, utcMinutes: 0 },
  { name: 'South West', value: 'South West', area: 'SW', utcHours: 0, utcMinutes: 0 },
  { name: 'Wales', value: 'Wales', area: 'CYM', utcHours: 0, utcMinutes: 0 },
  { name: 'Scotland', value: 'Scotland', area: 'SCO', utcHours: 0, utcMinutes: 0 },
  { name: 'Northern Ireland', value: 'Northern Ireland', area: 'NI', utcHours: 0, utcMinutes: 0 }
];

// TODO: Prevent duplicates between above countries (when supported) and ROW
const ROW_COUNTRIES = [
  { name: 'Afghanistan', value: 'Afghanistan', area: 'AF', utcHours: 4, utcMinutes: 30 },
  { name: 'Albania', value: 'Albania', area: 'AL', utcHours: 1, utcMinutes: 0 },
  { name: 'Algeria', value: 'Algeria', area: 'DZ', utcHours: 1, utcMinutes: 0 },
  { name: 'American Samoa', value: 'American Samoa', area: 'AS', utcHours: -11, utcMinutes: 0 },
  { name: 'Andorra', value: 'Andorra', area: 'AD', utcHours: 1, utcMinutes: 0 },
  { name: 'Angola', value: 'Angola', area: 'AO', utcHours: 1, utcMinutes: 0 },
  { name: 'Anguilla', value: 'Anguilla', area: 'AI', utcHours: -4, utcMinutes: 0 },
  { name: 'Antigua and Barbuda', value: 'Antigua and Barbuda', area: 'AG', utcHours: -4, utcMinutes: 0 },
  { name: 'Argentina', value: 'Argentina', area: 'AR', utcHours: -3, utcMinutes: 0 },
  { name: 'Armenia', value: 'Armenia', area: 'AM', utcHours: 4, utcMinutes: 0 },
  { name: 'Aruba', value: 'Aruba', area: 'AW', utcHours: -4, utcMinutes: 0 },
  { name: 'Austria', value: 'Austria', area: 'AT', utcHours: 1, utcMinutes: 0 },
  { name: 'Azerbaijan', value: 'Azerbaijan', area: 'AZ', utcHours: 4, utcMinutes: 0 },
  { name: 'Bahamas', value: 'Bahamas', area: 'BS', utcHours: -5, utcMinutes: 0 },
  { name: 'Bahrain', value: 'Bahrain', area: 'BH', utcHours: 3, utcMinutes: 0 },
  { name: 'Bangladesh', value: 'Bangladesh', area: 'BD', utcHours: 6, utcMinutes: 0 },
  { name: 'Barbados', value: 'Barbados', area: 'BB', utcHours: -4, utcMinutes: 0 },
  { name: 'Belarus', value: 'Belarus', area: 'BY', utcHours: 3, utcMinutes: 0 },
  { name: 'Belgium', value: 'Belgium', area: 'BE', utcHours: 1, utcMinutes: 0 },
  { name: 'Belize', value: 'Belize', area: 'BZ', utcHours: -6, utcMinutes: 0 },
  { name: 'Benin', value: 'Benin', area: 'BJ', utcHours: 1, utcMinutes: 0 },
  { name: 'Bermuda', value: 'Bermuda', area: 'BM', utcHours: -4, utcMinutes: 0 },
  { name: 'Bhutan', value: 'Bhutan', area: 'BT', utcHours: 6, utcMinutes: 0 },
  { name: 'Bolivia', value: 'Bolivia', area: 'BO', utcHours: -4, utcMinutes: 0 },
  { name: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina', area: 'BA', utcHours: 1, utcMinutes: 0 },
  { name: 'Botswana', value: 'Botswana', area: 'BW', utcHours: 2, utcMinutes: 0 },
  { name: 'Brazil', value: 'Brazil', area: 'BR', utcHours: -3, utcMinutes: 0 },
  { name: 'British Virgin Islands', value: 'British Virgin Islands', area: 'VG', utcHours: -4, utcMinutes: 0 },
  { name: 'Brunei', value: 'Brunei', area: 'BN', utcHours: 8, utcMinutes: 0 },
  { name: 'Bulgaria', value: 'Bulgaria', area: 'BG', utcHours: 2, utcMinutes: 0 },
  { name: 'Burkina Faso', value: 'Burkina Faso', area: 'BF', utcHours: 0, utcMinutes: 0 },
  { name: 'Burundi', value: 'Burundi', area: 'BI', utcHours: 2, utcMinutes: 0 },
  { name: 'Cambodia', value: 'Cambodia', area: 'KH', utcHours: 7, utcMinutes: 0 },
  { name: 'Cameroon', value: 'Cameroon', area: 'CM', utcHours: 1, utcMinutes: 0 },
  { name: 'Cape Verde', value: 'Cape Verde', area: 'CV', utcHours: -1, utcMinutes: 0 },
  { name: 'Cayman Islands', value: 'Cayman Islands', area: 'KY', utcHours: -5, utcMinutes: 0 },
  { name: 'Central African Republic', value: 'Central African Republic', area: 'CF', utcHours: 1, utcMinutes: 0 },
  { name: 'Chad', value: 'Chad', area: 'TD', utcHours: 1, utcMinutes: 0 },
  { name: 'Chile', value: 'Chile', area: 'CL', utcHours: -4, utcMinutes: 0 },
  { name: 'China', value: 'China', area: 'CN', utcHours: 8, utcMinutes: 0 },
  { name: 'Colombia', value: 'Colombia', area: 'CO', utcHours: -5, utcMinutes: 0 },
  { name: 'Comoros', value: 'Comoros', area: 'KM', utcHours: 3, utcMinutes: 0 },
  { name: 'Congo [DRC]', value: 'Congo [DRC]', area: 'CD', utcHours: 1, utcMinutes: 0 },
  { name: 'Congo [Republic]', value: 'Congo [Republic]', area: 'CG', utcHours: 1, utcMinutes: 0 },
  { name: 'Cook Islands', value: 'Cook Islands', area: 'CK', utcHours: -10, utcMinutes: 0 },
  { name: 'Costa Rica', value: 'Costa Rica', area: 'CR', utcHours: -6, utcMinutes: 0 },
  { name: "Côte d'Ivoir", value: "Côte d'Ivoir", area: 'CI', utcHours: 0, utcMinutes: 0 },
  { name: 'Croatia', value: 'Croatia', area: 'HR', utcHours: 1, utcMinutes: 0 },
  { name: 'Cuba', value: 'Cuba', area: 'CU', utcHours: -5, utcMinutes: 0 },
  { name: 'Cyprus', value: 'Cyprus', area: 'CY', utcHours: 2, utcMinutes: 0 },
  { name: 'Czech Republic', value: 'Czech Republic', area: 'CZ', utcHours: 1, utcMinutes: 0 },
  { name: 'Denmark', value: 'Denmark', area: 'DK', utcHours: 1, utcMinutes: 0 },
  { name: 'Djibouti', value: 'Djibouti', area: 'DJ', utcHours: 3, utcMinutes: 0 },
  { name: 'Dominica', value: 'Dominica', area: 'DM', utcHours: -4, utcMinutes: 0 },
  { name: 'Dominican Republic', value: 'Dominican Republic', area: 'DO', utcHours: -4, utcMinutes: 0 },
  { name: 'Ecuador', value: 'Ecuador', area: 'EC', utcHours: -5, utcMinutes: 0 },
  { name: 'Egypt', value: 'Egypt', area: 'EG', utcHours: 2, utcMinutes: 0 },
  { name: 'El Salvador', value: 'El Salvador', area: 'SV', utcHours: -6, utcMinutes: 0 },
  { name: 'Equatorial Guinea', value: 'Equatorial Guinea', area: 'GQ', utcHours: 1, utcMinutes: 0 },
  { name: 'Eritrea', value: 'Eritrea', area: 'ER', utcHours: 3, utcMinutes: 0 },
  { name: 'Estonia', value: 'Estonia', area: 'EE', utcHours: 2, utcMinutes: 0 },
  { name: 'Ethiopia', value: 'Ethiopia', area: 'ET', utcHours: 3, utcMinutes: 0 },
  { name: 'Falkland Islands [Islas Malvinas]', value: 'Falkland Islands [Islas Malvinas]', area: 'FK', utcHours: -3, utcMinutes: 0 },
  { name: 'Faroe Islands', value: 'Faroe Islands', area: 'FO', utcHours: 0, utcMinutes: 0 },
  { name: 'Fiji', value: 'Fiji', area: 'FJ', utcHours: 12, utcMinutes: 0 },
  { name: 'Finland', value: 'Finland', area: 'FI', utcHours: 2, utcMinutes: 0 },
  { name: 'France', value: 'France', area: 'FR', utcHours: 1, utcMinutes: 0 },
  { name: 'French Guiana', value: 'French Guiana', area: 'GF', utcHours: -3, utcMinutes: 0 },
  { name: 'French Polynesia', value: 'French Polynesia', area: 'PF', utcHours: -10, utcMinutes: 0 },
  { name: 'French Southern Territories', value: 'French Southern Territories', area: 'TF', utcHours: 5, utcMinutes: 0 },
  { name: 'Gabon', value: 'Gabon', area: 'GA', utcHours: 1, utcMinutes: 0 },
  { name: 'Gambia', value: 'Gambia', area: 'GM', utcHours: 0, utcMinutes: 0 },
  { name: 'Gaza Strip', value: 'Gaza Strip', area: 'GZ', utcHours: 2, utcMinutes: 0 },
  { name: 'Georgia', value: 'Georgia', area: 'GE', utcHours: 4, utcMinutes: 0 },
  { name: 'Ghana', value: 'Ghana', area: 'GH', utcHours: 0, utcMinutes: 0 },
  { name: 'Gibraltar', value: 'Gibraltar', area: 'GI', utcHours: 1, utcMinutes: 0 },
  { name: 'Greece', value: 'Greece', area: 'GR', utcHours: 2, utcMinutes: 0 },
  { name: 'Greenland', value: 'Greenland', area: 'GL', utcHours: -3, utcMinutes: 0 },
  { name: 'Grenada', value: 'Grenada', area: 'GD', utcHours: -4, utcMinutes: 0 },
  { name: 'Guadeloupe', value: 'Guadeloupe', area: 'GP', utcHours: -4, utcMinutes: 0 },
  { name: 'Guam', value: 'Guam', area: 'GU', utcHours: 10, utcMinutes: 0 },
  { name: 'Guatemala', value: 'Guatemala', area: 'GT', utcHours: -6, utcMinutes: 0 },
  { name: 'Guernsey', value: 'Guernsey', area: 'GG', utcHours: 0, utcMinutes: 0 },
  { name: 'Guinea', value: 'Guinea', area: 'GN', utcHours: 0, utcMinutes: 0 },
  { name: 'Guinea-Bissau', value: 'Guinea-Bissau', area: 'GW', utcHours: 0, utcMinutes: 0 },
  { name: 'Guyana', value: 'Guyana', area: 'GY', utcHours: -4, utcMinutes: 0 },
  { name: 'Haiti', value: 'Haiti', area: 'HT', utcHours: -5, utcMinutes: 0 },
  { name: 'Honduras', value: 'Honduras', area: 'HN', utcHours: -6, utcMinutes: 0 },
  { name: 'Hong Kong', value: 'Hong Kong', area: 'HK', utcHours: 8, utcMinutes: 0 },
  { name: 'Hungary', value: 'Hungary', area: 'HU', utcHours: 1, utcMinutes: 0 },
  { name: 'Iceland', value: 'Iceland', area: 'IS', utcHours: 0, utcMinutes: 0 },
  { name: 'India', value: 'India', area: 'IN', utcHours: 5, utcMinutes: 30 },
  { name: 'Indonesia', value: 'Indonesia', area: 'ID', utcHours: 8, utcMinutes: 0 },
  { name: 'Iran', value: 'Iran', area: 'IR', utcHours: 3, utcMinutes: 30 },
  { name: 'Iraq', value: 'Iraq', area: 'IQ', utcHours: 3, utcMinutes: 0 },
  { name: 'Ireland', value: 'Ireland', area: 'IE', utcHours: 1, utcMinutes: 0 },
  { name: 'Israel', value: 'Israel', area: 'IL', utcHours: 2, utcMinutes: 0 },
  { name: 'Italy', value: 'Italy', area: 'IT', utcHours: 1, utcMinutes: 0 },
  { name: 'Jamaica', value: 'Jamaica', area: 'JM', utcHours: -5, utcMinutes: 0 },
  { name: 'Japan', value: 'Japan', area: 'JP', utcHours: 9, utcMinutes: 0 },
  { name: 'Jersey', value: 'Jersey', area: 'JE', utcHours: 0, utcMinutes: 0 },
  { name: 'Jordan', value: 'Jordan', area: 'JO', utcHours: 2, utcMinutes: 0 },
  { name: 'Kazakhstan', value: 'Kazakhstan', area: 'KZ', utcHours: 6, utcMinutes: 0 },
  { name: 'Kenya', value: 'Kenya', area: 'KE', utcHours: 3, utcMinutes: 0 },
  { name: 'Kiribati', value: 'Kiribati', area: 'KI', utcHours: 13, utcMinutes: 0 },
  { name: 'Kosovo', value: 'Kosovo', area: 'XK', utcHours: 1, utcMinutes: 0 },
  { name: 'Kuwait', value: 'Kuwait', area: 'KW', utcHours: 3, utcMinutes: 0 },
  { name: 'Kyrgyzstan', value: 'Kyrgyzstan', area: 'KG', utcHours: 6, utcMinutes: 0 },
  { name: 'Laos', value: 'Laos', area: 'LA', utcHours: 7, utcMinutes: 0 },
  { name: 'Latvia', value: 'Latvia', area: 'LV', utcHours: 2, utcMinutes: 0 },
  { name: 'Lebanon', value: 'Lebanon', area: 'LB', utcHours: 2, utcMinutes: 0 },
  { name: 'Lesotho', value: 'Lesotho', area: 'LS', utcHours: 2, utcMinutes: 0 },
  { name: 'Liberia', value: 'Liberia', area: 'LR', utcHours: 0, utcMinutes: 0 },
  { name: 'Libya', value: 'Libya', area: 'LY', utcHours: 2, utcMinutes: 0 },
  { name: 'Liechtenstein', value: 'Liechtenstein', area: 'LI', utcHours: 1, utcMinutes: 0 },
  { name: 'Lithuania', value: 'Lithuania', area: 'LT', utcHours: 2, utcMinutes: 0 },
  { name: 'Luxembourg', value: 'Luxembourg', area: 'LU', utcHours: 1, utcMinutes: 0 },
  { name: 'Macau', value: 'Macau', area: 'MO', utcHours: 8, utcMinutes: 0 },
  { name: 'Macedonia', value: 'Macedonia', area: 'MK', utcHours: 1, utcMinutes: 0 },
  { name: 'Madagascar', value: 'Madagascar', area: 'MG', utcHours: 3, utcMinutes: 0 },
  { name: 'Malawi', value: 'Malawi', area: 'MW', utcHours: 2, utcMinutes: 0 },
  { name: 'Malaysia', value: 'Malaysia', area: 'MY', utcHours: 8, utcMinutes: 0 },
  { name: 'Maldives', value: 'Maldives', area: 'MV', utcHours: 5, utcMinutes: 0 },
  { name: 'Mali', value: 'Mali', area: 'ML', utcHours: 0, utcMinutes: 0 },
  { name: 'Malta', value: 'Malta', area: 'MT', utcHours: 1, utcMinutes: 0 },
  { name: 'Marshall Islands', value: 'Marshall Islands', area: 'MH', utcHours: 12, utcMinutes: 0 },
  { name: 'Martinique', value: 'Martinique', area: 'MQ', utcHours: -4, utcMinutes: 0 },
  { name: 'Mauritania', value: 'Mauritania', area: 'MR', utcHours: 0, utcMinutes: 0 },
  { name: 'Mauritius', value: 'Mauritius', area: 'MU', utcHours: 4, utcMinutes: 0 },
  { name: 'Mayotte', value: 'Mayotte', area: 'YT', utcHours: 3, utcMinutes: 0 },
  { name: 'Mexico', value: 'Mexico', area: 'MX', utcHours: -6, utcMinutes: 0 },
  { name: 'Micronesia', value: 'Micronesia', area: 'FM', utcHours: 11, utcMinutes: 0 },
  { name: 'Moldova', value: 'Moldova', area: 'MD', utcHours: 2, utcMinutes: 0 },
  { name: 'Monaco', value: 'Monaco', area: 'MC', utcHours: 1, utcMinutes: 0 },
  { name: 'Mongolia', value: 'Mongolia', area: 'MN', utcHours: 8, utcMinutes: 0 },
  { name: 'Montenegro', value: 'Montenegro', area: 'ME', utcHours: 1, utcMinutes: 0 },
  { name: 'Montserrat', value: 'Montserrat', area: 'MS', utcHours: -4, utcMinutes: 0 },
  { name: 'Morocco', value: 'Morocco', area: 'MA', utcHours: 1, utcMinutes: 0 },
  { name: 'Mozambique', value: 'Mozambique', area: 'MZ', utcHours: 2, utcMinutes: 0 },
  { name: 'Myanmar', value: 'Myanmar', area: 'MM', utcHours: 6, utcMinutes: 30 },
  { name: 'Namibia', value: 'Namibia', area: 'NA', utcHours: 2, utcMinutes: 0 },
  { name: 'Nauru', value: 'Nauru', area: 'NR', utcHours: 12, utcMinutes: 0 },
  { name: 'Nepal', value: 'Nepal', area: 'NP', utcHours: 5, utcMinutes: 45 },
  { name: 'Netherlands', value: 'Netherlands', area: 'NL', utcHours: 1, utcMinutes: 0 },
  { name: 'Caribbean Netherlands', value: 'Caribbean Netherlands', area: 'BQ', utcHours: 4, utcMinutes: 0 },
  { name: 'New Caledonia', value: 'New Caledonia', area: 'NC', utcHours: 11, utcMinutes: 0 },
  { name: 'Nicaragua', value: 'Nicaragua', area: 'NI', utcHours: -6, utcMinutes: 0 },
  { name: 'Niger', value: 'Niger', area: 'NE', utcHours: 1, utcMinutes: 0 },
  { name: 'Nigeria', value: 'Nigeria', area: 'NG', utcHours: 1, utcMinutes: 0 },
  { name: 'Niue', value: 'Niue', area: 'NU', utcHours: -11, utcMinutes: 0 },
  { name: 'Norfolk Island', value: 'Norfolk Island', area: 'NF', utcHours: 11, utcMinutes: 0 },
  { name: 'North Korea', value: 'North Korea', area: 'KP', utcHours: 9, utcMinutes: 0 },
  { name: 'Northern Mariana Islands', value: 'Northern Mariana Islands', area: 'MP', utcHours: 10, utcMinutes: 0 },
  { name: 'Norway', value: 'Norway', area: 'NO', utcHours: 1, utcMinutes: 0 },
  { name: 'Oman', value: 'Oman', area: 'OM', utcHours: 4, utcMinutes: 0 },
  { name: 'Pakistan', value: 'Pakistan', area: 'PK', utcHours: 5, utcMinutes: 0 },
  { name: 'Palau', value: 'Palau', area: 'PW', utcHours: 9, utcMinutes: 0 },
  { name: 'Palestinian Territories', value: 'Palestinian Territories', area: 'PS', utcHours: 2, utcMinutes: 0 },
  { name: 'Panama', value: 'Panama', area: 'PA', utcHours: -5, utcMinutes: 0 },
  { name: 'Papua New Guinea', value: 'Papua New Guinea', area: 'PG', utcHours: 11, utcMinutes: 0 },
  { name: 'Paraguay', value: 'Paraguay', area: 'PY', utcHours: -4, utcMinutes: 0 },
  { name: 'Peru', value: 'Peru', area: 'PE', utcHours: -5, utcMinutes: 0 },
  { name: 'Philippines', value: 'Philippines', area: 'PH', utcHours: 8, utcMinutes: 0 },
  { name: 'Pitcairn Islands', value: 'Pitcairn Islands', area: 'PN', utcHours: -8, utcMinutes: 0 },
  { name: 'Poland', value: 'Poland', area: 'PL', utcHours: 1, utcMinutes: 0 },
  { name: 'Portugal', value: 'Portugal', area: 'PT', utcHours: 0, utcMinutes: 0 },
  { name: 'Puerto Rico', value: 'Puerto Rico', area: 'PR', utcHours: -4, utcMinutes: 0 },
  { name: 'Qatar', value: 'Qatar', area: 'QA', utcHours: 3, utcMinutes: 0 },
  { name: 'Réunio', value: 'Réunio', area: 'RE', utcHours: 4, utcMinutes: 0 },
  { name: 'Romania', value: 'Romania', area: 'RO', utcHours: 2, utcMinutes: 0 },
  { name: 'Russia', value: 'Russia', area: 'RU', utcHours: 3, utcMinutes: 0 },
  { name: 'Rwanda', value: 'Rwanda', area: 'RW', utcHours: 2, utcMinutes: 0 },
  { name: 'Saint Helena', value: 'Saint Helena', area: 'SH', utcHours: 0, utcMinutes: 0 },
  { name: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis', area: 'KN', utcHours: -4, utcMinutes: 0 },
  { name: 'Saint Lucia', value: 'Saint Lucia', area: 'LC', utcHours: -4, utcMinutes: 0 },
  { name: 'Saint Pierre and Miquelon', value: 'Saint Pierre and Miquelon', area: 'PM', utcHours: -3, utcMinutes: 0 },
  { name: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines', area: 'VC', utcHours: -4, utcMinutes: 0 },
  { name: 'Samoa', value: 'Samoa', area: 'WS', utcHours: -11, utcMinutes: 0 },
  { name: 'San Marino', value: 'San Marino', area: 'SM', utcHours: 1, utcMinutes: 0 },
  { name: 'São Tomé and Príncipe', value: 'São Tomé and Príncipe', area: 'ST', utcHours: 0, utcMinutes: 0 },
  { name: 'Saudi Arabia', value: 'Saudi Arabia', area: 'SA', utcHours: 3, utcMinutes: 0 },
  { name: 'Senegal', value: 'Senegal', area: 'SN', utcHours: 0, utcMinutes: 0 },
  { name: 'Serbia', value: 'Serbia', area: 'RS', utcHours: 1, utcMinutes: 0 },
  { name: 'Seychelles', value: 'Seychelles', area: 'SC', utcHours: 4, utcMinutes: 0 },
  { name: 'Sierra Leone', value: 'Sierra Leone', area: 'SL', utcHours: 0, utcMinutes: 0 },
  { name: 'Singapore', value: 'Singapore', area: 'SG', utcHours: 8, utcMinutes: 0 },
  { name: 'Slovakia', value: 'Slovakia', area: 'SK', utcHours: 1, utcMinutes: 0 },
  { name: 'Slovenia', value: 'Slovenia', area: 'SI', utcHours: 1, utcMinutes: 0 },
  { name: 'Solomon Islands', value: 'Solomon Islands', area: 'SB', utcHours: 11, utcMinutes: 0 },
  { name: 'Somalia', value: 'Somalia', area: 'SO', utcHours: 3, utcMinutes: 0 },
  { name: 'South Africa', value: 'South Africa', area: 'ZA', utcHours: 2, utcMinutes: 0 },
  { name: 'South Georgia and the South Sandwich Islands', value: 'South Georgia and the South Sandwich Islands', area: 'GS', utcHours: -2, utcMinutes: 0 },
  { name: 'South Korea', value: 'South Korea', area: 'KR', utcHours: 9, utcMinutes: 0 },
  { name: 'Spain', value: 'Spain', area: 'ES', utcHours: 1, utcMinutes: 0 },
  { name: 'Sri Lanka', value: 'Sri Lanka', area: 'LK', utcHours: 5, utcMinutes: 30 },
  { name: 'Sudan', value: 'Sudan', area: 'SD', utcHours: 2, utcMinutes: 0 },
  { name: 'Suriname', value: 'Suriname', area: 'SR', utcHours: -3, utcMinutes: 0 },
  { name: 'Svalbard and Jan Mayen', value: 'Svalbard and Jan Mayen', area: 'SJ', utcHours: 1, utcMinutes: 0 },
  { name: 'Swaziland', value: 'Swaziland', area: 'SZ', utcHours: 2, utcMinutes: 0 },
  { name: 'Sweden', value: 'Sweden', area: 'SE', utcHours: 1, utcMinutes: 0 },
  { name: 'Switzerland', value: 'Switzerland', area: 'CH', utcHours: 1, utcMinutes: 0 },
  { name: 'Syria', value: 'Syria', area: 'SY', utcHours: 2, utcMinutes: 0 },
  { name: 'Taiwan', value: 'Taiwan', area: 'TW', utcHours: 8, utcMinutes: 0 },
  { name: 'Tajikistan', value: 'Tajikistan', area: 'TJ', utcHours: 5, utcMinutes: 0 },
  { name: 'Tanzania', value: 'Tanzania', area: 'TZ', utcHours: 3, utcMinutes: 0 },
  { name: 'Thailand', value: 'Thailand', area: 'TH', utcHours: 7, utcMinutes: 0 },
  { name: 'Timor-Leste', value: 'Timor-Leste', area: 'TL', utcHours: 9, utcMinutes: 0 },
  { name: 'Togo', value: 'Togo', area: 'TG', utcHours: 0, utcMinutes: 0 },
  { name: 'Tokelau', value: 'Tokelau', area: 'TK', utcHours: 13, utcMinutes: 0 },
  { name: 'Tonga', value: 'Tonga', area: 'TO', utcHours: 13, utcMinutes: 0 },
  { name: 'Trinidad and Tobago', value: 'Trinidad and Tobago', area: 'TT', utcHours: -4, utcMinutes: 0 },
  { name: 'Tunisia', value: 'Tunisia', area: 'TN', utcHours: 1, utcMinutes: 0 },
  { name: 'Turkey', value: 'Turkey', area: 'TR', utcHours: 3, utcMinutes: 0 },
  { name: 'Turkmenistan', value: 'Turkmenistan', area: 'TM', utcHours: 5, utcMinutes: 0 },
  { name: 'Turks and Caicos Islands', value: 'Turks and Caicos Islands', area: 'TC', utcHours: -4, utcMinutes: 0 },
  { name: 'Tuvalu', value: 'Tuvalu', area: 'TV', utcHours: 12, utcMinutes: 0 },
  { name: 'Uganda', value: 'Uganda', area: 'UG', utcHours: 3, utcMinutes: 0 },
  { name: 'Ukraine', value: 'Ukraine', area: 'UA', utcHours: 2, utcMinutes: 0 },
  { name: 'United Arab Emirates', value: 'United Arab Emirates', area: 'AE', utcHours: 4, utcMinutes: 0 },
  { name: 'Uruguay', value: 'Uruguay', area: 'UY', utcHours: -3, utcMinutes: 0 },
  { name: 'Uzbekistan', value: 'Uzbekistan', area: 'UZ', utcHours: 5, utcMinutes: 0 },
  { name: 'Vanuatu', value: 'Vanuatu', area: 'VU', utcHours: 11, utcMinutes: 0 },
  { name: 'Vatican City', value: 'Vatican City', area: 'VA', utcHours: 1, utcMinutes: 0 },
  { name: 'Venezuela', value: 'Venezuela', area: 'VE', utcHours: -4, utcMinutes: 0 },
  { name: 'Vietnam', value: 'Vietnam', area: 'VN', utcHours: 7, utcMinutes: 0 },
  { name: 'Wallis and Futuna', value: 'Wallis and Futuna', area: 'WF', utcHours: 12, utcMinutes: 0 },
  { name: 'Western Sahara', value: 'Western Sahara', area: 'EH', utcHours: 1, utcMinutes: 0 },
  { name: 'Yemen', value: 'Yemen', area: 'YE', utcHours: 3, utcMinutes: 0 },
  { name: 'Zambia', value: 'Zambia', area: 'ZM', utcHours: 2, utcMinutes: 0 },
  { name: 'Zimbabwe', value: 'Zimbabwe', area: 'ZW', utcHours: 2, utcMinutes: 0 }
];

export let Regions: Record<string, any> = {};
Regions[Country.AUSTRALIA] = {
  label: 'State',
  data: AUSTRALIAN_STATES
};
Regions[Country.CANADA] = {
  label: 'Province',
  data: CANADIAN_PROVINCES
};
Regions[Country.GERMANY] = {
  label: 'Land',
  data: GERMAN_LAENDER
};
Regions[Country.IRELAND] = {
  label: 'County',
  data: IRISH_COUNTIES
};
Regions[Country.NEW_ZEALAND] = {
  label: 'Region',
  data: NEWZEALAND_REGIONS
};
Regions[Country.SINGAPORE] = {
  label: 'District',
  data: SINGAPOREAN_DISTRICTS
};
Regions[Country.UNITED_KINGDOM] = {
  label: 'Region',
  data: UK_REGIONS
};
Regions[Country.UNITED_STATES] = {
  label: 'State',
  data: US_STATES
};
Regions[Country.REST_OF_WORLD] = {
  label: 'Country',
  data: ROW_COUNTRIES
};
