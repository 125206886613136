import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '@shared/guards/admin-guard';
import { AdminOrHostGuard } from '@shared/guards/admin-or-host-guard';
import { MembersGuard } from '@shared/guards/members-guard';
import { AdvertiserDetailPage } from './advertiser-detail/advertiser-detail.page';
import { AdvertiserEditPage } from './advertiser-edit/advertiser-edit.page';
import { AdvertiserListPage } from './advertiser-list/advertiser-list.page';
import { AnnouncementEditPage } from './announcement-edit/announcement-edit.page';

export const routes: Routes = [
  {
    path: '',
    component: AdvertiserListPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Advertiser listings'
    }
  },
  {
    path: ':id',
    component: AdvertiserDetailPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Advertiser listing details'
    }
  },
  {
    path: ':advertiserId/announcements/:announcementId/edit',
    component: AnnouncementEditPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Edit advertiser announcement'
    }
  },
  {
    path: 'update/:id',
    component: AdvertiserEditPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Update advertiser listing'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdvertisersRoutingModule {}
