import { Component } from '@angular/core';
import { AdminRole } from '@shared/constants/admin-role';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { Observable } from 'rxjs';
import { NewsService } from '../../services/news.service';
import { INews } from '../../models/news';

@Component({
  selector: 'app-news-list-component',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent {
  get ASSETS() {
    return this.constantsService.constants.ASSETS;
  }

  get CONSTANTS() {
    return this.constantsService.constants.MY_CHIRPY;
  }

  canAddNews$: Observable<boolean>;
  news$: Observable<INews[]>;
  loadingMessage: string = `Loading...`;
  noNewsMessage: string = `There is no news at the moment.`;

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private constantsService: ConstantsService, private newsService: NewsService) {}

  getNewsPhoto(news: INews) {
    let src = this.ASSETS.defaultImage;
    if (news != null && news.photos != null) {
      const photoKeys = Object.keys(news.photos || {});
      if (photoKeys.length > 0) src = news.photos[photoKeys[0]].mediumPhotoURL || src;
    }
    return src;
  }

  ionViewWillEnter() {
    // TODO: do we need to subscribe to authService?
    this.newsService.updateNewsLastRead(this.authService._userProfileSubject.value.uid);

    this.analyticsService.eventTrack(AnalyticsCategory.MY_CHIRPY, AnalyticsAction.MY_CHIRPY_VIEW_NEWS_LIST);
  }

  ionViewWillLeave() {
    // TODO: do we need to subscribe to authService?
    this.newsService.updateNewsLastRead(this.authService._userProfileSubject.value.uid);
  }

  ngOnInit() {
    this.canAddNews$ = this.authService.isAdmin$([AdminRole.EDITOR]);
    this.news$ = this.newsService.getNews();
  }
}
