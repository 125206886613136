import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AccessStatus } from '@shared/constants/access-status';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RomanceGuard implements CanActivate {
  constructor(private authService: AuthService, private constantsService: ConstantsService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.userProfileObservable.pipe(
      skipWhile(x => x == null),
      map((profile: UserObject) => {
        const requireListing = this.constantsService.constants.ROMANCE.INTRO.requireListing || false;
        if ((profile && profile.canAccessRomance === AccessStatus.GRANTED) || this.authService.isAdmin()) {
          return true;
        } // TODO: Keep logic in the next line in sync with romance-intro.page.html
        else if (requireListing && ((profile && profile.membershipType === 'annual') || this.authService.isCohostOrHostOrAdmin()) && state.url === `/romance/create/${profile.uid}`) {
          return true;
        } else {
          return this.router.parseUrl('/romance/intro');
        }
      })
    );
  }
}
