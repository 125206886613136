import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { ALL_COUNTRIES, CountryKey } from '@shared/constants/country';
import { IContent } from '@shared/models/content';
import { IInput } from '@shared/models/input';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { IPage } from '../models/page';
import { ContentPageService } from '../services/content-page.service';

@Component({
  selector: 'app-content-page-edit',
  templateUrl: './content-page-edit.page.html'
})
export class ContentPageEditPage implements OnInit {
  ALLOWED_COUNTRIES: CountryKey[];
  canEdit: boolean;
  content$: Observable<IContent>;
  country: CountryKey;
  country$ = new BehaviorSubject<CountryKey>(null);
  @ViewChild('editor', { static: false }) editor: any;
  EMPTY_DATA: Record<string, any> = { blocks: [] };
  filePrefix: string;
  page: IPage;

  constructor(private alertController: AlertController, private authService: AuthService, private constantsService: ConstantsService, private contentPageService: ContentPageService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.ALLOWED_COUNTRIES = Object.values(this.constantsService.constants.APP.countries);
    this.canEdit = this.authService.isAdmin([AdminRole.HOSTS, AdminRole.EDITOR]);
    const pageId = this.route.snapshot.paramMap.get('pageId');
    this.filePrefix = `page/${pageId}`;
    let firstLoad = true;

    this.content$ = this.contentPageService.getPage(pageId).pipe(
      switchMap(page => {
        this.page = page;
        if (firstLoad) {
          firstLoad = false;
          this.onSelectCountry(); // this modal sends a new value to the country$ observable
        }
        return this.country$;
      }),
      map(country => {
        this.country = country; // Don't fall back to ALL_COUNTRIES as this prevents us from creating specific country pages which don't yet exist
        return this.page.data[this.country] || this.EMPTY_DATA;
      })
    );
  }

  async onRename() {
    // truncate long thread titles to prevent tall modals pushing the name textbox and Rename button off-screen.
    const MAX_LENGTH = 320;
    let currentName = this.page.title;
    if (currentName.length > MAX_LENGTH) {
      currentName = currentName.slice(0, MAX_LENGTH) + '...';
    }

    const alert = await this.alertController.create({
      header: `Rename page`,
      subHeader: `Enter a new name for ${currentName}`,
      inputs: [
        {
          name: 'name',
          type: 'text',
          placeholder: `Enter a new name...`
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: `Rename`,
          handler: data => this.renamePageHandler(currentName, data)
        }
      ]
    });

    await alert.present();
  }

  onSave() {
    this.editor.onSave().then(content => {
      this.page.data[this.country] = content;
      // Need to pass title here, because when the page is reloaded the first() operator means we get back what we send here
      this.contentPageService.updatePage(this.page.uid, { data: { [this.country]: content }, title: this.page.title });
      this.router.navigate([`/pages/${this.page.uid}`]);
    });
  }

  async onSelectCountry() {
    const existingPages = Object.entries(this.page.data || {})
      .filter(x => (x[1].blocks || []).length > 0)
      .map(y => y[0]);
    const allowedCountryInputs = [ALL_COUNTRIES, ...this.ALLOWED_COUNTRIES].map(country => {
      const input = {
        label: country + (existingPages.includes(country) ? ` (exists)` : ``),
        type: 'radio',
        value: country
      } as IInput;
      if (country === this.country) input['checked'] = true;
      return input;
    });

    const alert = await this.alertController.create({
      cssClass: 'wide-select',
      header: 'Select country',
      message: `<ul class="instructions"><li>To update a country-specific version of this page, select a country below, edit the page and save.<li>To delete a country-specific version of this page, select a country below, and click delete.<li>If a specific version does not exist for the member's country, they will see the ALL version.</ul>`,
      inputs: allowedCountryInputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Delete',
          handler: data => this.deleteCountryConfirmation(data)
        },
        {
          text: `Edit`,
          handler: data => this.selectCountryHandler(data)
        }
      ]
    });

    await alert.present();
  }

  private async deleteCountryConfirmation(country: CountryKey) {
    const alert = await this.alertController.create({
      header: `Delete ${country} version?`,
      subHeader: `Clicking Delete will remove the version of the page for ${country}. THIS CANNOT BE UNDONE`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: `Delete`,
          handler: data => this.deleteCountryHandler(country)
        }
      ]
    });

    await alert.present();
  }

  private deleteCountryHandler(country: CountryKey) {
    delete this.page.data[country];
    this.contentPageService.strictUpdatePage(this.page.uid, this.page);
  }

  private renamePageHandler(currentName, data) {
    const name = data.name.trim();

    if (name.length === 0) {
      return false; // return false will stop the alert from closing.
    }

    this.contentPageService.updatePage(this.page.uid, { title: name });
    this.page.title = name;
  }

  private selectCountryHandler(data: CountryKey) {
    this.country$.next(data);
  }
}
