import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EventType } from '@infrastructure/constants/event-type';
import { ICatchup } from '@shared/models/catchups/catchup';
import { ICatchupTemplate } from '@shared/models/catchups/catchup-template';

@Injectable({
  providedIn: 'root'
})
export class CatchupEditPresenter {
  form = this.formBuilder.group({
    address: ['', Validators.required],
    coins: [''],
    country: [''],
    description: ['', Validators.required],
    date: ['', Validators.required],
    endDate: ['', Validators.required],
    endTime: ['', Validators.required],
    eventType: ['', Validators.required],
    hasPayments: [false],
    ownerId: ['', Validators.required],
    paymentAccountDetails: [''],
    paymentAmount: [''],
    paymentDueDate: [''],
    region: [''],
    rsvp: [false],
    sharedGroups: [{}],
    time: ['', Validators.required],
    title: ['', Validators.required],
    url: ['']
  });

  constructor(private formBuilder: FormBuilder) {}

  catchup() {
    let formValue = this.form.value as ICatchup;
    // sharedGroups is a map of groupId, groupName pairs. We keep the actual value in sync on the component, but only show the groupNames on the form control
    // Remove it here to so that it doesn't overwrite the map when we do Object.assign
    if (formValue.sharedGroups) {
      delete formValue.sharedGroups;
    }

    // Remove trailing spaces from URL
    if (formValue.url) formValue.url = formValue.url.trim();

    return formValue;
  }

  clear() {
    this.form.reset();
  }

  date(): string {
    return this.form.value.date;
  }

  endDate(): string {
    return this.form.value.endDate;
  }

  eventType() {
    return this.form.value.eventType as EventType;
  }

  hasPayments(): boolean {
    return this.form.value.hasPayments;
  }

  patchValue(data: any) {
    this.form.patchValue(data);
  }

  setValue(catchup: ICatchup | ICatchupTemplate) {
    this.form.setValue({
      address: catchup.address || '',
      coins: catchup.coins || null,
      country: catchup.country || '',
      date: catchup.date || '',
      description: catchup.description,
      endDate: catchup.endDate || '',
      endTime: catchup.endTime || '',
      eventType: catchup.eventType || EventType.CatchUp,
      hasPayments: catchup.hasPayments || false,
      ownerId: catchup.ownerId || '',
      paymentAccountDetails: catchup.paymentAccountDetails || '',
      paymentAmount: catchup.paymentAmount || '',
      paymentDueDate: catchup.paymentDueDate || '',
      region: catchup.region || '',
      rsvp: catchup.rsvp || false,
      sharedGroups: Object.values(catchup.sharedGroups || {}).join(', '),
      time: catchup.time || '',
      title: catchup.title,
      url: catchup.url || ''
    });
  }
}
