import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Gender } from '@shared/constants/gender';
import { NotificationTarget } from '@shared/constants/notification-target';
import { SelectType } from '@shared/constants/select-type';
import { ISelectOption } from '@shared/models/select-option';
import { INotificationSettings } from '@shared/models/notifications/notification-settings';
import { IPlace } from '@shared/models/place';
import { IValueWithId } from '@shared/models/value-with-id';
import { UserObject } from '@shared/models/user-object';
import { ActivityService, ActivityType } from '@shared/services/activity';
import { AuthService } from '@shared/services/auth.service';
import { RegionService } from '@shared/services/regions/region.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { LocationDatabase } from '@shared/services/location/location.database';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { of, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { SignupStepService } from '../services/signup-step.service';
import { SignupLocationService } from './services/location.service';
import { SignupLocationPresenter } from './location.presenter';

@Component({
  selector: 'app-signup-location',
  templateUrl: './location.page.html',
  viewProviders: [SignupLocationPresenter]
})
export class SignupLocationPage implements OnInit, OnDestroy {
  get formValid() {
    return this.presenter.form.valid;
  }

  get hasFewPlaces() {
    return this.hasLoadedPlaces && Object.keys(this.places).length > 0 && Object.keys(this.places).length < this.MANY_PLACES && this.presenter.region.trim().length > 0;
  }

  get hasFewPlacesWithin() {
    return this.hasLoadedSuburbs && Object.keys(this.suburbs).length > 0 && Object.keys(this.suburbs).length < this.MANY_PLACES && this.presenter.region.trim().length > 0;
  }

  get hasManyPlaces() {
    return this.hasLoadedPlaces && Object.keys(this.places).length >= this.MANY_PLACES && this.presenter.region.trim().length > 0;
  }

  get hasManyPlacesWithin() {
    return this.hasLoadedSuburbs && Object.keys(this.suburbs).length >= this.MANY_PLACES && this.presenter.region.trim().length > 0;
  }

  get hasNoPlaces() {
    return this.hasLoadedPlaces && Object.keys(this.places).length === 0 && this.presenter.region.trim().length >= 0;
  }

  get setLocationForm() {
    return this.presenter.form;
  }

  buttonColor: string;
  CONSTANTS: any;
  hasLoadedPlaces: boolean = false;
  hasLoadedSuburbs: boolean = false;
  isFirstLoad: boolean = true;
  isProcessing: boolean = false;
  MANY_PLACES: number = 6;
  places: Record<string, string> = {};
  progressColor: string;
  region: any = '';
  regionRef: Subscription;
  regionLabel: string;
  regionOptions: any = { cssClass: 'wide-select' };
  regions: ISelectOption[] = [];
  selectedPlace: Record<string, string>;
  selectedSuburb: Record<string, string>;
  selectType: string = SelectType.PLACE;
  showBackButton: boolean = false;
  showNavigation: boolean = false;
  submitButtonText: string = 'Next';
  suburbs: Record<string, string> = {};
  useBar: boolean;

  constructor(
    private activityService: ActivityService,
    private authService: AuthService,
    private regionService: RegionService,
    private constantsService: ConstantsService,
    private environmentService: EnvironmentService,
    private locationDatabase: LocationDatabase,
    private presenter: SignupLocationPresenter,
    public route: ActivatedRoute,
    private router: Router,
    private service: SignupLocationService,
    private signupStepService: SignupStepService,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService
  ) {}

  ionViewWillEnter() {
    if (this.authService._userProfileSubject.value) {
      // Don't set locality, placeId, or selectedPlace here as they get unset in the first call to onUpdateRegion
      this.presenter.patchValue({
        region: this.authService._userProfileSubject.value.region || ''
      });
      // Get regions for the member's country (if impersonating, ngOnInit will get regions for the admin's country)
      this.regionService.getRegionsForCountry(this.authService._userProfileSubject.value.country);
    }
  }

  ngOnInit() {
    this.buttonColor = this.constantsService.constants.WELCOME.SIGNUP.buttonColor;
    this.CONSTANTS = this.constantsService.constants.WELCOME.SIGNUP.LOCATION;
    this.progressColor = this.constantsService.constants.WELCOME.SIGNUP.progressColor;
    this.regionLabel = this.regionService.regionLabel;
    this.regions = this.regionService.locationRegions;
    this.showNavigation = this.constantsService.constants.WELCOME.SIGNUP.showNavigation;
    this.useBar = this.constantsService.constants.WELCOME.SIGNUP.useProgressBar;
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.regionRef);
  }

  onAddPlace(item: IValueWithId) {
    this.presenter.patchValue({
      locality: item.name,
      placeId: item.uid,
      suburbName: '',
      suburbId: ''
    });
    this.selectedPlace = { [item.uid]: item.name };

    if (item.uid) {
      this.service
        .getPlace(item.uid)
        .pipe(first())
        .subscribe((place: IPlace) => {
          // If the location selected is not a lowest-level place, show another set of places to choose from
          if (place && Object.entries(place.contains || {}).length > 0) {
            this.hasLoadedSuburbs = true;
            this.suburbs = place.contains;
          } else {
            this.hasLoadedSuburbs = false;
            this.suburbs = {};
          }
        });
    } else {
      this.hasLoadedSuburbs = false;
      this.suburbs = {};
    }
  }

  onAddSuburb(item: IValueWithId) {
    this.presenter.patchValue({
      suburbId: item.uid,
      suburbName: item.name
    });
    this.selectedSuburb = { [item.uid]: item.name };
  }

  onUpdateRegion($event: CustomEvent) {
    this.hasLoadedPlaces = false;
    this.hasLoadedSuburbs = false;
    const region = $event.detail.value;
    if (region != '') {
      if (this.region !== region) {
        // set to null to re-create location chooser, user can choose new placeId based on different region.
        this.setLocationForm.setValue({ region: region, placeId: null, locality: null, suburbId: null, suburbName: null }); // Edge case: If someone uses the back button to return to this page after setting location, this line unsets the placeId value on the form
        this.selectedPlace = {};
        this.selectedSuburb = {};
        this.region = region;
        this.subscriptionService.clearSubscription(this.regionRef);
        this.regionRef = this.service.getRegion(region).subscribe((placeData: IPlace) => {
          if (placeData == null) {
            this.places = {};
          } else if (placeData.contains) {
            this.places = placeData.contains;
          } else {
            this.places = { [placeData.uid]: placeData.displayName };
          }
          this.hasLoadedPlaces = true;

          // Set value of locality/placeId on first load, if already set
          if (this.isFirstLoad && this.authService._userProfileSubject.value) {
            const item: IValueWithId = {
              name: this.authService._userProfileSubject.value.locality,
              uid: this.authService._userProfileSubject.value.placeId
            };
            this.onAddPlace(item);
            this.isFirstLoad = false;
          }
        });
        this.subscriptionService.add(this.regionRef);
      }
    } else {
      this.places = {};
    }
  }

  onSubmit() {
    // second condition is necessary because the form still reads as valid if it starts with invalid input
    if (!this.formValid || (this.hasLoadedSuburbs && this.presenter.suburbId.length === 0)) {
      let message = 'Please select a location';
      if (this.presenter.region.trim().length === 0) message = `Please enter a valid ${this.regionLabel || 'region'}`;
      if (this.hasLoadedSuburbs) message = 'Please choose a suburb';

      this.toastService.presentToast(message);
      return;
    } else {
      this.isProcessing = true;
      this.submitButtonText = 'Processing';
      let data = this.presenter.submit();
      const locationId = data.suburbId || data.placeId;
      this.service
        .getPlace(locationId)
        .pipe(first())
        .subscribe((place: IPlace) => {
          if (place) {
            const utcOffset = this.regionService.getUtcOffset(data.region, this.authService._userProfileSubject.value.country);
            const locationData = { region: data.region, placeId: locationId, locality: place.displayName, utcOffset: utcOffset };
            this.service.setLocation(locationData);
            //this.createActivity(this.authService._userProfileSubject.value, place);
            const nextStep = this.signupStepService.getNextStep(this.route);
            this.isProcessing = false;
            this.submitButtonText = 'Next';
            if (nextStep) this.router.navigate(['/welcome/signup', nextStep]);
          } else {
            const locationName = data.suburbName || data.locality;
            const message = `Sorry, ${locationName} (id: ${locationId}) is not in our database. Please select a different location, or contact Support.`;
            this.toastService.presentToast(message);
            this.isProcessing = false;
            this.submitButtonText = 'Next';
          }
        });
    }
  }

  searchPlaces(search: string) {
    // chirpy-places-search expects to get a per-first-letter index for each new search term, and does its own filtering
    // we have only one list, place.contains, so don't need to retrieve anything on search
    return this.reformatSearchResults(this.places);
  }

  searchSuburbs(search: string) {
    return this.reformatSearchResults(this.suburbs);
  }

  private reformatSearchResults(data: Record<string, string>) {
    const result = Object.entries(data).reduce((acc, current) => {
      acc[current[1]] = current[0]; // chirpy-places-search expects name: uid, because of structure of centralPlacesIndex
      return acc;
    }, {});
    return of(result);
  }

  private createActivity(member: UserObject, place: IPlace): Promise<any> {
    const activity = {
      activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
      data: {
        baseUrl: this.environmentService.url(member.country),
        locationName: place.names[0],
        memberId: member.uid,
        memberName: member.displayName
      },
      message: `<a href="/members/${member.uid}">${member.displayName}</a> has just joined in ${place.displayName}`,
      notificationType: 'memberJoinedNotifyNearby',
      targetIds: [place.uid], // TODO: Once we have calculated nearby places use multiple targets here
      targetType: NotificationTarget.PLACE,
      timestamp: Date.now()
    };
    return this.activityService.createActivity(activity);
  }
}
