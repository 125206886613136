import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { NotificationTarget } from '@shared/constants/notification-target';
import { IListingImage } from '@shared/models/image/listing-image';
import { ActivityService, ActivityType } from '@shared/services/activity';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { ImageService } from '@shared/services/image/image.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { INews } from '../models/news';
import { NewsDatabase } from './news.database';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  constructor(
    private activityService: ActivityService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private imageService: ImageService,
    private newsDatabase: NewsDatabase,
    private router: Router,
    private toastService: ToastService
  ) {}

  deleteNewsItem(uid: string): Promise<void> {
    return this.newsDatabase.deleteNewsItem(uid);
  }

  getNews(): Observable<INews[]> {
    return this.authService._userProfileSubject.pipe(
      switchMap(member => {
        const country: Country | null = this.authService.isAdmin() ? null : member.country;
        return this.newsDatabase.getNews(country);
      })
    );
  }

  getNewsItem(uid: string): Observable<INews> {
    return this.newsDatabase.getNewsItem(uid);
  }

  hasUnreadNews(): Observable<boolean> {
    return combineLatest(this.authService._userProfileSubject, this.newsDatabase.getNewsLastUpdated()).pipe(
      map(([member, timestamps]) => {
        if (member == null || member.country == null || timestamps == null) return false;

        const lastRead = member.dateTimeNewsLastRead || 0;
        const lastUpdated = timestamps[member.country] || 0;
        return lastUpdated > lastRead;
      })
    );
  }

  updateNewsItem(news: INews, photosToRemove: IListingImage[]) {
    if (!this.validateForm(news)) return false;

    const isCreating = news.uid == null;
    if (isCreating) {
      news.dateTimeSent = this.dateTimeService.getDateTime();
    }
    news.dateTimeLastUpdated = this.dateTimeService.getDateTime();
    news.deleteDate = this.dateTimeService.addDaysToCurrentDate(45);

    // convert first letter to upper case
    news.title = news.title[0].toUpperCase() + news.title.slice(1);

    // Delete images removed from listing
    if (photosToRemove.length > 0) {
      for (const photo of photosToRemove) {
        this.imageService.deleteImage(photo).catch(err => console.error(JSON.stringify(err)));
      }
    }

    // copy photos data for later upload, and remove any File objects (Can't upload them to Firestore, we can't upload the image to Cloud storage until we have a shareListing uid)
    const photos = this.imageService.deepCopy(news.photos || {});
    for (let [key, photo] of Object.entries(news.photos || {})) {
      if (photo.file) {
        delete news.photos[key].file;
        news.photos[key].photoURL = '';
      }
    }

    const ref: Promise<any> = isCreating ? this.newsDatabase.createNewsItem(news) : this.newsDatabase.updateNewsItem(news, false);

    return ref.then(result => {
      const message = `News item ${isCreating ? 'added' : 'updated'}.`;
      this.toastService.presentToast(message);

      const uid = news.uid || result.id;
      news.uid = uid;

      // Now upload photos
      this.imageService.uploadImages(uid, photos, 'news');

      if (isCreating) {
        const constants = this.constantsService.constants.MY_CHIRPY;
        const activity = {
          activityTypes: [ActivityType.DIGEST],
          data: {}, // no data necessary for email notification
          message: `<strong><a href="/my-chirpy/news">${constants.tabs.news.title}</a>: ${news.title}</strong>`, // add full message if we add a feed option for this
          notificationType: 'newNews',
          targetIds: news.country, // this is an array of countries
          targetType: NotificationTarget.COUNTRY,
          timestamp: Date.now()
        };
        this.activityService.createActivity(activity);
      }

      // Upload news last updated time
      const now = Date.now();
      const data = news.country.reduce((output, current) => {
        output[current] = now;
        return output;
      }, {});
      this.newsDatabase.updateNewsLastUpdated(data);

      this.router.navigate(['/my-chirpy/news']);
    });
  }

  updateNewsLastRead(uid: string) {
    this.newsDatabase.updateNewsLastRead(uid, { dateTimeNewsLastRead: Date.now() });
  }

  validateForm(data: any) {
    const missingFields = [];
    if (data.title.trim().length === 0) missingFields.push('a title');
    if (data.content.trim().length === 0) missingFields.push('some content');

    if (missingFields.length > 0) {
      const message = 'Please enter ' + missingFields.join(', ');
      this.toastService.presentToast(message);
      return false;
    }

    return true;
  }
}
