import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Gender } from '@shared/constants/gender';
import { AuthService } from '@shared/services/auth.service';
import { skipWhile, take } from 'rxjs/operators';
import { IRomanceOptions } from '../../models/romance-options';
import { RomanceService } from '../../services/romance.service';

@Injectable({
  providedIn: 'root'
})
export class RomanceSearchPresenter {
  defaultRomanceSearch: IRomanceOptions;
  form = this.formBuilder.group({
    ageRange: [''],
    region: [''],
    seeking: [Gender.EVERYONE]
  });

  constructor(private formBuilder: FormBuilder, private romanceService: RomanceService, private authService: AuthService) {
    this.authService._userProfileSubject
      .pipe(
        skipWhile(u => !u),
        take(1)
      )
      .subscribe(user => {
        // sensible defaults, opposite gender.
        const defaultRomanceSearch = this.romanceService.getDefaultRomanceOptions(user);
        this.defaultRomanceSearch = defaultRomanceSearch;
      });
  }

  reset() {
    this.setValue(this.defaultRomanceSearch as IRomanceOptions);
  }

  search() {
    return this.form.value;
  }

  setValue(romanceOptions: IRomanceOptions) {
    this.form.setValue({ region: romanceOptions.region, ageRange: romanceOptions.ageRange, seeking: romanceOptions.seeking || Gender.EVERYONE });
  }
}
