import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AdvertiserService } from '@shared/services/advertisers/advertiser.service';
import { ConstantsService } from '@shared/services/constants.service';
import { BaseAdminComponent } from '../base-admin/base-admin.component';

@Component({
  selector: 'app-add-advertiser-access',
  templateUrl: '../base-admin/base-admin.component.html',
  styleUrls: ['../base-admin/base-admin.component.scss']
})
export class AddAdvertiserAccessComponent extends BaseAdminComponent {
  branding: string;

  constructor(private advertiserService: AdvertiserService, alertController: AlertController, private constantsService: ConstantsService, private router: Router) {
    super(alertController);
  }

  confirmMessage(memberId: string, memberName: string) {
    return `<p>Give ${memberName} access to ${this.branding}?</p><p>They need to be paying for this service.</p>`;
  }

  selectMemberHandler({ memberId, memberName, data }) {
    this.advertiserService.maybeCreateAdvertiser(memberId, memberName);
  }

  setup() {
    this.branding = this.constantsService.constants.ADVERTISERS.branding;

    this.confirmHeader = `Add ${this.branding} access`;
    this.confirmButton = `Grant access`;
    this.placeholder = `Search for a member to grant ${this.branding} access to`;
    this.title = `Add ${this.branding} access`;
  }
}
