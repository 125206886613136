import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MemberProfileDetailPage } from './member-profile-detail/member-profile-detail.page';
import { MemberProfileEditGuard } from './member-profile-edit/member-profile-edit.guard';
import { MemberProfileEditPage } from './member-profile-edit/member-profile-edit.page';

const routes: Routes = [
  {
    path: ':userId/edit',
    component: MemberProfileEditPage,
    canActivate: [MemberProfileEditGuard],
    data: {
      title: 'Edit profile'
    }
  },
  {
    path: ':userId',
    component: MemberProfileDetailPage,
    data: {
      title: 'Member profile'
    }
  },
  {
    path: '',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MembersRoutingModule {}
