import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { NotificationTarget } from '@shared/constants/notification-target';
import { IListingImage } from '@shared/models/image/listing-image';
import { ICollectionCount } from '@shared/models/media/collection-count';
import { IMedia } from '@shared/models/media/media';
import { IMediaMetadata } from '@shared/models/media/media-metadata';
import { ActivityService, ActivityType } from '@shared/services/activity';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { GroupService } from '@shared/services/groups/group.service';
import { ImageService } from '@shared/services/image/image.service';
import { PhotoService } from '@shared/services/photos/photo.service';
import { ToastService } from '@shared/services/toast.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ChirpyPhotoUploadPresenter } from './chirpy-photo-upload.presenter';

@Component({
  selector: 'chirpy-photo-upload',
  styleUrls: ['./chirpy-photo-upload.component.scss'],
  templateUrl: './chirpy-photo-upload.component.html',
  viewProviders: [ChirpyPhotoUploadPresenter]
})
export class ChirpyPhotoUploadComponent implements OnInit {
  get form() {
    return this.presenter.form;
  }

  @Input() collectionId: string;
  @Input() collectionTitle: string;
  @Input() collectionType: MediaCollectionType;
  CONSTANTS: Record<string, any>;
  MAX_PHOTOS: number = 1; // Only allow upload of one image at a time to enforce entering a caption
  NO_QUOTA: number = -1;
  mediaRemaining: number;
  media: IMedia = {
    collections: [],
    commentCount: 0,
    dateTimeUploaded: 0,
    isHidden: false,
    largePhotoURL: '',
    mediumPhotoURL: '',
    originalPhotoURL: '',
    photoURL: '',
    uploaderId: ''
  };
  collectionCount$: Observable<ICollectionCount>;

  compareById(a: any, b: any) {
    return a && b ? a.id === b.id : a === b;
  }

  constructor(
    private activityService: ActivityService,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private environmentService: EnvironmentService,
    private imageService: ImageService,
    private photoService: PhotoService,
    private presenter: ChirpyPhotoUploadPresenter,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.MEDIA;
    this.collectionCount$ = this.photoService.getCollectionCount(this.collectionId, this.collectionType);
  }

  async onSubmit(metadata: IMediaMetadata) {
    const isValid = await this.validateForm(metadata);
    if (!isValid) return;

    let data = this.presenter.photo();

    // Add category for public pix
    if (metadata.category.hasOwnProperty('id')) Object.assign(this.media, { category: metadata.category.id });

    // Add caption
    if (metadata.caption.trim().length > 0) Object.assign(this.media, { caption: metadata.caption });

    // Determine collection IDs
    let collections = [this.photoService.getCollectionKey(this.collectionId, this.collectionType)];
    if (metadata.public === true && this.collectionType !== MediaCollectionType.PUBLIC) collections.push(MediaCollectionType.PUBLIC); // TODO collection key (public) happens to be the same as MediaCollectionType (public), should really distinguish in case we have multiple public collections
    if (metadata.relatedId !== '') collections.push(this.photoService.getCollectionKey(metadata.relatedId, metadata.category.type));
    let changedCollections: Record<string, number> = {};
    for (let collection of collections) {
      changedCollections[collection] = 1;
    }

    // Add remaining metadata
    const uploaderId = this.authService._userProfileSubject.value.uid;
    Object.assign(this.media, { collections, uploaderId });

    // We support uploading multiple media at once, so loop over the supplied files
    // metadata in this.media is the same for all media
    const promises: any[] = [];
    const photos: IListingImage[] = Object.values(data.photos);
    for (let item of photos) {
      // Need to have a unique dateTimeUploaded when multiple images uploaded at once
      // Otherwise ordering for collection page and getAdjacentPhoto is inconsistent
      const dateTimeUploaded = this.dateTimeService.getDateTime() + Math.floor(Math.random() * 1000);
      Object.assign(this.media, { dateTimeUploaded });

      promises.push(
        this.photoService.createPhoto(this.media).then(result => {
          const uid = result.id;
          this.media.uid = uid;
          this.photoService.updateCollections(this.media, changedCollections).then(() => {
            this.imageService.uploadImage(item.file, `media/${uid}/file`);
            if (this.collectionType === MediaCollectionType.GROUP) {
              this.createActivity(this.media, this.collectionId, this.collectionTitle, false);
            } else if (metadata.category.type === MediaCollectionType.GROUP) {
              this.createActivity(this.media, metadata.relatedId, this.collectionTitle, true);
            }
            this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_UPLOAD_MEDIA, uid, { type: this.collectionTitle });
          });
        })
      );
    }
    return Promise.all(promises).then(() => {
      const message = photos.length === 1 ? `1 item uploaded` : `${photos.length} items uploaded`;
      this.toastService.presentToast(message);

      this.router.navigate(['../'], { relativeTo: this.route });
    });
  }

  private createActivity(media: IMedia, collectionId: string, collectionTitle: string, isPublic: boolean) {
    const uploaderName = this.authService._userProfileSubject.value.displayName;
    const message = isPublic
      ? `<a href="/members/${media.uploaderId}">${uploaderName}</a> added a <a href="/pix/public/${collectionTitle}/detail?photo=${media.uid}">new photo</a> for one of your groups to <a href="/pix/public/${collectionTitle}">${collectionTitle}</a>`
      : `<a href="/members/${media.uploaderId}">${uploaderName}</a> added a <a href="/groups/photos/${collectionId}/${collectionTitle}/detail?photo=${media.uid}">new photo</a> in <a href="/groups/photos/${collectionId}/${collectionTitle}">${collectionTitle}</a>`;

    const activity = {
      activityTypes: [ActivityType.FEED, ActivityType.DIGEST], // Add ActivityType.EMAIL back if we implement the ability to receive emails for these notifications
      data: {
        baseUrl: this.environmentService.url(this.authService._userProfileSubject.value.country),
        collectionId: collectionId,
        collectionTitle: collectionTitle,
        mediaId: media.uid,
        uploaderId: media.uploaderId,
        uploaderName: uploaderName
      },
      message: message,
      notificationType: 'newMedia',
      targetIds: [collectionId],
      targetType: NotificationTarget.GROUP,
      timestamp: Date.now()
    };
    this.activityService.createActivity(activity);
  }

  private validateForm(metadata: IMediaMetadata): Promise<boolean> {
    const data = this.presenter.photo();

    const messages: string[] = [];
    if (Object.keys(data.photos).length === 0) {
      messages.push(`Please upload at least one media item.`);
    }

    const quotaCollection = metadata.relatedId !== '' ? this.photoService.getCollectionCount(metadata.relatedId, metadata.category.type) : this.photoService.getCollectionCount(this.collectionId, this.collectionType);

    return quotaCollection
      .pipe(first())
      .toPromise()
      .then(collectionCount => {
        if (collectionCount.hasQuota && !collectionCount.hasMediaRemaining) {
          // TODO: When there are more options that public or group, change this message
          messages.push(`Group has reached quota. Please delete at least ${collectionCount.count - collectionCount.quota + 1} item.`);
        }

        if (messages.length > 0) {
          this.toastService.presentToast(messages.join('\n'));
          return false;
        }

        return true;
      });
  }
}
