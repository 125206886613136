import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Country } from '@shared/constants/country';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { UIService } from '@shared/services/ui.service';
import { UrlHelperService } from '@shared/services/url-helper.service';
import { of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss']
})
export class DashboardPage {
  iconSize = { LARGE: 'LARGE', SMALL: 'SMALL' };

  icons = [
    {
      title: 'My Chirpy',
      url: '/my-chirpy',
      size: this.iconSize.LARGE,
      src: '/assets/chirpy/dashboard/my-chirpy.svg'
    },
    {
      title: 'CatchUps & Events',
      url: '/catchups',
      size: this.iconSize.LARGE,
      src: '/assets/chirpy/dashboard/catchups.svg'
    },
    {
      title: 'Groups',
      url: '/groups',
      size: this.iconSize.LARGE,
      src: '/assets/chirpy/dashboard/groups.svg'
    },
    {
      title: 'Socials',
      url: '/social',
      size: this.iconSize.LARGE,
      src: '/assets/chirpy/dashboard/social.svg'
    },
    {
      title: 'Travel',
      url: '/travel-au', //redirected to /groups/50jOCeizq4wGL0genKQE in chirpy-routing.module
      size: this.iconSize.SMALL,
      src: '/assets/chirpy/dashboard/travel.svg',
      canShow: this.isAU$()
    },
    {
      title: 'Travel',
      url: '/travel-nz', //redirected to /groups/9BnrvUW2w6aeduG9qS2Z in chirpy-routing.module
      size: this.iconSize.SMALL,
      src: '/assets/chirpy/dashboard/travel.svg',
      canShow: this.isNZ$()
    },
    {
      title: 'Rewards',
      url: '/rewards',
      size: this.iconSize.SMALL,
      src: '/assets/chirpy/dashboard/coins.svg'
    },
    {
      title: 'Chirpy Pix',
      url: '/pix/public/Chirpy Pix',
      size: this.iconSize.SMALL,
      src: '/assets/chirpy/dashboard/chirpy-pix.svg'
    },
    {
      title: 'Find Local Chirpies',
      url: '/meeting-place',
      size: this.iconSize.SMALL,
      src: '/assets/chirpy/dashboard/meeting-place.svg'
    },
    {
      title: 'Romance',
      url: '/romance',
      size: this.iconSize.SMALL,
      src: '/assets/chirpy/dashboard/romance.svg'
    },
    {
      title: 'Games',
      url: '/pages/fun-and-games',
      size: this.iconSize.SMALL,
      src: '/assets/chirpy/dashboard/fun.svg'
    },
    {
      title: 'Chirpy Savings',
      url: '/pages/savings',
      size: this.iconSize.SMALL,
      src: '/assets/chirpy/dashboard/savings.svg',
      canShow: this.isNZ$()
    },
    {
      title: 'Advertisers',
      url: '/advertisers',
      size: this.iconSize.SMALL,
      src: '/assets/chirpy/dashboard/advertisers.svg',
      canShow: this.isNZ$()
    },
    {
      title: 'Admin',
      url: '/admin',
      size: this.iconSize.SMALL,
      src: '/assets/chirpy/dashboard/admin.svg',
      canShow: this.authService.isAdmin$()
    }
  ];

  uiServiceSubscription: Subscription;
  size = { LARGE: 6, SMALL: 4 };

  constructor(private analyticsService: AnalyticsService, public authService: AuthService, private environmentService: EnvironmentService, public platform: Platform, public uiService: UIService, private urlHelperService: UrlHelperService) {}

  getSize(iconSize: string): number {
    return this.size[iconSize];
  }

  getTravelTagUrl() {
    return `https://app.chirpytraveltag${this.urlHelperService.domain}`;
  }

  isAU$() {
    return this.authService.userProfileObservable.pipe(map((user: UserObject) => user != null && user.country === Country.AUSTRALIA));
  }

  isNZ$() {
    return this.authService.userProfileObservable.pipe(map((user: UserObject) => user != null && user.country === Country.NEW_ZEALAND));
  }

  ngOnInit() {
    this.uiServiceSubscription = this.uiService.isMediumDisplay$.subscribe(isMediumDisplay => {
      if (isMediumDisplay) {
        this.size = { LARGE: 3, SMALL: 2 };
      } else {
        this.size = { LARGE: 6, SMALL: 4 };
      }
    });
  }

  ngOnDestroy() {
    this.uiServiceSubscription.unsubscribe();
  }

  onClickIcon(icon: any, index: number) {
    this.analyticsService.eventTrack(AnalyticsCategory.NAVIGATION, AnalyticsAction.NAVIGATION_DASHBOARD_ICON, icon.title, { type: icon.size }, index);
  }

  showLoadingOverlay() {
    this.uiService.showLoadingOverlay();
  }
}
