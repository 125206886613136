import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { IChirpyMemberListButton } from '@shared/components/chirpy-member-list/chirpy-member-list-button';
import { IAttendeeData } from '@shared/models/catchups/attendee-data';
import { ICatchup } from '@shared/models/catchups/catchup';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { ITransformFunction } from '@shared/models/transform-function';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { CatchupRsvpService } from '@shared/services/catchups/catchup-rsvp.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EmailService } from '@shared/services/email/email.service';
import { GroupService } from '@shared/services/groups/group.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import firebase from 'firebase/app';
import { of, Subscription } from 'rxjs';
import { skipWhile, switchMap, take } from 'rxjs/operators';
import FieldValue = firebase.firestore.FieldValue;

@Component({
  selector: 'app-catchup-waitlist',
  templateUrl: './catchup-waitlist.component.html'
})
export class CatchupWaitlistComponent implements OnInit {
  attendeeData: Record<string, IAttendeeData> = {};
  attendees: UserObject[];
  buttons: IChirpyMemberListButton[];
  buttonsAfter: boolean = false;
  canManageGroup: boolean; // is Admin or Group Host
  catchup: ICatchup;
  catchupSubscription: Subscription;
  dataMap: Record<string, ITransformFunction> = {
    dateTime: data => {
      return this.dateTimeService.formatDate(data, 'ddd D MMM YYYY, h:mm a');
    },
    info: data => data,
    paymentRequest: data => {
      return `Payment request${data.length > 1 ? 's' : ''} sent ` + data.map(x => this.dateTimeService.formatDate(x, 'ddd D MMM YYYY, h:mm a')).join(';');
    }
  };
  largePhotoURL: string;
  user: UserObject;

  constructor(
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private catchupRsvpService: CatchupRsvpService,
    private catchupService: CatchupService,
    private dateTimeService: DateTimeService,
    private emailService: EmailService,
    private groupService: GroupService,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  initButtons(catchup: ICatchup = null) {
    if (catchup === null || !this.catchupRsvpService.isClosed(catchup)) {
      this.buttons = [
        {
          canShow: member => this.canManageGroup && this.isMemberWaiting(member),
          command: CatchupRsvpStatus.GOING,
          label: 'Accept'
        },
        {
          canShow: member => this.canManageGroup && this.isMemberWaiting(member),
          command: CatchupRsvpStatus.DECLINED_BY_HOST,
          label: 'Decline'
        },
        {
          canShow: member => this.canManageGroup && !!this.catchup.hasPayments, // TODO: Hide button when payment made?
          command: 'request-payment',
          label: 'Request payment'
        },
        {
          canShow: member => this.canManageGroup,
          command: 'edit-info',
          label: 'Add info'
        }
      ];
    } else {
      this.buttons = [];
    }
    // Display the buttons after the member name and data if there are more than 3 buttons
    this.buttonsAfter = this.buttons.length > 3;
  }

  isMemberWaiting(member: UserObject) {
    return this.catchup.attendees[member.uid] === CatchupRsvpStatus.WAITING_FOR_HOST;
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.catchupSubscription);
  }

  ngOnInit() {
    this.initButtons();

    const uid = this.route.snapshot.parent.paramMap.get('catchupId');

    // Ideally call eventTrack on ionViewWillEnter, but then have a race condition with loading CatchUp data
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_VIEW_WAITLIST, uid);

    this.catchupSubscription = this.catchupService
      .getCatchup(uid)
      .pipe(
        switchMap((catchup: ICatchup) => {
          if (catchup == null) return;

          this.catchup = catchup;
          this.initButtons(catchup);
          return this.groupService.canManageGroup$(catchup.groupId);
        }),
        switchMap((canManageGroup: boolean) => {
          this.canManageGroup = canManageGroup;
          return canManageGroup ? this.catchupService.getCatchupAttendeesData(this.catchup.uid) : of({});
        }),
        switchMap((attendeeData: Record<string, IAttendeeData>) => {
          this.attendeeData = attendeeData || {};
          return this.catchupService.getCatchupWaitlist(this.catchup.attendees);
        })
      )
      .subscribe((attendees: UserObject[]) => {
        if (attendees == null) return;
        this.attendees = attendees;
      });
    this.subscriptionService.add(this.catchupSubscription);

    this.authService._userProfileSubject
      .pipe(
        skipWhile(u => !u),
        take(1)
      )
      .subscribe(user => (this.user = user));
  }

  onButtonClick({ member, command }) {
    const currentCount = (this.attendees || []).length;
    switch (command) {
      case CatchupRsvpStatus.GOING:
        this.catchupRsvpService.acceptRsvp(this.catchup, member);
        break;

      case CatchupRsvpStatus.DECLINED_BY_HOST:
        this.catchupRsvpService.declineRsvp(this.catchup, member);
        break;

      case 'edit-info':
        this.onEditInfo(member);
        break;

      case 'request-payment':
        this.onRequestPayment(member);
        break;
    }
    if (currentCount === 1 && Object.values(CatchupRsvpStatus).includes(command)) this.router.navigate(['../attendees'], { relativeTo: this.route }); // navigate away if there was only one waitlisted attendee at the start, rather than waiting till accept/decline has processed and there are no waitlist attendees left
  }

  async onEditInfo(member: UserObject) {
    const memberData = this.attendeeData[member.uid] || {};
    const alert = await this.alertController.create({
      header: `Add info about ${member.displayName}`,
      message: `(This is only visible to hosts and co-hosts)`,
      inputs: [
        {
          name: 'info',
          type: 'text',
          value: memberData.info || '',
          placeholder: `Enter info (optional)`
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: `Save`,
          handler: data => this.editInfoHandler(member.uid, data)
        }
      ]
    });

    await alert.present();
  }

  async onRequestPayment(member: UserObject) {
    const alert = await this.alertController.create({
      header: `Request payment`,
      message: `Send an email to ${member.displayName} with bank account details?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: `Send`,
          handler: data => this.onRequestPaymentHandler(member)
        }
      ]
    });

    await alert.present();
  }

  private onRequestPaymentHandler(member: UserObject) {
    this.catchupService.updateCatchupAttendeeData(this.catchup.uid, member.uid, { paymentRequest: FieldValue.arrayUnion(this.dateTimeService.getDateTime()) });
    this.emailService.sendCatchupRequestPaymentEmail(member, this.catchup);
  }

  private editInfoHandler(memberId: string, data: any) {
    this.catchupService.updateCatchupAttendeeData(this.catchup.uid, memberId, data);
    this.analyticsService.eventTrack(AnalyticsCategory.CATCHUPS, AnalyticsAction.CATCHUPS_ADD_INFO, this.catchup.uid, { type: 'waitlist' });
  }
}
