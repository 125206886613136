import { Component, Input } from '@angular/core';

@Component({
  selector: 'email-header',
  templateUrl: './email-header.component.html'
})
export class EmailHeaderComponent {
  @Input() date: any;
  @Input() subject: string;
  @Input() to: string;
}
