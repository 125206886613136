import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { SignupProgressComponent } from './signup/components/signup-progress/signup-progress.component';
import { SignupNavigationComponent } from './signup/components/signup-navigation/signup-navigation.component';
import { SignupDisplayNamePage } from './signup/display-name/display-name.page';
import { SignupGenderPage } from './signup/gender/gender.page';
import { SignupGroupsPage } from './signup/groups/groups.page';
import { SignupLocationPage } from './signup/location/location.page';
import { SignupOptionalPage } from './signup/optional/optional.page';
import { SignupWhatsNextPage } from './signup/whats-next/whats-next.page';
import { WelcomePage } from './welcome-page/welcome.page';
import { WelcomeRoutingModule } from './welcome-routing.module';

@NgModule({
  imports: [SharedModule, WelcomeRoutingModule],
  declarations: [SignupDisplayNamePage, SignupGenderPage, SignupGroupsPage, SignupLocationPage, SignupOptionalPage, SignupProgressComponent, SignupNavigationComponent, SignupWhatsNextPage, WelcomePage]
})
export class WelcomeModule {}
