import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class SignupGroupsPresenter {
  form = this.formBuilder.group({
    groupCheckboxes: this.formBuilder.group({})
  });

  constructor(private formBuilder: FormBuilder) {}

  setGroups(groups: string[]): void {
    const checkboxes = <FormGroup>this.form.get('groupCheckboxes');
    groups.forEach(uid => {
      checkboxes.addControl(uid, new FormControl(false));
    });
  }

  submit(): any {
    return this.form.value;
  }
}
