import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChirpyChangePasswordPresenter } from '@shared/components/chirpy-change-password/chirpy-change-password.presenter';
import { IChangePasswordResult } from '@shared/models/update-password-result';
import { AuthService } from '@shared/services/auth.service';
import { ToastService } from '@shared/services/toast.service';

@Component({
  selector: 'chirpy-change-password',
  templateUrl: './chirpy-change-password.component.html',
  viewProviders: [ChirpyChangePasswordPresenter]
})
export class ChirpyChangePasswordComponent implements OnInit {
  get formValid() {
    return this.presenter.form.valid;
  }

  get changePasswordForm() {
    return this.presenter.form;
  }

  @Input() color: string = 'primary';
  @Input() currentPassword: string = '';
  @Input() email: string;
  @Input() showCurrentPasswordField: boolean = true;
  showSpinner: boolean = false;
  @Output() success = new EventEmitter<any>();

  get submitButtonText() {
    return this.showCurrentPasswordField ? 'Change password' : 'Set password';
  }

  constructor(private authService: AuthService, private presenter: ChirpyChangePasswordPresenter, private toastService: ToastService) {}

  ngOnInit() {
    this.presenter.setFormValue({ currentPassword: this.currentPassword, password: '' });
  }

  onSubmit() {
    if (!this.formValid) return;

    const { currentPassword, password } = this.presenter.submit();

    this.showSpinner = true;
    this.authService
      .updatePassword(this.email, currentPassword, password)
      .then((data: IChangePasswordResult) => {
        if (data.error) {
          this.toastService.presentToast(data.message);
          return;
        }

        this.success.emit(data);
      })
      .finally(() => {
        this.showSpinner = false;
      });
  }
}
