import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventType } from '@infrastructure/constants/event-type';
import { AlertController, IonSlides } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { TripSection } from '@shared/constants/trip-section';
import { ITrip } from '@shared/models/trips/trip';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { MessageHelperService } from '@shared/services/messages/message-helper.service';
import { TripService } from '@shared/services/trips/trip.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UIService } from '@shared/services/ui.service';
import { of, Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-trip-detail',
  templateUrl: './trip-detail.page.html',
  styleUrls: ['./trip-detail.page.scss']
})
export class TripDetailPage implements OnDestroy, OnInit {
  get CONSTANTS() {
    return this.constantsService.constants.TRIPS.DETAIL;
  }

  get isAdmin() {
    return this.authService.isAdmin([AdminRole.TRAVEL]);
  }

  get SECTIONS() {
    return this.constantsService.constants.TRIPS.SECTIONS;
  }

  canManageTrip: boolean = false;
  canSeeGroup: boolean = false;
  isDesktop: boolean = false;
  platformSubscription: Subscription;
  trip: ITrip;
  tripSubscription: Subscription;
  errorMessage: string;
  @ViewChild('slides', { static: false }) slides: IonSlides;
  slideOpts: any = {
    loop: true,
    slidesPerView: 1
  };
  title: string;
  member: UserObject;

  constructor(
    private alertController: AlertController,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private constantsService: ConstantsService,
    private groupService: GroupService,
    private tripService: TripService,
    private subscriptionService: SubscriptionService,
    private route: ActivatedRoute,
    private router: Router,
    private uiService: UIService
  ) {}

  deleteTrip() {
    this.tripService.deleteTrip(this.trip);
  }

  getTrip(uid: string) {
    this.tripSubscription = this.tripService
      .getTrip(uid)
      .pipe(
        switchMap(trip => {
          if (trip == null) {
            this.errorMessage = 'Trip does not exist';
            return of(false);
          }

          if (trip.interested == null) trip.interested = {};
          this.trip = trip;
          this.title = this.trip.title;
          return this.groupService.canManageGroup$(this.trip.groupId);
        })
      )
      .subscribe(canManageGroup => {
        this.canManageTrip = this.isAdmin || canManageGroup;
        // TODO: Is it better to use explicit group membership, or booked status on trip?
        this.canSeeGroup = this.isAdmin || (this.member.catchupGroupIds || []).includes(this.trip.tripGroupId);
        this.sendAnalytics(this.trip);
      });
    this.subscriptionService.add(this.tripSubscription);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.platformSubscription);
    this.subscriptionService.clearSubscription(this.tripSubscription);
  }

  ngOnInit() {
    this.platformSubscription = this.uiService.isLargeDisplay$.subscribe(isLargeDisplay => {
      this.isDesktop = isLargeDisplay;
    });
    this.subscriptionService.add(this.platformSubscription);

    this.authService._userProfileSubject.subscribe(user => {
      this.member = user;
    });

    // Set fallback title
    this.title = this.CONSTANTS.title;

    const uid = this.route.snapshot.paramMap.get('tripId');
    this.getTrip(uid);
  }

  async publishTrip() {
    const alert = await this.alertController.create({
      header: 'Publish Trip?',
      message: 'Are you sure you want to publish this trip?\n This will make it visible to members and send notifications to all members of the Travel group.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Publish',
          handler: data => this.tripService.publishTrip(this.trip)
        }
      ]
    });

    await alert.present();
  }

  sendAnalytics(trip: ITrip) {
    this.analyticsService.eventTrack(AnalyticsCategory.TRIPS, AnalyticsAction.TRIPS_VIEW_DETAILS, this.trip.title);
  }

  slidePrev() {
    this.slides.slidePrev();
  }
  slideNext() {
    this.slides.slideNext();
  }
}
