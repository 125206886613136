import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { IMediaCategoryOption } from '@shared/models/media/media-category-option';
import { IMediaMetadata } from '@shared/models/media/media-metadata';

@Injectable({
  providedIn: 'root'
})
export class ChirpyEditMediaMetadataPresenter {
  form = this.formBuilder.group({
    caption: ['', Validators.required],
    category: [{}],
    public: [false],
    relatedId: ['']
  });

  constructor(private formBuilder: FormBuilder) {}

  media() {
    return this.form.value;
  }

  patchValue(data: any) {
    this.form.patchValue(data);
  }

  setValue(metadata: IMediaMetadata) {
    this.form.setValue({
      caption: metadata.caption || '',
      category: metadata.category || { id: null, type: '' },
      public: metadata.public || false,
      relatedId: metadata.relatedId || ''
    });
  }
}
