import { Component, Input } from '@angular/core';

@Component({
  selector: 'chirpy-disclaimer',
  styleUrls: ['./chirpy-disclaimer.component.scss'],
  templateUrl: './chirpy-disclaimer.component.html'
})
export class ChirpyDisclaimerComponent {
  @Input() html: string;
  @Input() text: string;
}
