import { Component } from '@angular/core';
import { IActivity } from '@shared/models/activity/activity';
import { ActivityService } from '@shared/services/activity/activity.service';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-activity-component',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent {
  get CONSTANTS() {
    return this.constantsService.constants.MY_CHIRPY;
  }

  activity$: Observable<any>;
  IMPORTANT: string[] = ['catchupCreatedNotifyMember', 'catchupCancelledNotifyMember', 'socialCreatedNotifyNearby', 'socialCancelledNotifyMember'];
  loadingMessage: string = `Loading`;
  noActivityMessage: string = `No recent activity`;
  pageOpenedTimestamp: number = 0;

  constructor(private activityService: ActivityService, private analyticsService: AnalyticsService, private authService: AuthService, private constantsService: ConstantsService) {}

  ionViewWillEnter() {
    this.pageOpenedTimestamp = Date.now();
    if (!this.authService.isImpersonating) {
      this.activityService.updateActivityLastRead(this.authService._userProfileSubject.value.uid);
      this.analyticsService.eventTrack(AnalyticsCategory.MY_CHIRPY, AnalyticsAction.MY_CHIRPY_VIEW_ACTIVITY);
    }
  }

  ionViewWillLeave() {
    if (!this.authService.isImpersonating) {
      this.activityService.updateActivityLastRead(this.authService._userProfileSubject.value.uid);
    }
  }

  isImportant(activity: IActivity): boolean {
    return this.IMPORTANT.includes(activity.notificationType);
  }

  ngOnInit() {
    this.activity$ = this.activityService.getActivityFeed();
  }

  onClick(activity: IActivity) {
    this.analyticsService.eventTrack(AnalyticsCategory.MY_CHIRPY, AnalyticsAction.MY_CHIRPY_CLICK_ACTIVITY, activity.notificationType);
  }
}
