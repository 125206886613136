import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ComposeComponent } from './compose/compose.component';
import { ComposeService } from './compose/compose.service';
import { ThreadDetailComponent } from './thread-detail/thread-detail.component';
import { ThreadDetailService } from './thread-detail/thread-detail.service';
import { ThreadListComponent } from './thread-list/thread-list.component';
import { ThreadMembersComponent } from './thread-members/thread-members.component';
import { ThreadSettingsComponent } from './thread-settings/thread-settings.component';
import { ThreadsRoutingModule } from './threads-routing.module';

@NgModule({
  imports: [SharedModule, ThreadsRoutingModule],
  declarations: [ComposeComponent, ThreadDetailComponent, ThreadListComponent, ThreadMembersComponent, ThreadSettingsComponent],
  providers: [ComposeService, ThreadDetailService],
  exports: [ComposeComponent, ThreadDetailComponent, ThreadListComponent, ThreadMembersComponent, ThreadSettingsComponent]
})
export class ThreadsModule {}
