import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { UserObject } from '@shared/models/user-object';
import { BaseDatabase } from '@shared/services/base.database';
import { CacheService } from '@shared/services/cache.service';
import { finalize, skipWhile, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MemberProfileEditDatabase extends BaseDatabase {
  constructor(afs: AngularFirestore, cache: CacheService, private storage: AngularFireStorage) {
    super(afs, cache);
  }

  async displayNameExists(uid: string, displayName: string, country: string): Promise<boolean> {
    const queryFn = ref => ref.where('country', '==', country).where('searchName', '==', displayName.toLowerCase());

    const promise = new Promise<boolean>(resolve => {
      this.getDocumentsByQuery<UserObject>(this.COLLECTION.CENTRAL_MEMBERS, queryFn)
        .pipe(
          skipWhile(x => x == null),
          take(1)
        )
        .subscribe((members: UserObject[]) => {
          const existingMember = members.find(d => d.uid !== uid && d.displayName.toLowerCase() === displayName.toLowerCase());
          resolve(existingMember != null);
        });
    });

    return promise;
  }

  uploadProfilePhoto(uid: string, file: any) {
    const filePath = `member/${uid}/avatar`;
    const fileRef = this.storage.ref(filePath);
    const promise = new Promise<string>(resolve => {
      return this.storage
        .upload(filePath, file)
        .snapshotChanges()
        .subscribe();
    });

    return promise;
  }
}
