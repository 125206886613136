import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ILastAction } from '@shared/models/last-action/last-action';
import { IColumn } from '@shared/models/column';
import { DateTimeService } from '@shared/services/date-time.service';
import { LastActionService } from '@shared/services/last-action/last-action.service';
import { map } from 'rxjs/operators';
import { IInput } from '@shared/models/input';
import { BaseReportComponent } from '../../base-report/base-report.component';

@Component({
  selector: 'app-reports-last-activity-by-date',
  templateUrl: '../../base-report/base-report.component.html'
})
export class LastActivityByDateComponent extends BaseReportComponent {
  columns = [
    { label: 'Date', field: 'date', class: 'data-table__column-responsive-bold' },
    { label: 'Name', field: 'displayName' },
    { label: 'Email', field: 'email' },
    { label: 'Plan', field: 'membershipType' },
    { label: 'Action', field: 'action' }
  ];
  config = {
    routerLink: {
      path: '/members',
      id: [':key']
    }
  };
  dateTo: string = '';
  parametersHeader = 'Select dates';
  parametersMessage = `<p>Enter the cut-off date for member's last activity on the site</p>`;
  parametersInputs: IInput[] = [
    {
      label: 'Date: ',
      name: 'dateTo',
      placeholder: 'YYYY-MM-DD',
      type: 'date'
    }
  ];
  instructions = '<p>Click on "select parameters" to choose a date for the report</p>';
  title = 'Last Activity Report by Date';

  constructor(alertController: AlertController, private dateTimeService: DateTimeService, private lastActionService: LastActionService) {
    super(alertController);
  }

  loadData() {
    if (!this.dateTo) return;

    this.data$ = this.lastActionService.getLastActionBeforeDate(this.dateTo).pipe(
      map(actions => {
        return actions.map(action => {
          const date = this.dateTimeService.formatDate(action.dateTime, 'D MMM YYYY h:mm a');
          return Object.assign({}, action, { date });
        });
      })
    );
  }

  selectParametersHandler(data: any) {
    this.dateTo = data.dateTo;
    this.parametersInputs[0].value = this.dateTo;
    this.loadData();
  }
}
