import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { EnforceProfileService } from '@shared/services/enforce-profile.service';
import { ThreadDetailCatchupService } from '@shared/services/messages/thread-detail-catchup.service';
import { UIService } from '@shared/services/ui.service';
import { ViewType } from './models/view-type';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.page.html',
  styleUrls: ['./messages.page.scss']
})
export class MessagesPage implements OnInit {
  get virtualCatchup(): string {
    return this.hasDetail === false ? '' : this.threadDetailCatchupService.getCatchup();
  }

  hasDetail: boolean;
  type: ViewType;

  constructor(private cd: ChangeDetectorRef, private enforceProfileService: EnforceProfileService, private route: ActivatedRoute, private uiService: UIService, private router: Router, private authService: AuthService, private threadDetailCatchupService: ThreadDetailCatchupService) {}

  ionViewWillEnter() {
    this.enforceProfileService.enforce();
  }

  ngOnInit() {
    //Get current parameters immediately
    this.parseRoute(this.router.url);

    //Listen for changes to the ActivatedRoute
    this.route.url.subscribe(parentRoute => {
      this.parseRoute(this.router.url);
      //This seems to be necessary, otherwise the changes to ion-hide class don't take effect
      this.cd.detectChanges();
    });
  }

  parseRoute(url: string): void {
    const segments = url.split('/');

    if (!segments.length) {
      this.type = ViewType.Thread;
      this.hasDetail = false;
      return;
    }

    let detailSegmentIndex = 3;
    switch (segments[2]) {
      case 'contacts':
        this.type = ViewType.Contact;
        break;

      case 'more':
        this.type = segments[3] === 'archive' ? ViewType.Archive : ViewType.More;
        detailSegmentIndex = 4;
        break;

      case 'threads':
        this.type = ViewType.Thread;
        break;

      default:
        this.type = null;
        break;
    }

    this.hasDetail = typeof segments[detailSegmentIndex] === 'undefined' ? false : true;
  }

  canShowDetailView(): boolean {
    return this.authService._userProfileSubject.value != null;
  }

  canShowVirtualCatchup(): boolean {
    return this.type === ViewType.Thread;
  }

  showDetailView(): boolean {
    // Hide detail view when VirtualCatchUp is viewed on mobile
    if (!this.uiService.isMediumDisplay && this.virtualCatchup !== '') return false;

    return this.hasDetail !== false;
  }

  showMasterView(): boolean {
    return (this.uiService.isMediumDisplay && this.virtualCatchup === '') || this.hasDetail === false;
  }

  showVirtualCatchup(): boolean {
    return this.virtualCatchup !== '';
  }
}
