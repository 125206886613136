import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { Direction } from '@shared/constants/direction';
import { IMedia } from '@shared/models/media/media';
import { IMessageData } from '@shared/models/messages/message-data';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { CommentService } from '@shared/services/comments/comment.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { PhotoService } from '@shared/services/photos/photo.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from 'rxjs';
import { ChirpyPhotoDetailPresenter } from './chirpy-photo-detail.presenter';

@Component({
  selector: 'chirpy-photo-detail',
  templateUrl: './chirpy-photo-detail.component.html',
  styleUrls: ['./chirpy-photo-detail.component.scss'],
  viewProviders: [ChirpyPhotoDetailPresenter]
})
export class ChirpyPhotoDetailComponent implements OnInit {
  canEditPhoto: boolean = false;
  canReviewPhoto: boolean = false;
  canViewPhoto: boolean = false;
  @Input() collectionId: string;
  @Input() collectionTitle: string;
  @Input() collectionType: MediaCollectionType;
  dateTimeFormat: string;
  readonly Direction = Direction;
  @Input() defaultHref: string;
  editButtonText: string = `Add caption`;
  editCaptionForm: any;
  errorMessage: string;
  @Input() isAdmin: boolean;
  isEditing: boolean = false;
  loadingPhoto: boolean = true;
  newerPhoto: string;
  newerPhotoRef: Subscription;
  noCommentsText: string = `No comments yet`;
  olderPhoto: string;
  olderPhotoRef: Subscription;
  photo: IMedia;
  @Input() photoId: string;
  photoRef: Subscription;
  uploader: UserObject;
  uploaderRef: Subscription;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private commentService: CommentService,
    private dateTimeService: DateTimeService,
    private photoService: PhotoService,
    private presenter: ChirpyPhotoDetailPresenter,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService
  ) {}

  deletePhoto() {
    this.photoService.deletePhoto(this.photo, this.route);
  }

  getNewerPhotoLink() {
    this.subscriptionService.clearSubscription(this.newerPhotoRef);
    const key = this.photoService.getCollectionKey(this.collectionId, this.collectionType);
    this.newerPhotoRef = this.photoService.getAdjacentPhoto(key, this.photo.dateTimeUploaded, Direction.NEXT, this.canReviewPhoto).subscribe(uid => {
      if (uid == null) {
        this.newerPhoto = null;
      } else {
        this.newerPhoto = uid;
      }
    });
    this.subscriptionService.add(this.newerPhotoRef);
  }

  getOlderPhotoLink() {
    this.subscriptionService.clearSubscription(this.olderPhotoRef);
    const key = this.photoService.getCollectionKey(this.collectionId, this.collectionType);
    this.olderPhotoRef = this.photoService.getAdjacentPhoto(key, this.photo.dateTimeUploaded, Direction.PREV, this.canReviewPhoto).subscribe(uid => {
      if (uid == null) {
        this.olderPhoto = null;
      } else {
        this.olderPhoto = uid;
      }
    });
    this.subscriptionService.add(this.olderPhotoRef);
  }

  getPhoto(uid: string) {
    this.loadingPhoto = true;
    this.photo = null;
    this.subscriptionService.clearSubscription(this.photoRef);
    this.photoRef = this.photoService.getPhoto(uid).subscribe(photo => {
      this.loadingPhoto = false;
      this.canReviewPhoto = this.authService.isAdmin();
      this.canViewPhoto = this.canReviewPhoto || (photo != null && photo.isHidden !== true);

      if (photo == null || !this.canViewPhoto) {
        this.errorMessage = `Sorry, this photo either does not exist, has been deleted, or is being reviewed by our Support team.`;
        return;
      }
      this.errorMessage = '';
      this.photo = photo;
      this.dateTimeFormat = this.dateTimeService.getDateFormat(photo.dateTimeUploaded);
      this.editButtonText = photo.caption != null && photo.caption.length > 0 ? `Edit caption` : `Add caption`;
      this.getOlderPhotoLink();
      this.getNewerPhotoLink();
      this.getUploader(photo.uploaderId);
    });
    this.subscriptionService.add(this.photoRef);
  }

  getUploader(uid: string) {
    this.canEditPhoto = !!this.isAdmin; // if for some reason uploader doesn't exist, still let admins/hosts edit it
    this.subscriptionService.clearSubscription(this.uploaderRef);
    this.uploaderRef = this.photoService.getUploader(uid).subscribe(uploader => {
      if (uploader == null) {
        return;
      }
      this.uploader = uploader;
      this.canEditPhoto = !!this.isAdmin || (this.photo != null && this.authService.memberId != null && this.photo.uploaderId === this.authService.memberId);
    });
    this.subscriptionService.add(this.uploaderRef);
  }

  hidePhoto() {
    this.photoService.updateHiddenStatus(this.photo.uid, true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.photoId && changes.photoId.currentValue !== changes.photoId.previousValue) {
      this.getPhoto(this.photoId);
      this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_VIEW_DETAIL, this.photoId, { type: this.collectionTitle });
    }
  }

  ngOnInit() {
    this.editCaptionForm = this.presenter.form;
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.newerPhotoRef);
    this.subscriptionService.clearSubscription(this.olderPhotoRef);
    this.subscriptionService.clearSubscription(this.photoRef);
    this.subscriptionService.clearSubscription(this.uploaderRef);
  }

  onAddComment(messageData: IMessageData) {
    this.commentService.addComment(messageData.text, this.photoId, this.collectionId, this.collectionTitle, this.collectionType).then(() => {
      this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_ADD_COMMENT, this.photoId, { type: this.collectionTitle });
    });
  }

  onCancel() {
    this.presenter.setFormValue(this.photo.caption); //Reset value in ion-textarea
    this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_CANCEL_EDIT_CAPTION, this.photoId, { type: this.collectionTitle });
    this.onToggleEdit();
  }

  onClickArrow(direction: Direction) {
    this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_CLICK_ARROW, direction as string, { type: this.collectionTitle });
  }

  onSubmit() {
    const { caption } = this.presenter.submit(); // TODO: Sanitise?
    this.photo.caption = caption; //Update UI immediately
    // TODO: Create new function that updates and propagates?
    this.photoService.updatePhoto(this.photo.uid, { caption: caption }).then(() => {
      const increments = {};
      for (const collection of this.photo.collections) {
        increments[collection] = 0;
      }
      this.photoService.updateCollections(this.photo, increments);
    });
    this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_EDIT_CAPTION, this.photoId, { type: this.collectionTitle });
    this.onToggleEdit();
  }

  onToggleEdit() {
    this.isEditing = !this.isEditing;
  }

  reportPhoto() {
    this.photoService.reportPhoto(this.photo.uid, this.authService.member, this.route);
    this.analyticsService.eventTrack(AnalyticsCategory.PIX, AnalyticsAction.PIX_CLICK_REPORT, this.photoId, { type: this.collectionTitle });
  }

  unhidePhoto() {
    this.photoService.updateHiddenStatus(this.photo.uid, false);
  }
}
