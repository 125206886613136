import { NgModule } from '@angular/core';
import { AdminGuard } from '@shared/guards/admin-guard';
import { SharedModule } from '@shared/shared.module';
import { AdminPlayersPage } from './admin/admin-players/admin-players.page';
import { AdminPage } from './admin/admin.page';
import { BingoRoutingModule } from './bingo-routing.module';
import { BingoBallComponent } from './components/bingo-ball/bingo-ball.component';
import { BingoGameOverComponent, GameCancelledCardComponent, GameOverCardComponent, GameWaitingCardComponent } from './game/components';
import { GamePage } from './game/game.page';
import { BingoHelpNotesComponent, BingoLeaderBoardComponent, BingoListGamesComponent } from './start/components';
import { StartPage } from './start/start.page';

@NgModule({
  imports: [BingoRoutingModule, SharedModule],
  entryComponents: [BingoGameOverComponent, BingoHelpNotesComponent],
  declarations: [
    // Shared components
    BingoBallComponent,
    // Admin
    AdminPage,
    AdminPlayersPage,
    // Start
    StartPage,
    BingoHelpNotesComponent,
    BingoLeaderBoardComponent,
    BingoListGamesComponent,
    // Game
    GamePage,
    BingoGameOverComponent,
    GameCancelledCardComponent,
    GameOverCardComponent,
    GameWaitingCardComponent
  ],
  providers: [AdminGuard]
})
export class BingoPageModule {}
