import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IAnnouncement } from '@shared/models/advertisers/announcement';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementEditPresenter {
  form = this.formBuilder.group({
    title: [[], Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}

  announcement() {
    let formValue = this.form.value;
    formValue.title = (formValue.title || '').trim();
    return formValue;
  }

  setValue(announcement: IAnnouncement) {
    this.form.setValue({
      title: announcement.title
    });
  }
}
