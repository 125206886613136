import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { CancellationOption } from '@shared/constants/cancellation-option';
import { Country } from '@shared/constants/country';
import { IChargebeeSubscription } from '@shared/models/chargebee-subscription';
import { DateTimeService } from '@shared/services/date-time.service';
import { CancelSubscriptionDatabase } from './cancel-subscription.database';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CancelSubscriptionService {
  get option() {
    return this._option;
  }

  set option(value: CancellationOption) {
    this._option = value;
  }

  _option: CancellationOption;
  subscriptions: IChargebeeSubscription[];

  constructor(private database: CancelSubscriptionDatabase, private fns: AngularFireFunctions, private dateTimeService: DateTimeService) {}

  getPage(uid: string, country: Country) {
    return this.database.getPage(uid).pipe(
      map(page => {
        if (!page || !page.data) return []; // TODO Show something here?
        if (page.data[country]) return page.data[country];
        return page.data['ALL'] || [];
      })
    );
  }

  // TODO: Return type should be Promise<IChargebeeSubscription[]> but this gives type errors about void not assignable to IChargebeeSubscription[]
  getSubscriptionDetails(data: any): Promise<any> {
    const callable = this.fns.httpsCallable('getSubscriptionDetails');
    return callable(data)
      .toPromise()
      .then(results => {
        this.subscriptions = (results || [])
          .map(x => x.subscription)
          .map(subscription => {
            if (subscription.next_billing_at) {
              subscription.next_billing = this.dateTimeService.formatDate(subscription.next_billing_at * 1000); // Chargebee timestamp is in seconds not milliseconds
            }
            if (subscription.cancelled_at) {
              subscription.next_billing = this.dateTimeService.formatDate(subscription.cancelled_at * 1000); // Chargebee timestamp is in seconds not milliseconds
            }
            subscription.plan_unit_price = subscription.plan_unit_price / 100; // Convert from cents to dollars so we don't have to do calculations in email template
            return subscription;
          });
        return this.subscriptions;
      })
      .catch(err => {
        console.log(err);
      });
  }
}
