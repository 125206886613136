import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AvatarSize } from '@shared/constants/avatar-size';
import { INotice } from '@shared/models/groups/notice';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { GroupService } from '@shared/services/groups/group.service';
import { UIService } from '@shared/services/ui.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-group-notices',
  templateUrl: './group-notices.page.html'
})
export class GroupNoticesPage {
  avatarSize: AvatarSize = AvatarSize.MEDIUM;
  canEditNotices$: Observable<boolean>;
  noNoticesMessage: string = `Sorry, this group does not have any notices at the moment.`;
  notices$: Observable<INotice[]>;
  groupId: string;

  constructor(private analyticsService: AnalyticsService, private groupService: GroupService, private route: ActivatedRoute, public uiService: UIService) {}

  ionViewWillEnter() {
    this.groupId = this.route.snapshot.paramMap.get('id');
    this.notices$ = this.groupService.getGroupNotices(this.groupId);
    this.canEditNotices$ = this.groupService.canManageGroup$(this.groupId);
    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_VIEW_NOTICES, this.groupId);
  }
}
