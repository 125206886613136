import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { IGroup } from '@shared/models/groups/group';
import { GroupService } from '@shared/services/groups/group.service';
import { of, Observable } from 'rxjs';

@Component({
  selector: 'pix',
  templateUrl: './pix.component.html'
})
export class PixComponent {
  buttonLabel: string = 'Upload media';
  canManageCollection$: Observable<boolean> = of(false);
  canUploadPhotos$: Observable<boolean>;
  collectionType: MediaCollectionType = MediaCollectionType.GROUP;
  readonly MAX_PHOTOS: number = 3;
  @Input() group: IGroup;
  @Input() heading: string = 'Group Pix';
  @Input() isAdmin: boolean;
  @Input() isGroupMember: boolean;
  @Output() linkClicked = new EventEmitter();
  @Input() noPhotosMessage: string;
  viewAllLabel: string = 'View all Pix';

  constructor(private groupService: GroupService) {}

  ngOnInit() {
    // For the moment each group has one collection. If we allow multiple collections, needs to use different way of specifying who has admin privileges
    this.canUploadPhotos$ = this.groupService.canManageGroup$(this.group.uid);
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }
}
