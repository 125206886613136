import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AccessStatus } from '@shared/constants/access-status';
import { AvatarSize } from '@shared/constants/avatar-size';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EmailService } from '@shared/services/email/email.service';
import { UserService } from '@shared/services/user/user.service';

@Component({
  selector: 'app-romance-intro',
  styleUrls: ['./romance-intro.page.scss'],
  templateUrl: './romance-intro.page.html'
})
export class RomanceIntroPage {
  get CONSTANTS() {
    return this.constantsService.constants.ROMANCE.INTRO;
  }

  readonly AccessStatus = AccessStatus;
  size: AvatarSize = AvatarSize.XLARGE;

  constructor(private alertController: AlertController, private analyticsService: AnalyticsService, public authService: AuthService, private constantsService: ConstantsService, private emailService: EmailService, private router: Router, private userService: UserService) {}

  ionViewWillEnter() {
    this.analyticsService.eventTrack(AnalyticsCategory.ROMANCE, AnalyticsAction.ROMANCE_VIEW_INTRO);
  }

  async onRegister() {
    this.analyticsService.eventTrack(AnalyticsCategory.ROMANCE, AnalyticsAction.ROMANCE_INTRO_BUTTON, this.CONSTANTS.submitButton);

    if (this.CONSTANTS.requireListing) {
      //userService.requestRomanceAccess is called when the listing is first saved
      this.router.navigate(['/romance/create/', this.authService._userProfileSubject.value.uid]);
    } else {
      this.userService.requestRomanceAccess(this.authService._userProfileSubject.value.uid);
      this.emailService.sendRomanceRegistrationRequest(this.authService._userProfileSubject.value);

      const alert = await this.alertController.create({
        cssClass: 'wide-select',
        header: this.CONSTANTS.alert.header,
        message: this.CONSTANTS.alert.message,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: this.CONSTANTS.alert.button,
            role: 'OK'
          }
        ]
      });

      await alert.present();
    }
  }
}
