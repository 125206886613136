import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { EventType } from '@infrastructure/constants/event-type';
import { GroupType } from '@shared/constants/group-type';
import { IGroup } from '@shared/models/groups/group';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';

@Component({
  selector: 'groups-box',
  styleUrls: ['./groups-box.component.scss'],
  templateUrl: './groups-box.component.html'
})
export class GroupsBoxComponent implements OnInit {
  get ASSETS() {
    return this.constantsService.constants.ASSETS;
  }

  public readonly EventType = EventType;
  isFirst: boolean = true;
  groups$: Observable<IGroup[]>;
  @Input() groupType: GroupType;
  @Input() link: string;
  @Output() linkClicked = new EventEmitter();
  @Input() placeholder: string;
  @Output() hasNoEvents = new EventEmitter();
  userId: string;

  constructor(private authService: AuthService, private constantsService: ConstantsService, private groupService: GroupService) {}

  ngOnInit() {
    this.authService._userProfileSubject.pipe(first(x => x != null)).subscribe(member => {
      this.userId = member.uid;
      this.groups$ = this.groupService.getGroupsWithNoticesById(member.catchupGroupIds, this.groupType).pipe(
        tap(groups => {
          if (this.isFirst) {
            this.isFirst = false;
          } else {
            this.hasNoEvents.emit((groups || []).length === 0);
          }
        })
      );
    });
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }

  onLinkClicked(type: string) {
    this.linkClicked.emit(`Next ${type}`);
  }
}
