import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SharedModule } from '@shared/shared.module';
import { MeetingPlaceRoutingModule } from './meeting-place-routing.module';
import { MeetingPlaceListUserProfilesComponent, MeetingPlaceMemberProfileComponent, MeetingPlaceMembersLocatorComponent, MeetingPlaceSearchComponent } from './meeting-place/components';
import { MeetingPlaceMembersLocatorService } from './meeting-place/components/members-locator/members-locator.service';
import { MeetingPlaceMembersLocatorPinComponent } from './meeting-place/components/members-locator/pin/pin.component';
import { MeetingPlacePage } from './meeting-place/meeting-place.page';
import { MeetingPlaceService } from './meeting-place/services/meeting-place.service';

@NgModule({
  declarations: [MeetingPlacePage, MeetingPlaceListUserProfilesComponent, MeetingPlaceMembersLocatorComponent, MeetingPlaceSearchComponent, MeetingPlaceMemberProfileComponent, MeetingPlaceMembersLocatorPinComponent],
  providers: [MeetingPlaceService, MeetingPlaceMembersLocatorService],
  imports: [SharedModule, LeafletModule, MeetingPlaceRoutingModule],
  exports: [MeetingPlaceRoutingModule, MeetingPlaceListUserProfilesComponent, MeetingPlaceMembersLocatorComponent, MeetingPlaceSearchComponent, MeetingPlaceMemberProfileComponent],
  entryComponents: [MeetingPlaceMembersLocatorPinComponent, MeetingPlaceSearchComponent]
})
export class MeetingPlaceModule {}
