import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventType } from '@infrastructure/constants/event-type';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { ICatchupTemplate } from '@shared/models/catchups/catchup-template';
import { ISelectOption } from '@shared/models/select-option';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { CatchupTypeService } from '@shared/services/catchups/catchup-types.service';
import { RegionService } from '@shared/services/regions/region.service';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { UIService } from '@shared/services/ui.service';
import { UserService } from '@shared/services/user/user.service';
import { first, switchMap, tap } from 'rxjs/operators';
import { CatchupTemplateEditPresenter } from './catchup-template-edit.presenter';

@Component({
  selector: 'app-catchup-template-edit',
  templateUrl: './catchup-template-edit.page.html'
})
export class CatchupTemplateEditPage implements OnInit {
  // TODO: Is it better to just check if eventType === EventType.Virtual, to avoid injecting catchupTypeService
  get canShowAddress() {
    return this.catchupTypeService.canShowAddress(this.eventType);
  }

  get canShowRegion() {
    return this.catchupTypeService.canShowRegion(this.eventType);
  }

  get eventType() {
    return this.catchupTemplateEditPresenter.eventType();
  }

  get CONSTANTS() {
    return this.constantsService.constants.CATCHUPS.EDIT;
  }

  get form() {
    return this.catchupTemplateEditPresenter.form;
  }

  get isAdmin() {
    return this.authService.isAdmin([AdminRole.HOSTS]);
  }

  get isEdit() {
    return this.template != null && this.template.uid != null;
  }

  get searchCountry() {
    const allowOtherCountries = this.constantsService.constants.APP.allowOtherCountries;
    if (allowOtherCountries) return '';
    return this.member && !this.isAdmin ? this.member.country : '';
  }

  get title() {
    return this.isEdit ? `Update template` : `Create template`;
  }

  get updateButtonText() {
    return this.isEdit ? 'Update' : 'Create';
  }

  BRANDING: string;
  CatchupTypes: EventType[] = [];
  COUNTRIES: Record<string, Country> = {};
  groupId: string;
  groupName: string;
  hostsAndCohosts: Record<string, string> = {};
  INSTRUCTIONS: string;
  regionLabel: string = '';
  regions: ISelectOption[] = [];
  template: ICatchupTemplate;
  templateId: string;
  member: UserObject;

  constructor(
    private authService: AuthService,
    private catchupTemplateEditPresenter: CatchupTemplateEditPresenter,
    private catchupService: CatchupService,
    private catchupTypeService: CatchupTypeService,
    private constantsService: ConstantsService,
    private groupService: GroupService,
    private regionService: RegionService,
    private route: ActivatedRoute,
    private router: Router,
    private uiService: UIService,
    private userService: UserService
  ) {}

  getNumberOfRows() {
    return this.uiService.getNumberOfTextAreaRows();
  }

  ngOnInit() {
    this.BRANDING = this.constantsService.constants.CATCHUPS.branding;
    this.CatchupTypes = [EventType.CatchUp, EventType.Virtual];
    this.COUNTRIES = this.constantsService.constants.APP.countries;
    this.groupId = this.route.snapshot.paramMap.get('groupId');
    this.INSTRUCTIONS = this.constantsService.constants.CATCHUPS.TEMPLATE.instructions;
    this.templateId = this.route.snapshot.paramMap.get('templateId');

    this.authService._userProfileSubject
      .pipe(
        first(x => !!x) // if we don't read once, the subscribe callback can be triggered by profile updates during the template creation
      )
      .subscribe(async member => {
        if (member == null) return;
        this.member = member;

        this.regions = this.regionService.catchupRegions;
        this.regionLabel = this.regionService.regionLabel;

        // Creating new template
        if (this.templateId === 'new') {
          let eventType = EventType.CatchUp;

          // Pre-populate country from members profile
          const country = [this.member.country];

          this.template = {
            address: '',
            country,
            created: 0,
            description: this.CONSTANTS.populateDescription,
            endTime: '',
            eventType,
            name: '',
            ownerId: '',
            ownerName: '',
            region: '',
            rsvp: false,
            sharedGroups: {},
            time: '',
            title: '',
            uid: null
          } as ICatchupTemplate;

          this.catchupTemplateEditPresenter.setValue(this.template);

          this.groupService
            .getGroup(this.groupId)
            .pipe(first(x => !!x))
            .subscribe(group => {
              this.groupName = group.name;
              this.hostsAndCohosts = Object.assign({}, group.hosts, group.cohosts);

              // TODO: Check if co/host here too?

              // Pre-populate location from Group details.
              this.template.region = group.region;
              if (group.region.startsWith('Virtual')) {
                this.template.eventType = EventType.Virtual;
              }
              this.template.country = group.country;

              this.catchupTemplateEditPresenter.setValue(this.template);
            });

          return;
        }

        // Only get here if editing an existing template
        this.catchupService
          .getCatchupTemplate(this.groupId, this.templateId)
          .pipe(
            first(x => !!x),
            switchMap((template: ICatchupTemplate) => {
              this.template = template;
              return this.groupService.getGroup(this.groupId);
            }),
            first(x => !!x),
            tap(group => {
              this.groupName = group.name;
              this.hostsAndCohosts = Object.assign({}, group.hosts, group.cohosts);

              // Check whether you have permission to edit this template
              if (!this.isAdmin && !Object.keys(this.hostsAndCohosts).includes(this.member.uid)) {
                this.router.navigate(['/error/permission-denied']);
                return;
              }
            })
          )
          .subscribe(group => {
            this.catchupTemplateEditPresenter.setValue(this.template);
          });
      });
  }

  onAddGroup(item: Record<string, string>) {
    this.template.sharedGroups = this.template.sharedGroups || {};
    this.template.sharedGroups[item.key] = item.value;

    const names = Object.values(this.template.sharedGroups);
    this.catchupTemplateEditPresenter.form.controls.sharedGroups.setValue(names);
  }

  onRemoveGroup(item: Record<string, string>) {
    delete this.template.sharedGroups[item.key];
    const names = Object.values(this.template.sharedGroups);
    this.catchupTemplateEditPresenter.form.controls.sharedGroups.setValue(names);
  }

  searchGroups(startsWith: string = '') {
    return this.groupService.searchGroups(startsWith, this.searchCountry);
  }

  updateTemplate() {
    const formData = this.catchupTemplateEditPresenter.template();
    // Set other fields from lookups
    const ownerName = this.hostsAndCohosts[formData.ownerId] ? this.hostsAndCohosts[formData.ownerId] : null;
    const sharedGroups = this.template.sharedGroups || {}; // the form field only has the names of the groups, we want to save IDs too
    let templateData = Object.assign(formData, { ownerName, sharedGroups });

    this.catchupService.updateCatchupTemplate(this.groupId, this.template.uid, templateData, this.groupName);
  }
}
