import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { skipWhile, take } from 'rxjs/operators';
import { IMediaOptions } from '@shared/models/media/media-options';

@Injectable({
  providedIn: 'root'
})
export class PixSearchPresenter {
  form = this.formBuilder.group({
    category: ['']
  });

  constructor(private formBuilder: FormBuilder) {}

  reset() {
    this.setValue({ category: 'all' });
  }

  search() {
    return this.form.value;
  }

  setValue(mediaOptions: IMediaOptions) {
    this.form.setValue({
      category: mediaOptions.category || 'all'
    });
  }
}
