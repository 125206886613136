import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AuthService } from '@shared/services/auth.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { GameObject } from '../models/game-object';
import { LeaderboardPlayerObject } from '../models/leaderboard-player-object';
import { PlayerObject } from '../models/player-object';
import { RoundObject } from '../models/round-object';

@Injectable({
  providedIn: 'root'
})
export class GameService {
  private _gamesProfileSubject: BehaviorSubject<GameObject[]> = new BehaviorSubject(null);
  games: Observable<GameObject[]>;
  gamesSubscription: Subscription;
  private _playersSubject: BehaviorSubject<LeaderboardPlayerObject[]> = new BehaviorSubject(null);
  players: Observable<LeaderboardPlayerObject[]>;
  playersSubscription: Subscription;

  constructor(private authService: AuthService, private afs: AngularFirestore, private fns: AngularFireFunctions, private subscriptionService: SubscriptionService) {
    // Init Observers
    this.games = this._gamesProfileSubject.asObservable();
    this.players = this._playersSubject.asObservable();

    this.gamesSubscription = afs
      .collection('games/bingo/games', ref => ref.orderBy('created', 'asc'))
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const newGame: GameObject = new GameObject().deserialize(a.payload.doc.data());
            newGame.uid = a.payload.doc.id;
            return newGame;
          })
        )
      )
      .subscribe(parsed => this._gamesProfileSubject.next(parsed));
    this.subscriptionService.add(this.gamesSubscription);

    this.playersSubscription = afs
      .collection('players', ref => ref.orderBy('totalPoints', 'desc').limit(30))
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const newPlayer: LeaderboardPlayerObject = new LeaderboardPlayerObject().deserialize(a.payload.doc.data());
            newPlayer.uid = a.payload.doc.id;
            return newPlayer;
          })
        )
      )
      .subscribe(parsed => this._playersSubject.next(parsed));
    this.subscriptionService.add(this.playersSubscription);
  }

  async markNumber(gameId: string, num: number, roundId: string) {
    const markData = {
      gameId,
      number: num,
      roundId
    };
    const callable = this.fns.httpsCallable('markNumber');
    return callable(markData);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.gamesSubscription);
    this.subscriptionService.clearSubscription(this.playersSubscription);
  }

  bingo(game: GameObject) {
    const user = this.authService._userProfileSubject.value;
    if (user != null) {
      const callBingoData = {
        gameId: game.uid,
        playerId: user.uid,
        roundId: user.uid + '_' + game.currentRound
      };
      const callable = this.fns.httpsCallable('callBingo');
      return callable(callBingoData).toPromise();
    }
    return Promise.reject('Invalid User');
  }

  leaveGame(game: GameObject) {
    const user = this.authService._userProfileSubject.value;
    if (user != null) {
      const callBingoData = {
        gameId: game.uid,
        playerId: user.uid,
        roundId: user.uid + '_' + game.currentRound
      };
      const callable = this.fns.httpsCallable('leaveGame');
      return callable(callBingoData).toPromise();
    }
    return Promise.reject('Invalid User');
  }

  isAwaitingBingo(game: GameObject) {
    const user = this.authService._userProfileSubject.value;
    if (user != null && game.bingoPlayers !== undefined && game.bingoPlayers != null) {
      if (game.bingoPlayers.indexOf(user.uid) >= 0) {
        return true;
      }
    }
    return false;
  }

  loadPlayerForGame(gameId: string, userId: string): Observable<PlayerObject> {
    const gamePlayer = this.afs
      .collection('players')
      .doc(userId)
      .snapshotChanges()
      .pipe(
        map(action => {
          const newPlayer: PlayerObject = new PlayerObject().deserialize(action.payload.data());
          newPlayer.uid = action.payload.id;
          return newPlayer;
        })
      );
    return gamePlayer;
  }

  loadRoundsForPlayer(gameId: string, userId: string): Observable<RoundObject[]> {
    const rounds = this.afs
      .collection('games/bingo/games')
      .doc(gameId)
      .collection('Players')
      .doc(userId)
      .collection('Rounds')
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const newRound: RoundObject = new RoundObject().deserialize(a.payload.doc.data());
            newRound.uid = a.payload.doc.id;
            return newRound;
          })
        )
      );
    return rounds;
  }

  async saveGame(gameObject: GameObject): Promise<GameObject> {
    const user = this.authService._userProfileSubject.value;
    if (user != null) {
      gameObject.userId = user.uid;
      if (gameObject.uid) {
        // Update
        const uid = gameObject.uid;
        delete gameObject.uid; // This is not part of object
        return this.afs
          .collection('games/bingo/games')
          .doc(uid)
          .update({ ...gameObject })
          .then(updated => {
            gameObject.uid = uid;
            return gameObject;
          })
          .catch(err => {
            throw err;
          });
      } else {
        // Create
        delete gameObject.uid; // This is not part of object
        return this.afs
          .collection('games/bingo/games')
          .add({ ...gameObject })
          .then(saved => {
            gameObject.uid = saved.id;
            return gameObject;
          });
      }
    } else {
      throw new Error('Current User is NULL');
    }
  }

  joinGame(gameObject: GameObject) {
    const user = this.authService._userProfileSubject.value;
    if (user != null) {
      const playerData = {
        playerId: user.uid,
        playerName: user.displayName,
        gameId: gameObject.uid
      };
      const callable = this.fns.httpsCallable('joinGame');
      return callable(playerData).toPromise();
    }
    return Promise.reject('Invalid User');
  }
}
