import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { IColumn } from '@shared/models/column';
import { UserObject } from '@shared/models/user-object';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { UserService } from '@shared/services/user/user.service';
import { map, mergeMap } from 'rxjs/operators';
import { IInput } from '@shared/models/input';
import { BaseReportComponent } from '../../base-report/base-report.component';

@Component({
  selector: 'app-reports-coins-claimed-by-member',
  templateUrl: '../../base-report/base-report.component.html'
})
export class CoinsClaimedByMemberComponent extends BaseReportComponent {
  columns = [
    { label: 'Date', field: 'date', class: 'data-table__column-responsive-bold' },
    { label: 'CatchUp name', field: 'name' },
    { label: 'Group name', field: 'groupName' },
    { label: 'Country', field: 'country' }
  ];
  config = {
    routerLink: {
      path: '/catchups',
      id: [':uid']
    }
  };
  dateFrom: string = '';
  dateTo: string = '';
  hasParameters = false;
  instructions = 'No coins claims found in the last month';
  title = 'Coins claimed report by member';

  constructor(alertController: AlertController, private catchupService: CatchupService, private route: ActivatedRoute) {
    super(alertController);
    this.loadData(); // TODO: run this on ionViewWillEnter?
  }

  loadData() {
    const memberId = this.route.snapshot.paramMap.get('memberId');
    if (!memberId) return;
    // TODO: Retrieve entries from coinsTransactions, since catchups get deleted after a month?
    this.data$ = this.catchupService.getCatchupsForMember(memberId, [CatchupRsvpStatus.GOING_CLAIMED]);
  }
}
