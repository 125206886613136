import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { BaseAdminComponent } from '../base-admin/base-admin.component';

@Component({
  selector: 'app-set-password',
  templateUrl: '../base-admin/base-admin.component.html',
  styleUrls: ['../base-admin/base-admin.component.scss']
})
export class SetPasswordComponent extends BaseAdminComponent {
  confirmButton = 'Set';
  confirmHeader = 'Set password';
  confirmInputs = [
    {
      name: 'password',
      type: 'text',
      placeholder: 'Password (>6 chars)'
      // TODO when we move to Ionic 5 use the following
      /*
      attributes: {
        minlength: 6
      }
      */
    }
  ];
  placeholder = 'Search for a member to set their password';
  title = 'Set password';

  constructor(alertController: AlertController, private fns: AngularFireFunctions, private router: Router, private toastService: ToastService, private userService: UserService) {
    super(alertController);
  }

  confirmMessage(memberId: string, memberName: string) {
    return `<p>Set password for ${memberName}?</p><p>You will need to communicate this to them, otherwise they won't be able to log in.</p>`;
  }

  selectMemberHandler({ memberId, memberName, data }) {
    const memberData = {
      uid: memberId,
      password: data.password
    };

    let message = `Password changed for ${memberName}.`;

    const MIN_PASSWORD_LENGTH = 6;
    if (data.password.length < MIN_PASSWORD_LENGTH) {
      message = 'Password must be more than 6 characters.';
      this.toastService.presentToast(message);
      return;
    }

    const callable = this.fns.httpsCallable('setPassword');
    callable(memberData)
      .toPromise()
      .then(result => {
        //do nothing
      })
      .catch(err => {
        message = `Password changed for ${memberName}: ${err}`;
      })
      .finally(() => {
        this.userService.updateSetPasswordFlagByUid(memberId);
        this.toastService.presentToast(message);
        this.router.navigate(['/admin']);
      });
  }
}
