import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { ErrorsRoutingModule } from './errors-routing.module';
import { NotFoundPage } from './not-found/not-found.page';
import { PermissionDeniedPage } from './permission-denied/permission-denied.page';

@NgModule({
  imports: [ErrorsRoutingModule, SharedModule],
  declarations: [NotFoundPage, PermissionDeniedPage]
})
export class ErrorsModule {}
