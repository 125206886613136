export interface IEmailOptions {
  sentAfter: string;
  sentBefore: string;
  status: string;
  to: string;
  template: string[];
}

export const DefaultEmailOptions: IEmailOptions = {
  sentAfter: '',
  sentBefore: '',
  status: '',
  to: '',
  template: []
};
