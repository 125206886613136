import { Injectable } from '@angular/core';
import { IMemberThread } from '@shared/models/messages/member-thread';
import { IMemberThreadType } from '@shared/models/messages/member-thread-type';
import { UserObject } from '@shared/models/user-object';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import FieldValue = firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class SignupDisplayNameDatabase extends BaseDatabase {
  LIMIT_RECORDS: number = 5; // in principle the number of members with the same displayName should be 2, i.e. the number of countries

  getDisplayName(displayName: string): Observable<UserObject[]> {
    const whereConditions: IWhereCondition[] = [{ field: 'searchName', operator: '==', value: displayName.toLowerCase() }];

    const orderConditions: IOrderCondition[] = [];

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, this.LIMIT_RECORDS);
    return this.getDocumentsByQuery<UserObject>(this.COLLECTION.CENTRAL_MEMBERS, queryFn);
  }

  setDisplayName(displayName: string, uid: string) {
    return this.updateDocument(this.COLLECTION.CENTRAL_MEMBERS, uid, { displayName: displayName, searchName: displayName.toLowerCase() });
  }

  threadExists$(memberId: string, senderId: string): Observable<boolean> {
    const whereConditions: IWhereCondition[] = [
      { field: 'memberId', operator: '==', value: memberId },
      { field: 'relatedIds', operator: '==', value: [senderId] },
      { field: 'type', operator: '==', value: IMemberThreadType.DirectMessage }
    ];

    const orderConditions: IOrderCondition[] = [];

    const queryFn = this.createQueryFunction(whereConditions, orderConditions, this.LIMIT_RECORDS);
    return this.getDocumentsByQuery<IMemberThread>(this.COLLECTION.MESSAGES_MEMBER_THREADS, queryFn).pipe(map(results => results.length > 0));
  }
}
