import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICoinsLevel } from '@shared/models/coins/coins-level';
import { ICoinsTransaction } from '@shared/models/coins/coins-transaction';
import { UserObject } from '@shared/models/user-object';
import { DateTimeService } from '@shared/services/date-time.service';
import { AuthService } from '@shared/services/auth.service';
import { CoinsService } from '@shared/services/coins/coins.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'coins-box',
  styleUrls: ['./coins-box.component.scss'],
  templateUrl: './coins-box.component.html'
})
export class CoinsBoxComponent implements OnInit {
  coinsToNextLevel: number;
  level: ICoinsLevel;
  @Input() link: string;
  @Output() linkClicked = new EventEmitter();
  nextLevel: ICoinsLevel;
  @Input() placeholder: string;
  transactions$: Observable<ICoinsTransaction[]>;
  TRANSACTIONS_TO_FETCH: number = 1;
  verb: string = 'next';

  constructor(public authService: AuthService, private coinsService: CoinsService, private dateTimeService: DateTimeService) {}

  ngOnInit() {
    this.transactions$ = this.authService._userProfileSubject.pipe(
      switchMap((member: UserObject) => {
        if (member == null) return of([] as ICoinsTransaction[]);
        this.level = this.coinsService.getLevelByName(member.coinsLevel);
        this.nextLevel = this.coinsService.getNextLevel(this.level);
        this.coinsToNextLevel = this.coinsService.getCoinsToNextLevel(member, this.level, this.nextLevel);
        this.verb = this.level.name === this.nextLevel.name ? 'retain' : 'next';
        return this.coinsService.getTransactionsForMember(member.uid, member.coinsBalance, this.TRANSACTIONS_TO_FETCH);
      })
    );
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }
}
