import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '@shared/guards/admin-guard';
import { AdminOrHostOrCohostGuard } from '@shared/guards/admin-or-host-or-cohost-guard';
import { MembersGuard } from '@shared/guards/members-guard';
import { RoomShareEditPage } from './room-share-edit/room-share-edit.page';
import { RoomShareListPage } from './room-share-list/room-share-list.page';
import { RoomShareManagePage } from './room-share-manage/room-share-manage.page';
import { RoomShareMatchPage } from './room-share-match/room-share-match.page';
import { TripEditPage } from './trip-edit/trip-edit.page';
import { TripDetailPage } from './trip-detail/trip-detail.page';
import { TripInterestedPage } from './trip-interested/trip-interested.page';
import { TripRegisterPage } from './trip-register/trip-register.page';

export const routes: Routes = [
  {
    path: ':tripId',
    component: TripDetailPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Trip details'
    }
  },
  {
    path: 'register/:tripId',
    component: TripRegisterPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Register for trip'
    }
  },
  {
    path: 'room-share/:tripId',
    component: RoomShareListPage,
    canActivate: [MembersGuard], // TODO: Only let registered members view?
    data: {
      title: 'Room share'
    }
  },
  {
    path: 'room-share/manage/:tripId',
    component: RoomShareManagePage,
    canActivate: [AdminGuard],
    data: {
      title: 'Manage room share matches'
    }
  },
  {
    path: 'room-share/:tripId/match',
    component: RoomShareMatchPage,
    canActivate: [MembersGuard], // TODO: Only let registered members view?
    data: {
      title: 'Manage room share match'
    }
  },
  {
    path: 'room-share/:tripId/update',
    component: RoomShareEditPage,
    canActivate: [MembersGuard], // TODO: Only let registered members view?
    data: {
      title: 'Update room share'
    }
  },
  {
    path: 'update/:tripId',
    component: TripEditPage,
    canActivate: [AdminOrHostOrCohostGuard],
    data: {
      title: 'Update trip'
    }
  },
  {
    path: 'create/:groupId/:groupName',
    component: TripEditPage,
    canActivate: [AdminOrHostOrCohostGuard],
    data: {
      title: 'Update trip'
    }
  },
  {
    path: 'interested/:tripId',
    component: TripInterestedPage,
    canActivate: [AdminOrHostOrCohostGuard],
    data: {
      title: 'Trip attendees'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TripsRoutingModule {}
