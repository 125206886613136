// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  domainRegexp: '(dev\\.chirpyplus\\.com?\\.(nz|au)|localhost:)',
  name: 'dev',
  shortName: 'dev',
  firebase: {
    apiKey: 'AIzaSyCr_CZr_NNeWRYNjm58o2Uy71JiGMXsgcc',
    authDomain: 'chirpy-dev-ac30a.firebaseapp.com',
    publicURL: 'chirpy-dev-ac30a.firebaseapp.com',
    databaseURL: 'https://chirpy-dev-ac30a.firebaseio.com',
    projectId: 'chirpy-dev-ac30a',
    storageBucket: 'chirpy-dev-ac30a.appspot.com',
    messagingSenderId: '36944343794',
    appId: '1:36944343794:web:3d2057659a348a3f'
  },
  settings: {
    beaconId: 'b3957b70-ec99-42c2-8030-47aaae6de938',
    constants: 'chirpy',
    supportEmail: 'technical@chirpyplus.com',
    approveCatchupEmail: {
      AU: 'technical@chirpyplus.com',
      NZ: 'technical@chirpyplus.com'
    },
    approveContentEmail: 'technical@chirpyplus.com',
    hostSupportEmail: {
      AU: 'technicalau@chirpyplus.com',
      NZ: 'technicalnz@chirpyplus.com'
    },
    redirectTestEmail: 'philip@chirpyplus.co.nz',
    sentryDsn: 'https://0103b40eeda74f52be594a0acf78bd45@o374048.ingest.sentry.io/5191482',
    sentryEnabled: true,
    sentryEnvironment: 'Test',
    travelEmail: {
      ALL: 'technical@chirpyplus.com',
      AU: 'technicalau@chirpyplus.com',
      NZ: 'technicalnz@chirpyplus.com'
    },
    url: {
      AU: 'dev.chirpyplus.com.au',
      NZ: 'dev.chirpyplus.co.nz'
    }
  },
  gst: {
    trackingIds: ['G-DJ33EM0ZGX']
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
