import { FirebaseObject } from '@shared/models/firebase-object';

export class LeaderboardPlayerObject extends FirebaseObject {
  lastGameId: string;
  gamesPlayed: number;
  lastUpdated: number;
  playerId: string;
  totalPoints: number;

  deserialize(input: any): this {
    super.deserialize(input);
    Object.assign(this, input);
    return this;
  }
}
