import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { IListingImage } from '@shared/models/image/listing-image';
import { IMediaOptions } from '@shared/models/media/media-options';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { GroupService } from '@shared/services/groups/group.service';
import { PhotoService } from '@shared/services/photos/photo.service';
import { combineLatest, Observable, BehaviorSubject } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'chirpy-photo-list',
  styleUrls: ['./chirpy-photo-list.component.scss'],
  templateUrl: './chirpy-photo-list.component.html'
})
export class ChirpyPhotoListComponent implements OnInit {
  @Input() canManageCollection: Observable<boolean>;
  @Input() canViewPhotos: boolean = true;
  @Input() collectionId: string;
  @Input() collectionTitle: string;
  @Input() collectionType: MediaCollectionType;
  groupNames: Record<string, string> = {};
  hasMore: boolean;
  isLoading: boolean = true;
  @Input() isWidget: boolean = false;
  @Output() linkClicked = new EventEmitter();
  @Input() loadMore: boolean = false;
  @Input() maxPhotos: number;
  photos$: Observable<IListingImage[]>; // collection has essential details of photos saved as Record<uid,IListingImage>, rather than IMedia[]
  photosToShow$ = new BehaviorSubject<number>(9999);
  @Input() routerLinkPrefix: string;
  searchModel$ = new BehaviorSubject<IMediaOptions>({ category: 'all' });
  @Input()
  set searchModel(searchModel: IMediaOptions) {
    this.searchModel$.next(searchModel);
  }

  get searchModel() {
    return this.searchModel$.value;
  }
  @Input() showCollectionNames: boolean = false;

  constructor(private analyticsService: AnalyticsService, private groupService: GroupService, private photoService: PhotoService) {}

  filterMedia(media: IListingImage[], searchModel: IMediaOptions, photosToShow: number) {
    if (this.isLoading) this.isLoading = false;

    let filtered = media;
    if (searchModel.category !== 'all') {
      filtered = media.filter(x => x.category === searchModel.category);
    }
    // Check before slicing whether there are more images
    this.hasMore = this.loadMore && photosToShow < filtered.length ? true : false;
    filtered = filtered.slice(0, photosToShow);

    return filtered;
  }

  getRouterLink(collectionId: string, collectionName: string) {
    if (collectionId.startsWith('group_')) {
      // TODO: Is the group detail page or the group pix page more useful?
      return ['/groups', collectionId.replace(/^group_/, '')];
    }
    return '';
  }

  ngOnInit() {
    this.photosToShow$.next(this.maxPhotos);

    if (this.showCollectionNames) {
      this.groupService
        .getAllGroupNames(true)
        .pipe(first(x => !!x))
        .subscribe(groups => {
          for (const group of groups) {
            this.groupNames[`group_${group.uid}`] = group.name || null;
          }
        });
    }

    // If filtering collection
    const getMedia$ = this.canManageCollection.pipe(
      switchMap(canManageCollection => {
        return this.photoService.getPhotosInCollection(this.collectionId, this.collectionType, canManageCollection);
      })
    );

    this.photos$ = combineLatest(getMedia$, this.searchModel$, this.photosToShow$).pipe(map(([media, searchModel, photosToShow]) => this.filterMedia(media, searchModel, photosToShow)));

    // If querying collection
    /*
    this.photos$ = this.searchModel$.pipe(
      switchMap(searchModel => this.canManageCollection$()), // use observable searchModel$ to trigger new subscription, but use current value this.searchModel in switchMap below
      switchMap(canManageCollection => {
        return this.photoService.queryPhotosInCollection(this.collectionId, this.collectionType, this.searchModel, canManageCollection, this.maxPhotos);
      })
    );
    */
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }

  onLoadMore() {
    this.photosToShow$.next(this.photosToShow$.value + this.maxPhotos);
  }
}
