import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventType } from '@infrastructure/constants/event-type';
import { IContactFormSetting } from '@shared/models/contact-form-setting';
import { IDateOfBirth } from '@infrastructure/models/date-of-birth';
import { ITrip } from '@shared/models/trips/trip';
import { AnalyticsAction, AnalyticsCategory, IAnalyticsSetting } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { TripService } from '@shared/services/trips/trip.service';
import { UserService } from '@shared/services/user/user.service';
import { UserObject } from '@shared/models/user-object';
import { Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'app-trip-register',
  styleUrls: ['./trip-register.page.scss'],
  templateUrl: './trip-register.page.html'
})
export class TripRegisterPage implements OnInit, OnDestroy {
  trip: ITrip;
  tripSubscription: Subscription;
  analytics: IAnalyticsSetting = {
    action: AnalyticsAction.TRIPS_REGISTER,
    category: AnalyticsCategory.TRIPS,
    label: '' // set to trip.title once trip loaded
  };
  CONSTANTS: any;
  country: string = '';
  fields: IContactFormSetting[] = [
    {
      id: 'trip',
      placeholder: 'Enter trip name...',
      prePopulate: true,
      required: true,
      title: 'Trip'
    },
    {
      id: 'date',
      placeholder: 'Enter trip date...',
      prePopulate: true,
      required: true,
      title: 'Date'
    },
    {
      autoPopulate: true,
      id: 'fullName',
      isHidden: false,
      placeholder: 'Enter your name...',
      prePopulate: true,
      required: true,
      title: 'Your name'
    },
    {
      autoPopulate: true,
      id: 'displayName',
      placeholder: 'Enter your display name...',
      prePopulate: true,
      required: true,
      title: 'Display name'
    },
    {
      autoPopulate: true,
      id: 'email',
      isHidden: false,
      placeholder: 'Enter your email...',
      prePopulate: true,
      required: true,
      title: 'Email'
    },
    {
      autoPopulate: true,
      id: 'locality',
      placeholder: 'Enter your town/suburb...',
      prePopulate: true,
      required: true,
      title: 'Address'
    },
    {
      id: 'phone',
      placeholder: 'Enter your phone number...',
      required: true,
      title: 'Phone number (mobile preferred)'
    },
    {
      autoPopulate: true,
      id: 'dateOfBirth',
      isHidden: false,
      placeholder: 'Enter your date of birth...',
      prePopulate: true,
      required: true,
      title: 'Date of birth',
      type: 'date'
    },
    {
      id: 'roomType',
      options: ['Shared accommodation', 'Solo accommodation'],
      placeholder: 'Select your preferred room type...',
      required: true, // TODO: What if there are day trips?
      title: 'Accommodation',
      type: 'select'
    },
    {
      id: 'description',
      placeholder: 'Message to send to our travel team (optional)',
      required: false,
      title: 'Message'
    }
  ];
  instructions: string;
  isSubmitted: boolean = false;
  member: UserObject;
  params: Record<string, string>;
  recipient: string;
  subject: string;
  successMessage: string;
  tripId: string;

  constructor(private authService: AuthService, private tripService: TripService, private constantsService: ConstantsService, private route: ActivatedRoute, private subscriptionService: SubscriptionService, private userService: UserService) {}

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.tripSubscription);
  }

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.TRIPS.REGISTER;
    this.subject = this.CONSTANTS.subject;
    this.successMessage = this.CONSTANTS.successMessage;

    // Get trip ID from route
    this.tripId = this.route.snapshot.paramMap.get('tripId');

    // Load trip
    this.tripSubscription = this.tripService.getTrip(this.tripId).subscribe(async (trip: ITrip) => {
      if (trip == null || trip.eventType !== EventType.Trip) return;
      this.trip = trip;
      this.analytics.label = trip.title;
      this.params = { trip: this.trip.title, date: `${this.trip.date} – ${this.trip.date}` };
      this.recipient = `travel`;
      this.country = trip.adminEmail;
    });
    this.subscriptionService.add(this.tripSubscription);

    // Load member info, for auto-populating form
    this.authService._userProfileSubject
      .pipe(
        skipWhile(u => !u),
        take(1)
      )
      .subscribe(user => (this.member = user));
  }

  onSubmit(data: any) {
    this.isSubmitted = true;
    if (this.trip.uid !== '' && this.member.uid !== '') {
      this.tripService.registerForTrip(this.trip.uid, this.member.uid);
    }

    // Update profile with DOB. If this DOB is already in Firestore, this won't generate a write
    if (data.dateOfBirth) {
      const parts = data.dateOfBirth.split('-'); // displayed as YYYY-MM-DD - e.g. 1980-09-20
      const dateOfBirth: IDateOfBirth = {
        day: parts[2],
        month: parts[1],
        year: parts[0]
      };

      this.userService.updatePrivateUserField(this.member.uid, 'dateOfBirth', dateOfBirth);
    }
  }
}
