import { Injectable } from '@angular/core';
import { ICancellation } from '@shared/models/cancellations/cancellation';
import { ICancellationOptions } from '@shared/models/cancellations/cancellation-options';
import { IWhereCondition } from '@shared/models/where-condition';
import { IOrderCondition } from '@shared/models/order-condition';
import { Observable } from 'rxjs';
import { BaseDatabase } from '@shared/services/base.database';

@Injectable({
  providedIn: 'root'
})
export class CancellationDatabase extends BaseDatabase {
  getAllCancellations(search: ICancellationOptions, limit: number, lastTimestamp: number): Observable<ICancellation[]> {
    const queryFn = this.getAllCancellationsQuery(search, limit, lastTimestamp);
    return this.getDocumentsByQuery<ICancellation>(this.COLLECTION.CANCELLATIONS, queryFn);
  }

  saveCancellation(cancellation: ICancellation): Promise<any> {
    return this.createDocument(this.COLLECTION.CANCELLATIONS, cancellation);
  }

  private getAllCancellationsQuery(search: ICancellationOptions, recordsToFetch: number, lastTimestamp: number) {
    const whereConditions: IWhereCondition[] = [];

    // If we are loading more entries for the same search
    if (lastTimestamp != null) {
      // If we have already loaded results for the current search, then we always want results < lastTimestamp,
      // regardless of the search, because the results are ordered by startTime desc.
      whereConditions.push({ field: 'dateTimeCancelled', operator: '<', value: lastTimestamp });

      // TODO: Edge case - what if lastTimestamp is exactly equal to search.dateBefore?
      // Low probability because lastTimstamp has nanosecond precision while search.cancelledAfter only specifies seconds
    } else {
      if (search.cancelledBefore !== '') {
        const cancelledBefore = new Date(search.cancelledBefore).getTime();
        whereConditions.push({ field: 'dateTimeCancelled', operator: '<', value: cancelledBefore });
      }
    }

    if (search.cancelledAfter !== '') {
      const cancelledAfter = new Date(search.cancelledAfter).getTime();
      whereConditions.push({ field: 'dateTimeCancelled', operator: '>=', value: cancelledAfter });
    }

    if (search.reason !== '') {
      whereConditions.push({ field: 'reason', operator: '==', value: search.reason });
    }

    const orderConditions: IOrderCondition[] = [];
    orderConditions.push({ field: 'dateTimeCancelled', direction: 'desc' });
    return this.createQueryFunction(whereConditions, orderConditions, recordsToFetch);
  }
}
