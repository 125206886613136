import { Component, Input, OnInit } from '@angular/core';
import { AvatarSize } from '@shared/constants/avatar-size';

@Component({
  selector: 'chirpy-text-avatar',
  templateUrl: './chirpy-text-avatar.component.html',
  styleUrls: ['./chirpy-text-avatar.component.scss']
})
export class ChirpyTextAvatarComponent implements OnInit {
  abbreviatedText: string;
  @Input() border: boolean = false;
  @Input() abbreviateText: boolean;
  @Input() size: string = 'default';
  @Input() text: string;

  getAbbreviatedText() {
    if (this.text == null) {
      this.abbreviatedText = '';
    } else if (!this.abbreviateText) {
      this.abbreviatedText = this.text;
    } else {
      this.abbreviatedText = this.text
        .split(' ')
        .reduce((acronym, word) => (acronym += word.charAt(0).toUpperCase()), '')
        .slice(0, 3);
    }
  }

  ngOnInit() {
    this.getAbbreviatedText();
  }
}
