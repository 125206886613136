import { Component } from '@angular/core';
import { IContactFormSetting } from '@shared/models/contact-form-setting';
import { AnalyticsAction, AnalyticsCategory, IAnalyticsSetting } from '@shared/services/analytics';
import { ConstantsService } from '@shared/services/constants.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.page.html'
})
export class ContactUsPage {
  get CONSTANTS() {
    return this.constantsService.constants.CONTACT_US;
  }

  analytics: IAnalyticsSetting = {
    action: AnalyticsAction.FORMS_CONTACT,
    category: AnalyticsCategory.FORMS
  };

  fields: IContactFormSetting[] = [
    {
      id: 'name',
      title: 'Name',
      required: true,
      placeholder: 'Enter your name...'
    },
    {
      autoPopulate: true,
      id: 'email',
      title: 'Email',
      required: true,
      placeholder: 'Enter your email...'
    },
    {
      id: 'description',
      title: 'What can we help you with?',
      required: true,
      placeholder: 'What can we help you with?'
    }
  ];

  constructor(private constantsService: ConstantsService) {}
}
