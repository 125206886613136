import { Injectable } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UIService {
  get isAndroid() {
    return this.platform.is('android');
  }

  get isIOS() {
    return this.platform.is('ios');
  }

  // Default ionic breakpoints
  get isExtraLargeDisplay() {
    return this.platform.width() > 1199;
  }

  get isLargeDisplay() {
    return this.platform.width() > 991;
  }

  get isMediumDisplay() {
    return this.platform.width() > 767;
  }

  get isSmallDisplay() {
    return this.platform.width() > 575;
  }

  isExtraLargeDisplay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLargeDisplay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isMediumDisplay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSmallDisplay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showFooterSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private loadingController: LoadingController, private platform: Platform) {
    this.showFooterSubject.next(true);
    this.isExtraLargeDisplay$.next(this.isExtraLargeDisplay);
    this.isLargeDisplay$.next(this.isLargeDisplay);
    this.isMediumDisplay$.next(this.isMediumDisplay);
    this.isSmallDisplay$.next(this.isSmallDisplay);
    // Provide observable which updates on page resize events
    this.platform.resize.subscribe(x => {
      this.isExtraLargeDisplay$.next(this.isExtraLargeDisplay);
      this.isLargeDisplay$.next(this.isLargeDisplay);
      this.isMediumDisplay$.next(this.isMediumDisplay);
      this.isSmallDisplay$.next(this.isSmallDisplay);
    });
  }

  getNumberOfTextAreaRows() {
    // display textarea with 5 rows on mobile phones.
    // display textarea with 8 rows on iPads. this fits perfectly on iPad landscape.
    // display 14 rows on medium and larger screens.
    const iPad = this.platform.is('ipad');
    if (iPad) {
      return 8;
    }

    return this.isMediumDisplay ? 14 : 5;
  }

  hideFooter() {
    this.showFooterSubject.next(false);
  }

  hideLoadingOverlay() {
    this.loadingController.dismiss();
  }

  showFooter() {
    this.showFooterSubject.next(true);
  }

  showLoadingOverlay(message = null) {
    if (message == null) message = 'Loading...';

    return this.loadingController
      .create({
        message
      })
      .then(overlay => {
        return overlay.present();
      });
  }
}
