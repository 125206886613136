import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'chirpy-item-search',
  templateUrl: './chirpy-item-search.component.html',
  styleUrls: ['chirpy-item-search.component.scss']
})
export class ChirpyItemSearchComponent implements OnInit, OnDestroy {
  // don't show items you've already picked
  get availableItems() {
    const searchResults = {};
    Object.keys(this.searchResults).forEach(key => {
      if (this.selectedItems[key] == null) searchResults[key] = this.searchResults[key];
    });

    return searchResults;
  }

  @Input() addItem;
  @Input() canRemoveItems: boolean = true;
  @Input() memberType: IMemberThreadRelatedType = null;
  @Input() noResultsText: string;
  @Input() placeholder: string;
  populateSubscription: Subscription;
  @Input() removeItem;
  @Input() selectedItems: Record<string, string> = {};
  @Input() search;
  @ViewChild('searchBar', { static: false }) searchBar: IonSearchbar;
  searchResults: Record<string, string> = {}; // key, value
  searchSubscription: Subscription;
  showNoItemsFoundMessage = false;
  @Input() singleSelect: boolean;

  constructor(private modalController: ModalController, private subscriptionService: SubscriptionService) {}

  dismiss() {
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.searchSubscription);
    this.subscriptionService.clearSubscription(this.populateSubscription);
  }

  ngOnInit(): void {
    this.populateList();
  }

  onAddItem(item: any) {
    this.addItem(item);
    if (this.singleSelect) this.dismiss();
  }

  onRemoveItem(item: any) {
    this.removeItem(item);
  }

  onClearSearch() {
    this.clearSearchBar();
    this.populateList();
  }

  focusSearchBar(): void {
    if (this.searchBar) this.searchBar.setFocus();
  }

  onSearch(search: string) {
    this.showNoItemsFoundMessage = false;

    if (search.length < 2) {
      this.populateList();
      return;
    }

    // convert first letter to upper case because member names always captalized in Firebase and Firebase can only do case sensitive searches
    search = search[0].toUpperCase() + search.slice(1);

    this.subscriptionService.clearSubscription(this.searchSubscription);
    this.search(search).subscribe(items => this.processSearchResults(items));
    this.subscriptionService.add(this.searchSubscription);
  }

  populateList() {
    this.subscriptionService.clearSubscription(this.populateSubscription);
    this.search(null).subscribe(items => this.processSearchResults(items));
    this.subscriptionService.add(this.populateSubscription);
  }

  processSearchResults(items: any) {
    const searchResults: Record<string, string> = {};
    if (Array.isArray(items)) {
      items.forEach((item: string) => {
        searchResults[item] = item;
      });
    } else {
      Object.keys(items).forEach((item: string) => {
        searchResults[item] = items[item];
      });
    }

    this.searchResults = searchResults;
    this.focusSearchBar();
    this.showNoItemsFoundMessage = Object.keys(searchResults).length === 0;
  }

  private clearSearchBar(): void {
    this.searchBar.value = '';
  }
}
