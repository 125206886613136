import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthService } from '@shared/services/auth.service';
import { skipWhile, take } from 'rxjs/operators';
import { IMarketplaceOptions } from '@shared/models/marketplace/marketplace-options';
import { MarketplaceService } from '@shared/services/marketplace/marketplace.service';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceSearchPresenter {
  defaultMarketplaceSearch: IMarketplaceOptions;
  form = this.formBuilder.group({
    category: [''],
    country: [''],
    location: ['']
  });

  constructor(private formBuilder: FormBuilder, private marketplaceService: MarketplaceService, private authService: AuthService) {}

  patchValue(data: any) {
    this.form.patchValue(data);
  }

  reset() {
    this.setValue({ location: '', category: '', country: null });
  }

  search() {
    return this.form.value;
  }

  setValue(marketplaceOptions: IMarketplaceOptions) {
    this.form.setValue({
      location: marketplaceOptions.location,
      category: marketplaceOptions.category || '',
      country: marketplaceOptions.country || null
    });
  }
}
