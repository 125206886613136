import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { SelectType } from '@shared/constants/select-type';
import { IGroup } from '@shared/models/groups/group';
import { GroupType } from '@shared/constants/group-type';
import { IMapState } from '@shared/models/map-state';
import { ISelectOption } from '@shared/models/select-option';
import { IValueWithId } from '@shared/models/value-with-id';
import { IPlace } from '@shared/models/place';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { RegionService } from '@shared/services/regions/region.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EnforceProfileService } from '@shared/services/enforce-profile.service';
import { GroupService } from '@shared/services/groups/group.service';
import { LocationService } from '@shared/services/location/location.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { UIService } from '@shared/services/ui.service';
import { UserService } from '@shared/services/user/user.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { GroupLocatorComponent } from '../components/group-locator-map/group-locator.component';
import { GroupEditPresenter } from './group-edit.presenter';

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.page.html',
  styleUrls: ['./group-edit.page.scss'],
  viewProviders: [GroupLocatorComponent]
})
export class GroupEditPage implements OnDestroy {
  // Editor can edit description and image, only Hosts can edit other group details
  get canEditDetails() {
    return this.authService.isAdmin([AdminRole.HOSTS, AdminRole.TRAVEL]);
  }

  get canEditProtected() {
    return this.authService.isAdmin([AdminRole.SUPER]);
  }

  get canEditLocation() {
    return this.authService.isAdmin([AdminRole.HOSTS]);
  }

  get CONSTANTS() {
    return this.constantsService.constants.GROUPS.EDIT;
  }

  get groupTypes() {
    return this.constantsService.constants.GROUPS.GROUP_TYPES;
  }

  get form() {
    return this.groupEditPresenter.form;
  }

  get isEdit() {
    return this.group != null && this.group.uid != null;
  }

  get isSocialsEnabled() {
    return this.constantsService.constants.SOCIAL.enabled;
  }

  get isPhysicalGroup() {
    return this.group != null && !this.groupEditPresenter.group().region.startsWith('Virtual');
  }

  get title() {
    return this.isEdit || !!this.errorMessage ? this.CONSTANTS.updatePageHeading : this.CONSTANTS.createPageHeading;
  }

  get updateButtonText() {
    return this.isEdit ? this.CONSTANTS.updateButtonText : this.CONSTANTS.createButtonText;
  }

  catchupBranding: string;
  editButtonText: string = `Set location`;
  errorMessage: string;
  group: IGroup;
  groupAdvisors: Record<string, string> = {};
  groupCohosts: Record<string, string> = {};
  groupHosts: Record<string, string> = {};
  groupLocations: Record<string, string> = {};
  groupSubscription: Subscription;
  groups$: BehaviorSubject<IGroup[]> = new BehaviorSubject<IGroup[]>(null);
  initialLocation: Record<string, string>;
  interfaceOptions: any = { cssClass: 'wide-select' };
  isSmallMap: boolean = true;
  @ViewChild('locator', { static: false }) locator: any;
  pixBranding: string = 'Group Pix';
  placeSubscription: Subscription;
  places$: BehaviorSubject<IPlace[]> = new BehaviorSubject<IPlace[]>(null);
  regionLabel: string = '';
  regions: ISelectOption[] = [];
  selectType: string = SelectType.PLACE;
  socialBranding: string;
  private user: UserObject;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private groupService: GroupService,
    private regionService: RegionService,
    private constantsService: ConstantsService,
    private enforceProfileService: EnforceProfileService,
    private groupEditPresenter: GroupEditPresenter,
    private locationService: LocationService,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private uiService: UIService,
    private userService: UserService
  ) {}

  getNumberOfRows() {
    return this.uiService.getNumberOfTextAreaRows();
  }

  ionViewWillEnter() {
    this.catchupBranding = this.constantsService.constants.CATCHUPS.branding;
    this.socialBranding = this.constantsService.constants.SOCIAL.branding;
    this.enforceProfileService.enforce();
    this.loadGroup();
  }

  loadGroup() {
    const uid = this.route.snapshot.paramMap.get('id');

    this.authService._userProfileSubject
      .pipe(
        skipWhile(u => !u),
        take(1)
      )
      .subscribe(user => {
        this.user = user;

        this.regions = this.regionService.groupRegions;
        this.regionLabel = this.regionService.regionLabel;

        if (uid === null || uid === 'new') {
          this.group = {
            advisors: {},
            canShowCatchups: true,
            cohosts: {},
            created: 0,
            country: [this.user.country],
            description: '',
            groupType: GroupType.GROUP,
            locations: {},
            memberCount: 0,
            hosts: {},
            name: '',
            region: '',
            uid: null
          } as IGroup;
          this.groupEditPresenter.setValue(this.group);
          //this.groupEditPresenter.form.reset(); // TODO: Why was this necessary?
          return;
        }

        this.groupSubscription = this.groupService.getGroup(uid).subscribe((group: IGroup) => {
          // Doesn't exist, you're creating a new CatchUp.
          if (group == null) return;

          // Hosts can't edit someone else's Group, but Admin Editors can modify description/avatar image
          const canEdit = this.groupService.canManageGroup(group) || this.authService.isAdmin([AdminRole.EDITOR]);
          if (!canEdit) {
            this.errorMessage = `You don't have permission to update this group.`;
            return;
          }

          this.group = group;
          this.groupAdvisors = group.advisors;
          this.groupCohosts = group.cohosts;
          this.groupHosts = group.hosts;
          this.initialLocation = Object.assign({}, group.locations); // Save this so we can correctly unset flags if the location is updated

          // location can be null if the group has not been updated since this field was introduced, or empty string if the group has been update but the location is not set
          if (Object.keys(group.locations).length > 0) {
            this.editButtonText = `Edit location`;
            this.groupLocations = group.locations; // This needs to be set, even if canEditLocation is false, otherwise the location gets cleared in updateGroup
            if (this.canEditLocation) {
              this.updateMapInputs(this.group);
            }
          }

          this.groupEditPresenter.setValue(this.group);
        });
        this.subscriptionService.add(this.groupSubscription);
      });
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.groupSubscription);
    this.subscriptionService.clearSubscription(this.placeSubscription);
  }

  onAddAdvisorToGroup(member: Record<string, string>) {
    this.groupAdvisors[member.key] = member.value;

    this.updateFormWithAdvisorNames();
  }

  onAddCohostToGroup(member: Record<string, string>) {
    this.groupCohosts[member.key] = member.value;

    this.updateFormWithCohostNames();
  }

  onAddHostToGroup(member: Record<string, string>) {
    this.groupHosts[member.key] = member.value;

    this.updateFormWithHostNames();
  }

  onAddPlace(item: IValueWithId) {
    this.groupLocations[item.uid] = item.name;
    this.updateFormWithLocationNames();
    this.updateMapInputs(this.group);
    this.editButtonText = `Edit location`;
  }

  onMapBoundsChange(state: IMapState) {
    // TODO: Show other places/groups on map?
  }

  onRemoveAdvisorFromGroup(member: Record<string, string>) {
    if (this.groupAdvisors[member.key]) {
      delete this.groupAdvisors[member.key];

      this.updateFormWithAdvisorNames();
    }
  }

  onRemoveCohostFromGroup(member: Record<string, string>) {
    if (this.groupCohosts[member.key]) {
      delete this.groupCohosts[member.key];

      this.updateFormWithCohostNames();
    }
  }

  onRemoveHostFromGroup(member: Record<string, string>) {
    if (this.groupHosts[member.key]) {
      delete this.groupHosts[member.key];
      this.updateFormWithHostNames();
    }
  }

  onRemovePlace(item: Record<string, string>) {
    if (this.groupLocations[item.key]) {
      delete this.groupLocations[item.key];
      this.updateFormWithLocationNames();
      this.updateMapInputs(this.group);
    }
    if (Object.keys(this.groupLocations).length === 0) this.editButtonText = `Set location`;
  }

  searchAdvisors(startsWith: string) {
    return this.groupService.searchAdvisors(startsWith);
  }

  searchCohosts(startsWith: string) {
    return this.groupService.searchCohosts(startsWith);
  }

  searchHosts(startsWith: string) {
    return this.groupService.searchHosts(startsWith);
  }

  searchPlaces(startsWith: string) {
    return this.locationService.search(startsWith, this.user.country, this.authService.isAdmin([AdminRole.HOSTS]));
  }

  // Create a new group or Update existing group, depending whether catchup.uid is null.
  updateGroup() {
    // merge form values with non-form editable group values.
    const formValue = this.groupEditPresenter.group();
    Object.assign(this.group, formValue);

    if (!this.validateForm(this.group)) return;

    // convert first letter to upper case
    this.group.name = this.group.name[0].toUpperCase() + this.group.name.slice(1);

    // Copy Country for the chosen State/Region.
    const regionCountry = this.regionService.getCountryForRegion(this.group.region);
    this.group.country = regionCountry;

    // Save (co)host memberId's, it's reloaded as memberNames when the page loads.
    this.group.advisors = this.groupAdvisors || {};
    this.group.cohosts = this.groupCohosts || {};
    this.group.hosts = this.groupHosts || {};
    this.group.locations = this.groupLocations || {};

    // Remove location if this group is now virtual
    if (this.group.region.startsWith('Virtual')) {
      this.group.locations = {};
    }

    this.groupService.updateGroup(this.group);

    // Update hasGroup flag for locations
    const oldLocationIds = Object.keys(this.initialLocation || {});
    const newLocationIds = Object.keys(this.group.locations || {});

    // unset flag for removed locations
    if (oldLocationIds.length > 0 || newLocationIds.length > 0) {
      let oldLocationsOnly = oldLocationIds.filter(x => !newLocationIds.includes(x));
      if (oldLocationsOnly.length > 0) {
        for (const oldId of oldLocationsOnly) {
          this.locationService.unsetHasGroupFlag(oldId, this.group.uid);
        }
      }

      // set flag for added locations
      let newLocationsOnly = newLocationIds.filter(x => !oldLocationIds.includes(x));
      if (newLocationsOnly.length > 0) {
        for (const newId of newLocationsOnly) {
          this.locationService.setHasGroupFlag(newId, this.group.uid);
        }
      }
    }

    // TODO: Log analytics for each of the on... functions above?
    // Only hosts and admins can edit groups so possibly not important to track
    this.analyticsService.eventTrack(AnalyticsCategory.GROUPS, AnalyticsAction.GROUPS_UPDATE_GROUP);
  }

  validateForm(data: IGroup) {
    const missingFields = [];
    if ((data.name || '').trim().length === 0) missingFields.push('Name');
    if ((data.region || '').trim().length === 0) missingFields.push(this.regionService.regionLabel);
    if ((data.description || '').trim().length === 0) missingFields.push('Description');
    if ((data.groupType || '').trim().length === 0) missingFields.push('Group Type');

    if (missingFields.length > 0) {
      const message = 'Please enter a ' + missingFields.join(', ');
      this.toastService.presentToast(message);
      return false;
    }

    return true;
  }

  private isNullOrEmpty(query: string) {
    return query == null || query.trim().length === 0;
  }

  // this.group.advisors is an array of memberIds but we need to display a comma delimited string
  // of member names in the form. Convert this back to memberId's before saving to Firebase.
  private updateFormWithAdvisorNames() {
    this.group.advisors = this.groupAdvisors || {};
    const names = Object.values(this.group.advisors);
    this.groupEditPresenter.form.controls.advisors.setValue(names);
  }

  // this.group.cohosts is a map of memberIds: memberNames but we need to display a comma delimited string
  // of member names in the form. Convert this back to map before saving to Firebase.
  private updateFormWithCohostNames() {
    this.group.cohosts = this.groupCohosts || {};
    const names = Object.values(this.group.cohosts);
    this.groupEditPresenter.form.controls.cohosts.setValue(names);
  }

  // this.group.hosts is a map of memberIds: memberNames but we need to display a comma delimited string
  // of member names in the form. Convert this back to map before saving to Firebase.
  private updateFormWithHostNames() {
    this.group.hosts = this.groupHosts || {};
    const names = Object.values(this.group.hosts);
    this.groupEditPresenter.form.controls.hosts.setValue(names);
  }

  // this.group.locations is a map of locationIds: locationNames but we need to display a comma delimited string
  // of location names in the form. Convert this back to map before saving to Firebase.
  private updateFormWithLocationNames() {
    this.group.locations = this.groupLocations || {};
    const names = Object.values(this.group.locations).join(';\n');
    this.groupEditPresenter.form.controls.locations.setValue(names);
  }

  private updateMapInputs(group: IGroup) {
    this.groups$.next([group]);
    this.subscriptionService.clearSubscription(this.placeSubscription);
    this.placeSubscription = this.locationService.getPlacesById(Object.keys(group.locations)).subscribe(places => {
      // Sometimes the map isn't loaded when we want to set the view. If so, wait a second
      const timeout = this.locator ? 0 : 1000;
      setTimeout(() => {
        //this.locator.map.setView([places[0].coordinates.latitude, places[0].coordinates.longitude], places[0].zoomTo);
        this.places$.next(places);
        this.locator.fitToData();
      }, timeout);
    });
    this.subscriptionService.add(this.placeSubscription);
  }
}
