import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '@shared/services/constants.service';
import { SocialService } from '@shared/services/social/social.service';
import { ToastService } from '@shared/services/toast.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { BaseApprovalComponent } from '../base-approval/base-approval.component';
import { BaseApprovalPresenter } from '../base-approval/base-approval.presenter';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-approve-socials',
  styleUrls: ['../base-approval/base-approval.component.scss'],
  templateUrl: '../base-approval/base-approval.component.html'
})
export class ApproveSocialsComponent extends BaseApprovalComponent {
  get CONSTANTS() {
    return this.constantsService.constants.SOCIAL;
  }

  approveButtonText = 'Approve';
  itemDetails = [
    {
      field: 'title',
      icon: '',
      type: 'heading'
    },
    {
      field: 'memberName',
      icon: 'person',
      type: 'memberWithBadges',
      uid: 'memberId'
    },
    {
      field: 'time',
      icon: 'clock',
      type: 'text'
    },
    {
      field: 'address',
      icon: 'pin',
      type: 'text'
    },
    {
      field: 'locationName',
      icon: 'map',
      type: 'text'
    },
    {
      field: 'sharedGroupNames',
      icon: 'contacts',
      type: 'array'
    },
    {
      field: 'description',
      icon: '',
      type: 'long-text'
    }
  ];
  items$: Observable<any[]>;
  routerPrefix: string;
  startSlot = 'date';
  title: string;

  constructor(baseApprovalPresenter: BaseApprovalPresenter, subscriptionService: SubscriptionService, toastService: ToastService, private constantsService: ConstantsService, private socialService: SocialService) {
    super(baseApprovalPresenter, subscriptionService, toastService);
  }

  loadItems() {
    this.items$ = this.socialService.getSocialsForApproval();
  }

  setRouterLinks() {
    this.routerPrefix = `/${this.CONSTANTS.branding.toLowerCase()}`;
    this.title = `Approve ${this.CONSTANTS.branding}`;
  }

  onApproveHandler(item: any) {
    this.socialService.approveSocial(item);
  }
}
