import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UIService } from '@shared/services/ui.service';
import { SignupStepService } from '../../services/signup-step.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'signup-navigation',
  styleUrls: ['./signup-navigation.component.scss'],
  templateUrl: './signup-navigation.component.html'
})
export class SignupNavigationComponent implements OnInit {
  @Input() color: string;
  currentStep: number;
  isMediumDisplay$: Observable<boolean>;
  @Input() route: ActivatedRoute;
  steps: any[];
  @Input() showNavigation: boolean = false;

  constructor(private signupStepService: SignupStepService, private uiService: UIService) {}

  ngOnInit() {
    this.currentStep = this.signupStepService.getCurrentStepNumber(this.route);
    this.isMediumDisplay$ = this.uiService.isMediumDisplay$;
    this.steps = this.signupStepService.getStepNames();
  }
}
