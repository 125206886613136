import { Component } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Country } from '@shared/constants/country';
import { IChargebeePlan } from '@shared/models/chargebee-plan';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { UrlHelperService } from '@shared/services/url-helper.service';
import { Observable } from 'rxjs';
import { skipWhile } from 'rxjs/operators';

@Component({
  selector: 'app-account-settings',
  templateUrl: 'settings.page.html',
  styleUrls: ['settings.page.scss']
})
export class SettingsPage {
  annualSubscription$: Observable<IChargebeePlan>;
  country: string;
  isLoading = true;
  isPaypalMember: boolean = false;
  monthlySubscription$: Observable<IChargebeePlan>;
  url: SafeResourceUrl;

  get canDisplayErrorMessage() {
    return !this.isPaypalMember && !this.isLoading && !this.url;
  }

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private fns: AngularFireFunctions, private sanitizer: DomSanitizer, private urlHelperService: UrlHelperService) {}

  ionViewWillEnter() {
    this.analyticsService.eventTrack(AnalyticsCategory.ACCOUNT, AnalyticsAction.ACCOUNT_MANAGE_SUBSCRIPTION);
  }

  ngOnInit() {
    this.authService.userProfileObservable.pipe(skipWhile(x => !x)).subscribe(member => {
      if (member == null) return;

      this.country = member.country;
      this.isPaypalMember = member.isPaypalMember != null && member.isPaypalMember === true;
      if (!this.isPaypalMember) {
        this.getPortalUrl(member);
      } else {
        this.isLoading = false;
        this.url = null;
      }
    });
    this.annualSubscription$ = this.urlHelperService.annualSubscription$;
    this.monthlySubscription$ = this.urlHelperService.monthlySubscription$;
  }

  private getPortalUrl(member: UserObject) {
    const data = {
      chargebeeId: member.chargebeeId,
      country: member.country
    };

    const callable = this.fns.httpsCallable('getPortalLink');
    callable(data)
      .toPromise()
      .then(portal => {
        //Doesn't seem to work just binding a string url to iframe src
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(portal);
        this.isLoading = false;
      })
      .catch(err => {
        this.url = null;
        this.isLoading = false;
      });
  }
}
