import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MembersGuard } from '@shared/guards/members-guard';
import { AccountPage } from './account.page';
import { AdvancedNotificationsPage } from './notifications/components/advanced/advanced.page';
import { CancelOfferPage } from './cancel-subscription/components/cancel-offer/cancel-offer.page';
import { CancelOptionPage } from './cancel-subscription/components/cancel-option/cancel-option.page';
import { CancelQuestionsPage } from './cancel-subscription/components/cancel-questions/cancel-questions.page';
import { ChangePasswordPage } from './change-password/change-password.page';
import { NotificationsPage } from './notifications/notifications.page';
import { SettingsPage } from './settings/settings.page';

const routes: Routes = [
  {
    component: AccountPage,
    path: '',
    pathMatch: 'full',
    canActivate: [MembersGuard],
    data: {
      title: 'Account'
    }
  },
  {
    path: 'cancel-subscription',
    canActivate: [MembersGuard],
    children: [
      {
        path: '',
        redirectTo: 'option'
      },
      {
        path: 'offer',
        component: CancelOfferPage,
        canActivate: [MembersGuard],
        data: {
          title: 'Cancel Subscription Offer'
        }
      },
      {
        path: 'option',
        component: CancelOptionPage,
        canActivate: [MembersGuard],
        data: {
          title: 'Cancel Subscription Option'
        }
      },
      {
        path: 'questions',
        component: CancelQuestionsPage,
        canActivate: [MembersGuard],
        data: {
          title: 'Cancel Subscription Questions'
        }
      }
    ]
  },
  {
    path: 'change-password',
    component: ChangePasswordPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Change password'
    }
  },
  {
    path: 'notifications',
    component: NotificationsPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Manage notifications'
    }
  },
  {
    path: 'notifications/advanced/:type/:key/:value',
    component: AdvancedNotificationsPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Manage notifications (Advanced)'
    }
  },
  {
    path: 'settings',
    component: SettingsPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Manage subscription'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {}
