import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { MemberProfileDetailPage } from './member-profile-detail/member-profile-detail.page';
import { MemberProfileEditGuard } from './member-profile-edit/member-profile-edit.guard';
import { MemberProfileEditPage } from './member-profile-edit/member-profile-edit.page';
import { MembersRoutingModule } from './members-routing.module';

@NgModule({
  declarations: [MemberProfileEditPage, MemberProfileDetailPage],
  providers: [MemberProfileEditGuard],
  imports: [MembersRoutingModule, SharedModule]
})
export class MembersModule {}
