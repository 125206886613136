import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { MoreArchiveComponent } from './more-archive/more-archive.component';
import { MoreDetailComponent } from './more-detail/more-detail.component';
import { MoreListComponent } from './more-list/more-list.component';
import { MoreRoutingModule } from './more-routing.module';

@NgModule({
  imports: [SharedModule, MoreRoutingModule],
  declarations: [MoreArchiveComponent, MoreDetailComponent, MoreListComponent],
  exports: [MoreArchiveComponent, MoreDetailComponent, MoreListComponent]
})
export class MoreModule {}
