import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ActivatedRoute, Router } from '@angular/router';
import { IChangePasswordResult } from '@shared/models/update-password-result';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { UserService } from '@shared/services/user/user.service';
declare let fbq: any;
declare let pintrk: any;
declare let ttq: any;
declare let window: any;

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss']
})
export class WelcomePage implements OnInit {
  get canDisplayErrorMessage() {
    return !this.isLoading && (!this.email || !this.isValidMember);
  }

  CONSTANTS: any;
  country: string;
  currencyCode: string;
  customerId: string;
  email: string;
  firstName: string;
  isError: boolean = false;
  isLoading: boolean = true;
  isValidMember: boolean = false;
  subscriptionValue: string;

  constructor(private authService: AuthService, private constantsService: ConstantsService, private environmentService: EnvironmentService, private fns: AngularFireFunctions, private route: ActivatedRoute, private router: Router, private userService: UserService) {}

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.WELCOME.PASSWORD;

    if (this.environmentService.isLocalDevelopment) {
      this.authService._userProfileSubject.subscribe(user => {
        if (user == null) return;
        this.email = user.email;
        this.firstName = user.firstName;
        this.customerId = 'Test123';
        this.isLoading = false;
        this.isValidMember = true;
      });
      return;
    }

    this.route.queryParams.subscribe(async params => {
      this.country = params.customer_country || ''; //TODO validate countries in cloud function chargebeeOptions
      this.currencyCode = params.currency || '';
      this.customerId = params.customer_id || '';
      this.email = params.customer_email || '';
      this.firstName = params.customer_name || '';
      this.subscriptionValue = Number.isInteger(+params.value) ? (params.value / 100).toFixed(2) : '0.00';

      //Track conversion with Facebook pixel
      const pixelId = this.environmentService.settings.facebookPixel ? this.environmentService.settings.facebookPixel[this.country] : null;
      if (this.environmentService.isProduction && pixelId != null) {
        fbq('trackSingle', pixelId, 'PageView');
        fbq('trackSingle', pixelId, 'CompleteRegistration', {
          currency: this.currencyCode,
          value: this.subscriptionValue
        });
      }

      //Track conversion with Pinterest tag
      const pinterestTag = this.environmentService.settings.pinterestTag ? this.environmentService.settings.pinterestTag[this.country] : null;
      if (this.environmentService.isProduction && pinterestTag != null) {
        pintrk('page');
        pintrk('track', 'checkout', {
          currency: this.currencyCode,
          order_quantity: 1,
          value: this.subscriptionValue
        });
      }

      //Track conversion with Tiktok pixel
      const tikTokPixel = this.environmentService.settings.tikTokPixel ? this.environmentService.settings.tikTokPixel[this.country] : null;
      if (this.environmentService.isProduction && tikTokPixel != null) {
        ttq.page();
      }

      // Track conversion with Nine Ad Manager pixel
      if (window.pubSuite) {
        window.pubSuite.sendConversion('sign_up');
      }

      if (!this.email.length) {
        this.isLoading = false;
        return;
      }
      this.checkMemberExists();
    });
  }

  onPasswordSet(data: IChangePasswordResult) {
    if (data.user == null) return;

    const uid = data.user.uid;
    this.userService.updateSetPasswordFlagByUid(uid);

    //In principle /members/me/edit could be used, but sometimes there's a race condition between the navigation and setting the profile
    this.router.navigate([`/welcome/signup`]);
  }

  private checkMemberExists() {
    //Cases:
    // 1. Existing member is loggedIn: status = existing, redirect to ''
    // 2. Firebase auth account exists, and centralMembersPrivate.isOnboarding = true, status = valid, proceed with onboarding
    // 3. Firebase auth account exists, and centralMembersPrivate.isOnboarding != true, status = existing, redirect to ''
    // 4. Firebase auth account exists, centralMembersPrivate does not exist, throw error
    // 5. Firebase auth account doesn't exist, chargebee customer with customerId has active sub, create auth account & profile, status = valid, proceed with onboarding
    // 6. Firebase auth account doesn't exist, chargebee customer doesn't exist or have active sub, throw error

    const member = {
      country: this.country,
      customerId: this.customerId,
      email: this.email
    };

    const callable = this.fns.httpsCallable('checkMemberExists');
    callable(member)
      .toPromise()
      .then(status => {
        if ('existing' === status) {
          this.router.navigate(['']);
        }

        this.isLoading = false;

        this.isValidMember = 'valid' === status ? true : false;
        return;
      })
      .catch(err => {
        this.isLoading = false;
        this.isValidMember = false;
      });
  }
}
