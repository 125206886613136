import { Injectable } from '@angular/core';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { Direction } from '@shared/constants/direction';
import { MessageType } from '@shared/constants/message-type';
import { NotificationTarget } from '@shared/constants/notification-target';
import { IMessage } from '@shared/models/messages/message';
import { ActivityService, ActivityType } from '@shared/services/activity';
import { AuthService } from '@shared/services/auth.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { BehaviorSubject } from 'rxjs';
import { Observable, Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { CommentDatabase } from '@shared/services/comments/comment.database';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  comments$: BehaviorSubject<IMessage[]> = new BehaviorSubject<IMessage[]>([]);
  commentsRef: Subscription;

  constructor(private activityService: ActivityService, private authService: AuthService, private commentDatabase: CommentDatabase, private environmentService: EnvironmentService, private dateTimeService: DateTimeService, private subscriptionService: SubscriptionService) {}

  addComment(content: string, parentId: string, collectionId: string, collectionTitle: string, collectionType: MediaCollectionType): Promise<any> {
    const currentDateTime = this.dateTimeService.getDateTime();
    const comment = {
      content,
      dateTimeSent: currentDateTime,
      dateTimeLastUpdated: currentDateTime,
      name: this.authService._userProfileSubject.value.displayName,
      senderId: this.authService._userProfileSubject.value.uid,
      photos: {},
      type: MessageType.COMMENT
    };

    return this.commentDatabase.addComment(comment, parentId).then(() => {
      // TODO: Notifications for comments on public media, or on your own uploads?
      if (collectionType === MediaCollectionType.GROUP) {
        const activity = {
          activityTypes: [ActivityType.FEED, ActivityType.EMAIL, ActivityType.DIGEST],
          data: {
            baseUrl: this.environmentService.url(this.authService._userProfileSubject.value.country),
            collectionId: collectionId,
            collectionTitle: collectionTitle,
            parentId: parentId,
            memberName: comment.name
          },
          message: `<a href="/members/${comment.senderId}">${comment.name}</a> added a <a href="/groups/photos/${collectionId}/${collectionTitle}/detail?photo=${parentId}">new comment on a photo</a> in <a href="/groups/${collectionId}">${collectionTitle}</a>:<br><em>${comment.content}</em>`,
          notificationType: 'newMediaComment',
          targetIds: [collectionId],
          targetType: NotificationTarget.GROUP,
          timestamp: Date.now()
        };
        this.activityService.createActivity(activity);
      }
    });
  }

  // Assume that number of comments will be small, so we can just get all of them
  // rather than loading X, listening for new, and loading older on demand
  loadComments(parentCollection: string, parentId: string) {
    this.comments$.next([]); // Clear any existing comments
    this.subscriptionService.clearSubscription(this.commentsRef);

    const commentsRef = this.commentDatabase.getComments(parentId).subscribe(comments => {
      if (comments == null || comments.length === 0) comments = [];
      this.comments$.next(comments);
    });
    this.subscriptionService.add(this.commentsRef);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.commentsRef);
  }
}
