import { Injectable } from '@angular/core';
import { EventType } from '@infrastructure/constants/event-type';
import { IBaseEvent } from '@shared/models/base-event';
import { CatchupRsvpStatus } from '@shared/constants/catchup-rsvp-status';
import { CatchupService } from '@shared/services/catchups/catchup.service';
import { ConstantsService } from '@shared/services/constants.service';
import { SocialService } from '@shared/services/social/social.service';
import { TripService } from '@shared/services/trips/trip.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BaseEventService {
  constructor(private catchupService: CatchupService, private constantsService: ConstantsService, private socialService: SocialService, private tripService: TripService) {}

  getEventsForMember(memberId: string): Observable<IBaseEvent[]> {
    return combineLatest(this.catchupService.getCatchupsForMember(memberId, [CatchupRsvpStatus.GOING]), this.socialService.getSocialsOfInterestToMember(memberId)).pipe(
      map(arrays => Array.prototype.concat(...arrays)) //TODO use arrays.flat() when supported
    );
  }

  getTypeName(event: IBaseEvent): string {
    if (!event) return '';

    switch (event.eventType) {
      case EventType.CatchUp:
      case EventType.Virtual: // TODO: Do we need to explicitly say Virtual CatchUp?
        return this.constantsService.constants.CATCHUPS.branding;

      case EventType.Social:
        return this.constantsService.constants.SOCIAL.branding;

      case EventType.Trip:
        return 'Trip';

      case EventType.Event:
      default:
        return 'Event';
    }
  }

  isGoing(event: IBaseEvent): boolean {
    if (!event) return false;

    switch (event.eventType) {
      case EventType.Social:
        return this.socialService.isGoing(event);

      default:
        return this.catchupService.isGoing(event);
    }
  }

  isInterested(event: IBaseEvent): boolean {
    if (!event) return false;

    switch (event.eventType) {
      case EventType.Social:
        return this.socialService.isInterested(event);

      default:
        return false;
    }
  }

  isSocial(event: IBaseEvent) {
    return !!event && event.eventType === EventType.Social;
  }

  isTrip(event: IBaseEvent) {
    return !!event && event.eventType === EventType.Trip;
  }

  numberGoing(event: IBaseEvent): string {
    if (!event) return '';

    switch (event.eventType) {
      case EventType.Social:
        return this.socialService.numberInterested(event);

      case EventType.Trip:
        return this.tripService.numberInterestedAndGoing(event);

      default:
        return this.catchupService.numberGoing(event);
    }
  }
}
