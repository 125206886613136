import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AccessStatus } from '@shared/constants/access-status';
import { IContactFormSetting } from '@shared/models/contact-form-setting';
import { INotificationSettings } from '@shared/models/notifications/notification-settings';
import { IAdvancedNotification } from '@shared/models/notifications/advanced-notification';
import { UserObject } from '@shared/models/user-object';
import { IUtcOffset } from '@shared/models/utc-offset';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { NotificationTarget, NotificationService } from '@shared/services/notifications';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { NotificationsPresenter } from './notifications.presenter';
import { Observable, Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-notifications',
  styleUrls: ['notifications.page.scss'],
  templateUrl: 'notifications.page.html'
})
export class NotificationsPage implements OnDestroy, OnInit {
  get form() {
    return this.presenter.form;
  }

  readonly AccessStatus = AccessStatus;
  HEADINGS: Record<NotificationTarget, string>;
  INSTRUCTIONS: string;
  isDigestEnabled: boolean = false;
  member: UserObject;
  SETTINGS: Record<NotificationTarget, IContactFormSetting[]>;
  savedValues: INotificationSettings;
  subscription: Subscription;
  timeOptions: Record<string, string> = {};

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private notificationService: NotificationService,
    private presenter: NotificationsPresenter,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService
  ) {}

  countMatchingValues(group: string, id: string, value: boolean): string | number {
    const items = Object.values(this.savedValues[group][id].items || {});
    const matchingItems = items.filter(x => x === value);
    if (matchingItems.length === items.length) {
      return 'all';
    } else if (matchingItems.length === 0) {
      return 'new';
    } else {
      return matchingItems.length;
    }
  }

  hasMatchingValues(group: string, id: string, value: boolean): boolean {
    if (this.presenter.getControlValue(group, id) === value) {
      return true;
    } else if (Object.values(this.savedValues[group][id].items || {}).some(x => x === value)) {
      return true;
    } else {
      return false;
    }
  }

  ionViewWillEnter() {
    this.analyticsService.eventTrack(AnalyticsCategory.NOTIFICATION, AnalyticsAction.NOTIFICATION_VIEW_SETTINGS);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.subscription);
  }

  ngOnInit() {
    this.HEADINGS = this.constantsService.constants.NOTIFICATIONS.headings;
    this.INSTRUCTIONS = this.constantsService.constants.NOTIFICATIONS.instructions;
    this.SETTINGS = this.constantsService.constants.NOTIFICATIONS.settings;
    this.presenter.init(this.SETTINGS);
    this.subscription = this.authService._userProfileSubject
      .pipe(
        first(x => x != null),
        switchMap((member: UserObject) => {
          this.member = member;
          if (member.canAccessRomance !== AccessStatus.GRANTED) delete this.SETTINGS.Romance;
          const settings: Observable<any> = this.notificationService.getSettingsForMember(member.uid);
          return settings;
        })
      )
      .subscribe(settings => {
        this.generateTimeOptions(this.member.utcOffset);
        this.isDigestEnabled = !!settings.isEnabled;
        this.savedValues = settings;
        this.presenter.setValue(settings);
      });
    this.subscriptionService.add(this.subscription);
  }

  onChange(event: any, group: string, id: string) {
    if (this.savedValues[group][id].hasOwnProperty('items')) {
      for (let key of Object.keys(this.savedValues[group][id].items)) {
        this.savedValues[group][id].items[key] = event.detail.checked;
      }
    }
  }

  onSave() {
    let updatedData: INotificationSettings = {} as INotificationSettings;
    for (let [group, settings] of Object.entries(this.savedValues)) {
      if ('digestTime' === group) {
        updatedData[group] = this.presenter.settings[group];
      } else if ('isEnabled' === group) {
        updatedData[group] = this.presenter.settings[group]; // Doing this in the same clause as digestTime causes a type error
      } else {
        updatedData[group] = {};
        // Only update settings if the control exists (e.g. members without romance access don't see romance settings)
        if (this.presenter.settings[group]) {
          for (let [id, value] of Object.entries(settings)) {
            if (this.savedValues[group][id].hasOwnProperty('items')) {
              updatedData[group][id] = Object.assign({}, { default: this.presenter.settings[group][id], items: this.savedValues[group][id].items });
            } else {
              updatedData[group][id] = this.presenter.settings[group][id];
            }
          }
        }
      }
    }
    this.notificationService.updateSettingsForMember(this.member.uid, updatedData);
    this.analyticsService.eventTrack(AnalyticsCategory.NOTIFICATION, AnalyticsAction.NOTIFICATION_UPDATE_SETTINGS);
    this.toastService.presentToast('Notification settings updated');
  }

  onToggleDigest(event) {
    this.isDigestEnabled = event.detail.checked;
    this.savedValues.isEnabled = event.detail.checked;
  }

  sortNull() {}

  private generateTimeOptions(utcOffset: IUtcOffset) {
    for (let h = 0; h < 24; h++) {
      const hours = ((h + utcOffset.hours) % 24).toString().padStart(2, '0');
      const minutes = ((60 + utcOffset.minutes) % 60).toString().padStart(2, '0');
      const key = `${hours}:${minutes}`;
      const value = `${h.toString().padStart(2, '0')}:00`;
      this.timeOptions[key] = value;
    }
  }
}
