import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MeetingPlacePage } from './meeting-place/meeting-place.page';

const routes: Routes = [
  {
    path: '',
    component: MeetingPlacePage,
    data: {
      title: 'Meeting Place'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MeetingPlaceRoutingModule {}
