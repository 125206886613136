import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { IMessage } from '@shared/models/messages/message';
import { CommentService } from '@shared/services/comments/comment.service';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'chirpy-comment-list',
  templateUrl: './chirpy-comment-list.component.html',
  styleUrls: ['./chirpy-comment-list.component.scss']
})
export class ChirpyCommentListComponent {
  comments$: Observable<IMessage[]>;
  noComments: boolean = true;
  @Input() noCommentsText: string;
  noMorePastComments: boolean = false;
  @Input() parentCollection: string;
  @Input() parentId: string;
  ref: Subscription;

  constructor(private service: CommentService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parentId.currentValue !== changes.parentId.previousValue) {
      if (this.ref) this.ref.unsubscribe();
      this.service.loadComments(this.parentCollection, this.parentId);
      this.comments$ = this.service.comments$.pipe(
        tap(comments => {
          this.noComments = this.noCommentsText !== '' && comments.length === 0;
        })
      );
    }
  }
}
