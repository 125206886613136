import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'linkify' })
export class LinkifyPipe implements PipeTransform {
  transform(link: string): string {
    return this.linkify(link);
  }

  private linkify(plainText): string {
    let replacedText;

    //URLs starting with http://, https://, or ftp://
    //but exclude cases where the URL is quoted, e.g. <a href="https://foo.com">bar</a>
    //TODO: Use some sort of whitelist for the pattern to prevent hiding malicious links?
    let replacePattern1 = /(^|[^"])(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = plainText.replace(replacePattern1, '$1<a href="$2" target="_blank">$2</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    let replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    let replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
  }
}
