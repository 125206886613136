import { Component, Input, OnInit } from '@angular/core';
import { IIcon } from '@shared/models/icon';
import { ConstantsService } from '@shared/services/constants.service';

@Component({
  selector: 'chirpy-badge-table',
  templateUrl: './chirpy-badge-table.component.html'
})
export class ChirpyBadgeTableComponent implements OnInit {
  BADGES: Record<string, Record<string, IIcon>>;
  COINS: Record<string, any>;

  constructor(private constantsService: ConstantsService) {}

  ngOnInit() {
    this.BADGES = this.constantsService.constants.ASSETS.BADGES;
    this.COINS = this.constantsService.constants.COINS;
  }

  sortNull() {}
}
