import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { ALL_COUNTRIES } from '@shared/constants/country';
import { IButtonGroup } from '@shared/models/button-group';
import { IRoomShare } from '@shared/models/trips/room-share';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { RoomShareService } from '@shared/services/trips/room-share.service';
import { ToastService } from '@shared/services/toast.service';
import { TripService } from '@shared/services/trips/trip.service';
import { Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'room-share-list-page',
  templateUrl: './room-share-list.page.html'
})
export class RoomShareListPage {
  buttonGroups: IButtonGroup[];
  CONSTANTS: any;
  interested: Record<string, string> = {};
  memberId: string;
  @Input() members$: Observable<IRoomShare[]>;
  myRoomShare$: Observable<IRoomShare>;
  myRoomShareListing: IRoomShare;
  title$: Observable<string>;
  tripId: string;

  constructor(private authService: AuthService, private constantsService: ConstantsService, private roomShareService: RoomShareService, private route: ActivatedRoute, private toastService: ToastService, private tripService: TripService) {}

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.TRIPS.ROOM_SHARE;
    this.tripId = this.route.snapshot.paramMap.get('tripId');
    this.title$ = this.tripService.getTrip(this.tripId).pipe(
      tap(trip => {
        this.interested = Object.assign({}, trip.interested, trip.attendees);
      }),
      map(x => `Room Share for ` + x.title)
    );
    const foundMatches = false;
    this.members$ = this.authService._userProfileSubject.pipe(
      first(x => !!x),
      switchMap(profile => {
        const country = this.authService.isAdmin([AdminRole.TRAVEL]) ? ALL_COUNTRIES : profile.country;
        return this.roomShareService.getRoomShareForTrip(this.tripId, foundMatches, country);
      }),
      map(roomShares => roomShares.filter(x => x.uid !== this.authService._userProfileSubject.value.uid))
    );
    this.myRoomShare$ = this.authService._userProfileSubject.pipe(
      switchMap(profile => {
        this.memberId = profile.uid;
        return this.roomShareService.getRoomShareForMember(this.tripId, this.memberId);
      }),
      tap(roomShare => {
        this.myRoomShareListing = roomShare;
        this.initButtons();
      })
    );
    this.initButtons();
  }

  onShowMessage() {
    this.toastService.presentToast(this.CONSTANTS.requirementMessage);
  }

  private initButtons() {
    this.buttonGroups = [
      {
        condition: true,
        buttons: [
          {
            condition: this.authService.isAdmin([AdminRole.TRAVEL]),
            color: 'light',
            icon: 'cog',
            routerLink: ['/trips/room-share/manage', this.tripId],
            responsive: false,
            text: 'Manage Room Share'
          },
          {
            condition: !!this.interested[this.memberId],
            color: 'light',
            icon: 'create',
            routerLink: ['/trips/room-share', this.tripId, 'update'],
            responsive: false,
            text: 'Your listing'
          },
          {
            condition: !this.interested[this.memberId],
            click: this.onShowMessage.bind(this),
            color: 'light',
            icon: 'create',
            responsive: false,
            text: 'Your listing'
          },
          {
            condition: !!this.myRoomShareListing,
            color: 'light',
            icon: 'contacts',
            responsive: false,
            routerLink: ['/trips/room-share', this.tripId, 'match'],
            text: 'Your matches'
          }
        ]
      }
    ];
  }
}
