import { Component, Input } from '@angular/core';

@Component({
  selector: 'chirpy-accordion',
  styleUrls: ['./chirpy-accordion.component.scss'],
  templateUrl: './chirpy-accordion.component.html'
})
export class ChirpyAccordionComponent {
  isOpen: boolean;
  @Input() color: string;
  @Input() startOpen: boolean = true;
  @Input() title: string;

  constructor() {}

  ngOnInit() {
    this.isOpen = this.startOpen;
  }

  onToggleOpen() {
    this.isOpen = !this.isOpen;
  }
}
