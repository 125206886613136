import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ChirpyImageService } from '@shared/components/chirpy-image/chirpy-image.service';
import { UserObject } from '@shared/models/user-object';
import { ConstantsService } from '@shared/services/constants.service';

@Component({
  selector: 'chirpy-image',
  templateUrl: './chirpy-image.component.html',
  styleUrls: ['./chirpy-image.component.scss']
})
export class ChirpyImageComponent {
  @Input() canEdit = true;

  get ASSETS() {
    return this.constantsService.constants.ASSETS;
  }

  get uploadImageButtonText() {
    return this.photoURL == null ? 'Add image' : 'Change image';
  }

  errorMessage: string;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  hasCamera: boolean;
  @Input() imageType: 'member' | 'thread' | 'group'; // these types must match the types in on-image-upload.ts, this Cloud function resizes the uploaded image and updates the appropriate collection and uid.0
  isUploadingImage: boolean;
  @Input() photoURL: string;
  @Input() showHostBadge = false;
  @Input() uid: string; // create a filepath based on this uid and update the Firebase collection for this uid in on-image-upload.ts

  constructor(private constantsService: ConstantsService, private service: ChirpyImageService) {}

  onImageLoad() {
    this.isUploadingImage = false;
  }

  async onUploadFile(event) {
    // Prevent loading spinner from showing when user does the following:
    // 1. "Upload File" button
    // 2. Select a file (uploads automatically)
    // 3. "Upload File" button again
    // 4. Cancel
    if (event.target.files.length === 0) return;

    const file = event.target.files[0];
    await this.saveImage(file);

    // Remove last uploaded file from file control.
    event.target.files.length = 0;
  }

  openFileChooser() {
    this.fileInput.nativeElement.click();
  }

  async saveImage(file) {
    this.isUploadingImage = true;
    const filePath = `${this.imageType}/${this.uid}/avatar`;
    await this.service.uploadImage(file, filePath);
  }
}
