import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebcamsPage } from './webcams/webcams.page';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/pages/fun-and-games',
    pathMatch: 'full'
  },
  {
    path: 'webcams',
    component: WebcamsPage,
    data: {
      title: 'Webcams'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FunRoutingModule {}
