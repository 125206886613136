import { Injectable } from '@angular/core';
import { BaseDatabase } from '@shared/services/base.database';
import { IVersion } from '@shared/services/version/models/version';

@Injectable({
  providedIn: 'root'
})
export class VersionDatabase extends BaseDatabase {
  getVersion() {
    return this.getDocument<IVersion>('app', 'version');
  }
}
