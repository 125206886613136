import { ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { AvatarSize } from '@shared/constants/avatar-size';
import { PhotoSize } from '@shared/constants/photo-size';
import { Role } from '@infrastructure/constants/role';
import { IIcon } from '@shared/models/icon';
import { IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { UserObject } from '@shared/models/user-object';
import { ConstantsService } from '@shared/services/constants.service';
import { AuthService } from '@shared/services/auth.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UserService } from '@shared/services/user/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'chirpy-avatar',
  templateUrl: './chirpy-avatar.component.html',
  styleUrls: ['./chirpy-avatar.component.scss']
})
export class ChirpyAvatarComponent implements OnInit {
  avatarUrl: string;
  badges: Record<Role, IIcon>;
  @Input() forceChangeDetection: boolean = false;
  hasBorder: boolean = false;
  imageSize: PhotoSize;
  @Input() isMe: boolean = false;
  @Input() noBadges: boolean = false;
  @Input() noMargin: boolean = false;
  @Input() memberId: string = '';
  roleBadge: IIcon;
  @Input() size: string = 'default';
  private subscription: Subscription;

  constructor(private authService: AuthService, private changeDetectorRef: ChangeDetectorRef, private constantsService: ConstantsService, private subscriptionService: SubscriptionService, private userService: UserService) {}

  getAvatars(): void {
    if (this.isMe) {
      this.getMyAvatar();
    } else {
      this.getImageAvatar();
    }
  }

  getImageAvatar(): void {
    if (this.memberId == null) return;
    // TODO: Revisit implementation of this.
    // Change detection is calling this too often, and it doesn't play nicely if contained in a component with onPush change detection

    this.subscription = this.userService.getUserProfile(this.memberId).subscribe(profile => {
      this.setData(profile);
      // Needed for where parent component uses onPush ChangeDetectionStrategy
      if (this.forceChangeDetection) this.changeDetectorRef.detectChanges();
    });
    this.subscriptionService.add(this.subscription);
  }

  getMyAvatar() {
    const profile = this.authService._userProfileSubject.value;
    this.setData(profile);
  }

  ngOnInit() {
    this.badges = this.constantsService.constants.ASSETS.BADGES.role || null;
    this.imageSize = this.size === AvatarSize.LARGE || this.size === AvatarSize.XLARGE ? PhotoSize.LARGE : PhotoSize.THUMBNAIL;
    this.getAvatars();
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.subscription);
  }

  setData(profile: UserObject) {
    if (profile != null) {
      const badges = profile.badges || {};
      this.avatarUrl = this.userService.getAvatarUrl(profile, this.imageSize);
      this.hasBorder = Object.keys(badges.text || {}).length > 0;
      this.roleBadge = this.badges ? this.badges[profile.role] : null;
    } else {
      this.avatarUrl = '';
      this.hasBorder = false;
      this.roleBadge = null;
    }
  }
}
