import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uidFilter',
  pure: false
})
export class UidPipe implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    if (filter.value === true) {
      return items.filter(item => item[filter.selector] === filter.uid);
    } else {
      return items.filter(item => item[filter.selector] !== filter.uid);
    }
  }
}
