import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminRole } from '@shared/constants/admin-role';
import { AvatarSize } from '@shared/constants/avatar-size';
import { PhotoSize } from '@shared/constants/photo-size';
import { IButtonGroup } from '@shared/models/button-group';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { MessageHelperService } from '@shared/services/messages/message-helper.service';
import { UserService } from '@shared/services/user/user.service';
import { Subscription } from 'rxjs';
import { IRomanceListing } from '../../models/romance-listing';
import { RomanceService } from '../../services/romance.service';

@Component({
  selector: 'romance-detail',
  templateUrl: './romance-detail.component.html',
  styleUrls: ['./romance-detail.component.scss']
})
export class RomanceDetailComponent implements OnInit, OnDestroy {
  get CONSTANTS() {
    return this.constantsService.constants.ROMANCE.DETAIL;
  }

  get isAdmin() {
    return this.authService.isAdmin([AdminRole.SUPER, AdminRole.ROMANCE]);
  }

  get userId() {
    if (this.authService._userProfileSubject != null && this.authService._userProfileSubject.value != null) {
      return this.authService._userProfileSubject.value.uid;
    }

    return '';
  }

  buttonGroups: IButtonGroup[];
  errorMessage: string;
  romanceListing: IRomanceListing;
  romanceListingRef: Subscription;
  size: AvatarSize = AvatarSize.LARGE;
  title: string;
  user: UserObject; // member whose profile is being viewed

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private messageHelperService: MessageHelperService,
    private romanceService: RomanceService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {}

  approveListing() {
    const uid = this.romanceListing.uid;
    this.romanceService.approveRomanceListing(this.romanceListing, this.user);
  }

  deleteRomanceListing() {
    this.romanceService.deleteRomanceListing(this.romanceListing);
  }

  getDateFormat(created: number) {
    return this.dateTimeService.getDateFormat(created);
  }

  getGenderValue(gender) {
    return this.romanceService.getGenderValue(gender);
  }

  getRomanceListing(uid: string) {
    this.romanceListingRef = this.romanceService.getRomanceListing(uid).subscribe(romanceListing => {
      if (romanceListing == null) {
        this.title = this.CONSTANTS.pageHeading;
        this.errorMessage = this.CONSTANTS.doesNotExist;
        return;
      }

      this.romanceListing = romanceListing;
      this.title = this.romanceListing.title;

      this.initButtons();
      this.sendAnalytics(this.romanceListing);
    });
  }

  goBack() {
    this.router.navigate([`/romance`]);
  }

  ngOnDestroy() {
    if (this.romanceListingRef) this.romanceListingRef.unsubscribe();
  }

  ngOnInit() {
    const userId = this.route.snapshot.paramMap.get('id');
    if (userId == null) return;

    if (userId === 'me') {
      // the right hand side menu opens /romance/me
      // turn it into a url people can copy and paste to others by redirecting to /romance/{your-user-id}
      this.router.navigate(['/romance/', this.authService._userProfileSubject.value.uid]);
      return;
    }

    this.userService.getUserProfile(userId).subscribe(user => {
      if (user == null) return;

      this.user = user;
      this.getRomanceListing(this.user.uid);
    });
  }

  onSendMessage() {
    this.analyticsService.eventTrack(AnalyticsCategory.ROMANCE, AnalyticsAction.ROMANCE_SEND_MESSAGE, this.user.displayName);
  }

  rejectListing() {
    const uid = this.romanceListing.uid;
    this.romanceService.rejectRomanceListing(uid);
  }

  sendAnalytics(romanceListing: IRomanceListing) {
    if (romanceListing.uid === this.userId) return; // don't track events for when you view your own profile.

    // Log main analytic
    this.analyticsService.eventTrack(AnalyticsCategory.ROMANCE, AnalyticsAction.ROMANCE_VIEW_LISTING_DETAIL, this.user.displayName);
  }

  private initButtons() {
    if (!this.romanceListing) return [];

    this.buttonGroups = [
      {
        condition: this.isAdmin,
        buttons: [
          {
            click: this.approveListing.bind(this),
            condition: !this.romanceListing.approved,
            icon: 'checkmark',
            responsive: true,
            text: 'Approve'
          },
          {
            click: this.rejectListing.bind(this),
            condition: this.romanceListing.approved,
            icon: 'close',
            responsive: true,
            text: 'Reject'
          }
        ]
      },
      {
        condition: this.isAdmin || this.romanceListing.uid === this.userId,
        buttons: [
          {
            condition: true,
            icon: 'create',
            routerLink: ['/romance/update', this.romanceListing.uid],
            text: 'Update'
          },
          {
            click: this.deleteRomanceListing.bind(this),
            condition: true,
            icon: 'trash',
            text: `Delete`
          }
        ]
      },
      {
        condition: true,
        buttons: [
          {
            condition: this.romanceListing.uid !== this.userId,
            icon: 'chatboxes',
            routerLink: ['/messages/compose', this.romanceListing.uid],
            responsive: false,
            text: 'Send Message'
          }
        ]
      }
    ];
  }
}
