import { Component, Input } from '@angular/core';
import { IMessage } from '@shared/models/messages/message';
import { DateTimeService } from '@shared/services/date-time.service';

@Component({
  selector: 'chirpy-comment-detail',
  templateUrl: './chirpy-comment-detail.component.html',
  styleUrls: ['./chirpy-comment-detail.component.scss']
})
export class ChirpyCommentDetailComponent {
  get routerLink() {
    return ['/members/', this.comment.senderId];
  }

  @Input() comment: IMessage;

  constructor(private dateTimeService: DateTimeService) {}

  getDateFormat(message: IMessage) {
    return this.dateTimeService.getDateFormat(this.comment.dateTimeSent);
  }
}
