import { Injectable } from '@angular/core';
import { ContactService } from '@shared/services/messages/contact.service';

@Injectable({
  providedIn: 'root'
})
export class AddContactService {
  constructor(private contactService: ContactService) {}

  get member() {
    return this.contactService.user;
  }

  get contacts() {
    return this.contactService.contacts;
  }

  addMemberToContacts(memberId: string, memberName: string) {
    return this.contactService.addMemberToContacts(memberId, memberName);
  }

  removeMemberFromContacts(memberId: string, memberName: string) {
    return this.contactService.removeMemberFromContacts(memberId, memberName);
  }

  filterSearchResults(searchResults: Record<string, string>, showContacts: boolean) {
    const results: Record<string, string> = {};

    Object.keys(searchResults).forEach(uid => {
      const isContact = this.contacts.length > 0 && this.contacts.some(r => r.relatedId === uid && r.isContact === true);
      const isMe = uid === this.member.uid;
      if (!isMe) {
        if (showContacts === isContact) {
          results[uid] = searchResults[uid];
        }
      }
    });

    return results;
  }
}
