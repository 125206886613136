import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '@shared/services/constants.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.page.html'
})
export class AccountPage {
  CONSTANTS: any;

  constructor(private constantsService: ConstantsService) {}

  ngOnInit() {
    this.CONSTANTS = this.constantsService.constants.ACCOUNT;
  }
}
