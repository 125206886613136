import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EventType } from '@infrastructure/constants/event-type';
import { ICatchupTemplate } from '@shared/models/catchups/catchup-template';

@Injectable({
  providedIn: 'root'
})
export class CatchupTemplateEditPresenter {
  // Only the template name is required, because templates can be partial
  form = this.formBuilder.group({
    address: [''],
    country: [''],
    description: [''],
    endTime: [''],
    eventType: [''],
    hasPayments: [false],
    name: ['', Validators.required],
    ownerId: [''],
    region: [''],
    rsvp: [false],
    sharedGroups: [{}],
    time: [''],
    title: [''],
    url: ['']
  });

  constructor(private formBuilder: FormBuilder) {}

  template() {
    let formValue = this.form.value as ICatchupTemplate;
    // sharedGroups is a map of groupId, groupName pairs. We keep the actual value in sync on the component, but only show the groupNames on the form control
    // Remove it here to so that it doesn't overwrite the map when we do Object.assign
    if (formValue.sharedGroups) {
      delete formValue.sharedGroups;
    }

    // Remove trailing spaces from URL
    if (formValue.url) formValue.url = formValue.url.trim();

    return formValue;
  }

  eventType() {
    return this.form.value.eventType as EventType;
  }

  setValue(template: ICatchupTemplate) {
    this.form.setValue({
      address: template.address || '',
      country: template.country || '',
      description: template.description,
      endTime: template.endTime || '',
      eventType: template.eventType || EventType.CatchUp,
      hasPayments: template.hasPayments || false,
      name: template.name || '',
      ownerId: template.ownerId || '',
      region: template.region || '',
      rsvp: template.rsvp || false,
      sharedGroups: Object.values(template.sharedGroups || {}).join(', '),
      time: template.time || '',
      title: template.title,
      url: template.url || ''
    });
  }
}
