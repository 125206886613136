import { Component, OnInit } from '@angular/core';
import { IAppOptions } from '@shared/models/app-options';
import { AppOptionsService } from '@shared/services/app-options/app-options.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-settings-list',
  templateUrl: './settings-list.page.html'
})
export class SettingsListPage implements OnInit {
  settings$: Observable<string[]>;

  constructor(private appOptionsService: AppOptionsService) {}

  keyToName(key: string) {
    return key
      .replace(/([A-Z])/g, match => ` ${match}`)
      .replace(/^./, match => match.toUpperCase())
      .trim();
  }

  ngOnInit() {
    this.settings$ = this.appOptionsService.getOptionsValues('_keys', 'ALL');
  }
}
