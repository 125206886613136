import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { IAppOptions } from '@shared/models/app-options';
import { IContactFormSetting } from '@shared/models/contact-form-setting';
import { IListingImage } from '@shared/models/image/listing-image';
import { MARKETPLACE_EXPIRY } from '@shared/models/marketplace/marketplace-expiry';
import { IMarketplaceListing } from '@shared/models/marketplace/marketplace-listing';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { EmailService } from '@shared/services/email/email.service';
import { ImageService } from '@shared/services/image/image.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { UserService } from '@shared/services/user/user.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { MarketplaceDatabase } from './marketplace.database';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceService implements OnDestroy {
  private get CONSTANTS() {
    return this.constantsService.constants.MARKETPLACE.SERVICE;
  }

  get member() {
    return this.authService._userProfileSubject.value;
  }

  categoriesSubscription: Subscription;
  hasMore: boolean;
  readonly INCREMENT: number = 10; // get 10 records initially. when user clicks Load more button then increase by 10. this enables us to have live updating of newly added and removed records.
  listingsSubscription: Subscription;
  readonly MAX_LISTINGS: number = 8; // max number of simultaneous active listings per member
  recordsToFetch = 9999; // for the moment load all listings. If we start getting too many, then revisit implementation.
  marketplaceCategories: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  marketplaceListings: IMarketplaceListing[] = [];

  constructor(
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private emailService: EmailService,
    private imageService: ImageService,
    private environmentService: EnvironmentService,
    private marketplaceDatabase: MarketplaceDatabase,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private userService: UserService
  ) {
    this.loadMarketplaceCategories();
  }

  approveMarketplaceListing(uid: string) {
    const data = { uid, approved: true };
    return this.marketplaceDatabase.updateMarketplaceListing(data);
  }

  canAddNewListing(memberId: string): Observable<boolean> {
    return this.marketplaceDatabase.getMemberMarketplaceListings(memberId, this.MAX_LISTINGS).pipe(
      skipWhile(x => x == null),
      take(1),
      map(listings => (listings || []).length < this.MAX_LISTINGS)
    );
  }

  async deleteMarketplaceListing(marketplaceListing: IMarketplaceListing) {
    const alert = await this.alertController.create({
      header: this.CONSTANTS.deleteHeader,
      message: this.CONSTANTS.deleteMessage,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: 'OK',
          handler: data => this.deleteMarketplaceListingHandler(marketplaceListing)
        }
      ]
    });

    await alert.present();
  }

  getCategoriesList(categories: string[]) {
    return categories.length > 0 ? categories.join(', ') : '';
  }

  getLatestMarketplaceListings(): Observable<IMarketplaceListing[]> {
    return this.authService._userProfileSubject.pipe(
      skipWhile(x => x == null),
      take(1),
      switchMap(member => {
        const country = this.constantsService.constants.APP.allowOtherCountries ? null : member.country;
        const isAdmin = false; // Admins shouldn't see unapproved listings in the Marketplace box on My Chirpy
        const recordsToFetch = 3;
        const hideUnapproved = true; // Members shouldn't see unapproved listings in the Marketplace box on My Chirpy
        return this.marketplaceDatabase.getMarketplaceListings(country, isAdmin, recordsToFetch, hideUnapproved);
      })
    );
  }

  getMoreMarketplaceListings() {
    // NB Much of this function is unnecessary if we are only reading the listings once, but leave it in case we get too many listings to read at once
    const subject$ = new BehaviorSubject<IMarketplaceListing[]>(null);

    this.getMarketplaceListings().subscribe(marketplaceListings => {
      if (marketplaceListings == null) return;

      const totalNewRecords = marketplaceListings.length - this.marketplaceListings.length;
      if (totalNewRecords !== 0) this.hasMore = totalNewRecords === this.INCREMENT;
      // if (this.recordsToFetch > marketplaceListings.length) this.hasMore = false;

      if (this.hasMore) this.recordsToFetch += this.INCREMENT; // increase how many Marketplace Listings to display.

      // USE THE DATA THE DATABASE GIVES US:
      // this.marketplaceListings = marketplaceListings;
      // subject$.next(marketplaceListings);

      // UPDATE THE EXISTING ARRAY SO THE UI UPDATES FASTER
      const newData = [];
      // add new entries, update existing entries
      marketplaceListings.forEach(r => {
        const item = this.marketplaceListings.find(x => x.uid === r.uid);
        if (item != null) {
          // update anything in the list that has changed.
          Object.assign(item, r);
        } else {
          newData.push(r);
        }
      });

      // remove entries that no longer exist
      const currentData = [];
      this.marketplaceListings.forEach((r, index) => {
        const item = marketplaceListings.find(x => x.uid === r.uid);
        if (item != null) currentData.push(this.marketplaceListings[index]);
      });

      const unsorted = currentData.concat(newData);
      let sorted = unsorted.sort((a, b) => (a.created >= b.created ? 1 : -1)).sort();
      if (this.authService.isAdmin([AdminRole.SUPER])) {
        sorted = sorted.sort((a, b) => (a.approved > b.approved ? 1 : -1));
      }

      this.marketplaceListings = sorted;
      subject$.next(sorted);
    });

    return subject$;
  }

  getMarketplaceExpiry() {
    return MARKETPLACE_EXPIRY;
  }

  getMarketplaceListing(uid: string = null): Observable<IMarketplaceListing> {
    if (uid == null) uid = this.member.uid;
    return this.marketplaceDatabase.getMarketplaceListing(uid);
  }

  getMyMarketplaceListings(): Observable<IMarketplaceListing[]> {
    return this.authService._userProfileSubject.pipe(
      skipWhile(x => x == null),
      take(1),
      switchMap(member => {
        return this.marketplaceDatabase.getMemberMarketplaceListings(member.uid, this.recordsToFetch);
      }),
      take(1)
    );
  }

  getMarketplaceListings() {
    const subject = new BehaviorSubject(null);

    this.authService._userProfileSubject
      .pipe(
        skipWhile(x => x == null),
        take(1)
      )
      .subscribe(member => {
        this.subscriptionService.clearSubscription(this.listingsSubscription);
        const country = this.constantsService.constants.APP.allowOtherCountries ? null : member.country;
        this.listingsSubscription = this.marketplaceDatabase.getMarketplaceListings(country, this.authService.isAdmin([AdminRole.SUPER]), this.recordsToFetch).subscribe((marketplaceListings: IMarketplaceListing[]) => {
          if (marketplaceListings != null) subject.next(marketplaceListings);
        });
        this.subscriptionService.add(this.listingsSubscription);
      });

    return subject;
  }

  getMarketplaceListingsForApproval(): Observable<IMarketplaceListing[]> {
    return this.marketplaceDatabase.getMarketplaceListingsForApproval();
  }

  hideListing(uid: string) {
    const data = { uid, published: false };
    return this.marketplaceDatabase.updateMarketplaceListing(data).then(() => {
      this.toastService.presentToast(this.CONSTANTS.hiddenToast);
    });
  }

  loadMarketplaceCategories() {
    // TODO: Explicitly call this if categories are updates in Manage Settings?
    this.categoriesSubscription = this.marketplaceDatabase.getMarketplaceCategories().subscribe((options: IAppOptions) => {
      this.marketplaceCategories.next(options.data.ALL || []);
    });
    this.subscriptionService.add(this.categoriesSubscription);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.listingsSubscription);
    this.subscriptionService.clearSubscription(this.categoriesSubscription);
  }

  publishListing(uid: string, approved: boolean) {
    const data = { uid, published: true };
    return this.marketplaceDatabase.updateMarketplaceListing(data).then(() => {
      const message = approved ? this.CONSTANTS.publishApproved : this.CONSTANTS.publishUnapproved;
      this.toastService.presentToast(message);

      this.requestMarketplaceListingReview(uid, false);
    });
  }

  rejectMarketplaceListing(uid: string) {
    const data = { uid, approved: false };
    return this.marketplaceDatabase.updateMarketplaceListing(data);
  }

  requestMarketplaceListingReview(uid: string, isNew: boolean) {
    this.getMarketplaceListing(uid)
      .pipe(
        skipWhile(x => x == null),
        take(1)
      )
      .subscribe(marketplaceListing => {
        this.userService
          .getUserProfile(marketplaceListing.memberId)
          .pipe(
            skipWhile(x => x == null),
            take(1)
          )
          .subscribe(member => {
            const newOrUpdated = isNew ? 'New' : 'Updated';
            this.emailService.sendMarketplaceListingApprovalRequest(marketplaceListing, member, newOrUpdated);
          });
      });
  }

  updateMarketplaceListing(member: UserObject, marketplaceListing: IMarketplaceListing, terms: IContactFormSetting[], photosToRemove: IListingImage[]) {
    if (!this.validateForm(marketplaceListing, terms)) return false;

    const isCreating = marketplaceListing.uid == null;
    if (isCreating) {
      marketplaceListing.created = this.dateTimeService.getDateTime();
      marketplaceListing.published = true;
    }

    // When a member edits their marketplace listing it needs approving again unless they're an Admin.
    if (!this.authService.isAdmin([AdminRole.SUPER])) marketplaceListing.approved = false;

    // convert first letter to upper case
    marketplaceListing.title = marketplaceListing.title[0].toUpperCase() + marketplaceListing.title.slice(1);

    // Delete images removed from listing
    if (photosToRemove.length > 0) {
      for (const photo of photosToRemove) {
        this.imageService.deleteImage(photo).catch(err => console.error(JSON.stringify(err)));
      }
    }

    // copy photos data for later upload, and remove any File objects (Can't upload them to Firestore, we can't upload the image to Cloud storage until we have a marketplaceListing uid)
    const photos = this.imageService.deepCopy(marketplaceListing.photos);
    for (let [key, photo] of Object.entries(marketplaceListing.photos)) {
      if (photo.file) {
        delete marketplaceListing.photos[key].file;
        marketplaceListing.photos[key].photoURL = '';
      }
    }

    const ref: Promise<any> = isCreating ? this.marketplaceDatabase.createMarketplaceListing(marketplaceListing) : this.marketplaceDatabase.updateMarketplaceListing(marketplaceListing, false);

    return ref.then(result => {
      const message = `${this.CONSTANTS.updatedProfileToast} ${isCreating ? 'added' : 'updated'}.`;
      this.toastService.presentToast(message);

      const uid = marketplaceListing.uid || result.id;
      marketplaceListing.uid = uid;

      // Now upload photos
      this.imageService.uploadImages(uid, photos, 'marketplace');

      // TODO: Show diff from previous version?
      this.requestMarketplaceListingReview(marketplaceListing.uid, isCreating);

      // Log main analytic
      const action = isCreating ? AnalyticsAction.MARKETPLACE_ADD_LISTING : AnalyticsAction.MARKETPLACE_UPDATE_LISTING;
      this.analyticsService.eventTrack(AnalyticsCategory.MARKETPLACE, action, uid);

      this.router.navigate(['/marketplace', marketplaceListing.uid]);
    });
  }

  validateForm(data: IMarketplaceListing, terms: IContactFormSetting[]) {
    const missingFields = [];
    if (data.categories.length === 0) missingFields.push(`a Category`);
    if (data.description.trim().length === 0) missingFields.push('a Description');
    if (data.expiry === 0) missingFields.push(`an Expiry`);
    if (data.gender.length === 0) missingFields.push(`a Gender`);
    if (data.location.trim().length === 0) missingFields.push('a Location');
    if (data.price.trim().length === 0) missingFields.push('a Price');
    if (data.title.trim().length === 0) missingFields.push('a Title');

    const requiredTerms = Object.entries(data.terms).reduce((output, current) => {
      if (terms.some(x => x.id === current[0] && x.required)) output.push(current[1]);
      return output;
    }, []);
    if (Object.values(requiredTerms).includes(false)) missingFields.push(`acceptance of the terms and conditions`);

    if (missingFields.length > 0) {
      const message = 'Please enter ' + missingFields.join(', ');
      this.toastService.presentToast(message);
      return false;
    }

    return true;
  }

  private deleteMarketplaceListingHandler(marketplaceListing: IMarketplaceListing) {
    return this.marketplaceDatabase.deleteMarketplaceListing(marketplaceListing.uid).then(() => {
      this.toastService.presentToast(this.CONSTANTS.deletedToast);
      this.router.navigate(['/marketplace']);
    });
  }
}
