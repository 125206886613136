import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IEmail } from '@shared/models/email';
import { IMailTemplate } from '@shared/models/mail-template';
import { EmailService } from '@shared/services/email/email.service';
import { first, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-email-detail',
  templateUrl: './email-detail.page.html'
})
export class EmailDetailPage implements OnInit {
  body: string;
  data: any;
  date: any;
  error: string;
  subject: string;
  title: string = 'View email';
  to: string;

  constructor(private emailService: EmailService, private route: ActivatedRoute) {}

  ngOnInit() {
    const uid = this.route.snapshot.paramMap.get('id');

    this.emailService
      .getEmail(uid)
      .pipe(first())
      .subscribe(email => {
        this.date = email.delivery.startTime.toDate();
        this.to = this.emailService.getToAddresses(email);
        this.error = email.delivery.error || '';
        this.populateEmail(email);
      });
  }

  // emails are stored as a mailTemplate name + json data. The Firebase Trigger Email plugin
  // automatically sends an email by loading the mailTemplate from the Firebase /mailTemplates collection and
  // populating the handlebar variables. This populateEmail function replicates that functionality.
  populateEmail(email: IEmail) {
    if (email.template != null) {
      this.emailService
        .getMailTemplate(email.template.name)
        .pipe(
          first(),
          switchMap(mailTemplate => this.emailService.maybeRegisterPartialsForTemplate(mailTemplate)),
          first()
        )
        .subscribe((mailTemplate: IMailTemplate) => {
          const emailBody = this.emailService.populateVariables(mailTemplate.html, email.template.data);
          const emailSubject = this.emailService.populateVariables(mailTemplate.subject, email.template.data);
          this.body = emailBody;
          this.subject = emailSubject;
          this.data = Object.entries(email.template.data);
        });
    } else if (email.message != null) {
      // Retained for backwards compatibility, all messages are now sent using a template, for better searching in the email log
      this.body = email.message.html;
      this.subject = email.message.subject;
    }
  }
}
