import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StateGuard } from '@shared/services/messages/state.guard';
import { MessagesPage } from './messages.page';

export const routes: Routes = [
  {
    path: 'threads',
    component: MessagesPage,
    loadChildren: () => import('./modules/threads/threads.module').then(m => m.ThreadsModule)
  },
  {
    path: 'contacts',
    component: MessagesPage,
    loadChildren: () => import('./modules/contacts/contacts.module').then(m => m.ContactsModule)
  },
  {
    path: 'more',
    component: MessagesPage,
    loadChildren: () => import('./modules/more/more.module').then(m => m.MoreModule)
  },
  {
    path: '',
    //Handle this in StateGuard; goes into redirect loop if CanActivate is on the threads/components route
    //redirectTo: 'threads',
    pathMatch: 'full',
    canActivate: [StateGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MessagesRoutingModule {}
