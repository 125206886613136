import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IGroup } from '@shared/models/groups/group';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { GroupService } from '@shared/services/groups/group.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { Subscription } from 'rxjs';
import { SignupStepService } from '../services/signup-step.service';
import { SignupGroupsService } from './services/groups.service';
import { SignupGroupsPresenter } from './groups.presenter';

@Component({
  selector: 'app-signup-groups',
  styleUrls: ['./groups.page.scss'],
  templateUrl: './groups.page.html',
  viewProviders: [SignupGroupsPresenter]
})
export class SignupGroupsPage implements OnInit, OnDestroy {
  get formValid() {
    return this.presenter.form.valid;
  }

  get setGroupsForm() {
    return this.presenter.form;
  }

  ASSETS: any;
  buttonColor: string;
  CONSTANTS: any;
  groups: IGroup[] = [];
  groupIds: string[] = [];
  hasLoadedGroups: boolean = false;
  isProcessing: boolean = false;
  nextStep: string;
  progressColor: string;
  private _ref: Subscription;
  showBackButton: boolean = false;
  showNavigation: boolean = false;
  submitButtonText: string = 'Next';
  useBar: boolean;

  constructor(
    private authService: AuthService,
    private groupService: GroupService,
    private constantsService: ConstantsService,
    private presenter: SignupGroupsPresenter,
    public route: ActivatedRoute,
    private router: Router,
    private service: SignupGroupsService,
    private signupStepService: SignupStepService,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService
  ) {}

  countHosts(hosts: Record<string, string>) {
    return Object.values(hosts || {}).length;
  }

  getHosts(hosts: Record<string, string>) {
    return Object.values(hosts || {}).join(', ');
  }

  getRegion(region: string) {
    if (!region) return '';
    return region.startsWith('Virtual') ? 'Virtual' : region;
  }

  isGroupMember(uid: string) {
    return this.groupService.isGroupMember(uid);
  }

  loadGroups() {
    this.subscriptionService.clearSubscription(this._ref);
    this._ref = this.groupService.getGroupsById(this.groupIds).subscribe(groups => {
      if (groups == null) {
        //this.hasLoadedGroups = true; // TODO: What if Firebase times out?
        return;
      }

      // Display groups in same order as list of ids
      let lookup = {};
      groups.forEach(group => {
        lookup[group.uid] = group;
      });

      const sortedGroups = [];
      this.groupIds.forEach(id => {
        if (lookup[id]) sortedGroups.push(lookup[id]);
      });

      this.groups = sortedGroups;
      this.hasLoadedGroups = true; // set it here so there is not a mismatch between groups.length and hasLoadedGroups
      this.presenter.setGroups(this.groupIds);
    });
    this.subscriptionService.add(this._ref);
  }

  ngOnInit() {
    this.buttonColor = this.constantsService.constants.WELCOME.SIGNUP.buttonColor;
    this.CONSTANTS = this.constantsService.constants.WELCOME.SIGNUP.GROUPS;
    this.ASSETS = this.constantsService.constants.ASSETS;
    this.nextStep = this.signupStepService.getNextStep(this.route);
    this.progressColor = this.constantsService.constants.WELCOME.SIGNUP.progressColor;
    this.showNavigation = this.constantsService.constants.WELCOME.SIGNUP.showNavigation;
    this.useBar = this.constantsService.constants.WELCOME.SIGNUP.useProgressBar;

    this.authService._userProfileSubject.subscribe(async (profile: UserObject) => {
      if (profile.coordinates) {
        this.service.findClosestGroups(profile.coordinates).subscribe(groupIds => {
          if (groupIds.length) {
            this.groupIds = groupIds;
            this.loadGroups();
          } else {
            // No groups nearby
            this.hasLoadedGroups = true;
          }
        });
      } else if (profile.uid) {
        // profile exists but does not have coordinates, can't find close groups
        this.hasLoadedGroups = true;
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this._ref);
  }

  async onSubmit() {
    if (!this.formValid) {
      this.toastService.presentToast(`Please select a group.`);
      return;
    } else {
      this.isProcessing = true;
      this.submitButtonText = 'Processing';
      const { groupCheckboxes } = this.presenter.submit();
      const groups: IGroup[] = Object.entries(groupCheckboxes).reduce((selected, entry) => {
        //entry = [groupId, true|false]
        if (entry[1]) selected = [...selected, ...this.groups.filter(x => x.uid === entry[0])];
        return selected;
      }, []);
      await this.service.setGroups(groups);
      this.isProcessing = false;
      this.submitButtonText = 'Next';
      if (this.nextStep) this.router.navigate(['/welcome/signup', this.nextStep]);
    }
  }
}
