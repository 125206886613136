import { Injectable, OnDestroy } from '@angular/core';
import { IActivity } from '@shared/models/activity/activity';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { combineLatest, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { ActivityRTDatabase } from './activity.rtdatabase';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  constructor(private activityRTDatabase: ActivityRTDatabase, private authService: AuthService) {}

  createActivity(activity: IActivity) {
    return this.activityRTDatabase.createActivity(activity);
  }

  getActivityFeed(): Observable<IActivity[]> {
    return this.authService._userProfileSubject.pipe(
      first(x => !!x),
      switchMap(member => this.activityRTDatabase.getActivityFeed(member.uid)),
      map(x => x.sort((a, b) => b.timestamp - a.timestamp))
    );
  }

  hasUnreadActivity(): Observable<boolean> {
    return this.authService._userProfileSubject.pipe(
      first(x => !!x),
      switchMap((member: UserObject) => combineLatest(this.activityRTDatabase.getActivityLastRead(member.uid), this.activityRTDatabase.getActivityLastUpdated(member.uid))),
      map(([lastRead, lastUpdated]) => {
        if (lastRead == null || lastUpdated == null) return false;
        return (lastUpdated || 0) > (lastRead || 0);
      })
    );
  }

  updateActivityLastRead(uid: string) {
    this.activityRTDatabase.updateActivityLastRead(uid, Date.now());
  }
}
