import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { BehaviorSubject } from 'rxjs';
import { IToolbarIcon } from '../models/toolbar-icon';
import { AbstractFooterService } from './abstract-footer.service';
import { ChirpyFooterService } from './white-label/chirpy-footer.service';
import { FriendsFooterService } from './white-label/friends-footer.service';
import { PlantsFooterService } from './white-label/plants-footer.service';

@Injectable({
  providedIn: 'root'
})
export class AppFooterService {
  get branding() {
    return this.environmentService.settings.constants;
  }

  toolbarIcons$: BehaviorSubject<IToolbarIcon[]> = new BehaviorSubject(null);
  private footerService: AbstractFooterService;

  constructor(private authService: AuthService, private environmentService: EnvironmentService) {
    this.initFooterService(); // TODO: Inject with factory
    this.getToolbarIcons();
  }

  private getFooterService() {
    switch (this.environmentService.settings.constants) {
      case 'chirpy':
        return new ChirpyFooterService();
      case 'friends':
        return new FriendsFooterService();
      case 'plants':
        return new PlantsFooterService();
      default:
        return null;
    }
  }

  private getToolbarIcons() {
    this.authService.userProfileObservable.subscribe(user => {
      let toolbar: IToolbarIcon[] = null;

      if (user != null) {
        toolbar = this.footerService.getToolbar(user);
      }

      this.toolbarIcons$.next(toolbar);
    });
  }

  private initFooterService() {
    this.footerService = this.getFooterService();
    if (this.footerService == null) {
      console.error('footerService is null, check your environment settings', this.environmentService.settings);
    }
  }
}
