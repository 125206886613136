import { Injectable } from '@angular/core';
import { AuthService } from '@shared/services/auth.service';
import { EnvironmentService } from '@shared/services/environment.service';
import { UIService } from '@shared/services/ui.service';
import { Subscription } from 'rxjs';
declare let Beacon: any;

@Injectable()
export class BeaconService {
  loggedInMemberEmail: string;
  ref: Subscription;

  constructor(private authService: AuthService, private environmentService: EnvironmentService, private uiService: UIService) {}

  get canShowBeacon() {
    // Hide on mobile because Beacon overlaps the Toast close button. It can't be fixed by altering the z-index because
    // the beacon div isn't at the same level as the ion-toast element.
    return this.uiService.isMediumDisplay && this.environmentService.settings.beaconId != null;
  }

  showBeacon() {
    if (!this.canShowBeacon) return;

    if (this.ref) this.ref.unsubscribe();
    this.ref = this.authService._userProfileSubject.subscribe(user => {
      // Clear identify data.
      if (user == null) {
        Beacon('logout');
        this.loggedInMemberEmail = null; // set to NULL to allow Beacon to re-initialize.
      } else {
        // Initialise Beacon once.
        if (this.loggedInMemberEmail !== user.email) {
          this.loggedInMemberEmail = user.email;

          // Prefill 'Ask' form with contact details to review past message history.
          Beacon('identify', {
            name: `${user.fullName}`,
            email: user.email
          });
        }
      }
    });

    Beacon('init', this.environmentService.settings.beaconId);

    Beacon('config', {
      labels: {
        responseTime: 'We will respond to you shortly.'
      }
    });
  }

  hideBeacon() {
    if (!this.canShowBeacon) return;

    Beacon('destroy');
    this.loggedInMemberEmail = null; // set to NULL to allow Beacon to re-initialize.
  }
}
