import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IChirpyMemberListButton } from '@shared/components/chirpy-member-list/chirpy-member-list-button';
import { ISocial } from '@shared/models/social/social';
import { InterestStatus } from '@shared/constants/interest-status';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { SocialService } from '@shared/services/social/social.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'app-social-interested',
  styleUrls: ['././social-interested.page.scss'],
  templateUrl: './social-interested.page.html'
})
export class SocialInterestedPage implements OnInit, OnDestroy {
  get CONSTANTS() {
    return this.constantsService.constants.SOCIAL.INTERESTED;
  }

  buttons: IChirpyMemberListButton[];
  currentView: string = 'going-view';
  defaultHref: string = '/social';
  going: UserObject[];
  interested: UserObject[];
  isGoingView: boolean = true;
  isInterestedView: boolean = false;
  social: ISocial;
  socialId: string;
  socialSubscription: Subscription;
  shortTitle: string;
  title: string;

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private constantsService: ConstantsService, private socialService: SocialService, private subscriptionService: SubscriptionService, private route: ActivatedRoute) {}

  initButtons(social: ISocial = null) {
    if (social === null || !this.socialService.isClosed(social)) {
      this.buttons = [
        {
          canShow: member => this.authService.isAdmin() && this.isMemberInterested(member),
          command: InterestStatus.NOT_INTERESTED,
          label: 'Remove'
        }
      ];
    } else {
      this.buttons = [];
    }
  }

  isMemberInterested(member: UserObject) {
    return this.social.interested[member.uid] === InterestStatus.INTERESTED;
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.socialSubscription);
  }

  ngOnInit() {
    // Set fallback title
    this.shortTitle = this.CONSTANTS.title;
    this.title = this.CONSTANTS.title;

    this.initButtons();

    this.socialId = this.route.snapshot.paramMap.get('socialId');
    // NB if default value for currentView is changed, need to change AnalyticsAction here
    this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, AnalyticsAction.SOCIAL_VIEW_GOING, this.socialId);

    this.socialSubscription = this.socialService.getSocial(this.socialId).subscribe(async (social: ISocial) => {
      if (social == null) return;

      this.social = social;
      this.defaultHref = `/social/${social.uid}`;
      this.title = `Attendees for ${social.title}`;
      this.initButtons(social);

      // display going/interested members
      this.socialService.getGoingMembers(this.social.interested).subscribe(going => {
        if (going == null) this.going = [];
        else this.going = going;
      });
      this.socialService.getInterestedMembers(this.social.interested).subscribe(interested => {
        if (interested == null) this.interested = [];
        else this.interested = interested;
      });
    });
    this.subscriptionService.add(this.socialSubscription);
  }

  onButtonClick({ member, command }) {
    return this.socialService.setInterested(this.social.uid, member.uid, command);
  }

  onChangeView(event: any) {
    // Ideally call eventTrack with Social.title, but then have a race condition with loading Social data
    switch (event.detail.value) {
      case 'interested-view':
        this.isGoingView = false;
        this.isInterestedView = true;
        this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, AnalyticsAction.SOCIAL_VIEW_INTERESTED, this.socialId);
        break;

      case 'going-view':
      default:
        this.isGoingView = true;
        this.isInterestedView = false;
        this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, AnalyticsAction.SOCIAL_VIEW_GOING, this.socialId);
        break;
    }
  }
}
