import { Component } from '@angular/core';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';

@Component({
  selector: 'app-webcams',
  styleUrls: ['./webcams.page.scss'],
  templateUrl: 'webcams.page.html'
})
export class WebcamsPage {
  webcams = [
    {
      title: 'Times Square, New York',
      description: 'This high-quality camera provides the viewer with a view of the bustling street below.',
      url: 'https://www.earthcam.com/usa/newyork/timessquare/?cam=tsrobo1',
      imageURL: '/assets/chirpy/webcams/times-square.jpg'
    },
    {
      title: 'Tokyo traffic',
      description: 'Watch a chaotic intersection located in Tokyo.',
      url: 'http://118.22.23.185/CgiStart?page=Single&Language=1',
      imageURL: '/assets/chirpy/webcams/tokyo.jpg'
    },
    {
      title: 'Hawaii (beach view)',
      description: 'When the weather is nice a good camera to watch surfers.',
      url: 'https://www.earthcam.com/usa/hawaii/bigisland/waikoloa/?cam=waikoloa',
      imageURL: '/assets/chirpy/webcams/waikoloa.jpg'
    },
    {
      title: 'Chicago',
      description: 'Located outside Chicago’s baseball arena.  If you are watching when a game is on it is interesting to see all the fans running wild.',
      url: 'https://www.earthcam.com/usa/illinois/chicago/wrigleyville/?cam=wrigleyville',
      imageURL: '/assets/chirpy/webcams/wrigleyville.jpg'
    },
    {
      title: 'U.K.',
      description: 'Nice beach to look over during the day, however, as it located in the UK good luck waiting for a sunny day!',
      url: 'https://www.earthcam.com/world/uk/bognorregis/?cam=bognorregis',
      imageURL: '/assets/chirpy/webcams/bognor-regis.jpg'
    },
    {
      title: 'France (Eiffel Tower)',
      description: 'A portrait view of this famous monument, during the day you can see tourists walking around the base.',
      url: 'https://www.earthcam.com/world/france/paris/?cam=eiffeltower_hd',
      imageURL: '/assets/chirpy/webcams/eiffel-tower.jpg'
    },
    {
      title: 'Denver',
      description: 'Looks over a normal intersection in Denver, a little slower paced than some of the other cameras,',
      url: 'https://www.earthcam.com/usa/colorado/denver/?cam=denver',
      imageURL: '/assets/chirpy/webcams/denver.jpg'
    },
    {
      title: 'Rochester',
      description: 'Image updates every 5 seconds, usually quite a busy spot.',
      url: 'https://www.downtownrochestermn.com/webcam',
      imageURL: '/assets/chirpy/webcams/rochester.jpg'
    },
    {
      title: 'Vermont University',
      description: 'Good to watch during the day when all the students are out and about.',
      url: 'https://www.earthcam.com/usa/vermont/burlington/?cam=uvm',
      imageURL: '/assets/chirpy/webcams/vermont.jpg'
    },
    {
      title: 'Otago University',
      description: 'This website has multiple cameras located around the university campus, they all update every 10 seconds.',
      url: 'https://www.propserv.otago.ac.nz/webcamera',
      imageURL: '/assets/chirpy/webcams/otago-university.jpg'
    },
    {
      title: 'Brooklyn Bridge',
      description: 'Looking down on the busy Brooklyn bridge this camera allows views to watch as cars drive over this iconic bridge.',
      url: 'https://www.earthcam.com/usa/newyork/timessquare/?cam=tstwo_hd',
      imageURL: '/assets/chirpy/webcams/brooklyn-bridge.jpg'
    },
    {
      title: 'Times Square Birdseye view',
      description: `A good Bird's eye view allowing you to look down on the busy streets below.`,
      url: 'https://chirpyplus.co.nz/wp-content/uploads/2018/11/Times-Square-New-York-webcam.jpg',
      imageURL: '/assets/chirpy/webcams/times-square-new-york-birds-eye.jpg'
    },
    {
      title: 'Dublin Nightlife',
      description: 'This is a street view of the exterior of an Irish pub, good to watch the night evolve as the viewer can see the steady increase in visitors to the pub that the camera is located outside of.',
      url: 'https://www.earthcam.com/world/ireland/dublin/?cam=templebar',
      imageURL: '/assets/chirpy/webcams/dublin-pub.jpg'
    },
    {
      title: 'Dublin Pub',
      description: 'Inside, during NZ lunchtime this webcam usually has a live band in allowing the viewer to look over a usually packed pub.',
      url: 'https://www.earthcam.com/world/ireland/dublin/?cam=dublinpub',
      imageURL: '/assets/chirpy/webcams/dublin-pub-inside.jpg'
    },
    {
      title: 'Bali Elephant Cam',
      description: 'Watches over an elephant enclosure bathing pool.',
      url: 'https://www.earthcam.com/world/indonesia/bali/?cam=bali2',
      imageURL: '/assets/chirpy/webcams/elephant.jpg'
    },
    {
      title: 'Port of Tauranga',
      description: 'This website provides multiple views of the port; each camera updates every 2 minutes so while not a continues video it provides images of a location a little closer to home.  Will be worth a look when the larger cruise ships pull into dock.',
      url: 'https://www.port-tauranga.co.nz/cargo-and-shipping/tauranga-web-cams/',
      imageURL: '/assets/chirpy/webcams/port-of-tauranga.jpg'
    },
    {
      title: 'Abbey Road Crossing',
      description: 'An iconic location, good to watch tourists try recreate possibly one of the most famous album covers.',
      url: 'https://www.earthcam.com/world/england/london/abbeyroad/?cam=abbeyroad_uk',
      imageURL: '/assets/chirpy/webcams/abbey-road-crossing.jpg'
    },
    {
      title: 'Mexico',
      description: 'A good camera to watch the sunset which occurs around lunchtime NZ time',
      url: 'https://www.earthcam.com/world/mexico/cancun/?cam=cancunbillys',
      imageURL: '/assets/chirpy/webcams/mexico.jpg'
    },
    {
      title: 'Lyttelton port',
      description: 'A view of Lyttelton port, good to watch ships unload.',
      url: 'http://www.lpc.co.nz/lpc/harbour-webcam/',
      imageURL: '/assets/chirpy/webcams/lyttelton.jpg'
    },
    {
      title: 'Florida university animal study',
      description: 'Interesting to watching during the NZ morning however after 1 it gets very dark.  This cam is good however as during the New Zealand night you experience Florida during the day.',
      url: 'https://www.earthcam.com/usa/florida/gainesville/?cam=uflorida2',
      imageURL: '/assets/chirpy/webcams/animal-study.jpg'
    },
    {
      title: 'St. Croix',
      description: 'Good to watch tourists getting on and off ferries. Good people watching cam.',
      url: 'https://www.earthcam.com/usa/virginislands/stcroix/?cam=mmstcroix_hd',
      imageURL: '/assets/chirpy/webcams/st-croix.jpg'
    },
    {
      title: 'Aruba',
      description: 'Another Beach camera, 10 pm is 8 am here so not many people show up until later in the evening.',
      url: 'https://www.earthcam.com/world/aruba/eaglebeach/?cam=bucuti1',
      imageURL: '/assets/chirpy/webcams/aruba.jpg'
    },
    {
      title: 'Michigan Mill Pond Park',
      description: 'Located in Michigan this camera provides a good view of the local pond where members of the community can be seen walking around.',
      url: 'https://www.earthcam.com/usa/michigan/brighton/?cam=brighton',
      imageURL: '/assets/chirpy/webcams/michigan.jpg'
    },
    {
      title: 'Melbourne (FED Square)',
      description: 'The most popular live cam in Melbourne. Watch every festival, every pop-up art installation, the whole shebang.',
      url: 'https://fedsquare.com/fed-cam',
      imageURL: '/assets/chirpy/webcams/melbourne.jpg'
    }
  ];

  constructor(private analyticsService: AnalyticsService) {}

  eventTrack(title: string, index: number) {
    this.analyticsService.eventTrack(AnalyticsCategory.FUN, AnalyticsAction.FUN_CLICK_WEBCAM, title, {}, index);
  }
}
