import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IGroup } from '@shared/models/groups/group';
import { GroupService } from '@shared/services/groups/group.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from 'rxjs';
import { ChirpyGroupListComponent } from '../chirpy-group-list/chirpy-group-list.component';

@Component({
  selector: 'chirpy-group-list-widget',
  templateUrl: './chirpy-group-list-widget.component.html'
})
export class ChirpyGroupListWidgetComponent implements OnInit, OnDestroy {
  @Input() groupIds: string[] = [];
  groups: IGroup[];
  @Input() heading: string = '';
  private _ref: Subscription;
  showPlaceholders: boolean = true;

  constructor(private groupService: GroupService, private subscriptionService: SubscriptionService) {}

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this._ref);
  }

  ngOnInit() {
    this._ref = this.groupService.getGroupsById(this.groupIds).subscribe(groups => {
      if (groups == null) return;

      // Display groups in same order as list of ids
      let lookup = {};
      groups.forEach(group => {
        lookup[group.uid] = group;
      });

      const sortedGroups = [];
      this.groupIds.forEach(id => {
        if (lookup[id]) sortedGroups.push(lookup[id]);
      });

      this.groups = sortedGroups;
    });
    this.subscriptionService.add(this._ref);
  }
}
