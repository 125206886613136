import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { IContactFormSetting } from '@shared/models/contact-form-setting';
import { DateTimeService } from '@shared/services/date-time.service';
import { IMarketplaceListing } from '@shared/models/marketplace/marketplace-listing';
import { combineLatest, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceEditPresenter implements OnDestroy {
  expiryDate$ = new Subject<number>();
  form = this.formBuilder.group({
    categories: [[], Validators.required],
    photos: [{}],
    terms: new FormGroup({}),
    deleteDate: [0, Validators.required],
    description: ['', Validators.required],
    expiry: [0, Validators.required],
    gender: ['', Validators.required],
    location: ['', Validators.required],
    price: ['', Validators.required],
    renew: [false],
    startDate: [0, Validators.required],
    title: ['', Validators.required]
  });
  ref: Subscription;

  constructor(private formBuilder: FormBuilder, private dateTimeService: DateTimeService) {}

  init(termsData: IContactFormSetting[]) {
    // Dynamically add terms and conditions checkboxes
    const termsGroup = this.form.controls.terms as FormGroup;
    termsData.forEach(term => {
      if (term.required) {
        termsGroup.addControl(term.id, new FormControl(false, [Validators.requiredTrue]));
      } else {
        termsGroup.addControl(term.id, new FormControl(false));
      }
    });

    // Listen for changes to expiry period, and calculate the date
    this.ref = combineLatest(this.form.controls.expiry.valueChanges, this.form.controls.renew.valueChanges, this.form.controls.startDate.valueChanges).subscribe(([expiry, renew, startDate]) => {
      // expiry and startDate are provided as strings not numbers
      expiry = +expiry;
      startDate = +startDate;
      let start = renew || startDate === 0 ? this.dateTimeService.getDateTime() : startDate;
      const expiryDate = this.dateTimeService.addDays(start, expiry);
      this.form.controls.deleteDate.setValue(expiryDate);
      this.expiryDate$.next(expiryDate);
    });
  }

  ngOnDestroy() {
    this.expiryDate$.complete();
    this.ref.unsubscribe();
  }

  marketplaceListing() {
    let formValue = this.form.value as IMarketplaceListing;
    if (this.form.value.renew || +formValue.startDate === 0) {
      formValue.startDate = this.dateTimeService.getDateTime();
    }
    delete formValue['renew'];
    // enforce number type
    formValue.deleteDate = +formValue.deleteDate;
    formValue.expiry = +formValue.expiry;
    formValue.startDate = +formValue.startDate;
    return formValue;
  }

  setValue(marketplaceListing: IMarketplaceListing) {
    const startDate = marketplaceListing.startDate === 0 ? this.dateTimeService.getDateTime() : +marketplaceListing.startDate;
    this.form.setValue({
      categories: marketplaceListing.categories,
      deleteDate: marketplaceListing.deleteDate,
      description: marketplaceListing.description,
      expiry: marketplaceListing.expiry,
      gender: marketplaceListing.gender,
      location: marketplaceListing.location,
      photos: Object.assign({}, marketplaceListing.photos), // we want a copy, not a reference, so we can compare old vs new to see if images have been removed
      price: marketplaceListing.price,
      renew: false,
      startDate: startDate,
      terms: marketplaceListing.terms,
      title: marketplaceListing.title
    });
  }
}
