import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { ChirpyLocatorMapComponent } from '@shared/components/chirpy-locator-map/chirpy-locator-map.component';
import { AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { GroupLocatorService } from './group-locator.service';

@Component({
  selector: 'group-locator',
  templateUrl: '../../../../shared/components/chirpy-locator-map/chirpy-locator-map.component.html',
  styleUrls: ['../../../../shared/components/chirpy-locator-map/chirpy-locator-map.component.scss', './group-locator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GroupLocatorComponent extends ChirpyLocatorMapComponent {
  wrapperClass = 'group-locator';

  constructor(analyticsService: AnalyticsService, authService: AuthService, ref: ChangeDetectorRef, subscriptionService: SubscriptionService, protected markerService: GroupLocatorService) {
    super(analyticsService, authService, ref, subscriptionService);
  }
}
