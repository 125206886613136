import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IGroup } from '@shared/models/groups/group';
import { GroupType } from '@shared/constants/group-type';

@Injectable({
  providedIn: 'root'
})
export class GroupEditPresenter {
  form = this.formBuilder.group({
    advisors: [''],
    canShowCatchups: [false],
    canShowPix: [false],
    canShowSocials: [false],
    cohosts: [''],
    country: ['', Validators.required],
    description: ['', Validators.required],
    groupType: ['', Validators.required],
    hosts: [''],
    isUnhosted: [false],
    locations: [''],
    region: ['', Validators.required],
    name: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}

  group() {
    return this.form.value as IGroup;
  }

  setValue(group: IGroup) {
    this.form.setValue({
      advisors: Object.values(group.advisors || {}).join(', ') || '',
      canShowCatchups: group.canShowCatchups || false,
      canShowPix: group.canShowPix || false,
      canShowSocials: group.canShowSocials || false,
      cohosts: Object.values(group.cohosts || {}).join(', ') || '',
      country: group.country || '',
      description: group.description || '',
      groupType: group.groupType || GroupType.GROUP,
      hosts: Object.values(group.hosts || {}).join(', ') || '',
      isUnhosted: group.isUnhosted || false,
      locations: Object.values(group.locations || {}).join(', ') || '',
      region: group.region || '',
      name: group.name || ''
    });
  }
}
