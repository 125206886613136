import { Component, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Country } from '@shared/constants/country';
import { ISocial } from '@shared/models/social/social';
import { IMapState } from '@shared/models/map-state';
import { IPlace } from '@shared/models/place';
import { IResultsModel } from '@shared/models/results-model';
import { ISearchModel } from '@shared/models/search-model';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { LocationService } from '@shared/services/location/location.service';
import { SocialService } from '@shared/services/social/social.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ToastService } from '@shared/services/toast.service';
import { UIService } from '@shared/services/ui.service';
import * as L from 'leaflet';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { SocialSearchComponent } from './components/social-search/social-search.component';

@Component({
  selector: 'app-socials-with-map',
  styleUrls: ['./social-with-map.page.scss'],
  templateUrl: './social-with-map.page.html',
  viewProviders: [SocialSearchComponent]
})
export class SocialWithMapPage implements OnInit, OnDestroy {
  allPlaces: IPlace[] = [];
  allSocials: ISocial[] = [];
  analyticsCategory: AnalyticsCategory = AnalyticsCategory.SOCIAL;
  CONSTANTS: any;
  country: Country;
  currentView: string = 'map-view';
  private defaultSearchModel: ISearchModel = {} as ISearchModel;
  isDesktop: boolean = false;
  isDoingSearch: boolean = false;
  isListView: boolean = false;
  isMapView: boolean = true;
  isMineView: boolean = false;
  isSearchView: boolean = false;
  @ViewChild('locator', { static: false }) locator: any;
  lookupPlacesThenSocialsRef: Subscription;
  lookupSocialsThenPlacesRef: Subscription;
  mySocialsCreated$: Observable<ISocial[]>;
  mySocialsInterested$: Observable<ISocial[]>;
  noSocialsMessage: string = '';
  placeholders: string[] = [];
  places$: BehaviorSubject<IPlace[]> = new BehaviorSubject<IPlace[]>([]);
  private placesSubscription: Subscription;
  private platformSubscription: Subscription;
  searchModel: ISearchModel = {} as ISearchModel;
  showPlaceholders: boolean = true;
  socials$: BehaviorSubject<ISocial[]> = new BehaviorSubject<ISocial[]>([]);
  private socialsSubscription: Subscription;

  constructor(
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private constantsService: ConstantsService,
    private locationService: LocationService,
    private socialService: SocialService,
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private uiService: UIService
  ) {}

  ngOnInit() {
    // TODO: Is this pattern better than using a getter, in terms of change detection?
    this.CONSTANTS = this.constantsService.constants.SOCIAL.PAGE;

    this.platformSubscription = this.uiService.isLargeDisplay$.subscribe(isLargeDisplay => {
      this.isDesktop = isLargeDisplay;
      if (this.isDesktop) {
        this.isSearchView = true;
      } else {
        this.isSearchView = this.currentView === 'search-view';
      }

      // if changing from small to large and you were on the search view, change to the list view
      // two-way binding triggers onChangeView
      if (isLargeDisplay && this.currentView === 'search-view') {
        this.currentView = 'list-view';
      }
    });
    this.subscriptionService.add(this.platformSubscription);
    // if default currentView changes, need to update this event too
    this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, AnalyticsAction.SOCIAL_VIEW_MAP);
    this.init();
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.socialsSubscription);
    this.subscriptionService.clearSubscription(this.placesSubscription);
    this.subscriptionService.clearSubscription(this.platformSubscription);
  }

  init() {
    this.authService._userProfileSubject
      .pipe(
        skipWhile(x => x == null),
        take(1)
      )
      .subscribe((profile: UserObject) => {
        this.country = this.authService.isAdmin() ? null : profile.country;
        this.loadMySocials(profile.uid);
      });
  }

  loadMySocials(uid: string) {
    this.mySocialsCreated$ = this.socialService.getSocialsCreatedByMember(uid);
    this.mySocialsInterested$ = this.socialService.getSocialsOfInterestToMember(uid);
  }

  lookupSocialsThenPlaces() {
    //Cancel subscription to observable with previous values
    this.subscriptionService.clearSubscription(this.lookupSocialsThenPlacesRef);
    this.lookupSocialsThenPlacesRef = this.socialService.getSocialsThenPlaces(this.country).subscribe((results: IResultsModel) => {
      this.places$.next(results.places);
      this.socials$.next(this.getUniqueSocials(results.items));
    });
    this.subscriptionService.add(this.lookupSocialsThenPlacesRef);
  }

  lookupPlacesThenSocials() {
    //Cancel subscription to observable with previous values
    this.subscriptionService.clearSubscription(this.lookupPlacesThenSocialsRef);
    this.lookupPlacesThenSocialsRef = this.socialService.getPlacesThenSocials(this.searchModel, this.country).subscribe((results: IResultsModel) => {
      this.places$.next(results.places);
      this.socials$.next(this.getUniqueSocials(results.items));
    });
    this.subscriptionService.add(this.lookupPlacesThenSocialsRef);
  }

  onChangeView(event: any) {
    switch (event.detail.value) {
      case 'list-view':
        this.isListView = true;
        this.isMapView = false;
        this.isMineView = false;
        this.isSearchView = this.isDesktop;
        this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, AnalyticsAction.SOCIAL_VIEW_LIST);
        break;

      case 'mine-view':
        this.isListView = false;
        this.isMapView = false;
        this.isMineView = true;
        this.isSearchView = this.isDesktop;
        this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, AnalyticsAction.SOCIAL_VIEW_MINE);
        break;

      case 'search-view':
        this.isListView = false;
        this.isMapView = false;
        this.isMineView = false;
        this.isSearchView = true;
        break;

      case 'map-view':
      default:
        this.isListView = false;
        this.isMapView = true;
        this.isMineView = false;
        this.isSearchView = this.isDesktop;
        this.analyticsService.eventTrack(AnalyticsCategory.SOCIAL, AnalyticsAction.SOCIAL_VIEW_MAP);
        break;
    }
  }

  onMapBoundsChange(state: IMapState) {
    //Save initial map bounds
    if (this.isNullSearch(this.defaultSearchModel)) {
      this.defaultSearchModel.mapBounds = state.mapBounds;
      this.defaultSearchModel.mapZoom = state.mapZoom;
    }
    //Remove place from search model
    //TODO: Clear from search presenter?
    this.searchModel.place = null;
    this.searchModel.placeId = null;

    //Merge map bounds with any other search criteria
    this.searchModel.mapBounds = state.mapBounds;
    this.searchModel.mapZoom = state.mapZoom;

    // If we are doing lookupSocialsThenPlaces, prevent the fitToData method from triggering a new search
    if (!this.isDoingSearch) {
      // At zoom level of 10 a typical places lookup will read ~500 places.
      // If we zoom further out it is likely to be more efficient to just retrieve all socials than to find first the places displayed on the map
      if (state.mapZoom > 10) {
        this.lookupPlacesThenSocials();
      } else {
        this.lookupSocialsThenPlaces();
        this.isDoingSearch = true;
      }
    } else {
      this.isDoingSearch = false;
    }
  }

  onSearch(searchModel: ISearchModel) {
    //Close search panel on mobile, and show list or map depending on search
    if (this.isNullSearch(searchModel)) {
      this.currentView = this.isDesktop ? 'mine-view' : 'search-view';
    } else {
      this.currentView = !this.isNullOrEmpty(searchModel.placeId) ? 'map-view' : 'list-view';
    }

    //clear previous search criteria
    this.searchModel = searchModel;
    if (this.isNullSearch(searchModel)) {
      //Should only get here by clicking the reset button, move map to default coordinates
      this.searchModel = {} as ISearchModel;
      this.locator.map.setView(this.defaultSearchModel.mapBounds.getCenter(), this.defaultSearchModel.mapZoom);
      //moving the map triggers this.onMapBoundsChange which calls lookupPlacesThenSocials
    } else {
      if (!this.isNullOrEmpty(searchModel.placeId)) {
        //TODO Clear subscription
        //lookup coordinates for placename
        this.locationService.getPlace(searchModel.placeId).subscribe((location: IPlace) => {
          if (location == null || !location.coordinates) {
            this.toastService.presentToast('Sorry, that location is invalid.');
            return;
          }
          //Need to convert from Firestore geoPoint to Leaflet LatLng
          this.locator.map.setView([location.coordinates.latitude, location.coordinates.longitude], location.zoomTo);
          //Moving the map triggers this.onMapBoundsChange which updates the list of socials to show only those on the map
        });
      }
    }
  }

  private getUniqueSocials(socials: any[]) {
    //Put socials into key->value map. Any duplicates simply overwrite the earlier value. Convert back to an array
    const uniqueSocials = new Map(socials.map(social => [social.uid, social]));
    return Array.from(uniqueSocials.values());
  }

  private isNullOrEmpty(query: string) {
    return query == null || query.trim().length === 0;
  }

  private isNullSearch(searchModel: ISearchModel) {
    if (Object.keys(searchModel).length === 0) return true;
    return Object.values(searchModel).filter(x => x).length === 0;
  }
}
