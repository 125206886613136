import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StateGuard } from '@shared/services/messages/state.guard';
import { SharedModule } from '@shared/shared.module';
import { ComposeComponent } from './compose/compose.component';
import { ThreadDetailComponent } from './thread-detail/thread-detail.component';
import { ThreadListComponent } from './thread-list/thread-list.component';
import { ThreadMembersComponent } from './thread-members/thread-members.component';
import { ThreadSettingsComponent } from './thread-settings/thread-settings.component';

export const routes: Routes = [
  {
    path: 'compose',
    component: ComposeComponent,
    canDeactivate: [StateGuard],
    data: {
      title: 'Compose message'
    }
  },
  {
    path: 'compose/:id',
    component: ComposeComponent,
    canDeactivate: [StateGuard],
    data: {
      title: 'Compose message'
    }
  },
  {
    path: ':id/members',
    component: ThreadMembersComponent,
    canDeactivate: [StateGuard],
    data: {
      title: 'Thread members'
    }
  },
  {
    path: ':id/settings',
    component: ThreadSettingsComponent,
    canDeactivate: [StateGuard],
    data: {
      title: 'Thread settings'
    }
  },
  {
    path: ':id',
    component: ThreadDetailComponent,
    canDeactivate: [StateGuard],
    data: {
      title: 'Message details'
    }
  }
];

@NgModule({
  imports: [SharedModule, RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ThreadsRoutingModule {}
