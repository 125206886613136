import { Injectable } from '@angular/core';
import { ALL_COUNTRIES, Country, CountryKey } from '@shared/constants/country';
import { IContactFormSetting } from '@shared/models/contact-form-setting';
import { AuthService } from '@shared/services/auth.service';
import { EmailService } from '@shared/services/email/email.service';
import { ToastService } from '@shared/services/toast.service';
import { IContactForm } from './models/contact-form';

@Injectable({
  providedIn: 'root'
})
export class ChirpyContactFormService {
  hasDisclaimerValidationError: boolean;

  constructor(private authService: AuthService, private emailService: EmailService, private toastService: ToastService) {}

  getEmailBody(contactForm: IContactForm) {
    const options = {
      title: 'Contact Form',
      text: null,
      data: contactForm
    };

    return this.emailService.getEmailBody(options);
  }

  submitContactForm(recipient: string, subject: string, data: any, country: CountryKey, recipientAddress: string = ''): Promise<any> {
    const title = 'Contact Form';
    const text = '';
    if (country == null) country = this.authService.getCountry() || ALL_COUNTRIES;

    switch (recipient) {
      case 'hostSupport':
        return this.emailService.sendEmailToHostSupport(subject, title, text, data, country);

      case 'travel':
        return this.emailService.sendEmailToTravel(subject, title, text, data, country);

      case 'support':
      default:
        const windowLocation = window.location
          .toString()
          .toUpperCase()
          .split('.')
          .pop();
        let countryPrefix = '';
        //Don't include a country prefix on localhost or .web.app domains
        //TODO: Is this still necessary? If so, do we need to add other countries?
        if (windowLocation === Country.NEW_ZEALAND || windowLocation === Country.AUSTRALIA) {
          countryPrefix = `${windowLocation} `;
        }
        return this.emailService.sendEmailToSupport(`${countryPrefix}${subject}`, title, text, data);
    }
  }

  validateForm(contactForm: IContactForm, fields: IContactFormSetting[]) {
    const hasDisclaimer = fields.some(x => x.id === 'disclaimer');
    if (hasDisclaimer && contactForm.disclaimer !== true) {
      const message = 'Please accept the terms and conditions.';
      this.toastService.presentToast(message);
      this.hasDisclaimerValidationError = true;
      return false;
    }

    const requiredFields = fields.filter(x => x.required === true);
    if (requiredFields.length === 0) return true;

    const missingFields = [];
    for (const field of requiredFields) {
      if (contactForm[field.id].length === 0) missingFields.push(field.title);
    }

    if (missingFields.length > 0) {
      const message = 'Please enter a ' + missingFields.join(', ');
      this.toastService.presentToast(message);
      return false;
    }

    this.hasDisclaimerValidationError = false;

    return true;
  }
}
