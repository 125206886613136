import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CatchupClaimPresenter {
  get catchupId() {
    let formValue = this.form.value;
    return formValue.catchupId;
  }

  form = this.formBuilder.group({
    catchupId: ['', Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}
}
