import { Component, OnDestroy, OnInit } from '@angular/core';
import { IContact } from '@shared/models/messages/contact';
import { IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { ConstantsService } from '@shared/services/constants.service';
import { ContactService } from '@shared/services/messages/contact.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit, OnDestroy {
  get CONSTANTS() {
    return this.constantsService.constants.MESSAGES.CONTACTS.LIST;
  }

  contactRef: Subscription;
  contacts: IContact[] = [];
  contactsLoaded: boolean = false;
  hasContacts: boolean = false;
  readonly memberThreadType = IMemberThreadRelatedType.Member;
  onboardingMessage: string;

  constructor(private analyticsService: AnalyticsService, private contactService: ContactService, private constantsService: ConstantsService, private subscriptionService: SubscriptionService) {}

  getDetailRoute(contact: IContact): any {
    return '/messages/contacts/' + contact.relatedId;
  }

  ionViewWillEnter() {
    this.analyticsService.eventTrack(AnalyticsCategory.MESSAGES, AnalyticsAction.MESSAGES_VIEW_CONTACT_LIST);
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.contactRef);
  }

  ngOnInit() {
    this.contactRef = this.contactService.getContacts().subscribe((contacts: IContact[]) => {
      this.contacts = contacts;
      this.contactsLoaded = true;
      this.hasContacts = !!this.contacts.length;
    });
    this.subscriptionService.add(this.contactRef);

    this.onboardingMessage = this.CONSTANTS.onboardingMessage;
  }
}
