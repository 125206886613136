import { Country } from '@shared/constants/country';
import { Gender } from '@shared/constants/gender';
import { GroupType } from '@shared/constants/group-type';
import { MediaCollectionType } from '@shared/constants/media-collection-type';
import { Role } from '@infrastructure/constants/role';
import { TripSection } from '@shared/constants/trip-section';
import { IConstants } from '../constants';

// Constants for OnlyFriends
export const FriendsConstants: IConstants = {
  ACCOUNT: {
    CANCEL: {
      enabled: true,
      instructions: `Select an option below. Please note, Only Friends has a No Refund policy.`,
      reasons: [`The community is not active enough`, `There are no OF Social groups in my area`, `The web app is hard to use`, `I am no longer interested`],
      showExtraInstructions: `We're sorry to see you go. To help us continually improve Only Friends, we'd appreciate knowing why you're cancelling your membership.`,
      supportRequest: `I would like OF Support to contact me about changing to a different subscription plan.`,
      yesNoQuestion: `Did you participate in any of the chat groups?`
    }
  },
  ADVERTISERS: {
    branding: ``,
    enabled: false,
    headerText: ``,
    noAdvertisersMessage: ``,
    ANNOUNCEMENTS: {
      branding: ``,
      pageHeading: ``,
      titlePlaceholder: ``
    },
    DETAIL: {
      addAnnouncementButton: ``,
      disclaimer: ``,
      doesNotExist: ``,
      noAnnouncements: ``,
      pageHeading: ``,
      placeholder: ``
    },
    EDIT: {
      contactPlaceholder: ``,
      descriptionPlaceholder: ``,
      displayNamePlaceholder: ``,
      loadingErrorMessage: ``,
      pageHeading: ``,
      permissionErrorMessage: ``,
      regionInstructions: ``
    },
    SEARCH: {
      heading: ``,
      namePlaceholder: ``
    },
    SERVICE: {
      deleteHeader: ``,
      deleteMessage: ``,
      deletedAnnouncementToast: ``,
      deletedToast: ``,
      updatedToast: ``
    }
  },
  AGE_RANGES: [],
  APP: {
    allowOtherCountries: true,
    altText: `OnlyFriends logo`,
    countries: {
      Australia: Country.AUSTRALIA,
      Canada: Country.CANADA,
      Germany: Country.GERMANY,
      'Rest of the World': Country.REST_OF_WORLD,
      'United Kingdom': Country.UNITED_KINGDOM,
      'United States': Country.UNITED_STATES
    },
    logo: `/assets/friends/banner-logo.svg`
  },
  ASSETS: {
    BADGES: {
      icon: {},
      role: {
        [Role.ADMIN]: {
          src: ``,
          title: ``
        },
        [Role.ADVISOR]: {
          src: ``,
          title: ``
        },
        [Role.COHOST]: {
          src: ``,
          title: ``
        },
        [Role.HOST]: {
          src: `/assets/friends/host-icon.svg`,
          title: `OF Host`
        },
        [Role.MEMBER]: {
          src: ``,
          title: ``
        }
      }
    },
    defaultGroupImage: `/assets/friends/local-catchup.png`,
    defaultImage: `/assets/friends/of.svg`,
    placeholderImage: `/assets/friends/of.svg`
  },
  CATCHUPS: {
    branding: `CatchUp`,
    CLAIM: {
      disclaimer: `By filling in this form, you are declaring that the information you have provided is true and correct. We will perform random checks to ensure coin claims are genuine. Any member found to be falsely claiming CatchUp coins will forfeit their entire coins balance.`,
      excludeRoles: [],
      excludeRoleMessages: {},
      instructions: `<p>To claim coins for attending a CatchUp, you must:</p>
                  <ul>
                    <li>Have RSVPed before the CatchUp</li>
                    <li>Attend the CatchUp</li>
                    <li>Claim your coins within two days of attending the CatchUp</li>
                  </ul>
                <p>You can claim coins for a maximum of 10 CatchUps per calendar month.</p>`,
      noCatchups: `Sorry, there are no CatchUps that you can claim coins for.`,
      PERIOD: {
        first: 0,
        second: 0
      }
    },
    DETAIL: {
      disclaimer: `<p>OnlyFriends reserves the right not to publish any events or listings that it feels are not suitable or appropriate for the OnlyFriends community. All events and listings are reviewed by a member of the OnlyFriends team before they are published.</p>`,
      noMessages: `No messages yet`,
      underReview: `Please check that all information entered is correct before you approve this catchup.`
    },
    EDIT: {
      disclaimer: `OnlyFriends reserves the right not to publish any events or listings that it feels are not suitable or appropriate for the OnlyFriends community. All events and listings are reviewed by a member of the OnlyFriends team before they are published.`,
      noResultsText: `CatchUps cannot be shared with groups which already have a CatchUp on the same day.`,
      populateDescription: ``,
      selectDateFirstMessage: `Please select a date first, so we can check for potential clashes.`
    },
    LIST: {
      isSearchEnabled: true,
      title: `CatchUps & Events`
    },
    TEMPLATE: {
      instructions: `<p>Enter as many or as few fields as you need to facilitate creating CatchUps. The only required field is the template name.</p>
      <p>The changes you make to this template will not affect existing CatchUps.</p>`
    }
  },
  COINS: {
    branding: `coins`,
    earnMoreButton: `Boost Balance`,
    earnMoreCallToAction: `Discover more ways to boost your OF Coins Balance`,
    enabled: true,
    levels: [
      {
        icon: `/assets/friends/coins/level-1-icon.svg`,
        name: `level 1`,
        order: 0,
        threshold: 0
      },
      {
        icon: `/assets/friends/coins/level-2-icon.svg`,
        name: `level 2`,
        order: 1,
        threshold: 3000
      },
      {
        icon: `/assets/friends/coins/level-3-icon.svg`,
        name: `level 3`,
        order: 2,
        threshold: 6000
      },
      {
        icon: `/assets/friends/coins/level-4-icon.svg`,
        name: `level 4`,
        order: 3,
        threshold: 10000
      }
    ],
    levelSuffix: ``,
    nextLevelMessage: `get to your`,
    retainLevelMessage: `retain your`,
    showLevelsFrom: -1,
    site: `OF`,
    title: `OF Coins`
  },
  CONTACT_US: {
    emailSubject: `OnlyFriends Contact Us`,
    footerText: `We promise to email you back within 48 hours. It should be within 24 hours but we like to under-promise and over-deliver.`
  },
  EVENTS: {
    CLAIM: {
      disclaimer: `By filling in this form, you are declaring that the information you have provided is true and correct. We will perform random checks to ensure coin claims are genuine. Any member found to be falsely claiming CatchUp coins will forfeit their entire coins balance.`,
      instructions: `<p>To claim coins for attending a CatchUp, you must:</p>
                  <ul>
                    <li>Have RSVPed before the CatchUp</li>
                    <li>Attend the CatchUp</li>
                    <li>Claim your coins within two days of attending the CatchUp</li>
                  </ul>
                <p>You can claim coins for a maximum of 10 CatchUps per calendar month.</p>`,
      noCatchups: `Sorry, there are no CatchUps that you can claim coins for.`
    },
    DETAIL: {
      disclaimer: `<p>OnlyFriends reserves the right not to publish any events or listings that it feels are not suitable or appropriate for the OnlyFriends community. All events and listings are reviewed by a member of the OnlyFriends team before they are published.</p>`,
      title: `Detail title`,
      underReview: `Please check that all information entered is correct before you approve this catchup.`
    },
    EDIT: {
      addressDisclaimer: ``,
      createButtonText: `Create`,
      createPageHeading: `Create an Event`,
      disclaimer: `OnlyFriends reserves the right not to publish any events or listings that it feels are not suitable or appropriate for the WeLIP community. All events and listings are reviewed by a member of the WeLIP team before they are published.`,
      mapInstructions: ``,
      updateButtonText: `Update`,
      updatePageHeading: `Update Event`
    },
    INTERESTED: {
      title: `Interested title`
    },
    LIST: {
      isEventSubmissionEnabled: false,
      isSearchEnabled: true,
      title: `CatchUps & Events`
    },
    REGISTER: {
      instructions: `<p>Thank you for your interest in this OF travel event. Please submit the form below to register for the trip with our travel agent and receive the special discounted OnlyFriends price.</p>`,
      subject: `OF Travel Registration`,
      successMessage: `Thank you for registering your interest. We have forwarded your details to our travel agent who will be in contact shortly.`
    },
    SEARCH: {
      heading: `Search heading`
    }
  },
  GENDERS: [
    {
      name: 'She/Her',
      value: Gender.SHE_HER,
      avatarUrl: `/assets/friends/gender/female-icon.svg`
    },
    {
      name: 'He/Him',
      value: Gender.HE_HIM,
      avatarUrl: `/assets/friends/gender/male-icon.svg`
    },
    {
      name: 'They/Them',
      value: Gender.THEY_THEM,
      avatarUrl: `/assets/friends/gender/no-gender-icon.svg`,
      default: 'true'
    }
  ],
  GROUPS: {
    disableMemberJoinedNotifyHost: false,
    CHIT_CHAT: {
      branding: `Chat`,
      noMessages: `No-one's said anything yet. Why not get the ball rolling? Post a message at the bottom of the screen.`
    },
    DETAIL: {
      catchupsHeading: `CatchUps`,
      disclaimer: `OnlyFriends reserves the right not to publish any events or listings that it feels are not suitable or appropriate for the OnlyFriends community. All events and listings are reviewed by a member of the OnlyFriends team before they are published.`,
      noSocialsMessage: `If you want to organise a YOLO event in your area, click the button below, enter the details and share it with your local groups.`,
      socialsButton: `Create YOLO`,
      socialsHeading: `YOLO`
    },
    EDIT: {
      createButtonText: `Create`,
      createPageHeading: `Create a group`,
      hasAdvisors: false,
      hasCohosts: false,
      updateButtonText: `Update`,
      updatePageHeading: `Update group`
    },
    GROUP_TYPES: [
      {
        name: 'Hidden groups',
        groupType: GroupType.HIDDEN_GROUP,
        hidden: true,
        protected: true
      },
      {
        name: 'Groups',
        groupType: GroupType.GROUP,
        hidden: false,
        protected: false
      },
      {
        name: 'Special interest groups',
        groupType: GroupType.SPECIAL_INTEREST_GROUP,
        hidden: false,
        protected: false
      },
      {
        name: 'Travel groups',
        groupType: GroupType.TRAVEL_GROUP,
        hidden: false,
        protected: false
      },
      {
        name: 'Host-only groups',
        groupType: GroupType.HOST_ONLY_GROUP,
        hidden: false,
        protected: true
      }
    ],
    LIST: {
      headerText: null,
      mapHeaderText: null,
      noGroupsMessage: {
        [GroupType.GROUP]: `<p>Sorry, no local groups match your search criteria. If you'd like to suggest a new group, please <a routerLink="/contact-us">contact support</a>.</p>`,
        [GroupType.SPECIAL_INTEREST_GROUP]: `<p>Sorry, no virtual groups match your search criteria. If you'd like to suggest a new group, please <a routerLink="/contact-us">contact support</a>.</p>`
      }
    },
    NOTIFICATIONS: {
      instructions: `
      <li>When something new is posted in this group, we send you a notification.</li>
      <li>You can choose whether to receive an email straight away, or have it included in the "Daily Digest" email with all other notifications from the past 24 hours.</li>
      <li>Use the toggles below to select your preferences, then click "Save Changes".</li>
      <li>You can change settings for other notifications on the <a href="/account/notifications">Manage notifications</a> page, and you can see your notifications at any time by checking your <a href="/central/FOMO">FOMO</a>.
      `
    },
    SEARCH: {
      heading: `Search Groups`,
      sigLabel: `Special interest groups`
    }
  },
  LOGIN: {
    logo: `/assets/friends/login/hi.svg`,
    heading: null,
    text: ``,
    footerLogo: `/assets/friends/login/pinky-swear.svg`
  },
  MARKETPLACE: {
    branding: `Marketplace`,
    enabled: true,
    disclaimer: `<p>OF Marketplace is an online market exclusively available to OnlyFriends members. You may use it to sell something in any of the listed categories, as long as it complies with the following rules.</p>
<ul><li>You must be the legal owner of the item(s) you are selling.</li>
<li>You may not sell items that require special sales permits or are illegal (for example, counterfeit items, alcohol, drugs, tobacco, fireworks, or firearms), intimate apparel, offensive items, event tickets, or digital currency.</li>
<li>You can not include a private phone number or email address in your listing. To protect your privacy, all correspondence must be completed via OF Messages.</li></ul>
<p>OnlyFriends reserves the right to remove any listing that breaches these rules, or any other listing that it deems unsuitable. OF does not take any commission on, or get involved in any arrangements that you make between yourself and another member. If you receive an OF Message that is not appropriate to your listing, block and report the sender by clicking the BLOCK/REPORT button at the top of the OF Messages screen. Don't give out any personal information such as your home address, email address or phone number unless it is necessary to complete the transaction.</p>`,
    DETAIL: {
      codeOfConductWarning: `By contacting a member regarding their OF Marketplace listing you are agreeing to abide by the <a href="https://only-friends.helpscoutdocs.com/article/471-code-of-conduct" target="_blank">Code of Conduct</a>`,
      doesNotExist: `Listing does not exist`,
      pageHeading: `View OF Marketplace Listing`,
      underReview: `This listing is being reviewed by OF Support, it is not available to members yet.`
    },
    EDIT: {
      codeOfConductUrl: `https://only-friends.helpscoutdocs.com/article/471-code-of-conduct`,
      createButtonText: `Add Listing`,
      createPageHeading: `Add a listing`,
      descriptionPlaceholder: `Describe what you're selling`,
      locationPlaceholder: `Where you are, or where you're going`,
      locationWarning: `For your privacy please do not put your street address here.`,
      maxPhotos: 1,
      showGenderFilter: false,
      terms: [
        {
          id: `profileCurrent`,
          required: true,
          title: `My profile information is up to date`
        },
        {
          id: 'profilePhoto',
          required: true,
          title: `My profile includes a current photo`
        },
        {
          id: `disclaimer`,
          required: true,
          title: `I have read the OF Marketplace disclaimer below`
        },
        {
          id: `codeOfConduct`,
          required: true,
          title: `I will abide by the <a href="https://only-friends.helpscoutdocs.com/article/471-code-of-conduct" target="_blank" class="checkbox-label-link">Code of Conduct</a>`
        }
      ],
      termsHeading: `TERMS AND CONDITIONS`,
      titlePlaceholder: `e.g. Second-hand clothes, Hand-made jewellery, Typing service...`,
      updateButtonText: `Update Listing`,
      updatePageHeading: `Update listing`,
      updateMemberPageHeading: `Update listing for `
    },
    GENDERS: [],
    INTRO: {
      callToAction: `What do you want to do?`,
      pageHeading: `OF Marketplace`,
      pageIntro: `<p>OF Marketplace is an online market, exclusive to OnlyFriends members, where you can sell your preloved items or things that you have crafted. It's so easy - just use the button below to list the item you'd like to sell. Or search the OF Marketplace to see if anyone is selling something you'd like to buy. You can use the OF Marketplace to sell things like second-hand clothing, accessories, books, CDs, DVDs, musical instruments, and more. Or handcrafted items like paintings, jewellery, sewing, beads, charms, or woodwork. You can also sell services like typing, music lessons, or lawn mowing. Best of all, there are no success fees!</p>`,
      pageTitle: `Welcome to OF Marketplace`,
      options: [
        {
          heading: `Look for something to buy`,
          routerLink: `/marketplace`,
          text: `Discover what OF members have for sale`
        },
        {
          heading: `Sell something`,
          routerLink: `/marketplace/update/new`,
          text: `List something that you'd like to sell to other members`
        }
      ]
    },
    LIST: {
      pageHeading: `OF Marketplace`,
      noRecordsFound: `No listings found matching your criteria.`
    },
    SEARCH: {
      categoryPlaceholder: `Search categories...`,
      pageHeading: `Search Listings`
    },
    SERVICE: {
      deleteHeader: `Delete listing`,
      deleteMessage: `This will permanently delete this listing. Are you sure?`,
      deletedToast: `OF Marketplace listing has been deleted.`,
      hiddenToast: `Your listing has been hidden.`,
      updatedProfileToast: `Listing has been `,
      publishApproved: `Your listing has been published, it is now visible to all members.`,
      publishUnapproved: `Your listing has been published. It will be displayed to other members when an Admin has reviewed and approved your listing.`
    }
  },
  MEDIA: {
    branding: `OF Pix`,
    quotas: {
      [MediaCollectionType.GROUP]: 50,
      [MediaCollectionType.MEMBER]: 10,
      [MediaCollectionType.PUBLIC]: -1
    }
  },
  MEETING_PLACE: {
    instructions: ``,
    pageHeading: `Find Friends`,
    searchHeading: `Search Friends`
  },
  MESSAGES: {
    CONTACTS: {
      LIST: {
        onboardingMessage: `You haven't added any contacts yet.\nClick the + button below to add an OnlyFriends member to your contacts list.`
      }
    },
    MORE: {
      ARCHIVE: {
        button: 'Archive',
        header: `Archive messages`,
        message: `Select a date to archive all threads where the last message was received before this date`,
        onboardingMessage: `You haven't archived any messages yet.`
      }
    },
    THREADS: {
      DETAIL: {
        isBlockedByMessage: `You cannot reply to this member because they have blocked you.`,
        isBlockedMessage: `You cannot reply to this member because you have blocked them.`,
        isSoleMemberMessage: `You cannot reply to this person because they are no longer a member of OnlyFriends.`,
        moderatedMessage: `This message has been moderated by OF Support`
      },
      LIST: {
        onboardingMessage: `You haven't received any messages yet.\nClick the + button below to send a message to another OnlyFriends member.`
      }
    }
  },
  MY_CHIRPY: {
    tabs: {
      mine: {
        title: `Central`,
        src: `/assets/friends/central/central.svg`
      },
      news: {
        title: `News`,
        src: `/assets/friends/central/news.svg`
      },
      activity: {
        title: `FOMO`,
        src: `/assets/friends/central/fomo.svg`
      }
    },
    iconLayout: `icon-start`,
    boxes: [
      {
        color: `primary`,
        isButtonEnabled: true,
        link: `/catchups`,
        placeholder: `RSVP to a CatchUp`,
        size: 12,
        title: `My Events`,
        type: `events`
      },
      {
        align: 'right',
        color: `primary`,
        title: `Claim coins`,
        type: `buttons`
      },
      {
        color: `secondary`,
        link: `/groups`,
        placeholder: `Join a group`,
        title: `My Groups`,
        type: `groups`
      },
      {
        color: `primary`,
        link: `/coins`,
        placeholder: `View all details`,
        title: `My Coins`,
        type: `coins`
      },
      {
        color: `secondary`,
        groupType: GroupType.HIDDEN_GROUP,
        hideIfEmpty: true,
        link: `/pages/travel-au`,
        placeholder: `Find a trip`,
        title: `My Trips`,
        type: `groups`
      },
      {
        color: `tertiary`,
        link: `/share/update/new`,
        placeholder: `Add a new OF Share listing`,
        title: `Latest OF Share listings`,
        type: `share`
      }
    ]
  },
  NOTIFICATIONS: {
    headings: {
      Catchup: 'CatchUps',
      Country: 'OF News',
      Group: 'Groups',
      Member: 'Mentions',
      Message: 'Messages',
      Place: 'Nearby',
      Social: 'YOLO'
    },
    instructions: `
      <li>By default we send you a "Daily Digest" email once a day. This list all your notifications which have occurred for the previous 24 hours.</li>
      <li>If you do not wish to receive this Daily Digest, turn Digest to OFF with the toggle button below and click "Save Changes".</li>
      <li>However, if you wish to be notified immediately about messages and activities on OnlyFriends, use the settings on this page to turn on the notifications you are most interested in.</li>
      <li>And remember, you can check your notifications at any time by going to your <a href="/central/fomo">FOMO</a>.
    `,
    settings: {
      Message: [
        {
          id: 'directMessage',
          title: 'Messages only to you',
          required: true,
          type: 'advanced'
        },
        {
          id: 'conversation',
          required: true,
          title: 'Messages to you and others',
          type: 'advanced'
        }
        // Too confusing to members to distinguish between MemberThreadType.Conversation and MemberThreadType.Group
      ],
      Group: [
        {
          id: 'catchupCreatedNotifyMember',
          required: true,
          title: 'New CatchUp',
          type: 'advanced'
        },
        {
          id: 'newChitChat',
          required: true,
          title: 'New chit-chat',
          type: 'advanced'
        },
        {
          id: 'newChitChatReply',
          required: true,
          title: `New reply in a conversation you're part of`,
          type: 'advanced'
        },
        {
          id: 'newNotice',
          required: true,
          title: 'New group notice',
          type: 'advanced'
        },
        {
          id: 'newMedia',
          isHidden: true,
          required: true,
          title: 'New group photo',
          type: 'advanced'
        },
        {
          id: 'newMediaComment',
          isHidden: true,
          required: true,
          title: 'New comment on group photo',
          type: 'advanced'
        },
        {
          id: 'socialCreatedNotifyGroup',
          required: true,
          title: 'New YOLO event',
          type: 'advanced'
        }
      ],
      Catchup: [
        {
          id: 'catchupCancelledNotifyMember',
          required: true,
          title: 'CatchUp cancelled'
        },
        {
          id: 'newCatchupNote',
          required: true,
          title: 'New note on a CatchUp'
        },
        {
          id: 'newCatchupReply',
          required: true,
          title: 'New reply to a note on CatchUp'
        }
      ],
      Social: [
        {
          id: 'socialCancelledNotifyMember',
          required: true,
          title: 'Event cancelled'
        }
      ],
      Place: [
        {
          id: 'socialCreatedNotifyNearby',
          isHidden: true,
          required: true,
          title: 'New Event nearby'
        },
        {
          id: 'memberJoinedNotifyNearby',
          isHidden: true,
          required: true,
          title: 'New member joined nearby'
        }
      ],
      Country: [
        {
          id: 'newNews',
          required: true,
          title: 'OF News posted'
        }
      ]
    }
  },
  PROFILE: {
    DOB: {
      enabled: false,
      disclaimer: ``,
      icon: ``,
      label: ``,
      minAge: 0,
      maxAge: 110,
      placeholder: ``
    },
    genderLabel: `Pronouns`,
    INTERESTS: {
      enabled: true,
      icon: `heart`,
      label: `Interests`,
      placeholder: `Search for interests...`
    },
    onboardingMessage: `You know how the profile thing goes – OnlyFriends is the same. It's really important to fill this in, as it helps in finding other people who like to do similar stuff.`,
    ORGANISATIONS: {
      enabled: false,
      icon: ``,
      label: ``,
      placeholder: ``
    },
    PHONE: {
      enabled: false,
      disclaimer: ``,
      icon: ``,
      label: ``,
      placeholder: ``
    },
    TITLE: {
      enabled: false,
      icon: ``,
      label: ``,
      placeholder: ``
    }
  },
  RESET_PASSWORD: {
    HEADING: {
      auto: `Set Password`,
      manual: `Reset Password`
    },
    logo: `/assets/friends/banner-logo.svg`,
    MESSAGE: {
      auto: `You need to set a password. Please check your email address, and click the button. You will receive a link to create a password via email.`,
      manual: `Forgot your password? Please enter your email address. You will receive a link to create a new password via email.`
    }
  },
  ROMANCE: {
    enabled: false,
    DETAIL: {
      doesNotExist: `Dating profile does not exist`,
      pageHeading: `View Dating profile`,
      underReview: `This Dating profile is being reviewed by OnlyFriends Support, it is not available to members yet.`
    },
    EDIT: {
      createButtonText: `Create`,
      createPageHeading: `Create a dating profile`,
      disclaimer: `OnlyFriends reserves the right not to publish any events or listings that it feels are not suitable or appropriate for the OnlyFriends community. All events and listings are reviewed by a member of the OnlyFriends team before they are published.`,
      updateButtonText: `Update`,
      updatePageHeading: `Update my dating profile`,
      updateMemberPageHeading: `Update dating profile for `
    },
    GENDERS: [Gender.HE_HIM, Gender.SHE_HER, Gender.THEY_THEM], // This doesn't really work, but dating is disabled
    INTRO: {
      alert: {},
      disclaimer: ``,
      ineligibleInstructions: ``,
      pageHeading: ``,
      pageIntro: ``,
      pageTitle: ``,
      pictureInstructions: ``,
      requireListing: false,
      submitButton: ``,
      submitInstructions: ``
    },
    LIST: {
      pageHeading: `Dating`,
      noRecordsFound: `No dating profiles found matching your criteria`
    },
    SEARCH: {
      pageHeading: `Search Dating Profiles`
    },
    SERVICE: {
      branding: `Dating profile`,
      deleteHeader: `Delete dating profile`,
      deleteMessage: `This will permanently delete this dating profile. Are you sure?`,
      deletedToast: `Dating profile has been deleted.`,
      hiddenToast: `Your dating profile has been hidden.`,
      updatedProfileToast: `Your dating profile has been `,
      updatedMemberProfileToast: `Dating profile has been `,
      publishApproved: `Your dating profile has been published, it is now visible to all members.`,
      publishUnapproved: `Your dating profile has been published. It will be displayed to other members when an Admin has reviewed and approved your listing.`,
      reviewEmailSubject: `Romance profile to review:`
    }
  },
  SHARE: {
    branding: `Share`,
    enabled: true,
    disclaimer: `OF Share is a classifieds service exclusively available to OF members. You may use it to share something in any of the listed categories but you may not use it to advertise for a romantic partner. OnlyFriends does not take any commission on, or get involved in any arrangements that you make between yourself and another member. To protect your privacy, please keep your correspondence within the OF Messages platform until you are certain that the other party is trustworthy. If you receive a message that is not appropriate to your listing, block and report the sender by clicking the BLOCK/REPORT button at the top of the OF Messages screen. Don't give out any personal information such as your home address, email address or phone number until it is necessary. OnlyFriends reserves the right to limit the number of listings per member.`,
    DETAIL: {
      codeOfConductWarning: `By contacting a member regarding their OF Share listing you are agreeing to abide by the <a href="https://only-friends.helpscoutdocs.com/article/471-code-of-conduct" target="_blank">Code of Conduct</a>`,
      doesNotExist: `Listing does not exist`,
      pageHeading: `View listing`,
      underReview: `This listing is being reviewed by OnlyFriends Support, it is not available to members yet.`
    },
    EDIT: {
      codeOfConductUrl: ``,
      createButtonText: `Create`,
      createPageHeading: `Create a listing`,
      descriptionPlaceholder: `Describe what you're offering, or what you're looking for`,
      locationPlaceholder: `Where you are or where you're going...`,
      locationWarning: `For your privacy please do not put your street address here.`,
      maxPhotos: 1,
      showGenderFilter: false,
      terms: [
        {
          id: `profileCurrent`,
          required: true,
          title: `My profile information is up to date`
        },
        {
          id: 'profilePhoto',
          required: true,
          title: `My profile includes a current photo`
        },
        {
          id: `disclaimer`,
          required: true,
          title: `I have read the OF Share disclaimer below`
        },
        {
          id: `codeOfConduct`,
          required: true,
          title: `I will abide by the <a href="https://only-friends.helpscoutdocs.com/article/471-code-of-conduct" target="_blank" class="share-listing__code-of-conduct-link">Code of Conduct</a>`
        }
      ],
      termsHeading: `Terms and conditions`,
      titlePlaceholder: `e.g. Share a car, travel companion wanted, spare room available...`,
      updateButtonText: `Update`,
      updatePageHeading: `Update listing`,
      updateMemberPageHeading: `Update listing for `
    },
    GENDERS: [],
    INTRO: {
      callToAction: `What do you want to do?`,
      pageHeading: `OF Share`,
      pageIntro: `<p>OF Share is a classifieds service exclusive to OF members that helps you to share experiences, costs, and even your spare room with other OF members. It's so easy - just use the button below to list the thing you'd like to share. Or search the list of Shares to see if anyone is sharing something that you'd like to do too. You can use OF Share to do things like find a travel buddy, save money on excursions by booking together, share a ride between cities, earn some money from your spare room, or show people around your area. Plus, it's a fantastic way to make new friends!</p>`,
      pageTitle: `Welcome to OF Share`,
      options: [
        {
          heading: `Browse the listings`,
          routerLink: `/share`,
          text: ``
        },
        {
          heading: `Add a listing`,
          routerLink: `/share/update/new`,
          text: ``
        }
      ]
    },
    LIST: {
      pageHeading: `OF Share`,
      noRecordsFound: `No listings found matching your criteria.`
    },
    SEARCH: {
      pageHeading: `Search listings`
    },
    SERVICE: {
      branding: `OF Share listing`,
      deleteHeader: `Delete listing`,
      deleteMessage: `This will permanently delete this listing. Are you sure?`,
      deletedToast: `Listing has been deleted.`,
      hiddenToast: `Your listing has been hidden.`,
      updatedProfileToast: `Your listing has been `,
      publishApproved: `Your listing has been published, it is now visible to all members.`,
      publishUnapproved: `Your listing has been published. It will be displayed to other members when an Admin has reviewed and approved your listing.`
    }
  },
  SOCIAL: {
    branding: `YOLO`,
    DETAIL: {
      title: `View Event`,
      underReview: `This Event is being reviewed by OnlyFriends Support. It is not available for members yet.`
    },
    disclaimer: `We sometimes publish YOLO photos in our newsletters and occasional digital marketing. If you do not consent to your image being published please exclude yourself from any group photos.`,
    EDIT: {
      addressDisclaimer: `For the security of our members, Events must happen in a public place.`,
      createButtonText: `Create`,
      createPageHeading: `Create an Event`,
      mapInstructions: `Choose the city or state of your Event. We will use this to show a marker on the map.`,
      shareInstructions: `Advertise your event in groups that you belong to.`,
      updateButtonText: `Update`,
      updatePageHeading: `Update Event`
    },
    enabled: true,
    INTERESTED: {
      title: `Event Attendees`
    },
    LIST: {
      headerText: `<p>Welcome to YOLO! Use the search form or browse the list or map to find an event near you</p>`
    },
    PAGE: {
      cantCreateMessage: `As a host or co-host you cannot create YOLO events. If you want to create an event, please create a CatchUp instead.`,
      createButtonLink: `/yolo/update/new`,
      createButtonText: `Create an event`,
      mineLabel: `My Events`,
      shortName: `Event`,
      title: `YOLO`
    },
    SEARCH: {
      heading: `Search Events`
    }
  },
  TRIPS: {
    DETAIL: {
      draftNotice: ``,
      title: ``
    },
    REGISTER: {
      instructions: `<p>Thank you for your interest in this OF travel event. Please submit the form below to register for the trip with our travel agent and receive the special discounted OnlyFriends price.</p>`,
      subject: `OF Travel Registration`,
      successMessage: `Thank you for registering your interest. We have forwarded your details to our travel agent who will be in contact shortly.`
    },
    ROOM_SHARE: {
      descriptionIntro: ``,
      disclaimer: ``,
      listIntro: ``,
      requirementMessage: ``
    },
    SECTIONS: [TripSection.OVERVIEW, TripSection.ITINERARY, TripSection.PRICE, TripSection.INCLUDED, TripSection.NOT_INCLUDED, TripSection.OTHER_INFO, TripSection.TERMS_AND_CONDITIONS]
  },
  WELCOME: {
    PASSWORD: {
      buttonColor: `secondary`,
      pleaseWaitText: `<p>Hey friend,<br/>We're so glad you're here.</p>
              <p>The bots are just setting up your account.<br><strong>You know the drill, don't navigate away from this page. It might take up to 30 seconds.<strong></p>
              <p>While that's happening, you can start thinking about your preferred OnlyFriends username and password.</p>`,
      pleaseWaitImage: `/assets/friends/welcome/welcome.svg`,
      spinner: `/assets/friends/welcome/spinner.gif`,
      welcomeImage: `/assets/friends/welcome/yay-you.svg`,
      welcomeText: `<p>Thank you for joining our OnlyFriends community.</p>
      <p>As an early adopter member you will receive rewards and benefits that will grow as we do!</p>
      <p>First, choose a password.</p>`
    },
    SIGNUP: {
      buttonColor: `secondary`,
      countLastStep: true,
      DISPLAY_NAME: {
        image: `/assets/friends/welcome/username.svg`,
        instructions: `<p>Choose your username.</p>
        <p>This will appear on your profile and next to any comments or messages you send.</p>
        <p>Most people use their first name, followed by a number, letters or location. eg. Mia93</p>
        <p>For privacy reasons don't use your email address.Click save once you're done.</p>`,
        label: `Enter your username`,
        title: `Username`
      },
      GENDER: {
        image: `/assets/friends/welcome/pronouns.svg`,
        instructions: `<p>OnlyFriends is a safe space. Being active in the community is the best way to get the most out of your membership.</p>
        <p>Let others know how to refer to you.</p>`,
        label: ``,
        title: `Pronouns`
      },
      GROUPS: {
        image: `/assets/friends/welcome/catchup.svg`,
        INSTRUCTIONS: {
          all: `<p>The best way to connect with others and make friends is to join a "Catchup" group.</p>
                <p>We have a lot of groups on OnlyFriends and you can join as many as you like</p>`,
          foundGroups: `<p>Here is a list of groups closest to you.</p> <p><strong>Select the ones you’re interested in and hit Save.</p>`,
          noGroups: `<p>Sorry, we can't automatically determine the groups closest to you. Have a look on the Groups page after you’ve finished setting up your profile.</p>`
        },
        label: `Groups`,
        skip: `Continue without joining a group`,
        title: `Finally, join a Catchup Group`
      },
      LOCATION: {
        image: `/assets/friends/welcome/location.svg`,
        instructions: `<p>Attending CatchUps and Events is a big part of the OnlyFriends community. To make it easier for you to find your local groups, you need to add your location.</p>
        <p>Don't worry, for security purposes you will never be asked for your full address or to share it with anyone — EVER!</p>`,
        label: `Location`,
        title: `Location`
      },
      OPTIONAL: {
        image: ``,
        instructions: ``,
        title: ``,
        DOB: {
          disclaimer: ``,
          image: ``,
          instructions: ``,
          label: ``,
          minAge: 0,
          maxAge: 110,
          title: ``
        },
        PHONE: {
          disclaimer: ``,
          image: ``,
          instructions: ``,
          label: ``,
          title: ``
        }
      },
      progressColor: `tertiary`,
      showNavigation: false,
      steps: ['display-name', 'gender', 'location', 'groups', 'whats-next'],
      useProgressBar: false,
      WHATS_NEXT: {
        buttonLink: '/',
        buttonText: 'Get Started!',
        footer: ``,
        header: `<p>Your set up is done! You can update your profile at any time in the My Profile menu.</p>
        <p>What now?</p>
        <ol>
          <li>Check your <strong>email</strong> for some useful tips.</li>
          <li><strong>Discover</strong> what’s trending.</li>
          <li><strong>Join</strong> as many Groups, Clubs, or CatchUps as you like and <strong>chat</strong> with your Host!</li>
          <li><strong>Get involved and make friends.</strong></li>
        </ol>`,
        image: `/assets/friends/welcome/done.svg`,
        options: [],
        title: 'Done!'
      }
    }
  }
};
