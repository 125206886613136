import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ActivityComponent } from './components/activity/activity.component';
import { MineComponent } from './components/mine/mine.component';
import { ButtonsBoxComponent } from './components/mine/components/buttons-box/buttons-box.component';
import { CoinsBoxComponent } from './components/mine/components/coins-box/coins-box.component';
import { EventCalendarComponent } from './components/mine/components/event-calendar/event-calendar.component';
import { EventCardComponent } from './components/mine/components/event-card/event-card.component';
import { EventsBoxComponent } from './components/mine/components/events-box/events-box.component';
import { GroupsBoxComponent } from './components/mine/components/groups-box/groups-box.component';
import { MarketplaceBoxComponent } from './components/mine/components/marketplace-box/marketplace-box.component';
import { NextCatchupComponent } from './components/mine/components/next-catchup/next-catchup.component';
import { NoticeBoxComponent } from './components/mine/components/notice-box/notice-box.component';
import { ShareBoxComponent } from './components/mine/components/share-box/share-box.component';
import { NewsDetailComponent } from './components/news/components/news-detail/news-detail.component';
import { NewsEditComponent } from './components/news/components/news-edit/news-edit.component';
import { NewsListComponent } from './components/news/components/news-list/news-list.component';
import { MyChirpyPageRoutingModule } from './my-chirpy-routing.module';
import { MyChirpyPage } from './my-chirpy.page';

@NgModule({
  imports: [MyChirpyPageRoutingModule, SharedModule],
  declarations: [
    ActivityComponent,
    MineComponent,
    ButtonsBoxComponent,
    CoinsBoxComponent,
    EventCalendarComponent,
    EventCardComponent,
    EventsBoxComponent,
    GroupsBoxComponent,
    MarketplaceBoxComponent,
    NextCatchupComponent,
    NoticeBoxComponent,
    ShareBoxComponent,
    NewsDetailComponent,
    NewsEditComponent,
    NewsListComponent,
    MyChirpyPage
  ]
})
export class MyChirpyPageModule {}
