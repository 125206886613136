import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Country } from '@shared/constants/country';
import { IMarketplaceListing } from '@shared/models/marketplace/marketplace-listing';
import { IMarketplaceOptions } from '@shared/models/marketplace/marketplace-options';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { ConstantsService } from '@shared/services/constants.service';
import { MarketplaceService } from '@shared/services/marketplace/marketplace.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MarketplaceSearchPresenter } from './marketplace-search.presenter';

@Component({
  selector: 'app-marketplace-search',
  templateUrl: './marketplace-search.component.html',
  styleUrls: ['./marketplace-search.component.scss'],
  viewProviders: [MarketplaceSearchPresenter],
  encapsulation: ViewEncapsulation.None
})
export class MarketplaceSearchComponent implements OnInit {
  get CONSTANTS() {
    return this.constantsService.constants.MARKETPLACE.SEARCH;
  }

  get searchForm() {
    return this.presenter.form;
  }

  categoryLabel: string;
  categoryPlaceholder: string;
  COUNTRIES: Record<string, Country> = {};
  isCountrySearchEnabled: boolean;
  @Input() options: IMarketplaceOptions | null;
  @Output() search = new EventEmitter<any>();
  selectedItems: Record<string, string> = {};

  constructor(private analyticsService: AnalyticsService, private constantsService: ConstantsService, private marketplaceService: MarketplaceService, private presenter: MarketplaceSearchPresenter, private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss();
  }

  ngOnInit() {
    this.COUNTRIES = this.constantsService.constants.APP.countries;
    this.isCountrySearchEnabled = this.constantsService.constants.APP.allowOtherCountries;

    if (this.options != null) {
      this.presenter.setValue(this.options);
    } else {
      this.presenter.reset();
    }
  }

  onAddCategory(item: any) {
    // allow only a single category
    Object.keys(this.selectedItems).forEach(key => delete this.selectedItems[key]);
    this.selectedItems[item.key] = item.value;
    this.presenter.patchValue({ category: item.value });
  }

  onRemoveCategory(item: any) {
    delete this.selectedItems[item.key];
    this.presenter.patchValue({ category: 'all' });
  }

  onSearch() {
    const search = this.presenter.search();
    // TODO: How to handle the fact country search can be turned on/off for a given environment?
    // Logical order for fields is Country, Location, Category, but if country is turned off, field1 becomes location etc
    // For the moment use term3 for country, and remember that it is not actually the third field on the form
    this.analyticsService.eventTrack(AnalyticsCategory.MARKETPLACE, AnalyticsAction.MARKETPLACE_SEARCH, null, { term1: search.location, term2: search.category, term3: search.country });
    this.search.emit(search);
  }

  reset() {
    this.presenter.reset();
    const search = this.presenter.search();
    this.analyticsService.eventTrack(AnalyticsCategory.MARKETPLACE, AnalyticsAction.MARKETPLACE_CLEAR_SEARCH);
    this.search.emit(search);
  }

  searchCategories(search: string) {
    return this.marketplaceService.marketplaceCategories.pipe(
      map(categories => {
        return !search ? categories : categories.filter(c => c.toLowerCase().indexOf(search.toLowerCase()) > -1);
      })
    );
  }
}
