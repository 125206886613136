import { Injectable } from '@angular/core';
import { IAdvancedNotification } from '@shared/models/notifications/advanced-notification';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { IContactFormSetting } from '@shared/models/contact-form-setting';

@Injectable({
  providedIn: 'root'
})
export class NotificationsPresenter {
  get settings() {
    return this.form.value;
  }

  form = this.formBuilder.group({
    digestTime: ['', Validators.required],
    isEnabled: [false, Validators.required]
  });

  constructor(private formBuilder: FormBuilder) {}

  getControlValue(group: string, id: string) {
    const formGroup = this.form.get(group) as FormGroup;
    return formGroup.get(id).value;
  }

  init(settingsData: Record<string, IContactFormSetting[]>) {
    // Dynamically add settings toggles
    for (let [key, settings] of Object.entries(settingsData)) {
      this.form.addControl(key, new FormGroup({}));
      const settingsGroup = this.form.controls[key] as FormGroup;
      settings.forEach(setting => {
        settingsGroup.addControl(setting.id, new FormControl(false, [Validators.requiredTrue]));
      });
    }
  }

  setValue(data: Record<string, any>) {
    for (let [key, settings] of Object.entries(data)) {
      if ('digestTime' === key) {
        this.form.patchValue({ digestTime: settings });
      } else if ('isEnabled' === key) {
        this.form.patchValue({ isEnabled: settings });
      } else {
        const settingsGroup = this.form.controls[key] as FormGroup;
        if (settingsGroup) {
          let topLevelSettings = Object.assign({}, settings);
          for (let [settingKey, settingValue] of Object.entries(settings)) {
            if (settingValue.hasOwnProperty('default')) topLevelSettings[settingKey] = (settingValue as IAdvancedNotification).default;
          }
          settingsGroup.patchValue(topLevelSettings);
        }
      }
    }
  }
}
