import { EventEmitter, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MeetingPlaceSearchComponent } from './components';
import { ISearchModel } from '@shared/models/search-model';

@Injectable({
  providedIn: 'root'
})
export class MeetingPlacePresenter {
  constructor(private modalController: ModalController) {}

  async presentSearchModal(onSearch: (searchModel: ISearchModel) => void) {
    const eventEmitter = new EventEmitter<ISearchModel>();
    eventEmitter.subscribe($event => {
      modal.dismiss();
      return onSearch($event);
    });

    const modal = await this.modalController.create({
      component: MeetingPlaceSearchComponent,
      cssClass: 'meeting-place-search__modal',
      componentProps: {
        search: eventEmitter
      }
    });

    return await modal.present();
  }
}
