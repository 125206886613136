import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-games',
  templateUrl: 'games.page.html'
})
export class GamesPage {
  constructor(public platform: Platform) {}
}
