import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IMemberThread, IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { IMemberThreadType } from '@shared/models/messages/member-thread-type';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { ContactService } from '@shared/services/messages/contact.service';
import { MessageService } from '@shared/services/messages/message.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { UIService } from '@shared/services/ui.service';
import { UserService } from '@shared/services/user/user.service';
import { Subscription } from 'rxjs';
import { ThreadMembersService } from '../thread-members/thread-members.service';

@Component({
  selector: 'thread-settings',
  templateUrl: './thread-settings.component.html',
  styleUrls: ['./thread-settings.component.scss']
})
export class ThreadSettingsComponent implements OnDestroy {
  memberThread: IMemberThread;
  memberThreadType = IMemberThreadRelatedType.Member;
  contacts: UserObject[];
  hasContacts = false;
  threadRef: Subscription;
  memberNamesRef: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService,
    private userService: UserService,
    private threadMemberService: ThreadMembersService,
    private contactService: ContactService,
    private authService: AuthService,
    private uiService: UIService
  ) {}

  get isGroup() {
    return this.memberThread != null && this.memberThread.type === IMemberThreadType.Group;
  }

  get isDirectMessage() {
    return this.memberThread != null && this.memberThread.type === IMemberThreadType.DirectMessage;
  }

  get canAddMembers() {
    return this.memberThread != null && (this.authService.isImpersonating || this.memberThread.isOwner) && this.memberThread.type !== IMemberThreadType.DirectMessage;
  }

  get canRemoveMembers() {
    return this.memberThread != null && (this.authService.isImpersonating || this.memberThread.isOwner) && this.memberThread.type === IMemberThreadType.Group;
  }

  ionViewWillEnter() {
    // ngOnInit doesn't trigger if you come back from a navigation stack, ionViewWillEnter / ionViewDidEnter will.
    const threadId = this.route.snapshot.paramMap.get('id');
    if (threadId == null) return;

    this.threadRef = this.messageService.getMemberThread(threadId).subscribe(memberThread => {
      if (memberThread == null) return;
      if (this.memberThread != null) {
        const hasDifferentMembers = this.memberThread.relatedIds.sort().toString() !== memberThread.relatedIds.sort().toString();
        if (hasDifferentMembers) {
          // the members list has changed, kill the previous subscription and setup a new one.
          if (this.memberNamesRef) this.memberNamesRef.unsubscribe();
        } else {
          const hasChanges = JSON.stringify(this.memberThread) !== JSON.stringify(memberThread);
          if (hasChanges) this.memberThread = memberThread;
          return;
        }
      }

      this.memberThread = memberThread;
      this.subscriptionService.clearSubscription(this.memberNamesRef);
      this.memberNamesRef = this.threadMemberService.getMemberNamesInMemberThread(memberThread).subscribe((users: UserObject[]) => {
        if (users == null) return;

        // dont show yourself in the Members list, you can click Leave conversation to remove yourself, use the
        // Members list and "Add members" page to add/remove members other than yourself.
        const filteredUsers = users.filter(x => x.uid !== this.messageService.user.uid);
        this.contacts = filteredUsers;
        this.hasContacts = true;
      });
      this.subscriptionService.add(this.memberNamesRef);
    });
    this.subscriptionService.add(this.threadRef);
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

  ionViewWillLeave() {
    this.unsubscribeAll();
  }

  unsubscribeAll() {
    this.subscriptionService.clearSubscription(this.threadRef);
    this.subscriptionService.clearSubscription(this.memberNamesRef);
  }

  getDetailRoute(user: UserObject) {
    return `/members/${user.uid}`;
  }

  onRemoveMemberFromThread(memberId, memberName) {
    this.threadMemberService.removeMemberFromThread(this.memberThread, memberId, memberName);
  }
}
