import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { IMessage } from '@shared/models/messages/message';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { MessageService } from '@shared/services/messages/message.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'chirpy-message-detail',
  templateUrl: './chirpy-message-detail.component.html',
  styleUrls: ['./chirpy-message-detail.component.scss']
})
export class ChirpyMessageDetailComponent {
  get CONSTANTS() {
    return this.constantsService.constants.MESSAGES.THREADS.DETAIL;
  }

  get hasImage() {
    return this.message.fileName != null && this.message.thumbnailURL != null && this.message.imageURL != null;
  }

  get hasTextContent() {
    return this.message.content.trim().length > 0;
  }

  get isAttachingImage() {
    return this.message.fileName != null && !this.message.thumbnailURL && !this.message.imageURL;
  }

  get isModerated() {
    return !!this.message.isModerated;
  }

  get routerLink() {
    return this.avatarLinksToProfile === true ? ['/members/', this.message.senderId] : ['/messages/contacts/', this.message.senderId];
  }

  @Input() avatarLinksToProfile: boolean = false;
  @Input() hasMultiplePeople: boolean;
  @Input() isAdmin: boolean;
  @Input() isWidget: boolean;
  @Output() linkClicked = new EventEmitter();
  @Input() message: IMessage;
  @Input() noPadding: boolean = false;
  @Output() onDelete = new EventEmitter();
  @Output() onModerate = new EventEmitter();
  @Input() oneLine: boolean = false;
  @Input() showAvatar: boolean = true;
  @Input() threadId: string;
  @Input() userId: string;

  constructor(private alertController: AlertController, private constantsService: ConstantsService, private dateTimeService: DateTimeService, private messageService: MessageService) {}

  async deleteMessage(slider: any) {
    const alert = await this.alertController.create({
      header: `Delete message`,
      message: `Are you sure you want to permanently delete this message? This action cannot be reversed`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: `Delete`,
          handler: data => this.deleteMessageHandler(slider)
        }
      ]
    });

    await alert.present();
  }

  deleteMessageHandler(slider: any) {
    this.messageService.deleteMessage(this.threadId, this.message.uid).then(result => {
      slider.close();
      this.onDelete.emit(this.message.uid); // Hide message in UI without need for reload
      // Update lastMessage in memberThreads
      if (!this.threadId.startsWith('group_')) {
        this.messageService
          .getLastMessage(this.threadId)
          .pipe(first())
          .subscribe((messages: IMessage[]) => {
            const data = messages.length > 0 ? { dateTimeLastUpdated: messages[0].dateTimeLastUpdated, lastMessage: messages[0].content } : { dateTimeLastUpdated: Date.now(), lastMessage: null };
            this.messageService.updateMemberThreads(this.threadId, data);
          });
      }
    });
  }

  getDateFormat(message: IMessage) {
    return this.dateTimeService.getDateFormat(this.message.dateTimeSent);
  }

  getMessageWidthClass() {
    if (this.isWidget) {
      return this.noPadding ? 'message message--no-padding' : 'message';
    } else {
      return this.isSender() ? 'message message--sender' : 'message message--no-sender';
    }
  }

  isSender() {
    return this.message.senderId === this.userId;
  }

  async moderateMessage(slider: any) {
    const alert = await this.alertController.create({
      header: `Moderate message`,
      message: `Are you sure you want to moderate this message? This will replace the content of the message with "${this.CONSTANTS.moderatedMessage}", and cannot be reversed`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: `Moderate`,
          handler: data => this.moderateMessageHandler(slider)
        }
      ]
    });

    await alert.present();
  }

  moderateMessageHandler(slider: any) {
    const data = { content: this.CONSTANTS.moderatedMessage, isModerated: true };
    this.messageService.updateMessage(this.threadId, this.message.uid, data).then(result => {
      slider.close();
      this.onModerate.emit({ uid: this.message.uid, ...data }); // Hide message in UI without need for reload

      // Update lastMessage in memberThreads
      if (!this.threadId.startsWith('group_')) {
        this.messageService
          .getLastMessage(this.threadId)
          .pipe(first(x => !!x))
          .subscribe((messages: IMessage[]) => {
            if (messages.length > 0) this.messageService.updateMemberThreads(this.threadId, { dateTimeLastUpdated: messages[0].dateTimeLastUpdated, lastMessage: messages[0].content });
          });
      }
    });
  }

  onClick(title: string) {
    this.linkClicked.emit(title);
  }
}
