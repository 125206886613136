export enum AnalyticsCategory {
  ACCOUNT = 'account',
  ADVERTISERS = 'advertisers',
  AUTH = 'auth',
  BINGO = 'bingo',
  CATCHUPS = 'catchups',
  COINS = 'coins',
  EVENTS = 'events',
  FORMS = 'forms',
  FUN = 'fun',
  GROUPS = 'groups',
  MARKETPLACE = 'marketplace',
  MEETING_PLACE = 'meeting-place',
  MEMBERS = 'members',
  MESSAGES = 'messages',
  MY_CHIRPY = 'my-chirpy',
  NAVIGATION = 'navigation',
  NOTIFICATION = 'notification',
  PIX = 'pix',
  ROMANCE = 'romance',
  SHARE = 'share',
  SIGNUP = 'signup',
  SOCIAL = 'social',
  TRIPS = 'trips',
  VIRTUAL_CATCHUPS = 'virtual-catchups'
}
