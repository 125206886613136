import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { ITrip } from '@shared/models/trips/trip';
import { UserObject } from '@shared/models/user-object';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { TripService } from '@shared/services/trips/trip.service';
import { UIService } from '@shared/services/ui.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'trip-detail-header',
  templateUrl: './trip-detail-header.component.html',
  styleUrls: ['./trip-detail-header.component.scss']
})
export class TripDetailHeaderComponent implements OnChanges, OnInit {
  get isLargeDisplay() {
    return this.uiService.isLargeDisplay;
  }

  countries: Country[];
  interested: string;
  isMemberInterested: boolean = false;
  @Input() trip: ITrip;
  @Input() showButton = true;
  @Input() member: UserObject;

  constructor(private analyticsService: AnalyticsService, private authService: AuthService, private constantsService: ConstantsService, private tripService: TripService, private uiService: UIService) {}

  ngOnChanges(changes: SimpleChanges) {
    const newTrip = changes.trip.currentValue;
    this.interested = this.tripService.numberInterestedAndGoing(newTrip);
    this.isMemberInterested = this.tripService.isMemberInterested(this.member.uid, this.trip);
  }

  ngOnInit() {
    this.authService._userProfileSubject.pipe(first(x => !!x)).subscribe(profile => {
      this.countries = this.authService.isAdmin([AdminRole.TRAVEL]) ? Object.values(this.constantsService.constants.APP.countries) : [this.authService._userProfileSubject.value.country];
    });
  }

  onClick() {
    this.analyticsService.eventTrack(AnalyticsCategory.TRIPS, AnalyticsAction.TRIPS_VIEW_REGISTRATION, this.trip.title);
  }
}
