import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ITransformFunction } from '@shared/models/transform-function';
import { UserObject } from '@shared/models/user-object';
import { IChirpyMemberListButton } from './chirpy-member-list-button';

@Component({
  selector: 'chirpy-member-list',
  styleUrls: ['chirpy-member-list.component.scss'],
  templateUrl: './chirpy-member-list.component.html'
})
export class ChirpyMemberListComponent {
  additionalData: Record<string, any> = {};
  @Output() buttonClicked = new EventEmitter();
  @Input() buttons: IChirpyMemberListButton[];
  @Input() buttonsAfter: boolean = false;
  @Input() dataMap: Record<string, ITransformFunction> = {};
  @Input() isAdmin: boolean = false;
  @Output() memberClicked = new EventEmitter();
  @Input() members: UserObject[] = [];
  @Input() memberData: Record<string, any> = {};
  @Input() noMembersMessage = 'No members to display';

  ngOnChanges(changes: SimpleChanges) {
    if ((this.members || []).length > 0 && Object.keys(this.memberData).length > 0 && Object.keys(this.dataMap).length > 0) {
      const newAdditionalData = {};
      const keys = Object.keys(this.dataMap);
      for (let member of this.members) {
        newAdditionalData[member.uid] = keys.filter(key => (this.memberData[member.uid] || {}).hasOwnProperty(key)).map(key => this.dataMap[key](this.memberData[member.uid][key]));
      }
      this.additionalData = newAdditionalData;
    } else {
      this.additionalData = {};
    }
  }

  onButtonClick(member: UserObject, command: string) {
    this.buttonClicked.emit({ member, command });
  }

  onMemberClicked(member: UserObject) {
    this.memberClicked.emit(member);
  }
}
