import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController } from '@ionic/angular';
import { AuthService } from '@shared/services/auth.service';
import { UIService } from '@shared/services/ui.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { GameStatus } from '../game/game-status';
import { GameObject } from '../models/game-object';
import { NumberObject } from '../models/number-object';
import { GameService } from '../services/game-service';

interface BallObject {
  number: string;
  color: string;
}

@Component({
  selector: 'app-bingo-admin',
  templateUrl: 'admin.page.html'
})
export class AdminPage implements OnInit {
  private generatedBalls = [];
  loadedGame: Observable<GameObject>;
  private loadedGameSubject: BehaviorSubject<GameObject> = new BehaviorSubject(null);
  loadedNumbers: Observable<NumberObject[]>;

  slideOpts = {
    initialSlide: 0,
    slidesPerView: 12,
    slidesPerColumn: 1,
    spaceBetween: 8,
    effect: 'slide'
  };

  readonly GameStatus = GameStatus;

  constructor(private gameService: GameService, private route: ActivatedRoute, private router: Router, public alertController: AlertController, private afs: AngularFirestore, private authService: AuthService, private uiService: UIService, public loadingController: LoadingController) {
    for (let i = 1; i <= 90; i++) {
      this.generatedBalls.push({ number: i.toString(), color: this.getRandomHexColor() });
    }
  }

  ngOnInit() {
    this.loadedGame = this.loadedGameSubject.asObservable();
    this.route.paramMap.subscribe(params => {
      const param = params.get('gameId');
      if (param == null) {
        this.loadedGame = of(new GameObject());
      } else {
        this.gameService.games
          .pipe(
            skipWhile(gs => !gs),
            map(gs => gs.filter(g => g.uid === param)[0])
          )
          .subscribe(g => {
            this.loadedGameSubject.next(g);
          });

        const game = this.afs.doc<GameObject>('games/bingo/games/' + param);

        this.authService.userProfileObservable.subscribe(userProfile => {
          if (userProfile != null) {
            // const players = game.collection<PlayerObject>('Players');
            // const rounds = game.collection<RoundObject>('Rounds');
            const numbers = game.collection<NumberObject>('Numbers', ref => ref.orderBy('timestamp', 'desc'));
            //  this.loadedPlayers = players.valueChanges();
            this.loadedNumbers = numbers.valueChanges();
            // this.loadedRounds = rounds.snapshotChanges().pipe(map(actions =>
            //   actions.map(a => {
            //     const newRound: RoundObject = new RoundObject().deserialize(a.payload.doc.data());
            //     newRound.uid = a.payload.doc.id;
            //     return newRound;
            //   })));
          }
        });
      }
    });
  }

  async stopGame(game: GameObject) {
    this.uiService.showLoadingOverlay('Stopping Game...');
    game.status = GameStatus.CANCELLED;
    return this.gameService
      .saveGame(game)
      .then((savedGame: GameObject) => {
        this.uiService.hideLoadingOverlay();
        // Navigate to new UID
        this.router.navigate(['/games/bingo/admin', savedGame.uid]);
      })
      .catch(async err => {
        this.uiService.hideLoadingOverlay();
        const alert = await this.alertController.create({
          header: 'Stop Game',
          subHeader: 'Error',
          message: err,
          buttons: ['OK']
        });

        await alert.present();
      });
  }
  async saveGame(game: GameObject) {
    this.uiService.showLoadingOverlay('Saving Game...');
    return this.gameService
      .saveGame(game)
      .then((savedGame: GameObject) => {
        this.uiService.hideLoadingOverlay();
        // Navigate to new UID
        this.router.navigate(['/games/bingo/admin', savedGame.uid]);
      })
      .catch(async err => {
        this.uiService.hideLoadingOverlay();

        const alert = await this.alertController.create({
          header: 'Save Game',
          subHeader: 'Error',
          message: err,
          buttons: ['OK']
        });

        await alert.present();
      });
  }

  numberTrackBy(index: number, item: NumberObject) {
    return item.uid;
  }

  getRandomHexColor(): string {
    // tslint:disable-next-line: no-bitwise
    return '#' + ((Math.random() * 0xffffff) << 0).toString(16);
  }
}
