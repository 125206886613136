import { Injectable } from '@angular/core';
import { NotificationTarget } from '@shared/constants/notification-target';
import { IAdvancedNotification } from '@shared/models/notifications/advanced-notification';
import { INotificationSettings } from '@shared/models/notifications/notification-settings';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';
import FieldValue = firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class NotificationDatabase extends BaseDatabase {
  createNotificationForMember(target: NotificationTarget, targetId: string, memberId: string, value: Record<string, boolean>) {
    return this.updateSubDocument(this.COLLECTION.NOTIFICATIONS, target, this.COLLECTION.TARGETS, targetId, { memberIds: FieldValue.arrayUnion(memberId), [memberId]: value });
  }

  deleteNotification(target: NotificationTarget, targetId: string) {
    return this.deleteSubDocument(this.COLLECTION.NOTIFICATIONS, target, this.COLLECTION.TARGETS, targetId);
  }

  getNotificationsForMember(uid: string, target: NotificationTarget): Observable<any> {
    return this.getDocumentsBySubcollectionQuery(this.COLLECTION.NOTIFICATIONS, target, this.COLLECTION.TARGETS, ref => ref.where('memberIds', 'array-contains', uid));
  }

  getSettingsForMember(uid: string): Observable<INotificationSettings> {
    return this.getDocument<INotificationSettings>(this.COLLECTION.NOTIFICATION_SETTINGS, uid);
  }

  removeNotificationForMember(target: NotificationTarget, targetId: string, memberId: string) {
    return this.updateSubDocument(this.COLLECTION.NOTIFICATIONS, target, this.COLLECTION.TARGETS, targetId, { memberIds: FieldValue.arrayRemove(memberId), [memberId]: FieldValue.delete() });
  }

  updateIndividualSettingForMember(uid: string, target: NotificationTarget, data: Record<string, IAdvancedNotification>) {
    return this.updateDocument(this.COLLECTION.NOTIFICATION_SETTINGS, uid, { [target]: data });
  }

  updateNotificationForMember(target: NotificationTarget, targetId: string, memberId: string, value: boolean) {
    return this.updateSubDocument(this.COLLECTION.NOTIFICATIONS, target, this.COLLECTION.TARGETS, targetId, { [memberId]: value });
  }

  updateSettingsForMember(uid: string, data: any, merge: boolean = true) {
    return this.updateDocument(this.COLLECTION.NOTIFICATION_SETTINGS, uid, data, merge);
  }
}
