import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MembersGuard } from '@shared/guards/members-guard';
import { ActivityComponent } from './components/activity/activity.component';
import { MineComponent } from './components/mine/mine.component';
import { NewsDetailComponent } from './components/news/components/news-detail/news-detail.component';
import { NewsEditComponent } from './components/news/components/news-edit/news-edit.component';
import { NewsListComponent } from './components/news/components/news-list/news-list.component';
import { MyChirpyPage } from './my-chirpy.page';

const routes: Routes = [
  {
    path: '',
    component: MyChirpyPage,
    canActivate: [MembersGuard],
    data: {
      title: 'My Chirpy' // TODO Check that this is set appropriately for Friends in white label service
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'mine'
      },
      {
        path: 'activity', // TODO: How to we easily make this match the branding?
        component: ActivityComponent,
        canActivate: [MembersGuard],
        data: {
          title: 'Activity'
        }
      },
      {
        path: 'mine',
        component: MineComponent,
        canActivate: [MembersGuard],
        data: {
          title: 'Mine'
        }
      },
      {
        path: 'news',
        component: NewsListComponent,
        canActivate: [MembersGuard],
        data: {
          title: 'News'
        }
      }
    ]
  },
  {
    path: 'news/:id',
    component: NewsDetailComponent,
    canActivate: [MembersGuard],
    data: {
      title: 'News detail'
    }
  },
  {
    path: 'news/:id/edit',
    component: NewsEditComponent,
    canActivate: [MembersGuard],
    data: {
      title: 'Edit news'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyChirpyPageRoutingModule {}
