import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CustomControl } from '@shared/components/custom-control';
import { SelectType } from '@shared/constants/select-type';
import { IMemberThreadRelatedType } from '@shared/models/messages/member-thread';
import { ToastService } from '@shared/services/toast.service';
import { ChirpyItemSearchComponent } from './chirpy-item-search/chirpy-item-search.component';
import { ChirpyPlaceSearchComponent } from './chirpy-place-search/chirpy-place-search.component';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ChirpySelectItemsComponent),
  multi: true
};

@Component({
  selector: 'chirpy-select-items',
  providers: [CUSTOM_VALUE_ACCESSOR],
  templateUrl: './chirpy-select-items.component.html',
  styleUrls: ['./chirpy-select-items.component.scss']
})
export class ChirpySelectItemsComponent extends CustomControl {
  @Input() addItem;
  @Input() canRemoveItems: boolean = false;
  @Input() condition: boolean = true;
  @Input() conditionFailedMessage: string = '';
  @Input() editButtonText: string = 'Edit';
  @Input() label: string;
  @Input() memberType = IMemberThreadRelatedType.Member;
  @Input() modalPlaceholder: string;
  @Input() noResultsText = 'Contact us if you would like us to add another item to the list.';
  @Input() placeholder: string;
  @Input() prepopulateResults: boolean;
  @Input() removeItem;
  @Input() search;
  searchResults: Record<string, any> = {}; // key, value
  @Input() selectedItems;
  @Input() selectType: string = SelectType.ITEM;
  @Input() showButton: boolean = false;
  @Input() showChips: boolean = false;
  @Input() showLabel: boolean = true;
  @Input() singleSelect: boolean = false;

  constructor(private modalController: ModalController, private toastService: ToastService) {
    super();
  }

  async presentModal() {
    if (this.condition) {
      const modal = await this.getModal();
      return modal.present();
    } else {
      this.toastService.presentToast(this.conditionFailedMessage || `Error: Cannot select items`);
    }
  }

  private getModal() {
    switch (this.selectType) {
      case SelectType.PLACE:
        return this.modalController.create({
          component: ChirpyPlaceSearchComponent,
          cssClass: 'chirpy-place-search__modal',
          componentProps: {
            addItem: this.addItem,
            canRemoveItems: this.canRemoveItems,
            label: this.label,
            noResultsText: this.noResultsText,
            placeholder: this.modalPlaceholder || this.placeholder,
            prepopulateResults: this.prepopulateResults,
            removeItem: this.removeItem,
            search: this.search,
            selectedItems: this.selectedItems
          }
        });

      case SelectType.ITEM:
        return this.modalController.create({
          component: ChirpyItemSearchComponent,
          cssClass: 'chirpy-item-search__modal',
          componentProps: {
            addItem: this.addItem,
            canRemoveItems: this.canRemoveItems,
            label: this.label,
            noResultsText: this.noResultsText,
            memberType: this.memberType,
            placeholder: this.placeholder,
            removeItem: this.removeItem,
            search: this.search,
            selectedItems: this.selectedItems,
            singleSelect: this.singleSelect
          }
        });
    }
  }
}
