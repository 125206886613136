import { Component, ViewChild } from '@angular/core';
import { ChirpyMemberSearchComponent } from '@shared/components/chirpy-member-search/chirpy-member-search.component';
import { AddContactService } from './add-contact.service';

@Component({
  selector: 'add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['add-contact.component.scss']
})
export class AddContactComponent {
  @ViewChild(ChirpyMemberSearchComponent, { static: true }) memberSearchComponent: ChirpyMemberSearchComponent;

  constructor(private service: AddContactService) {}

  get membersToAdd() {
    return this.service.filterSearchResults(this.memberSearchComponent.searchResults, false);
  }

  get membersToRemove() {
    return this.service.filterSearchResults(this.memberSearchComponent.searchResults, true);
  }

  ionViewWillEnter() {
    // ngOnInit doesn't trigger if you come back from a navigation stack, ionViewWillEnter / ionViewDidEnter will.
    this.memberSearchComponent.clearSearch();
    this.memberSearchComponent.focusSearchBar();
  }

  onAddMemberToContacts({ memberId, memberName }) {
    this.service.addMemberToContacts(memberId, memberName);
  }

  onRemoveMemberFromContacts({ memberId, memberName }) {
    this.service.removeMemberFromContacts(memberId, memberName);
  }
}
