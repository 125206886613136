import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { UserObject } from '@shared/models/user-object';
import { AuthService } from '@shared/services/auth.service';
import { ToastService } from '@shared/services/toast.service';
import { UserDatabase } from '@shared/services/user/user.database';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-member-detail',
  styleUrls: ['./member-detail.page.scss'],
  templateUrl: './member-detail.page.html'
})
export class MemberDetailPage implements OnInit {
  canDelete: boolean = false;
  canImpersonate: boolean = false;
  member$: Observable<UserObject>;
  memberDetails: Record<string, string[]> = {
    IDs: ['uid', 'chargebeeId', 'email'],
    Names: ['displayName', 'fullName', 'firstName', 'lastName', 'searchName'],
    Location: ['locality', 'region', 'country', 'domain', 'coordinates', 'placeId', 'zoomTo', 'utcOffset'],
    Status: ['badges', 'role', 'adminRoles', 'membershipType', 'membershipStatus', 'canAccessRomance', 'isOnboarding', 'isVisible', 'messageNotificationCount', 'dateTimeNewsLastRead'],
    Profile: ['bitAboutMe', 'gender', 'catchupGroupIds', 'interests', 'organisations', 'dateOfBirth', 'dateRegistered', 'photoURL', 'largePhotoURL'],
    Coins: ['coinsBalance', 'coinsLevel', 'statusPoints', 'statusDate']
  };
  uid: string;

  constructor(private alertController: AlertController, private authService: AuthService, private fns: AngularFireFunctions, private route: ActivatedRoute, private router: Router, private toastService: ToastService, private userDatabase: UserDatabase) {}

  isDateField(field: string, value: any) {
    return field.toLowerCase().includes('date') && Number.isInteger(value);
  }

  ngOnInit() {
    this.canDelete = this.authService.isAdmin([AdminRole.SUPER, AdminRole.DELETE]);
    this.canImpersonate = this.authService.isAdmin([AdminRole.SUPER, AdminRole.CONCIERGE]); // Keep these roles in sync with the permissions on admin.page.ts
    this.uid = this.route.snapshot.paramMap.get('id');
    this.member$ = combineLatest(this.userDatabase.getPublicMemberData(this.uid), this.userDatabase.getPrivateMemberData(this.uid)).pipe(
      map(([publicData, privateData]) => {
        const member = Object.assign({}, publicData, privateData) as UserObject;
        this.getOtherKeys(member);
        return member;
      })
    );
  }

  async onDeleteMember(displayName: string = '', fullName: string = '') {
    const memberName = displayName || fullName;
    const message = `<p>Delete ${memberName ? memberName + "'s" : ''} account?</p><p>THIS CANNOT BE UNDONE AND TAKES EFFECT IMMEDIATELY!</p>`;
    const alert = await this.alertController.create({
      header: `Delete member`,
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: `Delete`,
          handler: () => this.onDeleteMemberHandler(memberName)
        }
      ]
    });

    await alert.present();
  }

  onDeleteMemberHandler(memberName: string) {
    let message = '';

    const callable = this.fns.httpsCallable('deleteMemberNow');
    callable({ uid: this.uid })
      .toPromise()
      .then(result => {
        message = `Member ${memberName} deleted`;
      })
      .catch(err => {
        message = `Couldn't delete member ${memberName}: ${err}`;
      })
      .finally(() => {
        this.toastService.presentToast(message);
        this.router.navigate(['/admin']);
      });
  }

  onImpersonateMember() {
    const ref = this.authService.impersonateMember(this.uid).subscribe(() => {
      this.router.navigate(['/admin']).then(() => {
        ref.unsubscribe();
      });
    });
  }

  sortNull() {}

  private getOtherKeys(member: UserObject) {
    const knownKeys = Array.prototype.concat(...Object.values(this.memberDetails));
    const memberKeys = Object.keys(member);
    const otherKeys = memberKeys.filter(x => !knownKeys.includes(x));
    this.memberDetails.Other = otherKeys;
  }
}
