export class Gender {
  static readonly EVERYONE = 'Everyone'; // For Romance searches
  static readonly FEMALE = 'Female';
  static readonly MALE = 'Male';
  static readonly NOT_SPECIFIED = 'Not specified';

  // TODO: Implement pronouns as a separate enum?
  static readonly HE_HIM = 'He/Him';
  static readonly SHE_HER = 'She/Her';
  static readonly THEY_THEM = 'They/Them';
}
