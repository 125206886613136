import { Injectable } from '@angular/core';
import { AdminRole } from '@shared/constants/admin-role';
import { Country } from '@shared/constants/country';
import { ISelectOption } from '@shared/models/select-option';
import { UserObject } from '@shared/models/user-object';
import { IUtcOffset } from '@shared/models/utc-offset';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { skipWhile, take } from 'rxjs/operators';
import { Regions } from './models/regions';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  advertiserRegions: ISelectOption[];
  allRegions: ISelectOption[];
  catchupRegions: ISelectOption[];
  groupRegions: ISelectOption[];
  locationRegions: ISelectOption[];
  regionLabel: string;
  searchCatchupRegions: ISelectOption[];
  readonly SHOW_ALL_REGIONS_OPTION: ISelectOption = { name: `Show all`, value: '', area: '' };

  // choose States or Regions depending on members country.
  constructor(private authService: AuthService, private constantsService: ConstantsService) {
    const allowedCountries: string[] = Object.values(this.constantsService.constants.APP.countries);
    this.allRegions = Object.entries(Regions)
      .filter(([country, regions]) => allowedCountries.includes(country))
      .map(([country, regions]) => regions.data || [])
      .flat(1);

    authService._userProfileSubject
      .pipe(
        skipWhile(x => !x),
        take(1)
      )
      .subscribe(user => {
        // TODO: If you log out then log back in as a member from a different country, this block will not get called again
        // so it will show the wrong regions. This should not affect members.
        const regions = this.getRegions(user.country, this.authService.isAdmin([AdminRole.HOSTS, AdminRole.MODERATOR]));

        this.regionLabel = regions.label;

        this.catchupRegions = regions.data;
        this.searchCatchupRegions = [this.SHOW_ALL_REGIONS_OPTION].concat(regions.data);

        this.advertiserRegions = this.getAdvertiserOptions(user).concat(regions.data);
        this.groupRegions = this.getVirtualOptions(user).concat(regions.data);

        this.locationRegions = this.getRegions(user.country, false).data;
      });
  }

  getAdvertiserOptions(user: UserObject): ISelectOption[] {
    // User.country gets set at account creation
    let countries = [user.country];
    let options = [
      { name: `Global`, value: `Global`, area: 'G' } // area = text on icon, not used for advertiser
    ];
    if (this.authService.isAdmin([AdminRole.HOSTS])) {
      countries = Object.values(this.constantsService.constants.APP.countries);
    }
    for (const country of countries) {
      const newOption = { name: `Nationwide (${country})`, value: `${country}`, area: `${country}` };
      options = [...options, newOption];
    }
    return options;
  }

  getCountryForRegion(value: string): string[] {
    if (value === '') return [];

    if (value === 'Virtual_GLOBAL') {
      return Object.values(this.constantsService.constants.APP.countries);
    }

    if (value.startsWith('Virtual')) {
      // Virtual regions for specific countries have form Virtual_AU or Virtual_AU_NZ
      const countries = value.split('_').slice(1);
      // Use members country as a fallback (shouldn't happen).
      return countries.length > 0 ? countries : [this.authService._userProfileSubject.value.country];
    }

    if (Regions[Country.AUSTRALIA].data.find(r => r.value === value)) {
      return [Country.AUSTRALIA];
    }

    if (Regions[Country.CANADA].data.find(r => r.value === value)) {
      return [Country.CANADA];
    }

    if (Regions[Country.GERMANY].data.find(r => r.value === value)) {
      return [Country.GERMANY];
    }

    if (Regions[Country.NEW_ZEALAND].data.find(r => r.value === value)) {
      return [Country.NEW_ZEALAND];
    }

    if (Regions[Country.REST_OF_WORLD].data.find(r => r.value === value)) {
      return [Country.REST_OF_WORLD];
    }

    if (Regions[Country.SINGAPORE].data.find(r => r.value === value)) {
      return [Country.SINGAPORE];
    }

    if (Regions[Country.UNITED_KINGDOM].data.find(r => r.value === value)) {
      return [Country.UNITED_KINGDOM];
    }

    if (Regions[Country.UNITED_STATES].data.find(r => r.value === value)) {
      return [Country.UNITED_STATES];
    }

    throw { message: `Can't find country for region ${value}` };
  }

  getRegionLabelForCountry(country: Country) {
    const showAll = country === null;
    return this.getRegions(country, showAll).label;
  }

  getRegionLabelForRegion(region: string) {
    const countries = this.getCountryForRegion(region);
    const country = countries.length > 0 ? (countries[0] as Country) : null;
    const showAll = country === null;
    return this.getRegions(country, showAll).label;
  }

  getRegionsForCountry(country: Country, selectAllOption: boolean = false) {
    const showForAllCountries = country === null;
    const regions = this.getRegions(country, showForAllCountries).data;
    return selectAllOption ? [this.SHOW_ALL_REGIONS_OPTION].concat(regions) : regions;
  }

  getRegionFromLocality(locality: string = '') {
    const parts = locality.split(',');
    if (parts.length === 0) return '';

    // Locality has format (suburb, ) town, region, country
    return parts[parts.length - 2].trim();
  }

  getRegions(country: Country, showAll: boolean) {
    if (showAll) {
      // Admins see all States and Regions.
      return { label: 'State/Region', data: this.allRegions };
    }
    // Default to Australia when there's no country set for this member.
    country = country || Country.AUSTRALIA;

    // Hard code reference data to prevent unnecessary database reads.
    // In future, store regions in Firebase and store in localStorage with expiry?
    const region = Regions[country];

    return region;
  }

  getUtcOffset(region: string, country: Country): IUtcOffset {
    const fallback: IUtcOffset = { hours: 0, minutes: 0 };
    const data = this.getRegionsForCountry(country);
    const matches = data.filter(x => x.value === region); // There should only be one match as locationRegions is filtered to the current country
    return matches.length > 0 ? { hours: matches[0].utcHours, minutes: matches[0].utcMinutes } : fallback;
  }

  getVirtualOptions(user: UserObject): ISelectOption[] {
    // Default to Australia if there's no country set for this member.
    let countries = [user.country] || [Country.AUSTRALIA];
    let options = [
      { name: `Virtual (Global)`, value: `Virtual_GLOBAL`, area: 'V' } // area is text on icon in e.g. catchup-list
    ];
    if (this.authService.isAdmin([AdminRole.HOSTS])) {
      countries = Object.values(this.constantsService.constants.APP.countries);
    }
    for (const country of countries) {
      const newOption = { name: `Virtual (${country})`, value: `Virtual_${country}`, area: 'V' };
      options = [...options, newOption];
    }
    return options;
  }
}
