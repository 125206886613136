import { Component, OnInit } from '@angular/core';
import { MailStatus } from '@shared/constants/mail-status';
import { IColumn } from '@shared/models/column';
import { IDataTableConfig } from '@shared/models/data-table-config';
import { DefaultEmailOptions, IEmailOptions } from '@shared/models/email-options';
import { IEmail } from '@shared/models/email';
import { IMailTemplate } from '@shared/models/mail-template';
import { DateTimeService } from '@shared/services/date-time.service';
import { EmailService } from '@shared/services/email/email.service';
import { firestore } from 'firebase/app';
import { first } from 'rxjs/operators';
import { IInput } from '@shared/models/input';
import { BaseLogPage } from '../../base-log/base-log.page';

@Component({
  selector: 'app-logs-email-log',
  templateUrl: '../../base-log/base-log.page.html'
})
export class EmailLogComponent extends BaseLogPage {
  columns: IColumn[] = [
    { label: 'Date sent', field: 'dateSent', class: 'data-table__column-responsive-bold' },
    { label: 'To', field: 'to' },
    { label: 'Mail template', field: 'mailTemplate' },
    { label: 'Subject', field: 'subject', class: 'data-table__column-responsive-italic' },
    { label: 'Delivery status', field: 'deliveryStatus' }
  ];
  config: IDataTableConfig = {
    routerLink: {
      path: '/admin/email-detail',
      id: [':uid']
    }
  };
  DEFAULT_OPTIONS = DefaultEmailOptions;
  interfaceOptions: any = { cssClass: 'wide-select' };
  MAIL_STATUS = MailStatus;
  searchModel: IEmailOptions = DefaultEmailOptions;
  templateData: Record<string, IMailTemplate> = {};
  title = `Email log`;
  //fields is out of alphabetical order so we can reference other instance members
  fields: IInput[] = [
    {
      label: 'Sent after: ',
      name: 'sentAfter',
      type: 'datetime-local'
    },
    {
      label: 'Sent before: ',
      name: 'sentBefore',
      type: 'datetime-local'
    },
    {
      label: 'Email address: ',
      name: 'to',
      placeholder: 'Enter email address',
      type: 'email'
    },
    {
      label: 'Template: ',
      name: 'template',
      placeholder: 'Select template',
      type: 'select$',
      attributes: {
        interfaceOptions: this.interfaceOptions,
        multiple: true
      },
      value: null // initialise with mailTemplates$ later
    },
    {
      label: 'Status: ',
      name: 'status',
      placeholder: 'Select status',
      type: 'select',
      attributes: {},
      value: this.MAIL_STATUS
    }
  ];

  addZeroWidthSpace(text: string) {
    return text.replace(/([@.A-Z])/g, '\u200b$1'); //Add zero-width space after @ in email address or capital letter in camelCase name
  }

  constructor(dateTimeService: DateTimeService, private emailService: EmailService) {
    super(dateTimeService);
    this.loadMailTemplates();
    this.initPresenter();
  }

  initPresenter() {
    const templateIndex = this.fields.findIndex(x => x.name === 'template');
    if (templateIndex > -1) this.fields[templateIndex].value = this.emailService.templateNames$;
  }

  loadData(append: boolean = false, lastTimestamp: firestore.Timestamp = null) {
    this.emailService
      .getEmails(this.searchModel, lastTimestamp)
      .pipe(first()) // emails are sent so frequently that I've made this page require a manual refresh to see the latest otherwise it's going to flicker like crazy when the UI is updated with a dozen entries every few seconds.
      .subscribe(emails => {
        this.isLoading = false;
        if (emails.length > 0) {
          this.canLoadMore = true;
          const lastEmail = emails[emails.length - 1];
          if (lastEmail.delivery.startTime != null) this.lastTimestamp = lastEmail.delivery.startTime;
        }
        const viewEmails = [];
        emails.forEach(email => {
          viewEmails.push({
            uid: email.uid,
            dateSent: this.dateTimeService.formatDate(email.delivery.startTime.toDate()),
            deliveryStatus: email.delivery.state,
            to: this.addZeroWidthSpace(this.emailService.getToAddresses(email)),
            mailTemplate: email.template != null ? this.addZeroWidthSpace(email.template.name) : '(none)',
            subject: email.template != null ? this.populateSubject(email) : email.message.subject
          });
        });

        if (append) {
          this.data = [...this.data, ...viewEmails];
        } else {
          this.data = viewEmails;
        }
      });
  }

  loadMailTemplates() {
    this.emailService.loadMailTemplates();

    this.emailService.templateData$.subscribe((templates: IMailTemplate[]) => {
      if (templates.length === 0) return;

      this.templateData = templates.reduce((lookup: Record<string, IMailTemplate>, current: IMailTemplate) => {
        lookup[current.uid] = current;
        return lookup;
      }, {});
    });
  }

  populateSubject(email: IEmail) {
    let subject = '';
    if (email.template != null && this.templateData[email.template.name]) {
      subject = this.emailService.populateVariables(this.templateData[email.template.name].subject, email.template.data);
    }
    return subject;
  }

  updateSearchCriteria(options: IEmailOptions = null) {
    // TODO: Could almost do this with key: value from IEmailOptions, but that doesn't allow hiding default criteria
    const criteria = {};

    if (options == null) this.searchCriteria = criteria;
    if (options.template.length > 0) {
      for (let t of options.template) {
        criteria['template:' + t] = `Template: ${t}`;
      }
    }
    if ((options.to || '').trim().length > 0) criteria['to'] = `To: ${options.to}`;
    if ((options.sentAfter || '').trim().length > 0) criteria['sentAfter'] = `Sent after: ${options.sentAfter}`;
    if ((options.sentBefore || '').trim().length > 0) criteria['sentBefore'] = `Sent before: ${options.sentBefore}`;
    if ((options.status || '').trim().length > 0) criteria['status'] = `Status: ${options.status}`;
    this.searchCriteria = criteria;
  }
}
