import { Component, Input } from '@angular/core';

@Component({
  selector: 'bingo-ball',
  templateUrl: 'bingo-ball.component.html'
})
export class BingoBallComponent {
  @Input() numberText = 1;
  @Input() height = 64;
  @Input() width = 64;
  @Input() ballColorHex = '#000000';
  @Input() fillColorHex = '#FFFFFF';
  @Input() textColorHex = '#000000';

  constructor() {}

  onClick() {}

  getBallColor() {
    if (this.numberText > 75) {
      return '#FF5722';
    }
    if (this.numberText > 60) {
      return '#9C27B0';
    }
    if (this.numberText > 45) {
      return '#4CAF50';
    }
    if (this.numberText > 30) {
      return '#FFEB3B';
    }
    if (this.numberText > 15) {
      return '#3F51B5';
    } else {
      return '#F44336';
    }
  }
}
