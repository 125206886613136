import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AdminRole } from '@shared/constants/admin-role';
import { Gender } from '@shared/constants/gender';
import { ALL_AGES } from '@shared/models/age-range';
import { AnalyticsAction, AnalyticsCategory, AnalyticsService } from '@shared/services/analytics';
import { AuthService } from '@shared/services/auth.service';
import { ConstantsService } from '@shared/services/constants.service';
import { DateTimeService } from '@shared/services/date-time.service';
import { SubscriptionService } from '@shared/services/subscription.service';
import { Subscription } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { IRomanceListing } from '../../models/romance-listing';
import { RomanceService } from '../../services/romance.service';
import { RomanceSearchComponent } from '../romance-search/romance-search.component';

@Component({
  selector: 'romance-list',
  styleUrls: ['romance-list.component.scss'],
  templateUrl: './romance-list.component.html'
})
export class RomanceListComponent implements OnInit, OnDestroy {
  get CONSTANTS() {
    return this.constantsService.constants.ROMANCE.LIST;
  }

  get isAdmin() {
    return this.authService.isAdmin([AdminRole.SUPER, AdminRole.ROMANCE]);
  }

  get romanceListings() {
    // show your own listing at the top, exclude from Romance Listings below.
    if (this.romanceListing != null && this.romanceListing.uid != null) {
      return this.romanceService.romanceListings.filter(r => r.uid !== this.romanceListing.uid);
    }

    return this.romanceService.romanceListings;
  }

  get showLoadMoreButton() {
    return this.romanceService.hasMore;
  }

  hasRecords = false;
  loading = false;
  noListingsMessage: string;
  ownRomanceListingRef: Subscription;
  recordsLoaded = false;
  romanceListing: IRomanceListing;
  romanceListingsRef: Subscription;
  romanceOptionsRef: Subscription;

  constructor(
    private analyticsService: AnalyticsService,
    public authService: AuthService,
    private constantsService: ConstantsService,
    private dateTimeService: DateTimeService,
    private modalController: ModalController,
    private romanceService: RomanceService,
    private subscriptionService: SubscriptionService
  ) {}

  getDateFormat(romanceListing: IRomanceListing): string {
    return this.dateTimeService.getDateFormat(romanceListing.created);
  }

  loadMoreRomanceListings() {
    if (this.loading) return;
    this.loading = true;

    this.subscriptionService.clearSubscription(this.romanceListingsRef);
    this.romanceListingsRef = this.romanceService.getMoreRomanceListings().subscribe(romanceListings => {
      if (romanceListings == null) return;

      this.hasRecords = !!this.romanceListings.length;
      this.recordsLoaded = true;
      this.loading = false;
    });
    this.subscriptionService.add(this.romanceListingsRef);
  }

  loadMyRomanceListing() {
    this.authService._userProfileSubject
      .pipe(
        skipWhile(x => x == null),
        take(1)
      )
      .subscribe(user => {
        this.ownRomanceListingRef = this.romanceService.getRomanceListing(user.uid).subscribe(romanceListing => {
          this.romanceListing = romanceListing;
        });
        this.subscriptionService.add(this.ownRomanceListingRef);
      });
  }

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.ownRomanceListingRef);
    this.subscriptionService.clearSubscription(this.romanceListingsRef);
    this.subscriptionService.clearSubscription(this.romanceOptionsRef);
  }

  ngOnInit() {
    this.noListingsMessage = `${this.CONSTANTS.noRecordsFound}.`;
    this.resetSearch();
    this.updateNoRomanceListingsFoundMessage();
    this.loadMyRomanceListing();
    this.loadMoreRomanceListings();
    this.sendAnalytics();
  }

  async onOpenRomanceFilterModal() {
    await this.presentRomanceFilterModal();
  }

  onSearch(romanceSearch: any) {
    if (romanceSearch == null) return;

    // fill in country field, because search component only has UI for location field, etc
    const romanceOptions = this.romanceService.getDefaultRomanceOptions(this.authService._userProfileSubject.value);
    Object.assign(romanceOptions, romanceSearch);

    this.romanceService.updateRomanceOptions(this.authService._userProfileSubject.value.uid, romanceOptions);
  }

  async presentRomanceFilterModal() {
    const eventEmitter = new EventEmitter<any>();
    eventEmitter.subscribe($event => {
      modal.dismiss();
      return this.onSearch($event);
    });

    const modal = await this.modalController.create({
      component: RomanceSearchComponent,
      cssClass: 'romance-search__modal',
      componentProps: {
        search: eventEmitter
      }
    });

    return await modal.present();
  }

  resetSearch() {
    this.onSearch({});
  }

  sendAnalytics() {
    this.analyticsService.eventTrack(AnalyticsCategory.ROMANCE, AnalyticsAction.ROMANCE_VIEW_LISTINGS);
  }

  updateNoRomanceListingsFoundMessage() {
    this.subscriptionService.clearSubscription(this.romanceOptionsRef);
    this.romanceOptionsRef = this.romanceService.getRomanceOptions().subscribe(options => {
      if (options == null) return;
      const seeking = (options.seeking || '').trim().length > 0 && options.seeking !== Gender.EVERYONE ? `<li>${options.seeking}</li>` : '';
      const region = (options.region || '').trim().length > 0 ? `<li>${options.region}</li>` : '';
      const ageRange = !this.isAdmin && options.ageRange !== ALL_AGES.label ? `<li>${options.ageRange}</li>` : ''; // admins can't search on Age Range
      this.noListingsMessage = `${this.CONSTANTS.noRecordsFound}:<ul>${region}${seeking}${ageRange}</ul>`;
    });
    this.subscriptionService.add(this.romanceOptionsRef);
  }
}
