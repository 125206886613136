import { Component, Input } from '@angular/core';
import { IMemberThreadRelatedType } from '@shared/models/messages/member-thread';

@Component({
  selector: 'remove-member',
  styleUrls: ['./remove-member.component.scss'],
  templateUrl: './remove-member.component.html'
})
export class ChirpyRemoveMemberComponent {
  @Input() memberId: string;
  @Input() memberName: string;

  readonly memberType = IMemberThreadRelatedType.Member;
}
