import { Component, Input } from '@angular/core';
import { ViewType } from '../../models/view-type';

@Component({
  selector: 'messages-master-view',
  templateUrl: './master-view.component.html',
  styleUrls: ['./master-view.component.scss']
})
export class MessagesMasterViewComponent {
  readonly CONTACTS: string = 'contacts';
  readonly MORE: string = 'more';
  readonly THREADS: string = 'threads';
  @Input() type: ViewType;

  backButtonColor() {
    return this.selectedTab() === this.THREADS ? 'page-heading' : '';
  }

  isArchive(): boolean {
    return ViewType.Archive === this.type;
  }

  isContact(): boolean {
    return ViewType.Contact === this.type;
  }

  isMore(): boolean {
    return ViewType.More === this.type;
  }

  isThread(): boolean {
    return ViewType.Thread === this.type;
  }

  selectedTab(): string {
    if (this.isArchive()) return this.MORE;
    if (this.isContact()) return this.CONTACTS;
    if (this.isThread()) return this.THREADS;
    if (this.isMore()) return this.MORE;
  }
}
