import { Component } from '@angular/core';
import { UserService } from '@shared/services/user/user.service';
import { GameService } from '../../../services/game-service';

@Component({
  selector: 'bingo-leader-board',
  templateUrl: 'leader-board.component.html'
})
export class BingoLeaderBoardComponent {
  constructor(public gameService: GameService, public userService: UserService) {}
}
