import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ICoinsLimit } from '@shared/models/coins/coins-limit';
import { IOrderCondition } from '@shared/models/order-condition';
import { IWhereCondition } from '@shared/models/where-condition';
import { BaseDatabase } from '@shared/services/base.database';
import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import FieldValue = firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class LimitDatabase extends BaseDatabase {
  getMemberLimit(memberId: string): Observable<ICoinsLimit> {
    return this.getDocument<ICoinsLimit>(this.COLLECTION.LIMITS, memberId);
  }

  updateMemberLimit(memberId: string, data: any, merge: boolean = true): Promise<void> {
    return this.updateDocument(this.COLLECTION.LIMITS, memberId, data, merge);
  }
}
