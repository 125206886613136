import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { IInput } from '@shared/models/input';

@Injectable({
  providedIn: 'root'
})
export class DataSearchPresenter {
  defaultOptions: any;
  form = this.formBuilder.group({});

  constructor(private formBuilder: FormBuilder) {}

  init(defaultOptions: any, fields: IInput[]) {
    this.defaultOptions = defaultOptions;

    for (const field of fields) {
      let defaultValue: any = '';
      if (field.attributes && field.attributes.multiple) defaultValue = [];
      if (field.type === 'number') defaultValue = null;
      //const required = field.required ? [Validators.requiredTrue] : [];
      this.form.addControl(field.name, new FormControl(defaultValue, []));
    }
  }

  reset() {
    this.setValue(this.defaultOptions);
  }

  search() {
    return this.form.value;
  }

  setValue(options: any) {
    this.form.setValue(options);
  }
}
