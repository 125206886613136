// Code borrowed from https://github.com/katedoctor/calendar
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SubscriptionService } from '@shared/services/subscription.service';
import { ICalendarDate } from './models/calendar-date';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss']
})
export class EventCalendarComponent implements OnInit {
  @ViewChild('calendar', { static: true }) calendar;
  @Input() eventDates$: Observable<string[]>;
  private datesSubscription: Subscription;
  currentDate: moment.Moment;
  isPrevMonthDisabled: boolean = true;
  namesOfDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  selectedDates: string[] = [];
  weeks: ICalendarDate[] = [];

  constructor(private subscriptionService: SubscriptionService) {}

  ngOnDestroy() {
    this.subscriptionService.clearSubscription(this.datesSubscription);
  }

  ngOnInit() {
    this.currentDate = moment();
    this.generateCalendar();
    this.datesSubscription = this.eventDates$.subscribe(dates => {
      this.selectedDates = dates;
      this.generateCalendar();
    });
    this.subscriptionService.add(this.datesSubscription);
  }

  isDisabled(): boolean {
    const today = moment();
    return moment(this.currentDate).isSame(today, 'months');
  }

  prevMonth(): void {
    this.currentDate = moment(this.currentDate).subtract(1, 'months');
    this.isPrevMonthDisabled = this.isDisabled();
    this.generateCalendar();
  }

  nextMonth(): void {
    this.currentDate = moment(this.currentDate).add(1, 'months');
    this.isPrevMonthDisabled = false;
    this.generateCalendar();
  }

  private fillDates(currentMoment: moment.Moment) {
    // Get 0-6 for day of week
    const firstOfMonth = moment(currentMoment)
      .startOf('month')
      .day();
    const lastOfMonth = moment(currentMoment)
      .endOf('month')
      .day();

    // Fill in days from prev/next month in first/last rows of calendar
    const firstDayOfGrid = moment(currentMoment)
      .startOf('month')
      .subtract(firstOfMonth, 'days');
    const lastDayOfGrid = moment(currentMoment)
      .endOf('month')
      .subtract(lastOfMonth, 'days')
      .add(7, 'days');

    const startCalendar = firstDayOfGrid.date();

    return this.range(startCalendar, startCalendar + lastDayOfGrid.diff(firstDayOfGrid, 'days')).map(date => {
      const newDate = moment(firstDayOfGrid).date(date);
      return {
        disabled: !this.isCurrentMonth(newDate),
        today: this.isToday(newDate),
        selected: this.isSelected(newDate),
        mDate: newDate
      };
    });
  }

  private generateCalendar(): void {
    const dates = this.fillDates(this.currentDate);
    const weeks = [];
    while (dates.length > 0) {
      weeks.push(dates.splice(0, 7));
    }
    this.weeks = weeks;
  }

  private isCurrentMonth(date: moment.Moment): boolean {
    return moment(date).isSame(this.currentDate, 'month');
  }

  private isSelected(date: moment.Moment): boolean {
    return this.selectedDates.includes(moment(date).format('YYYY-MM-DD'));
  }

  private isToday(date: moment.Moment): boolean {
    return moment().isSame(moment(date), 'day');
  }

  // Helper function to avoid importing lodash range
  private range(startValue, endValue): number[] {
    const length = endValue - startValue;
    return Array.from({ length: length }, (_, i) => i + startValue);
  }
}
