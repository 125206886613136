import { ControlValueAccessor } from '@angular/forms';

// extend this class when you want to create a custom control and embed in part of an Angular form.
export class CustomControl implements ControlValueAccessor {
  private disabled: boolean;
  private onChange;
  private onTouched;
  protected _controlValue;

  constructor() {
    this.onChange = (_: any) => {
      return;
    };
    this.onTouched = () => {
      return;
    };
    this.disabled = false;
  }

  get controlValue() {
    return this._controlValue;
  }

  set controlValue(val) {
    this._controlValue = val;
    this.propagateChange(this._controlValue);
  }

  propagateChange = (_: any) => {};

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  // tslint:disable-next-line:no-empty
  registerOnTouched() {}

  writeValue(value: any) {
    if (value !== undefined) {
      this.controlValue = value;
    }
  }
}
