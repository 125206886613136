import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { RomanceDetailComponent } from './components/romance-detail/romance-detail.component';
import { RomanceEditComponent } from './components/romance-edit/romance-edit.component';
import { RomanceListComponent } from './components/romance-list/romance-list.component';
import { RomanceSearchComponent } from './components/romance-search/romance-search.component';
import { RomanceIntroPage } from './romance-intro/romance-intro.page';
import { RomanceRoutingModule } from './romance-routing.module';
import { RomancePage } from './romance.page';

@NgModule({
  imports: [RomanceRoutingModule, SharedModule],
  declarations: [RomanceDetailComponent, RomanceEditComponent, RomanceSearchComponent, RomanceListComponent, RomanceIntroPage, RomancePage],
  entryComponents: [RomanceSearchComponent]
})
export class RomanceModule {}
