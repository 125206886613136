import { Deserializable } from './deserializable.model';

// FirebaseObject is a convenience class. Its purpose is to append the uid to the document you have read from the
// database. If you don’t need the uid, or if you’re setting not reading the data, like here, then you don’t have to
// use a FirebaseObject. AngularFirestore is happy with any type.
export class FirebaseObject implements Deserializable {
  uid: string;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}
