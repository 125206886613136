import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MembersGuard } from '@shared/guards/members-guard';
import { PixCollectionPage } from './pix-collection/pix-collection.page';
import { PixDetailPage } from './pix-detail/pix-detail.page';
import { PixEditPage } from './pix-edit/pix-edit.page';
import { PixUploadPage } from './pix-upload/pix-upload.page';

export const routes: Routes = [
  {
    path: ':id/:title',
    component: PixCollectionPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Public media collection'
    }
  },
  {
    path: ':id/:title/detail',
    component: PixDetailPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Public media details'
    }
  },
  {
    path: ':id/:title/detail/:photoId/edit',
    component: PixEditPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Public media edit metadata'
    }
  },
  {
    path: ':id/:title/upload',
    component: PixUploadPage,
    canActivate: [MembersGuard],
    data: {
      title: 'Public media upload'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PixRoutingModule {}
