import { Component, Input, OnInit } from '@angular/core';
import { UserObject } from '@shared/models/user-object';
import { InterestStatus } from '@shared/constants/interest-status';
import { ISocial } from '@shared/models/social/social';
import { SocialService } from '@shared/services/social/social.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'social-rsvp',
  templateUrl: './social-rsvp.component.html'
})
export class SocialRsvpComponent implements OnInit {
  get numberInterested() {
    return this.socialService.numberInterested(this.social);
  }

  get socialInterestStatus() {
    return this.social.interested[this.member.uid];
  }

  @Input() social: ISocial;
  readonly InterestStatus = InterestStatus;
  isClosed: boolean = false;
  @Input() member: UserObject;

  addGoing() {
    this.socialService.setInterested(this.social.uid, this.member.uid, InterestStatus.GOING);
  }

  addInterest() {
    this.socialService.setInterested(this.social.uid, this.member.uid, InterestStatus.INTERESTED);
  }

  constructor(private socialService: SocialService) {}

  ngOnInit() {
    // TODO: Remove this? Only really necessary if coins are being claimed
    // Prevent expressing interested if the event has started.
    this.isClosed = this.socialService.isClosed(this.social);
  }

  removeInterest() {
    this.socialService.setInterested(this.social.uid, this.member.uid, InterestStatus.NOT_INTERESTED);
  }
}
